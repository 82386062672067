import React from 'react'
import { arrayOf, func, object } from 'prop-types'

const TotalCell = ({ aggregatorFn, column, records }) => {
  const defaultAggFn = (acc, record) => {
    return (acc += record[column.name] || 0)
  }

  const aggFn = aggregatorFn && typeof aggregatorFn === 'function' ? aggregatorFn : defaultAggFn
  const total = records.reduce(aggFn, 0)

  return <td>{total}</td>
}
TotalCell.propTypes = {
  /**
   * An aggregator function that if provided will be called for all the records of the current column
   * If not provided, the cell is going to try to aggregate all the field of the record matching the column name
   *
   * @param {object} record The current record
   * @returns {number}
   */
  aggregatorFn: func,

  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * All the available records provided to the table
   */
  records: arrayOf(object).isRequired
}

export default TotalCell
