import { createAction } from 'redux-actions'

export const addSellinOrderCompletionToRegion = createAction('Add Sell-in Values to region')
export const addSellinGeographyToRegion = createAction('Add Sell-in Geography Values to region')
export const addSellinRunRateToRegion = createAction('Add Sell-in Run Rate Values to region')
export const addPlanningSellinValuesToRegion = createAction('Add Planning Sell-in Values to region')
export const addDistroTrendedDataToRegion = createAction('Add Distro Trended Data to region')
export const addDistroBrandDataToRegion = createAction('Add Distro Brand Data to region')
export const addDistroMaterialDataToRegion = createAction('Add Distro Material Data to region')
export const addDistroGeographyDataToRegion = createAction('Add Distro Geography Data to region')
export const addDistroSummaryDataToRegion = createAction('Add Distro Summary Data to region')
export const addPlanningSelloutValuesToRegion = createAction('Add Planning Sell-out Values to region')
export const addPlanningHealthCheckValuesToRegion = createAction('Add Planning Health Check Values to region')
export const addPlanningPriceCheckValuesToRegion = createAction('Add Planning Price Check Values to region')
export const addPlanningEngagementValuesToRegion = createAction('Add Planning Engagement Values to region')
export const addPlanningCycleCampaignValuesToRegion = createAction('Add Cycle Campaign Values to region')

export const addAmplifySelloutValuesToRegion = createAction('Add Amplify Sell-out Values to region')
export const addAmplifySelloutGeographyValuesToRegion = createAction('Add Amplify Sell-out Geography Values to region')
export const addAmplifyInventoryOosTrendingValuesToRegion = createAction(
  'Add Amplify Inventory OOS Trending Values to region'
)
export const addAmplifyInventoryOosBrandsValuesToRegion = createAction(
  'Add Amplify Inventory OOS Brand Values to region'
)
export const addAmplifyInventoryOosMaterialValuesToRegion = createAction(
  'Add Amplify Inventory OOS Material Values to region'
)
export const addAmplifyInventoryOosGeographyValuesToRegion = createAction(
  'Add Amplify Inventory OOS Geography Values to region'
)
export const addAmplifyInventoryOosFiltersValuesToRegion = createAction(
  'Add Amplify Inventory OOS Filters Values to region'
)

export const addAmplifyExtraHubTrendingValuesToRegion = createAction('Add Amplify Extra Hub Trending Values to Region')
export const addAmplifyExtraHubEngagementValuesToRegion = createAction(
  'Add Amplify Extra Hub Engagement Values to Region'
)
export const addAmplifyExtraHubGeographyValuesToRegion = createAction(
  'Add Amplify Extra Hub Geography Values to Region'
)
export const addAmplifyExtraHubFiltersValuesToRegion = createAction('Add Amplify Extra Hub Filters Values to Region')
export const addAmplifyExtraHubActivityValuesToRegion = createAction('Add Amplify Extra Hub Activity Values to Region')
export const addAmplifyPriceCaptureComplianceFootprintValuesToRegion = createAction(
  'Add Amplify Price Capture Compliance Footprint Values to Region'
)
export const addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion = createAction(
  'Add Amplify Price Capture Compliance Brand Completion Values to Region'
)
export const addAmplifyPriceCaptureComplianceFiltersValuesToRegion = createAction(
  'Add Amplify Price Capture Compliance Filters Values to Region'
)
export const addAmplifyPriceCaptureComplianceGeographyValuesToRegion = createAction(
  'Add Amplify Price Capture Compliance Geography Values to Region'
)
export const addAmplifyPriceComplianceStrategyComplianceValuesToRegion = createAction(
  'Add Amplify Price Compliance Strategy Compliance Values to Region'
)
export const addAmplifyPriceComplianceEdlpComplianceValuesToRegion = createAction(
  'Add Amplify Price Compliance Edlp Compliance Values to Region'
)
export const addAmplifyPriceComplianceGeographyComplianceValuesToRegion = createAction(
  'Add Amplify Price Compliance Geography Compliance Values to Region'
)
export const addAmplifyPriceComplianceGeographyFiltersValuesToRegion = createAction(
  'Add Amplify Price Compliance Geography Filters Values to Region'
)

export const addCycleCampaignsToRegion = createAction('Add Cycle Campaign to Region')
