import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

const geographyFromProps = (state, props) => props.geography
const offsetFromProps = (state, props) => props.offset
const filterFromProps = (state, props) => props.filter
const sortByFromProps = (state, props) => props.sortBy
const sortDirectionFromProps = (state, props) => props.sortDirection
const getCurrentExtraHub = createSelector(dataSourceFromSectorLevel, (sectorData) => sectorData?.extraHub || {})
export const getCurrentTrending = createSelector(getCurrentExtraHub, (hub) => {
  const trendData = { user: [], store: [] }
  if (!hub.trending) return trendData

  hub.trending.forEach((m) => {
    const cycleNb = m.order >= 0 ? m.order + 1 : 13 + m.order
    const name = 'C' + cycleNb.toLocaleString().padStart(2, '0')
    trendData.user.push({ name, amount: m.totalCompletedUsers ?? null, activity: m.activeActivities ?? null })
    trendData.store.push({ name, amount: m.totalCompletedStores ?? null, activity: m.activeActivities ?? null })
  })

  return trendData
})

export const getCurrentEngagement = createSelector(getCurrentExtraHub, (hub) => {
  return (
    hub?.engagement || {
      engagedContacts: 0,
      activeContacts: 0,
      engagement: 0
    }
  )
})

export const getCurrentFilters = createSelector(getCurrentExtraHub, (hub) => hub?.filters || [])

export const getCurrentGeographies = createSelector(
  getCurrentExtraHub,
  geographyFromProps,
  offsetFromProps,
  filterFromProps,
  sortByFromProps,
  sortDirectionFromProps,
  (hub, geography, offset, filter, sortBy, sortDirection) => {
    const geoData = hub?.geographies?.[`${geography}-${filter}-${offset}-${sortBy}-${sortDirection}`] || []

    if (!geoData) return []

    return geoData.map((geo) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${geo.id}/pace/amplify/extra-hub`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${geo.id}/pace/amplify/extra-hub`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${geo.id}/pace/amplify/extra-hub`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${geo.id}/pace/amplify/extra-hub`,
          ...geo
        }
      }
      return {
        linkTo: null,
        ...geo
      }
    })
  }
)

export const getCurrentActivity = createSelector(getCurrentExtraHub, (hub) => {
  return {
    erp: hub?.activity?.storeCompletion || 0,
    totalErp: hub?.activity?.totalStores || 0,
    user: hub?.activity?.userCompletion || 0,
    totalUser: hub?.activity?.activeContacts || 0,
    coins: hub?.activity?.coinsEarned || 0
  }
})
