import React from 'react'
import { connect } from 'react-redux'
import { stripUnit } from 'polished'
import { func, number, object, string } from 'prop-types'
import styled from 'styled-components'

import { deleteUserMileage } from 'store/mileage/actions'

import IconButton from 'components/Icon'

import { borderColor, greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    border-top: 1px solid ${borderColor};
    padding-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

const Title = styled.span`
  display: block;
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  color: ${greyDark};
`

const Address = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const Distance = styled.span`
  font-size: ${smallFontSize};
  margin-right: 8px;
`

const MileageRow = ({ date, type, address, distance, deleteUserMileage, mileage }) => (
  <Container>
    <div>
      <Title>
        {date} • {type}
      </Title>
      <Address>{address}</Address>
    </div>
    <div>
      <Distance>{distance.toFixed(3)} km</Distance>
      <IconButton icon="trash" primary onClick={() => deleteUserMileage(mileage)} />
    </div>
  </Container>
)

MileageRow.propTypes = {
  date: string,
  type: string,
  address: string,
  distance: number,
  mileage: object,
  deleteUserMileage: func.isRequired
}

export default connect(null, { deleteUserMileage })(MileageRow)
