import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as Accordion from '@radix-ui/react-accordion'
import { Form, Formik, useFormikContext } from 'formik'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import sum from 'lodash/sum'
import { array, bool, func, node, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerAssortment } from 'store/customers/actions'
import { isPo98Customer, newCustomerAssortment } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { submitNewSAQ, submitSAQEdit } from 'store/orders/actions'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Card from 'components/card'
import CheckoutTable from 'components/close/CheckoutTable'
import PlaceOrder from 'components/close/PlaceOrder'
import EmptyState from 'components/EmptyState'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import WarningModal from 'components/Modal/WarningModal'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatCompactCurrency } from 'utils/formatters'
import { createDataKey, excelSafeNanoid, getErrorMessage } from 'utils/helpers'

const MINIMUM_QUANTITY_REQUIRED = 5

function getOrdersInStorage() {
  const ordersInStorage = localStorage.getItem('orders')

  return ordersInStorage ? JSON.parse(ordersInStorage) : null
}

const CartCard = ({ span, submitNewSAQ, submitSAQEdit, getCustomerAssortment }) => {
  const { sectorId: customerId } = useParams()
  const { translate } = useContext(LangContext)
  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const [error, setError] = useState()
  const isPO98Order = useSelector((state) => isPo98Customer(state, { customerId }))

  const navigate = useNavigate()
  const { insightId, projectId } = location.state || {}
  const assortment = useSelector((state) => newCustomerAssortment(state, { customerId }))
  const employee = useSelector((state) => state.auth.user)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ASSORTMENT, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const employeeId = useSelector((state) => state.auth.user.id)
  const errorMessages = {
    emergencyMinimumQuantityNotReached: translate('saq.emergencyMinimumQtyNotReached'),
    negativeValueDetected: translate('saq.negativeValueDetected'),
    generic: translate('app.warn.errorOccuredTryLater'),
    globalMinimumQuantity: translate('saq.template.globalMinimumQuantity', {
      minimumQuantityRequired: MINIMUM_QUANTITY_REQUIRED
    })
  }

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomerAssortment({ customerId, dataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [customerId])

  const HeaderActions = ({ resetForm }) => (
    <div className="flex w-full gap-3 max-md:justify-between">
      <div className="flex gap-3">
        <WarningModal
          title="Are you sure?"
          message="This will set all the quantities in the cart to 0. You can't undo this action."
          primaryAction={{
            label: 'Clear all',
            type: 'destructive',
            action: () => resetForm()
          }}
          secondaryAction={{
            label: 'Cancel',
            action: () => resetForm()
          }}
          trigger={
            <>
              <div className="flex @md/card:hidden">
                <IconButton secondary icon="close" compact />
              </div>
              <div className="hidden @md/card:flex">
                <Button secondary icon="close">
                  Clear
                </Button>
              </div>
            </>
          }
        />
        <WarningModal
          title="Are you sure?"
          message="This will reset the quantities in the cart to what they were before you made any changes. You can't undo this action."
          primaryAction={{
            label: 'Reset all',
            type: 'destructive',
            action: () => resetForm()
          }}
          secondaryAction={{
            label: 'Cancel',
            action: () => resetForm()
          }}
          trigger={
            <>
              <div className="flex @md/card:hidden">
                <IconButton secondary icon="undo" compact />
              </div>
              <div className="hidden @md/card:flex">
                <Button secondary icon="undo">
                  Reset
                </Button>
              </div>
            </>
          }
        />
      </div>
      <div className="h-9 w-px shrink-0 bg-slate-100 max-md:hidden" />
      <WarningModal
        title="Are you sure?"
        message="This will overwrite the current cart with the previous order. You can't undo this action."
        primaryAction={{
          label: 'Copy Previous Order',
          action: () => console.log('Reset')
        }}
        secondaryAction={{
          label: 'Cancel',
          action: () => console.log('Cancel')
        }}
        trigger={
          <Button secondary icon="file-copy">
            Copy Previous Order
          </Button>
        }
      />
    </div>
  )

  HeaderActions.propTypes = {
    resetForm: func
  }

  const handleOrderSubmit = async (values, { resetForm }) => {
    const {
      sendEmailCopy,
      preferredLanguage, // undefined
      type,
      emergencyFlag,
      po98Reason,
      po98Comments,
      po98CoordinatorApproved,
      po98CoordinatorName
    } = values

    const redirect = () => {
      resetForm()
      navigate('..', { relative: 'path' })
    }

    if (isMobileViewOnly) {
      return redirect()
    }

    const productsWithCounts = pickBy(values.entries, (val) => val > 0)
    const entries = Object.entries(productsWithCounts).reduce((acc, [productId, qty]) => {
      const validQty = Math.max(+qty, 0)
      if (!+validQty) return acc
      return [
        ...acc,
        {
          productId: productId.replace(/\D/g, ''),
          qty: validQty
        }
      ]
    }, [])

    if (!entries.length) return

    const orderId = values.id || excelSafeNanoid()
    const submitDetails = {
      id: orderId,
      customerId,
      employeeId,
      entries,
      insightId,
      projectId,
      createdAt: values.createdAt || new Date().toISOString(),
      sendEmailCopy,
      preferredLanguage: preferredLanguage || employee.preferredLanguage,
      type,
      emergencyFlag: ['order', 'emergency'].includes(type) ? true : emergencyFlag
    }

    if (values.type === 'po98') {
      Object.assign(submitDetails, {
        po98Reason,
        po98CoordinatorApproved,
        po98CoordinatorName: po98CoordinatorApproved ? po98CoordinatorName : null,
        po98Comments
      })
    }
    try {
      await submitNewSAQ(submitDetails)
      removeOrderFromStorage()

      redirect()
    } catch (err) {
      const { response } = err
      const loginError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[loginError] || errorMessages.generic)
      // setSubmitting(false)
    }
  }

  function removeOrderFromStorage() {
    const ordersInStorage = getOrdersInStorage()
    if (!ordersInStorage) return

    localStorage.setItem('orders', JSON.stringify(omit(ordersInStorage, customerId)))
  }

  const initialValues = useMemo(() => {
    const common = { entries: assortment.reduce((acc, it) => ({ ...acc, [it.id]: 0 }), {}), sendEmailCopy: false }

    if (isPO98Order) {
      return {
        ...common,
        type: 'po98',
        po98Reason: '',
        po98CoordinatorApproved: false
      }
    }

    return {
      ...common,
      type: 'saq'
    }
  }, [assortment])

  return (
    <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur onSubmit={handleOrderSubmit}>
      {({ globalError, isSubmitting, resetForm }) => {
        return (
          <Card title={'Order Cart'} span={span} headerActions={[<HeaderActions key={1} resetForm={resetForm} />]}>
            {error ? <GlobalAlert>{error}</GlobalAlert> : null}
            {globalError ? <GlobalAlert warning>{globalError}</GlobalAlert> : null}
            {isLoading ? (
              <EmptyState isLoading title={translate('app.warn.isLoading')} />
            ) : isSubmitting ? (
              <EmptyState
                isLoading
                title={translate('common.submitting')}
                subtitle={translate('app.warn.waitWhileWeSaveData')}
              />
            ) : (
              <OrderCartForm assortment={assortment} customerId={customerId} />
            )}
          </Card>
        )
      }}
    </Formik>
  )
}

CartCard.propTypes = {
  span: number,
  submitNewSAQ: func,
  submitSAQEdit: func,
  getCustomerAssortment: func
}

export default connect(null, { submitNewSAQ, submitSAQEdit, getCustomerAssortment })(CartCard)

const CategoryTrigger = React.forwardRef(({ title, open = 'false', suggestedAmounts, ...props }, forwardedRef) => (
  <Accordion.Trigger
    className="group flex w-full items-center gap-4 px-3 py-3 text-lg font-medium text-slate-900 md:py-4 md:pl-4 md:pr-5"
    {...props}
    ref={forwardedRef}
  >
    <div
      aria-hidden
      className="flex size-6 shrink-0 items-center justify-center rounded-full bg-slate-900/5 text-slate-700"
    >
      <Icon icon={open ? 'minus-small' : 'plus-small'} compact />
    </div>
    <div className="flex w-full items-center justify-between">
      <h2 className="font-semibold text-slate-700 group-hover:text-slate-900 group-focus:text-slate-900">{title}</h2>
      <div className="flex flex-col items-end gap-1">
        <p className="text-2xs uppercase leading-none text-slate-500">Suggested</p>
        <div className="flex items-center gap-1.5 text-sm text-sky-600">
          <p>{suggestedAmounts.qty} ctns</p>
          <div className="h-4 w-px bg-slate-900/10" />
          <p>${suggestedAmounts.value.toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
        </div>
      </div>
    </div>
  </Accordion.Trigger>
))

CategoryTrigger.displayName = 'CategoryTrigger'

CategoryTrigger.propTypes = {
  title: node.isRequired,
  open: bool,
  suggestedAmounts: {
    qty: number.isRequired,
    value: number.isRequired
  }
}

const CategoryContent = ({ children }) => (
  <Accordion.Content className="overflow-auto transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
    {children}
  </Accordion.Content>
)

CategoryContent.propTypes = {
  children: node.isRequired
}

const OrderCartForm = ({ assortment, customerId }) => {
  const { globalError, setValues, values, ...formikProps } = useFormikContext()
  const navigate = useNavigate()
  const [activeProductType, setActiveProductType] = useState('fmc')
  const totalQuantity = sum(Object.values(values.entries))
  const totalAmount = Object.entries(values.entries).reduce((acc, [productId, qty]) => {
    const linkedProduct = assortment.find((asso) => asso.id === productId)
    if (!linkedProduct || !linkedProduct?.basePriceInDollars) return acc
    return (acc += linkedProduct.basePriceInDollars * qty)
  }, 0)

  function saveAndClose(formValues) {
    saveOrderToLocalstorage(formValues)
    navigate('..', { relative: 'path' })
  }

  useEffect(() => {
    const ordersInStorage = getOrdersInStorage()
    if (ordersInStorage) {
      const formWasStarted = ordersInStorage[customerId]
      if (formWasStarted) setValues(formWasStarted)
    }
  }, [])

  const { FMC_PRODUCTS, VAPE_PRODUCTS } = useMemo(() => {
    const productsByType = assortment.reduce(
      (acc, it) => {
        const productCategory = it.materialGroup === 'FG Cigarettes' ? 'FMC_PRODUCTS' : 'VAPE_PRODUCTS'
        return { ...acc, [productCategory]: [...acc[productCategory], it] }
      },
      { FMC_PRODUCTS: [], VAPE_PRODUCTS: [] }
    )

    const groupedFmc = groupBy(productsByType.FMC_PRODUCTS, 'englishBrand')
    const groupedVape = groupBy(productsByType.VAPE_PRODUCTS, 'englishBrand')

    return {
      FMC_PRODUCTS: Object.entries(groupedFmc).map(([brand, products]) => ({ name: brand, data: products })),
      VAPE_PRODUCTS: Object.entries(groupedVape).map(([brand, products]) => ({ name: brand, data: products }))
    }
  }, [assortment])

  function saveOrderToLocalstorage(currentOrder) {
    const ordersInStorage = getOrdersInStorage()

    if (ordersInStorage) {
      ordersInStorage[customerId] = currentOrder
      localStorage.setItem('orders', JSON.stringify(ordersInStorage))
    } else {
      localStorage.setItem('orders', JSON.stringify({ [customerId]: currentOrder }))
    }
  }

  return (
    <div className="relative flex grow flex-col gap-4">
      <div className="flex flex-col items-start justify-between rounded-md bg-slate-50 font-medium @md/card:flex-row @md/card:items-center max-md:gap-2 max-md:p-3 md:gap-2 md:p-4 ">
        <p className="text-2xs uppercase leading-none text-slate-500 @md/card:text-sm">Recommended Total Amounts</p>
        <div className="flex items-center gap-1.5 text-sm leading-none text-slate-700">
          <p># units</p>
          <div className="h-4 w-px bg-slate-900/10" />
          <p>${(0).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
        </div>
      </div>

      <Form className="flex h-full flex-col justify-between">
        {globalError && (
          <FieldsetItem>
            <GlobalAlert>{globalError}</GlobalAlert>
          </FieldsetItem>
        )}
        <Accordion.Root
          type="single"
          defaultValue="fmc"
          collapsible
          onValueChange={setActiveProductType}
          className="mb-auto divide-y divide-slate-900/10 rounded-md bg-white ring-1 ring-slate-900/10"
        >
          <Accordion.Item value="fmc" className="divide-y divide-slate-900/10">
            <CategoryTrigger title="FMC" open={activeProductType === 'fmc'} suggestedAmounts={{ qty: '#', value: 0 }} />
            <CategoryContent>
              <CheckoutTable productData={FMC_PRODUCTS} {...formikProps} />
            </CategoryContent>
          </Accordion.Item>

          <Accordion.Item value="vape" className="divide-y divide-slate-900/10">
            <CategoryTrigger
              title="VAPE"
              open={activeProductType === 'vape'}
              suggestedAmounts={{ qty: '#', value: 0 }}
            />
            <CategoryContent>
              <CheckoutTable productData={VAPE_PRODUCTS} {...formikProps} />
            </CategoryContent>
          </Accordion.Item>
        </Accordion.Root>

        <hr className="my-2 border-slate-900/10 " />
        <div className="flex flex-col items-end gap-4 font-medium @md/card:flex-row @md/card:justify-between">
          <div className="flex flex-col items-start gap-1">
            <p className="text-2xs uppercase leading-none text-slate-500">Cart summary</p>
            <div className="flex items-center gap-1.5 text-sm text-sky-600">
              <p>{totalQuantity} ctns</p>
              <div className="h-4 w-px bg-slate-900/10" />
              <p>{formatCompactCurrency(totalAmount)}</p>
            </div>
          </div>

          <div className="flex shrink-0 gap-3">
            <Button secondary onClick={() => saveAndClose(values)}>
              Save & Close
            </Button>
            <PlaceOrder products={{ fmc: FMC_PRODUCTS, vape: VAPE_PRODUCTS }} />
          </div>
        </div>
      </Form>
    </div>
  )
}

OrderCartForm.propTypes = {
  assortment: array,
  customerId: string
}
