import React from 'react'
import moment from 'moment'
import { object, string } from 'prop-types'

import StatDetails from 'components/StatDetails'

const TerritoryProjectStatusHeader = ({ actioned, creation, expiry, resolved, total }) => (
  <StatDetails
    data={[
      {
        label: 'Actioned',
        stats: [
          `${actioned.count}/${total.count}`,
          `(${total.valueSum ? Math.round((actioned.valueSum / total.valueSum) * 100) : '-'}% wtd)`
        ]
      },
      {
        label: 'Resolved',
        stats: [
          `${resolved.count}/${total.count}`,
          `(${total.valueSum ? Math.round((resolved.valueSum / total.valueSum) * 100) : '-'}% wtd)`
        ]
      },
      {
        label: 'Started',
        stats: [moment(creation).format('MMM DD')]
      },
      {
        label: 'Expires',
        stats: moment(expiry).format('MMM DD')
      }
    ]}
  />
)

TerritoryProjectStatusHeader.propTypes = {
  creation: string.isRequired,
  expiry: string.isRequired,
  actioned: object.isRequired,
  resolved: object,
  total: object
}

export default TerritoryProjectStatusHeader
