import { schema } from 'normalizr'

const customer = new schema.Entity('customers')
const goFund = new schema.Entity('goFunds')
const goFundProgram = new schema.Entity(
  'goFundPrograms',
  { customer },
  {
    idAttribute: ({ id, goFundId }) => `${id}_${goFundId}`
  }
)

export const territoryGoFundAllocations = new schema.Entity('goFundAllocation', {
  goFundPrograms: [goFundProgram],
  goFund
})
