import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import union from 'lodash/union'
import moment from 'moment'
import { createSelector } from 'reselect'

import { lastSyncs } from 'store/auth/selectors'
import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { formatPerformanceData } from 'utils/performance'

export const getCurrentTerritory = createSelector(dataSourceFromSectorLevel, (sectorData) => sectorData || {})

export const territoryIdFromProps = (state, props) => props?.territoryId
export const allTerritories = (state) => omit(state.territories, '_persist')
const allIntel = (state) => omit(state.intel, '_persist')
const allCustomers = (state) => omit(state.customers, '_persist')
const allSurveys = (state) => omit(state.surveys, '_persist')
const allProjects = (state) => omit(state.projects, '_persist')
const allSellInPrograms = (state) => omit(state.sellInPrograms, '_persist')

const surveyIdFromUrl = (state, props) => props.surveyId

export const currentTerritory = createSelector(territoryIdFromProps, allTerritories, (id, territories) => {
  if (!id || isEmpty(territories)) return null
  return territories[id] || null
})

export const currentTerritoryName = createSelector(currentTerritory, (currentTerritory) => {
  return currentTerritory?.name
})

export const territoryProvinces = createSelector(allTerritories, (territories) => {
  return Object.values(territories).reduce(
    (acc, { provinces, name }) => {
      const isVapeChannel = name.toLowerCase().includes('vape')
      return {
        general: isVapeChannel ? acc.general : union(acc.general, provinces),
        vape: isVapeChannel ? union(acc.vape, provinces) : acc.vape
      }
    },
    { general: [], vape: [] }
  )
})

export const territoryDropdownOptions = createSelector(allTerritories, (territories) => {
  if (isEmpty(territories)) return null
  const territoriesWithCustomers = Object.values(territories).filter(({ customer }) => !customer || customer.length)
  return sortBy(territoriesWithCustomers, 'name').reduce(
    (acc, { id, name }) => [...acc, { label: name, value: id }],
    []
  )
})

export const territoryErpOptions = createSelector(currentTerritory, allCustomers, (currentTerritory, customers) => {
  if (!currentTerritory?.customers?.length) return []
  return Object.values(pick(customers, currentTerritory.customers)).map(({ id, name, banner }) => ({
    id,
    name,
    banner
  }))
})
export const currentTerritoryFetched = createSelector(currentTerritory, (territory) => territory && territory.lastFetch)

export const territoryPerfSequence = createSelector(
  currentTerritory,
  (currentTerritory) => currentTerritory && currentTerritory.sequence
)
export const territoryPerformance = createSelector(currentTerritory, lastSyncs, (currentTerritory, lastSyncs) => {
  if (!currentTerritory) return null
  const formattedPerformanceData = formatPerformanceData(
    currentTerritory.performance,
    currentTerritory.topSkus,
    undefined,
    {
      ...lastSyncs.territory,
      topSkus: currentTerritory.topSkusUpdatedAt
    }
  )
  return formattedPerformanceData.reduce((acc, section) => ({ ...acc, [section.sequenceReference]: section }), {})
})

export const territoryProjects = createSelector(
  getCurrentTerritory,
  allProjects,
  allCustomers,
  (currentTerritory, allProjects, allCustomers) => {
    if (!currentTerritory?.customers) return {}

    const customerProjectLinks = currentTerritory.customers.reduce((acc, id) => {
      const { name, projectLinks } = allCustomers[id] || {}
      if (!name || !projectLinks?.length) return acc
      return acc.concat(projectLinks.map((c) => ({ name, ...c })))
    }, [])

    const projectGroupedCustomerProjects = groupBy(customerProjectLinks, 'projectId') || {}
    return currentTerritory.projectLinks?.reduce((acc, { projectId, ...tp }) => {
      const project = allProjects[projectId]
      if (!project) return acc
      const sortOrder = project.sortingOrder || 'descending'
      const projectCustomers = projectGroupedCustomerProjects[projectId] || []
      const actionCategoryCounts = countBy(project.actions, 'category')
      const customerActions = projectCustomers.reduce(
        (acc, { actionLinks }) =>
          acc.concat(
            actionLinks.map(({ actionId, ...a }) => ({
              ...(project.actions.find(({ id }) => actionId === id) || null),
              ...a,
              actionId
            }))
          ),
        []
      )
      const completedActionsByCategory = groupBy(customerActions, 'category')
      return {
        ...acc,
        [projectId]: {
          ...tp,
          actionTotals: Object.entries(actionCategoryCounts).map(([category, count]) => ({
            // TO BE  FIXEED TO REFLECT DESIRED VALUES
            category,
            total: count * projectCustomers.length,
            completed: completedActionsByCategory[category] ? completedActionsByCategory[category].length : 0
          })),
          total: {
            count: projectCustomers.length,
            valueSum: sumBy(projectCustomers, 'value')
          },
          actioned: {
            count: sumBy(projectCustomers, ({ actionLinks }) => (actionLinks?.length > 0 ? 1 : 0)),
            valueSum: sumBy(projectCustomers, ({ actionLinks, value }) => (actionLinks?.length ? value : 0))
          },
          resolved: {
            count: sumBy(projectCustomers, ({ status }) => (status === 'resolved' ? 1 : 0)),
            valueSum: sumBy(projectCustomers, ({ status, value }) => (status === 'resolved' ? value : 0))
          },
          project,
          customers: groupBy(
            orderBy(projectCustomers, 'value', sortOrder === 'ascending' ? 'asc' : 'desc'),
            ({ status }) => (['unresolved', 'followUp'].includes(status) ? 'toAction' : 'actioned')
          )
        }
      }
    }, {})
  }
)

export const territoryIntel = createSelector(
  currentTerritory,
  allIntel,
  allCustomers,
  (currentTerritory, allIntel, allCustomers) => {
    if (!currentTerritory || !currentTerritory.customers || !currentTerritory.customers.length)
      return { current: [], past: [] }
    const intelsInTerritory = Object.values(allIntel).filter(({ customerId }) =>
      currentTerritory.customers.includes(customerId)
    )

    const customersWithIntel = Object.keys(keyBy(intelsInTerritory, 'customerId'))

    const totalCustomers = currentTerritory?.customers?.length || 0

    const { current = [], past = [] } = groupBy(intelsInTerritory, (intelActivity) => {
      const dateToCheck =
        intelActivity.endDate || moment(intelActivity.startDate || intelActivity.createdAt).add(90, 'days')
      return moment().isAfter(dateToCheck, 'day') ? 'past' : 'current'
    })

    return {
      current: {
        customerCount: current.length,
        groupedIntel: groupBy(
          current,
          ({ productCategory, type, manufacturer, brand }) =>
            `${productCategory}|${manufacturer}|${brand}|${type.split('(')[0]}`
        )
      },
      past: {
        customerCount: past.length,
        groupedIntel: groupBy(
          past,
          ({ productCategory, type, manufacturer, brand }) =>
            `${productCategory}|${manufacturer}|${brand}|${type.split('(')[0]}`
        )
      },
      customers: pick(allCustomers, customersWithIntel),
      totalCustomers
    }
  }
)

export const territoryCustomers = createSelector(currentTerritory, allCustomers, (territory, customers) => {
  return pick(customers, territory.customers)
})

const defaultCompletionObject = { notStarted: 0, inProgress: 0, complete: 0, clarificationNeeded: 0, total: 0 }
export const territorySurveys = createSelector(territoryCustomers, allSurveys, (territoryCustomers, allSurveys) => {
  const { total, complete, notStarted, inProgress, clarificationNeeded, ...surveys } = Object.values(
    territoryCustomers
  ).reduce((acc, { surveys: customerSurveys, name, address, id }, idx, tcs) => {
    const targetedSurveys = customerSurveys?.filter(({ isTargeted }) => isTargeted)
    acc.total += targetedSurveys?.length || 0
    if (!targetedSurveys?.length) return acc
    targetedSurveys.forEach(({ completion, surveyId, survey, answers, customerId, isTargeted, isLocked, ...rest }) => {
      if (!isTargeted) return
      if (completion) acc[completion]++
      const surveyCompletion = acc[surveyId] || Object.assign({}, defaultCompletionObject)
      if (completion) surveyCompletion[completion]++
      surveyCompletion.total++
      surveyCompletion.survey = allSurveys[surveyId] || survey
      surveyCompletion.customers = {
        ...(surveyCompletion.customers || {}),
        [customerId]: { name, address, completion, customerId, surveyId, isLocked }
      }
      acc[surveyId] = surveyCompletion
    })
    return acc
  }, Object.assign({}, defaultCompletionObject))
  return {
    total,
    complete,
    notStarted,
    inProgress,
    clarificationNeeded,
    surveys
  }
})

export const territorySurveysList = createSelector(territorySurveys, ({ surveys, ...rest }) => {
  return {
    ...rest,
    surveys: Object.values(surveys)
      .filter(
        ({ survey, clarificationNeeded }) =>
          survey && (moment().isBetween(survey.startDate, survey.endDate, 'day', '[]') || clarificationNeeded)
      )
      .sort((a, b) => new Date(a.survey.endDate).valueOf() - new Date(b.survey.endDate).valueOf())
  }
})

export const territorySurveyCustomers = createSelector(territorySurveys, surveyIdFromUrl, ({ surveys }, surveyId) => {
  if (!surveys) return {}
  const { customers, ...survey } = surveys[surveyId] || {}
  if (!survey?.survey) return {}
  return {
    ...survey,
    customers: Object.values(customers)
  }
})

export const territoryVapeData = createSelector(currentTerritory, ({ ngp }) => ngp)
export const territoryThpData = createSelector(currentTerritory, ({ thp }) => thp)

export const sellInProgramIdFromUrl = (state, props) => props?.match?.params?.sellInProgramId
export const sellInProgram = createSelector(currentTerritory, sellInProgramIdFromUrl, (territory, sellInProgramId) => {
  if (!sellInProgramId) return
  return territory?.sellInPrograms?.[sellInProgramId] || {}
})
export const territorySellInPrograms = createSelector(
  currentTerritory,
  allSellInPrograms,
  (territory, sellInPrograms) => {
    if (!territory?.sellInPrograms) return []
    return Object.values(pick(sellInPrograms, territory.sellInPrograms))
  }
)

export const customerIdFromProps = (state, props) => props?.match?.params?.customerId
export const customerSellInProgram = createSelector(customerIdFromProps, sellInProgram, (customerId, sellInProgram) => {
  return sellInProgram?.customerSellInPrograms?.find(
    (customerProgram) => customerProgram.customerId.toString() === customerId
  )
})

export const sellInQuestions = createSelector(
  customerSellInProgram,
  (customerProgram) => customerProgram?.questions || []
)
export const sellInCustomerQuestions = createSelector(
  customerSellInProgram,
  sellInQuestions,
  (customerProgram, questions) => {
    const customerQuestions = customerProgram?.programQuestions || []
    if (!customerQuestions.length || !questions?.length) return []

    // filter out customer questions related to inexistant questions
    const filteredCustomerQuestions = customerQuestions.filter((quest) => {
      return questions.find((qt) => qt.ref === quest.ref)
    })

    // filter out options that are higher than the max possible value
    const finalCustomerQuestions = filteredCustomerQuestions.map((customerQuestion) => {
      const question = questions.find((qt) => qt.ref === customerQuestion.ref)
      if (!question) return customerQuestion
      return {
        ...customerQuestion,
        options: customerQuestion.options?.filter((qt) => {
          const max = Number(question.max || question.preset)
          const value = Number(qt.value)
          return (!max && max !== 0) || max >= value
        })
      }
    })

    return finalCustomerQuestions
  }
)

export const getRegion = createSelector(currentTerritory, (currentTerritory) => currentTerritory?.region)
