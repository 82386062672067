import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const AlertContainer = styled.div((props) => [
  tw`absolute bottom-4 left-4 right-4 flex items-center justify-center rounded-md px-4 py-3 shadow-md ring-1 gap-2`,
  props.severity === 'warning'
    ? tw`bg-amber-50 text-amber-600 ring-amber-600/40`
    : props.severity === 'error'
    ? tw`bg-red-50 text-red-600 ring-red-600/40`
    : tw`bg-white text-slate-900 ring-slate-900/10`
])

const AlertProvider = ({
  children,
  displayAlert = false,
  alertMessage = 'No data available',
  alertSeverity = 'warning'
}) => {
  return (
    <div className="relative">
      {children}
      {displayAlert && (
        <AlertContainer severity={alertSeverity}>
          {alertSeverity === 'warning' && <Icon icon="warning" small />}
          <p>{alertMessage}</p>
        </AlertContainer>
      )}
    </div>
  )
}

AlertProvider.propTypes = {
  children: node.isRequired,
  displayAlert: bool,
  alertMessage: string,
  alertSeverity: oneOf(['warning', 'error'])
}

export default AlertProvider
