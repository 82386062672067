import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'

import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'

const PriceCaptureHeader = ({ parentHeader, header }) => {
  const { isWideLayout } = useContext(MediaContext)
  const filters = isWideLayout ? [] : [<ProductTypePicker key="amplify-price-capture-product-type-picker" />]
  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

PriceCaptureHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default PriceCaptureHeader
