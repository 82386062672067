import React from 'react'
import { arrayOf, object, oneOf } from 'prop-types'

import CustomCell from 'components/table/cells/footer/CustomCell'
import TextCell from 'components/table/cells/footer/TextCell'
import TotalCell from 'components/table/cells/footer/TotalCell'

const FooterCell = ({ column, records, type, ...rest }) => {
  switch (type) {
    case 'customcell':
      return <CustomCell column={column} records={records} {...rest} />
    case 'totalcell':
      return <TotalCell column={column} records={records} {...rest} />
    case 'textcell':
    default:
      return <TextCell column={column} records={records} {...rest} />
  }
}
FooterCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * All the available records provided to the table
   */
  records: arrayOf(object).isRequired,

  /**
   * The type of cell to render
   */
  type: oneOf(['totalcell', 'textcell', 'customcell']).isRequired
}

export default FooterCell
