import omit from 'lodash/omit'

import { addEntities, mergeEntities, setEntities } from 'store/actions'

import {
  addMessageToCustomerCall,
  addMileageToCustomerCall,
  removeCustomerCall,
  removeMileageToCustomerCall
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.calls
  }),
  [setEntities]: (state, action) => {
    if (!action.payload.calls) return state
    return Object.entries(action.payload.calls).reduce((mergedCalls, [id, call]) => {
      return {
        ...mergedCalls,
        [id]: {
          ...(state[id] || {}),
          ...call
        }
      }
    }, {})
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.calls) return state
    return Object.entries(action.payload.calls).reduce((mergedCalls, [id, call]) => {
      const currentCall = mergedCalls[id]
      if (currentCall && currentCall.updatedAt && currentCall.updatedAt > call.updatedAt) {
        return mergedCalls
      }
      return {
        ...mergedCalls,
        [id]: {
          ...(mergedCalls[id] || {}),
          ...call
        }
      }
    }, state)
  },
  [removeCustomerCall]: (state, { payload }) => omit(state, payload.id),
  [addMileageToCustomerCall]: (state, { payload }) => {
    if (!payload.customerCallId) return state
    return {
      ...state,
      [payload.customerCallId]: {
        ...state[payload.customerCallId],
        employeeKm: { ...payload }
      }
    }
  },
  [removeMileageToCustomerCall]: (state, { payload }) => {
    if (!payload.customerCallId) return state
    return {
      ...state,
      [payload.customerCallId]: {
        ...state[payload.customerCallId],
        employeeKm: null
      }
    }
  },
  [addMessageToCustomerCall]: (state, { payload }) => {
    if (!payload.customerCallId) return state
    return {
      ...state,
      [payload.customerCallId]: {
        ...state[payload.customerCallId],
        message: { ...payload }
      }
    }
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
