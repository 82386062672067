import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import maxBy from 'lodash/maxBy'
import startCase from 'lodash/startCase'
import moment from 'moment'
import { mix, stripUnit } from 'polished'
import { func, node, number, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import CustomerAddress from 'components/CustomerAddress'
import Icon from 'components/Icon'

import { formatPhone } from 'utils/formatters'

import { borderColor, green, grey, greyDark, greyLight, offWhite, red, white, yellow } from 'styles/colors'
import {
  animationCurve,
  animationTime,
  borderRadius,
  cover,
  customerCardCommentHeight,
  customerCardHeight,
  square
} from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontBold, fontSemibold, tinyFontSize } from 'styles/typography'

import 'moment/locale/fr'

const MISSING_EMAIL = 'missing@email.com'

const Container = styled.div`
  position: static;
`

const Inner = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  transition: background-color ${animationTime} ${animationCurve};
  height: ${(props) => stripUnit(customerCardHeight) + props.commentHeight};

  &:hover,
  &:focus,
  &:active {
    background-color: ${mix(0.4, offWhite, white)};
  }

  ${media.breakpoint`
    border-radius: ${borderRadius};
    border: 1px solid ${borderColor};
  `};
`

const LinkOverlay = styled(Link)`
  display: block;
  z-index: 1;
  ${cover('absolute')};
`

const Name = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  color: ${greyDark};
  font-weight: ${fontBold};
  margin-bottom: 0;

  ${media.breakpoint`
    max-width: 360px;
  `};
`

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.small};
  padding-bottom: ${spacing.small};
`

const Actions = styled.div`
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  padding: ${spacing.small};
  margin: -${spacing.small};
`

const Action = styled.a`
  margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const ActionButton = styled.button`
  margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const ActionLink = styled(Link)`
  margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};

  :first-child {
    margin-left: 0;
  }
`

const Footer = styled.footer`
  display: flex;
  align-items: center;
  color: ${greyLight};
  padding: 0 ${spacing.medium};
`

const FooterItem = styled.small`
  display: inline-block;
  margin-right: ${spacing.small};
  font-size: ${tinyFontSize};
`

const CenteredFooterItem = styled(FooterItem)`
  margin-right: ${spacing.xLarge};

  strong {
    display: block;
  }
`

const PrimaryContact = styled.span`
  font-size: ${tinyFontSize};
  margin-bottom: ${spacing.small};
  display: block;
`
const OrderDate = styled.span`
  margin-right: ${spacing.tiny};
  background-color: ${offWhite};
  padding: 0.15em 0.5em;
  border-radius: ${borderRadius};
  font-size: ${tinyFontSize};
`

const UpcomingOrders = styled.div`
  display: flex;
  padding: ${spacing.tiny} ${spacing.medium};
  align-items: center;
  overflow-x: hidden;
  font-size: ${tinyFontSize};
`

const LatestComments = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: ${tinyFontSize};
  z-index: 2;
  min-height: ${customerCardCommentHeight};
  padding: 0 ${spacing.medium};
  border-top: 1px solid ${borderColor};
`

const Comment = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => (props.half ? stripUnit(customerCardCommentHeight) / 2 : customerCardCommentHeight)};
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
`

const CommentText = styled.span`
  white-space: nowrap;
  order: 1;
  flex: 0 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0px;
`
const CommentOverlay = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const NoComments = styled.div`
  color: ${greyLight};
  font-style: italic;
  display: flex;
  align-items: center;
  height: ${customerCardCommentHeight};
`

const MessageStatusIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  text-align: center;
  ${square('20px')};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  font-weight: ${fontSemibold};
  font-size: 10px;
`

const DetailRow = styled.div`
  display: flex;

  & + & {
    margin-top: ${spacing.small};
  }
`

const DetailLabel = styled.label`
  flex-shrink: 0;
  width: 30%;
  color: ${greyLight};
  font-size: ${tinyFontSize};
  margin-top: ${spacing.tiny};
`

const DetailContent = styled.main`
  width: 70%;
  padding-left: ${spacing.tiny};
`

const CustomerDetailRow = ({ label, content }) => (
  <DetailRow>
    <DetailLabel>{label}</DetailLabel>
    <DetailContent>{content}</DetailContent>
  </DetailRow>
)

CustomerDetailRow.propTypes = {
  label: string.isRequired,
  content: node.isRequired
}

const AwrData = ({ awrData, kpiOption, position }) => {
  return (
    <Footer>
      <CenteredFooterItem>
        <strong>AWR</strong>
        {awrData.awr ? '$' + Math.round(awrData.awr) : '-'}
      </CenteredFooterItem>
      <CenteredFooterItem>
        <strong>Rank</strong>
        {kpiOption === 'posShareGap' && (awrData.posShareGap || awrData.posShareGap === 0)
          ? position
          : awrData[kpiOption] || '-'}
      </CenteredFooterItem>
      {kpiOption === 'rank' && (
        <CenteredFooterItem>
          <strong>Cum Contr</strong>
          {awrData.awrCumContr ? (awrData.awrCumContr * 100).toFixed(1) + '%' : '-'}
        </CenteredFooterItem>
      )}
      {kpiOption === 'posShareGap' && (
        <CenteredFooterItem>
          <strong>Share Tgt</strong>
          {awrData.posShareTarget ? (awrData.posShareTarget * 100).toFixed(1) + '%' : '-'}
        </CenteredFooterItem>
      )}
      {['posShareRank', 'posShareChgRank'].includes(kpiOption) && (
        <CenteredFooterItem>
          <strong>Contr</strong>
          {awrData.contr ? (awrData.contr * 100).toFixed(1) + '%' : '-'}
        </CenteredFooterItem>
      )}
      {kpiOption === 'posShareGap' ? (
        <CenteredFooterItem>
          <strong>Share (Gap)</strong>
          {awrData.posShareL2 ? (awrData.posShareL2 * 100).toFixed(1) + '%' : '-'}
          {(awrData.posShareGap || awrData.posShareGap === 0) && (
            <span style={{ color: awrData.posShareGap < 0 ? red : green }}>
              {' '}
              ({awrData.posShareGap > 0 ? '+' : ''}
              {(awrData.posShareGap * 100).toFixed(1) + '%'})
            </span>
          )}
        </CenteredFooterItem>
      ) : (
        <CenteredFooterItem>
          <strong>Share (Chg)</strong>
          {awrData.posShare ? (awrData.posShare * 100).toFixed(1) + '%' : '-'}
          {awrData.posShareChg && (
            <span style={{ color: awrData.posShareChg > 0 ? green : red }}>
              {' '}
              ({awrData.posShareChg > 0 ? '+' : ''}
              {awrData.posShareChg ? (awrData.posShareChg * 100).toFixed(1) + '%' : ''})
            </span>
          )}
        </CenteredFooterItem>
      )}
    </Footer>
  )
}

AwrData.propTypes = {
  awrData: object,
  kpiOption: string,
  position: number
}

const MessageStatus = ({ lastMessage, employee }) => {
  if (!lastMessage?.actionRequiredBy) return <Icon icon="chat" accent />
  const assignmentNotExpired = lastMessage.assignmentExpiry && moment().isBefore(lastMessage.assignmentExpiry)
  if (lastMessage.assignee && assignmentNotExpired) {
    const assignedToMe = lastMessage.assignedEmployeeId === employee.id
    return assignedToMe ? (
      <Icon icon="chat" color={red} />
    ) : (
      <MessageStatusIndicator color={yellow}>
        {lastMessage.assignee.firstName[0]}
        {lastMessage.assignee.lastName[0]}
      </MessageStatusIndicator>
    )
  }
  const assignedToVF = lastMessage.actionRequiredBy === 'VF'
  const isVFUser = ['telesalesRepresentative', 'customerService'].includes(employee.groupCode)
  const indicatorColor = assignedToVF === isVFUser ? red : grey
  return <MessageStatusIndicator color={indicatorColor}>{lastMessage.actionRequiredBy}</MessageStatusIndicator>
}

MessageStatus.propTypes = {
  lastMessage: object,
  employee: object.isRequired
}

const CustomerCard = ({
  customer: { orderDates, awr = {}, ...customer },
  style,
  userLocation,
  onClick,
  setEmailRecipient,
  awrBrandOption,
  customerSortingOption,
  kpiOption,
  index,
  employee
}) => {
  const { translate, lang } = useContext(LangContext)

  const lastMessage = maxBy(customer.messages, 'createdAt')
  const commentHeight = parseInt(customerCardCommentHeight)

  const awrData = awr[awrBrandOption] || {}

  return (
    <Container style={style}>
      <Inner commentHeight={commentHeight}>
        <Main>
          {/* The minWidth here is to allow a flex-child to shrink, without being blocked by a child using ellipsis */}
          {/* https://css-tricks.com/flexbox-truncated-text/ */}
          <div style={{ flex: 1, minWidth: 0 }}>
            <Name className="notranslate" translate="no">
              {customer.name}
            </Name>
            <PrimaryContact>
              {customer.primaryContact.firstname} {customer.primaryContact.lastname}
            </PrimaryContact>
            <CustomerAddress customer={customer} userLocation={userLocation} />
          </div>
          <Actions>
            <ActionLink to={{ pathname: `/customers/${customer.id}/calls`, state: { showInfo: true } }}>
              <Icon icon="info" accent />
            </ActionLink>
            <Action href={`tel:${formatPhone(customer.storePhoneNumber)}`}>
              <Icon icon="phone" accent />
            </Action>
            <ActionButton
              className="notranslate"
              translate="no"
              onClick={() =>
                setEmailRecipient({
                  erp: customer.id,
                  email: customer.primaryContact.email,
                  state: customer.address.state
                })
              }
            >
              <Icon icon="mail" accent />
            </ActionButton>
          </Actions>
        </Main>
        <Footer>
          <FooterItem>ERP: {customer.id}</FooterItem>
          <FooterItem>Week sales: {customer.customerCardPerformance?.salesTW || '-'}</FooterItem>
          <FooterItem>Target: {customer.customerCardPerformance?.targetTW || '-'}</FooterItem>
          <FooterItem>Status: {customer.customerCardPerformance?.orderStatus || '-'}</FooterItem>
        </Footer>
        <AwrData awrData={awrData} kpiOption={kpiOption} position={index + 1} />
        <UpcomingOrders>
          <span>{translate('common.nextOrders')}:&nbsp;</span>
          {orderDates &&
            orderDates
              .slice(0, 4)
              .map((date, idx) => <OrderDate key={idx}>{moment(date).locale(lang).format('ddd MMM DD')}</OrderDate>)}
        </UpcomingOrders>
        <LinkOverlay to={`/customers/${customer.id}/calls`} onClick={onClick} />
        <LatestComments>
          <CommentOverlay to={`/customers/${customer.id}/calls/messages`} onClick={onClick} />
          <div style={{ flex: 1, minWidth: 0, flexGrow: 1 }}>
            {customer.messages?.length > 0 ? (
              customer.messages.slice(0, 2).map(({ id, author, text, createdAt }) => (
                <Comment key={id} half={customer.messages.length > 1}>
                  <span>{moment(createdAt).locale(lang).format('MMM D HH:mm')}</span>&nbsp;
                  <strong className="notranslate" translate="no">
                    {author ? startCase(`${author?.firstName.toLowerCase()} ${author?.lastName[0]}.`) : 'Admin'}
                  </strong>
                  &nbsp;
                  <CommentText className="notranslate" translate="no">
                    {text}
                  </CommentText>
                </Comment>
              ))
            ) : (
              <Comment>
                <NoComments>{translate('common.noComments')}</NoComments>
              </Comment>
            )}
          </div>
          <MessageStatus lastMessage={lastMessage} employee={employee} />
        </LatestComments>
      </Inner>
    </Container>
  )
}

CustomerCard.propTypes = {
  customer: object.isRequired,
  userLocation: object,
  style: object,
  onClick: func,
  setEmailRecipient: func,
  awrBrandOption: string,
  customerSortingOption: string,
  kpiOption: string.isRequired,
  index: number.isRequired,
  employee: object
}

export default connect((state) => ({ userLocation: state.auth.coords }))(CustomerCard)

export const CustomerInfoSheet = ({ customer }) => {
  const { translate, lang } = useContext(LangContext)

  const { primaryContacts, storeContacts } = groupBy(customer.storeContacts, ({ isPrimary }) =>
    isPrimary ? 'primaryContacts' : 'storeContacts'
  )

  return (
    <div>
      <CustomerDetailRow
        label={translate('customerInfo.storeNameErp')}
        content={
          <p className="notranslate" translate="no">
            {customer.name} <small>({customer.id})</small>
          </p>
        }
      />
      <CustomerDetailRow label={translate('common.address')} content={<CustomerAddress customer={customer} />} />
      <CustomerDetailRow
        label={translate('common.phoneNumber')}
        content={
          <a href={`tel:${formatPhone(customer.storePhoneNumber)}`}>
            <p className="notranslate" translate="no">
              {formatPhone(customer.storePhoneNumber)}
            </p>
          </a>
        }
      />
      <CustomerDetailRow
        label={translate('customerInfo.nationalChainNumber')}
        content={
          <p className="notranslate" translate="no">
            {customer.natChain}
          </p>
        }
      />
      <CustomerDetailRow
        label={translate('customerInfo.ownershipType')}
        content={<small>{customer.ownershipType}</small>}
      />
      <CustomerDetailRow label={translate('customerInfo.rcm')} content={<small>{customer.rcmRetailerClass}</small>} />
      <CustomerDetailRow
        label={translate('customerInfo.headofficeBanner')}
        content={
          <small>
            {customer.headoffice?.name || '-'} / {customer.banner?.name || '-'}
          </small>
        }
      />
      <CustomerDetailRow label={translate('customerInfo.carrier')} content={<small>{customer.carrier}</small>} />
      <CustomerDetailRow
        label={translate('customerInfo.posOutletIndicator')}
        content={<small>{customer.posIndicator}</small>}
      />
      {customer.partnershipType && (
        <CustomerDetailRow
          label={translate('customerInfo.partnershipType')}
          content={<small>{customer.partnershipType}</small>}
        />
      )}
      <CustomerDetailRow
        label={translate('customerInfo.nextOrderDays')}
        content={
          (customer.orderDates &&
            customer.orderDates.slice(0, 4).map((date, idx) => {
              return <OrderDate key={idx}>{moment(date).locale(lang).format('ddd MMM DD')}</OrderDate>
            })) ||
          null
        }
      />
      {customer.userQuota && (
        <CustomerDetailRow label={translate('customerInfo.userQuota')} content={<small>{customer.userQuota}</small>} />
      )}
      {!primaryContacts?.length && customer.primaryContact && (
        <CustomerDetailRow
          label={translate('customerInfo.primaryContact')}
          content={
            <small>
              {customer.primaryContact.firstname} {customer.primaryContact.lastname} (
              {customer.primaryContact.email && customer.primaryContact.email !== MISSING_EMAIL ? (
                <a href={`mailto:${customer.primaryContact.email}`}>{customer.primaryContact.email}</a>
              ) : (
                translate('customerInfo.noEmailProvided')
              )}
              )
            </small>
          }
        />
      )}
      {primaryContacts?.length &&
        primaryContacts.map((primaryContact, idx) => (
          <CustomerDetailRow
            key={`customer-primary-contacts-${idx}`}
            label={!idx && translate('customerInfo.primaryContact')}
            content={
              <small>
                {primaryContact.firstname} {primaryContact.lastname} (
                {primaryContact.email && primaryContact.email !== MISSING_EMAIL ? (
                  <a href={`mailto:${primaryContact.email}`}>{primaryContact.email}</a>
                ) : (
                  translate('customerInfo.noEmailProvided')
                )}
                )
              </small>
            }
          />
        ))}
      {storeContacts?.length &&
        storeContacts.map((storeContact, idx) => (
          <CustomerDetailRow
            key={`customer-primary-contacts-${idx}`}
            label={!idx && translate('customerInfo.storeContacts')}
            content={
              <small>
                {storeContact.firstname} {storeContact.lastname} (
                {storeContact.email && storeContact.email !== MISSING_EMAIL ? (
                  <a href={`mailto:${storeContact.email}`}>{storeContact.email}</a>
                ) : (
                  translate('customerInfo.noEmailProvided')
                )}
                )
              </small>
            }
          />
        ))}
    </div>
  )
}

CustomerInfoSheet.propTypes = {
  customer: object
}
