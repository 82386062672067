import React from 'react'
import { connect, useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
import { func } from 'prop-types'

import { updateCustomerScope } from 'store/customers/actions'
import * as customerSelector from 'store/customers/selectors'
import * as intelSelector from 'store/intel/selectors'

import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import FeedbackCard from 'components/FeedbackCard'

const IntelRequests = ({ updateCustomerScope }) => {
  const intel = useSelector((state) => intelSelector.intelRequiringActions(state))
  const scopes = useSelector((state) => customerSelector.groupedScopeConfirmations(state))

  if (!intel.length && !scopes.length)
    return <EmptyState title="No intel requests" subtitle="There are no requests for feedback at this time." />
  const inboxItems = orderBy([...intel, ...scopes], ({ updatedAt }) => new Date(updatedAt).valueOf(), 'desc')
  return (
    <Container>
      {inboxItems.map((item, idx) => (
        <FeedbackCard key={`${item.id}-${idx}`} inboxItem={item} updateCustomerScope={updateCustomerScope} />
      ))}
    </Container>
  )
}

IntelRequests.propTypes = {
  updateCustomerScope: func
}

const mapActionCreators = {
  updateCustomerScope
}

export default connect(null, mapActionCreators)(IntelRequests)
