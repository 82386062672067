import React, { useContext } from 'react'
import { number, shape } from 'prop-types'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import OrderRecap from 'components/evaluate/OrderRecap'

const OrderRecapCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  return (
    <Card title={translate('evaluate.orderRecap')} span={span} displayAmplify={false}>
      <OrderRecap />
    </Card>
  )
}

OrderRecapCard.propTypes = {
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

export default OrderRecapCard
