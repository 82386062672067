import React, { useContext } from 'react'
import { bool, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { CardContent, CardHeading, Data, HiddenIndicator } from '.'

export const PriceCheckData = ({ data, timeRange, hidden }) => {
  const { translate } = useContext(LangContext)
  return (
    <CardContent>
      <CardHeading>{translate('app.priceCheck')}</CardHeading>
      {hidden && <HiddenIndicator />}

      <div
        className={`${
          hidden ? 'pointer-events-none select-none blur' : ''
        } flex h-full w-full flex-col items-center gap-2`}
      >
        <Data
          size="small"
          data={data.priceCompliance}
          title={translate('app.priceCompliance')}
          additionalData={{
            type: 'variation',
            data: {
              variation: {
                amount: data.priceCompliance.variation,
                time: timeRange
              }
            }
          }}
        />

        <Data
          size="small"
          data={data.priceUpdate}
          title={translate('app.priceCapture')}
          additionalData={{
            type: 'variation',
            data: {
              variation: {
                amount: data.priceUpdate.variation,
                time: timeRange
              }
            }
          }}
        />

        <Data
          size="small"
          data={data.priceSign}
          title={translate('app.priceSign')}
          additionalData={{
            type: 'variation',
            data: {
              variation: {
                amount: data.priceSign.variation,
                time: timeRange
              }
            }
          }}
        />
      </div>
    </CardContent>
  )
}

PriceCheckData.propTypes = {
  data: {
    priceCompliance: {
      value: number,
      variation: number,
      isTracked: bool
    },
    priceUpdate: {
      value: number,
      variation: number,
      isTracked: bool
    },
    priceSign: {
      value: number,
      variation: number,
      isTracked: bool
    }
  },
  timeRange: string,
  hidden: bool
}

PriceCheckData.defaultProps = {
  data: {
    priceCompliance: {
      value: 100,
      isTracked: true
    },
    priceUpdate: {
      value: 0,
      isTracked: true
    },
    priceSign: {
      value: 0,
      isTracked: true
    }
  }
}
