import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { node } from 'prop-types'

const MediaContext = React.createContext()
export default MediaContext

export function MediaProvider({ children }) {
  const isWideLayout = useMediaQuery({
    minWidth: 900,
    minDeviceWidth: 900
  })

  const value = {
    isWideLayout
  }
  return <MediaContext.Provider value={value}>{children}</MediaContext.Provider>
}

MediaProvider.propTypes = {
  children: node.isRequired
}
