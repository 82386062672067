import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bool, func, object } from 'prop-types'

import * as authSelector from 'store/auth/selectors'
import { customerTerritory } from 'store/customers/selectors'
import { cancelCustomerSellInException } from 'store/sellInPrograms/actions'
import * as sellInSelector from 'store/sellInPrograms/selectors'

import GlobalAlert from 'components/GlobalAlert'
import { Modal } from 'components/Modal'
import Pill from 'components/Pill'
import ExceptionDetails from 'components/sellInPrograms/ExceptionDetails'
import Spinner from 'components/Spinner'

import { getExceptionType } from 'utils/formatters'

import { sellInStatusColors } from 'styles/colors'

export const UnConnectedSellInExceptionDetails = ({ exception, cancelCustomerSellInException, onCancelException }) => {
  const { sellInProgramId, customerId } = exception
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const preferredLanguage = useSelector((state) => authSelector.preferredLanguage(state))
  const territoryId = useSelector((state) => customerTerritory(state, { customerId }))
  const { sellInTarget, sellInProgram } = useSelector((state) =>
    sellInSelector.sellInProgramTarget(state, { sellInProgramId, customerId, territoryId })
  )

  const cancelException = () => {
    setError()
    setIsLoading(true)
    cancelCustomerSellInException(exception)
      .then(onCancelException)
      .catch((error) => {
        if (isMounted.current) {
          setIsLoading(false)
          setError(`Could not remove exception: ${error.message}`)
        }
      })
  }

  if (isLoading)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner icon="spinner" />
      </div>
    )

  return (
    <>
      {error && <GlobalAlert error>{error}</GlobalAlert>}
      <ExceptionDetails
        onCancelException={cancelException}
        exception={exception}
        sellInQuestions={sellInProgram?.questions}
        sellInTargetSkus={sellInTarget?.skus}
        preferredLanguage={preferredLanguage}
      />
    </>
  )
}

UnConnectedSellInExceptionDetails.propTypes = {
  cancelCustomerSellInException: func.isRequired,
  onCancelException: func.isRequired,
  exception: object.isRequired
}

const SellINExceptionDetails = connect(null, {
  cancelCustomerSellInException
})(UnConnectedSellInExceptionDetails)

const SellInExceptionModal = ({ exception, onHandleClose, open }) => {
  return (
    <Modal
      open={open}
      title={
        <div className="flex items-center gap-1">
          <Pill color={sellInStatusColors[exception.exceptionStatus]}>{exception.exceptionStatus}</Pill>
          {getExceptionType(exception)} Exception
        </div>
      }
      onHandleClose={onHandleClose}
    >
      <SellINExceptionDetails exception={exception} onCancelException={onHandleClose} />
    </Modal>
  )
}

SellInExceptionModal.propTypes = {
  exception: object.isRequired,
  onHandleClose: func.isRequired,
  open: bool
}

export default SellInExceptionModal
