import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import xorBy from 'lodash/xorBy'
import moment from 'moment'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { saveDelegation } from 'store/auth/actions'
import { getManagerSuggestions } from 'store/auth/endpoints'

import Button from 'components/button/Button'
import Container from 'components/Container'
import DatePicker from 'components/DatePicker'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import MobileHeader from 'components/MobileHeader'
import Select from 'components/Select'

import { small } from 'styles/spacing'

const Delegations = ({ saveDelegation }) => {
  const { translate } = useContext(LangContext)

  const user = useSelector(({ auth }) => auth.user)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [delegates, setDelegates] = useState([])
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)

  const maxDelegatesReached = delegates.length >= 2

  const loadManagerOptions = debounce((input, callback) => {
    if (maxDelegatesReached) {
      const emptyResponse = []
      callback(emptyResponse)
    } else {
      getManagerSuggestions(input).then(({ data }) => {
        callback(data)
      })
    }
  }, 200)

  useEffect(() => {
    if (user?.delegations?.length) {
      setStartDate(user.delegations[0].startDate)
      setEndDate(user.delegations[0].endDate)
      setDelegates(user.delegations)
    } else {
      setStartDate('')
      setEndDate('')
      setDelegates([])
    }
  }, [user?.delegations])

  const saveDisabled = useMemo(() => {
    const sameStartDate = user?.delegations?.[0]?.startDate === startDate
    const sameEndDate = user?.delegations?.[0]?.endDate === endDate
    const sameDelegates = xorBy(user?.delegations || [], delegates, 'id').length === 0
    const unchanged = sameStartDate && sameEndDate && sameDelegates
    const missingStartEnd = !startDate || !endDate
    return unchanged || missingStartEnd
  }, [startDate, endDate, delegates, user?.delegations])

  const onSaveDelegations = () => {
    if (saveDisabled) return
    setSuccess(false)
    setError(false)
    setLoading(true)
    saveDelegation(
      startDate,
      endDate,
      delegates.map(({ id }) => id)
    )
      .then((res) => {
        setSuccess(true)
        setError(false)
      })
      .catch(function (err) {
        setSuccess(false)
        setError(err.message)
      })
  }

  const onEndDateChange = (val) => {
    if (!val) return
    setSuccess(false)
    setEndDate(moment(val).endOf('date').format())
  }

  const onStartDateChange = (val) => {
    if (!val) return
    setSuccess(false)
    if (endDate < val) {
      setEndDate(null)
    }
    setStartDate(val)
  }

  const onDelegatesChange = (val) => {
    setSuccess(false)
    setDelegates(val)
  }

  const isValidNewOption = (inputValue, selectValue) => {
    return !maxDelegatesReached
  }

  return (
    <>
      <MobileHeader isNavOnly />
      <div className="w-full px-5 max-md:mt-16">
        <Container>
          <div className="mb-6 mt-4 block p-px max-md:mx-5">
            <Button tertiary to="../" icon="left-arrow">
              {translate('common.back')}
            </Button>
          </div>
          <Fieldset>
            <FieldsetItem half>
              <DatePicker label="Start date" value={startDate} onChange={onStartDateChange} />
            </FieldsetItem>
            <FieldsetItem half>
              <DatePicker
                label="End date"
                value={endDate}
                onChange={onEndDateChange}
                isDayBlocked={(date) => {
                  if (startDate) {
                    return new Date(date.startOf('date')) < new Date(startDate)
                  }
                  return false
                }}
              />
            </FieldsetItem>
            <FieldsetItem>
              <Select
                label="Employees"
                isAsync
                isMulti
                defaultOptions={!maxDelegatesReached}
                cacheOptions={!maxDelegatesReached}
                value={delegates}
                loadOptions={loadManagerOptions}
                isValidNewOption={isValidNewOption}
                noOptionsMessage={() => {
                  return maxDelegatesReached
                    ? 'You have the max number of delegates'
                    : 'No results. Search by ID, name or email.'
                }}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ firstName, lastName, id }) =>
                  `${id} - ${firstName.toUpperCase()} ${lastName.toUpperCase()}`
                }
                styles={{
                  control: (_) => ({
                    ..._,
                    padding: small
                  })
                }}
                onChange={onDelegatesChange}
              />
            </FieldsetItem>
            <FieldsetItem>
              <Button primary disabled={saveDisabled} onClick={onSaveDelegations} isLoading={loading}>
                Save delegation
              </Button>
            </FieldsetItem>
            {success && (
              <FieldsetItem>
                <GlobalAlert success>Saved successfully</GlobalAlert>
              </FieldsetItem>
            )}
            {error && (
              <FieldsetItem>
                <GlobalAlert>{error}</GlobalAlert>
              </FieldsetItem>
            )}
          </Fieldset>
        </Container>
      </div>
    </>
  )
}

Delegations.propTypes = {
  saveDelegation: func.isRequired
}

const mapActionCreators = {
  saveDelegation
}

export default connect(null, mapActionCreators)(Delegations)
