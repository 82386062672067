import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { connect } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import template from 'lodash/template'
import { node, string } from 'prop-types'
import en from 'translations/en.json'
import fr from 'translations/fr.json'

import { parseSearchQuery } from 'utils/helpers'

const LangContext = React.createContext()
export default LangContext

const translations = {
  en,
  fr
}

const validateLang = (lang) => {
  const validLangs = Object.keys(translations)
  const isValidLang = lang && validLangs.includes(lang)
  return isValidLang && lang
}

const LangProviderInner = ({ children, employeeLanguage }) => {
  const [searchParams] = useSearchParams()
  if (searchParams.has('utm_source')) {
    console.log('utm_source ', searchParams.get('utm_source'))
    ReactGA.default.event('utm_source', { value: searchParams.get('utm_source') })
  }
  if (searchParams.has('utm_medium')) {
    console.log('utm_medium ', searchParams.get('utm_medium'))
    ReactGA.default.event('utm_medium', { value: searchParams.get('utm_medium') })
  }
  if (searchParams.has('utm_campaign')) {
    console.log('utm_campaign ', searchParams.get('utm_campaign'))
    ReactGA.default.event('utm_campaign', { value: searchParams.get('utm_campaign') })
  }

  const location = useLocation()
  const localLang = sessionStorage.getItem('lang')

  const [lang, setLang] = useState(validateLang(localLang) || 'en')
  const [preferredLanguage, setPreferredLanguage] = useState(employeeLanguage)

  const langLabels = {
    en: 'English',
    fr: 'Français'
  }

  const setValidLang = (lang) => {
    const validLang = validateLang(lang)
    if (!validLang) return
    setLang(validLang)
    sessionStorage.setItem('lang', lang)
  }

  useEffect(() => {
    const { lang } = parseSearchQuery(location.search) || {}
    if (lang) setValidLang(lang)
  }, [location.search])

  useEffect(() => {
    if (employeeLanguage) setValidLang(employeeLanguage.substring(0, 2))
    setPreferredLanguage(employeeLanguage)
  }, [employeeLanguage])

  const translate = (key, variables) => {
    if (typeof key !== 'string') {
      console.error(`${key} cannot be translated. It must be a string.`)
      return key
    }

    const translated = translations[lang][key] ?? key
    if (!variables) return translated

    const compiled = template(translated)
    return compiled(variables)
  }

  const value = {
    lang,
    setLang: setValidLang,
    translate,
    preferredLanguage,
    langLabels
  }

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

LangProviderInner.propTypes = {
  children: node.isRequired,
  employeeLanguage: string
}

export const LangProvider = connect((state) => ({ employeeLanguage: state.auth?.user?.preferredLanguage }))(
  LangProviderInner
)
