import React, { useContext } from 'react'
import { bool, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { CardContent, CardHeading, Data, HiddenIndicator } from '.'

export const EngagementData = ({ data, timeRange, hidden }) => {
  const { translate } = useContext(LangContext)
  return (
    <CardContent>
      <CardHeading>{translate('app.extraHubActivity')}</CardHeading>
      {hidden && <HiddenIndicator />}
      <div
        className={`${hidden ? 'pointer-events-none select-none blur' : ''} flex h-full w-full flex-col items-center`}
      >
        <Data
          size="small"
          data={data}
          title="Extra Hub"
          additionalData={{
            type: 'variation',
            data: {
              variation: {
                amount: data.variation,
                time: timeRange
              }
            }
          }}
        />
      </div>
    </CardContent>
  )
}

EngagementData.propTypes = {
  data: {
    value: number,
    variation: number,
    isTracked: bool
  },
  timeRange: string,
  hidden: bool
}

EngagementData.defaultProps = {
  data: {
    value: 0,
    variation: 0,
    isTracked: true
  }
}
