import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { node, string } from 'prop-types'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Icon from 'components/Icon'

// //////////////////////////////////////////////////////////////
// PLACEHOLDER TABLE
// //////////////////////////////////////////////////////////////

const PlaceholderTable = () => {
  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full rounded">
        <thead>
          <tr className="border-b *:border-r *:px-1 *:pt-1 *:text-left *:text-2xs *:font-medium *:uppercase *:text-slate-500 first:*:pl-2 last:*:border-r-0 last:*:pr-2">
            <th>Top 5</th>
            <th>Actuals (CTD)</th>
            <th>Avg Orders (L13)</th>
            <th>Avg Orders (L4)</th>
            <th>Avg Sales (L4)</th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, i) => (
            <tr
              key={i}
              className="border-b *:whitespace-nowrap *:border-r *:px-1 *:text-left *:text-2xs *:text-slate-900 first:*:pl-2 last:border-b-0 last:*:border-r-0 last:*:pb-1 last:*:pr-2"
            >
              <td>JP Rich KS 25</td>
              <td>100</td>
              <td>60</td>
              <td>70</td>
              <td>60</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const AccordionItem = ({ category, summary, children }) => {
  return (
    <Accordion.Item value={category} className="divide-y divide-slate-900/10 overflow-hidden">
      <Accordion.Trigger className="group flex w-full items-center justify-between gap-2 bg-slate-50 px-2 py-1 text-left font-medium text-slate-900 hover:bg-slate-100">
        <p className="text-slate-700">
          <span className="mr-1.5 font-semibold uppercase text-slate-900">{category}</span> {summary}
        </p>
        <div className="relative size-5 shrink-0">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform group-data-[state=open]:rotate-180">
            <Icon icon="down-chevron" compact />
          </div>
        </div>
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down ">
        {children}
      </Accordion.Content>
    </Accordion.Item>
  )
}

AccordionItem.propTypes = {
  category: string.isRequired,
  summary: string.isRequired,
  children: node.isRequired
}

const SectionCard = ({ title, tip, data, amplifyLink }) => {
  return (
    <div className="min-w-0 max-w-full rounded-md bg-white p-3 shadow-sm ring-1 ring-slate-900/10">
      <div className="flex w-full items-center justify-between gap-2">
        <h4 className="text-base font-medium text-slate-900 md:text-lg">{title}</h4>
        {amplifyLink && (
          <>
            <div className="max-md:hidden">
              <Button primary icon="three-stars" to={amplifyLink}>
                Amplify
              </Button>
            </div>
            <div className="md:hidden">
              <IconButton primary icon="three-stars" compact to={amplifyLink}>
                Amplify
              </IconButton>
            </div>
          </>
        )}
      </div>
      <hr className="my-2" />
      <div className="space-y-2">
        {tip && <p className="font-medium text-slate-900">{tip}</p>}

        <Accordion.Root
          type="single"
          collapsible
          className="divide-y divide-slate-900/10 rounded-md border border-slate-900/10"
        >
          <AccordionItem category="fmc" summary={data?.fmc?.summary}>
            <PlaceholderTable />
          </AccordionItem>

          <AccordionItem category="vape" summary={data?.vape?.summary}>
            <PlaceholderTable />
          </AccordionItem>
        </Accordion.Root>
      </div>
    </div>
  )
}

export default SectionCard

SectionCard.propTypes = {
  title: string.isRequired,
  tip: string.isRequired,
  data: node.isRequired,
  amplifyLink: string
}
