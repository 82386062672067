import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import config from 'config'
import InlineSVG from 'svg-inline-react'

import GAnalyticsContext from 'context/GAnalyticsContext'
import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'

import { AdvanceLogotype } from 'components/AdvanceLogotype'

import BatLogo from 'static/logos/bat-logo.svg'

import Profile from './Profile'
import SidebarLink from './SidebarLink'

const Sidebar = () => {
  const { translate } = useContext(LangContext)
  const { isWideLayout } = useContext(MediaContext)
  const { addUsageEvent } = useContext(GAnalyticsContext)

  const { user } = useSelector((state) => state.auth)
  const isCustomerService = user && user.groupCode === 'customerService'

  if (!isWideLayout) return null
  return (
    <nav className="sticky flex min-h-screen w-[220px] shrink-0 flex-col overflow-y-scroll bg-brand p-6 pt-0 text-white scrollbar-hide max-md:hidden">
      <AdvanceLogotype />
      <div className="mt-6 flex h-full flex-col justify-between">
        {/* Main links */}
        <div className="space-y-5">
          {!isCustomerService && (
            <>
              <div className="space-y-3">
                <span className="ml-1 text-xs font-medium uppercase text-white/60">Pace</span>
                <div className="space-y-1">
                  <SidebarLink
                    name={translate('app.pace.plan')}
                    url="/pace/plan"
                    icon="dashboard"
                    onClick={() => addUsageEvent('sidebar_plan_click')}
                  />
                  <SidebarLink
                    name={translate('app.pace.amplify')}
                    url="/pace/amplify"
                    icon="graph-window"
                    onClick={() => addUsageEvent('sidebar_amplify_click')}
                  />
                  <SidebarLink
                    name={translate('app.pace.close')}
                    url="/pace/close"
                    icon="checklist"
                    onClick={() => addUsageEvent('sidebar_close_click')}
                  />
                  <SidebarLink
                    name={translate('app.pace.evaluate')}
                    url="/pace/evaluate"
                    icon="clipboard"
                    disabled={!config.featureFlags.evaluate}
                    onClick={() => addUsageEvent('sidebar_evaluate_click')}
                  />
                </div>
              </div>
              <hr className="w-full bg-white opacity-10" />
            </>
          )}
          <div className="space-y-1">
            <SidebarLink
              name={translate('app.actions')}
              url="/actions"
              icon="actions"
              onClick={() => addUsageEvent('sidebar_actions_click')}
            />
            <SidebarLink
              name={translate('app.alerts')}
              url="/alerts"
              icon="notification"
              onClick={() => addUsageEvent('sidebar_alerts_click')}
            />
            <SidebarLink
              name={translate('app.schedule')}
              url="/schedule"
              icon="calendar"
              onClick={() => addUsageEvent('sidebar_schedule_click')}
            />
            <SidebarLink
              name={translate('app.payouts')}
              url="/payouts"
              icon="stacked-coins"
              onClick={() => addUsageEvent('sidebar_payouts_click')}
            />
          </div>
        </div>

        {/* User links */}
        <div className="mt-8 flex flex-col gap-5">
          <Profile portal />
          <hr className="w-full bg-white opacity-10" />
          <div className="-mt-2 flex shrink-0 items-center justify-center rounded py-4">
            {/* <div className="-ml-3 -mt-1"> */}
            <InlineSVG src={BatLogo} className="h-[35px] w-[120]" raw />
            {/* </div> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Sidebar
