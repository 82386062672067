import styled from 'styled-components'

import { red } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

export default styled.small`
  color: ${red};
  display: block;
  margin-top: ${spacing.small};
  font-size: ${tinyFontSize};
`
