import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import moment from 'moment'

import { createSelector } from 'store/selectors'

const allSurveys = (state) => omit(state.surveys, '_persist')

export const surveyIdFromUrl = (state, props) => props.surveyId
export const customerIdFromProps = (state, props) => props.customerId || null

export const surveyFromUrl = createSelector(surveyIdFromUrl, allSurveys, (surveyId, surveys) => {
  const { questions, ...survey } = surveys[surveyId] || {}
  return {
    survey,
    questions: orderBy(questions, 'sequence')
  }
})

// export const surveyFromUrl = createSelector(
//   surveyIdFromUrl,
//   allSurveys,
//   (surveyId, surveys) => {
//     const { questions, ...survey } = surveys[surveyId]
//     const customer = customers[customerId] || {}
//     const { answers, ...customerSurvey } = customer.surveys.find(cs => cs.surveyId === surveyId) || {}
//     return {
//       survey,
//       questions: orderBy(questions, 'sequence'),
//       answers: groupBy(answers || {}, 'questionId'),
//       customerSurvey
//     }
//   }
// )

export const allOpenSurveys = createSelector(allSurveys, (surveys) =>
  Object.values(surveys).filter(({ isOpen, startDate, endDate }) => isOpen && moment().isBetween(startDate, endDate))
)

export const autofillInitialValues = createSelector(surveyFromUrl, ({ questions }) => {
  return questions.reduce((acc, { id, type, defaultAnswer, options }) => {
    const defaultOptions = options?.filter(({ isDefault }) => isDefault).map(({ id }) => id)
    return {
      ...acc,
      [id]: type === 'multi' ? defaultOptions : type === 'dropdown' ? defaultOptions[0] : defaultAnswer
    }
  }, {})
})
