import React, { useContext, useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import inRange from 'lodash/inRange'
import { ellipsis, stripUnit } from 'polished'
import { func, object } from 'prop-types'
import styled from 'styled-components'

import CallDurationContext from 'context/CallDurationContext'

import { getTimeStringFromSeconds } from 'utils/helpers'

import { black, calendarEventColors } from 'styles/colors'
import { borderRadius, boxShadow, tabBarHeight } from 'styles/global'
import * as spacing from 'styles/spacing'
import { fontSemibold, H1 } from 'styles/typography'

const minDragOffset = 3

const Container = styled.button`
  position: fixed;
  display: flex;
  align-items: center;
  bottom: calc(${tabBarHeight} + ${spacing.medium});
  left: ${spacing.medium};
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${spacing.medium};
  border-radius: ${borderRadius};
  z-index: 400;
  background-color: ${(props) => props.color || '#f7b34d'};
  text-align: left;
  ${boxShadow};
`

const Main = styled.div`
  padding-right: ${spacing.large};
`

const Title = styled.span`
  display: block;
  color: ${black};
  font-weight: ${fontSemibold};
`

const Duration = styled(H1)`
  color: ${black};
`

const Store = styled.small`
  color: ${black};
  ${ellipsis('150px')};
`

const CallDuration = (props) => {
  const { ongoingCallSecondsElapsed } = useContext(CallDurationContext)
  return <Duration>{getTimeStringFromSeconds(ongoingCallSecondsElapsed)}</Duration>
}

const MinifiedCall = ({ call, setIsMinimized }) => {
  const [beingDragged, setBeingDragged] = useState(false)
  const [bounds, setBounds] = useState()
  const [touchStartPosition, setTouchStartPosition] = useState()
  const ref = useRef()
  // const dragRef = useRef()
  const mounted = useRef(false)

  useEffect(() => {
    const { left, right, top, bottom } = ref.current.getBoundingClientRect()
    mounted.current = true
    setBounds({
      top: 0 - top,
      left: 0 - left,
      right: window.innerWidth - right,
      bottom: window.innerHeight - bottom
    })

    return () => {
      mounted.current = false
    }
  }, [])

  let clickDisableTimeout

  const handleDragStart = () => {
    setTouchStartPosition(ref.current.getBoundingClientRect())
    clearInterval(clickDisableTimeout)
    clickDisableTimeout = setTimeout(() => {
      if (mounted.current) setBeingDragged(true)
    }, 100)
  }

  const handleDragStop = (e) => {
    const { left, top } = ref.current.getBoundingClientRect()
    if (
      inRange(left, left - minDragOffset, left + minDragOffset) &&
      inRange(top, top - minDragOffset, touchStartPosition.top + minDragOffset)
    ) {
      if (mounted.current) {
        setBeingDragged(false)
        handleClick()
      }
    }
    clickDisableTimeout = setTimeout(() => {
      if (mounted.current) setBeingDragged(false)
    }, 200)
  }

  const handleClick = () => {
    if (beingDragged || !mounted.current) return
    setIsMinimized(false)
  }

  return (
    <Draggable bounds={bounds} onStart={handleDragStart} onStop={handleDragStop}>
      <Container ref={ref} onClick={handleClick} color={calendarEventColors[call.type]}>
        <Main>
          <Title>Ongoing Call</Title>
          <div
            style={{
              display: 'flex'
            }}
          >
            <Store>{call.customer.name}</Store>
          </div>
        </Main>
        <CallDuration />
      </Container>
    </Draggable>
  )
}

MinifiedCall.propTypes = {
  call: object.isRequired,
  setIsMinimized: func.isRequired
}

export default MinifiedCall
