import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CustomerSurveys from 'views/Customer/CustomerSurveys'

import TerritorySurveys from 'components/TerritorySurveys'

import { SECTOR_LEVELS } from 'utils/constants'

const SurveysActionSwitcher = () => {
  const { sectorType } = useParams()

  useEffect(() => {
    document.title = 'Actions - Surveys'
  }, [])

  return (
    <>
      {sectorType === SECTOR_LEVELS.CUSTOMER && <CustomerSurveys />}
      {sectorType === SECTOR_LEVELS.TERRITORY && <TerritorySurveys />}
    </>
  )
}

export default SurveysActionSwitcher
