import React, { useContext, useEffect, useState } from 'react'

import LangContext from 'context/LangContext'

import CheckForValidScope from 'components/guards/CheckForValidScope'

import { SECTOR_LEVELS } from 'utils/constants'

import FMCOpportunityCard from './FMCOpportunityCard'
import OpportunitiesModal from './OpportunitiesModal'
import TotalOpportunitiesCard from './TotalOpportunitiesCard'
import VAPEOpportunityCard from './VAPEOpportunityCard'

const OpportunitiesView = () => {
  const { translate } = useContext(LangContext)

  const [isOpportunitiesModalOpen, setIsOpportunitiesModalOpen] = useState(true)

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  useEffect(() => {
    document.title = 'Close - Close Opportunities'
  }, [])

  return (
    <CheckForValidScope pageName={translate('close.closeOpportunities')} validScopes={validScopes}>
      <div className="grid w-full grid-cols-12 gap-5 max-md:pt-20">
        <FMCOpportunityCard span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} />
        <VAPEOpportunityCard span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} />
        <TotalOpportunitiesCard span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} />
        <OpportunitiesModal open={isOpportunitiesModalOpen} onOpenChange={setIsOpportunitiesModalOpen} />
      </div>
    </CheckForValidScope>
  )
}

export default OpportunitiesView
