import React from 'react'
import { any, func, object } from 'prop-types'

const getLabel = ({ column, label, labelGenFn }) => {
  if (typeof label === 'string') return label
  if (labelGenFn) return labelGenFn?.(column)
  return column.name
}
const TextCell = ({ column, label, labelGenFn }) => {
  const lLabel = getLabel({ column, label, labelGenFn })
  return <th>{lLabel}</th>
}
TextCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  label: any,

  /**
   * An optional function that should provide the label that will be displayed inside the cell.
   * If not provided, the label is going to be the value of the record value matching the column name.
   * If no such value exist, the label will be the column name
   * @param {object} column The definition of the column the cell is going to be rendered into
   * @returns {string} The label that will be displayed in the cell
   */
  labelGenFn: func
}

export default TextCell
