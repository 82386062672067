import React, { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { sum, sumBy } from 'lodash'
import pickBy from 'lodash/pickBy'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as customerSelectors from 'store/customers/selectors'
import { isPo98Customer } from 'store/customers/selectors'
import * as orderSelectors from 'store/orders/selectors'
import { getRegion } from 'store/territories/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import { Modal } from 'components/Modal'
import PO98ActionSheet from 'components/saq/PO98ActionSheet'
import SAQActionSheet from 'components/saq/SAQActionSheet'

function linkQuantities({ products, quantities }) {
  return products.reduce((acc, product) => {
    if (!quantities[product.id]) return acc
    return [...acc, { product: product.englishName, qty: quantities[product.id] }]
  }, [])
}

function buildReceiptByType({ products, values }) {
  const fmcProducts = products.fmc.map((brand) => brand.data).flat()
  const vapeProducts = products.vape.map((brand) => brand.data).flat()
  const quantities = pickBy(values, (val) => val > 0)

  return [
    {
      type: 'FMC',
      data: linkQuantities({ products: fmcProducts, quantities })
    },
    {
      type: 'VAPE',
      data: linkQuantities({ products: vapeProducts, quantities })
    }
  ]
}

const PlaceOrder = ({ products }) => {
  const navigate = useNavigate()
  const { sectorId: customerId } = useParams()

  const { translate } = useContext(LangContext)
  const { currentSector: customer } = useContext(SectorContext)
  const { values, isSubmitting } = useFormikContext()

  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const isPO98Order = useSelector((state) => isPo98Customer(state, { customerId }))
  const saqSoxMaterialGroupIds = useSelector((state) => orderSelectors.saqSoxMaterialGroupIds(state, { customerId }))
  const bannerOwnership = useSelector((state) => customerSelectors.getBannerOwnership(state, { customerId }))
  const region = useSelector((state) => getRegion(state, {}))
  const orderComplianceThresholds = useSelector((state) =>
    customerSelectors.orderComplianceThresholds(state, { customerId })
  )

  const [summaryOpen, setSummaryOpen] = useState(false)
  const [checkoutOpen, setCheckoutOpen] = useState(false)

  const receipt = buildReceiptByType({ products, values: values.entries })
  const totalQuantity = sumBy(receipt.map((r) => r.data).flat(), 'qty')

  const handleCheckout = () => {
    setSummaryOpen(false)
    setCheckoutOpen(true)
  }

  const handleCompleteOrder = () => {
    setCheckoutOpen(false)
    navigate('..')
  }

  const FooterContent = () => (
    <div className="flex w-full justify-end">
      <div className="flex shrink-0 gap-3">
        <Button secondary onClick={() => setSummaryOpen(false)}>
          Go Back
        </Button>
        <Button primary endIcon="right-arrow" onClick={handleCheckout}>
          Checkout
        </Button>
      </div>
    </div>
  )

  const isEmergencyOrder = useMemo(
    () => ['order', 'emergency'].includes(values.type) || values.emergencyFlag,
    [values.emergencyFlag, values.type]
  )
  const threshold = isEmergencyOrder ? orderComplianceThresholds.emergency : orderComplianceThresholds.saq

  const requiresApproval = useMemo(() => {
    if (isPO98Order) return false
    const validProducts = pickBy(values, (value, key) => saqSoxMaterialGroupIds.includes(key.replace('p', '')))
    const saqSoxMaterialGroupQuantity = sum(Object.values(validProducts))
    const isOverThreshold = Boolean(saqSoxMaterialGroupQuantity) && saqSoxMaterialGroupQuantity >= threshold

    const isOrderApprovalExempt =
      customer.orderApprovalExempt || bannerOwnership?.orderApprovalExempt || region?.orderApprovalExempt
    return isOverThreshold && !isOrderApprovalExempt
  }, [values])

  return (
    <>
      <Modal
        trigger={
          <Button primary endIcon="right-arrow">
            {translate('common.checkout')}
          </Button>
        }
        title="Cart Summary"
        subtitle="Make sure you have everything before you checkout"
        footer={<FooterContent />}
        open={summaryOpen}
        onOpenChange={setSummaryOpen}
      >
        <table className="w-full text-sm">
          <thead>
            <tr className="*:font-medium *:uppercase *:text-slate-500">
              <th>Product</th>
              <th className="text-right">Qty</th>
            </tr>
          </thead>
          <tbody>
            {receipt.map((order) => {
              const totalQty = order.data.reduce((sum, item) => sum + item.qty, 0)

              return (
                <React.Fragment key={order.type}>
                  <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
                    <td>{order.type}</td>
                    <td className="text-right">{totalQty}</td>
                  </tr>
                  {order.data.map((data) => (
                    <tr key={data.product} className="*:py-1 *:text-slate-700">
                      <td>{data.product}</td>
                      <td className="text-right">{data.qty}</td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </Modal>
      <ActionSheet
        title={'Complete Order'}
        visible={checkoutOpen}
        onOverlayClick={() => setCheckoutOpen(false)}
        action={<button onClick={() => setCheckoutOpen(false)}>{translate('common.cancel')}</button>}
      >
        <div className="flex flex-col gap-4">
          <div>{translate('close.placeOrder.PO98orSAQ')}</div>

          {isPO98Order ? (
            <PO98ActionSheet
              customer={customer}
              onClose={() => setCheckoutOpen(false)}
              submitting={isSubmitting}
              isMobileViewOnly={isMobileViewOnly}
              isEdit={false}
              totalQuantity={totalQuantity}
            />
          ) : (
            <SAQActionSheet
              customer={customer}
              isEmergencyOrder={false}
              requiresApproval={requiresApproval}
              threshold={threshold}
              onClose={() => setCheckoutOpen(false)}
              submitting={isSubmitting}
              isMobileViewOnly={isMobileViewOnly}
              showSecondEmergencyOrderWarning={false}
              isEdit={false}
              totalQuantity={totalQuantity}
              qtyToRemove={0}
              visible={true}
            />
          )}

          <div>
            <Button primary onClick={handleCompleteOrder}>
              {translate('common.completeOrder')}
            </Button>
          </div>
        </div>
      </ActionSheet>
    </>
  )
}

PlaceOrder.propTypes = {
  products: object
}

export default PlaceOrder
