import config from 'config'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import snakeCase from 'lodash/snakeCase'
import sortBy from 'lodash/sortBy'
import startCase from 'lodash/startCase'

export const STRESS_TEST_AMOUNT = 0
export const DEFAULT_TTL = 90
export const DEFAULT_STORE_SORT = 'performance'
export const DEFAULT_AWR_BRAND = 'BAT'
export const MIN_PRICE_UPDATE = '2020-03-22'
export const DOUBLE_NESTED_BRANDS = ['Vype', 'Vuse']
export const DEFAULT_TABLE_PAGE_SIZE = 10
// Sell In Program Exception Types
export const STATUS = 'STATUS'
export const AGREEMENT_TERMS = 'AGREEMENT TERMS'

export const EMERGENCY_ORDER_MIN_CTNS = {
  horeca: 10,
  vapeShop: 10,
  vapeShopECommerce: 10,
  parklandOtp: 10,
  default: 20
}

export const MAX_ZONNIC_CTNS = 10
export const getMinEmergencyOrderCtns = (customer) => {
  // EMERGENCY override logic goes here.

  // REGULAR LOGIC
  const minByOutletSubtype = customer.outletSubtype && EMERGENCY_ORDER_MIN_CTNS[camelCase(customer.outletSubtype)]
  return minByOutletSubtype || EMERGENCY_ORDER_MIN_CTNS.default
}

export const ZONNIC_MATERIAL_GROUP_CODE = 'ZFG135003'
// export const SOX_COMPLIANT_MATERIAL_GROUPS = ['FG CIGARETTES', 'FG VAPOUR CARTOMISER', 'FG VAPOUR STARTERKIT', 'FG Vapour Disposable', 'FG Oral Nic Pouch']
export const SOX_COMPLIANCE_MATERIAL_GROUP_CODES = [
  'ZFG100000',
  'ZFG431000',
  'ZFG439003',
  'ZFG439006',
  ZONNIC_MATERIAL_GROUP_CODE
]

export const DEFAULT_TERRITORY_PERF_SEQUENCE = ['sellIn', 'inventoryManagement', 'sellOut']
export const THP_PERF_COLS = ['lw', 'AWR L4', 'AWR P4', 'ytd']
export const VAPE_PERF_COLS = ['lw', 'Curr\nCycle\nAWR', 'Last\nCycle\nAWR', 'ytd']

export const TWO_PACK_PROGRAM = '2-pack program'
export const TWO_PACK_BANDED_DUOS = '2-pack banded duos'
export const COMMERCIAL_AND_GROWTH = 'Commercial & Growth Programs'
export const BRAND_LAUNCHES_MIGRATIONS = 'Brand Launches & Migrations'
export const RETAIL_ACTIVATION = 'Retail Activation (POS, Price Sign, Contracts, etc)'
export const DIGITAL_B2B = 'Digital B2B'
export const HORECA_ACTIVATION = 'Horeca Activation'
export const ILLICIT_TRADE = 'Illicit Trade'
export const PRODUCT_REDESIGN = 'Pack / Product redesign'
export const LEP = 'LEP (Limited Edition Product)'
export const VAPE_SPECIALTY_STORE = 'Vape specialty store'

export const PMI = 'PMI'
export const JTI = 'JTI'
export const JUUL = 'Juul Labs'
export const NONCTMC = 'NON-CTMC'
export const OTHER = 'Other'
export const SESH = 'Sesh'
const OTHER_PLEASE_SPECIFY = 'Other (Please specify)'

// export const PLOOMTECH = 'ploom TECH/Vapeleaf'
export const IQOS = 'iQOS / HEETS'

// export const HYBRID = 'Hybrid'
export const THP = 'THP'
export const CIGARETTES = 'Cigarettes'
export const VAPE = 'Vape'
export const PAPERS = 'Papers'
export const FINE_CUT = 'Fine Cut'
export const NRT = 'NRT'

export const IN_PERSON_LOCATION_ERRORS = {
  denied: 'You must enable location tracking in NOD settings and on your device.',
  outsideRadius: 'You are too far to start an in-person visit. Move closer to the store.',
  innaccurateLocation: 'Your location is innacurate, refresh the page to refresh your location.'
}

export const IN_PERSON_LOCATION_WARNINGS = {
  unavailable:
    'The acquisition of the geolocation failed because at least one internal source of position returned an internal error',
  timeout: 'It took too long to get your location.'
}

export const types = [
  TWO_PACK_PROGRAM,
  TWO_PACK_BANDED_DUOS,
  COMMERCIAL_AND_GROWTH,
  BRAND_LAUNCHES_MIGRATIONS,
  RETAIL_ACTIVATION,
  DIGITAL_B2B,
  HORECA_ACTIVATION,
  ILLICIT_TRADE,
  PRODUCT_REDESIGN,
  LEP
  // VAPE_SPECIALTY_STORE
]

export const sources = [
  'Voluntarily provided by retailer',
  'In-store display',
  'POS material openly visible',
  'Price scan',
  'Competitor Website'
]

export const brandsByManufacturerAndCategory = {
  [CIGARETTES]: {
    [PMI]: [
      'Belmont',
      'Canadian Classics',
      'Next',
      'Philip Morris',
      'Number 7',
      'Quebec Classique',
      'Rothman Std',
      'B&H',
      'Mark Ten'
    ],
    [JTI]: ['Export A', 'Macdonald Special', 'LD', 'Camel North'],
    [OTHER]: [OTHER]
  },
  // [HYBRID]: {
  //   [JTI]: [PLOOMTECH]
  // },
  [THP]: {
    [PMI]: [IQOS]
  },
  [VAPE]: {
    [JUUL]: ['Juul'],
    [JTI]: ['Logic'],
    STLTH: ['STLTH'],
    // 'Imperial Brands': ['Blue'],
    'Myle Vape': ['Myle'],
    Allo: ['Allo'],
    Relx: ['Relx'],
    [PMI]: ['VEEV', 'VEEBA'],
    Envi: ['Envi'],
    Ghost: ['Ghost'],
    Boosted: ['Boosted'],
    'Mr. Fog': ['Mr. Fog'],
    [OTHER]: [OTHER]
  },
  [NRT]: {
    'J&J': ['Nicorette'],
    [SESH]: ['Sesh Nicotine Gum']
  },
  [PAPERS]: {
    [OTHER]: [OTHER]
  },
  [FINE_CUT]: {
    [OTHER]: [OTHER]
  },
  [OTHER]: {
    [OTHER]: [OTHER]
  }
}

export const productCategories = Object.keys(brandsByManufacturerAndCategory).map((category) => ({
  label: category === OTHER ? OTHER_PLEASE_SPECIFY : category,
  value: category
}))

export const ALLOWED_PROGRAM_BRANDS_BY_LANGUAGE = {
  en: ['du Maurier Core', 'John Player Plus', 'John Player Standard', 'Marlboro VFM', 'Pall Mall', 'Vype', 'Vuse'],
  fr: [
    'du Maurier Gamme Principale',
    'John Player Plus',
    'John Player Standard',
    'Marlboro CT',
    'Pall Mall',
    'Vype',
    'Vuse'
  ]
}

export const PROGRAM_SPECIFIC_BRANDS = ['Marlboro VFM'] // Brands that should show up in the program form but don't have any products in the DB

export const getCategoryManufacturers = (category) =>
  category ? Object.keys(brandsByManufacturerAndCategory[category]) : []

const commonRequiredFields = {
  productCategory: 'Product Category',
  manufacturer: 'Manufacturer',
  brand: 'Brand',
  type: 'CI Type',
  source: 'Source',
  customBrand: 'Custom Brand'
}

const commonOptionalFields = {
  startDate: 'Start date',
  endDate: 'End date',
  impactRating: 'Impact Rating',
  plainPack: 'Plain pack',
  covid: 'Covid',
  regulations: 'Regulations'
}

export const twoPackFields = {
  mfctRebate: 'Manuf Rebate (per ctn)',
  rspDiscount25: 'Total savings on a 2-pk 25s',
  rspDiscount20: 'Total savings on a 2-pk 20s'
}

export const commercialGrowthFields = {
  offInvoice: 'Off-invoice per ctn',
  backMargin: 'Back margin per ctn',
  isAppliedToRsp: 'Applied to RSP?'
}

export const vapeSpecialtyFields = {
  storeName: 'Store name'
}

export const textFields = {
  impactSummary: 'Impact summary',
  details: 'Details'
}

export const prepopulatedFieldLabels = {
  ...commonRequiredFields,
  ...twoPackFields,
  ...commercialGrowthFields,
  ...vapeSpecialtyFields,
  ...commonOptionalFields
}

export const allFieldLabels = {
  ...prepopulatedFieldLabels,
  ...textFields,
  comments: 'Vape Store Comments'
}

export const fieldOrder = Object.keys(allFieldLabels)

export const clarifyDetailsFieldLabels = (type) => {
  let additionalFields = {}
  switch (type) {
    case TWO_PACK_PROGRAM:
    case TWO_PACK_BANDED_DUOS:
      additionalFields = twoPackFields
      break
    case COMMERCIAL_AND_GROWTH:
      additionalFields = commercialGrowthFields
      break
    case VAPE_SPECIALTY_STORE:
      additionalFields = vapeSpecialtyFields
      break
    default:
      additionalFields = { ...twoPackFields, ...commercialGrowthFields, ...vapeSpecialtyFields }
  }

  return {
    ...additionalFields,
    ...commonOptionalFields,
    ...textFields
  }
}

export const filterAllowedRequiredValues = (type) => Object.keys(clarifyDetailsFieldLabels(type))

export const imageMimeTypeToExtension = {
  'image/bmp': 'bmp',
  'image/prs.btif': 'btif',
  'image/cgm': 'cgm',
  'image/x-cmx': 'cmx',
  'image/vnd.djvu': 'djvu',
  'image/vnd.dwg': 'dwg',
  'image/vnd.dxf': 'dxf',
  'image/vnd.fastbidsheet': 'fbs',
  'image/x-freehand': 'fh',
  'image/vnd.fpx': 'fpx',
  'image/vnd.fst': 'fst',
  'image/g3fax': 'g3',
  'image/gif': 'gif',
  'image/x-icon': 'ico',
  'image/ief': 'ief',
  'image/jpeg': 'jpg',
  'image/x-citrix-jpeg': 'jpg',
  'image/ktx': 'ktx',
  'image/vnd.ms-modi': 'mdi',
  'image/vnd.fujixerox.edmics-mmr': 'mmr',
  'image/vnd.net-fpx': 'npx',
  'image/x-portable-bitmap': 'pbm',
  'image/x-pcx': 'pcx',
  'image/x-portable-graymap': 'pgm',
  'image/x-pict': 'pic',
  'image/pjpeg': 'pjpeg',
  'image/png': 'png',
  'image/x-citrix-png': 'png',
  'image/x-png': 'png',
  'image/x-portable-anymap': 'pnm',
  'image/x-portable-pixmap': 'ppm',
  'image/vnd.adobe.photoshop': 'psd',
  'image/x-cmu-raster': 'ras',
  'image/x-rgb': 'rgb',
  'image/vnd.fujixerox.edmics-rlc': 'rlc',
  'image/vnd.dvb.subtitle': 'sub',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/vnd.dece.graphic': 'uvi',
  'image/vnd.wap.wbmp': 'wbmp',
  'image/webp': 'webp',
  'image/x-xbitmap': 'xbm',
  'image/vnd.xiff': 'xif',
  'image/x-xpixmap': 'xpm',
  'image/x-xwindowdump': 'xwd'
}

export const KPI_OPTIONS = {
  all: [
    {
      label: 'Shr Chg',
      value: 'posShareChgRank'
    },
    {
      label: 'Shr',
      value: 'posShareRank'
    },
    {
      label: '$ AWR',
      value: 'rank'
    }
  ],
  BAT: [
    {
      label: 'Shr Target',
      value: 'posShareGap'
    }
  ]
}

export const TEAMS_INVITE_TITLE = {
  en: 'Imperial Tobacco Canada Virtual Trade Visit with ',
  fr: 'Visite virtuelle commerciale d’Imperial Tobacco Canada avec '
}

export const TEAMS_INVITE_CONTENT = {
  en: `Please accept the following request to confirm your virtual meeting with your ITCO sales representative. - Our sales team is happy to accommodate you and communicate with you on the communication platform of your choice. - The information in this message is potentially subject to solicitor client or other legal privilege and confidential. In the event of a transmission error and if you are not the individual or entity mentioned above, you are hereby advised that any use, copying and reproduction of this message is strictly forbidden. Please advise us of the error and destroy this message.`,
  fr: `Veuillez accepter la requête suivante afin de confirmer votre réunion virtuelle avec votre représentant ITCO. - Notre équipe des ventes sera donc heureuse de vous accommoder en communiquant avec vous par le biais de la plateforme de communication que vous aurez choisie. - L'information contenue dans ce message électronique est potentiellement de nature légalement privilégiée et confidentielle. Si ce message vous est parvenu par erreur et que vous n'êtes pas le destinataire visé, vous êtes par les présentes avisés que tout usage, copie ou distribution de ce message est strictement interdit. Vous êtes donc priés de nous informer immédiatement de cette erreur et de détruire ce message.`
}

export const SURVEY_COMPLETION_LABELS = {
  notStarted: 'Not started',
  inProgress: 'In progress',
  complete: 'Complete',
  clarificationNeeded: 'Clarification needed'
}

export const SOX_OPTIONS = [
  {
    label: 'Yes - Provide picture of asset & serial #',
    value: 'yes'
  },
  {
    label: 'No, Missing asset - Provide picture of entire store',
    value: 'missingAsset'
  },
  {
    label: 'No, Missing Serial # - Provide picture of the asset',
    value: 'missingSerialNumber'
  },
  {
    label: 'No, Wrong Serial # - Provide picture of the serial #',
    value: 'wrongSerialNumber'
  }
]

export const PROJECT_STATUSES = {
  unresolved: 'Unresolved',
  pending: 'Pending',
  pendingFollowUp: 'Pending',
  resolved: 'Resolved'
}

export const PROGRAM_MAX_RESERVABLE = 500

export const MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET = 'Mini Volume Growth Program With Target'
export const MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN = 'Mini Volume Growth Program With Target - Sell In'
export const MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_OUT = 'Mini Volume Growth Program With Target - Sell Out'
export const MINI_INCREMENTAL_VOLUME_GROWTH_PROGRAM = 'Mini Incremental Volume Growth Program'
export const SEASONAL_DAYS_ON_HAND = 'Seasonal Days on Hand'
export const FLEXIBLE_TEDS = 'Flexible TEDs'
export const SHARE_GROWTH_PROGRAM = 'Share Growth Program'
export const VOLUME_OFFTAKE_PROGRAM = 'Volume Off-Take Program'
export const PREFERRED_ASSORTMENT = 'Preferred Assortment'
export const PRICE_COMPETITIVENESS = 'Price Competitiveness'
export const INDEPENDENT_STORE_LEVEL_ACTIVATION = 'Independent store level activation'
export const POWER_HOUR = 'Power hour'
export const AWARENESS_PROGRAMS = 'Awareness Programs'
export const TWO_PK_PROGRAMS = '2 PK Programs'
export const SHARE_PROGRAM = 'Share program'
export const EDUCATION = 'Education'
export const MINI_AVP_QC = 'Mini AVP (QC)'
export const IN_STORE_ON_SHELVES = 'In Store on Shelves'

export const transactionTypes = {
  digitalCard: 'Digital card',
  goCard: 'Digital card',
  prepaidCard: 'Prepaid card',
  pap: 'PAP'
}

export const TIME_OPTIONS = [
  { label: '12:00 AM', value: '00:00' },
  { label: '12:30 AM', value: '00:30' },
  { label: '1:00 AM', value: '01:00' },
  { label: '1:30 AM', value: '01:30' },
  { label: '2:00 AM', value: '02:00' },
  { label: '2:30 AM', value: '02:30' },
  { label: '3:00 AM', value: '03:00' },
  { label: '3:30 AM', value: '03:30' },
  { label: '4:00 AM', value: '04:00' },
  { label: '4:30 AM', value: '04:30' },
  { label: '5:00 AM', value: '05:00' },
  { label: '5:30 AM', value: '05:30' },
  { label: '6:00 AM', value: '06:00' },
  { label: '6:30 AM', value: '06:30' },
  { label: '7:00 AM', value: '07:00' },
  { label: '7:30 AM', value: '07:30' },
  { label: '8:00 AM', value: '08:00' },
  { label: '8:30 AM', value: '08:30' },
  { label: '9:00 AM', value: '09:00' },
  { label: '9:30 AM', value: '09:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '1:00 PM', value: '13:00' },
  { label: '1:30 PM', value: '13:30' },
  { label: '2:00 PM', value: '14:00' },
  { label: '2:30 PM', value: '14:30' },
  { label: '3:00 PM', value: '15:00' },
  { label: '3:30 PM', value: '15:30' },
  { label: '4:00 PM', value: '16:00' },
  { label: '4:30 PM', value: '16:30' },
  { label: '5:00 PM', value: '17:00' },
  { label: '5:30 PM', value: '17:30' },
  { label: '6:00 PM', value: '18:00' },
  { label: '6:30 PM', value: '18:30' },
  { label: '7:00 PM', value: '19:00' },
  { label: '7:30 PM', value: '19:30' },
  { label: '8:00 PM', value: '20:00' },
  { label: '8:30 PM', value: '20:30' },
  { label: '9:00 PM', value: '21:00' },
  { label: '9:30 PM', value: '21:30' },
  { label: '10:00 PM', value: '22:00' },
  { label: '10:30 PM', value: '22:30' },
  { label: '11:00 PM', value: '23:00' },
  { label: '11:30 PM', value: '23:30' }
]

export const HOUR_OPTIONS = [
  { label: '12 AM', value: '00' },
  { label: '1 AM', value: '01' },
  { label: '2 AM', value: '02' },
  { label: '3 AM', value: '03' },
  { label: '4 AM', value: '04' },
  { label: '5 AM', value: '05' },
  { label: '6 AM', value: '06' },
  { label: '7 AM', value: '07' },
  { label: '8 AM', value: '08' },
  { label: '9 AM', value: '09' },
  { label: '10 AM', value: '10' },
  { label: '11 AM', value: '11' },
  { label: '12 PM', value: '12' },
  { label: '1 PM', value: '13' },
  { label: '2 PM', value: '14' },
  { label: '3 PM', value: '15' },
  { label: '4 PM', value: '16' },
  { label: '5 PM', value: '17' },
  { label: '6 PM', value: '18' },
  { label: '7 PM', value: '19' },
  { label: '8 PM', value: '20' },
  { label: '9 PM', value: '21' },
  { label: '10 PM', value: '22' },
  { label: '11 PM', value: '23' }
]
function createMinuteOptions() {
  const options = []
  for (let i = 0; i < 60; i++) {
    options.push({ label: String(i).padStart(2, '0'), value: String(i).padStart(2, '0') })
  }
  return options
}
export const MINUTE_OPTIONS = createMinuteOptions()
export const getValidTimeOptions = ({ start, end }) => {
  return TIME_OPTIONS.filter(({ value }) => (!start || value >= start) && (!end || value <= end))
}

export const OOT_CATEGORIES = [
  { label: 'Medical (dental, doctor appointment)', value: 'OOT_MEDICAL' },
  { label: 'Business Development (Signing up new stores)', value: 'OOT_BUSINESS_DEVELOPMENT' },
  { label: 'Development & Engagement', value: 'OOT_DEVELOPMENT_ENGAGEMENT' },
  { label: 'Cycle Planning', value: 'OOT_CYCLE_PLANNING' },
  { label: 'Vacation', value: 'OOT_VACATION' },
  { label: 'Technical Issues', value: 'OOT_TECHNICAL_ISSUES' },
  { label: 'Vehicle', value: 'OOT_VEHICLE' },
  { label: 'Statutory holidays', value: 'OOT_STATUTORY_HOLIDAYS' },
  { label: 'Special Days', value: 'OOT_SPECIAL_DAYS' },
  { label: 'Vape Coverage', value: 'OOT_VAPE_COVERAGE' }
]

export const PROVINCE_CODES = [
  { label: 'AB', value: 'AB' },
  { label: 'MB', value: 'MB' },
  { label: 'QC', value: 'QC' },
  { label: 'NS', value: 'NS' },
  { label: 'NB', value: 'NB' },
  { label: 'NL', value: 'NL' },
  { label: 'PE', value: 'PE' },
  { label: 'ON', value: 'ON' },
  { label: 'SK', value: 'SK' },
  { label: 'BC', value: 'BC' },
  { label: 'YT', value: 'YT' },
  { label: 'NT', value: 'NT' },
  { label: 'NU', value: 'NU' }
]

export const DEFAULT_OPENING_HOURS = {
  sun: { open: '08:00', close: '17:00' },
  mon: { open: '08:00', close: '17:00' },
  tue: { open: '08:00', close: '17:00' },
  wed: { open: '08:00', close: '17:00' },
  thu: { open: '08:00', close: '17:00' },
  fri: { open: '08:00', close: '17:00' },
  sat: { open: '08:00', close: '17:00' }
}

export const OUTBOX_KEYS = {
  UPSERTED_MILEAGE: 'upserted-mileage',
  DELETED_MILEAGE: 'deleted-mileage',
  UPSERTED_MESSAGES: 'upserted-messages',
  UPSERTED_OOTS: 'upserted-oot',
  DELETED_OOTS: 'deleted-oot',
  UPSERTED_CALLS: 'upserted-calls',
  DELETED_CALLS: 'deleted-calls'
}

export const SYNC_ACTION_KEYS = {
  SUBMIT_PENDING_MILEAGE: 'submit-pending-mileage',
  DELETE_PENDING_MILEAGE: 'delete-pending-mileage',
  SUBMIT_PENDING_MESSAGES: 'submit-pending-messages',
  SUBMIT_PENDING_OOTS: 'submit-pending-oot',
  DELETE_PENDING_OOTS: 'submit-pending-delete-oot',
  DELETE_PENDING_CALLS: 'submit-pending-delete-call',
  SUBMIT_PENDING_CALLS: 'submit-pending-calls'
}

export const SYNC_HANDLER_TYPES = {
  MILEAGE_SUBMIT_SUCCESS: 'mileage-submit-success',
  MILEAGE_DELETE_SUCCESS: 'mileage-delete-success',
  MESSAGE_SUBMIT_SUCCESS: 'message-submit-success',
  OOT_SUBMIT_SUCCESS: 'oot-submit-success',
  CALL_SUBMIT_SUCCESS: 'call-submit-success'
}

export const DATA_UPDATE_STATUS = {
  OVER: 'over',
  LOADING: 'loading',
  ERROR: 'error',
  OLD: 'old'
}

export const DATA_EXPIRATION_MINUTES = 10
export const MAX_PROMO_QUANTITY = 4

export const SKU_MAX_QTY_BY_BANNER = {
  startDate: '2023-05-15',
  endDate: '2023-06-30',
  banners: [
    "MAC'S CONVENIENCE (ON) (CORPORATE)",
    "MAC'S CONVENIENCE (WEST) (CORPORATE)",
    'CIRCLE K',
    'COUCHE TARD QC OUEST',
    'COUCHE TARD QC EST',
    'CHEVRON TOWN PANTRY',
    'FAS GAS',
    'PARKLAND',
    'PARKLAND (NON-CONTRACTED)',
    'PARKLAND CORPORATION',
    'PARKLAND CST',
    'PARKLAND OTR Dealer',
    'PARKLAND OTRF',
    'PIONEER AFFILIATED',
    'PIONEER AFFILIATED (NON-CONTRACTED)',
    'PIONEER CORPORATE'
  ],
  skus: [10169432, 10169444, 10169492, 10169454, 10169437, 10169448]
}

export const GO_FUND_PAYMENT_TYPES = {
  instant: 'instant',
  offline: 'offline',
  automatic: 'automatic'
}

export const CALL_CUSTOMER_ATTRS = [
  'accountType',
  'address',
  'banner',
  'id',
  'latitude',
  'longitude',
  'name',
  'openingHours',
  'outletSubtype',
  'partnershipType',
  'primaryContact',
  'primaryTerritory',
  'storePhoneNumber',
  'timezone'
]

export const GO_FUNDS_PAYMENTS_REASONS = [
  { label: 'Education / Awareness Program', value: 'REASON_EDUCATION_AWARENESS' },
  { label: 'Flexible TEDs', value: 'REASON_FLEXIBLE' },
  { label: 'Seasonal Days on Hand /Inventory', value: 'REASON_INVENTORY' },
  { label: 'Preferred Assortment', value: 'REASON_ASSORTMENT' },
  { label: 'Price Competitiveness', value: 'REASON_COMPETITIVENESS' },
  { label: 'Price Competitiveness', value: 'REASON_COMPETITIVENESS' },
  { label: 'Incremental Program', value: 'REASON_INCREMENTAL' }
]

export const CURRENT_COMPARISONS_OPTIONS = [
  { label: 'To date', value: 'to_date' },
  { label: 'To go', value: 'to_go' }
]

export const PERIOD_FILTERS = {
  TODAY: 'today',
  WEEK: 'week',
  CYCLE: 'cycle',
  QUARTER: 'quarter',
  YEAR: 'year'
}

export const DISABLE_TOGO_PERIOD = [PERIOD_FILTERS.TODAY, PERIOD_FILTERS.QUARTER, PERIOD_FILTERS.YEAR]

export const PERIOD_FILTERS_OPTIONS = Object.values(PERIOD_FILTERS).map((period) => ({
  label: startCase(period),
  value: period
}))

export const SECTOR_LEVELS = {
  CUSTOMER: 'customer',
  TERRITORY: 'territory',
  DISTRICT: 'district',
  REGION: 'region',
  NATIONAL: 'national'
}

const PRODUCT_TYPES = [
  {
    value: 'fmc',
    label: 'FMC'
  },
  {
    value: 'vape',
    label: 'VAPE'
  },
  {
    value: 'nrt',
    label: 'NRT'
  }
]

export const PRODUCT_TYPE_OPTIONS = config.featureFlags.nrtPace
  ? PRODUCT_TYPES
  : PRODUCT_TYPES.filter((o) => o.value !== 'nrt')

export const TIME_FRAME_OPTIONS = [
  {
    label: 'LW',
    value: '1'
  },
  {
    label: 'L4',
    value: '4'
  }
]

export const DISTRO_VAPE_CHANNEL = [
  {
    label: 'common.traditional',
    value: 'traditional'
  },
  {
    label: 'app.vapeShop',
    value: 'vapeshop'
  }
]

export const OOS_VAPE_PRODUCT_CATEGORY = [
  {
    label: 'app.devices',
    value: 'devices'
  },
  {
    label: 'app.consumables',
    value: 'consumables'
  },
  {
    label: 'app.disposables',
    value: 'disposables'
  },
  {
    label: 'common.all',
    value: 'all'
  }
]

export const UNIT_OF_MEASURE_OPTIONS = [
  {
    label: 'Sticks',
    value: 'ceq'
  },
  {
    label: 'Pack',
    value: 'pck'
  },
  {
    label: 'Carton',
    value: 'ctn'
  }
]

export const VAPE_UNIT_OF_MEASURE_OPTIONS = [
  {
    label: 'Units',
    value: 'ceq'
  },
  {
    label: 'PEQ',
    value: 'peq'
  }
]

export const VAPE_PRODUCT_CATEGORY = [
  {
    label: 'app.consumables',
    value: 'consumables'
  },
  {
    label: 'app.disposables',
    value: 'disposables'
  },
  {
    label: 'common.all',
    value: 'all'
  }
]

export const PRODUCT_TYPE_ROUTES = [
  '/pace/plan',
  'pace/amplify/sell-in',
  'pace/amplify/sell-out',
  'pace/amplify/inventory',
  'pace/amplify/distro',
  'pace/amplify/price-capture',
  'pace/amplify/price-compliance'
]

export const DATAKEY_TYPES = {
  SECTOR_PICKER: {
    keys: ['sectorType'],
    create: ({ sectorType }) => `sector_picker-${sectorType}`
  },
  CUSTOMER: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}`
  },
  CUSTOMER_GO_FUND: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-go-fund`
  },
  TERRITORY_GO_FUND: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-go-fund`
  },
  CUSTOMER_SELL_IN_PROGRAMS: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-sell_in_programs`
  },
  CUSTOMER_PROJECTS: {
    keys: ['customerId', 'projectId'],
    create: ({ customerId, projectId }) => `customer-${customerId}-project-${projectId}`
  },
  TERRITORY: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}`
  },
  TERRITORY_INTEL: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-intel`
  },
  TERRITORY_PERFORMANCE: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-performance`
  },
  TERRITORY_CUSTOMERS: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-customers`
  },
  TERRITORY_SELL_IN_PROGRAMS: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-sell_in_programs`
  },
  TERRITORY_SELL_IN_PROGRAM: {
    keys: ['territoryId', 'sellInProgramId'],
    create: ({ territoryId, sellInProgramId }) => `territory-${territoryId}-sell_in_program-${sellInProgramId}`
  },
  PLANNING_ENGAGEMENT: {
    keys: ['sectorType', 'sectorId'],
    create: ({ sectorType, sectorId }) => `planning-engagement-${sectorType}-${sectorId}`
  },
  PLANNING_ENGAGEMENT_EXTRA_HUB: {
    keys: ['sectorType', 'sectorId'],
    create: ({ sectorType, sectorId }) => `planning-engagement-extra-hub-${sectorType}-${sectorId}`
  },
  PLANNING_HEALTH_CHECK: {
    keys: ['sectorType', 'sectorId', 'productType'],
    create: ({ sectorType, sectorId, productType, timeframe, vapeChannel }) =>
      `planning-healthcheck-${sectorType}-${sectorId}-product-type-${productType}-${timeframe}_weeks-${vapeChannel}`
  },
  PLANNING_PRICE_CHECK: {
    keys: ['sectorType', 'sectorId', 'productType'],
    create: ({ sectorType, sectorId, productType }) =>
      `planning-pricecheck-${sectorType}-${sectorId}-product-type-${productType}`
  },
  PLANNING_SELL_IN: {
    keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'unitOfMeasure', 'period', 'range'],
    create: ({ sectorType, sectorId, productType, vapeCategory, unitOfMeasure, period, range }) =>
      `planning-sell_in-${sectorType}-${sectorId}-product-type-${productType}-${vapeCategory}-${unitOfMeasure}-period-${period}-${range}`
  },
  PLANNING_SELL_OUT: {
    keys: ['sectorType', 'sectorId', 'manufacturer'],
    create: ({ sectorType, sectorId, manufacturer }) =>
      `planning-sell_out-${sectorType}-${sectorId}-manufacturer-${manufacturer}`
  },
  PLANNING_CYCLE_FOCUS: {
    keys: ['sectorType', 'sectorId', 'campaignId'],
    create: ({ sectorType, sectorId, campaignId }) => `planning-cycle-${sectorType}-${sectorId}-campaign-${campaignId}`
  },
  SELL_IN_ORDER_COMPLETION: {
    keys: ['sectorType', 'sectorId'],
    create: ({ sectorType, sectorId }) => `sell_in-order-completion-${sectorType}-${sectorId}`
  },
  SELL_IN_RUN_RATE: {
    keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'unitOfMeasure'],
    create: ({ sectorType, sectorId, productType, vapeCategory = 'all', unitOfMeasure = 'ceq' }) =>
      `sell_in-run-rate-${sectorType}-${sectorId}-product-type-${productType}-vape-category-${vapeCategory}-unit-of-measure-${unitOfMeasure}`
  },
  DISTRO_TRENDED: {
    keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'vapeChannel'],
    create: ({ sectorType, sectorId, productType, vapeCategory, vapeChannel }) =>
      `distro-trended-${sectorType}-${sectorId}-product-type-${productType}-${vapeCategory}-${vapeChannel}`
  },
  DISTRO_BRAND: {
    keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'vapeChannel', 'sortBy', 'direction'],
    create: ({ sectorType, sectorId, productType, vapeCategory, vapeChannel, sortBy, direction }) =>
      `distro-brand-${sectorType}-${sectorId}-product-type-${productType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`
  },
  DISTRO_MATERIAL: {
    keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'vapeChannel', 'sortBy', 'direction'],
    create: ({ sectorType, sectorId, productType, vapeCategory, vapeChannel, sortBy, direction }) =>
      `distro-material-${sectorType}-${sectorId}-product-type-${productType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`
  },
  AMPLIFY: {
    INVENTORY_OOS: {
      OOS_INV_CAPTURE: {
        keys: ['sectorType', 'sectorId', 'productType', 'timeframe', 'vapeCategory'],
        create: ({ sectorType, sectorId, productType, timeframe, vapeCategory }) =>
          `amplify-inventory_oos-${sectorType}-${sectorId}-${productType}-${vapeCategory}-${timeframe}`
      },
      TRENDING: {
        keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory'],
        create: ({ sectorType, sectorId, productType, vapeCategory }) =>
          `amplify-inventory_oos-trending-${sectorType}-${sectorId}-${productType}-${vapeCategory}`
      },
      BRANDS: {
        keys: ['sectorType', 'sectorId', 'productType', 'timeframe', 'vapeCategory', 'sortBy', 'direction'],
        create: ({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
          `amplify-inventory_oos-brands-${sectorType}-${sectorId}-${productType}-${vapeCategory}-${timeframe}-${sortBy}-${direction}_weeks`
      },
      MATERIALS: {
        keys: ['sectorType', 'sectorId', 'productType', 'timeframe', 'vapeCategory', 'sortBy', 'direction'],
        create: ({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
          `amplify-inventory_oos-materials-${sectorType}-${sectorId}-${productType}-${vapeCategory}-${timeframe}-${sortBy}-${direction}_weeks`
      },
      GEOGRAPHY: {
        keys: [
          'sectorType',
          'sectorId',
          'geography',
          'ownership',
          'brand',
          'material',
          'productType',
          'timeframe',
          'vapeCategory',
          'offset',
          'sortBy',
          'direction'
        ],
        create: ({
          sectorType,
          sectorId,
          geography,
          ownership,
          brand,
          material,
          productType,
          timeframe,
          vapeCategory,
          offset,
          sortBy,
          direction
        }) =>
          `amplify-inventory_oos-region-${sectorType}-${sectorId}-${geography}-${ownership}-${brand}-${material}-${productType}-${vapeCategory}-${timeframe}-${offset}-${sortBy}-${direction}`
      },
      FILTERS: {
        keys: ['sectorType', 'sectorId', 'productType', 'timeframe', 'vapeCategory'],
        create: ({ sectorType, sectorId, productType, timeframe, vapeCategory }) =>
          `amplify-inventory_oos-filters-${sectorType}-${sectorId}-${productType}-${vapeCategory}-${timeframe}`
      }
    },
    DISTRO: {
      GEOGRAPHY: {
        keys: [
          'sectorType',
          'sectorId',
          'geography',
          'productType',
          'vapeCategory',
          'vapeChannel',
          'offset',
          'sortBy',
          'direction'
        ],
        create: ({
          sectorType,
          sectorId,
          geography,
          productType,
          vapeCategory,
          vapeChannel,
          offset,
          filters,
          sortBy,
          direction
        }) =>
          `amplify-distro-${sectorType}-${sectorId}-${geography}-${productType}-${vapeCategory}-${vapeChannel}-offset-${offset}-${
            filters && !isEmpty(filters)
              ? Object.entries(filters)
                  .map(([key, value]) => `${key}-${snakeCase(value)}`)
                  .join('-')
              : 'no-filters'
          }-${sortBy}-${direction}`
      },
      SUMMARY: {
        keys: ['sectorType', 'sectorId', 'productType', 'vapeCategory', 'vapeChannel'],
        create: ({ sectorType, sectorId, productType, vapeCategory, vapeChannel }) =>
          `amplify-distro-${sectorType}-${sectorId}-${productType}-${vapeCategory}-${vapeChannel}`
      }
    },
    SELL_IN: {
      GEOGRAPHY: {
        keys: [
          'sectorType',
          'sectorId',
          'geography',
          'currentProductType',
          'period',
          'vapeCategory',
          'range',
          'offset'
        ],
        create: ({
          sectorType,
          sectorId,
          geography,
          currentProductType,
          period,
          vapeCategory,
          unitOfMeasure,
          range,
          offset,
          columnSorted,
          sortDirection
        }) =>
          `amplify-sellin-geography-${sectorType}-${sectorId}-${geography}-${currentProductType}-${period}-${vapeCategory}-${unitOfMeasure}-${columnSorted}-${sortDirection}-${range}-${offset}`
      }
    },
    EXTRA_HUB: {
      TRENDING: {
        keys: ['sectorType', 'sectorId'],
        create: ({ sectorType, sectorId }) => `amplify-extra_hub_incentives-trending-${sectorType}-${sectorId}`
      },
      ENGAGEMENT: {
        keys: ['sectorType', 'sectorId'],
        create: ({ sectorType, sectorId }) => `amplify-extra_hub_incentives-engagement-${sectorType}-${sectorId}`
      },
      FILTERS: {
        keys: ['sectorType', 'sectorId'],
        create: ({ sectorType, sectorId }) => `amplify-extra_hub_incentives-filters-${sectorType}-${sectorId}`
      },
      GEOGRAPHY: {
        keys: ['sectorType', 'sectorId', 'geography', 'activity', 'offset', 'columnSorted', 'sortDirection'],
        create: ({ sectorType, sectorId, geography, activity, offset, columnSorted, sortDirection }) =>
          `amplify-extra_hub_incentives-geography-${sectorType}-${sectorId}-${geography}-${activity}-${offset}-${columnSorted}-${sortDirection}`
      },
      ACTIVITY: {
        keys: ['sectorType', 'sectorId'],
        create: ({ sectorType, sectorId }) => `amplify-extra_hub_incentives-activity-${sectorType}-${sectorId}`
      }
    },
    PRICE_CAPTURE_COMPLIANCE: {
      STORE_FOOTPRINT: {
        keys: ['sectorType', 'sectorId', 'productType'],
        create: ({ sectorType, sectorId, productType }) =>
          `amplify-price_capture-store_footprint-${sectorType}-${sectorId}-${productType}`
      },
      BRAND_COMPLETION: {
        keys: ['sectorType', 'sectorId', 'productType'],
        create: ({ sectorType, sectorId, productType }) =>
          `amplify-price_capture-brand_completion-${sectorType}-${sectorId}-${productType}`
      },
      FILTERS: {
        keys: ['sectorType', 'sectorId', 'productType'],
        create: ({ sectorType, sectorId, productType }) =>
          `amplify-price_capture-filters-${sectorType}-${sectorId}-${productType}`
      },
      GEOGRAPHY: {
        keys: ['sectorType', 'sectorId', 'geography', 'productType', 'filters', 'offset', 'sortBy', 'direction'],
        create: ({ sectorType, sectorId, geography, productType, offset, filters = {}, sortBy, direction }) => {
          const lFilters = {
            channel: 'all',
            ownershipType: 'all',
            banner: 'all',
            headoffice: 'all',
            tier: 'all',
            ...filters
          }
          return `amplify-price_capture-${sectorType}-${sectorId}-${geography}-${productType}${Object.values(
            lFilters
          ).join((value) => `-${value}`)}-${offset}-${sortBy}-${direction}`
        }
      },
      STRATEGY_COMPLIANCE: {
        keys: ['sectorType', 'sectorId', 'productType'],
        create: ({ sectorType, sectorId, productType }) =>
          `amplify-price_compliance-strategy_compliance-${sectorType}-${sectorId}-${productType}`
      },
      EDLP_COMPLIANCE: {
        keys: ['sectorType', 'sectorId'],
        create: ({ sectorType, sectorId }) => `amplify-price_compliance-edlp_compliance-${sectorType}-${sectorId}`
      },
      PRICE_COMPLIANCE_GEOGRAPHY: {
        keys: ['sectorType', 'sectorId', 'geography', 'productType', 'filters', 'offset'],
        create: ({
          sectorType,
          sectorId,
          geography,
          productType,
          filters: {
            channel,
            ownershipType,
            banner,
            headoffice,
            packType,
            packCount,
            strategyType,
            tier,
            productName,
            strategyName
          },
          offset
        }) =>
          `amplify-price_compliance-geography_compliance-${sectorType}-${sectorId}-${geography}-${productType}-${channel}-${ownershipType}-${banner}-${headoffice}-${packType}-${packCount}-${strategyType}-${tier}-${productName}-${strategyName}-${offset}`
      },
      PRICE_COMPLIANCE_GEOGRAPHY_FILTERS: {
        keys: ['sectorType', 'sectorId', 'productType'],
        create: ({ sectorType, sectorId, productType }) =>
          `amplify-price_compliance-geography_filters-${sectorType}-${sectorId}-${productType}`
      }
    },
    SELL_OUT: {
      MAIN: {
        keys: ['sectorType', 'sectorId', 'productType', 'channel', 'dataType', 'unitOfMeasure'],
        create: ({ sectorType, sectorId, productType, channel, dataType, unitOfMeasure }) =>
          `amplify-sell_out-${sectorType}-${sectorId}-product-type-${productType}-channel-${channel}-data-type-${dataType}-${unitOfMeasure}`
      },
      GEOGRAPHY: {
        keys: [
          'sectorType',
          'sectorId',
          'productType',
          'channel',
          'geography',
          'dataType',
          'unitOfMeasure',
          'proportion',
          'timeDisplay',
          'columnSorted',
          'sortDirection'
        ], // OPTIONAL: filters: { manufacturer, vapeCategory }, 'offset', 'limit', 'sortColumn', 'sortDirection'
        create: ({
          sectorType,
          sectorId,
          productType,
          channel,
          geography,
          dataType,
          unitOfMeasure,
          proportion,
          timeDisplay,
          offset = 0,
          limit = 10,
          columnSorted = 'lMid',
          sortDirection = 'desc',
          filters
        }) =>
          [
            `amplify-sell_out`,
            sectorType,
            sectorId,
            `productType-${productType}`,
            `channel-${channel}`,
            `dataType-${dataType}`,
            `${unitOfMeasure}`,
            `proportion-${proportion}`,
            `timeDisplay-${timeDisplay}`,
            `geography-${geography}`,
            `offset-${offset}`,
            `limit-${limit}`,
            `sortColumn-${columnSorted}`,
            `sortDirection-${sortDirection}`
          ]
            .concat(
              !isEmpty(filters) &&
                'filters-' +
                  sortBy(Object.keys(filters))
                    .map((k) => filters[k])
                    .join('-')
            )
            .filter(Boolean)
            .join('-')
      }
    }
  },
  CUSTOMER_CONSUMER_OFFER: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-consumer-offer`
  },
  CUSTOMER_PROJECT_LINKS: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-project-link`
  },
  CUSTOMER_ORDERS: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-orders`
  },
  CUSTOMER_ASSORTMENT: {
    keys: ['customerId'],
    create: ({ customerId }) => `customer-${customerId}-assortment`
  },
  TERRITORY_PROJECT_LINKS: {
    keys: ['territoryId'],
    create: ({ territoryId }) => `territory-${territoryId}-project-link`
  },
  CYCLE_CAMPAIGN: {
    keys: ['sectorId', 'sectorType'],
    create: ({ sectorType, sectorId }) => `cycle-campaign-type-${sectorType}-id-${sectorId}}`
  }
}

export const CYCLE_CAMPAIGN_KPI_TYPES = {
  WEIGHTED_DISTRO: 'weighted distro',
  RATES_OF_SELL: 'rates of sales',
  SELL_IN: 'sell-in',
  SELL_OUT: 'sell-out',
  EXTRA_HUB: 'extra Hub',
  PRICE_UPDATE: 'price update',
  PRICE_SIGN: 'price sign',
  PRICE_COMPLIANCE: 'price compliance'
}

export const PACE_SELLOUT_MANUFACTURER_FILTERS = {
  fmc: {
    pos: [
      { label: 'ITCAN', value: 'ITCAN' },
      { label: 'RBH', value: 'RBH' },
      { label: 'JTI', value: 'JTI' }
    ]
  },
  nrt: {
    pos: [
      { label: 'Zonnic', value: 'Zonnic' },
      { label: 'Sesh', value: 'Sesh' },
      { label: 'common.others', value: 'Others' }
    ]
  },
  vape: {
    pos: [
      { label: 'Vuse', value: 'Vuse' },
      { label: 'STLTH', value: 'STLTH' },
      { label: 'Veev', value: 'Veev' },
      { label: 'Juul', value: 'Juul' },
      { label: 'common.other', value: 'other' }
    ],
    specialty: [
      { label: 'Vuse', value: 'Vuse' },
      { label: 'STLTH', value: 'STLTH' },
      { label: 'Vice', value: 'Vice' },
      { label: 'Flavour Beast', value: 'Flavour Beast' },
      { label: 'common.other', value: 'other' }
    ]
  }
}

export const CHANNEL_FILTERS = [
  { label: 'app.acronyms.POS', value: 'pos' },
  { label: 'common.specialty', value: 'specialty' }
]

export const GEOGRAPHIES = [
  {
    label: 'app.geo.region',
    value: 'region'
  },
  {
    label: 'app.geo.province',
    value: 'province'
  },
  {
    label: 'app.geo.district',
    value: 'district'
  },
  {
    label: 'app.geo.territory',
    value: 'territory'
  },
  {
    label: 'app.geo.HO',
    value: 'headoffice'
  },
  {
    label: 'app.geo.channel',
    value: 'channel'
  },
  {
    label: 'app.geo.ownership',
    value: 'ownership'
  },
  {
    label: 'app.geo.store',
    value: 'customer'
  },
  {
    label: 'app.geo.activity',
    value: 'activity'
  },
  {
    label: 'app.geo.campaign',
    value: 'campaign'
  },
  {
    label: 'app.geo.brand',
    value: 'brand'
  },
  {
    label: 'app.geo.brandVariant',
    value: 'brand_variant'
  },
  {
    label: 'app.geo.SKU',
    value: 'sku'
  },
  {
    label: 'app.user',
    value: 'user'
  }
]

export const PLACEHOLDERS = {
  NO_VALUE: '-',
  NO_VALUE_PERCENT: '- %'
}

export const SORTING_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
}
