import React from 'react'
import { any, arrayOf, func, object } from 'prop-types'

const TextCell = ({ column, label, labelGenFn, records }) => {
  // nullish coalescing (??) operator would be nice here ...
  const lLabel = label || labelGenFn?.(records) || ''
  return <td>{lLabel}</td>
}
TextCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * All the available records provided to the table
   */
  records: arrayOf(object).isRequired,

  label: any,
  /**
   * An optional function that should provide the label that will be displayed inside the cell.
   *
   * @param {object} record The record provided to the cell
   * @returns {string} The label that will be displayed in the cell
   */
  labelGenFn: func

  // textAlign: string
}

export default TextCell
