import React from 'react'
import moment from 'moment'
import { string } from 'prop-types'
import styled from 'styled-components'

import Status from 'components/Status'

import { PROJECT_STATUSES } from 'utils/constants'

import { grey } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Container = styled.div`
  display: flex;
  margin-top: ${spacing.medium};
`

const Item = styled.div`
  flex: 1;
  text-align: left;
`

const Number = styled.strong`
  display: block;
  color: ${grey};
  margin-bottom: 0;
  font-size: ${tinyFontSize};
`

const Label = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const ProjectStatusHeader = ({ firstActionedAt, creation, expiry, status }) => (
  <Container>
    <Item>
      <Label>Actioned</Label>
      <Number>{firstActionedAt ? moment(firstActionedAt).format('MMM DD') : 'Not yet'}</Number>
    </Item>
    <Item>
      <Label>Started</Label>
      <Number>{moment(creation).format('MMM DD')}</Number>
    </Item>
    <Item>
      <Label>Expires</Label>
      <Number>{moment(expiry).format('MMM DD')}</Number>
    </Item>
    <Item>
      <Label>Status</Label>
      <Number>
        <Status status={status} label={PROJECT_STATUSES[status]} />
      </Number>
    </Item>
  </Container>
)

ProjectStatusHeader.propTypes = {
  creation: string.isRequired,
  expiry: string.isRequired,
  status: string.isRequired,
  firstActionedAt: string
}

export default ProjectStatusHeader
