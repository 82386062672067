import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchDistroTrended = createAction(
  'Fetch Distro Trended Data',
  (props, dataKey) => async (dispatch, getState) => {
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchDistroTrended(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroTrended
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
  }
)

export const fetchDistroBrand = createAction(
  'Fetch Distro Brand Data',
  (props, dataKey) => async (dispatch, getState) => {
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchDistroBrand(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroBrand
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
  }
)

export const fetchDistroMaterial = createAction(
  'Fetch Distro Material Data',
  (props, dataKey) => async (dispatch, getState) => {
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchDistroMaterial(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].distroMaterial
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
  }
)

export const fetchDistroGeographyData = createAction(
  'Fetch Distro Table Data',
  ({ filters, ...props }, dataKey) =>
    async (dispatch, getState) => {
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.fetchDistroGeography({ ...filters, ...props })
        const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorType].distro.geography
        dispatch(
          addResultToScope({
            ...data,
            filters,
            ...props
          })
        )
      })
    }
)

export const fetchDistroSummaryData = createAction(
  'Fetch Distro Table Data',
  (props, dataKey) => async (dispatch, getState) => {
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchDistroGeography(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorType].distro.summary
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
  }
)
