import React from 'react'

import Container from 'components/Container'

const Privacy = () => (
  <>
    <Container padded>
      <p>
        Nulla vitae elit libero, a pharetra augue. Aenean lacinia bibendum nulla sed consectetur. Vivamus sagittis lacus
        vel augue laoreet rutrum faucibus dolor auctor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
        auctor.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed posuere consectetur est at lobortis. Cras justo
        odio, dapibus ac facilisis in, egestas eget quam. Aenean eu leo quam. Pellentesque ornare sem lacinia quam
        venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur.
      </p>
    </Container>
  </>
)

export default Privacy
