import React from 'react'
import { object, oneOf } from 'prop-types'

import CustomCell from 'components/table/cells/body/CustomCell'
import LinkCell from 'components/table/cells/body/LinkCell'
import TextCell from 'components/table/cells/body/TextCell'

const BodyCell = ({ column, type, record, ...rest }) => {
  switch (type) {
    case 'customcell':
      return <CustomCell column={column} record={record} {...rest} />
    case 'linkcell':
      return <LinkCell column={column} record={record} {...rest} />
    case 'textcell':
    default:
      return <TextCell column={column} record={record} {...rest} />
  }
}
BodyCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The record the cell will render
   */
  record: object.isRequired,

  /**
   * The type of cell to render
   */
  type: oneOf(['headercell', 'textcell', 'linkcell', 'customcell']).isRequired
}

export default BodyCell
