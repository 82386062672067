import React from 'react'
import { arrayOf, object } from 'prop-types'

import Table from 'components/table'

const BrandTable = ({ brands = [] }) => (
  <Table
    data={brands}
    columns={[
      {
        name: 'brand',
        header: 'Brand'
      },
      {
        name: 'suggestedQty',
        header: 'Suggested Qty'
      },
      {
        name: 'finalQty',
        header: 'Final Accepted Qty'
      }
    ]}
  />
)
BrandTable.propTypes = {
  brands: arrayOf(object)
}

export default BrandTable
