import moment from 'moment'
import queryString from 'query-string'

import { api } from 'store/api'

export const upsertCustomerCall = (call) => api.post(`/customer-calls`, call)

export const fetchCustomerCalls = ({ before, after, employeeId, customerId }) =>
  api.get(
    `/customer-calls?${queryString.stringify(
      {
        after: moment(after).format('yyyy-MM-DD'),
        before: moment(before).format('yyyy-MM-DD'),
        employeeId,
        customerId
      },
      { skipNull: true }
    )}`
  )

export const deleteCustomerCall = (call) => api.delete(`/customer-calls/${call.id}`)

export const swapScheduleDates = (datesToSwap) => api.post(`/customer-calls/swap-dates`, datesToSwap)
