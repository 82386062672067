import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { array, bool } from 'prop-types'
import styled from 'styled-components'

import ProgramAccordion from 'components/accordion/ProgramAccordion'
import Icon from 'components/Icon'
import Status from 'components/Status'

import { SURVEY_COMPLETION_LABELS } from 'utils/constants'

import { borderColor, greyDark, greyLight, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  width: 100%;
  overflow: visible;

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const Item = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.medium};

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const Name = styled.span`
  color: ${greyDark};
  display: flex;
  align-items: center;
  font-weight: ${fontSemibold};
`

const Lock = styled(Icon)`
  margin-left: ${spacing.tiny};
`

const EndDate = styled.span`
  font-size: ${smallFontSize};
  color: ${greyLight};
  display: block;
`

const EmptyState = styled.div`
  padding: ${spacing.large};
  text-align: center;
  color: ${greyLight};
`

const Dots = styled.div`
  display: flex;
  margin-bottom: ${spacing.tiny};
  justify-content: flex-end;
  margin-right: -6px;
`

const SurveyList = ({ surveys, isCustomerView, openSurveys }) => {
  return (
    <>
      <Container>
        <div className="max-md:-mx-5">
          {surveys.map(
            ({ survey, total, complete, notStarted, inProgress, clarificationNeeded, completion, isLocked }, idx) => {
              const currentlyLocked =
                survey.lockStartDate &&
                survey.lockEndDate &&
                moment().isBetween(survey.lockStartDate, survey.lockEndDate, 'day', '[]')
              const targetLocked = isLocked || survey.allLocked
              return (
                <Item key={survey.id} to={survey.id.toString()}>
                  <div>
                    {!isCustomerView && completion && <Status status={completion} />}
                    <Name>
                      {survey.name}
                      {currentlyLocked && targetLocked && <Lock small icon="lock" />}
                    </Name>
                    <EndDate>End date: {moment(survey.endDate).format('YYYY-MM-DD')}</EndDate>
                  </div>
                  <div>
                    {isCustomerView ? (
                      <Status status={completion} label={SURVEY_COMPLETION_LABELS[completion]} />
                    ) : (
                      <>
                        <Dots>
                          {notStarted > 0 && <Status status="notStarted" />}
                          {inProgress > 0 && <Status status="inProgress" />}
                          {complete > 0 && <Status status="complete" />}
                          {clarificationNeeded > 0 && <Status status="clarificationNeeded" />}
                        </Dots>
                        <span>{((complete / total) * 100).toFixed(0)}% completed</span>
                      </>
                    )}
                  </div>
                </Item>
              )
            }
          )}
          {surveys.length === 0 && <EmptyState>No surveys</EmptyState>}
        </div>
      </Container>
      {openSurveys?.length > 0 && (
        <ProgramAccordion title="Optional surveys">
          <Container>
            {openSurveys.map(({ id, name, endDate, allLocked, lockStartDate, lockEndDate }) => {
              const surveyLocked =
                lockStartDate && lockEndDate && moment().isBetween(lockStartDate, lockEndDate, 'day', '[]') && allLocked
              return (
                <Item key={id} to={`${id}`}>
                  <div>
                    <Name>
                      {name}
                      {surveyLocked && <Lock small icon="lock" />}
                    </Name>
                    <EndDate>End date: {moment(endDate).format('YYYY-MM-DD')}</EndDate>
                  </div>
                  <div>
                    <Status status="notStarted" label={SURVEY_COMPLETION_LABELS.notStarted} />
                  </div>
                </Item>
              )
            })}
          </Container>
        </ProgramAccordion>
      )}
    </>
  )
}
SurveyList.propTypes = {
  surveys: array,
  isCustomerView: bool,
  openSurveys: array
}

export default SurveyList
