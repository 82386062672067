import React, { Fragment, useEffect, useMemo, useState } from 'react'
import sum from 'lodash/sum'
import { stripUnit } from 'polished'
import { array, bool, func, number, object, string } from 'prop-types'
import styled from 'styled-components'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import QuantitySelect from 'components/QuantitySelect'

import * as spacing from 'styles/spacing'

const ProductWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`
const TitleWrap = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  overflow-y: auto;
  margin-bottom: ${spacing.medium};

  li + li {
    margin-top: ${spacing.small};
  }

  li ul {
    padding-left: ${spacing.small};
    padding-top: ${spacing.small};
  }
`

const Messages = ({
  projectId,
  messages,
  orders,
  preferredLanguage,
  customerId,
  locationState,
  status,
  hasCompleteAction,
  setSaqInitialValues,
  isSubmitting,
  customerSaqsDisabled
}) => {
  const [saqValues, setSaqValues] = useState(null)
  const hasSaqValues = useMemo(
    () => !customerSaqsDisabled && Boolean(saqValues && sum(Object.values(saqValues))),
    [customerSaqsDisabled, saqValues]
  )
  const [goToSaqClicked, setGoToSaqClicked] = useState(false)

  const goToSaq = () => {
    setGoToSaqClicked(true)
    setSaqInitialValues(saqValues)
  }

  const updateSaqValue = (productId, newQty) => {
    setSaqValues({ ...saqValues, [productId]: Math.max(0, newQty) })
  }

  useEffect(() => {
    const initialSaqValues =
      messages?.reduce((acc, { saq, submessages }) => {
        const submessageSaqs = submessages?.reduce((subSaqs, { saq }) => [...subSaqs, saq], []).filter(Boolean) || []
        const allSaqs = [].concat(saq).concat(submessageSaqs).filter(Boolean)
        if (!allSaqs.length) return acc
        return allSaqs.reduce((a, { matId, qty }) => ({ ...a, [matId]: qty }), acc)
      }, {}) || {}
    setSaqValues(initialSaqValues)
  }, [messages, customerSaqsDisabled])

  const saqDisabled = status === 'resolved' || customerSaqsDisabled
  if (!messages.length) return null
  return (
    <Fragment>
      <TitleWrap>
        {orders && Boolean(orders.length) && (
          <h5>
            <Icon icon="checkmark-small" accent />
            SAQ submitted
          </h5>
        )}
      </TitleWrap>
      <div className="max-h-64 overflow-y-auto">
        <List>
          {messages?.length > 0 &&
            messages.map(({ text, submessages, saq }, idx) => (
              <li key={idx}>
                <ProductWrap>
                  <small>
                    <b>{text && text[preferredLanguage]}</b>
                  </small>
                  {saq && saqValues && !customerSaqsDisabled && (
                    <QuantitySelect
                      name={saq.matId}
                      type="number"
                      disabled={saqDisabled}
                      input={{
                        value: saqValues[saq.matId],
                        onChange: (newVal) => updateSaqValue(saq.matId, newVal)
                      }}
                    />
                  )}
                </ProductWrap>
                {submessages?.length > 0 && (
                  <List>
                    {submessages.map(({ text, saq }, idx) => (
                      <li key={`submessage-${idx}`}>
                        <ProductWrap>
                          <small>{text && text[preferredLanguage]}</small>
                          {saq && saqValues && !customerSaqsDisabled && (
                            <QuantitySelect
                              name={saq.matId}
                              type="number"
                              disabled={saqDisabled}
                              input={{
                                value: saqValues[saq.matId],
                                onChange: (newVal) => updateSaqValue(saq.matId, newVal)
                              }}
                            />
                          )}
                        </ProductWrap>
                      </li>
                    ))}
                  </List>
                )}
              </li>
            ))}
        </List>
      </div>
      {hasSaqValues && !saqDisabled && (
        <ButtonGroup>
          <Button
            onClick={goToSaq}
            primary
            isLoading={isSubmitting && goToSaqClicked}
            disabled={!hasCompleteAction || isSubmitting}
          >
            Go to SAQ
          </Button>
          {!hasCompleteAction && !isSubmitting && (
            <ErrorMessage>At least one action below must be completed</ErrorMessage>
          )}
        </ButtonGroup>
      )}
    </Fragment>
  )
}

Messages.propTypes = {
  projectId: number.isRequired,
  messages: array.isRequired,
  orders: array,
  preferredLanguage: string,
  customerId: number,
  locationState: object,
  status: string,
  hasCompleteAction: bool,
  setSaqInitialValues: func,
  isSubmitting: bool,
  customerSaqsDisabled: bool
}

export default Messages
