import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { signup } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { isBatEmail, isEmail, minPasswordLength, required } from 'utils/validators'

export function SignUp({ signup }) {
  const { translate } = useContext(LangContext)
  const navigate = useNavigate()
  const [error, setError] = useState()

  const errorMessages = {
    employeeAlreadyExists: (
      <GlobalAlert>
        {translate('auth.error.employeeAlreadyExists.1')}
        <Link to="/auth/login">{translate('auth.error.employeeAlreadyExists.2')}</Link>
        {translate('auth.error.employeeAlreadyExists.3')}
      </GlobalAlert>
    ),
    offlineError: <GlobalAlert>{translate('auth.offlineError')}</GlobalAlert>,
    invalidEmployeeDetails: <GlobalAlert>{translate('auth.invalidEmployeeDetails')}</GlobalAlert>,
    permissionDenied: <GlobalAlert>{translate('auth.permissionDenied')}</GlobalAlert>,
    generic: <GlobalAlert>{translate('auth.signUp.offlineError')}</GlobalAlert>
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()
    try {
      await signup(values)
      navigate('/auth/signup-requested')
    } catch ({ response }) {
      const signupError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[signupError] || errorMessages.generic)
    } finally {
      setSubmitting(false)
    }
  }

  const handleEmailChange = (value, setFieldValue) => {
    const email = value.trim().toLowerCase()
    setFieldValue('email', email)
  }

  const validateEmail = (value) => {
    return required(value) || isEmail(value) || isBatEmail(value)
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', oneId: '', password: '' }}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            <Fieldset>
              <FieldsetItem>
                <h3 className="mb-2 text-xl font-medium text-slate-700">{translate('auth.signUp')}</h3>
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder={translate('common.firstName')}
                  name="firstName"
                  validate={required}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder={translate('common.lastName')}
                  name="lastName"
                  validate={required}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder={translate('common.emailAddress')}
                  name="email"
                  onChange={(event) => handleEmailChange(event.target.value, setFieldValue)}
                  validate={validateEmail}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder={translate('common.oneID')}
                  name="oneId"
                  type="number"
                  validate={required}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  placeholder={translate('common.password')}
                  type="password"
                  name="password"
                  validate={validatePassword}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button type="submit" primary full disabled={Object.keys(errors).length > 0} isLoading={isSubmitting}>
                  {translate('auth.signUp')}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <hr className="my-4 border-t border-slate-300" />
              </FieldsetItem>

              <FieldsetItem>
                <div className="space-y-4">
                  <p className="text-base font-medium text-slate-600">{translate('auth.alreadyHaveAccount')}</p>
                  <Button secondary full onClick={() => navigate('/auth/login')}>
                    {translate('auth.logIn')}
                  </Button>
                </div>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapActionCreators = {
  signup
}

SignUp.propTypes = {
  signup: func.isRequired
}

export default connect(null, mapActionCreators)(SignUp)
