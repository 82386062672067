import React from 'react'
import moment from 'moment'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

import GlobalAlert from 'components/GlobalAlert'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  margin-bottom: ${spacing.medium};
  padding: 0 ${spacing.small};

  ${media.breakpoint`
    padding: 0;
  `};
`

export const isSellInEnded = (endDate) => moment(endDate).isBefore(new Date(), 'dates')

const SellInEndedAlert = ({ isVolumeProgram, endDate, isEnded }) => {
  const programEnded = isEnded || !endDate || isSellInEnded(endDate)
  if (!programEnded) return null
  const isEndedMessage = `This program has ended, you will no longer be able to ${
    isVolumeProgram ? 'enter quantities or ' : ''
  }accept/reject the sell-in`
  return (
    <Container>
      <GlobalAlert>{isEndedMessage}</GlobalAlert>
    </Container>
  )
}

SellInEndedAlert.propTypes = {
  isVolumeProgram: bool,
  endDate: string,
  isEnded: bool
}

export default SellInEndedAlert
