import React from 'react'
import { string } from 'prop-types'

import Icon from 'components/Icon'

import Tooltip from './Tooltip'

const HintToolTip = ({ hint }) => (
  <Tooltip hint={hint}>
    <div className="-mt-1 ml-1 text-slate-600">
      <Icon icon="help" small />
    </div>
  </Tooltip>
)

HintToolTip.propTypes = {
  hint: string
}

export default HintToolTip
