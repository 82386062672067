import React from 'react'
import { number, string } from 'prop-types'

import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Tooltip from 'components/Tooltip'

import { getAddressString } from 'utils/helpers'

const StoreInfoTooltip = ({ displayName, customerName, erp, address, ownershipType, linkTo }) => {
  const includedInfo = ['line1', 'city', 'state', 'post_code', 'country']
  const addressStr = getAddressString(address, includedInfo)

  const tooltipContent = (
    <div className="space-y-2 p-1">
      <div>
        <p className="font-semibold">{customerName}</p>
        <p className="tabular-nums">{erp}</p>
      </div>

      <p className="text-xs">{addressStr}</p>
      <p className="text-xs">{ownershipType}</p>
    </div>
  )

  const props = linkTo ? { linkTo } : {}

  return (
    <Tooltip isNumber={false} hint={tooltipContent}>
      <GeoTableLineHeader name={displayName} {...props} />
    </Tooltip>
  )
}

export default StoreInfoTooltip

StoreInfoTooltip.propTypes = {
  displayName: string,
  customerName: string,
  erp: number,
  address: string,
  ownershipType: string,
  linkTo: string
}
