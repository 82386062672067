import React, { useContext } from 'react'
import config from 'config'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'

import { UNIT_OF_MEASURE_OPTIONS, VAPE_PRODUCT_CATEGORY, VAPE_UNIT_OF_MEASURE_OPTIONS } from 'utils/constants'

import SellinContext from './SellinContext'
const SellinHeader = ({ parentHeader, header }) => {
  const { vapeCategory, setVapeCategory, unitOfMeasure, setUnitOfMeasure } = useContext(SellinContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)

  const uomOption =
    currentProductType === 'fmc'
      ? UNIT_OF_MEASURE_OPTIONS.filter(({ value }) => value !== 'pck')
      : VAPE_UNIT_OF_MEASURE_OPTIONS
  const filters = []
    .concat(!isWideLayout && <ProductTypePicker key="amplify-sell-in-product-type-picker" />)
    .concat(
      currentProductType === 'vape' && (
        <Dropdown
          key="amplify-sell-in-vape-category-picker"
          name="vape-category"
          onChange={(e) => setVapeCategory(e.target.value)}
          value={vapeCategory}
          options={VAPE_PRODUCT_CATEGORY}
          shouldUpperCase
        />
      )
    )
    .concat(
      config.featureFlags.uom && (
        <Dropdown
          key="amplify-sell-in-unit-of-measure"
          name="unit-of-measure"
          onChange={(e) => setUnitOfMeasure(e.target.value)}
          value={unitOfMeasure}
          options={uomOption}
          shouldUpperCase
        />
      )
    )
    .filter(Boolean)

  return (
    <ViewHeader
      breadcrumbs={
        [
          // { text: parentHeader, href: '../' },
          // { text: header, href: '' }
        ]
      }
      header={header}
      filters={filters}
    />
  )
}

SellinHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default SellinHeader
