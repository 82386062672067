import React, { useRef } from 'react'
import Dropzone from 'react-dropzone'
import { rgba } from 'polished'
import { bool } from 'prop-types'
import styled from 'styled-components'

import Button from 'components/button/Button'
import Icon from 'components/Icon'

import { getInputFieldProps } from 'utils/helpers'

import { black, borderColor, grey, greyLight, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, cover } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: block;
  width: 100%;
  text-align: left;
`

const DropzoneContainer = styled.div`
  padding: ${spacing.xLarge};
  border: 1px dashed ${borderColor};
  cursor: pointer;
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${greyLight};
  border-radius: ${borderRadius};
  background-color: ${rgba(white, 0.5)};
  transition: color ${animationTime} ${animationCurve};
  margin-bottom: ${spacing.medium};

  &:hover,
  &:focus {
    color: ${grey};
  }

  ${media.breakpoint`
    padding: ${spacing.xxLarge};
  `};
`

const DropzoneIcon = styled(Icon)`
  margin-bottom: ${spacing.small};
`

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.tiny};
`

const GalleryItem = styled.div`
  width: 25%;
  padding: ${spacing.tiny};
`

const PhotoWrap = styled.div`
  position: relative;
  border-radius: ${borderRadius};
  padding-bottom: 100%;
  overflow: hidden;
`

const Photo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
`

const Remove = styled.button.attrs({
  type: 'button'
})`
  ${cover('absolute')};
  z-index: 10;
  background-color: ${rgba(black, 0.6)};
  color: ${white};
`

const AddButton = styled(Button).attrs({ type: 'button' })`
  margin-top: ${spacing.medium};
`

const FormImageInput = ({ disabled, ...props }) => {
  const dropzoneRef = useRef()
  const inputFieldProps = getInputFieldProps(props)
  // const meta = getMeta(props)
  const onDrop = (images) => {
    if (disabled) return
    inputFieldProps.onChange((inputFieldProps.value || []).concat(images))
  }

  const removeImage = (key, { preview, path }) => {
    const updatedImageArray = inputFieldProps.value.filter(
      (img) => (!img.path || img.path !== path) && (!img.key || img.key !== key)
    )
    if (preview) {
      window.URL.revokeObjectURL(preview)
    }
    inputFieldProps.onChange(updatedImageArray)
  }

  const noImages = !inputFieldProps.value?.length

  return (
    <Container>
      <Dropzone ref={dropzoneRef} onDrop={onDrop} multiple accept={{ 'image/*': [] }} disabled={disabled}>
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer {...getRootProps({ hidden: !noImages })}>
            <input {...getInputProps()} />
            <DropzoneIcon icon={disabled ? 'lock' : 'add-photo'} />
            {disabled ? <span>Image upload disabled</span> : <span>Drag and drop or tap to upload your photos</span>}
          </DropzoneContainer>
        )}
      </Dropzone>
      {!noImages && (
        <>
          <Gallery>
            {inputFieldProps.value.map((file, idx) => {
              const { path, key, url, createdAt } = file
              const preview = file instanceof File ? window.URL.createObjectURL(file) : null
              return (
                <GalleryItem key={idx}>
                  <PhotoWrap>
                    <Photo src={preview || `${url}/${key}`} alt={new Date(createdAt).toString()} />
                    {!disabled && (
                      <Remove onClick={() => removeImage(key, { preview, path })}>
                        <Icon icon="trash" />
                      </Remove>
                    )}
                  </PhotoWrap>
                </GalleryItem>
              )
            })}
          </Gallery>
          {!disabled && inputFieldProps.value.length <= 3 && (
            <AddButton secondary onClick={() => dropzoneRef.current.open()}>
              Add more photos
            </AddButton>
          )}
        </>
      )}
    </Container>
  )
}

FormImageInput.propTypes = {
  disabled: bool
}

export default FormImageInput
