import React from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { bool, string } from 'prop-types'
import styled from 'styled-components'

import Label from 'components/Label'

const Container = styled.div`
  display: block;
  width: 100%;
`

const Select = (props) => (
  <Container className={props.className}>
    {props.label && <Label>{props.label}</Label>}
    {props.isAsync ? (
      <AsyncSelect onValueClick={(e) => e.stopPropagation()} {...props} />
    ) : (
      <ReactSelect onValueClick={(e) => e.stopPropagation()} {...props} />
    )}
  </Container>
)

Select.propTypes = {
  label: string,
  isAsync: bool,
  className: string
}

export default Select
