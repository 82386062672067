import React, { useContext, useEffect } from 'react'
import PriceCheckCard from 'views/plan/PriceCheckCard'

import SectorContext from 'context/SectorContext'

import BrandCompletion from './BrandCompletion'
import GeographyTable from './GeographyTable'
import PriceCaptureHeader from './PriceCaptureHeader'
import StoreFootprint from './StoreFootprint'

const AmplifyPriceCaptureLayout = () => {
  const { selectedLevel } = useContext(SectorContext)

  useEffect(() => {
    document.title = 'Amplify - Price Capture'
  }, [])

  const showFootprint = selectedLevel !== 'customer'
  const xlCardWidth = showFootprint ? 4 : 6

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
      <PriceCaptureHeader parentHeader="Amplify" header="Price Capture" />
      <PriceCheckCard span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: xlCardWidth }} displayAmplify={false} />
      <BrandCompletion span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: xlCardWidth }} />
      {showFootprint && <StoreFootprint span={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 4 }} />}
      <GeographyTable span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
    </div>
  )
}

export default AmplifyPriceCaptureLayout
