import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { allCustomers } from 'store/customers/selectors'
import { allDistricts } from 'store/districts/selectors'
import { allRegions } from 'store/regions/selectors'
import { dataSourceFromSectorLevel } from 'store/sector/selectors'
import { allTerritories } from 'store/territories/selectors'

const getProps = (state, props) => props
const allCampaigns = (state) => omit(state.cycleCampaigns, '_persist')
const campaignIdFromProps = (state, props) => props.campaignId

export const activeCycleCampaigns = createSelector(allCampaigns, (campaigns) => {
  return Object.values(campaigns).filter((c) => c.isVisible)
})

export const selectSectorCycleCampaigns = createSelector(
  activeCycleCampaigns,
  allCustomers,
  allTerritories,
  allDistricts,
  allRegions,
  getProps,
  (cycleCampaigns, customers, territories, districts, regions, { sectorType, sectorId }) => {
    let campaignIds = []
    switch (sectorType) {
      case 'customer':
        campaignIds = customers[sectorId]?.cycleCampaigns
        break
      case 'territory':
        campaignIds = territories[sectorId]?.cycleCampaigns
        break
      case 'district':
        campaignIds = districts[sectorId]?.cycleCampaigns
        break
      case 'region':
        campaignIds = regions[sectorId]?.cycleCampaigns
        break
    }
    return cycleCampaigns.filter((campaign) => (campaignIds || []).includes(campaign.id))
  }
)

export const prioritizedCycleCampaigns = createSelector(selectSectorCycleCampaigns, (campaigns) =>
  sortBy(campaigns, ['sequence']).map((campaign, index) => ({
    ...campaign,
    name: `${index + 1} - ${campaign.name}`
  }))
)

export const activeCycleCampaignOptions = createSelector(prioritizedCycleCampaigns, (campaigns) => {
  return campaigns.map((c) => ({
    label: c.name,
    value: c.id
  }))
})

export const campaignDetails = createSelector(dataSourceFromSectorLevel, campaignIdFromProps, (sector, campaignId) => {
  if (!sector) return null
  return sector[`planning-cycle-planning-campaign-${campaignId}`]
})
