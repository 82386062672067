import React, { useContext } from 'react'
import { isNumber } from 'lodash'
import { number, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import VariationIndicator from 'components/VariationIndicator'

import { EngagementData } from './EngagementData'
import { PriceCheckData } from './PriceCheckData'
import { SellInData } from './SellInData'
import { SOMData } from './SOMData'
import { WeightedDistroData } from './WeightedDistroData'

export const CardContent = tw.div`h-full w-full flex relative flex-col items-center gap-5 pb-5 justify-center`

export const CardHeading = ({ children }) => (
  <div className="w-full border-b border-slate-900/10 pb-3">
    <h2 className="w-full text-center text-xl font-semibold text-slate-900">{children}</h2>
  </div>
)

CardHeading.propTypes = {
  children: string.isRequired
}

export const DataVariation = ({ variation, timeRange }) => (
  <div className="flex items-center space-x-2">
    <VariationIndicator variation={variation} />
    <span className="text-sm font-medium text-slate-500">
      {Math.abs(variation).toFixed(2)} vs. {timeRange}
    </span>
  </div>
)

DataVariation.propTypes = {
  variation: number.isRequired,
  timeRange: string.isRequired
}

export const NotTargeted = () => (
  <p className="flex h-full items-center justify-center text-center text-base font-medium text-slate-300">
    Not targeted
  </p>
)

export const HiddenIndicator = () => {
  const { translate } = useContext(LangContext)

  return (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
      <div className="pointer-events-none z-10 select-none rounded bg-white px-3 py-2 shadow-lg ring-1 ring-slate-900/10 blur-none">
        {translate('common.comingSoon')}
      </div>
    </div>
  )
}

export const Data = ({ data, additionalData, size = 'default', title }) => (
  <div className={`flex grow flex-col items-center justify-center ${size === 'default' ? 'space-y-2' : 'space-y-0.5'}`}>
    <span className="text-md font-medium text-slate-500">{title}</span>
    <div className="flex flex-col items-center gap-1">
      {data.isTracked && data.value !== null ? (
        <div className="flex flex-col items-center gap-1">
          <span className={`${size === 'default' ? 'text-3xl' : 'text-2xl'} font-medium`}>
            {data.value.toFixed(2)}%
          </span>
          {additionalData.type === 'variation'
            ? additionalData.data.variation.amount != null && (
                <DataVariation
                  variation={additionalData.data.variation.amount}
                  timeRange={additionalData.data.variation.time}
                />
              )
            : isNumber(additionalData.data.actuals.amount) && (
                <span className="text font-medium">
                  {additionalData.data.actuals.amount.toLocaleString()}/
                  {additionalData.data.actuals.total.toLocaleString()}
                </span>
              )}
        </div>
      ) : (
        <NotTargeted />
      )}
    </div>
  </div>
)

Data.propTypes = {
  data: object,
  additionalData: {
    type: 'variation' | 'totals',
    data: {
      variation: { amount: number, time: string },
      actuals: {
        amount: number,
        total: number
      }
    }
  },
  title: string.isRequired,
  size: string
}

export { SellInData, SOMData, WeightedDistroData, PriceCheckData, EngagementData }
