import React, { useContext } from 'react'
import { arrayOf, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import Button from 'components/button/Button'
import Icon from 'components/Icon'

import { listArrayContent } from 'utils/helpers'

const IncompatibleScope = ({ page, acceptedScopes }) => {
  const { translate } = useContext(LangContext)
  const { setModalOpen, selectedLevel } = useContext(SectorContext)

  const translatedScopes = acceptedScopes.map((scope) => translate(`app.geo.${scope}`))

  const acceptedScopesString = listArrayContent(translatedScopes, translate('common.or'))

  return (
    <div className="-mt-16 flex h-[calc(100dvh-5rem)] w-full flex-col items-center justify-center gap-8 px-12 text-center sm:px-16 md:h-[calc(100dvh-7.5rem)] md:px-24">
      <Icon icon="wrong-location" className="mb-4 !h-12 !w-12 text-slate-500" />
      <h2 className="max-w-xl text-xl font-medium text-slate-900 ">
        {translate('app.incompatibleScope.template.warning', {
          page,
          selectedScope: translate(`app.geo.${selectedLevel}`)
        })}
      </h2>
      <p className="text-slate-800">
        {translate('app.incompatibleScope.cta.lead')}
        <span className="inline-block">
          <Button tertiary onClick={() => setModalOpen(true)}>
            {translate('app.incompatibleScope.cta.button')}
          </Button>
        </span>
        {translate('app.incompatibleScope.cta.template.acceptedScopes', {
          acceptedScopes: acceptedScopesString
        })}
      </p>
    </div>
  )
}

IncompatibleScope.propTypes = {
  page: string.isRequired,
  acceptedScopes: arrayOf(string).isRequired
}

export default IncompatibleScope
