import React, { useContext } from 'react'
import { bool, node } from 'prop-types'

import LangContext from 'context/LangContext'

function CardPlaceholder({ children, passThrough = false }) {
  const { translate } = useContext(LangContext)

  return (
    <div className="relative flex grow flex-col">
      {!passThrough && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
          <div className="pointer-events-none z-10 select-none rounded bg-white px-3 py-2 shadow-lg ring-1 ring-slate-900/10 blur-none">
            {translate('common.comingSoon')}
          </div>
        </div>
      )}
      <div
        className={`${
          !passThrough && 'pointer-events-none flex h-full select-none items-center justify-center opacity-90 blur'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

CardPlaceholder.propTypes = {
  children: node,
  passThrough: bool
}

export default CardPlaceholder
