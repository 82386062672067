import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CustomerPricingView from 'views/actions/pricing/CustomerPricingView'
import PricingPerformance from 'views/TerritoryPerformance/PricingPerformance'

import { SECTOR_LEVELS } from 'utils/constants'

const PricingIndex = () => {
  const { sectorType } = useParams()

  useEffect(() => {
    document.title = 'Actions - Pricing'
  }, [])

  return (
    <>
      {sectorType === SECTOR_LEVELS.TERRITORY && <PricingPerformance />}
      {sectorType === SECTOR_LEVELS.CUSTOMER && <CustomerPricingView />}
    </>
  )
}

export default PricingIndex
