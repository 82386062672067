import React from 'react'
import { Link } from 'react-router-dom'
import { string } from 'prop-types'

import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'

const GeoTableLineHeader = ({ name, linkTo }) => {
  return (
    <div className="flex w-full items-center gap-2 max-md:max-w-64">
      <div className="shrink-0 text-slate-500 md:hidden">
        <Tooltip hint={name}>
          <Icon icon="info-outline" small />
        </Tooltip>
      </div>
      {linkTo ? (
        <Link
          to={linkTo}
          className="flex min-w-0 items-center gap-1 border-b-[1.5px] border-slate-300 transition-colors hover:border-slate-900"
        >
          <span className="truncate">{name}</span>
        </Link>
      ) : (
        <span className="truncate">{name}</span>
      )}
    </div>
  )
}

GeoTableLineHeader.propTypes = {
  name: string,
  linkTo: string
}

export default GeoTableLineHeader
