import React, { useState } from 'react'
import { node } from 'prop-types'

const CalendarWrapper = ({ children, ...rest }) => {
  const [isStyleSheetLoaded, setIsStyleSheetLoaded] = useState(false)

  const handleStylesheetLoaded = () => {
    setIsStyleSheetLoaded(true)
  }

  return (
    <div {...rest} className="h-[calc(100dvh-132px)] md:h-[calc(100dvh-68px)]">
      <link rel="stylesheet" href="/styles/calendarDefaultStyles.css" onLoad={handleStylesheetLoaded} />

      {isStyleSheetLoaded && <>{children}</>}
    </div>
  )
}

CalendarWrapper.propTypes = {
  children: node
}

export default CalendarWrapper
