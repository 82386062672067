import React, { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { bool, number, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import SectorContext from 'context/SectorContext'

import Status from 'components/Status'

import { SECTOR_LEVELS } from 'utils/constants'

import { borderColor, greyLight, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize, tinyFontSize } from 'styles/typography'
// import { fetchMessages } from 'store/messages/actions'

const Container = styled(Link)`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.small} ${spacing.medium};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }

    &:first-child {
      border-top: 1px solid ${borderColor};
    }
  `};
`

const Header = styled.header`
  margin-bottom: ${spacing.small};
`

const Footer = styled.footer`
  display: flex;
`

const FooterItem = styled.div`
  position: static;

  & + & {
    margin-left: ${spacing.medium};
  }
`

const Comment = styled(ReactMarkdown)`
  margin-bottom: ${spacing.small};
  font-size: ${smallFontSize};
  font-style: italic;
`

const TimeStamp = styled.small`
  display: block;
  color: ${greyLight};
  font-size: ${tinyFontSize};
`

const User = styled.span`
  display: block;
  font-size: ${smallFontSize};
`

const InboxMessage = ({
  id,
  customerId,
  text = '',
  author,
  createdAt,
  tempId,
  isMyComment,
  status,
  assignee,
  assignmentExpiry
}) => {
  const { fetchSector } = useContext(SectorContext)

  return (
    <Container onClick={() => fetchSector({ type: SECTOR_LEVELS.CUSTOMER, id: customerId })}>
      <Header>
        <Status {...status} label={`ERP: ${customerId}`} />
      </Header>
      <Comment source={text} />
      <Footer>
        <FooterItem>
          <User>By {isMyComment ? 'you' : author ? author.fullName : 'Admin'}</User>
          <TimeStamp>at {moment(createdAt).format('MMM D h:mm a')}</TimeStamp>
        </FooterItem>
        {assignee && assignmentExpiry && (
          <FooterItem>
            <User>Assigned to {assignee.fullName}</User>
            <TimeStamp>until {moment(assignmentExpiry).format('MMM D h:mm a')}</TimeStamp>
          </FooterItem>
        )}
      </Footer>
    </Container>
  )
}

InboxMessage.propTypes = {
  text: string.isRequired,
  id: oneOfType([number, string]).isRequired,
  tempId: string,
  customerId: number.isRequired,
  status: object.isRequired,
  author: object,
  createdAt: string.isRequired,
  isMyComment: bool,
  assignee: object,
  assignmentExpiry: string
}

export default InboxMessage
