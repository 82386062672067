import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import { verifyRetailer } from 'store/auth/actions'

import { Spinner } from 'components/button/Button'
import GlobalAlert from 'components/GlobalAlert'

const errorMessages = {
  missingRetailerId: <GlobalAlert warning>This page is only accessible through a valid link</GlobalAlert>,
  offlineError: <GlobalAlert>You cannot fetch your information without an internet connection.</GlobalAlert>,
  invalidRetailerId: (
    <GlobalAlert>We cannot find your information. Please make sure you have copied the link correctly.</GlobalAlert>
  ),
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

const RetailerVerification = ({ verifyRetailer }) => {
  const navigate = useNavigate()
  const { target, retailerId } = useParams()

  const isLoading = useSelector(({ auth }) => auth.loading)

  const [error, setError] = useState(null)

  useEffect(() => {
    if (!retailerId) {
      setError(errorMessages.missingRetailerId)
      return
    }
    verifyRetailer(retailerId, navigate, target).catch((res) => {
      const retailerError =
        !res.response || res.response.status === 500 || !res.response.data.message
          ? 'generic'
          : res.response.data.message
      setError(errorMessages[retailerError] || errorMessages.generic)
    })
  }, [navigate, retailerId, target, verifyRetailer])

  return (
    <>
      {!error && <p>Please wait while we fetch your information.</p>}
      {isLoading && <Spinner icon="spinner" />}
      {error}
    </>
  )
}

RetailerVerification.propTypes = {
  verifyRetailer: func.isRequired
}

const mapActionCreators = {
  verifyRetailer
}

export default connect(null, mapActionCreators)(RetailerVerification)
