import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { addEntities } from 'store/actions'
import { callTask as callTaskSchema } from 'store/schema'

import * as api from './endpoints'

export const fetchCallTasks = createAction('Fetch call tasks', () => async (dispatch) => {
  try {
    const { data } = await api.fetchCallTasks()
    const { entities } = normalize(data.callTasks, [callTaskSchema])
    dispatch(addEntities(entities))
  } catch (err) {
    console.dir(err)
    throw err
  }
})
