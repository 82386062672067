import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Accordion from '@radix-ui/react-accordion'
import { func, shape, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerOrders } from 'store/customers/actions'
import { customerOrders } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import Pill from 'components/Pill'
import SAQDetailRow from 'components/SAQDetailRow'
import { WrappedSpinner } from 'components/Spinner'
import Status from 'components/Status'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import { accents, white } from 'styles/colors'

const calculateTotalQty = (entries) => entries.reduce((sum, entry) => sum + entry.qty, 0)

const OrderRecap = ({ getCustomerOrders }) => {
  const AccordionTrigger = ({ orderData }) => {
    const { translate } = useContext(LangContext)

    return (
      <Accordion.Trigger asChild>
        <tr className="group/item cursor-pointer select-none *:px-4 *:py-3 hover:bg-slate-50">
          <td>{new Date(orderData.updatedAt).toDateString()}</td>
          <td>{calculateTotalQty(orderData.entries)}</td>
          <td>
            <Status status={orderData.status} />
            <br />
            <Pill textColor={white} color={orderData.type === 'emergency' ? accents.red : ''}>
              {translate(`app.orderType.${orderData.type}`)}
            </Pill>
          </td>
          <td className="!p-0">
            <Icon icon="down-chevron" compact className="text-slate-500 group-data-[state=open]/item:rotate-180" />
          </td>
        </tr>
      </Accordion.Trigger>
    )
  }

  AccordionTrigger.propTypes = {
    orderData: shape({}).isRequired
  }

  const OrderByType = ({ productsData, type }) => {
    return (
      <React.Fragment key={type}>
        <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
          <td>{type}</td>
          <td className="text-right">{calculateTotalQty(productsData)} CTNS</td>
        </tr>
        {productsData.map((data) => (
          <tr key={data.product.id} className="*:py-1 *:text-slate-700">
            <td>{data.product.englishName}</td>
            <td className="text-right">{data.qty}</td>
          </tr>
        ))}
      </React.Fragment>
    )
  }

  OrderByType.propTypes = {
    productsData: shape({}).isRequired,
    type: string.isRequired
  }

  const AccordionContent = ({ orderData }) => {
    const { translate } = useContext(LangContext)

    const filterProductsByCategory = useCallback(
      (entries, category) => entries.filter((entry) => entry.product.category === category),
      []
    )

    const vuseFilteredProducts = filterProductsByCategory(orderData.entries, 'vype')
    const fmcFilteredProducts = filterProductsByCategory(orderData.entries, 'fmc')

    return (
      <Accordion.Content asChild className="overflow-hidden">
        <tr className="border-b border-slate-900/10 *:p-4">
          <td colSpan="4" className="w-full">
            <div>
              <SAQDetailRow label={translate('app.order#')} content={<p>{orderData.id || '-'}</p>} />
              <SAQDetailRow
                label={translate('common.placedBy')}
                content={<p>{orderData.employee.fullName || '-'}</p>}
              />
              <SAQDetailRow label={translate('app.purchaseOrder')} content={<p>{orderData.purchaseOrder || '-'}</p>} />
              {/* {isPo98Order && order.updatedAt ? (
            <SAQDetailRow
              label={translate('common.updatedAt')}
              content={<p>{new Date(order.updatedAt).toDateString()}</p>}
            />
          ) : null}
          {isPo98Order && order.status !== 'created' && (
            <SAQDetailRow
              label={translate('app.statusUpdatedAt')}
              content={<p>{new Date(order.updatedAt).toDateString()}</p>}
            />
          )}
          {order.confirmationDate && (
            <SAQDetailRow
              label={translate('common.confirmedAt')}
              content={<p>{new Date(order.confirmationDate).toDateString()}</p>}
            />
          )}
          {isPo98Order && order.po98PoNumber && (
            <SAQDetailRow label={translate('app.P0Number')} content={<p>{order.po98PoNumber}</p>} />
          )}
          {order.purchaseOrder && (
            <SAQDetailRow
              label={translate('app.purchaseOrder') + ':'}
              content={<p>{order.purchaseOrder}</p>}
            />
          )} */}
              <div className="mt-4 rounded bg-slate-50 p-4">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="*:font-medium *:uppercase *:text-slate-500">
                      <th className="text-left">{translate('common.product')}</th>
                      <th className="text-right">{translate('common.qty')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vuseFilteredProducts && <OrderByType type="Vuse" productsData={vuseFilteredProducts} />}
                    {fmcFilteredProducts && <OrderByType type="FMC" productsData={fmcFilteredProducts} />}

                    <tr className="*:pb-2 *:pt-4 *:font-semibold *:text-slate-900">
                      <td>Total</td>
                      <td className="text-right">{calculateTotalQty(orderData.entries)} CTNS</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </Accordion.Content>
    )
  }

  AccordionContent.propTypes = {
    orderData: shape({}).isRequired
  }

  const { sectorId: customerId, sectorLevel } = useParams()
  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ORDERS, { customerId })

  const latestOrderRecaps = useSelector((state) => customerOrders(state, { customerId }))

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const [error, setError] = useState()

  useEffect(() => {
    if (sectorLevel === SECTOR_LEVELS.CUSTOMER && customerId) {
      setError()
      getCustomerOrders({ customerId, dataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [customerId])

  const { translate } = useContext(LangContext)

  if (isLoading) return <WrappedSpinner icon="spinner" />

  if (error) return <EmptyState title={getErrorMessage(error)} />

  const getContent = () => {
    return (
      <Accordion.Root type="single" collapsible asChild>
        <table className="w-full rounded-md ring-1 ring-slate-900/10">
          <thead>
            <tr className="border-b border-slate-900/10 bg-slate-100 *:px-4 *:py-3 *:text-left *:font-normal *:text-slate-500">
              <th className="rounded-tl-md">Date</th>
              <th>{translate('common.qty')}</th>
              <th>{translate('common.status')}</th>
              <th className="rounded-tr-md content-['']" />
            </tr>
          </thead>
          <tbody className="group/table divide-y divide-slate-900/10 overflow-hidden">
            {latestOrderRecaps.slice(0, 10).map((order) => (
              <Accordion.Item value={order.id} key={order.id} asChild>
                <>
                  <AccordionTrigger orderData={order} />
                  <AccordionContent orderData={order} />
                </>
              </Accordion.Item>
            ))}
          </tbody>
        </table>
      </Accordion.Root>
    )
  }

  return getContent()
}

OrderRecap.propTypes = {
  getCustomerOrders: func
}

export default connect(null, { getCustomerOrders })(OrderRecap)
