import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import { verifyRetailer } from 'store/auth/actions'
import { customerFromUrl } from 'store/customers/selectors'

import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

const retailerAuth = (Component) => {
  const Base = ({ verifyRetailer }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { customerId, target } = useParams()

    const customer = useSelector((state) => customerFromUrl(state, { customerId }))

    useEffect(() => {
      window.scroll(0, 0)
    }, [location])

    useEffect(() => {
      const localRetailerId = sessionStorage.getItem('retailerId')

      if (localRetailerId) {
        try {
          verifyRetailer(localRetailerId, navigate, target)
        } catch (err) {
          navigate(`/auth/retailer/${target}/${localRetailerId}${location.search}`, {
            replace: true,
            state: { error: err }
          })
        }
      }
    }, [location.search, navigate, target, verifyRetailer])

    const localRetailerId = sessionStorage.getItem('retailerId')

    if (!localRetailerId || !customerId) {
      return (
        <Navigate
          to={{
            pathname: `/auth/retailer/${target}/${localRetailerId || ''}${location.search}`,
            state: { from: location }
          }}
        />
      )
    }

    if (localRetailerId && !customer) {
      return (
        <EmptyState title="Fetching..." subtitle="Just a moment while we fetch this store's data">
          <Spinner icon="spinner" />
        </EmptyState>
      )
    }

    if (localRetailerId && customer && customer.retailerId === localRetailerId) {
      return <Component customer={customer} verifyRetailer={verifyRetailer} target={target} />
    }
  }

  Base.propTypes = {
    verifyRetailer: func
  }

  return connect(null, { verifyRetailer })(Base)
}

export default retailerAuth
