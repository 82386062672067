import React, { useContext, useMemo, useState } from 'react'
import { array, func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Dropdown from 'components/Dropdown'
import FilteredDropdown from 'components/FilteredDropdown'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

const FilterRow = ({ filterTypes, selectedFilter, activeFilters, onFilterChange, onRemove }) => {
  // Create options array with disabled state for used filters
  const filterTypeOptions = useMemo(() => {
    const usedTypes = new Set(activeFilters.map((f) => f.type))
    return filterTypes.map((type) => ({
      label: type.label,
      value: type.value,
      // Only disable if it's used AND it's not the current filter's type
      disabled: usedTypes.has(type.value) && type.value !== selectedFilter.type
    }))
  }, [filterTypes, activeFilters, selectedFilter.type])

  // Get the options for the selected filter type
  const filterOptions = useMemo(() => {
    return filterTypes.find((t) => t.value === selectedFilter.type)?.options || []
  }, [filterTypes, selectedFilter.type])

  const handleFilterTypeChange = (e) => {
    // Find the new filter type's options
    const newFilterOptions = filterTypes.find((t) => t.value === e.target.value)?.options || []
    // Update with the first available option from the new filter type
    onFilterChange({
      type: e.target.value,
      value: newFilterOptions[0]?.value
    })
  }

  return (
    <div className="flex w-full items-center gap-2">
      <div className="flex w-full min-w-0 items-center gap-2 max-md:flex-col max-md:items-start max-md:border-r max-md:border-slate-200 max-md:pr-3">
        <div className="shrink-0">
          <Dropdown options={filterTypeOptions} value={selectedFilter.type} onChange={handleFilterTypeChange} />
        </div>
        <div className="w-full min-w-0">
          <FilteredDropdown
            options={filterOptions}
            value={selectedFilter.value}
            onChange={(value) => onFilterChange({ ...selectedFilter, value })}
          />
        </div>
      </div>
      <div className="shrink-0">
        <IconButton ghost onClick={onRemove} icon="trash" compact />
      </div>
    </div>
  )
}

FilterRow.propTypes = {
  filterTypes: array.isRequired,
  selectedFilter: object.isRequired,
  onFilterChange: func.isRequired,
  onRemove: func.isRequired,
  activeFilters: array.isRequired
}

const GeographyFilterBuilder = ({ filters, onFiltersChange }) => {
  const { translate } = useContext(LangContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [previousFilters, setPreviousFilters] = useState([])

  const activeFiltersCount = activeFilters.length

  const hasActiveFilters = activeFiltersCount > 0

  // Create filter type options from the provided filters
  const filterTypes = useMemo(() => {
    return filters.map((filter) => ({
      label: filter.label,
      value: filter.id,
      options: filter.options
    }))
  }, [filters])

  const addFilter = () => {
    const usedTypes = new Set(activeFilters.map((f) => f.type))
    const firstAvailableFilter = filterTypes.find((type) => !usedTypes.has(type.value))

    if (!firstAvailableFilter) return

    const newFilter = {
      type: firstAvailableFilter.value,
      value: firstAvailableFilter.options?.length ? firstAvailableFilter.options[0].value : null
    }
    setActiveFilters([...activeFilters, newFilter])
  }

  const removeFilter = (filterToRemove) => {
    setActiveFilters(
      activeFilters.filter((filter) => filter.type !== filterToRemove.type || filter.value !== filterToRemove.value)
    )
  }

  const updateFilter = (oldFilter, newValues) => {
    setActiveFilters(
      activeFilters.map((filter) =>
        filter.type === oldFilter.type && filter.value === oldFilter.value ? newValues : filter
      )
    )
  }

  const handleApplyFilters = () => {
    if (activeFilters.length === 0) {
      // If no active filters, set all filters to "all"
      const defaultFilters = {}
      filters.forEach((filter) => {
        defaultFilters[filter.id] = 'all'
      })
      onFiltersChange(defaultFilters)
    } else {
      // Handle active filters as before
      const newFilters = {}
      activeFilters.forEach((filter) => {
        newFilters[filter.type] = filter.value
      })
      onFiltersChange(newFilters)
    }
    setModalOpen(false)
  }

  const handleCancel = () => {
    // Restore the previous state of filters
    setActiveFilters(previousFilters)
    setModalOpen(false)
  }

  const clearAllFilters = () => {
    setActiveFilters([])
  }

  return (
    <>
      <Modal
        title={translate('common.filters')}
        open={modalOpen}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            // Store the current state of filters when opening
            setPreviousFilters([...activeFilters])
            setModalOpen(true)
          } else {
            handleCancel()
          }
        }}
        footer={
          <div className="flex items-center gap-2">
            <Button secondary onClick={handleCancel}>
              {translate('common.cancel')}
            </Button>
            <Button primary onClick={handleApplyFilters}>
              {translate('common.apply')}
            </Button>
          </div>
        }
      >
        <div className="flex h-full flex-col rounded-md ring-1 ring-slate-900/10">
          <div className="overflow-y-auto">
            <div className="m-3 space-y-3 max-md:space-y-4">
              {activeFilters.length === 0 ? (
                <div className="flex h-9 flex-col items-center justify-center gap-2 rounded-md bg-slate-50 p-3 text-center text-sm text-slate-700">
                  {translate('common.noFiltersApplied')}
                </div>
              ) : (
                activeFilters.map((filter) => (
                  <FilterRow
                    key={`${filter.type}-${filter.value}`}
                    filterTypes={filterTypes}
                    selectedFilter={filter}
                    activeFilters={activeFilters}
                    onFilterChange={(newValues) => updateFilter(filter, newValues)}
                    onRemove={() => removeFilter(filter)}
                  />
                ))
              )}
            </div>
          </div>
          <hr className="mx-3 border-slate-200" />
          <div className="m-3">
            {/* Desktop filter buttons */}
            <div className="flex justify-between max-md:hidden">
              <Button
                secondary
                onClick={addFilter}
                icon="plus-small"
                disabled={activeFiltersCount >= filterTypes.length}
              >
                {translate('common.addFilters')}
              </Button>
              <Button secondary onClick={clearAllFilters} icon="trash" disabled={activeFiltersCount === 0}>
                {translate('common.clearFilters')}
              </Button>
            </div>
            {/* Mobile filter buttons */}
            <div className="flex flex-col items-center justify-between gap-2 md:hidden">
              <Button
                secondary
                full
                onClick={addFilter}
                icon="plus-small"
                disabled={activeFiltersCount >= filterTypes.length}
              >
                {translate('common.addFilters')}
              </Button>
              <Button secondary full onClick={clearAllFilters} icon="trash" disabled={activeFiltersCount === 0}>
                {translate('common.clearFilters')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <button
        onClick={() => {
          setPreviousFilters([...activeFilters])
          setModalOpen(true)
        }}
        className={`${
          hasActiveFilters ? 'font-medium text-brand-800' : 'text-slate-800'
        } relative flex h-9 items-center gap-2 rounded-md bg-white px-4 shadow-md ring-1 ring-slate-900/10 transition hover:bg-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 active:bg-slate-500/5`}
      >
        {hasActiveFilters ? (
          <div className="-ml-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-brand-100 text-2xs text-brand">
            {activeFiltersCount}
          </div>
        ) : (
          <Icon className="-ml-0.5 flex h-5 w-5 shrink-0" icon="filter-list" compact />
        )}
        <span className="flex items-center whitespace-nowrap">{translate('common.filters')}</span>
      </button>
    </>
  )
}

GeographyFilterBuilder.propTypes = {
  filters: array.isRequired,
  onFiltersChange: func.isRequired
}

export default GeographyFilterBuilder
