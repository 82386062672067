import { omit } from 'lodash'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { addMileageToEmployee } from 'store/auth/actions'
import { addCallToCustomer, removeCallFromCustomer } from 'store/customers/actions'
import outbox from 'store/outbox'
import { call as callSchema, customer as customerSchema } from 'store/schema'

import { OUTBOX_KEYS, SYNC_ACTION_KEYS } from 'utils/constants'
import { ERRORS } from 'utils/helpers'

import * as api from './endpoints'

export const removeCustomerCall = createAction('Remove stored call')
export const addMessageToCustomerCall = createAction('Add Message To Customer Call')
export const addMileageToCustomerCall = createAction('Add Mileage To Customer Call')
export const removeMileageToCustomerCall = createAction('Remove Mileage To Customer Call')

const queueOfflineCallDeletion = async (call) => {
  const ootOutbox = (await outbox.getItem(OUTBOX_KEYS.DELETED_CALLS)) || {}
  const callUpsertOutbox = (await outbox.getItem(OUTBOX_KEYS.UPSERTED_CALLS)) || {}
  if (callUpsertOutbox[call.id]) {
    await outbox.setItem(OUTBOX_KEYS.UPSERTED_CALLS, omit(callUpsertOutbox, call.id))
  }
  await outbox.setItem(OUTBOX_KEYS.DELETED_CALLS, { ...ootOutbox, [call.id]: call })
  return registerSync(SYNC_ACTION_KEYS.DELETE_PENDING_CALLS)
}

const queueOfflineCallUpsert = async (call) => {
  const { callStart, callEnd, scheduledStart, scheduledEnd } = call
  const tempCall = { ...call, id: call.id || `temp-id-${Date.now()}` }

  if (callStart) tempCall.callStart = moment(callStart).format()
  if (callEnd) tempCall.callEnd = moment(callEnd).format()
  if (scheduledStart) tempCall.scheduledStart = moment(scheduledStart).format()
  if (scheduledEnd) tempCall.scheduledEnd = moment(scheduledEnd).format()

  const callOutbox = (await outbox.getItem(OUTBOX_KEYS.UPSERTED_CALLS)) || {}
  await outbox.setItem(OUTBOX_KEYS.UPSERTED_CALLS, { ...callOutbox, [tempCall.id]: tempCall })
  await registerSync(SYNC_ACTION_KEYS.SUBMIT_PENDING_CALLS)
  return tempCall
}

export const fetchCustomerCalls = createAction(
  'Fetch customer calls',
  ({ before, after, customerId }) =>
    async (dispatch) => {
      try {
        if (!window.navigator.onLine) throw ERRORS.offline
        const { data } = await api.fetchCustomerCalls({ before, after, customerId })
        const callsByCustomerId = groupBy(data.customerCalls, 'customerId')

        const normalizrInput = Object.entries(callsByCustomerId).map(([customerId, calls]) => ({
          id: customerId,
          ...calls[0].customer,
          calls
        }))

        const { entities } = normalize(normalizrInput, [customerSchema])
        dispatch(mergeEntities(entities))
      } catch (e) {
        console.error(e)
        throw e
      }
    }
)

export const upsertCustomerCall = createAction('Upsert customer call', (upsertedCall) => async (dispatch) => {
  try {
    let call
    if (window.navigator.onLine) {
      const { data } = await api.upsertCustomerCall(upsertedCall)
      call = data.customerCall
    } else {
      const offlineCall = await queueOfflineCallUpsert(upsertedCall)
      call = offlineCall
    }

    dispatch(addMileageToEmployee(upsertedCall.employeeKm))

    const { entities } = normalize(call, callSchema)
    dispatch(mergeEntities(entities))
    dispatch(addCallToCustomer(call))
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const deleteCustomerCall = createAction('Delete customer call', (callToDelete) => async (dispatch) => {
  try {
    if (window.navigator.onLine) {
      await api.deleteCustomerCall(callToDelete)
    } else {
      await queueOfflineCallDeletion(callToDelete)
    }
    dispatch(removeCustomerCall(callToDelete))
    dispatch(removeCallFromCustomer(callToDelete))
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const replaceCustomerCall = createAction(
  'Override Customer Call',
  ({ idToReplace, call }) =>
    async (dispatch) => {
      try {
        dispatch(removeCustomerCall({ id: idToReplace }))
        dispatch(addMileageToEmployee(call.employeeKm))
        const { entities } = normalize(call, callSchema)
        dispatch(mergeEntities(entities))
        dispatch(addCallToCustomer(call))
        return call
      } catch (err) {
        console.dir(err)
        throw err
      }
    }
)
export const submitSwapScheduleDays = createAction('Swap schedule days', (datesToSwap) => async (dispatch) => {
  try {
    const { data } = await api.swapScheduleDates(datesToSwap)

    const { entities } = normalize(data.customerCalls, [callSchema])
    dispatch(mergeEntities(entities))
  } catch (err) {
    console.dir(err)
    // console.log(JSON.stringify(err, Object.getOwnPropertyNames(err)))
    throw err
  }
})
