import { applyMiddleware, compose, createStore } from 'redux'
import fsaThunk from 'redux-fsa-thunk'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import reducer from './reducer'

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [fsaThunk, thunk]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []

  let composeEnhancers = compose

  if (__DEV__ && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false // This prevents replaying the whole action history on reducer HMR
    })
  }
  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middleware), ...enhancers))
  const persistor = persistStore(store)

  return { persistor, store }
}
