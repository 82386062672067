import { isEmpty, omit } from 'lodash'

import { DATA_UPDATE_STATUS } from 'utils/constants'

import { setDataFetch } from './actions'

const ACTION_HANDLERS = {
  [setDataFetch]: (state, { payload }) => {
    if (!payload?.dataKey) return state
    const { dataKey, ...statusUpdate } = payload

    if (isEmpty(statusUpdate)) return omit(state, dataKey)

    // If status is set to over, we just completed a fetch.
    if (statusUpdate.status === DATA_UPDATE_STATUS.OVER) {
      statusUpdate.lastFetch = Date.now()
    }
    return { ...state, [dataKey]: statusUpdate }
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
