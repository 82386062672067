import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Navigate, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import sum from 'lodash/sum'
import uniq from 'lodash/uniq'
import moment from 'moment'
import { bool, func, object, string } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'
import LangContext from 'context/LangContext'

import * as customerSelectors from 'store/customers/selectors'
import { submitNewSAQ, submitSAQEdit } from 'store/orders/actions'
import * as orderSelectors from 'store/orders/selectors'
import * as productSelectors from 'store/products/selectors'
import { getRegion } from 'store/territories/selectors'

import SAQAccordion from 'components/accordion/SAQAccordion'
import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import PO98ActionSheet from 'components/saq/PO98ActionSheet'
import SAQActionSheet from 'components/saq/SAQActionSheet'

import { SECTOR_LEVELS } from 'utils/constants'
import { excelSafeNanoid } from 'utils/helpers'

const MINIMUM_QUANTITY_REQUIRED = 5

function CreateSAQInner({ values, isSubmitting, globalError, isEdit, setFieldTouched }) {
  const { translate } = useContext(LangContext)

  const { addAction } = useContext(ActionHeaderContext)
  const { sectorId: customerId } = useParams()

  const customer = useSelector((state) => customerSelectors.customerFromUrl(state, { customerId }))
  const isRestrictedBannerInRestrictedPeriod = useSelector((state) =>
    customerSelectors.isRestrictedBannerInRestrictedPeriod(state, { customerId })
  )

  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const lastSubmittedEmergencyOrder = useSelector((state) =>
    orderSelectors.lastSubmittedEmergencyOrder(state, { customerId })
  )
  const saqSoxMaterialGroupIds = useSelector((state) => orderSelectors.saqSoxMaterialGroupIds(state, { customerId }))
  const orderComplianceThresholds = useSelector((state) =>
    customerSelectors.orderComplianceThresholds(state, { customerId })
  )
  const productsByBrand = useSelector((state) => productSelectors.productsByBrand(state, { customerId }))
  const region = useSelector((state) => getRegion(state, {}))
  const bannerOwnership = useSelector((state) => customerSelectors.getBannerOwnership(state, { customerId }))

  const idsToRemove = customer.assortment
    .filter((a) => a.materialGroupCode === 'ZEB801416')
    .map((product) => 'p'.concat(product.id))

  const mergedEntries = useMemo(() => Object.assign({}, ...Object.values(values.entries)), [values.entries])
  const qtyToRemove = sum(Object.values(pick(mergedEntries, uniq(idsToRemove))))
  const totalQuantity = useMemo(() => sum(Object.values(mergedEntries)), [mergedEntries])

  const [emailPromptVisible, setEmailPromptVisible] = useState(false)

  useEffect(() => {
    addAction({
      getCreateButton: () => (
        <Button key={1} onClick={() => setEmailPromptVisible(true)} disabled={isSubmitting} primary>
          {translate(isEdit ? 'common.save' : 'common.create')}
        </Button>
      )
    })
  }, [])

  useEffect(() => {
    setFieldTouched('type')
  }, [values.type, setFieldTouched])

  const totalsByBrand = useMemo(
    () =>
      Object.entries(values.entries).reduce(
        (acc, [product, qtys]) => ({
          ...acc,
          [product]: sum(
            Object.values(qtys)
              .map((q) => +q)
              .filter(Boolean)
          )
        }),
        {}
      ),
    [values.entries]
  )

  const isPO98Order = values.type === 'po98'
  const isEmergencyOrder = useMemo(
    () => ['order', 'emergency'].includes(values.type) || values.emergencyFlag,
    [values.emergencyFlag, values.type]
  )

  const showSecondEmergencyOrderWarning =
    isEmergencyOrder && Boolean(lastSubmittedEmergencyOrder) && moment().isSame(lastSubmittedEmergencyOrder, 'day')

  const threshold = isEmergencyOrder ? orderComplianceThresholds.emergency : orderComplianceThresholds.saq

  const requiresApproval = useMemo(() => {
    if (isPO98Order) return false
    const validProducts = pickBy(mergedEntries, (value, key) => saqSoxMaterialGroupIds.includes(key.replace('p', '')))
    const saqSoxMaterialGroupQuantity = sum(Object.values(validProducts))
    const isOverThreshold = Boolean(saqSoxMaterialGroupQuantity) && saqSoxMaterialGroupQuantity >= threshold

    const isOrderApprovalExempt =
      customer.orderApprovalExempt || bannerOwnership?.orderApprovalExempt || region?.orderApprovalExempt
    return isOverThreshold && !isOrderApprovalExempt
  }, [values])

  if (isSubmitting) {
    return (
      <EmptyState
        isLoading
        title={translate('common.submitting')}
        subtitle={translate('app.warn.waitWhileWeSaveData')}
      />
    )
  }

  return (
    <Form>
      {globalError && (
        <FieldsetItem>
          <GlobalAlert>{globalError}</GlobalAlert>
        </FieldsetItem>
      )}

      {Object.keys(productsByBrand).length > 0 && (
        <Container>
          {Object.entries(productsByBrand).map(([brand, { label, products }], idx) => {
            const hasVariants = !Array.isArray(products)
            return hasVariants ? (
              <SAQAccordion
                label={label}
                brand={brand}
                key={`${brand}-${idx}`}
                hasSubrows
                totalProducts={sum(Object.values(pick(totalsByBrand, Object.keys(products))))}
              >
                {Object.entries(products).map(([variant, { label, products: variantProducts }], subIdx) => {
                  return (
                    <SAQAccordion
                      key={`${variant}-${idx}-${subIdx}`}
                      isSubrow
                      label={label}
                      brand={variant}
                      products={variantProducts}
                      parent={`entries`}
                      totalProducts={totalsByBrand[variant]}
                      isRestrictedBannerInRestrictedPeriod={isRestrictedBannerInRestrictedPeriod}
                    />
                  )
                })}
              </SAQAccordion>
            ) : (
              <SAQAccordion
                label={label}
                brand={brand}
                products={products}
                key={`${brand}-${idx}`}
                parent={`entries`}
                totalProducts={totalsByBrand[brand]}
                isRestrictedBannerInRestrictedPeriod={isRestrictedBannerInRestrictedPeriod}
              />
            )
          })}
        </Container>
      )}

      {emailPromptVisible && isPO98Order && (
        <PO98ActionSheet
          customer={customer}
          onClose={() => {
            setEmailPromptVisible(false)
          }}
          submitting={isSubmitting}
          isMobileViewOnly={isMobileViewOnly}
          isEdit={isEdit}
          totalQuantity={totalQuantity}
        />
      )}
      {!isPO98Order && (
        <SAQActionSheet
          customer={customer}
          isEmergencyOrder={isEmergencyOrder}
          requiresApproval={requiresApproval}
          threshold={threshold}
          onClose={() => {
            setEmailPromptVisible(false)
          }}
          submitting={isSubmitting}
          isMobileViewOnly={isMobileViewOnly}
          showSecondEmergencyOrderWarning={showSecondEmergencyOrderWarning}
          isEdit={isEdit}
          totalQuantity={totalQuantity}
          qtyToRemove={qtyToRemove}
          visible={emailPromptVisible}
        />
      )}
    </Form>
  )
}

CreateSAQInner.propTypes = {
  values: object.isRequired,
  isSubmitting: bool,
  globalError: string,
  isEdit: bool,
  setFieldTouched: func
}

function CreateSAQ({ submitNewSAQ, submitSAQEdit, isEdit }) {
  const { translate } = useContext(LangContext)

  const { sectorId: customerId, sectorType } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const customer = useSelector((state) => customerSelectors.customerFromUrl(state, { customerId }))
  const employee = useSelector((state) => state.auth.user)
  const employeeId = useSelector((state) => state.auth.user.id)
  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const initialValues = useSelector((state) =>
    orderSelectors.orderInitialValues(state, { orderId: null, location, customerId })
  )

  const [error, setError] = useState()

  const errorMessages = {
    emergencyMinimumQuantityNotReached: translate('saq.emergencyMinimumQtyNotReached'),
    negativeValueDetected: translate('saq.negativeValueDetected'),
    generic: translate('app.warn.errorOccuredTryLater'),
    globalMinimumQuantity: translate('saq.template.globalMinimumQuantity', {
      minimumQuantityRequired: MINIMUM_QUANTITY_REQUIRED
    })
  }

  if (sectorType !== SECTOR_LEVELS.CUSTOMER || !customer || customer.saqCreationDisabled) {
    return <Navigate to=".." />
  }

  const { insightId, projectId } = location.state || {}

  const handleOrderSubmit = async (values, { setSubmitting, setFieldError, setErrors, resetForm }) => {
    const {
      sendEmailCopy,
      preferredLanguage, // undefined
      type,
      emergencyFlag,
      po98Reason,
      po98Comments,
      po98CoordinatorApproved,
      po98CoordinatorName
    } = values

    const redirect = () => {
      resetForm()
      navigate('..', { relative: 'path' })
    }

    if (isMobileViewOnly) {
      return redirect()
    }

    const productsWithCounts = Object.assign({}, ...Object.values(values.entries))
    const entries = Object.entries(productsWithCounts).reduce((acc, [productId, qty]) => {
      const validQty = Math.max(+qty, 0)
      if (!+validQty) return acc
      return [
        ...acc,
        {
          productId: productId.replace(/\D/g, ''),
          qty: validQty
        }
      ]
    }, [])

    if (!entries.length) return

    const orderId = values.id || excelSafeNanoid()
    const submitDetails = {
      id: orderId,
      customerId,
      employeeId,
      entries,
      insightId,
      projectId,
      createdAt: values.createdAt || new Date().toISOString(),
      sendEmailCopy,
      preferredLanguage: preferredLanguage || employee.preferredLanguage,
      type,
      emergencyFlag: ['order', 'emergency'].includes(type) ? true : emergencyFlag
    }

    if (values.type === 'po98') {
      Object.assign(submitDetails, {
        po98Reason,
        po98CoordinatorApproved,
        po98CoordinatorName: po98CoordinatorApproved ? po98CoordinatorName : null,
        po98Comments
      })
    }

    try {
      if (isEdit) {
        await submitSAQEdit(submitDetails)
      } else {
        await submitNewSAQ(submitDetails)
      }
      redirect()
    } catch ({ response }) {
      const loginError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[loginError] || errorMessages.generic)
      setSubmitting(false)
    }
  }

  return (
    <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur onSubmit={handleOrderSubmit}>
      {(formikProps) => {
        return <CreateSAQInner {...formikProps} submitError={error} />
      }}
    </Formik>
  )
}

CreateSAQ.propTypes = {
  submitNewSAQ: func.isRequired,
  submitSAQEdit: func.isRequired,
  initialValues: object,
  isEdit: bool
}

const mapActionCreators = {
  submitNewSAQ,
  submitSAQEdit
}

export default connect(null, mapActionCreators)(CreateSAQ)
