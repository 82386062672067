import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { submitGoFundProgramPowerHourTarget } from 'store/goFundPrograms/actions'
import { programFromUrl } from 'store/goFundPrograms/selectors'
import { goFundTargetInitialValues } from 'store/goFunds/selectors'

import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import GoFundProgramCard from 'components/ProgramCard'

import { SECTOR_LEVELS } from 'utils/constants'

import Card from './Card'

const GoProgramTarget = ({ submitGoFundProgramPowerHourTarget }) => {
  const formRef = useRef()
  const { addAction } = useContext(ActionHeaderContext)
  const [error, setError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { programId, sectorType } = useParams()
  const navigate = useNavigate()
  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const program = useSelector((state) => programFromUrl(state, { programId }))
  const { programCardInfos } = program
  const initialValues = useSelector((state) => goFundTargetInitialValues(state, { programId }))

  useEffect(() => {
    addAction({
      onCreate: () => formRef.current.handleSubmit(),
      getLabel: () => 'Save',
      disabled: isSubmitting
    })
  }, [formRef])

  const validateNewTargetAmount = useCallback((val) => {
    const newAmount = +val
    const fundsAssociated = newAmount * program.perCartonCost

    const availableFunds = programCardInfos.powerHour.availableFunds

    const maximumAllowed = Math.floor(availableFunds / program.perCartonCost)

    if (!newAmount) return 'Please provide the target amount of carton.'
    if (newAmount < 0) return 'Please provide a valid target amount of carton.'
    if (fundsAssociated > availableFunds) return `This target amount can not be greater than ${maximumAllowed}`
  }, [])

  const submitTarget = async (values) => {
    setError()

    setIsSubmitting(true)
    submitGoFundProgramPowerHourTarget(values)
      .then(() => navigate(-1))
      .catch((e) => {
        console.log(e)
        setError(e.message)
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={submitTarget}>
      {({ values }) => {
        const target = Number(values?.cartonTargetAmount) || 0
        return (
          <Form>
            <Container>
              <GoFundProgramCard program={program} />
              <Card>
                <Fieldset padded>
                  <FieldsetItem third>
                    <Field
                      component={Input}
                      name="cartonTargetAmount"
                      label="Target amount"
                      placeholder="0"
                      normalize={(val) => val.replace(/\D/g, '')}
                      validate={validateNewTargetAmount}
                      disabled={isSubmitting}
                      required
                    />
                  </FieldsetItem>
                  <FieldsetItem third>
                    <Input
                      label="Per carton cost"
                      disabled
                      placeholder={'$0'}
                      value={'$'.concat(program.perCartonCost)}
                    />
                  </FieldsetItem>

                  <FieldsetItem third>
                    <Input
                      label="Total amount"
                      disabled
                      placeholder="$0"
                      value={'$'.concat(program.perCartonCost * target)}
                    />
                  </FieldsetItem>

                  {error && (
                    <FieldsetItem>
                      <GlobalAlert>{error}</GlobalAlert>
                    </FieldsetItem>
                  )}
                </Fieldset>
              </Card>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

GoProgramTarget.propTypes = {
  submitGoFundProgramPowerHourTarget: func.isRequired
}

const mapActionCreators = {
  submitGoFundProgramPowerHourTarget
}

export default connect(null, mapActionCreators)(GoProgramTarget)
