import React, { useContext, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { fetchAllSectors } from 'store/sector/actions'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import MobileOverlay from 'components/MobileOverlay'
import StoreInfoModal from 'components/SectorPicker/StoreInfoModal'

import { SECTOR_LEVELS } from 'utils/constants'

import MobileBreadcrumbs from './MobileBreadcrumbs'
import SectorBrowser from './SectorBrowser'

const Scope = () => {
  const { translate } = useContext(LangContext)
  const { selectedLevel, currentSector, modalOpen, setModalOpen } = useContext(SectorContext)

  const [sectorType, setSectorType] = useState('')
  const [queryString, setQueryString] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [page, setPage] = useState('select scope')

  const clearFields = () => {
    setSectorType('')
    setQueryString('')
    setSearchResults([])
  }

  const resetView = () => {
    setPage('select scope')
  }

  const selectedSectorName = useMemo(() => {
    return currentSector && currentSector[selectedLevel]?.name
  }, [selectedLevel, currentSector])

  const preventClosing = isEmpty(currentSector)

  const handleClose = () => {
    if (!preventClosing) setModalOpen(false)
  }

  return (
    <div className="relative z-10 flex h-10 min-w-0 grow items-center rounded-full bg-brand-100 text-brand shadow-lg ring-1 ring-brand/20 transition-all">
      <MobileOverlay
        header={page === 'select scope' ? 'Scope Selection' : 'Change Scope'}
        trigger={
          <button className="flex w-full min-w-0 items-center gap-2 rounded-bl-full rounded-tl-full p-2 transition active:bg-brand-200">
            <div className="shrink-0">
              <Icon icon="location" />
            </div>
            <p className="truncate">{selectedSectorName}</p>
          </button>
        }
        isOpen={modalOpen}
        onOpenChange={(params) => {
          clearFields()
          resetView()
          setModalOpen(!modalOpen)
        }}
        onHandleClose={handleClose}
        actions={
          page === 'select scope' ? (
            <Button icon="manage-search" primary full onClick={() => setPage('change scope')}>
              {translate('app.changeScope')}
            </Button>
          ) : null
        }
        preventClosing={isEmpty(currentSector)}
        hideBackButton={page === 'select scope'}
        onHandleBack={() => setPage('select scope')}
      >
        {page === 'select scope' ? (
          <MobileBreadcrumbs />
        ) : (
          <SectorBrowser
            isMobile
            sectorType={sectorType}
            setSectorType={setSectorType}
            queryString={queryString}
            setQueryString={setQueryString}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            clearFields={clearFields}
            onHandleClose={handleClose}
          />
        )}
      </MobileOverlay>
      {selectedLevel === SECTOR_LEVELS.CUSTOMER && currentSector[selectedLevel] && (
        <>
          <StoreInfoModal
            open={infoModalOpen}
            onOpenChange={setInfoModalOpen}
            customer={currentSector[selectedLevel]}
          />
          <button
            className="flex h-10 w-10 items-center gap-2 rounded-br-full rounded-tr-full p-2 transition active:bg-brand-200"
            onClick={() => setInfoModalOpen(true)}
          >
            <div className="shrink-0">
              <Icon icon="info-outline" />
            </div>
          </button>
        </>
      )}
    </div>
  )
}

export default connect(null, { fetchAllSectors })(Scope)
