import React, { useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import config from 'config'
import tw from 'twin.macro'

import GAnalyticsContext from 'context/GAnalyticsContext'
import LangContext from 'context/LangContext'

import ClickableTile from 'components/ClickableTile'

import { SECTOR_LEVELS } from 'utils/constants'

import CommentsIcon from 'static/images/advance/actions/comments.png'
import CompIntelIcon from 'static/images/advance/actions/comp_intel.png'
import FundsIcon from 'static/images/advance/actions/funds.png'
import OrdersIcon from 'static/images/advance/actions/orders.png'
import Pricing from 'static/images/advance/actions/pricing.png'
import ReturnsIcon from 'static/images/advance/actions/returns.png'
import SellInIcon from 'static/images/advance/actions/sell_in.png'
import SurveysIcon from 'static/images/advance/actions/surveys.png'

const Container = tw.div`col-span-12 grid lg:grid-cols-2 xl:grid-cols-3 max-md:pt-20 gap-4`

const getScanHref = (sectorType, sectorId, employee) => {
  let goScanHref = `${config.ppScannerUrl}/${employee.preferredLanguage.substring(0, 2)}`

  if (sectorType === SECTOR_LEVELS.CUSTOMER) {
    goScanHref += `/erp/${sectorId}`
  }

  const userToken = localStorage.getItem('token')
  goScanHref += `?sessionToken=${userToken}`

  return goScanHref
}

const getPricingUrl = (sectorType, sectorId) => {
  if (![SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY].includes(sectorType)) {
    return ''
  }

  const userToken = localStorage.getItem('token')

  return `${config.legacyUrl}/legacy-pricings/${sectorType}/${sectorId}?sessionToken=${userToken}`
}

const ActionIndex = () => {
  const { translate } = useContext(LangContext)
  const { addUsageEvent } = useContext(GAnalyticsContext)

  const { sectorType, sectorId } = useParams()

  useEffect(() => {
    document.title = 'Actions'
  }, [])
  const employee = useSelector((state) => state.auth.user)

  const TILES = [
    {
      title: translate('app.orders'),
      description: translate('app.actions.orders.description'),
      icon: {
        source: OrdersIcon,
        alt: translate('app.orders')
      },
      to: 'orders',
      order: 2
    },
    {
      title: translate('app.surveys'),
      description: translate('app.actions.surveys.description'),
      icon: {
        source: SurveysIcon,
        alt: translate('app.surveys')
      },
      to: 'surveys',
      order: 4
    },
    {
      title: translate('app.funds'),
      description: translate('app.actions.funds.description'),
      icon: {
        source: FundsIcon,
        alt: translate('app.funds')
      },
      to: 'funds',
      order: 5
    },
    {
      title: translate('app.intel'),
      description: translate('app.actions.intel.description'),
      icon: {
        source: CompIntelIcon,
        alt: translate('app.intel')
      },
      to: 'intel',
      order: 6
    },
    {
      title: translate('app.sellIn'),
      description: translate('app.actions.sellIn.description'),
      icon: {
        source: SellInIcon,
        alt: translate('app.sellIn')
      },
      to: 'sell-in',
      order: 7
    },
    {
      title: translate('app.comments'),
      description: translate('app.actions.comments.description'),
      icon: {
        source: CommentsIcon,
        alt: translate('app.comments')
      },
      to: 'comments',
      order: 8
    }
  ]

  const tiles = useMemo(() => {
    let completeTiles = TILES.concat([
      {
        title: translate('app.returns'),
        description: translate('app.actions.returns.description'),
        icon: {
          source: ReturnsIcon,
          alt: translate('app.returns')
        },
        href: getScanHref(sectorType, sectorId, employee),
        order: 3
      }
    ]).sort((a, b) => a.order - b.order)
    if (config.featureFlags.pricingAction) {
      completeTiles = completeTiles.concat([
        {
          title: translate('app.pricing'),
          description: translate('app.actions.pricing.description'),
          icon: {
            source: Pricing,
            alt: translate('app.pricing')
          },
          to: 'pricing',
          order: 1
        }
      ])
    } else {
      completeTiles = completeTiles.concat([
        {
          title: translate('app.pricing'),
          description: translate('app.actions.pricing.description'),
          icon: {
            source: Pricing,
            alt: translate('app.pricing')
          },
          href: getPricingUrl(sectorType, sectorId),
          order: 1,
          comingSoon: ![SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY].includes(sectorType)
        }
      ])
    }
    return completeTiles
  }, [sectorType, sectorId, employee?.preferredLanguage])

  return (
    <Container>
      {tiles
        .sort((a, b) => a.order - b.order)
        .map((tile) => (
          <ClickableTile
            key={tile.title}
            content={tile}
            comingSoon={tile.comingSoon}
            onClick={() => addUsageEvent(`actions_${tile.to.toLowerCase()}_click`)}
          />
        ))}
    </Container>
  )
}

export default ActionIndex
