import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { object, shape } from 'prop-types'
import tw from 'twin.macro'

import SectorContext from 'context/SectorContext'

import { SECTOR_LEVELS } from 'utils/constants'

import BreadcrumbItem from './BreadcrumbItem'

const Container = tw.div`shadow-md relative h-9 inline-flex overflow-hidden rounded-md text-slate-900 ring-1 ring-slate-900/10`

const delimiter = ' - '
const getSectorName = (input) => input.split(delimiter)[1]
const getSectorID = (input) => input.split(delimiter)[0]

const SectorBreadcrumbs = ({ sector }) => {
  const { selectedLevel, setSelectedLevel } = useContext(SectorContext)
  const { district, territory, customer, region } = sector
  const {
    user: { isDM, isSalesRep, isTM, territories }
  } = useSelector((state) => state.auth)

  if (isEmpty(sector)) return null

  function handleSectorClick(newSector) {
    if (newSector !== selectedLevel) setSelectedLevel(newSector)
  }

  return (
    <Container>
      {/* <BreadcrumbItem
        label={'Canada'}
        isActive={selectedLevel === SECTOR_LEVELS.NATIONAL}
        // onClick={() => handleSectorClick(SECTOR_LEVELS.NATIONAL)}
        disabled //= {isRSM || isDM || isSalesRep || isTM}
      /> */}
      {region && (
        <BreadcrumbItem
          label={region.name}
          isActive={selectedLevel === SECTOR_LEVELS.REGION}
          onClick={() => handleSectorClick(SECTOR_LEVELS.REGION)}
          disabled={isDM || isSalesRep || isTM}
        />
      )}
      {district && (
        <BreadcrumbItem
          label={getSectorName(district.name)}
          sublabel={getSectorID(district.name)}
          isActive={selectedLevel === SECTOR_LEVELS.DISTRICT}
          onClick={() => handleSectorClick(SECTOR_LEVELS.DISTRICT)}
          disabled={isSalesRep || isTM}
        />
      )}
      {territory && (
        <BreadcrumbItem
          label={getSectorName(territory.name)}
          sublabel={getSectorID(territory.name)}
          isActive={selectedLevel === SECTOR_LEVELS.TERRITORY}
          onClick={() => handleSectorClick(SECTOR_LEVELS.TERRITORY)}
          disabled={isSalesRep && !territories.includes(territory.id)}
        />
      )}
      {customer && (
        <BreadcrumbItem
          label={customer.name}
          sublabel={customer.id}
          isActive={selectedLevel === SECTOR_LEVELS.CUSTOMER}
          onClick={() => handleSectorClick(SECTOR_LEVELS.CUSTOMER)}
        />
      )}
    </Container>
  )
}

SectorBreadcrumbs.propTypes = {
  sector: shape({
    country: object,
    region: object,
    district: object,
    territory: object,
    customer: object
  })
}

export default SectorBreadcrumbs
