import { createAction } from 'redux-actions'

/*
 Basically a manually handled cache system. Dispatched in actions.js files
 Definition:
  dataKey: String built in Views/Components. Uses the params to build a unique id
  status: See constants.js -> DATA_UPDATE_STATUS
  lastFetch: current time
  error: null or the error on the latest call
 Example:
 [
  [dataKey]: {
    status: ,
    lastFetch: ,
    error:
    }
  ]
*/
export const setDataFetch = createAction('Set data fetch status')
