import React from 'react'
import { array } from 'prop-types'
import styled from 'styled-components'

import Label from 'components/Label'

import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { smallFontSize } from 'styles/typography'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  padding: 2px;
  background-color: ${borderColor};
`

const StyledLabel = styled(Label)`
  margin-bottom: 0;
  padding: ${spacing.small};
  height: 52px;
`

const Value = styled.span`
  display: block;
  font-size: ${smallFontSize};
  padding: ${spacing.small};
`

const ValueSet = styled.div`
  background-color: white;
`

const Divider = styled.hr`
  border: none;
  background-color: ${borderColor};
  height: 2px;
  margin: 0;
`

const PayoutKPIs = ({ data }) => {
  if (!data?.length) return null

  return (
    <Container>
      {data.map(({ title, value }) => {
        if (!title && !value) return null

        return (
          <ValueSet key={title}>
            <StyledLabel>{title}</StyledLabel>
            <Divider />
            <Value>{value}</Value>
          </ValueSet>
        )
      })}
    </Container>
  )
}

PayoutKPIs.propTypes = {
  data: array
}

export default PayoutKPIs
