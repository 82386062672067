import React from 'react'
import frLocale from '@fullcalendar/core/locales/fr'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import { array, func, string } from 'prop-types'

const CalendarAgenda = ({ events, onEventClick, initialDate, setDateRange, language, ...rest }) => {
  const getFullCalendarLocale = () => {
    switch (language) {
      case 'fr':
        return frLocale
      default:
        return null // Enligsh locale by default, no extra package
    }
  }

  return (
    <FullCalendar
      locale={getFullCalendarLocale(language)}
      plugins={[listPlugin]}
      initialView={'listMonth'}
      events={events}
      eventClick={onEventClick}
      headerToolbar={{
        end: 'today prev,next'
      }}
      height={'250px'}
      datesSet={(dateInfo) => setDateRange(dateInfo)}
    />
  )
}

CalendarAgenda.propTypes = {
  events: array,
  onEventClick: func,
  initialDate: string,
  setDateRange: func,
  language: string
}
export default CalendarAgenda
