import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import outbox from 'store/outbox'
import { insight as InsightSchema } from 'store/schema'

import * as api from './endpoints'

export const markInsightSaqComplete = createAction('Mark insight saq complete')

const queueOfflineInsight = async (insight) => {
  const insightOutbox = (await outbox.getItem('insights')) || {}
  const currentFeedback = insightOutbox[insight.id]
  await outbox.setItem('insights', { ...insightOutbox, [insight.id]: { ...currentFeedback, ...insight } })
  return registerSync('submit-pending-insights')
}

export const addInsightEntities = createAction('Add updated insights', (updatedInsight) => (dispatch) => {
  const { entities } = normalize(updatedInsight, InsightSchema)
  dispatch(mergeEntities(entities))
})

export const submitInsightFeedback = createAction('Submit insight feedback', (feedback) => async (dispatch) => {
  dispatch(addInsightEntities(feedback))
  try {
    if (window.navigator.onLine) {
      const {
        data: { insight }
      } = await api.submitNewInsight(feedback)
      dispatch(addInsightEntities(insight))
    } else {
      await queueOfflineInsight(feedback)
    }
  } catch (err) {
    if (err.message !== 'Network Error') {
      throw err
    }
    await queueOfflineInsight(feedback)
    dispatch(addInsightEntities(feedback))
  }
})
