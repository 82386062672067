import React from 'react'
import { array } from 'prop-types'

import StatsDash from 'components/StatsDash'

import { pricify } from 'utils/helpers'

import { goBlue } from 'styles/colors'

const GoTotals = ({ totals }) => <StatsDash stats={totals} valueKey="total" valueFormatter={pricify} color={goBlue} />

GoTotals.propTypes = {
  totals: array.isRequired
}

export default GoTotals
