import React, { useContext, useEffect } from 'react'
import SAQList from 'views/Customer/SAQList'

import LangContext from 'context/LangContext'

import CheckForValidScope from 'components/guards/CheckForValidScope'

import { SECTOR_LEVELS } from 'utils/constants'

const OrderActionSwitcher = () => {
  const { translate } = useContext(LangContext)

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  useEffect(() => {
    document.title = 'Actions - Orders'
  }, [])

  return (
    <CheckForValidScope pageName={translate('app.orders')} validScopes={validScopes}>
      <SAQList />
    </CheckForValidScope>
  )
}

export default OrderActionSwitcher
