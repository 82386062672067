import React, { useContext } from 'react'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { bool, number, object, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import ScheduleContext from 'context/ScheduleContext'

import { borderColor, greyLight, secondaryColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.small};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }

    &:first-child {
      border-top: 1px solid ${borderColor};
    }
  `};
`

const Comment = styled(ReactMarkdown)`
  padding-right: ${spacing.xxLarge};
  margin-bottom: ${spacing.tiny};
  font-size: ${smallFontSize};
`

const TimeStamp = styled.small`
  display: block;
  color: ${greyLight};
  font-size: ${tinyFontSize};
`

const Link = styled.span`
  color: ${secondaryColor};
  cursor: pointer;
`

const CommentCard = ({ id, customerId, text = '', link = '', author, createdAt, tempId, isMyComment, tcpEnabled }) => {
  const { toggleVisibleApptDetails } = useContext(ScheduleContext)

  const scheduleCall = () => {
    toggleVisibleApptDetails({ createdFromMessageId: id, customerId })
  }

  return (
    <Container>
      {text && <Comment>{text}</Comment>}
      <TimeStamp>
        {moment(createdAt).format('MMM D h:mm a')} • {isMyComment ? 'you' : author ? author.fullName : 'Admin'} •{' '}
        {tcpEnabled && <Link onClick={scheduleCall}>Schedule Call</Link>}
      </TimeStamp>
    </Container>
  )
}

CommentCard.propTypes = {
  text: string.isRequired,
  link: string,
  id: oneOfType([number, string]).isRequired,
  tempId: string,
  customerId: number.isRequired,
  author: object,
  createdAt: string.isRequired,
  isMyComment: bool,
  tcpEnabled: bool
}

export default CommentCard
