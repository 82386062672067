import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import concat from 'lodash/concat'
import moment from 'moment'

import ActiveCallContext from 'context/ActiveCallContext'
import DistrictManagerContext from 'context/DistrictManagerContext'
import LangContext from 'context/LangContext'
import ScheduleContext from 'context/ScheduleContext'

import * as callSelector from 'store/customerCalls/selectors'
import * as ootSelector from 'store/employeeOots/selectors'

import FabWithDropdown from 'components/button/FabWithDropdown'
import CalendarSchedule from 'components/CalendarSchedule'
import Container from 'components/Container'
import FilteredDropdown from 'components/FilteredDropdown'
import MobileHeader from 'components/MobileHeader'
import AddMileage from 'components/schedule/AddMileage'
import LegendDropdown from 'components/schedule/LegendDropdown'
import LogLunch from 'components/schedule/LogLunch'
import SwapScheduleDays from 'components/schedule/SwapScheduleDays'

import { white } from 'styles/colors'

const Schedule = () => {
  const { translate } = useContext(LangContext)

  document.title = 'Schedule'

  const {
    toggleVisibleApptDetails,
    togglePastCallVisible,
    showCallDetailsScreen,
    fetchSchedule,
    showEditOotScreen,
    showPastCallScreenScreen,
    selectedDate,
    setSelectedDate
  } = useContext(ScheduleContext)
  const { fetchEmployeeSchedule, employeeSchedules, employeeScheduleOptions, setMountTime } =
    useContext(DistrictManagerContext)

  const user = useSelector((state) => state.auth.user)
  const calendarCalls = useSelector((state) => callSelector.allCalendarCalls(state))
  const calendarOots = useSelector((state) => ootSelector.allCalendarOots(state))

  const { showStartCallScreen, getPreviousCallDistance } = useContext(ActiveCallContext)

  const [mileageDetails, setMileageDetails] = useState()
  const [lunchDetails, setLunchDetails] = useState()
  const [suggestedKms, setSuggestedKms] = useState(0)
  const [selectedEmployee, setSelectedEmployee] = useState(user.id)
  const [swapDaysVisible, setSwapDaysVisible] = useState(false)

  useLayoutEffect(() => {
    setMountTime(Date.now()) // refetch schedules info on mount
  }, [setMountTime])

  const isScheduleOwner = useMemo(() => {
    return selectedEmployee === user.id
  }, [user, selectedEmployee])

  useEffect(() => {
    if (!isScheduleOwner) {
      fetchEmployeeSchedule(selectedDate, selectedEmployee)
    } else {
      fetchSchedule(selectedDate)
    }
  }, [selectedDate, selectedEmployee, isScheduleOwner])

  const allCalendarEvents = useMemo(() => {
    return concat(calendarCalls, calendarOots)
  }, [calendarCalls, calendarOots])

  const selectedEmployeeSchedule = useMemo(() => {
    return !isScheduleOwner ? employeeSchedules[selectedEmployee] || [] : allCalendarEvents
  }, [allCalendarEvents, employeeSchedules, selectedEmployee, isScheduleOwner])

  const scheduleEarliestScrollTime = selectedEmployee ? '07:00:00' : user.workHours.start

  const handleEventClick = ({ event }) => {
    const expandedProps = event?._def?.extendedProps

    switch (expandedProps?.eventType) {
      case 'past-call':
        !isScheduleOwner
          ? showCallDetailsScreen({ displayCall: expandedProps.callDetails, callId: expandedProps.callDetails.id })
          : showPastCallScreenScreen({ pastCall: expandedProps.callDetails })
        break
      case 'completed-call':
        showCallDetailsScreen({ displayCall: expandedProps.callDetails, callId: expandedProps.callDetails.id })
        break
      case 'scheduled-call':
        !isScheduleOwner
          ? showCallDetailsScreen({ displayCall: expandedProps.callDetails, callId: expandedProps.callDetails.id })
          : showStartCallScreen({ call: expandedProps.callDetails, callId: expandedProps.callDetails.id })
        break
      case 'oot':
        showEditOotScreen({ ootId: expandedProps.id, ootDetails: expandedProps.ootDetails })
        break
    }
  }

  return (
    <>
      <Container noPadding fullWidth background={white}>
        <MobileHeader isNavOnly />
        <div className="flex w-full justify-between gap-2 p-4 max-md:pt-20">
          <div className="w-[calc(100%-44px)] md:max-w-96">
            {employeeScheduleOptions?.length > 1 && (
              <FilteredDropdown
                searchPlaceholder={translate('app.placeholders.searchEmployees')}
                options={[{ label: 'My schedule', value: user.id }].concat(employeeScheduleOptions)}
                value={selectedEmployee}
                onChange={(value) => setSelectedEmployee(value)}
              />
            )}
          </div>
          <div className="shrink-0">
            <LegendDropdown />
          </div>
        </div>
        <CalendarSchedule
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          events={selectedEmployeeSchedule}
          onEventClick={handleEventClick}
          workDayStart={scheduleEarliestScrollTime}
        />
        {isScheduleOwner && (
          <FabWithDropdown
            items={[
              {
                label: 'Schedule call',
                icon: 'phone',
                handleClick: () => toggleVisibleApptDetails()
              },
              {
                label: 'Log past call',
                icon: 'call-history',
                handleClick: () => togglePastCallVisible()
              },
              {
                label: 'Create OOT',
                icon: 'plane',
                handleClick: () => {
                  showEditOotScreen({})
                }
              },
              {
                label: 'Log EOD KM',
                icon: 'car',
                handleClick: async () => {
                  const { address, workHours, latitude, longitude } = user
                  const [hour, minute] = workHours.end.split(':')
                  if (address && workHours && latitude && longitude) {
                    const distance = await getPreviousCallDistance({
                      start: moment({ hour, minute }),
                      address,
                      coords: { latitude, longitude }
                    })
                    setSuggestedKms(distance === 0 ? null : distance / 1000)
                  } else {
                    setSuggestedKms(0)
                  }
                  setMileageDetails({})
                }
              },
              {
                label: 'Swap days',
                icon: 'calendar',
                handleClick: () => setSwapDaysVisible(!swapDaysVisible)
              }
            ]}
          />
        )}
      </Container>
      <AddMileage
        suggestedKms={suggestedKms}
        visibleScheduleScreen={Boolean(mileageDetails)}
        setVisibleScheduleScreen={setMileageDetails}
      />
      <LogLunch visibleScheduleScreen={Boolean(lunchDetails)} setVisibleScheduleScreen={setLunchDetails} />
      <SwapScheduleDays
        visibleScheduleScreen={Boolean(swapDaysVisible)}
        setVisibleScheduleScreen={setSwapDaysVisible}
      />
    </>
  )
}

export default Schedule
