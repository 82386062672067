import React from 'react'
import { bool, node } from 'prop-types'
import styled, { css } from 'styled-components'

import * as spacing from 'styles/spacing'

const Container = styled.div`
  padding: ${spacing.small};
  width: 100%;

  ${(props) =>
    props.half &&
    css`
      width: 50%;
    `};

  ${(props) =>
    props.third &&
    css`
      width: 33.33%;
    `};

  ${(props) =>
    props.fourth &&
    css`
      width: 25%;
    `};
`

const FieldsetItem = ({ children, half, third, fourth }) => (
  <Container half={half} third={third} fourth={fourth}>
    {children}
  </Container>
)

FieldsetItem.propTypes = {
  children: node.isRequired,
  half: bool,
  fourth: bool,
  third: bool
}

export default FieldsetItem
