import config from 'config'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

const headers = {
  today: {
    en: 'Today',
    fr: "Aujourd'hui"
  },
  thisWeek: {
    en: 'This Week',
    fr: 'Semaine'
  },
  cycleToDate: {
    en: 'Cycle to date',
    fr: 'Cycle à date'
  }
}

const tableSections = [
  {
    sequenceReference: 'sellIn',
    title: 'Performance',
    columns: [
      {
        en: 'Orders',
        fr: 'Ventes'
      },
      {
        en: 'Target',
        fr: 'Cible'
      },
      {
        en: 'Gap',
        fr: 'Ecart'
      }
    ],
    subSections: [
      {
        section: 'sales',
        title: 'Sales',
        rows: [
          {
            name: headers.today,
            values: [100, 102, 'gapSalesToday']
          },
          {
            name: headers.thisWeek,
            values: ['salesTW', 117, 'gapSalesTW']
          },
          {
            name: headers.cycleToDate,
            values: [118, 119, 'gapSalesCycleToDate']
          },
          {
            name: 'Cycle',
            values: [120, 178, 'gapSalesCycle']
          }
        ],
        updatedAt: [
          { label: 'Updated at', key: 'F3' },
          { label: 'Orders', key: 'F1' }
        ]
      },
      {
        section: 'vypeDistro'
      },
      {
        section: 'vuseSellIn',
        title: { en: 'Vuse Sell-In', fr: 'Vuse Commandes' },
        columns: [
          {
            en: 'Invoice',
            fr: 'Facture'
          },
          {
            en: 'Target',
            fr: 'Cible'
          }
        ],
        rows: [
          {
            name: { en: 'Sell In Cycle Vuse GO', fr: 'Objectif du cycle pour Vuse GO Sell In' },
            values: [229, 232]
          },
          {
            name: { en: 'Sell In Cycle Vuse GO XL', fr: 'Objectif du cycle pour Vuse GO XL' },
            values: [230, 233]
          },
          {
            name: { en: 'Sell In Cycle Vuse GO 5000', fr: 'Objectif du cycle pour Vuse GO 5000' },
            values: [231, 234]
          },
          {
            name: {
              en: 'Sell In Cycle Vuse ePod Consumable',
              fr: 'Objectif du cycle pour les consommables Vuse GO ePod '
            },
            values: [222, 220]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      },
      {
        section: 'vuseSellOut',
        title: { en: 'Vuse Sell-Out', fr: 'Vuse Ventes au détail' },
        columns: [
          {
            en: 'POS Sales',
            fr: 'POS Sales'
          },
          {
            en: 'Target',
            fr: 'Cible'
          }
        ],
        rows: [
          {
            name: { en: 'Sell-out Cycle Vuse GO', fr: 'Objectif du cycle pour Vuse Vuse GO' },
            values: [235, 238]
          },
          {
            name: { en: 'Sell-out Cycle Vuse GO XL', fr: 'Objectif du cycle pour Vuse Vuse GO XL' },
            values: [236, 239]
          },
          {
            name: { en: 'Sell-out Cycle Vuse GO 5000', fr: 'Objectif du cycle pour Vuse GO 5000' },
            values: [237, 240]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      }
    ]
  },
  {
    sequenceReference: 'inventoryManagement',
    title: 'Inventory management',
    columns: ['LW', 'L4'],
    subSections: [
      {
        section: 'daysStock',
        title: 'Days Stock',
        rows: [
          {
            name: 'Total',
            values: [104, 105]
          },
          {
            name: 'duMaurier',
            values: [126, 127]
          },
          {
            name: 'John Player',
            values: [128, 129]
          },
          {
            name: 'Pall Mall',
            values: [130, 131]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      },
      {
        section: 'inventoryCapture',
        title: 'Inventory Capture %',
        rows: [
          {
            name: 'Total',
            values: [106, 107]
          },
          {
            name: 'duMaurier',
            values: [134, 135]
          },
          {
            name: 'John Player',
            values: [136, 137]
          },
          {
            name: 'Pall Mall',
            values: [138, 139]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      },
      {
        section: 'outOfStock',
        title: 'Out of Stock %',
        rows: [
          {
            name: 'Total',
            values: [108, 109]
          },
          {
            name: 'duMaurier',
            values: [142, 143]
          },
          {
            name: 'John Player',
            values: [144, 145]
          },
          {
            name: 'Pall Mall',
            values: [146, 147]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      },
      {
        section: 'seasonalityIndex',
        title: 'Seasonality Index %',
        columns: ['TW', 'NW'],
        rows: [
          {
            name: 'Total',
            values: [110, 111]
          }
        ],
        updatedAt: [{ label: 'Updated at', key: 'F3' }]
      }
    ]
  },
  {
    sequenceReference: 'sellOut',
    title: 'Sell-out / POS',
    columns: ['AWR4', 'L4 Contr %', 'L4-P4%'],
    subSections: [
      {
        section: 'posMulti',
        title: 'Category Contr($)',
        rows: [
          {
            name: 'FMC',
            values: [150, 151, 211]
          },
          {
            name: 'Vape',
            values: [152, 153, 212]
          },
          {
            name: 'THP',
            values: [154, 155, 213]
          },
          {
            name: 'Papers',
            values: [156, 157, 214]
          }
        ],
        updatedAt: [
          { label: 'POS data as of WE', key: 'F3', formatter: aWeekAgoSunday },
          { label: 'Updated at', key: 'F3' }
        ]
      },
      {
        section: 'posFmc',
        title: 'FMC Perf. (CTN)',
        columns: ['AWR4', 'L4 Shr %', 'L4-P4%'],
        rows: [
          {
            name: 'ITCAN',
            values: [158, 159, 215]
          },
          {
            name: 'PMI',
            values: [160, 161, 216]
          },
          {
            name: 'JTI',
            values: [162, 163, 217]
          },
          {
            name: 'Non - CTMC',
            values: [164, 165, 218]
          }
        ],
        updatedAt: [
          { label: 'POS data as of WE', key: 'F3', formatter: aWeekAgoSunday },
          { label: 'Updated at', key: 'F3' }
        ]
      }
    ]
  }
]

const createVctmSection =
  (updatedAt) =>
  ({ name: { en, fr }, ...section }) => {
    return {
      sequenceReference: en,
      subSectionRef: en,
      title: { en: 'Vape Category', fr: 'Vape Category' },
      columns: ['AWR4 $', 'L4 Shr %', 'L4-P4 %'],
      rows: [
        {
          name: { en, fr },
          values: [section.awr4, section.shareL4, section.l4P4]
        }
      ],
      topSkus: [
        {
          section: 'performance',
          title: { en: 'Brands', fr: 'Brands' },
          columns: ['AWR4 $', 'L4 Shr %', 'L4-P4 %'],
          rows: section.performance
            .map((row) => ({
              name: row.name,
              values: [row.awr4, row.shareL4, row.l4P4]
            }))
            .sort((b, a) => {
              return a.values[0] - b.values[0]
            }),
          updatedAt
        },
        {
          section: 'nicotine',
          title: { en: 'Nicotine', fr: 'Nicotine' },
          columns: ['AWR4 $', 'L4 Shr %', 'L4-P4 %'],
          subSections: section.nicotine?.map((subSection) => ({
            section: subSection.name.en,
            title: subSection.name,
            columns: [subSection.awr4, subSection.shareL4, subSection.l4P4],
            rows: subSection.performance
              ?.map((perf) => ({
                name: perf.name,
                values: [perf.awr4, perf.shareL4, perf.l4P4]
              }))
              .sort((b, a) => {
                return a.values[0] - b.values[0]
              }),
            updatedAt
          }))
        }
      ]
    }
  }

function updatedAtDateTime(updatedAt) {
  if (!updatedAt) return '-'
  return moment(updatedAt).format('M/D h:mmA')
}

function aWeekAgoSunday(updatedAt) {
  if (!updatedAt) return '-'
  return moment(updatedAt).startOf('week').subtract(7, 'days').format('MMMM D, YYYY')
}

export function formatPerformanceData(performanceData = {}, topSkus = {}, vctmSellOut = [], lastSyncs) {
  const isVctmStore = Boolean(vctmSellOut.length) && config.vctmEnabled
  const generateUpdatedAt = ({ key, label, formatter = updatedAtDateTime }) => ({
    label,
    value: formatter(lastSyncs[key])
  })

  return tableSections.reduce((acc, section) => {
    // NOD-707
    if (section.sequenceReference === 'inventoryManagement' && isVctmStore) return acc
    const isSellOutSection = section.sequenceReference === 'sellOut'
    const topSkusUpdatedAt = (
      isSellOutSection ? [{ label: 'POS data as of WE', key: 'topSkus', formatter: aWeekAgoSunday }] : []
    ).concat({ label: 'Updated at', key: 'topSkus' })
    const vctmUpdatedAt = [
      { label: 'Updated at', key: 'vctmSellOutUpdatedAt' },
      { label: 'POS data as of WE', key: 'vctmSellOutUpdatedAt', formatter: aWeekAgoSunday }
    ]

    if (isSellOutSection && isVctmStore) {
      return [
        ...acc,
        {
          ...section,
          subSections: vctmSellOut.map(createVctmSection(vctmUpdatedAt.map(generateUpdatedAt)))
        }
      ]
    } else {
      if (isVctmStore && section.sequenceReference === 'inventoryManagement') return acc
      return [
        ...acc,
        {
          ...section,
          subSections: section.subSections.map(({ section: subSectionRef, ...subSection }) => {
            return {
              ...subSection,
              subSectionRef,
              topSkus:
                topSkus && topSkus[subSectionRef] && !isEmpty(topSkus[subSectionRef])
                  ? [
                      {
                        ...topSkus[subSectionRef],
                        updatedAt: topSkusUpdatedAt.map(generateUpdatedAt)
                      }
                    ]
                  : [],
              rows: subSection.rows?.map((row) => ({
                ...row,
                values: row.values.map((value) => performanceData[value])
              })),
              updatedAt: subSection.updatedAt?.map(generateUpdatedAt)
            }
          })
        }
      ]
    }
  }, [])
}

// SCHEMA FORMATTER
function getPerformanceValue({ value, missing, confirmed }) {
  if (value) return value
  if (missing) return 'M'
  if (confirmed) return 'C'
  return null
}

function getGap(targetKpi, currentKpi) {
  return (pd) => {
    if (isNaN(parseInt(pd[targetKpi]))) return 0 // if there is no reported target, do not report gap
    return 0 - (pd[targetKpi] - (pd[currentKpi] || 0))
  }
}

function salesTW(pd) {
  return (pd[116] || 0) + (pd[100] || 0)
}

// function premiumTW(pd) {
//   return (pd[121] || 0) + (pd[101] || 0)
// }

function gapSalesTW(pd) {
  const target = pd[117]
  return isNaN(parseInt(target)) ? 0 : 0 - (target - salesTW(pd))
}

// function gapPremiumTW(pd) {
//   const target = pd[122]
//   return isNaN(parseInt(target)) ? 0 : 0 - (target - premiumTW(pd))
// }

export const calculatedPerfData = (performance) => {
  if (!performance) return null
  const perfData = performance.reduce((acc, row) => ({ ...acc, [row.key]: getPerformanceValue(row) }), {})
  return {
    ...perfData,
    // gapVypePosSalesLastWeek: getGap(181, 167)(perfData),
    // gapVypePosSalesThisCycle: getGap(182, 168)(perfData),
    // gapVypeNonPosOrdersLastWeek: getGap(170, 169)(perfData),
    // gapVypeNonPosOrdersThisCycle: getGap(180, 171)(perfData),
    gapSalesToday: getGap(102, 100)(perfData),
    gapSalesTW: gapSalesTW(perfData),
    gapSalesCycleToDate: getGap(119, 118)(perfData),
    gapSalesCycle: getGap(178, 120)(perfData),
    // gapPremiumToday: getGap(103, 101)(perfData),
    // gapPremiumTW: gapPremiumTW(perfData),
    // gapPremiumCycleToDate: getGap(124, 123)(perfData),
    // gapPremiumCycle: getGap(179, 125)(perfData),
    salesTW: salesTW(perfData),
    targetTW: perfData[117] || 0,
    orderStatus: perfData[166] || 0, // used in customer card
    // premiumTW: premiumTW(perfData),
    wtd: perfData[228] || 0, // USED for Order approval thresholds
    awr13: perfData[227] || 0 // USED for order approval thresholds
  }
}

export const calculatedCustomerCardPerfData = (performance) => {
  if (!performance) return null
  const perfData = performance.reduce((acc, row) => ({ ...acc, [row.key]: getPerformanceValue(row) }), {})
  return {
    salesTW: salesTW(perfData),
    targetTW: perfData[117] || 0,
    orderStatus: perfData[166] || 0 // used in customer card
  }
}

// 112,113,114,115,121,123,125,201,202,169,170,171,180

// ALSO NOT USED
// 122,124,179,181,167,103,101

// USED KPIs
// 100, 102,
// 100, 116, 117,
// 118, 119,
// 120, 178,
// 224, 223
// 226, 225
// 221, 219
// 222, 220
// 168, 182
// 104, 105
// 126, 127
// 128, 129
// 130, 131
// 132, 133
// 106, 107
// 134, 135
// 136, 137
// 138, 139
// 140, 141
// 108, 109
// 142, 143
// 144, 145
// 146, 147
// 148, 149
// 110, 111
// 150, 151, 211
// 152, 153, 212
// 154, 155, 213
// 156, 157, 214
// 158, 159, 215
// 160, 161, 216
// 162, 163, 217
// 164, 165, 218
// 228 - wtd: order approval thresholds
// 227 - awr13: order approval thresholds
// 166 - order status on customer card
