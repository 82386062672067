import { addEntities, mergeEntities, setEntities } from 'store/actions'

import { addExceptionToGoFundProgram } from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => Object.assign({}, state, action.payload.goFundPrograms),
  [setEntities]: (state, action) => action.payload.goFundPrograms || state,
  [mergeEntities]: (state, action) => {
    if (!action.payload.goFundPrograms) return state
    return Object.entries(action.payload.goFundPrograms).reduce((acc, [id, goProgram]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...goProgram
        }
      })
    }, Object.assign({}, state))
  },
  [addExceptionToGoFundProgram]: (state, { payload }) => {
    if (!payload) return state
    const { exception, programId } = payload
    state[programId].exceptions = [...state[programId].exceptions, exception]
    return Object.assign({}, state)
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
