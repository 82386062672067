import { createAction } from 'redux-actions'

import {
  addAmplifyExtraHubActivityValuesToCustomer,
  addAmplifyExtraHubEngagementValuesToCustomer,
  addAmplifyExtraHubFiltersValuesToCustomer,
  addAmplifyExtraHubGeographyValuesToCustomer,
  addAmplifyExtraHubTrendingValuesToCustomer,
  addAmplifyInventoryOosBrandsValuesToCustomer,
  addAmplifyInventoryOosFiltersValuesToCustomer,
  addAmplifyInventoryOosGeographyValuesToCustomer,
  addAmplifyInventoryOosMaterialValuesToCustomer,
  addAmplifyInventoryOosTrendingValuesToCustomer,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToCustomer,
  addAmplifyPriceCaptureComplianceFiltersValuesToCustomer,
  addAmplifyPriceCaptureComplianceFootprintValuesToCustomer,
  addAmplifyPriceCaptureComplianceGeographyValuesToCustomer,
  addAmplifyPriceComplianceEdlpComplianceValuesToCustomer,
  addAmplifyPriceComplianceGeographyComplianceValuesToCustomer,
  addAmplifyPriceComplianceGeographyFiltersValuesToCustomer,
  addAmplifyPriceComplianceStrategyComplianceValuesToCustomer,
  addAmplifySelloutGeographyValuesToCustomer,
  addAmplifySelloutValuesToCustomer,
  addDistroBrandDataToCustomer,
  addDistroGeographyDataToCustomer,
  addDistroMaterialDataToCustomer,
  addDistroSummaryDataToCustomer,
  addDistroTrendedDataToCustomer,
  addPlanningCycleCampaignValuesToCustomer,
  addPlanningEngagementValuesToCustomer,
  addPlanningHealthCheckValuesToCustomer,
  addPlanningPriceCheckValuesToCustomer,
  addPlanningSellinValuesToCustomer,
  addPlanningSelloutValuesToCustomer,
  addSellinGeographyToCustomer,
  addSellinOrderCompletionToCustomer,
  addSellinRunRateToCustomer
} from 'store/customers/actions'
import {
  addAmplifyExtraHubActivityValuesToDistrict,
  addAmplifyExtraHubEngagementValuesToDistrict,
  addAmplifyExtraHubFiltersValuesToDistrict,
  addAmplifyExtraHubGeographyValuesToDistrict,
  addAmplifyExtraHubTrendingValuesToDistrict,
  addAmplifyInventoryOosBrandsValuesToDistrict,
  addAmplifyInventoryOosFiltersValuesToDistrict,
  addAmplifyInventoryOosGeographyValuesToDistrict,
  addAmplifyInventoryOosMaterialValuesToDistrict,
  addAmplifyInventoryOosTrendingValuesToDistrict,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict,
  addAmplifyPriceCaptureComplianceFiltersValuesToDistrict,
  addAmplifyPriceCaptureComplianceFootprintValuesToDistrict,
  addAmplifyPriceCaptureComplianceGeographyValuesToDistrict,
  addAmplifyPriceComplianceEdlpComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyFiltersValuesToDistrict,
  addAmplifyPriceComplianceStrategyComplianceValuesToDistrict,
  addAmplifySelloutGeographyValuesToDistrict,
  addAmplifySelloutValuesToDistrict,
  addDistroBrandDataToDistrict,
  addDistroGeographyDataToDistrict,
  addDistroMaterialDataToDistrict,
  addDistroSummaryDataToDistrict,
  addDistroTrendedDataToDistrict,
  addPlanningCycleCampaignValuesToDistrict,
  addPlanningEngagementValuesToDistrict,
  addPlanningHealthCheckValuesToDistrict,
  addPlanningPriceCheckValuesToDistrict,
  addPlanningSellinValuesToDistrict,
  addPlanningSelloutValuesToDistrict,
  addSellinGeographyToDistrict,
  addSellinOrderCompletionToDistrict,
  addSellinRunRateToDistrict
} from 'store/districts/actions'
import {
  addAmplifyExtraHubActivityValuesToNation,
  addAmplifyExtraHubEngagementValuesToNation,
  addAmplifyExtraHubFiltersValuesToNation,
  addAmplifyExtraHubGeographyValuesToNation,
  addAmplifyExtraHubTrendingValuesToNation,
  addAmplifyInventoryOosBrandsValuesToNation,
  addAmplifyInventoryOosFiltersValuesToNation,
  addAmplifyInventoryOosGeographyValuesToNation,
  addAmplifyInventoryOosMaterialValuesToNation,
  addAmplifyInventoryOosTrendingValuesToNation,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation,
  addAmplifyPriceCaptureComplianceFiltersValuesToNation,
  addAmplifyPriceCaptureComplianceFootprintValuesToNation,
  addAmplifyPriceCaptureComplianceGeographyValuesToNation,
  addAmplifyPriceComplianceEdlpComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyFiltersValuesToNation,
  addAmplifyPriceComplianceStrategyComplianceValuesToNation,
  addAmplifySelloutGeographyValuesToNation,
  addAmplifySelloutValuesToNation,
  addDistroBrandDataToNation,
  addDistroGeographyDataToNation,
  addDistroMaterialDataToNation,
  addDistroSummaryDataToNation,
  addDistroTrendedDataToNation,
  addPlanningCycleCampaignValuesToNation,
  addPlanningEngagementValuesToNation,
  addPlanningHealthCheckValuesToNation,
  addPlanningPriceCheckValuesToNation,
  addPlanningSellinValuesToNation,
  addPlanningSelloutValuesToNation,
  addSellinGeographyToNation,
  addSellinOrderCompletionToNation,
  addSellinRunRateToNation
} from 'store/nations/actions'
import {
  addAmplifyExtraHubActivityValuesToRegion,
  addAmplifyExtraHubEngagementValuesToRegion,
  addAmplifyExtraHubFiltersValuesToRegion,
  addAmplifyExtraHubGeographyValuesToRegion,
  addAmplifyExtraHubTrendingValuesToRegion,
  addAmplifyInventoryOosBrandsValuesToRegion,
  addAmplifyInventoryOosFiltersValuesToRegion,
  addAmplifyInventoryOosGeographyValuesToRegion,
  addAmplifyInventoryOosMaterialValuesToRegion,
  addAmplifyInventoryOosTrendingValuesToRegion,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion,
  addAmplifyPriceCaptureComplianceFiltersValuesToRegion,
  addAmplifyPriceCaptureComplianceFootprintValuesToRegion,
  addAmplifyPriceCaptureComplianceGeographyValuesToRegion,
  addAmplifyPriceComplianceEdlpComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyComplianceValuesToRegion,
  addAmplifyPriceComplianceGeographyFiltersValuesToRegion,
  addAmplifyPriceComplianceStrategyComplianceValuesToRegion,
  addAmplifySelloutGeographyValuesToRegion,
  addAmplifySelloutValuesToRegion,
  addDistroBrandDataToRegion,
  addDistroGeographyDataToRegion,
  addDistroMaterialDataToRegion,
  addDistroSummaryDataToRegion,
  addDistroTrendedDataToRegion,
  addPlanningCycleCampaignValuesToRegion,
  addPlanningEngagementValuesToRegion,
  addPlanningHealthCheckValuesToRegion,
  addPlanningPriceCheckValuesToRegion,
  addPlanningSellinValuesToRegion,
  addPlanningSelloutValuesToRegion,
  addSellinGeographyToRegion,
  addSellinOrderCompletionToRegion,
  addSellinRunRateToRegion
} from 'store/regions/actions'
import {
  addAmplifyExtraHubActivityValuesToTerritory,
  addAmplifyExtraHubEngagementValuesToTerritory,
  addAmplifyExtraHubFiltersValuesToTerritory,
  addAmplifyExtraHubGeographyValuesToTerritory,
  addAmplifyExtraHubTrendingValuesToTerritory,
  addAmplifyInventoryOosBrandsValuesToTerritory,
  addAmplifyInventoryOosFiltersValuesToTerritory,
  addAmplifyInventoryOosGeographyValuesToTerritory,
  addAmplifyInventoryOosMaterialValuesToTerritory,
  addAmplifyInventoryOosTrendingValuesToTerritory,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory,
  addAmplifyPriceCaptureComplianceFiltersValuesToTerritory,
  addAmplifyPriceCaptureComplianceFootprintValuesToTerritory,
  addAmplifyPriceCaptureComplianceGeographyValuesToTerritory,
  addAmplifyPriceComplianceEdlpComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyFiltersValuesToTerritory,
  addAmplifyPriceComplianceStrategyComplianceValuesToTerritory,
  addAmplifySelloutGeographyValuesToTerritory,
  addAmplifySelloutValuesToTerritory,
  addDistroBrandDataToTerritory,
  addDistroGeographyDataToTerritory,
  addDistroMaterialDataToTerritory,
  addDistroSummaryDataToTerritory,
  addDistroTrendedDataToTerritory,
  addPlanningCycleCampaignValuesToTerritory,
  addPlanningEngagementValuesToTerritory,
  addPlanningHealthCheckValuesToTerritory,
  addPlanningPriceCheckValuesToTerritory,
  addPlanningSellinValuesToTerritory,
  addPlanningSelloutValuesToTerritory,
  addSellinGeographyToTerritory,
  addSellinOrderCompletionToTerritory,
  addSellinRunRateToTerritory
} from 'store/territories/actions'

export const addEntities = createAction('Add Entities')
export const setEntities = createAction('Set Entities')
export const mergeEntities = createAction('Merge Entities')

export const PLANNING_SELECTOR_ACTIONS = {
  customer: {
    sellin: addPlanningSellinValuesToCustomer,
    sellinOrderCompletion: addSellinOrderCompletionToCustomer,
    sellinRunRate: addSellinRunRateToCustomer,
    sellout: addPlanningSelloutValuesToCustomer,
    healthCheck: addPlanningHealthCheckValuesToCustomer,
    engagement: addPlanningEngagementValuesToCustomer,
    priceCheck: addPlanningPriceCheckValuesToCustomer,
    addCycleCampaigns: addPlanningCycleCampaignValuesToCustomer,
    distroTrended: addDistroTrendedDataToCustomer,
    distroBrand: addDistroBrandDataToCustomer,
    distroMaterial: addDistroMaterialDataToCustomer
  },
  territory: {
    sellin: addPlanningSellinValuesToTerritory,
    sellinOrderCompletion: addSellinOrderCompletionToTerritory,
    sellinRunRate: addSellinRunRateToTerritory,
    sellout: addPlanningSelloutValuesToTerritory,
    healthCheck: addPlanningHealthCheckValuesToTerritory,
    engagement: addPlanningEngagementValuesToTerritory,
    priceCheck: addPlanningPriceCheckValuesToTerritory,
    addCycleCampaigns: addPlanningCycleCampaignValuesToTerritory,
    distroTrended: addDistroTrendedDataToTerritory,
    distroBrand: addDistroBrandDataToTerritory,
    distroMaterial: addDistroMaterialDataToTerritory
  },
  district: {
    sellin: addPlanningSellinValuesToDistrict,
    sellinOrderCompletion: addSellinOrderCompletionToDistrict,
    sellinRunRate: addSellinRunRateToDistrict,
    sellout: addPlanningSelloutValuesToDistrict,
    healthCheck: addPlanningHealthCheckValuesToDistrict,
    engagement: addPlanningEngagementValuesToDistrict,
    priceCheck: addPlanningPriceCheckValuesToDistrict,
    addCycleCampaigns: addPlanningCycleCampaignValuesToDistrict,
    distroTrended: addDistroTrendedDataToDistrict,
    distroBrand: addDistroBrandDataToDistrict,
    distroMaterial: addDistroMaterialDataToDistrict
  },
  region: {
    sellin: addPlanningSellinValuesToRegion,
    sellinOrderCompletion: addSellinOrderCompletionToRegion,
    sellinRunRate: addSellinRunRateToRegion,
    sellout: addPlanningSelloutValuesToRegion,
    healthCheck: addPlanningHealthCheckValuesToRegion,
    engagement: addPlanningEngagementValuesToRegion,
    priceCheck: addPlanningPriceCheckValuesToRegion,
    addCycleCampaigns: addPlanningCycleCampaignValuesToRegion,
    distroTrended: addDistroTrendedDataToRegion,
    distroBrand: addDistroBrandDataToRegion,
    distroMaterial: addDistroMaterialDataToRegion
  },
  national: {
    sellin: addPlanningSellinValuesToNation,
    sellinOrderCompletion: addSellinOrderCompletionToNation,
    sellinRunRate: addSellinRunRateToNation,
    sellout: addPlanningSelloutValuesToNation,
    healthCheck: addPlanningHealthCheckValuesToNation,
    engagement: addPlanningEngagementValuesToNation,
    priceCheck: addPlanningPriceCheckValuesToNation,
    addCycleCampaigns: addPlanningCycleCampaignValuesToNation,
    distroTrended: addDistroTrendedDataToNation,
    distroBrand: addDistroBrandDataToNation,
    distroMaterial: addDistroMaterialDataToNation
  }
}

export const AMPLIFY_SELECTOR_ACTIONS = {
  customer: {
    sellout: {
      main: addAmplifySelloutValuesToCustomer,
      geography: addAmplifySelloutGeographyValuesToCustomer
    },
    sellin: {
      geography: addSellinGeographyToCustomer
    },
    inventoryOos: {
      trending: addAmplifyInventoryOosTrendingValuesToCustomer,
      brand: addAmplifyInventoryOosBrandsValuesToCustomer,
      materials: addAmplifyInventoryOosMaterialValuesToCustomer,
      geography: addAmplifyInventoryOosGeographyValuesToCustomer,
      filters: addAmplifyInventoryOosFiltersValuesToCustomer
    },
    extraHub: {
      trending: addAmplifyExtraHubTrendingValuesToCustomer,
      engagement: addAmplifyExtraHubEngagementValuesToCustomer,
      geography: addAmplifyExtraHubGeographyValuesToCustomer,
      filters: addAmplifyExtraHubFiltersValuesToCustomer,
      activity: addAmplifyExtraHubActivityValuesToCustomer
    },
    distro: {
      geography: addDistroGeographyDataToCustomer,
      summary: addDistroSummaryDataToCustomer
    },
    priceCaptureCompliance: {
      storeFootprint: addAmplifyPriceCaptureComplianceFootprintValuesToCustomer,
      brandCompletion: addAmplifyPriceCaptureComplianceBrandCompletionValuesToCustomer,
      filters: addAmplifyPriceCaptureComplianceFiltersValuesToCustomer,
      geography: addAmplifyPriceCaptureComplianceGeographyValuesToCustomer,
      strategyCompliance: addAmplifyPriceComplianceStrategyComplianceValuesToCustomer,
      edlpCompliance: addAmplifyPriceComplianceEdlpComplianceValuesToCustomer,
      priceComplianceGeography: addAmplifyPriceComplianceGeographyComplianceValuesToCustomer,
      priceComplianceGeographyFilters: addAmplifyPriceComplianceGeographyFiltersValuesToCustomer
    }
  },
  territory: {
    sellout: {
      main: addAmplifySelloutValuesToTerritory,
      geography: addAmplifySelloutGeographyValuesToTerritory
    },
    sellin: {
      geography: addSellinGeographyToTerritory
    },
    inventoryOos: {
      trending: addAmplifyInventoryOosTrendingValuesToTerritory,
      brand: addAmplifyInventoryOosBrandsValuesToTerritory,
      materials: addAmplifyInventoryOosMaterialValuesToTerritory,
      geography: addAmplifyInventoryOosGeographyValuesToTerritory,
      filters: addAmplifyInventoryOosFiltersValuesToTerritory
    },
    extraHub: {
      trending: addAmplifyExtraHubTrendingValuesToTerritory,
      engagement: addAmplifyExtraHubEngagementValuesToTerritory,
      geography: addAmplifyExtraHubGeographyValuesToTerritory,
      filters: addAmplifyExtraHubFiltersValuesToTerritory,
      activity: addAmplifyExtraHubActivityValuesToTerritory
    },
    distro: {
      geography: addDistroGeographyDataToTerritory,
      summary: addDistroSummaryDataToTerritory
    },
    priceCaptureCompliance: {
      storeFootprint: addAmplifyPriceCaptureComplianceFootprintValuesToTerritory,
      brandCompletion: addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory,
      filters: addAmplifyPriceCaptureComplianceFiltersValuesToTerritory,
      geography: addAmplifyPriceCaptureComplianceGeographyValuesToTerritory,
      strategyCompliance: addAmplifyPriceComplianceStrategyComplianceValuesToTerritory,
      edlpCompliance: addAmplifyPriceComplianceEdlpComplianceValuesToTerritory,
      priceComplianceGeography: addAmplifyPriceComplianceGeographyComplianceValuesToTerritory,
      priceComplianceGeographyFilters: addAmplifyPriceComplianceGeographyFiltersValuesToTerritory
    }
  },
  district: {
    sellout: {
      main: addAmplifySelloutValuesToDistrict,
      geography: addAmplifySelloutGeographyValuesToDistrict
    },
    sellin: {
      geography: addSellinGeographyToDistrict
    },
    inventoryOos: {
      trending: addAmplifyInventoryOosTrendingValuesToDistrict,
      brand: addAmplifyInventoryOosBrandsValuesToDistrict,
      materials: addAmplifyInventoryOosMaterialValuesToDistrict,
      geography: addAmplifyInventoryOosGeographyValuesToDistrict,
      filters: addAmplifyInventoryOosFiltersValuesToDistrict
    },
    extraHub: {
      trending: addAmplifyExtraHubTrendingValuesToDistrict,
      engagement: addAmplifyExtraHubEngagementValuesToDistrict,
      geography: addAmplifyExtraHubGeographyValuesToDistrict,
      filters: addAmplifyExtraHubFiltersValuesToDistrict,
      activity: addAmplifyExtraHubActivityValuesToDistrict
    },
    distro: {
      geography: addDistroGeographyDataToDistrict,
      summary: addDistroSummaryDataToDistrict
    },
    priceCaptureCompliance: {
      storeFootprint: addAmplifyPriceCaptureComplianceFootprintValuesToDistrict,
      brandCompletion: addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict,
      filters: addAmplifyPriceCaptureComplianceFiltersValuesToDistrict,
      geography: addAmplifyPriceCaptureComplianceGeographyValuesToDistrict,
      strategyCompliance: addAmplifyPriceComplianceStrategyComplianceValuesToDistrict,
      edlpCompliance: addAmplifyPriceComplianceEdlpComplianceValuesToDistrict,
      priceComplianceGeography: addAmplifyPriceComplianceGeographyComplianceValuesToDistrict,
      priceComplianceGeographyFilters: addAmplifyPriceComplianceGeographyFiltersValuesToDistrict
    }
  },
  region: {
    sellout: {
      main: addAmplifySelloutValuesToRegion,
      geography: addAmplifySelloutGeographyValuesToRegion
    },
    sellin: {
      geography: addSellinGeographyToRegion
    },
    inventoryOos: {
      trending: addAmplifyInventoryOosTrendingValuesToRegion,
      brand: addAmplifyInventoryOosBrandsValuesToRegion,
      materials: addAmplifyInventoryOosMaterialValuesToRegion,
      geography: addAmplifyInventoryOosGeographyValuesToRegion,
      filters: addAmplifyInventoryOosFiltersValuesToRegion
    },
    extraHub: {
      trending: addAmplifyExtraHubTrendingValuesToRegion,
      engagement: addAmplifyExtraHubEngagementValuesToRegion,
      geography: addAmplifyExtraHubGeographyValuesToRegion,
      filters: addAmplifyExtraHubFiltersValuesToRegion,
      activity: addAmplifyExtraHubActivityValuesToRegion
    },
    distro: {
      geography: addDistroGeographyDataToRegion,
      summary: addDistroSummaryDataToRegion
    },
    priceCaptureCompliance: {
      storeFootprint: addAmplifyPriceCaptureComplianceFootprintValuesToRegion,
      brandCompletion: addAmplifyPriceCaptureComplianceBrandCompletionValuesToRegion,
      filters: addAmplifyPriceCaptureComplianceFiltersValuesToRegion,
      geography: addAmplifyPriceCaptureComplianceGeographyValuesToRegion,
      strategyCompliance: addAmplifyPriceComplianceStrategyComplianceValuesToRegion,
      edlpCompliance: addAmplifyPriceComplianceEdlpComplianceValuesToRegion,
      priceComplianceGeography: addAmplifyPriceComplianceGeographyComplianceValuesToRegion,
      priceComplianceGeographyFilters: addAmplifyPriceComplianceGeographyFiltersValuesToRegion
    }
  },
  national: {
    sellout: {
      main: addAmplifySelloutValuesToNation,
      geography: addAmplifySelloutGeographyValuesToNation
    },
    sellin: {
      geography: addSellinGeographyToNation
    },
    inventoryOos: {
      trending: addAmplifyInventoryOosTrendingValuesToNation,
      brand: addAmplifyInventoryOosBrandsValuesToNation,
      materials: addAmplifyInventoryOosMaterialValuesToNation,
      geography: addAmplifyInventoryOosGeographyValuesToNation,
      filters: addAmplifyInventoryOosFiltersValuesToNation
    },
    extraHub: {
      trending: addAmplifyExtraHubTrendingValuesToNation,
      engagement: addAmplifyExtraHubEngagementValuesToNation,
      geography: addAmplifyExtraHubGeographyValuesToNation,
      filters: addAmplifyExtraHubFiltersValuesToNation,
      activity: addAmplifyExtraHubActivityValuesToNation
    },
    distro: {
      geography: addDistroGeographyDataToNation,
      summary: addDistroSummaryDataToNation
    },
    priceCaptureCompliance: {
      storeFootprint: addAmplifyPriceCaptureComplianceFootprintValuesToNation,
      brandCompletion: addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation,
      filters: addAmplifyPriceCaptureComplianceFiltersValuesToNation,
      geography: addAmplifyPriceCaptureComplianceGeographyValuesToNation,
      strategyCompliance: addAmplifyPriceComplianceStrategyComplianceValuesToNation,
      edlpCompliance: addAmplifyPriceComplianceEdlpComplianceValuesToNation,
      priceComplianceGeography: addAmplifyPriceComplianceGeographyComplianceValuesToNation,
      priceComplianceGeographyFilters: addAmplifyPriceComplianceGeographyFiltersValuesToNation
    }
  }
}
