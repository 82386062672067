import React from 'react'
import { object, oneOf } from 'prop-types'

import SortCell from 'components/table/cells/header/SortCell'
import TextCell from 'components/table/cells/header/TextCell'

const HeaderCell = ({ column, type, ...rest }) => {
  switch (type) {
    case 'sortcell':
      return <SortCell column={column} {...rest} />
    case 'textcell':
    default:
      return <TextCell column={column} {...rest} />
  }
}
HeaderCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The type of cell to render
   */
  type: oneOf(['sortcell', 'textcell']).isRequired
}

export default HeaderCell
