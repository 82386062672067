import styled from 'styled-components'

import { fontBold } from 'styles/typography'

export default styled.span`
  font-weight: ${fontBold};
  font-size: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
