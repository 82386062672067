import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import needsAuth from 'layouts/authWrappers/needsAuth'
import OrderApproval from 'views/OrderApproval'

import LangContext from 'context/LangContext'

import {
  getOrderForManagerApproval,
  getOrderForRetailerApproval,
  submitManagerOrderApproval,
  submitRetailerOrderApproval
} from 'store/orders/endpoints'

import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import GlobalHeader from 'components/Header'

import { deepBlue } from 'styles/colors'

const OrderApprovalLayout = () => {
  const { translate } = useContext(LangContext)
  const { retailerOrderToken, orderId } = useParams()

  const [order, setOrder] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const employee = useSelector((state) => state.auth.user)
  const isUserLoading = useSelector(({ auth }) => auth.loading)

  useEffect(() => {
    setLoading(false)
  }, [error, order])

  useEffect(() => {
    let mounted = true

    const getOrder = retailerOrderToken ? getOrderForRetailerApproval : getOrderForManagerApproval
    setLoading(true)
    getOrder(retailerOrderToken || orderId)
      .then(({ data }) => {
        if (mounted) {
          if (data.order) setOrder(data.order)
          if (data.error) setError(data.error)
          // setLoading(false)
        }
      })
      .catch((err) => {
        setError(err.message)
        // setLoading(false)
      })

    return () => {
      mounted = false
    }
  }, [orderId, retailerOrderToken])

  const isManager =
    order &&
    employee &&
    [order.rda?.id, order.employee.manager?.id, order.employee?.rda?.id]
      .concat(order.employee.manager?.delegations?.map(({ id }) => id) || [])
      .filter(Boolean)
      .includes(employee?.id)
  const isTM = employee?.id === order?.employeeId
  const isRetailer = Boolean(retailerOrderToken)
  const isNationAdmin = employee && employee.nationalMobilePermission

  const isActionable =
    (isRetailer && order?.status === 'awaitingRetailerApproval') ||
    (isManager && order?.status === 'awaitingManagerApproval')

  useEffect(() => {
    if (order && !isManager && !isNationAdmin && !isRetailer && !isTM && !isUserLoading) {
      setOrder(null)
      setError('invalidUser')
    }
  }, [order, isManager, isTM, isRetailer, isUserLoading])

  const submitOrderApproval = async ({ decision, rejectReason }) => {
    const submit = isRetailer ? submitRetailerOrderApproval : submitManagerOrderApproval
    const userType = isRetailer ? 'retailer' : 'manager'
    const update = {
      [`${userType}Decision`]: decision,
      [`${userType}DecisionAt`]: new Date().toISOString(),
      rejectReason: rejectReason || null,
      retailerOrderToken,
      orderId
    }
    setLoading(true)
    submit(update)
      .then(({ data }) => {
        setOrder(data.order)
        // setLoading(false)
      })
      .catch((err) => {
        setError(err.message)
      })
  }

  return (
    <>
      <GlobalHeader color={deepBlue} title={translate('app.orderApproval')} />
      {(isUserLoading || (!order && loading)) && <EmptyState isLoading={loading || isUserLoading} />}

      {(employee || isRetailer) && order && (
        <OrderApproval
          order={order}
          setOrder={setOrder}
          isRetailer={isRetailer}
          isManager={isManager}
          isTM={isTM}
          isActionable={isActionable}
          submitOrderApproval={submitOrderApproval}
          loading={loading}
        />
      )}

      {error && <GlobalAlert>{error}</GlobalAlert>}
    </>
  )
}

export const ManagerOrderApproval = needsAuth(OrderApprovalLayout)
export const RetailerOrderApproval = OrderApprovalLayout
