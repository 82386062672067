import React from 'react'
import { func, object } from 'prop-types'
import styled from 'styled-components'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const Spaced = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const ForcedCompleteSheet = ({ forcedCompleteDetails, setForcedCompleteDetails }) => {
  return (
    <ActionSheet title="Call completed" visible={forcedCompleteDetails}>
      <Spaced>
        The visit at {forcedCompleteDetails?.customer} was ended because you are {forcedCompleteDetails?.kms.toFixed(2)}{' '}
        kms away
      </Spaced>
      <Fieldset>
        <FieldsetItem>
          <Button full primary onClick={() => setForcedCompleteDetails(null)}>
            Okay
          </Button>
        </FieldsetItem>
      </Fieldset>
    </ActionSheet>
  )
}

ForcedCompleteSheet.propTypes = {
  forcedCompleteDetails: object,
  setForcedCompleteDetails: func.isRequired
}

export default ForcedCompleteSheet
