import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { array, number, object } from 'prop-types'
import styled, { css } from 'styled-components'

import SectorContext from 'context/SectorContext'

import Button from 'components/button/Button'
import Container from 'components/Container'
import Disclaimer from 'components/Disclaimer'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Status from 'components/Status'
import SurveyCompletionFilters from 'components/SurveyCompletionFilters'

import { SECTOR_LEVELS } from 'utils/constants'

import { borderColor, greyDark, greyLight, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, tinyFontSize } from 'styles/typography'

const Store = styled(Link)`
  display: block;
  position: relative;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  & + & {
    border-top: 0;
  }

  ${(props) =>
    props.accessDisabled &&
    css`
      cursor: default;
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    `};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-radius: ${borderRadius} ${borderRadius} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${borderRadius} ${borderRadius};
    }
  `};
`

const Stats = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.large};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  background-color: ${white};

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const StatsItem = styled.div`
  flex: 1;
  text-align: center;
`

const StatsNumber = styled(H3)`
  color: ${greyDark};
  margin-bottom: 0;
`

const StatsLabel = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const StoreHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.tiny};
`

const StoreName = styled.strong`
  display: block;
  color: ${greyDark};
`

const StoreMeta = styled.span`
  color: ${greyLight};
  display: block;
  font-size: ${tinyFontSize};
`

const ShowMore = styled(Button)`
  padding-top: ${spacing.medium};
`

const AutofillWrap = styled.div`
  margin-top: ${spacing.medium};
`

const Lock = styled(Icon)`
  margin-left: ${spacing.tiny};
`

const PAGE_SIZE = 20
const TerritorySurveyCustomers = ({
  total,
  complete,
  notStarted,
  inProgress,
  clarificationNeeded,
  survey,
  customers,
  employee
}) => {
  const navigate = useNavigate()
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [completionFilters, setCompletionFilters] = useState([])
  const [lastVisibleCustomer, setLastVisibleCustomer] = useState(PAGE_SIZE)
  const { fetchSector } = useContext(SectorContext)

  useEffect(() => {
    if (completionFilters.length) {
      const surveysFiltered = customers.filter((s) => {
        const hasValidCompletionStatus = completionFilters.length ? completionFilters.includes(s.completion) : true
        return hasValidCompletionStatus
      })
      setFilteredCustomers(surveysFiltered)
    } else {
      setFilteredCustomers(customers)
    }
  }, [customers, completionFilters])

  const { endDate, lockStartDate, lockEndDate, allLocked } = survey
  const currentlyLocked = lockStartDate && lockEndDate && moment().isBetween(lockStartDate, lockEndDate, 'day', '[]')
  const showAutoFill = !survey.isSox
  const autofillableSurveys = showAutoFill
    ? filteredCustomers.filter(({ completion, isLocked }) => {
        // if (completion === 'notStarted') return true
        if (!['notStarted', 'inProgress'].includes(completion)) return false
        return !currentlyLocked || !(isLocked || allLocked)
      })
    : []

  const userAutofillDisabled = employee.groupCode === 'telesalesRepresentative'

  const onAutofillClick = () => {
    navigate('autofill', { state: { targets: autofillableSurveys.map(({ customerId }) => customerId) } })
  }

  return (
    <Container>
      {allLocked && currentlyLocked && (
        <div style={{ marginBottom: spacing.medium }}>
          <GlobalAlert warning icon="lock">
            This survey cannot be edited once submitted
          </GlobalAlert>
        </div>
      )}
      <Stats>
        <StatsItem>
          <StatsNumber>{new Date(endDate).toDateString()}</StatsNumber>
          <StatsLabel>End date</StatsLabel>
        </StatsItem>
        <StatsItem>
          <StatsNumber>{((complete / total) * 100).toFixed(0)}%</StatsNumber>
          <StatsLabel>Completion rate</StatsLabel>
        </StatsItem>
      </Stats>
      <SurveyCompletionFilters
        total={total}
        complete={complete}
        notStarted={notStarted}
        inProgress={inProgress}
        clarificationNeeded={clarificationNeeded}
        updateCompletionFilters={setCompletionFilters}
      />
      <div>
        {filteredCustomers.slice(0, lastVisibleCustomer).map(({ name, address, completion, customerId, isLocked }) => {
          return (
            <Store
              key={customerId}
              onClick={() =>
                fetchSector({
                  id: customerId,
                  type: SECTOR_LEVELS.CUSTOMER
                })
              }
            >
              <StoreHeader>
                <Status status={completion} />
                <StoreName>{name}</StoreName>
                {isLocked && currentlyLocked && <Lock small icon="lock" />}
              </StoreHeader>
              <StoreMeta>ERP: {customerId}</StoreMeta>
              <StoreMeta>{address.line1}</StoreMeta>
            </Store>
          )
        })}
      </div>
      {filteredCustomers.length > lastVisibleCustomer && (
        <ShowMore full tertiary onClick={() => setLastVisibleCustomer(lastVisibleCustomer + PAGE_SIZE)}>
          Show more
        </ShowMore>
      )}
      {filteredCustomers.length > 0 && !survey.isSox && !userAutofillDisabled && (
        <AutofillWrap>
          <Button full primary disabled={!autofillableSurveys.length} onClick={onAutofillClick}>
            Autofill {autofillableSurveys.length} surveys
          </Button>
          <Disclaimer style={{ marginTop: spacing.medium }}>
            Only surveys that are not started or {currentlyLocked ? 'unlocked' : ''} in progress can be autofilled. Only
            blank answers will be filled. Existing answers will not be overwritten.
          </Disclaimer>
        </AutofillWrap>
      )}
    </Container>
  )
}

TerritorySurveyCustomers.propTypes = {
  total: number,
  complete: number,
  notStarted: number,
  inProgress: number,
  clarificationNeeded: number,
  customers: array,
  survey: object,
  employee: object
}

export default TerritorySurveyCustomers
