import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  border-bottom: 1px solid ${borderColor};

  &:last-child {
    border-bottom: none;
  }
`

const Question = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium} 0;
`

const QuestionTitle = styled(H3)`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
`

const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  padding-bottom: ${spacing.medium};
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};
`

class FAQAccordion extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    question: string.isRequired
  }

  state = {
    expanded: false
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { expanded } = this.state
    return (
      <Container expanded={expanded}>
        <Question onClick={this.toggleExpanded}>
          <QuestionTitle>{this.props.question}</QuestionTitle>
          <Chevron icon="down-chevron" accent expanded={expanded} />
        </Question>
        <Content expanded={expanded}>{this.props.children}</Content>
      </Container>
    )
  }
}

export default FAQAccordion
