import React, { useContext } from 'react'
import { node, number, oneOfType, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { green, greyLight, red, yellow } from 'styles/colors'
import * as spacing from 'styles/spacing'

const statusOptions = {
  needsApproval: {
    color: greyLight,
    label: 'app.orderStatus.needsApproval'
  },
  awaitingManagerApproval: {
    color: greyLight,
    label: 'app.orderStatus.awaitingManagerApproval'
  },
  awaitingRetailerApproval: {
    color: greyLight,
    label: 'app.orderStatus.awaitingRetailerApproval'
  },
  new: {
    color: greyLight,
    label: 'app.orderStatus.new'
  },
  pending: {
    color: greyLight,
    label: 'app.orderStatus.pending'
  },
  processing: {
    color: yellow,
    label: 'app.orderStatus.processing'
  },
  addedToOrder: {
    color: green,
    label: 'app.orderStatus.addedToOrder'
  },
  cancelled: {
    color: red,
    label: 'app.orderStatus.cancelled'
  },
  rejectedByManager: {
    color: red,
    label: 'app.orderStatus.rejectedByManager'
  },
  rejectedByRetailer: {
    color: red,
    label: 'app.orderStatus.rejectedByRetailer'
  },
  failure: {
    color: red,
    label: 'app.orderStatus.failure'
  },
  created: {
    color: greyLight,
    label: 'app.orderStatus.created'
  },
  extracted: {
    color: yellow,
    label: 'app.orderStatus.extracted'
  },
  approved: {
    color: green,
    label: 'app.orderStatus.approved'
  },
  rejected: {
    color: red,
    label: 'app.orderStatus.rejected'
  },
  modified: {
    color: green,
    label: 'app.orderStatus.modified'
  },
  invalid: {
    color: red
  },
  notCompetitive: {
    color: red
  },
  overCeiling: {
    color: red
  },
  expired: {
    color: red
  },
  valid: {
    color: green
  },
  updated: {
    color: green
  },
  incomplete: {
    color: red
  },
  partiallyComplete: {
    color: yellow
  },
  complete: {
    color: green
  },
  noStatus: {
    color: greyLight
  },
  notItCan: {
    color: greyLight
  },
  notStarted: {
    color: greyLight
  },
  inProgress: {
    color: yellow
  },
  clarificationNeeded: {
    color: red
  },
  unresolved: {
    color: red
  },
  outdated: {
    color: red
  },
  resolved: {
    color: green
  },
  pendingFollowUp: {
    color: greyLight
  },
  notRequired: {
    color: greyLight
  }
}

const colors = {
  none: greyLight,
  grey: greyLight,
  yellow,
  red,
  green
}
const Container = styled.div`
  display: inline-flex;
  align-items: center;

  &:before {
    content: '';
    flex-shrink: 0;
    display: block;
    width: ${spacing.small};
    height: ${spacing.small};
    margin-right: ${spacing.small};
    border-radius: 50%;
    background-color: ${(props) =>
      colors[props.color] || props.color || (statusOptions[props.status] || {}).color || greyLight};
  }
`

const Status = ({ status, color, label, children }) => {
  const { translate } = useContext(LangContext)

  if (!status && !color) return null
  return (
    <Container status={status} color={color}>
      {label || children || translate((statusOptions[status] || {}).label)}
    </Container>
  )
}

Status.propTypes = {
  status: string,
  label: oneOfType([number, string]),
  children: node,
  color: string
}

export default Status
