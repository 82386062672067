import React, { useContext, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { func } from 'prop-types'

import ActiveCallContext from 'context/ActiveCallContext'
import LangContext from 'context/LangContext'
import ScheduleContext from 'context/ScheduleContext'

import { fetchCustomerCalls } from 'store/customerCalls/actions'
import * as callSelector from 'store/customerCalls/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import CalendarAgenda from 'components/CalendarAgenda'
import CalendarWrapper from 'components/CalendarWrapper'
import SectionDivider from 'components/SectionDivider'

import { getErrorMessage } from 'utils/helpers'

const defaultCallsObject = { upcomingCalls: [], pastCalls: [], missedCalls: [] }

const CustomerCallsLogs = ({ fetchCustomerCalls, closeModal }) => {
  const { translate, lang } = useContext(LangContext)
  const { showCallDetailsScreen, showPastCallScreenScreen } = useContext(ScheduleContext)
  const { showStartCallScreen } = useContext(ActiveCallContext)

  const { sectorId: customerId } = useParams()

  const customer = useSelector((state) => callSelector.callLogsCustomer(state, { customerId }))
  const calendarEvents = useSelector((state) => callSelector.calendarCallsForCustomer(state, { customerId }))

  const onMonthChange = async (dateInfo) => {
    const { start, end } = dateInfo

    try {
      await fetchCustomerCalls({ after: start, before: end, customerId: customer.id })
    } catch (error) {
      console.error(getErrorMessage(error))
    }
  }

  const handleEventClick = ({ event }) => {
    const expandedProps = event?._def?.extendedProps
    closeModal()

    if (typeof expandedProps.callDetails.customer !== 'object') expandedProps.callDetails.customer = customer
    switch (expandedProps.eventType) {
      case 'past-call':
        showPastCallScreenScreen({ pastCall: expandedProps.callDetails })
        break
      case 'completed-call':
        showCallDetailsScreen({ displayCall: expandedProps.callDetails, callId: expandedProps.callDetails.id })
        break
      case 'scheduled-call':
        showStartCallScreen({
          call: expandedProps.callDetails,
          callId: expandedProps.callDetails.id,
          customerId: customer.id
        })
        break
    }
  }

  const { upcomingCalls, pastCalls, missedCalls } = useMemo(() => {
    if (!calendarEvents?.length) return defaultCallsObject

    return calendarEvents.reduce((acc, call) => {
      if (moment().isBefore(moment(call.end)))
        return {
          ...acc,
          upcomingCalls: acc.upcomingCalls.concat(call)
        }
      if (call.callCompleted)
        return {
          ...acc,
          pastCalls: acc.pastCalls.concat(call)
        }
      return {
        ...acc,
        missedCalls: acc.missedCalls.concat(call)
      }
    }, defaultCallsObject)
  }, [calendarEvents])

  return (
    <BasicAccordion title={translate('app.callAgenda')}>
      <CalendarWrapper>
        <SectionDivider title={translate('app.calls')} />
        <CalendarAgenda
          events={upcomingCalls}
          onEventClick={handleEventClick}
          initialDate={new Date().toISOString()}
          language={lang}
          setDateRange={onMonthChange}
        />

        <SectionDivider title={translate('app.missedCalls')} />
        <CalendarAgenda
          events={missedCalls}
          onEventClick={handleEventClick}
          initialDate={new Date().toISOString()}
          language={lang}
          setDateRange={onMonthChange}
        />

        <SectionDivider title={translate('app.pastCalls')} />
        <CalendarAgenda
          events={pastCalls}
          onEventClick={handleEventClick}
          initialDate={new Date().toISOString()}
          language={lang}
          setDateRange={onMonthChange}
        />
      </CalendarWrapper>
    </BasicAccordion>
  )
}

CustomerCallsLogs.propTypes = {
  fetchCustomerCalls: func.isRequired,
  closeModal: func
}

const mapActionCreators = {
  fetchCustomerCalls
}

export default connect(null, mapActionCreators)(CustomerCallsLogs)
