import React from 'react'
import { number, oneOfType, string } from 'prop-types'
import tw from 'twin.macro'

const Container = tw.div`flex space-x-4`
const Primary = tw.span`text-sm font-medium text-slate-900`

const Secondary = tw.span`text-sm font-medium text-slate-500`

const DataCompare = ({ primary, secondary }) => {
  return (
    <Container>
      <Primary>{primary}</Primary>
      <Secondary>{secondary}</Secondary>
    </Container>
  )
}

DataCompare.propTypes = {
  primary: oneOfType([string, number]).isRequired,
  secondary: oneOfType([string, number]).isRequired
}

export default DataCompare
