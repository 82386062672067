import React, { useContext } from 'react'
import { bool, func, string } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const ConfirmActionSheet = ({ title, visible, message, confirmAction, cancelAction, isLoading }) => {
  const { translate } = useContext(LangContext)

  return (
    <ActionSheet title={title} visible={visible} onOverlayClick={cancelAction}>
      <div>
        {message}
        <Fieldset>
          <FieldsetItem>
            <Button full primary isLoading={isLoading} disabled={isLoading} onClick={confirmAction}>
              {translate('common.yes')}
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full isLoading={isLoading} disabled={isLoading} onClick={cancelAction}>
              {translate('common.no')}
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

ConfirmActionSheet.propTypes = {
  title: string,
  message: string,
  visible: bool,
  confirmAction: func,
  cancelAction: func,
  isLoading: bool
}

export default ConfirmActionSheet
