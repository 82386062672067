import React from 'react'
import { mix, rgba, stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled, { css } from 'styled-components'

import Icon from 'components/Icon'

import { black, green, red, yellow } from 'styles/colors'
import { borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: block;
  border-radius: ${borderRadius};
  background-color: ${rgba(red, 0.15)};
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${spacing.medium};
  color: ${red};

  ${(props) =>
    props.success &&
    css`
      color: ${mix(0.3, black, green)};
      background-color: ${rgba(green, 0.15)};
    `};

  ${(props) =>
    props.warning &&
    css`
      color: ${mix(0.3, black, yellow)};
      background-color: ${rgba(yellow, 0.15)};
    `};

  a {
    border-bottom: 1px solid;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: ${spacing.small};
`

const GlobalAlert = ({ children, warning, success, error, icon }) => (
  <Container warning={warning} success={success} error={error || (!warning && !success)}>
    {icon && <StyledIcon icon={icon} />}
    {children}
  </Container>
)

GlobalAlert.propTypes = {
  children: node.isRequired,
  warning: bool,
  success: bool,
  error: bool,
  icon: string
}

export default GlobalAlert
