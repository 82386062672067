import React, { useContext, useEffect } from 'react'

import LangContext from 'context/LangContext'

import Activity from './Activity'
import ExtraHubHeader from './ExtraHubHeader'
import GeographyTable from './GeographyTable'
import Trended from './Trended'
import UserEngagement from './UserEngagement'

const AmplifyExtraHubLayout = () => {
  const { translate } = useContext(LangContext)

  useEffect(() => {
    document.title = 'Amplify - Extra Hub Activity'
  }, [])

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5">
      <ExtraHubHeader parentHeader="Amplify" header={translate('app.extraHubActivity')} />
      <Activity span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }} />
      <Trended span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }} />
      <UserEngagement span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }} />
      <GeographyTable span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
    </div>
  )
}
export default AmplifyExtraHubLayout
