import omit from 'lodash/omit'

import { addEntities, mergeEntities, setEntities } from 'store/actions'

import { addMileageToEmployeeOot, overrideEmployeeOot, removeEmployeOot, removeMileageToEmployeeOot } from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => Object.assign({ ...state, ...action.payload.employeeOots }),
  [setEntities]: (state, action) => action.payload.employeeOots || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.employeeOots) return state

    return Object.entries(payload.employeeOots).reduce((acc, [id, oot]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...oot
        }
      })
    }, Object.assign({}, state))
  },
  [removeEmployeOot]: (state, { payload }) => {
    return omit(state, payload.id)
  },
  [addMileageToEmployeeOot]: (state, { payload }) => {
    if (!payload) return state
    state[payload.employeeOotId].employeeKm = payload
    return state
  },
  [removeMileageToEmployeeOot]: (state, { payload }) => {
    if (!payload || !payload.employeeOotId) return state
    state[payload.employeeOotId].employeeKm = null
    return state
  },
  [overrideEmployeeOot]: (state, { payload }) => {
    if (!payload) return state
    const { idToReplace, oot } = payload
    const removeTemp = omit(state, idToReplace)
    return { ...removeTemp, [oot.id]: oot }
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
