import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { bool, func, node, oneOf, oneOfType, string } from 'prop-types'

import IconButton from 'components/button/IconButton'

import { cn } from 'utils/styling'

const overlayVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  },
  enter: {
    opacity: 1,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  }
}

const contentVariants = {
  initial: {
    opacity: 0,
    y: '5%',
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.25
    }
  }
}

export const Modal = ({
  title,
  subtitle,
  footer,
  open,
  onHandleClose,
  onOpenChange,
  children,
  size,
  trigger,
  preventClosing
}) => {
  const handlePointerDownOutside = (e) => {
    if (preventClosing) return e.preventDefault()
  }

  const handlEscapeKeyDown = (e) => {
    if (preventClosing) return e.preventDefault()
  }

  const handleOverlayClick = (e) => {
    e.stopPropagation()
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} modal={true}>
      {trigger && <Dialog.Trigger>{trigger}</Dialog.Trigger>}
      <AnimatePresence>
        {open && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild forceMount>
              <motion.div
                className="fixed inset-0 z-[200] bg-slate-950/60"
                key="overlay"
                variants={overlayVariants}
                initial="initial"
                animate="enter"
                exit="initial"
                onClick={handleOverlayClick}
              >
                <div className="flex h-full items-center justify-center px-2 text-center">
                  <Dialog.Content
                    forceMount
                    asChild
                    onPointerDownOutside={handlePointerDownOutside}
                    onEscapeKeyDown={handlEscapeKeyDown}
                  >
                    <motion.div
                      className={cn(
                        'relative my-8 flex max-h-[calc(100dvh-1rem)] w-full max-w-lg grow rounded-xl bg-white p-6 text-left shadow-xl focus:outline-none sm:p-8',
                        size === 'large' && 'max-w-3xl',
                        size === 'xLarge' && 'max-w-6xl'
                      )}
                      key="content"
                      variants={contentVariants}
                      initial="initial"
                      animate="enter"
                      exit="initial"
                    >
                      <div className="flex w-full grow flex-col gap-6 sm:gap-8">
                        {title || subtitle ? (
                          <div>
                            {title ? (
                              <Dialog.Title asChild>
                                <h3 className="text-xl font-semibold leading-6 text-slate-900">{title}</h3>
                              </Dialog.Title>
                            ) : null}
                            {subtitle ? (
                              <Dialog.Description asChild>
                                <p className="mt-3 text-sm text-slate-700">{subtitle}</p>
                              </Dialog.Description>
                            ) : null}
                          </div>
                        ) : null}

                        {children && <div className="-m-1 overflow-y-auto p-1">{children}</div>}

                        {footer && <div className="flex w-full justify-end">{footer}</div>}
                      </div>
                      <div className="absolute right-5 top-5">
                        {!preventClosing && (
                          <Dialog.Close asChild>
                            <IconButton
                              ghost
                              icon="close"
                              onClick={onHandleClose}
                              aria-label="Close modal"
                            ></IconButton>
                          </Dialog.Close>
                        )}
                      </div>
                    </motion.div>
                  </Dialog.Content>
                </div>
              </motion.div>
            </Dialog.Overlay>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

Modal.propTypes = {
  title: oneOfType([node, string]),
  subtitle: string,
  footer: node,
  open: bool,
  onHandleClose: func,
  onOpenChange: func,
  children: node,
  trigger: node,
  size: oneOf(['large', undefined]),
  preventClosing: bool
}
