import React from 'react'
import { Link } from 'react-router-dom'
import times from 'lodash/times'
import moment from 'moment'
import { rgba } from 'polished'
import { bool, func, object } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { filterAllowedRequiredValues, SECTOR_LEVELS } from 'utils/constants'

import { borderColor, grey, greyDark, greyLight, secondaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontRegular, Muted, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
`
const Feedback = styled(Link)`
  color: ${secondaryColor};
  display: inline-block;
  font-weight: ${fontRegular};
  font-size: ${smallFontSize};
  border-bottom: 1px solid ${rgba(secondaryColor, 0.25)};
`

const Name = styled.strong`
  display: block;
  color: ${greyDark};
  margin-bottom: ${spacing.tiny};
  padding-right: ${spacing.xxxLarge};
`

const Actions = styled.div`
  position: absolute;
  z-index: 10;
  top: ${spacing.medium};
  right: ${spacing.medium};
`

const Action = styled.button`
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};
  margin-left: ${spacing.small};

  &:hover,
  &:focus {
    color: ${grey};
  }
`

const ActionLink = styled(Link)`
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};
  margin-left: ${spacing.small};

  &:hover,
  &:focus {
    color: ${grey};
  }
`

const Photos = styled.div`
  display: flex;
  align-items: center;
`

const PhotoIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing.tiny};
`

const Rating = styled.span`
  display: inline-flex;
  align-items: center;
`

const IntelCard = ({ intel, deleteIntel, isPast, isMyIntel, customer, isCustomerView }) => {
  const {
    customerId,
    details,
    endDate,
    id,
    startDate,
    type,
    createdAt,
    tempId,
    images = [],
    impactRating,
    impactSummary,
    newImages = [],
    detailRequests,
    feedback
  } = intel
  const allowedRequiredValues = filterAllowedRequiredValues(type)
  const isMissingData =
    detailRequests &&
    detailRequests.filter((fieldRequest) => allowedRequiredValues.includes(fieldRequest)).some((field) => !intel[field])
  const requiresFeedback = feedback && feedback.some((qa) => !qa.answer)

  return (
    <Container>
      <Name>
        {isCustomerView ? (
          <small>{type}</small>
        ) : (
          customer && (
            <span>
              {customer.name}{' '}
              <small>
                ({customerId})<br />
                {type}
              </small>
            </span>
          )
        )}
      </Name>
      <p>
        <small>
          <Muted>
            {startDate ? (
              <span>
                {' '}
                {moment(startDate).format('MMMM D')}
                {endDate && ` - ${moment(endDate).format('MMMM D')}`}
              </span>
            ) : (
              <span>{moment(createdAt).format('MMMM D')}</span>
            )}
          </Muted>
        </small>
      </p>
      {details && (
        <p>
          <small>
            <Muted>{details}</Muted>
          </small>
        </p>
      )}
      {(isMissingData || requiresFeedback) && isMyIntel && (
        <Feedback to={`/${SECTOR_LEVELS.CUSTOMER}/${customerId}/actions/intel/feedback/${id}`}>
          Provide requested feedback
        </Feedback>
      )}
      {isMyIntel && (
        <Actions>
          <ActionLink
            to={`/${SECTOR_LEVELS.CUSTOMER}/${customerId}/actions/intel/${
              isMissingData || requiresFeedback ? 'feedback' : 'edit'
            }/${id}`}
          >
            <Icon icon="edit" />
          </ActionLink>
          <Action onClick={() => deleteIntel({ customerId, id, tempId })}>
            <Icon icon="trash" />
          </Action>
        </Actions>
      )}
      <p>
        {impactRating && (
          <Rating>
            {times(impactRating, (i) => (
              <Icon icon="star" small key={i} />
            ))}
          </Rating>
        )}
      </p>
      {impactSummary && <p>{impactSummary}</p>}
      {images.length + newImages.length > 0 && (
        <Photos>
          <PhotoIcon icon="photo" accent small />
          <small>{images.length + newImages.length}</small>
        </Photos>
      )}
    </Container>
  )
}

IntelCard.propTypes = {
  deleteIntel: func.isRequired,
  isPast: bool,
  isMyIntel: bool,
  intel: object,
  customer: object,
  isCustomerView: bool
}

export default IntelCard
