import styled from 'styled-components'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Logo = styled.img`
  margin-left: ${spacing.medium};
  margin-bottom: ${spacing.medium};
  width: 160px;

  ${media.breakpoint`
    width: 170px;
    margin-left: 0;
    margin-bottom: ${spacing.large};
  `};
`

export default Logo
