import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { setDataFetch } from 'store/dataFetches/actions'
import outbox from 'store/outbox'
import { sellInProgram as SellInProgramSchema } from 'store/schema'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS } from 'utils/helpers'

import * as api from './endpoints'

const queueOfflineSellInTarget = async (sellInTarget) => {
  const sellInTargetOutbox = (await outbox.getItem('sell-in-targets')) || {}
  await outbox.setItem('sell-in-targets', {
    ...sellInTargetOutbox,
    [`${sellInTarget.sellInProgramId}_${sellInTarget.customerId}`]: sellInTarget
  })
  return registerSync('submit-pending-sell-in-targets')
}

const queueOfflineSellInTargetException = async (sellInTargetException) => {
  const sellInTargetExceptionOutbox = (await outbox.getItem('sell-in-target-exceptions')) || {}
  await outbox.setItem('sell-in-target-exceptions', {
    ...sellInTargetExceptionOutbox,
    [`${sellInTargetException.sellInProgramId}_${sellInTargetException.customerId}`]: sellInTargetException
  })
  return registerSync('submit-pending-sell-in-target-exceptions')
}

export const addSellInTargetEntities = createAction(
  'Add new/updated sell-in target',
  (sellInProgramTarget) => (dispatch) => {
    const sellInProgram = { id: sellInProgramTarget.sellInProgramId, targets: [sellInProgramTarget] }
    const { entities } = normalize(sellInProgram, SellInProgramSchema)
    dispatch(mergeEntities(entities))
  }
)

export const submitCustomerProgramUpdate = createAction(
  'Submit customer sell-in program update',
  ({ customer, ...values }, redirect) =>
    async (dispatch) => {
      let programUpdate
      try {
        if (window.navigator.onLine) {
          const {
            data: { sellInProgramTarget }
          } = await api.submitUpdatedCustomerSellInProgram(values)
          programUpdate = sellInProgramTarget
        } else {
          await queueOfflineSellInTarget({ customer, ...values })
          programUpdate = { customer, ...values }
        }

        dispatch(addSellInTargetEntities(programUpdate))
      } catch (err) {
        if (err.message !== 'Network Error') {
          throw err
        }
        await queueOfflineSellInTarget(values)
        dispatch(addSellInTargetEntities(values))
      } finally {
        if (redirect) {
          redirect()
        }
      }
    }
)

export const submitCustomerSellInException = createAction(
  'Submit customer sell-in program exception',
  (values, sellInTarget, redirect) => async (dispatch) => {
    let programUpdate
    try {
      if (window.navigator.onLine) {
        const {
          data: { sellInProgramTarget }
        } = await api.submitCustomerSellInProgramException(values)
        programUpdate = sellInProgramTarget
      } else {
        // await queueOfflineSellInTargetException(values)
        programUpdate = { ...sellInTarget, exceptions: (sellInTarget.exceptions || []).concat(values) }
      }

      dispatch(addSellInTargetEntities(programUpdate))
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      // await queueOfflineSellInTargetException(values)
      dispatch(addSellInTargetEntities({ ...sellInTarget, exceptions: (sellInTarget.exceptions || []).concat(values) }))
    } finally {
      if (redirect) {
        redirect()
      }
    }
  }
)

export const cancelCustomerSellInException = createAction(
  'Cancel customer sell-in program exception',
  (values, sellInTarget) => async (dispatch) => {
    let programUpdate
    try {
      if (window.navigator.onLine) {
        const {
          data: { sellInProgramTarget }
        } = await api.cancelCustomerSellInProgramException(values)
        programUpdate = sellInProgramTarget
      } else {
        await queueOfflineSellInTargetException(values)
        programUpdate = {
          ...sellInTarget,
          exceptions: (sellInTarget.exceptions || []).filter(({ id }) => id !== values.id)
        }
      }

      dispatch(addSellInTargetEntities(programUpdate))
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      await queueOfflineSellInTargetException(values)
      dispatch(
        addSellInTargetEntities({
          ...sellInTarget,
          exceptions: (sellInTarget.exceptions || []).filter(({ id }) => id !== values.id)
        })
      )
    }
  }
)
export const fetchSellInProgram = createAction(
  'Fetch Sell-In Program',
  (sellInProgramId, territoryId, loadingKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches
    if (dataIsStillValid(dataFetchesState, loadingKey)) return
    try {
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchSellInProgramForTerritory({ sellInProgramId, territoryId })
      const targetsByTerritory = { [territoryId]: data.sellInProgram.targets.map(({ customerId }) => customerId) }
      const { entities } = normalize({ targetsByTerritory, ...data.sellInProgram }, SellInProgramSchema)
      dispatch(mergeEntities(entities))
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.OVER }))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey: loadingKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)
