import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SECTOR_LEVELS } from 'utils/constants'

import CustomerSellInAction from './CustomerSellInAction'
import TerritorySellInAction from './TerritorySellInAction'

const SellInActionSwitcher = () => {
  const { sectorType } = useParams()

  useEffect(() => {
    document.title = 'Actions - Sell-In'
  }, [])

  return (
    <>
      {sectorType === SECTOR_LEVELS.CUSTOMER && <CustomerSellInAction />}
      {sectorType === SECTOR_LEVELS.TERRITORY && <TerritorySellInAction />}
    </>
  )
}

export default SellInActionSwitcher
