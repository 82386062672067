import React from 'react'
import { string } from 'prop-types'

import Icon from 'components/Icon'

import { accents } from '../../styles/colors'

const CloseRecommendation = ({ type, recommendation }) => {
  const propertiesByType = {
    OOS: { color: accents.lime, icon: 'shelves' },
    seasonality: { color: accents.lightblue, icon: 'bar-chart-4-bars' },
    surge: { color: accents.magenta, icon: 'trending-up' }
  }

  return (
    <div className="flex gap-3 rounded-md bg-slate-50 p-4">
      <div className="shrink-0" style={{ color: propertiesByType[type].color }}>
        <Icon icon={propertiesByType[type].icon} medium />
      </div>
      <p className="font-medium text-slate-700">{recommendation}</p>
    </div>
  )
}

CloseRecommendation.propTypes = {
  type: ('OOS' | 'seasonality' | 'surge').isRequired,
  recommendation: string.isRequired
}

export default CloseRecommendation
