import React, { PureComponent } from 'react'
import { stripUnit } from 'polished'
import { node, number, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'
import SmallCaps from 'components/SmallCaps'

import { greyLight, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

import { Action, Container, Content, Header, Title } from './ProgramAccordion'

const Rating = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${spacing.small};
`

const RatingNumber = styled(SmallCaps)`
  margin-left: ${spacing.tiny};
`

const Count = styled.div`
  color: ${white};
  background-color: ${greyLight};
  padding: ${spacing.tiny} ${stripUnit(spacing.tiny) * 2.5}px;
  display: inline-block;
  margin-left: ${spacing.small};
  border-radius: ${spacing.large};
  font-size: ${tinyFontSize};
`
const AccordionTitle = ({ title }) => {
  const [category, manufacturer, brand, type] = title.split('|')
  return (
    <Title>
      {!manufacturer ? (
        <span>{category}</span>
      ) : (
        <span>
          {category}: {manufacturer} - {brand}
          <br />
          {type}
        </span>
      )}
    </Title>
  )
}

AccordionTitle.propTypes = {
  title: string
}

class IntelAccordion extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    title: string.isRequired,
    number,
    avgStars: number
  }

  state = {
    expanded: false
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { title, number, avgStars, children } = this.props
    const { expanded } = this.state

    const stars = avgStars ? avgStars.toFixed(1) : '-'

    return (
      <Container expanded={expanded}>
        <Header>
          <AccordionTitle title={title} />
          <Rating>
            <Icon icon="star" small />
            <RatingNumber>{stars}</RatingNumber>
            <Count>{number}</Count>
          </Rating>
          <Action onClick={this.toggleExpanded}>
            <span>{expanded ? 'hide' : 'show'}</span>
          </Action>
        </Header>
        <Content expanded={expanded}>{expanded && children}</Content>
      </Container>
    )
  }
}

export default IntelAccordion
