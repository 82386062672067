import React from 'react'
import { string } from 'prop-types'

import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'

const PlanHeader = ({ parentHeader, header }) => {
  return (
    <ViewHeader
      parentHeader={parentHeader}
      header={header}
      filters={[<ProductTypePicker key="plan-product-type-picker" />]}
    />
  )
}

PlanHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default PlanHeader
