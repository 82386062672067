import React from 'react'
import { bool, number } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const NoVariation = tw.div``

const IconWrapper = styled.div(({ variation, reverseVariation }) => [
  variation > 0
    ? reverseVariation
      ? tw`text-red-500`
      : tw`text-green-500`
    : reverseVariation
    ? tw`text-green-500 rotate-180`
    : tw`text-red-500 rotate-180`
])

const VariationIndicator = ({ variation, reverseVariation }) => {
  return variation ? (
    <IconWrapper variation={variation} reverseVariation={reverseVariation}>
      <Icon caret icon="caret" />
    </IconWrapper>
  ) : (
    <NoVariation />
  )
}

VariationIndicator.propTypes = {
  variation: number.isRequired,
  reverseVariation: bool
}

export default VariationIndicator
