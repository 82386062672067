import React from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'

import Disclaimer from 'components/Disclaimer'

const content = {
  en: (
    <div>
      <p>INTERNAL CONFIDENTIAL DOCUMENT FOR ITCO&apos;S SALES FORCE USE ONLY.</p>
      <p>DO NOT SHOW OR REMIT TO A CUSTOMER OR ANY THIRD-PARTY</p>
    </div>
  ),
  fr: (
    <div>
      <p>DOCUMENT INTERNE CONFIDENTIEL À L&apos;USAGE EXCLUSIF DE LA FORCE DE VENTE D&apos;ITCO.</p>
      <p>NE PAS MONTRER NI REMETTRE À UN CLIENT OU À UN TIERS.</p>
    </div>
  )
}
const PricingAnalyticsDisclaimer = ({ language = 'en' }) => <Disclaimer>{content[language]}</Disclaimer>

PricingAnalyticsDisclaimer.propTypes = {
  language: string
}

export default connect((state) => ({ language: state.auth.user.preferredLanguage.substring(0, 2) }))(
  PricingAnalyticsDisclaimer
)
