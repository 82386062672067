import React, { createContext, useContext, useState } from 'react'
import { node } from 'prop-types'

import SectorContext from 'context/SectorContext'

const SellinContext = createContext()
export default SellinContext

export const SellinProvider = ({ children }) => {
  const { currentProductType } = useContext(SectorContext)
  const [vapeCategory, setVapeCategory] = useState('all')
  const [fmcUnitOfMeasure, setFmcUnitOfMeasure] = useState('ceq')
  const [vapeUnitOfMeasure, setVapeUnitOfMeasure] = useState('ceq')

  const unitOfMeasure = currentProductType === 'fmc' ? fmcUnitOfMeasure : vapeUnitOfMeasure
  const setUnitOfMeasure = currentProductType === 'fmc' ? setFmcUnitOfMeasure : setVapeUnitOfMeasure
  const value = {
    vapeCategory,
    setVapeCategory,
    unitOfMeasure,
    setUnitOfMeasure
  }

  return <SellinContext.Provider value={value}>{children}</SellinContext.Provider>
}

SellinProvider.propTypes = {
  children: node.isRequired
}
