import { omit } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { DATA_EXPIRATION_MINUTES, DATA_UPDATE_STATUS } from 'utils/constants'

const allDataFetches = (state) => omit(state.dataFetches, '_persist')

const dataKeyFromProps = (state, props) => props.dataKey

export const getDataFetchStatus = createSelector(allDataFetches, dataKeyFromProps, (dataFetches, dataKey) => {
  if (!dataKey) return false
  const existingFetch = dataFetches[dataKey]
  return existingFetch
})

export const isDataKeyLoading = createSelector(getDataFetchStatus, (fetchDetails) => {
  if (!fetchDetails) return false
  return fetchDetails.status === DATA_UPDATE_STATUS.LOADING
})

export const isDataKeyStillValid = createSelector(getDataFetchStatus, (fetchDetails) => {
  if (!fetchDetails) return false
  return (
    fetchDetails.status === DATA_UPDATE_STATUS.OVER &&
    moment(fetchDetails.lastFetch).diff(moment(), 'minutes') < DATA_EXPIRATION_MINUTES
  )
})
