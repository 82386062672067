import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import maxBy from 'lodash/maxBy'
import moment from 'moment'
import { array, string } from 'prop-types'
import styled from 'styled-components'

import Button from 'components/button/Button'
import SmallCaps from 'components/SmallCaps'
import Status from 'components/Status'

import { PROJECT_STATUSES } from 'utils/constants'

import { borderColor, greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, tinyFontSize } from 'styles/typography'

const Row = styled(Link)`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-size: ${tinyFontSize};
  padding-top: ${spacing.small};
  margin-bottom: ${spacing.small};

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const Cell = styled.div`
  flex: 1;
  text-align: right;
  font-size: ${tinyFontSize};

  &:first-child {
    flex: 2;
    text-align: left;
  }
`

const Name = styled.span`
  color: ${greyDark};
  display: flex;
  align-items: center;
  font-weight: ${fontSemibold};
  font-size: ${tinyFontSize};
`

const ShowMore = styled(Button)`
  padding-top: ${spacing.medium};
`

const PAGE_SIZE = 10
const TerritoryCustomerProject = ({ preferredLanguage, customers, labels }) => {
  const [lastVisibleCustomer, setLastVisibleCustomer] = useState(PAGE_SIZE)
  return (
    <>
      <Row as="div">
        {labels?.map((col, idx) => (
          <Cell key={col + idx}>
            <SmallCaps>{col}</SmallCaps>
          </Cell>
        ))}
      </Row>
      {customers.slice(0, lastVisibleCustomer).map(({ customerId, name, status, actionLinks, projectId, summary }) => {
        return (
          <Row
            key={customerId}
            to={{
              pathname: `/customer/${customerId}/pace/close/insight-to-action/${projectId}`
            }}
          >
            <Cell>
              <Name>
                {customerId}
                <br />
                {name}
              </Name>
            </Cell>
            <Cell>{summary[preferredLanguage]}</Cell>
            <Cell>
              {actionLinks?.length
                ? moment(maxBy(actionLinks, 'updatedAt').updatedAt).format('MM/DD/YYYY')
                : 'To action'}
            </Cell>
            <Cell>
              <Status status={status} label={PROJECT_STATUSES[status]} />
            </Cell>
          </Row>
        )
      })}
      {customers.length > lastVisibleCustomer && (
        <ShowMore full tertiary onClick={() => setLastVisibleCustomer(lastVisibleCustomer + PAGE_SIZE)}>
          Show more
        </ShowMore>
      )}
    </>
  )
}
TerritoryCustomerProject.propTypes = {
  customers: array,
  preferredLanguage: string,
  labels: array.isRequired
}

export default TerritoryCustomerProject
