import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningSellout = ({ id, sectorLevel, activeProductType, manufacturer, channel }) =>
  api.get(
    `/sellout-actuals?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        manufacturer,
        channel
      },
      { skipNull: true }
    )}`
  )

export const fetchAmplifySellout = ({ id, sectorLevel, activeProductType, channel, dataType, unitOfMeasure }) => {
  // console.log({ id, sectorLevel, activeProductType, channel })
  return api.get(
    `/sellout-actuals/amplify?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        channel,
        unitOfMeasure,
        dataType
      },
      { skipNull: true }
    )}`
  )
}

export const fetchAmplifySelloutGeographyData = (params) => {
  // console.log({ id, sectorLevel, activeProductType, channel, unitOfMeasure })
  return api.get(`/sellout-actuals/amplify/geography?${queryString.stringify(params, { skipNull: true })}`)
}
