import React from 'react'
import { useSelector } from 'react-redux'
import Select, { components } from 'react-select'
import { mix, rgba, stripUnit } from 'polished'
import { func, string } from 'prop-types'
import styled from 'styled-components'

import * as authSelector from 'store/auth/selectors'

import { black, borderColor, grey, offWhite, red, secondaryColor } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { smallFontSize } from 'styles/typography'

const StyledSuggest = styled(Select)`
  position: relative;

  .multi-select__control {
    border-radius: ${borderRadius};
    border-color: ${borderColor};
    transition: border-color ${animationTime} ${animationCurve};

    &:hover {
      border-color: ${mix(0.1, black, borderColor)};
    }

    &--is-focused {
      border-color: ${secondaryColor};

      &:hover {
        border-color: ${secondaryColor};
      }
    }
  }

  .multi-select__multi-value {
    color: ${grey};
    background-color: ${offWhite};
    font-size: ${smallFontSize};
    border-radius: ${stripUnit(borderRadius) - 2 + 'px'};
  }

  .multi-select__multi-value__remove {
    cursor: pointer;
    color: ${grey};
    transition: all ${animationTime} ${animationCurve};

    &:hover {
      background-color: ${rgba(red, 0.15)};
      color: ${red};
    }
  }
`

const VFSuggest = ({ value, onChange, ...props }) => {
  const vfAssignmentOptions = useSelector((state) => authSelector.vfAssignmentOptions(state, props))

  return (
    <StyledSuggest
      {...props}
      inputId="vf-suggest"
      value={value}
      isClearable={Boolean(value)}
      cacheOptions
      className="vf-select"
      classNamePrefix="vf-select"
      getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
      getOptionValue={({ id }) => id}
      onChange={onChange}
      placeholder="Search name or email..."
      menuPlacement="top"
      options={vfAssignmentOptions}
      components={components.Group}
    />
  )
}

VFSuggest.propTypes = {
  onChange: func.isRequired,
  value: string
}

export default VFSuggest
