import React from 'react'
import { Link } from 'react-router-dom'
import { bool, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'
import RawSpinner from 'components/Spinner'

const Container = styled.button((props) => [
  tw`relative flex h-9 w-9 items-center justify-center rounded-md transition`,
  props.primary &&
    tw` shadow-md bg-brand text-white hover:bg-brand-700 active:bg-brand-700 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.confirm &&
    tw` shadow-md bg-green-500 text-white hover:bg-green-400 active:bg-green-400 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.cancel &&
    tw` shadow-md bg-red-500 text-white hover:bg-red-400 active:bg-red-400 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.secondary &&
    tw` shadow-md bg-white text-slate-800 ring-1 ring-slate-900/10 hover:bg-slate-50 active:bg-slate-50 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.ghost &&
    tw`text-slate-800 hover:bg-slate-100 active:bg-slate-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.dark && tw`text-white`,
  props.header && tw`h-auto text-white`,
  props.isLoading && tw`pointer-events-none`,
  props.disabled && tw`cursor-default pointer-events-none opacity-40 select-none shadow-none`
])

export const Spinner = tw(RawSpinner)`absolute -ml-3 -mt-3 top-1/2 left-1/2`

const getContainerAttrs = (props) => {
  if (props.to) return { as: Link }
  if (props.href) return { as: 'a' }
  return { as: 'button', type: props.type || 'button' }
}

const IconButton = React.forwardRef(({ isLoading, disabled, offlineDisabled, compact, medium, icon, ...rest }, ref) => {
  const containerAttrs = getContainerAttrs(rest)

  if (offlineDisabled && !window.navigator.onLine) disabled = true

  return (
    <Container isLoading={isLoading} disabled={disabled} ref={ref} {...containerAttrs} {...rest}>
      {!isLoading && <Icon left icon={icon} compact={compact} medium={medium} />}
      {isLoading && <Spinner icon="spinner" />}
    </Container>
  )
})

IconButton.propTypes = {
  icon: string.isRequired,
  primary: bool,
  secondary: bool,
  ghost: bool,
  isLoading: bool,
  disabled: bool,
  offlineDisabled: bool,
  compact: bool,
  medium: bool
}

IconButton.displayName = 'IconButton'

export default IconButton
