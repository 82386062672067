import React, { useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { array, func, string } from 'prop-types'

import Checkbox from 'components/Checkbox'
import Icon from 'components/Icon'

const Checklist = ({ header, items, handleCheck }) => {
  const [checklistItems, setChecklistItems] = useState(items)
  const [headerChecked, setHeaderChecked] = useState(false)

  const handleHeaderCheck = () => {
    const newHeaderChecked = !headerChecked
    const updatedItems = checklistItems.map((item) => ({ ...item, checked: newHeaderChecked }))
    setChecklistItems(updatedItems)
    setHeaderChecked(newHeaderChecked)
    handleCheck(updatedItems)
  }

  const handleItemCheck = (index) => {
    const updatedItems = [...checklistItems]
    updatedItems[index] = { ...updatedItems[index], checked: !updatedItems[index].checked }
    setChecklistItems(updatedItems)

    const allChecked = updatedItems.every((item) => item.checked)
    setHeaderChecked(allChecked)

    handleCheck(updatedItems)
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full justify-between">
        <Accordion.Root collapsible className="w-full" defaultValue={header}>
          <Accordion.Item value={header}>
            <Accordion.Trigger className="group flex items-center gap-2">
              <div className="flex size-6 items-center justify-center">
                <Icon icon="down-chevron" className="transition-transform group-data-[state=open]:-rotate-180" />
              </div>
              <h4 className="text-left text-base font-medium leading-snug">{header}</h4>
            </Accordion.Trigger>
            <Accordion.Content className="group w-[calc(100%+30px)] overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
              <div className="flex max-h-40 flex-col gap-2 overflow-y-auto pl-8 ">
                {checklistItems.map((item, index) => (
                  <div
                    key={item.label}
                    className="flex max-w-full items-center justify-between gap-2 transition-opacity duration-150 first:pt-3 group-data-[state=closed]:opacity-0 group-data-[state=open]:opacity-100"
                  >
                    <span className="text-slate-600">{item.label}</span>
                    <Checkbox checked={item.checked} onClick={() => handleItemCheck(index)} />
                  </div>
                ))}
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
        <div className="py-1.5">
          <Checkbox checked={headerChecked} onClick={handleHeaderCheck} />
        </div>
      </div>
    </div>
  )
}

Checklist.propTypes = {
  header: string.isRequired,
  items: array.isRequired,
  handleCheck: func.isRequired
}

export default Checklist
