import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import tw from 'twin.macro'
import PriceCaptures from 'views/Customer/Performance/PriceCaptures'

import { getCustomer } from 'store/customers/actions'

import LoadingCard from 'components/LoadingCard'
import PricingAnalyticsDisclaimer from 'components/PricingAnalyticsDisclaimer'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const OuterContainer = tw.div`flex flex-col h-full`

const CustomerPricingView = ({ connectedGetCustomer }) => {
  const { sectorId: customerId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const isMounted = useRef(false)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const getCustomerCaptures = async (customerId, dataKey) => {
    await connectedGetCustomer(customerId, dataKey)
  }

  useEffect(() => {
    setIsLoading(true)
    getCustomerCaptures(customerId, dataKey).then(() => {
      isMounted.current && setIsLoading(false)
    })
  }, [customerId])

  if (isLoading) return <LoadingCard dataKey={dataKey} />

  return (
    <OuterContainer>
      <PriceCaptures />
      <PricingAnalyticsDisclaimer />
    </OuterContainer>
  )
}

CustomerPricingView.propTypes = {
  connectedGetCustomer: func
}

export default connect(null, {
  connectedGetCustomer: getCustomer
})(CustomerPricingView)
