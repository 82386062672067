import React from 'react'
import { any, func, object } from 'prop-types'

import { isNullish } from 'utils/helpers'

const TextCell = ({ column, label, labelGenFn, record }) => {
  // nullish coalescing (??) operator would be nice here ...
  const value = !isNullish(record[column.name]) ? record[column.name] : null
  const lLabel = label || labelGenFn?.(record) || value
  return <td>{lLabel}</td>
}
TextCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The record the cell will render
   */
  record: object.isRequired,

  label: any,
  /**
   * An optional function that should provide the label that will be displayed inside the cell.
   * If not provided, the label is going to be the value of the record value matching the column name.
   * If no such value exist, the label will be the column name
   * @param {object} record The record provided to the cell
   * @returns {string} The label that will be displayed in the cell
   */
  labelGenFn: func
}

export default TextCell
