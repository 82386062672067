import React, { useContext } from 'react'
import { bool, object } from 'prop-types'

import LangContext from 'context/LangContext'

import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { getErrorMessage } from 'utils/helpers'

const GeographyDataTable = ({ isLoading, error, ...dataTableProps }) => {
  const { translate } = useContext(LangContext)

  if (error) {
    return <EmptyState title={translate('app.warn.errorOccured')} subtitle={getErrorMessage(error)} />
  }

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner icon="spinner" />
      </div>
    )
  }

  const { rows, columns } = dataTableProps

  if (!rows?.length || !columns?.length) {
    return <EmptyState title={translate('app.warn.nothingToDisplay')} />
  }

  return <DataTable {...dataTableProps} />
}

export default GeographyDataTable

GeographyDataTable.propTypes = {
  isLoading: bool.isRequired,
  error: object,
  dataTableProps: object
}
