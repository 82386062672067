import React, { useContext, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentPlugin from '@fullcalendar/moment'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import upperFirst from 'lodash/upperFirst'
import moment from 'moment-timezone'
import { array, func, string } from 'prop-types'

import LangContext from 'context/LangContext'

import CalendarWrapper from 'components/CalendarWrapper'
import Dropdown from 'components/Dropdown'

import { cn } from 'utils/styling'

const CalendarSchedule = ({
  events,
  onEventClick,
  selectedDate,
  setSelectedDate,
  workDayStart = '07:00:00',
  ...rest
}) => {
  const { lang: language } = useContext(LangContext)
  const { translate } = useContext(LangContext)

  const calendarRef = useRef()
  const [isCalendarMounted, setIsCalendarMounted] = useState(false)

  const smallWindowThreshold = 600

  const isWindowSmall = window.innerWidth < smallWindowThreshold

  const [viewType, setViewType] = useState(() => (isWindowSmall ? 'timeGrid3Day' : 'timeGridWeek'))

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      if (viewType === 'timeGrid3Day') {
        const yesterday = moment().subtract(1, 'day').startOf('day')
        calendarApi.gotoDate(yesterday.toDate())
      }
    }
  }, [isCalendarMounted])

  const [eventClickParams, setEventClickParams] = useState()

  useEffect(() => {
    if (eventClickParams) {
      onEventClick(eventClickParams)
      setEventClickParams()
    }
  }, [eventClickParams, onEventClick])

  const handleDateNavigation = ({ startStr, endStr }) => {
    if (!calendarRef?.current?.calendar) return
    const isInCurrentWorkday = moment().isBetween(moment(startStr), moment(endStr)) && moment().hour() < 20
    const timeToScroll = isInCurrentWorkday ? moment().subtract(1, 'hour').format('HH:mm:ss') : workDayStart
    calendarRef.current.calendar.scrollToTime(timeToScroll)
    setSelectedDate(startStr)
  }

  /*
    Updates getDefaultTime to return either 30 minutes before the first event of the week or 5 hours before current time, whichever is earlier.
    If there are no events for the week, it returns the workDayStart time.
  */
  const getDefaultTime = () => {
    const fiveHoursBeforeNow = moment().subtract(5, 'hours').format('HH:mm:ss')

    if (!events || events.length === 0) return workDayStart

    // Filter out events for the current week
    const weekStart = moment().startOf('week')
    const weekEnd = moment().endOf('week')
    const weekEvents = events.filter((event) => moment(event.start).isBetween(weekStart, weekEnd))

    if (weekEvents.length === 0) return workDayStart

    // Sort events to get the earliest
    const sortedEvents = weekEvents.sort((a, b) => moment(a.start).diff(moment(b.start)))
    const earliestEventTime = moment(sortedEvents[0].start).subtract(30, 'minutes').format('HH:mm:ss')

    // Return the earlier of the two times (30 minutes before first event or 5 hours before current time)
    return moment(earliestEventTime, 'HH:mm:ss').isBefore(moment(fiveHoursBeforeNow, 'HH:mm:ss'))
      ? earliestEventTime
      : fiveHoursBeforeNow
  }

  const handleWindowResize = () => {
    const isWindowSmall = window.innerWidth < smallWindowThreshold

    if (isWindowSmall && viewType !== 'timeGrid3Day') {
      handleViewChange('timeGrid3Day')
    } else if (!isWindowSmall && viewType !== 'timeGridWeek') {
      handleViewChange('timeGridWeek')
    }
  }

  const currentLocale = language === 'en' ? 'en-US' : 'fr-FR'

  const RenderDayHeaderContent = ({ date }) => {
    const momentDate = moment(date).locale(currentLocale)
    const isToday = momentDate.isSame(moment(), 'day')
    const isPast = momentDate.isBefore(moment(), 'day')

    return (
      <button
        onClick={() => setViewType('timeGridDay')}
        className={cn(
          'flex shrink-0 flex-col items-center pb-0.5 font-medium',
          !isToday && 'group',
          viewType === 'timeGridDay' && 'pointer-events-none'
        )}
      >
        <div
          className={cn(
            'text-2xs text-slate-600 transition-colors',
            isPast && 'text-slate-500',
            isToday && 'text-brand-600'
          )}
        >
          {upperFirst(momentDate.format('ddd'))}
        </div>
        <div
          className={cn(
            'flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-sm font-medium transition-all group-hover:bg-slate-200',
            isPast ? 'text-slate-400' : 'text-slate-800',
            isToday && 'bg-brand text-white shadow'
          )}
        >
          {momentDate.format('D')}
        </div>
      </button>
    )
  }

  RenderDayHeaderContent.propTypes = {
    date: string.isRequired
  }

  const RenderMonthHeaderContent = ({ date }) => {
    const momentDate = moment(date).locale(currentLocale)

    return (
      <div className="flex shrink-0 flex-col items-center font-medium text-slate-600">
        <div className="text-2xs transition-colors">{upperFirst(momentDate.format('ddd'))}</div>
      </div>
    )
  }

  RenderMonthHeaderContent.propTypes = {
    date: string.isRequired
  }

  const RenderDayCellContent = ({ date }) => {
    const momentDate = moment(date).locale(currentLocale)
    const isToday = momentDate.isSame(moment(), 'day')
    const isPast = momentDate.isBefore(moment(), 'day')
    const isFirstDay = momentDate.date() === 1

    return (
      <button
        onClick={() => setViewType('timeGridDay')}
        className={cn('flex shrink-0 items-center gap-0.5 pb-0.5 font-medium', !isToday && 'group')}
      >
        {isFirstDay && (
          <div
            className={cn(
              'text-sm max-md:hidden',
              isPast ? 'text-slate-400' : 'text-slate-800',
              isToday && 'text-brand-600'
            )}
          >
            {upperFirst(momentDate.format('MMM'))}
          </div>
        )}
        <div
          className={cn(
            'flex size-6 shrink-0 items-center justify-center rounded-full bg-white text-sm no-underline transition-all group-hover:bg-slate-200',
            isPast ? 'text-slate-400' : 'text-slate-800',
            isToday && 'bg-brand text-white shadow'
          )}
        >
          {momentDate.format('D')}
        </div>
      </button>
    )
  }

  RenderDayCellContent.propTypes = {
    date: string.isRequired
  }

  const formatTitle = (passedDate) => {
    const momentDate = moment(passedDate.date).locale(currentLocale)
    const currentYear = moment().year()
    const eventYear = momentDate.year()

    return eventYear === currentYear
      ? upperFirst(momentDate.format(isWindowSmall ? "MMM 'YY" : 'MMMM'))
      : upperFirst(momentDate.format(`MMM YYYY`))
  }

  const calendarViews = {
    timeGridDay: {
      titleFormat: (date) => formatTitle(date),
      dayHeaderContent: RenderDayHeaderContent
    },
    timeGrid3Day: {
      type: 'timeGrid',
      duration: { days: 3 },
      dateIncrement: { days: 3 },
      titleFormat: (date) => formatTitle(date),
      dayHeaderContent: RenderDayHeaderContent
    },
    workWeek: {
      type: 'timeGrid',
      duration: { days: 5 },
      titleFormat: (date) => formatTitle(date),
      dayHeaderContent: RenderDayHeaderContent,
      dateAlignment: 'week'
    },
    timeGridWeek: {
      duration: { weeks: 1 },
      titleFormat: (date) => formatTitle(date),
      dayHeaderContent: RenderDayHeaderContent
    }
  }

  const handleViewChange = (newView) => {
    const calendarApi = calendarRef.current.getApi()

    if (newView === 'timeGrid3Day') {
      const yesterday = moment().subtract(1, 'day').startOf('day')
      calendarApi.gotoDate(yesterday.toDate())
    } else if (newView === 'timeGridDay') {
      // For day view, ensure we're showing today
      calendarApi.gotoDate(moment().toDate())
    }
    // For other views, we don't need to adjust the date

    calendarApi.changeView(newView)
    setViewType(newView)
  }

  const customButtons = {
    today: {
      text: translate('period.today'),
      click: () => {
        const calendarApi = calendarRef.current.getApi()
        if (viewType === 'timeGrid3Day') {
          // Set start date to yesterday when clicking Today in 3-day view
          const yesterday = moment().subtract(1, 'day').startOf('day')
          calendarApi.gotoDate(yesterday.toDate())
        } else {
          calendarApi.today()
        }
      }
    }
  }

  return (
    <CalendarWrapper>
      <>
        {isCalendarMounted &&
          createPortal(
            <Dropdown
              onChange={(e) => handleViewChange(e.target.value)}
              value={viewType}
              options={[
                { label: translate('common.day'), value: 'timeGridDay' },
                { label: `3 ${translate('common.day')}`, value: 'timeGrid3Day' },
                { label: translate('common.workWeek'), value: 'workWeek' },
                { label: translate('common.week'), value: 'timeGridWeek' }
              ]}
            />,
            document.querySelector('.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr > *:last-child')
          )}

        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, dayGridPlugin, momentPlugin]}
          views={calendarViews}
          windowResize={() => handleWindowResize()}
          initialView={viewType}
          viewDidMount={() => {
            setIsCalendarMounted(true)
          }}
          customButtons={customButtons}
          headerToolbar={{
            left: 'title',
            right: 'prev,next today'
          }}
          events={events}
          eventClick={onEventClick}
          eventDisplay="block"
          dayMaxEventRows={true}
          navLinks
          allDaySlot={false}
          nowIndicator
          height="100%"
          firstDay={1}
          expandRows
          scrollTime={getDefaultTime()}
          datesSet={handleDateNavigation}
          slotLabelInterval={'01:00:00'}
          slotDuration={'00:15:00'}
          slotLabelFormat={language === 'en' ? 'h A' : 'H:mm'}
        />
      </>
    </CalendarWrapper>
  )
}

CalendarSchedule.propTypes = {
  workDayStart: string,
  events: array,
  onEventClick: func,
  selectedDate: string,
  setSelectedDate: func
}

export default CalendarSchedule
