import React from 'react'
import { rgba, stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled from 'styled-components'

import { greyDark, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'
import { fontRegular } from 'styles/typography'

const Hint = styled.div`
  display: inline-block;
  position: relative;
  margin-left: ${spacing.tiny};
`

const Tooltip = styled.div`
  z-index: 100;
  position: absolute;
  width: 500px;
  margin-left: -250px;
  text-align: left;
  left: 50%;
  bottom: calc(100% + ${stripUnit(spacing.small) * 1.5 + 'px'});
  background-color: ${rgba(greyDark, 0.93)};
  color: ${white};
  padding: ${spacing.small};
  opacity: 0;
  font-weight: ${fontRegular};
  visibility: hidden;
  transform: translateY(2px);
  border-radius: ${borderRadius};
  transition: opacity ${animationTime} ${animationCurve}, transform ${animationTime} ${animationCurve},
    visibility ${animationTime} ${animationCurve};
  list-style-type: disc;

  ul {
    li {
      display: list-item;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid ${rgba(greyDark, 0.93)};
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    backface-visibility: hidden;
  }

  ${Hint}:hover & {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
`

const HintToolTip = ({ hint, isNumber, children }) => (
  <Hint>
    {children}
    <Tooltip>{isNumber && hint ? hint.toLocaleString('default') : hint}</Tooltip>
  </Hint>
)

HintToolTip.propTypes = {
  children: node.isRequired,
  isNumber: bool,
  hint: string
}

export default HintToolTip
