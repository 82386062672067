import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func, node } from 'prop-types'

import { refreshTracker, updatePosition } from 'store/auth/actions'

const ProtectedContext = React.createContext()
export default ProtectedContext

const UnconnectedProtectedProvider = ({ children, updatePosition }) => {
  const coordsUpdatedAt = useSelector((state) => state.auth.coords?.timestamp)
  const locationLoading = useSelector((state) => state.auth.locationLoading)
  const trackingAllowed = useSelector((state) => state.auth.user?.trackingAllowed)

  const [shouldRefreshTracker, setShouldRefreshTracker] = useState(false)

  useEffect(() => {
    const coordsOutdated = !coordsUpdatedAt || Date.now() - coordsUpdatedAt > 10000
    if (locationLoading) {
      setShouldRefreshTracker(false)
    }
    if (shouldRefreshTracker && !locationLoading && trackingAllowed && coordsOutdated) {
      refreshTracker()
    }
  }, [shouldRefreshTracker, locationLoading, trackingAllowed, coordsUpdatedAt])

  useEffect(() => {
    const onFocusRefreshTracker = () => {
      setShouldRefreshTracker(true)
    }
    window.addEventListener('focus', onFocusRefreshTracker)
    return () => {
      window.removeEventListener('focus', onFocusRefreshTracker)
    }
  }, [])

  const value = {
    isLocationLoading: locationLoading
  }

  return <ProtectedContext.Provider value={value}>{children}</ProtectedContext.Provider>
}

UnconnectedProtectedProvider.propTypes = {
  children: node,
  updatePosition: func
}

export const ProtectedProvider = connect(null, { updatePosition, refreshTracker })(UnconnectedProtectedProvider)
