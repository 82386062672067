import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { object, string } from 'prop-types'
import styled from 'styled-components'

import SectorContext from 'context/SectorContext'

import { programFromUrl } from 'store/goFundPrograms/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import ImageAccordion from 'components/accordion/ImageAccordion'
import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import ProgramCard from 'components/ProgramCard'

// import ButtonGroup from 'components/button/ButtonGroup'
import { GO_FUND_PAYMENT_TYPES, SECTOR_LEVELS } from 'utils/constants'

import { goBlue, white } from 'styles/colors'

const Table = styled.table`
  width: 100%;

  td,
  th {
    border: 1px solid ${goBlue};
    padding: 8px;
    font-size: 12px;
    position: relative;
    background-color: ${white};

    @media print {
      padding: 3px;
      font-size: 8px;
    }
  }

  th {
    color: ${goBlue};
    text-align: left;
    -webkit-print-color-adjust: exact;
  }
`

const PrimaryButton = styled(Button)`
  color: ${goBlue};
  border: 1px solid ${goBlue};
  background-color: ${white};
`

const SecondaryButton = styled(Button)`
  color: ${white};
  background-color: ${goBlue};
`

const ActionButtons = ({ currentUrl, program, customer, programId }) => {
  const { actionButtons, activeTarget } = program.programCardInfos
  return (
    <>
      {actionButtons.canPayGoFund && (
        <>
          <FieldsetItem half={actionButtons.canUpdateCartonSales}>
            <SecondaryButton full to={`${currentUrl.pathname}/payment`} offlineDisabled>
              Pay now
            </SecondaryButton>
          </FieldsetItem>
          {actionButtons.canUpdateCartonSales && (
            <FieldsetItem half>
              <SecondaryButton full to={`${currentUrl.pathname}/carton`} offlineDisabled>
                Update carton sales
              </SecondaryButton>
            </FieldsetItem>
          )}
        </>
      )}

      {actionButtons.canAddCartonsTarget ? (
        <FieldsetItem>
          <PrimaryButton to={`${currentUrl.pathname}/target`} full offlineDisabled>
            Add cartons target
          </PrimaryButton>
        </FieldsetItem>
      ) : activeTarget ? (
        <FieldsetItem>
          <PrimaryButton to={`${currentUrl.pathname.concat(`/target/${activeTarget.id}`)}`} full offlineDisabled>
            Update cartons target
          </PrimaryButton>
        </FieldsetItem>
      ) : null}

      {actionButtons.canRequestException && (
        <FieldsetItem>
          <PrimaryButton full to={`${currentUrl.pathname}/exception`} offlineDisabled>
            Request exception
          </PrimaryButton>
        </FieldsetItem>
      )}
    </>
  )
}

ActionButtons.propTypes = {
  currentUrl: object,
  program: object,
  customer: object,
  programId: string
}

const ViewGoProgram = () => {
  const {
    currentSector: { customer }
  } = useContext(SectorContext)
  const currentUrl = useLocation()
  const { programId, sectorType } = useParams()
  const navigate = useNavigate()

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const goFundProgram = useSelector((state) => programFromUrl(state, { programId }))
  const { goFund, targets, programCardInfos } = goFundProgram

  const programExceptions = goFundProgram?.exceptions?.map((exception) => {
    const contact = customer.storeContacts.find((contact) => contact.id === exception.payeeId)
    return { ...exception, payee: contact }
  })
  const programPayments = goFundProgram?.payments?.map((payment) => {
    const contact = customer.storeContacts.find((contact) => contact.id === payment.payeeId)
    return { ...payment, payee: contact }
  })

  /*
    TODOs :
    - Return a 'remaining budget' with the goFund (or maybe the program) which is an amount or money left, this will be calculated on the fly
    - Make sure the activity is in the right Format, we might want to make a different validation for this
    - Return a cartonsToPayout value with the goFund (or maybe the program) which is the quantity of cartons left that can be payed out, this will be calculated on the fly
  */

  const newPayeeSubject = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `Demande de création d’un nouvel employé Extra Hub`
        : 'New Extra Hub user request'
  }, [customer])

  const newPayeeEmailBody = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `S'il vous plaît, ajouter ce bénéficiaire aux contacts du magasin. \nMagasin: ${customer.name} \nTitre du contact: \nPrénom du contact: \nNom du contact: \nCourriel du contact: \nLangage du contact: \nRôle du contact: \n`
        : `Please add this payee to the store contacts. \nStore: ${customer.name} \nContact title: \nContact first name: \nContact last name: \nContact email: \nContact language: \nContact role: \n`
  }, [customer])

  const sortedTargets = targets
    .filter((target) => target.completed)
    .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
  const sortedImages = goFundProgram.images.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
  return (
    <>
      <Container>
        {!window.navigator.onLine && (
          <GlobalAlert warning>
            You are offline, the data you are seeing might not be up to date and you will not be able to submit payments
            or exceptions
          </GlobalAlert>
        )}

        <ProgramCard
          program={goFundProgram}
          isCompleted={goFundProgram.status === 'completed'}
          canRequestException={programCardInfos.actionButtons.canRequestGoFundException}
          canPayNow={programCardInfos.actionButtons.canPayGoFund}
        />
        <div>
          <BasicAccordion title={'Payment history'}>
            {programPayments && programPayments.length ? (
              <Table>
                <tbody>
                  {programPayments.map((payment, index) => (
                    <tr key={payment.id.toString().concat(index)}>
                      <td>
                        {payment.payee
                          ? `${payment.payee.firstname} ${payment.payee.lastname} - ${payment.payee.role}`
                          : 'Unknown contact'}
                        <br />
                        {moment(payment.createdAt).format('MMM D/yy')}
                      </td>
                      <td style={{ textAlign: 'center' }}>${payment.paymentAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState title="No data yet" subtitle={`No instant payments logged for this program`} />
            )}
          </BasicAccordion>
        </div>
        <div>
          {programExceptions && Boolean(programExceptions.length) && (
            <BasicAccordion title={'Exception history'}>
              <Table>
                <tbody>
                  {programExceptions.map((e, index) => (
                    <tr key={e.payee.firstname.concat(e.payee.lastname, index)}>
                      <td>
                        {e.payee.firstname} {e.payee.lastname} - {e.payee.role} <br />
                        {moment(e.createdAt).format('MMM D/yy')}
                      </td>
                      <td style={{ textAlign: 'center' }}>${e.requestedAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </BasicAccordion>
          )}
        </div>

        <div>
          <BasicAccordion title={'Store contacts'}>
            {customer.storeContacts && customer.storeContacts.length ? (
              <Table>
                <tbody>
                  {customer.storeContacts.map((contact, index) => (
                    <tr key={contact.firstname.concat(contact.lastname, index)}>
                      <td>
                        {contact.firstname} {contact.lastname} - {contact.role}
                        <br />
                        {contact.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState
                title="No store contacts yet"
                subtitle={`To add new store contacts, use the 'Request new contact' button below.`}
              />
            )}
          </BasicAccordion>
        </div>

        {sortedTargets.length > 0 && (
          <div>
            <BasicAccordion title={'Target history'}>
              <Table>
                <tbody>
                  <tr>
                    <th>Target amount</th>
                    <th>Actual amount</th>
                    <th>Created at</th>
                  </tr>
                  {sortedTargets.map((target) => (
                    <tr key={target.id}>
                      <td>{target.cartonTargetAmount}</td>
                      <td>{target.cartonActualAmount}</td>
                      <td>{moment(target.createdAt).format('MMM DD YYYY HH:mm')}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </BasicAccordion>
          </div>
        )}

        {sortedImages.length > 0 && (
          <ImageAccordion
            images={sortedImages.map((image) => ({
              ...image,
              text: `${moment(image.createdAt).format('MMMM DD YYYY')} at ${moment(image.createdAt).format('HH:MM')}`
            }))}
          />
        )}

        {goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant && (
          <Fieldset padded>
            <FieldsetItem>
              <PrimaryButton
                full
                href={`mailto:${customer.primaryContact.email}?subject=${newPayeeSubject}&body=${encodeURIComponent(
                  newPayeeEmailBody
                )}`}
                target="_blank"
              >
                Request new contact
              </PrimaryButton>
            </FieldsetItem>

            {ActionButtons && (
              <ActionButtons
                currentUrl={currentUrl}
                program={goFundProgram}
                customer={customer}
                programId={programId}
              />
            )}
          </Fieldset>
        )}
      </Container>
    </>
  )
}

export default ViewGoProgram
