import { addEntities, mergeEntities, setEntities } from 'store/actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.callTasks
  }),
  [setEntities]: (state, action) => {
    if (!action.payload.callTasks) return state
    return Object.entries(action.payload.callTasks).reduce((mergedCallTasks, [id, callTask]) => {
      return {
        ...mergedCallTasks,
        [id]: {
          ...(state[id] || {}),
          ...callTask
        }
      }
    }, {})
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.callTasks) return state
    return Object.entries(action.payload.callTasks).reduce((mergedCallTasks, [id, callTask]) => {
      return {
        ...mergedCallTasks,
        [id]: {
          ...(mergedCallTasks[id] || {}),
          ...callTask
        }
      }
    }, state)
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
