import React, { useContext } from 'react'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import CloseRecommendation from 'components/close/CloseRecommendation'
import SuggestedAmounts from 'components/close/SuggestedAmounts'

const FMCOpportunityCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  const cardProps = {
    title: translate('common.fmc'),
    span
  }

  return (
    <Card {...cardProps}>
      <SuggestedAmounts qty={148} value={1234.2} />
      <div className="flex flex-col gap-2">
        <p className="text-2xs uppercase text-slate-500">Recommendations</p>
        <div className="flex flex-col gap-3">
          <CloseRecommendation
            type="OOS"
            recommendation="You can reduce OOS/Distro gap by 5% that may prevent loss of sales worth $823.43"
          />
          <CloseRecommendation
            type="seasonality"
            recommendation="Expected gain of 9% in offtake driven by seasonality/events (+10% avg. of last 3 years)"
          />
          <CloseRecommendation
            type="surge"
            recommendation="Rapid surge in Pall Mall expected to require +6% additional qty (L4: +8%, L13: +5%)"
          />
        </div>
      </div>
    </Card>
  )
}

FMCOpportunityCard.propTypes = {
  span: object
}

export default FMCOpportunityCard
