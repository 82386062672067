import React from 'react'
import { array, bool, string } from 'prop-types'
import styled from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontRegular } from 'styles/typography'

const Container = styled.div`
  margin-top: ${spacing.medium};

  ${media.breakpoint`
    margin-top: ${spacing.large};
  `};

  &:first-child {
    margin-top: 0;
  }
`

const Header = styled.header`
  display: flex;
  align-items: flex-end; /* Bottom align labels if wrapping occurs */
  font-weight: ${fontRegular};
  text-align: left;
  margin-bottom: ${spacing.medium};
  padding: 0 ${spacing.medium};

  ${media.breakpoint`
    border-top: 0;
  `};
`

const Title = styled(SmallCaps)`
  display: block;
  flex: 2;
  padding-right: ${spacing.medium};

  ${media.breakpoint`
    padding: 0;
  `};
`

const Columns = styled(SmallCaps)`
  display: flex;
  flex: 2;
  flex-grow: ${(props) => (props.wideCols ? 3 : 2)};
  white-space: pre;
  align-items: inherit;
  justify-content: space-between;
  text-align: center;

  ${media.breakpoint`
    padding: 0;
  `};
`

const SectionDivider = ({ title, columns, wideCols, ...rest }) => (
  <Container {...rest}>
    <Header>
      <Title>{title}</Title>
      {columns && (
        <Columns wideCols={wideCols}>
          {columns.map((col, i) => (
            <span key={i}>{col}</span>
          ))}
        </Columns>
      )}
    </Header>
  </Container>
)

SectionDivider.propTypes = {
  title: string,
  columns: array,
  wideCols: bool
}

export default SectionDivider
