import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, number } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerOrders } from 'store/customers/actions'
import { customerOrders } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Button from 'components/button/Button'
import Card from 'components/card'
import SuggestedAmounts from 'components/close/SuggestedAmounts'
import EmptyState from 'components/EmptyState'
import SAQTable from 'components/SAQTable'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const TotalOpportunitiesCard = ({ span, getCustomerOrders }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()
  const [error, setError] = useState()

  const cardProps = {
    title: translate('common.total'),
    span
  }

  const previousOrders = useSelector((state) => customerOrders(state, { customerId }))
  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ORDERS, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomerOrders({ customerId, dataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [customerId])

  const getContent = () => {
    if (isLoading) {
      return <Spinner icon="spinner" />
    }

    if (error) {
      return <EmptyState title="An error occured" subtitle={error} />
    }

    return (
      <>
        <div>
          <Button primary icon="cart" to="./cart">
            Review Cart
          </Button>
        </div>
        <SAQTable title="Last order" orders={previousOrders} />
      </>
    )
  }

  return (
    <Card {...cardProps}>
      <SuggestedAmounts title="Total Suggested Amounts" qty={296} value={2468.4} />

      <div className="flex flex-col gap-2 font-medium">
        <p className="text-2xs uppercase text-slate-500 ">Suggested Amounts by Category</p>
        <div className="flex flex-col gap-3 rounded-md bg-slate-50 p-4 @container">
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">FMC</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">148 units</p>
              <p className="text-right">${(1234.2).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </div>
          </div>
          <hr />
          <div className="flex items-center gap-4">
            <p className="w-full text-slate-700">VAPE</p>
            <div className="flex shrink-0 flex-col gap-2 @[18rem]:flex-row @[18rem]:gap-4 ">
              <p className="text-right">148 units</p>
              <p className="text-right">${(1234.2).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col items-center gap-4">{getContent()}</div>

      <div className="gap0.5 flex flex-col rounded-md bg-slate-50 p-4 text-xs font-medium text-slate-700">
        <p>
          AWR13: <span className="font-semibold text-sky-600">27</span>
        </p>
        <p>
          WTD: <span className="font-semibold text-sky-600">0</span>
        </p>
        <p>
          <span className="font-semibold text-sky-600">40</span> cartons left before threshold
        </p>
        <p>
          <span className="font-semibold text-sky-600">13</span> cartons left to SAQ before threshold
        </p>
      </div>
    </Card>
  )
}

TotalOpportunitiesCard.propTypes = {
  span: number,
  getCustomerOrders: func
}

export default connect(null, { getCustomerOrders })(TotalOpportunitiesCard)
