import omit from 'lodash/omit'
import moment from 'moment'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { addMessageToCustomerCall } from 'store/customerCalls/actions'
import { addCustomerMessage } from 'store/customers/actions'
import outbox from 'store/outbox'
import { customerFetchSchema } from 'store/schema'

import { OUTBOX_KEYS, SYNC_ACTION_KEYS } from 'utils/constants'

import * as api from './endpoints'

export const queueOfflineMessageInsert = async (messageDetails) => {
  const tempMessage = {
    ...messageDetails,
    id: messageDetails.id || `temp-id-${Date.now()}`,
    createdAt: moment().toISOString()
  }
  const messageOutbox = (await outbox.getItem(OUTBOX_KEYS.UPSERTED_MESSAGES)) || {}
  await outbox.setItem(OUTBOX_KEYS.UPSERTED_MESSAGES, { ...messageOutbox, [tempMessage.id]: tempMessage })
  await registerSync(SYNC_ACTION_KEYS.SUBMIT_PENDING_MESSAGES)
  return tempMessage
}

export const saveCallMessage = createAction('Save Call Message', (message) => async (dispatch) => {
  try {
    if (message?.text?.length > 500) message.text = message.text.slice(0, 499)
    const { data } = await api.saveCallMessage(omit(message, ['customerCallId']))
    if (message.customerCallId) {
      await dispatch(addMessageToCustomerCall(message))
    }
    return data
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const createMessage = createAction('Create customer message', (details) => async (dispatch) => {
  let message
  if (window.navigator.onLine) {
    const { data } = await api.createCustomerMessage(details)
    message = data.message
  } else {
    message = await queueOfflineMessageInsert(details)
  }

  dispatch(addCustomerMessage(message))
})

export const updateMessage = createAction('Update message - RESERVE', (details) => async (dispatch) => {
  const { data } = await api.updateCustomerMessage(details)
  const { entities } = normalize(data, customerFetchSchema)
  dispatch(mergeEntities(entities))
})

export const fetchMessages = createAction('Fetch customer messages', (params) => async (dispatch) => {
  const { data } = await api.fetchMyMessages(params)
  const { entities } = normalize(data, { customers: [customerFetchSchema.customer] })
  dispatch(mergeEntities(entities))
})
