import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { array, bool, func, object } from 'prop-types'
import { Form, getFormValues, reduxForm, submit } from 'redux-form'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { autofillSurveyTargets } from 'store/customers/selectors'
import { setDataFetch } from 'store/dataFetches/actions'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { submitSurveyAutoComplete } from 'store/surveys/actions'
import { autofillInitialValues, surveyFromUrl } from 'store/surveys/selectors'
import { fetchTerritory } from 'store/territories/actions'

import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import LoadingCard from 'components/LoadingCard'

import { DATA_UPDATE_STATUS, DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

import RenderSurveyQuestions from './RenderSurveyQuestions'

const AutofillSurvey = ({
  handleSubmit,
  submitting,
  submitSurveyAutoComplete,
  connectedFetchTerritory,
  dirty,
  change,
  dispatch,
  ...props
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { surveyId, sectorId: territoryId } = useParams()

  const [loading, setLoading] = useState(true)
  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY, { territoryId })
  useEffect(() => {
    setLoading(true)
    connectedFetchTerritory(territoryId, dataKey).then(() => {
      if (isMounted.current) setLoading(false)
    })
  }, [territoryId])

  const territoryLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const isLoading = loading || territoryLoading

  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const { survey, questions } = useSelector((state) => surveyFromUrl(state, { surveyId }))
  const targets = useSelector((state) => autofillSurveyTargets(state, { surveyId, location }))

  const goBack = () => {
    navigate('..')
  }

  const formatAndSubmitSurvey = async ({ startedAt, ...values }) => {
    const newAnswers = questions.reduce((acc, question) => {
      const answer = values[question.id]

      const controlNotSatisfiedAnswer = { text: 'N/A - Hidden' }

      const isHiddenControlledQuestion =
        question.controlQuestionId && values[question.controlQuestionId] !== question.controlAnswerId

      const baseAnswer = { questionId: question.id }
      if (isHiddenControlledQuestion) {
        return acc.concat({ ...baseAnswer, answer: controlNotSatisfiedAnswer })
      }
      if (isEmpty(answer)) return acc
      if (['text', 'number'].includes(question.type)) {
        return acc.concat({ ...baseAnswer, answer: { text: answer } })
      }
      if (['dropdown', 'multi'].includes(question.type)) {
        return acc.concat({ ...baseAnswer, options: [].concat(answer).map((id) => ({ id })) })
      }
      if (question.type === 'images') {
        return acc.concat({ ...baseAnswer, answer: { imgs: answer } })
      }
      console.dir({ error: 'INVALID QUESTION TYPE', question, answer })
      return acc
    }, [])

    // mark the data that needs refresh
    dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OLD }))

    return submitSurveyAutoComplete(
      {
        surveyId,
        customerIds: location.state.targets,
        startedAt,
        finishedAt: new Date().toISOString(),
        answers: newAnswers
      },
      goBack
    )
  }

  const { addAction } = useContext(ActionHeaderContext)
  useEffect(() => {
    addAction({
      getSurveyName: () => survey?.name || 'Survey',
      onSubmit: () => dispatch(submit('autofillSurvey')),
      isDisabled: () => submitting || isMobileViewOnly,
      isSubmitting: () => submitting
    })
  }, [survey?.name, submitting, isMobileViewOnly])

  if (!survey && !isLoading) navigate('..')
  if (isLoading) return <LoadingCard dataKey={dataKey} />

  return (
    <Form onSubmit={handleSubmit(formatAndSubmitSurvey)}>
      <Container padded>
        <Fieldset>
          <FieldsetItem>
            <GlobalAlert warning>{targets.length} surveys will be completed with the submitted answers</GlobalAlert>
          </FieldsetItem>
          <RenderSurveyQuestions questions={questions} change={change} {...props} />
        </Fieldset>
      </Container>
    </Form>
  )
}

AutofillSurvey.propTypes = {
  submitSurveyAutoComplete: func.isRequired,
  handleSubmit: func.isRequired,
  connectedFetchTerritory: func,
  submitting: bool,
  survey: object,
  questions: array,
  location: object,
  dirty: bool,
  change: func,
  targets: array,
  isMobileViewOnly: bool,
  dispatch: func
}

const mapActionCreators = {
  submitSurveyAutoComplete,
  connectedFetchTerritory: fetchTerritory
}

const selector = getFormValues('autofillSurvey')
const mapStateToProps = (state, props) => {
  return {
    employeeId: state.auth.user.id,
    formValues: selector(state),
    initialValues: autofillInitialValues(state, props),
    onSubmitSuccess: () => props.navigate('..')
  }
}

const AutofillSurveyForm = reduxForm({ form: 'autofillSurvey' })(AutofillSurvey)
const AutofillForm = connect(mapStateToProps, mapActionCreators)(AutofillSurveyForm)

const Autofill = () => {
  const navigate = useNavigate()

  return <AutofillForm navigate={navigate} />
}

export default Autofill
