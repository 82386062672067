import React from 'react'
import { string } from 'prop-types'

import ViewHeader from 'components/ViewHeader'

const ExtraHubHeader = ({ parentHeader, header }) => {
  return <ViewHeader parentHeader={parentHeader} header={header} />
}

ExtraHubHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default ExtraHubHeader
