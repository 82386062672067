import styled from 'styled-components'

import { containerMaxWidth } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

export default styled.div`
  font-size: ${tinyFontSize};
  padding: 0 ${spacing.medium};
  width: 100%;
  margin: 0 auto;
  ${media.breakpoint`
    max-width: ${containerMaxWidth};
    padding-left: 0;
    padding-right: 0;
  `};
`
