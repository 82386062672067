import { rgba, stripUnit } from 'polished'
import { createGlobalStyle, css } from 'styled-components'

import { backgroundColor, black } from './colors'
import Normalize from './normalize'
import { globalTypography } from './typography'
// Animation
export const animationTime = '0.3s'
export const animationTimeFast = '0.15s'
export const animationCurve = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)'

export const popoverContentAnimationClasses =
  'data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade'

// Border Radius
export const borderRadius = '6px'

// Misc
export const headerHeight = '56px'
export const tabBarHeight = '56px'
export const tabNavHeight = '48px'
export const containerMaxWidth = '650px'
export const customerCardHeight = '180px'
export const combinedNavHeight = stripUnit(headerHeight) + stripUnit(tabNavHeight)
export const customerCardCommentHeight = '48px'

// Inputs
export const inputHeight = '46px'
export const inputHeightSmall = '34px'

// Square
export const square = (size) => css`
  width: ${size};
  height: ${size};
`

// Box shadow
export const boxShadow = `box-shadow: 0 2px 5px 0 ${rgba(black, 0.08)};`
export const reverseBoxShadow = `box-shadow: 0 -2px 6px 0 ${rgba(black, 0.04)};`

// Cover
export function cover(position) {
  return `
    position: ${position};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
}

// Styles

export default createGlobalStyle`
  ${Normalize};
  ${globalTypography};

  body {
    background-color: ${backgroundColor};
    min-height: 100vh;
    
    &.lock {
      overflow: hidden;
    }
  }
`
