import React from 'react'
import { NavLink } from 'react-router-dom'
import { rgba } from 'polished'
import { array, bool, string } from 'prop-types'
import styled, { css } from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { borderColor, greyDark, greyLight, primaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, boxShadow, containerMaxWidth, headerHeight, tabNavHeight } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.nav`
  position: ${(props) => (props.alt ? 'static' : 'sticky')};
  height: ${tabNavHeight};
  top: ${headerHeight};
  background-color: ${(props) => (props.alt ? 'transparent' : white)};
  z-index: 400;
  overflow-x: auto;
  white-space: nowrap;
  ${boxShadow};

  @media print {
    display: none;
  }

  ${(props) =>
    props.alt &&
    css`
      box-shadow: inset 0 -1px 0 0 ${borderColor};
    `};
`

const Inner = styled.div`
  margin: 0 auto;
  text-align: center;

  ${media.breakpoint`
    max-width: ${containerMaxWidth};
  `};
`

const Tab = styled(SmallCaps)`
  display: inline-flex;
  align-items: center;
  position: relative;
  text-align: center;
  height: ${tabNavHeight};
  padding: 0 ${spacing.small};
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};
  letter-spacing: 0.6px;

  &:first-child {
    margin-left: ${spacing.tiny};
  }

  &:last-child {
    margin-right: ${spacing.tiny};
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: ${spacing.small};
    right: ${spacing.small};
    height: 2px;
    background-color: transparent;
    transition: background-color ${animationTime} ${animationCurve};
  }
}
&:hover,
 &:focus,
 &.${'active'} {
   color: ${greyDark};
 }

 &.${'active'}:after {
   background-color: ${(props) => props.color || primaryColor};
 }
`

const Badge = styled.span`
  font-size: 0.9em;
  display: inline-block;
  margin-left: ${spacing.tiny};
  background-color: ${rgba(borderColor, 0.5)};
  padding: 0 7px;
  border-radius: ${spacing.medium};
`

const getElement = (props) => {
  if (props.href) return 'a'
  if (props.to) return NavLink
  return 'button'
}

const TabNav = ({ links, alt, color }) => (
  <Container alt={alt && 'true'}>
    <Inner>
      {links.map(({ label, badgeCount, ...linkProps }, i) => {
        const asElement = getElement(linkProps)
        return (
          <Tab
            key={i}
            color={color}
            {...linkProps}
            as={asElement}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {label}
            {Boolean(badgeCount) && <Badge>{badgeCount}</Badge>}
          </Tab>
        )
      })}
    </Inner>
  </Container>
)

TabNav.propTypes = {
  links: array,
  alt: bool,
  color: string
}

export default TabNav
