import React, { useContext, useState } from 'react'
import { bool } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import Button from 'components/button/Button'

import { SECTOR_LEVELS } from 'utils/constants'

import ProductTypePicker from '../ProductTypePicker'

import SectorBreadcrumbs from './SectorBreadcrumbs'
import SectorModal from './SectorModal'
import StoreInfoModal from './StoreInfoModal'

const Container = tw.div`flex h-[76px] flex-shrink-0 px-5 space-x-4 max-w-full items-center justify-between border-b border-slate-950/10 bg-white shadow-md shadow-slate-900/5 overflow-x-auto`

const Separator = tw.div`w-1 h-1 bg-slate-950/10 rounded-full`

const SectorPicker = ({ showType }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const { currentSector, selectedLevel, modalOpen, setModalOpen } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  return (
    <Container>
      <div className="flex h-9 items-center space-x-3">
        <Button primary icon="manage-search" onClick={() => setModalOpen(true)}>
          {translate('app.changeScope')}
        </Button>

        <SectorModal onHandleClose={() => setModalOpen(false)} open={modalOpen} onOpenChange={setModalOpen} />

        <SectorBreadcrumbs sector={currentSector} />
        {selectedLevel === SECTOR_LEVELS.CUSTOMER && currentSector[selectedLevel] && (
          <>
            <StoreInfoModal
              open={infoModalOpen}
              onOpenChange={setInfoModalOpen}
              customer={currentSector[selectedLevel]}
            />
            <Separator />
            <Button icon="info-outline" secondary onClick={() => setInfoModalOpen(true)}>
              {translate('app.storeInfo')}
            </Button>
          </>
        )}
      </div>
      <div className="flex space-x-3">{showType && <ProductTypePicker showLabel={false} />}</div>
    </Container>
  )
}

SectorPicker.propTypes = {
  showType: bool
}

export default SectorPicker
