import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import countBy from 'lodash/countBy'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { rgba } from 'polished'
import { func } from 'prop-types'
import styled from 'styled-components'

import { getCustomer } from 'store/customers/actions'
import { customerOpenSurveys, customerSurveys } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Icon from 'components/Icon'
import Input from 'components/Input'
import LoadingCard from 'components/LoadingCard'
import SurveyCompletionFilters from 'components/SurveyCompletionFilters'
import SurveyList from 'components/SurveyList'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

import { black, white } from 'styles/colors'
import { square } from 'styles/global'

const ClearButton = styled.button`
  display: flex;
  border-radius: 50%;
  background-color: ${rgba(black, 0.4)};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  ${square('22px')};
`

const CustomerSurveys = ({ connectedGetCustomer }) => {
  const [completionFilters, setCompletionFilters] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredSurveys, setFilteredSurveys] = useState([])
  const { sectorId: customerId } = useParams()

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    connectedGetCustomer(customerId, dataKey)
  }, [customerId])

  const surveys = useSelector((state) => customerSurveys(state, { customerId }))
  const openSurveys = useSelector((state) => customerOpenSurveys(state, { customerId }))

  const { notStarted = 0, inProgress = 0, complete = 0, clarificationNeeded = 0 } = countBy(surveys, 'completion')
  const debouncedFilteredUpdate = debounce(setFilteredSurveys, 300)

  useEffect(() => {
    if (completionFilters.length || searchQuery?.length) {
      const surveysFiltered = surveys.filter((s) => {
        const hasValidCompletionStatus = completionFilters.length ? completionFilters.includes(s.completion) : true
        if (!hasValidCompletionStatus) return false
        if (!searchQuery) return true
        const searchQueryMatchesName = s.survey.name.toLowerCase().includes(searchQuery.toLowerCase())
        const dateQuery = moment(searchQuery)
        if (dateQuery.isValid() && dateQuery.get('year') === 2001)
          dateQuery.set('year', moment(s.survey.endDate).get('year')) // valid dates without years default to 2001 for some reason
        const searchQueryMatchesDate = dateQuery.isValid() && dateQuery.isSame(s.survey.endDate, 'day')
        return searchQueryMatchesName || searchQueryMatchesDate
      })
      debouncedFilteredUpdate(surveysFiltered)
    } else {
      setFilteredSurveys(surveys)
    }
  }, [surveys, completionFilters, searchQuery, debouncedFilteredUpdate])

  if (isLoading) return <LoadingCard dataKey={dataKey} />

  return (
    <>
      <div className="mt-4">
        <Input
          placeholder="Search by name or end date..."
          onChange={({ target }) => setSearchQuery(target.value)}
          value={searchQuery}
          addon={
            searchQuery ? (
              <ClearButton onClick={() => setSearchQuery('')}>
                <Icon icon="close" small />
              </ClearButton>
            ) : null
          }
        />
      </div>
      <SurveyCompletionFilters
        total={surveys.length}
        complete={complete}
        notStarted={notStarted}
        inProgress={inProgress}
        clarificationNeeded={clarificationNeeded}
        updateCompletionFilters={setCompletionFilters}
      />
      <SurveyList surveys={filteredSurveys} linkPath="." openSurveys={openSurveys} isCustomerView />
    </>
  )
}

CustomerSurveys.propTypes = {
  connectedGetCustomer: func
}

export default connect(null, {
  connectedGetCustomer: getCustomer
})(CustomerSurveys)
