import { addEntities, mergeEntities, setEntities } from 'store/actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.alerts
  }),
  [setEntities]: (state, action) => {
    if (!action.payload.alerts) return state
    return Object.entries(action.payload.alerts).reduce((mergedAlerts, [id, alert]) => {
      return {
        ...mergedAlerts,
        [id]: {
          ...(state[id] || {}),
          ...alert
        }
      }
    }, {})
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.alerts) return state
    return Object.entries(action.payload.alerts).reduce((mergedAlerts, [id, alert]) => {
      return {
        ...mergedAlerts,
        [id]: {
          ...(mergedAlerts[id] || {}),
          ...alert
        }
      }
    }, state)
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
