import React, { useContext, useState } from 'react'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

import { formatPercent } from 'utils/formatters'
import { cn } from 'utils/styling'

const GeographyContactDetails = ({ contact }) => {
  const { translate } = useContext(LangContext)
  const [modalOpen, setModalOpen] = useState(false)

  const contactInfos = {
    [translate('common.name')]: contact.name,
    [translate('app.userEngaged')]: contact.engaged ? 'YES' : 'NO',
    [translate('amplify.extra.activityCompleted')]: contact.completedActivities
      .toString()
      .concat(` / ${contact.totalActivities} (${formatPercent(contact.activityCompletion, { decimalPlaces: 0 })})`)
  }

  return (
    <>
      <Modal
        title={translate('amplify.extra.activityDetails')}
        open={modalOpen}
        onOpenChange={setModalOpen}
        onHandeClose={() => setModalOpen(false)}
      >
        <div className="flex flex-col gap-4">
          <div className="space-y-2">
            {Object.entries(contactInfos).map(([rowTitle, rowValue], i) => (
              <div key={rowTitle + i} className="flex w-full justify-between text-sm">
                <p className="text-slate-700">{rowTitle}</p>
                <p>{rowValue}</p>
              </div>
            ))}
          </div>

          <hr />
          <p className="text-xs uppercase text-slate-700">Activities</p>
          <div className="divide-y-slate-900/10 divide-y rounded-md ring-1 ring-slate-900/10">
            {contact.activities.map((activity) => (
              <div key={activity.id} className="flex w-full justify-between  px-3 py-2.5 ">
                <p>{activity.activityName}</p>
                <div
                  className={cn(
                    'flex size-6 items-center justify-center rounded-full',
                    activity.isCompleted ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'
                  )}
                >
                  {activity.isCompleted ? <Icon icon="checkmark" small /> : <Icon icon="close" small />}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Button onClick={() => setModalOpen(true)} small secondary>
        {translate('common.details')}
      </Button>
    </>
  )
}

GeographyContactDetails.propTypes = { contact: object.isRequired }

export default GeographyContactDetails
