import React from 'react'
import { string } from 'prop-types'
import tw from 'twin.macro'

const LegendWrapper = tw.div`flex items-center space-x-2`

const ColorDot = tw.div`h-2 w-2 rounded-full`

const Text = tw.span`text-slate-900`

const DataLegend = ({ color, name }) => {
  return (
    <LegendWrapper>
      <ColorDot style={{ backgroundColor: color }} />
      <Text>{name}</Text>
    </LegendWrapper>
  )
}

DataLegend.propTypes = {
  color: string,
  name: string
}

export default DataLegend
