import React, { useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { bool, func, node } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { Modal } from 'components/Modal'

import SectorBrowser from './SectorBrowser'

const SectorModal = ({ onHandleClose, open, onOpenChange, trigger }) => {
  const { translate } = useContext(LangContext)
  const { currentSector } = useContext(SectorContext)

  const [searchResults, setSearchResults] = useState([])
  const [sectorType, setSectorType] = useState('')
  const [queryString, setQueryString] = useState('')

  const clearFields = () => {
    setSectorType('')
    setQueryString('')
    setSearchResults([])
  }

  return (
    <Modal
      title={translate('app.changeScope')}
      onHandleClose={() => {
        clearFields()
        onHandleClose()
      }}
      trigger={trigger}
      open={open}
      onOpenChange={() => {
        clearFields()
        onOpenChange()
      }}
      size="large"
      preventClosing={isEmpty(currentSector)}
    >
      <SectorBrowser
        sectorType={sectorType}
        setSectorType={setSectorType}
        queryString={queryString}
        setQueryString={setQueryString}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        clearFields={clearFields}
        onHandleClose={onHandleClose}
      />
    </Modal>
  )
}

SectorModal.propTypes = {
  onHandleClose: func.isRequired,
  onOpenChange: func.isRequired,
  open: bool,
  trigger: node
}

export default SectorModal
