import { SELLOUT_STATE_KEYS } from 'utils/paceStateKeys'
export const mergeMain = (state, { payload: { id, activeProductType, channel, dataType, unitOfMeasure, result } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      [SELLOUT_STATE_KEYS.MAIN({ activeProductType, channel, dataType, unitOfMeasure })]: result
    }
  })
}

export const mergeGeography = (state, { payload: { id, result, uniqByCols, ...rest } }) => {
  const stateKey = SELLOUT_STATE_KEYS.GEOGRAPHY(rest)
  const existingEntity = state[id] || {}

  return Object.assign({}, state, {
    [id]: {
      ...existingEntity,
      [stateKey]: result
    }
  })
}
