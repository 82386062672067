import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import { createSelector } from 'reselect'

export const allTasks = (state) => Object.values(omit(state.callTasks || {}, '_persist'))
const callFromProps = (state, props) => props.call

export const tasksByOutletSubtype = createSelector(allTasks, (tasks) => {
  if (!tasks?.length) return null
  return groupBy(tasks, 'outletSubtype')
})
export const tasksForCall = createSelector(allTasks, callFromProps, (tasks, call) => {
  if (!tasks || !call) return []
  return tasks.filter(({ outletSubtype }) => outletSubtype === call.customer?.outletSubtype)
})
