import { createAction } from 'redux-actions'

import { registerSync } from 'store/api'
import { addPricingCaptureToCustomer } from 'store/customers/actions'
import outbox from 'store/outbox'

import * as api from './endpoints'

const queueOfflinePricingCapture = async (pricingCapture) => {
  const pricingCaptureOutbox = (await outbox.getItem('pricing-captures')) || {}
  const captureId = `${pricingCapture.customerId}_${pricingCapture.upc}`
  await outbox.setItem('pricing-captures', { ...pricingCaptureOutbox, [captureId]: pricingCapture })
  return registerSync('submit-pending-pricing-captures')
}

export const submitPricingCapture = createAction('Submit pricing capture', (newCapture) => async (dispatch) => {
  let newPricingCapture
  const captureData = { ...newCapture, updatedAt: new Date().toISOString() }
  dispatch(addPricingCaptureToCustomer(captureData))
  try {
    if (window.navigator.onLine) {
      const { data } = await api.submitPricingCapture(captureData)
      newPricingCapture = data.pricingCapture
    } else {
      await queueOfflinePricingCapture(captureData)
      newPricingCapture = captureData
    }
    dispatch(addPricingCaptureToCustomer(newPricingCapture))
  } catch (err) {
    if (err.response && err.message !== 'Network Error') {
      throw err
    }
    await queueOfflinePricingCapture(captureData)
    dispatch(addPricingCaptureToCustomer(captureData))
  }
})
