import queryString from 'query-string'

import { api } from 'store/api'

const getRoot = (sectorType, sectorId) => `/extra-hub-incentives/${sectorType}/${sectorId}`
export const getTrending = ({ sectorType, sectorId }) => api.get(`${getRoot(sectorType, sectorId)}/trending`)

export const getEngagement = ({ sectorType, sectorId }) => api.get(`${getRoot(sectorType, sectorId)}/engagement`)

export const getGeography = ({ sectorType, sectorId, geography, activity, offset, sortBy, sortDirection }) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/geographies?${queryString.stringify({
      geography,
      activity,
      offset,
      sortBy,
      sortDirection
    })}`
  )

export const getFilters = ({ sectorType, sectorId }) => api.get(`${getRoot(sectorType, sectorId)}/filters`)

export const getActivity = ({ sectorType, sectorId }) => api.get(`${getRoot(sectorType, sectorId)}/activity`)
