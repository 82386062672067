import React from 'react'
import { Outlet } from 'react-router'
import retailerAuth from 'layouts/authWrappers/retailerAuth'

const Retailer = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default retailerAuth(Retailer)
