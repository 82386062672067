/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import JsBarcode from 'jsbarcode'
import { rgba } from 'polished'
import { func } from 'prop-types'
import styled, { css } from 'styled-components'

import LangContext from 'context/LangContext'

import { submitPricingCapture } from 'store/pricings/actions'
import * as pricingSelector from 'store/pricings/selectors'

import Button from 'components/button/Button'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Label from 'components/Label'
import SegmentControl from 'components/SegmentControl'

import { formatUpc } from 'utils/helpers'

import { black, borderColor, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, boxShadow, cover, square } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H2 } from 'styles/typography'

const Container = styled.div`
  ${cover('fixed')};
  background-color: ${rgba(black, 0.65)};
  z-index: 500;
`

const Table = styled.div`
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
`

const Cell = styled.div`
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  padding: ${spacing.large};
  padding-bottom: ${spacing.xxLarge};
`

const Inner = styled.div`
  position: relative;
  padding: ${spacing.large};
  background-color: ${white};
  border-radius: ${borderRadius};
  margin: 0 auto;
  ${boxShadow};

  ${(props) =>
    props.capturesRemaining >= 1 &&
    css`
      &:after {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 6px;
        right: 6px;
        bottom: -6px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -1;
        ${boxShadow};
      }
    `}

  ${(props) =>
    props.capturesRemaining >= 2 &&
    css`
      &:before {
        content: '';
        position: absolute;
        background-color: ${white};
        left: 12px;
        right: 12px;
        bottom: -12px;
        height: 12px;
        border-radius: ${borderRadius};
        z-index: -2;
        ${boxShadow};
      }
    `}

  ${media.breakpoint`
    max-width: 380px;
  `};
`

const CodeWrap = styled.div`
  height: 100px;
  margin-bottom: ${spacing.medium};
  margin: 0 auto;
  text-align: center;
`

const Code = styled.img`
  height: 100%;
`

const Name = styled(H2)`
  margin-bottom: ${spacing.medium};
  text-align: center;
`

const NavLinks = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${spacing.large} 0;
`
const Skip = styled(Link)`
  display: block;
  text-align: center;
  color: ${rgba(white, 0.6)};
  transition: color ${animationTime} ${animationCurve};

  &:hover {
    color: ${white};
  }
`

const Close = styled(Link)`
  position: absolute;
  bottom: 100%;
  right: 0;
  ${square('48px')};
  border-radius: 50%;
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(black, 0.3)};
  margin-bottom: ${spacing.medium};
  transition: background-color ${animationTime} ${animationCurve};

  ${media.breakpoint`
    position: fixed;
    top: ${spacing.large};
    right: ${spacing.large};
    margin: 0;
  `};

  &:hover {
    background-color: ${rgba(black, 0.5)};
  }
`

const Details = styled.div`
  display: flex;
  padding: ${spacing.large} 0;
  border-top: 1px solid ${borderColor};
`

const DetailsColumn = styled.div`
  width: 50%;

  &:first-child {
    padding-right: ${spacing.small};
  }

  &:last-child {
    padding-left: ${spacing.small};
  }
`

const normalizePrice = (val) => {
  return val.replace(/[^\d|.]/g, '')
}

const PricingCard = ({ submitPricingCapture }) => {
  const { translate } = useContext(LangContext)
  const { customerId, upc } = useParams()
  const navigate = useNavigate()

  const pricing = useSelector((state) => pricingSelector.pricingFromUrl(state, { customerId, upc }))
  const nextCapture = useSelector((state) => pricingSelector.nextCaptureUpc(state, { customerId, upc }))
  const previousCapture = useSelector((state) => pricingSelector.previousCaptureUpc(state, { customerId, upc }))
  const capturesRemaining = useSelector((state) => pricingSelector.capturesRemaining(state, { customerId, upc }))

  const [price, setPrice] = useState('')
  const [outOfDistribution, setOutOfDistribution] = useState(false)
  const [validUpc, setValidUpc] = useState(true)
  const nextCaptureSlug = nextCapture ? `${nextCapture}` : '/thank-you'
  const previousCaptureSlug = previousCapture ? `${previousCapture}` : ''
  const pricingName = [pricing.shortBrand, pricing.packSize, pricing.packCount].filter(Boolean).join(' ')

  const submitCapture = () => {
    const body = {
      customerId: +customerId,
      upc: pricing.upc,
      price: +price || null,
      volume: null,
      outOfDistribution,
      competitorUpc: pricing.competitorUpc
    }
    submitPricingCapture(body)
    navigate(`../${nextCaptureSlug}`, { replace: true })
  }

  const renderBarcode = (newUpc) => {
    const { format, upcString } = formatUpc(newUpc)
    JsBarcode('#barcode', upcString, {
      format,
      valid: (isValid) => setValidUpc(isValid)
    })
  }

  useEffect(() => {
    if (pricing) {
      renderBarcode(pricing.upc)
      setPrice(pricing.retailerCapture?.price || '')
      setOutOfDistribution(false)
    }
  }, [pricing?.upc])

  useEffect(() => {
    if (outOfDistribution) {
      setPrice('')
    }
  }, [outOfDistribution])

  if (!pricing) return <Navigate to=".." />

  return (
    <Container>
      <Table>
        <Cell>
          <Inner capturesRemaining={capturesRemaining}>
            <Close to={'..'}>
              <Icon icon="close" />
            </Close>
            <GlobalAlert warning>
              <p>
                {translate('retailer.pricingCard.instruction1')}
                <br />
                {translate('retailer.pricingCard.instruction2')}
              </p>
            </GlobalAlert>
            <CodeWrap>{validUpc && <Code id="barcode" />}</CodeWrap>
            <Name className="notranslate" translate="no">
              {pricingName}
            </Name>
            <Label>{translate('retailer.pricingCard.inDistribution')}</Label>
            <SegmentControl
              small
              onChange={(e) => setOutOfDistribution(!outOfDistribution)}
              value={String(outOfDistribution)}
              options={[
                { label: translate('common.yes'), value: 'false' },
                { label: translate('common.no'), value: 'true' }
              ]}
            />

            <Details>
              <DetailsColumn>
                <Input
                  dollar
                  disabled={outOfDistribution}
                  small
                  label={translate('common.priceBeforeTax')}
                  input={{
                    value: price,
                    onChange: ({ target }) => setPrice(normalizePrice(target.value))
                  }}
                />
              </DetailsColumn>
            </Details>

            <Button onClick={submitCapture} disabled={!price && !outOfDistribution} primary full>
              {translate('common.submit')}
            </Button>
            <NavLinks>
              <Skip to={`../${previousCaptureSlug}`}>{translate('common.previous')}</Skip>
              <Skip to={`../${nextCaptureSlug}`}>{translate('common.skip')}</Skip>
            </NavLinks>
          </Inner>
        </Cell>
      </Table>
    </Container>
  )
}

PricingCard.propTypes = {
  submitPricingCapture: func.isRequired
}

export default connect(null, { submitPricingCapture })(PricingCard)

export const AllSubmittedThankYou = () => {
  const { translate } = useContext(LangContext)
  const navigate = useNavigate()

  return (
    <Container>
      <Table>
        <Cell>
          <Inner>
            <Close to={'..'}>
              <Icon icon="close" />
            </Close>
            <Name>{translate('pricingCard.thankYou')}</Name>
            <Button onClick={() => navigate('..')} primary full>
              {translate('common.done')}
            </Button>
          </Inner>
        </Cell>
      </Table>
    </Container>
  )
}
