import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'
import { stripUnit } from 'polished'
import { array, bool, string } from 'prop-types'
import styled from 'styled-components'

import DistrictManagerContext from 'context/DistrictManagerContext'
import LangContext from 'context/LangContext'

import BasicAccordion from 'components/accordion/BasicAccordion'
import EmptyState from 'components/EmptyState'
import FilteredDropdown from 'components/FilteredDropdown'
import Logo from 'components/Logo'
import MobileHeader from 'components/MobileHeader'
import PayoutGrid from 'components/payouts/PayoutGrid'
import PayoutKPIs from 'components/payouts/PayoutKPIs'
import PayoutOverview from 'components/payouts/PayoutOverview'
import PayoutSection from 'components/payouts/PayoutSection'
import SectionDivider from 'components/SectionDivider'

import { borderColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

import PayoutsLogo from 'static/images/extraordinary.png'

const Title = styled(H3)`
  margin-bottom: ${stripUnit(spacing.tiny) * 0.5 + 'px'};
  white-space: pre-line;
  margin-bottom: ${spacing.medium};
`

const SectionWrapper = styled.div`
  padding: ${spacing.medium};
  border: 2px solid ${borderColor};
  margin-bottom: ${spacing.large};
`

const PayoutKpiValueset = ({ description, values }) => {
  if (!description && !values) return null
  return (
    <SectionWrapper>
      {description && <Title>{description}</Title>}
      {values && <PayoutKPIs data={values} />}
    </SectionWrapper>
  )
}

PayoutKpiValueset.propTypes = {
  description: string,
  values: array
}

const YearPayouts = ({ year, payouts, isFirst }) => {
  return (
    <>
      <SectionDivider title={year} />
      <div>
        {payouts.map(
          (
            { periodName, position, kpis, lastUpdated, totalPayout, totalPayoutWithRsmChallenges, notes, paymentDate },
            idx
          ) => (
            <BasicAccordion key={idx} title={periodName}>
              <PayoutSection paddedMobile>
                <PayoutOverview
                  territory={position}
                  lastUpdated={new Date(lastUpdated).toDateString()}
                  totalPayout={totalPayout}
                  totalPayoutWithRsmChallenges={totalPayoutWithRsmChallenges}
                  paymentDate={paymentDate?.split('T')[0] || '-'}
                />
              </PayoutSection>
              <PayoutSection title="KPIs" muted>
                {kpis.map(({ name, payout, description, values, grid, valuesets }, subIdx) => {
                  if (!description && !values && !grid && !valuesets)
                    return <BasicAccordion key={`${idx}-${subIdx}`} title={name} addOn={payout} isSubRow />
                  return (
                    <BasicAccordion key={`${idx}-${subIdx}`} title={name} addOn={payout} isSubRow>
                      <PayoutKpiValueset description={description} values={values} />
                      {valuesets && valuesets.map((valueset, idx) => <PayoutKpiValueset {...valueset} key={idx} />)}
                      <PayoutGrid data={grid} />
                    </BasicAccordion>
                  )
                })}
              </PayoutSection>
              {Boolean(notes?.length) && (
                <PayoutSection title="Notes" muted paddedMobile>
                  {notes.map(({ title, content }, noteIdx) => (
                    <div key={noteIdx} className="mt-2 space-y-1">
                      <h5 className="font-medium">{title}</h5>
                      <p className="text-sm text-slate-800">{content}</p>
                    </div>
                  ))}
                </PayoutSection>
              )}
            </BasicAccordion>
          )
        )}
      </div>
    </>
  )
}

YearPayouts.propTypes = {
  year: string,
  payouts: array,
  isFirst: bool
}

const Payouts = () => {
  const { translate } = useContext(LangContext)

  useEffect(() => {
    document.title = 'Payouts'
  }, [])

  const user = useSelector(({ auth }) => auth.user)

  const { setMountTime, employeePayoutOptions, loading } = useContext(DistrictManagerContext)
  const [selectedEmployee, setSelectedEmployee] = useState(user?.id)
  const [visiblePayouts, setVisiblePayouts] = useState(null)

  useLayoutEffect(() => {
    setMountTime(Date.now()) // refetch payouts info on mount
  }, [setMountTime])

  useLayoutEffect(() => {
    if (employeePayoutOptions?.length) {
      setSelectedEmployee(employeePayoutOptions[0].value)
    }
  }, [employeePayoutOptions])

  useEffect(() => {
    if (selectedEmployee) {
      const { payouts } = employeePayoutOptions?.find(({ value }) => value === +selectedEmployee) || {}
      if (payouts) {
        const yearGroupedPayouts = groupBy(payouts, 'year')
        setVisiblePayouts(Object.entries(yearGroupedPayouts).sort(([a], [b]) => +b - +a))
      }
    }
  }, [user, selectedEmployee, employeePayoutOptions])

  return (
    <div className="mx-auto h-full w-full max-w-[650px] overflow-y-auto px-2 py-6">
      <MobileHeader isNavOnly />
      <div className="max-md:pt-16">
        <Logo src={PayoutsLogo} />
      </div>
      {Boolean(employeePayoutOptions?.length) && (
        <div className="mb-4 px-4 py-0 sm:p-0">
          <FilteredDropdown
            secondary
            searchPlaceholder={translate('app.placeholders.searchEmployees')}
            options={employeePayoutOptions}
            value={selectedEmployee}
            onChange={(value) => setSelectedEmployee(value)}
          />
        </div>
      )}
      {visiblePayouts?.length ? (
        visiblePayouts.map(([year, payouts], idx) => {
          return <YearPayouts year={year} payouts={payouts} isFirst={!idx} key={`${year}-${idx}-${selectedEmployee}`} />
        })
      ) : loading ? (
        <EmptyState isLoading title="Fetching..." subtitle="Just a moment while we fetch the payout information" />
      ) : (
        <EmptyState title="No data yet" subtitle={`No payout data available`} />
      )}
    </div>
  )
}

export default Payouts
