import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { max } from 'lodash'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { submitGoFundProgramCartons } from 'store/goFundPrograms/actions'
import { programFromUrl } from 'store/goFundPrograms/selectors'
import { goFundCartonsInitialValues } from 'store/goFunds/selectors'

import Button from 'components/button/Button'
import Container from 'components/Container'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import FileInput from 'components/FileInput'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import GoFundProgramCard from 'components/ProgramCard'

import { SECTOR_LEVELS } from 'utils/constants'

import Card from './Card'

const GoProgramCarton = ({ submitGoFundProgramCartons }) => {
  const { programId, sectorId: customerId, sectorType } = useParams()
  const formRef = useRef()
  const { addAction } = useContext(ActionHeaderContext)
  const navigate = useNavigate()

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const [error, setError] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const program = useSelector((state) => programFromUrl(state, { programId }))
  const initialValues = useSelector((state) => goFundCartonsInitialValues(state, { programId, customerId }))
  const newImages = useSelector((state) => state.auth.newImages)
  console.log(`/${sectorType}/${customerId}/funds/${programId}`)

  useEffect(() => {
    addAction({
      renderActions: () => (
        <>
          <Button
            key={1}
            secondary
            to={`/${sectorType}/${customerId}/actions/funds/${programId}`}
            disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            key={2}
            primary
            onClick={() => formRef.current.handleSubmit()}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            >
            Save
          </Button>
        </>
      )
    })
  }, [formRef, isSubmitting])

  const validateNewCartons = useCallback((val) => {
    const { targetVolume, ctnsBooked } = program

    const newAmount = +val

    const maxCartons = max([targetVolume - ctnsBooked, 0])
    if (!newAmount) return 'Please provide the amount of carton sold.'
    if (newAmount < 0) return 'Please provide a valid amount of carton sold.'
    if (newAmount > maxCartons) return `The total amount of cartons sold cannot exceed ${targetVolume}.`
  }, [])

  const submitCartons = async (values) => {
    setError()
    if (!newImages.length) {
      setError('You need to provide photos to track carton sales.')
      return
    }
    setIsSubmitting(true)
    const toSubmit = { ...values, newImages }
    submitGoFundProgramCartons(toSubmit)
      .then(() => navigate(-1))
      .catch((e) => {
        console.log(e)
        setError(e.message)
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Formik innerRef={formRef} onSubmit={submitCartons} initialValues={initialValues}>
      {({ values }) => {
        const toRemove = Number(values.cartonSales) || 0
        const totalAfterRemoving = program.targetVolume - (program.ctnsBooked || 0) - toRemove

        return (
          <Form>
            <Container>
              <GoFundProgramCard program={program} />
              <Card>
                <Fieldset padded>
                  <FieldsetItem half>
                    <Field
                      component={Input}
                      name="cartonSales"
                      label="Carton sales"
                      placeholder="0"
                      normalize={(val) => val.replace(/\D/g, '')}
                      validate={validateNewCartons}
                      disabled={isSubmitting}
                    />
                  </FieldsetItem>
                  <FieldsetItem half>
                    <Input label="Carton left before target" disabled placeholder={totalAfterRemoving} />
                  </FieldsetItem>

                  <FieldsetItem>
                    <FileInput name="images" existingImages={[]} loading={isSubmitting} />
                  </FieldsetItem>
                  {error && (
                    <FieldsetItem>
                      <GlobalAlert>{error}</GlobalAlert>
                    </FieldsetItem>
                  )}
                </Fieldset>
              </Card>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

GoProgramCarton.propTypes = {
  submitGoFundProgramCartons: func.isRequired
}

const mapActionCreators = {
  submitGoFundProgramCartons
}

export default connect(null, mapActionCreators)(GoProgramCarton)
