import React, { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'
import debounce from 'lodash/debounce'
import { mix, rgba, stripUnit } from 'polished'
import { bool, func, number, object, string } from 'prop-types'
import styled from 'styled-components'

import { getCustomerSuggestions } from 'store/customers/actions'

import { black, borderColor, grey, offWhite, red, secondaryColor } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { smallFontSize } from 'styles/typography'

const StyledSuggest = styled(AsyncSelect)`
  position: relative;

  .multi-select__control {
    border-radius: ${borderRadius};
    border-color: ${borderColor};
    transition: border-color ${animationTime} ${animationCurve};

    &:hover {
      border-color: ${mix(0.1, black, borderColor)};
    }

    &--is-focused {
      border-color: ${secondaryColor};

      &:hover {
        border-color: ${secondaryColor};
      }
    }
  }

  .multi-select__multi-value {
    color: ${grey};
    background-color: ${offWhite};
    font-size: ${smallFontSize};
    border-radius: ${stripUnit(borderRadius) - 2 + 'px'};
  }

  .multi-select__multi-value__remove {
    cursor: pointer;
    color: ${grey};
    transition: all ${animationTime} ${animationCurve};

    &:hover {
      background-color: ${rgba(red, 0.15)};
      color: ${red};
    }
  }
`

const OptionLabel = ({ id, name, address }) => {
  return (
    <span>
      {name} <small>({id})</small>
      {address && (
        <>
          <br />
          <small>{address.line1 || address.line_1}</small>
        </>
      )}
    </span>
  )
}

OptionLabel.propTypes = {
  id: number,
  name: string,
  address: string
}

const ErpSuggestAsync = ({ input = {}, isMulti = true, suggestCustomers, ...props }) => {
  const currentTerritoryId = useSelector((state) => state.auth.currentTerritoryId)

  const loadOptions = useMemo(() => {
    return debounce((input, callback) => {
      suggestCustomers({ query: input }).then(({ data }) => {
        callback(data.customers)
      })
    }, 300)
  }, [])

  const [searchInput, setInput] = useState('')
  return (
    <StyledSuggest
      {...props}
      currentTerritoryId={currentTerritoryId}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 12 })
      }}
      inputId="erp-suggest"
      inputValue={searchInput}
      onInputChange={(value, action) => {
        if (['input-change', 'menu-close'].includes(action.action)) setInput(value)
      }}
      isClearable={Boolean(input.value)}
      className="erp-select"
      classNamePrefix="erp-select"
      getOptionLabel={OptionLabel}
      getOptionValue={({ id }) => id}
      placeholder="Search name, ERP or banner..."
      menuPlacement="top"
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      blurInputOnSelect={!isMulti}
      name={input.name}
      onBlur={() => input.onBlur?.()}
      onChange={(val) => {
        input.onChange(val)
      }}
      value={input.value || []}
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      menuPosition="fixed"
    />
  )
}

ErpSuggestAsync.propTypes = {
  input: object,
  isMulti: bool,
  suggestCustomers: func
}

export default connect(null, {
  suggestCustomers: getCustomerSuggestions
})(ErpSuggestAsync)
