import { api } from 'store/api'

export const submitNewProgram = (program) => api.post('/go-fund-programs', program)

export const submitUpdatedProgram = (program) => api.put(`/go-fund-programs/${program.id}`, program)

export const submitGoFundProgramCartons = (values) => api.post(`/go-fund-programs/${values.programId}/cartons`, values)
export const submitProgramDeletion = (programId) => api.delete(`/go-fund-programs/${programId}`)

export const submitProgramPayment = (paymentSubmitted) =>
  api.post(`/go-fund-programs/${paymentSubmitted.payment.programId}/payment`, paymentSubmitted)

export const submitProgramTarget = (targetSubmitted) =>
  api.post(`/go-fund-programs/${targetSubmitted.goFundProgramId}/target`, targetSubmitted)

export const submitProgramTargetUpdate = (targetUpdated) =>
  api.put(`/go-fund-programs/${targetUpdated.goFundProgramId}/target/${targetUpdated.target.id}`, targetUpdated)
