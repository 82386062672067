import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import { bool, func, number } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { saveUserMileage } from 'store/mileage/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'

const Spacer = styled.div`
  height: 175px;
`
const DEFAULT_VALUES = {
  mileage: '',
  date: moment()
}

const AddMileage = ({ visibleScheduleScreen, setVisibleScheduleScreen, suggestedKms, saveUserMileage }) => {
  const { translate } = useContext(LangContext)
  const [initialValues, setInitialValues] = useState(DEFAULT_VALUES)

  const submitMileage = async (values, actions) => {
    const { mileage, date } = values
    await saveUserMileage({
      dateDriven: moment(date).toISOString(),
      kms: +mileage,
      suggestedKms: +suggestedKms,
      type: 'EOD'
    })
    setVisibleScheduleScreen()
    actions.resetForm()
  }

  useEffect(() => {
    setInitialValues({
      ...DEFAULT_VALUES,
      mileage: suggestedKms
    })
  }, [suggestedKms])

  const handleCancel = (...callbacks) => {
    callbacks.forEach((callback) => callback())
    setVisibleScheduleScreen()
  }

  return (
    <Formik onSubmit={submitMileage} initialValues={initialValues} enableReinitialize>
      {({ submitForm, resetForm, setFieldValue, isSubmitting }) => (
        <ActionSheet
          title={translate('app.addMileage')}
          visible={visibleScheduleScreen}
          action={
            <button type="button" onClick={() => handleCancel(resetForm)}>
              {translate('common.cancel')}
            </button>
          }
          onOverlayClick={() => handleCancel(resetForm)}
        >
          <Form>
            <div>
              <Fieldset>
                <Spacer />
                <FieldsetItem>
                  <Field name="date">
                    {({ field }) => (
                      <DatePicker
                        label={translate('common.date')}
                        placeholder={translate('app.selectDate')}
                        openDirection="down"
                        value={field.value}
                        onChange={(val) => setFieldValue('date', val)}
                        isOutsideRange={() => false}
                      />
                    )}
                  </Field>
                </FieldsetItem>

                <Spacer />
                <FieldsetItem>
                  <Field name="mileage">
                    {({ field }) => (
                      <Input label={translate('common.mileage')} placeholder="0" type="number" min={0} {...field} />
                    )}
                  </Field>
                </FieldsetItem>

                <Spacer />
                <FieldsetItem>
                  <Button full primary onClick={submitForm} isLoading={isSubmitting} disabled={isSubmitting}>
                    {translate('common.save')}
                  </Button>
                </FieldsetItem>
              </Fieldset>
            </div>
          </Form>
        </ActionSheet>
      )}
    </Formik>
  )
}

AddMileage.propTypes = {
  visibleScheduleScreen: bool,
  setVisibleScheduleScreen: func,
  suggestedKms: number,
  saveUserMileage: func.isRequired
}

export default connect(null, { saveUserMileage })(AddMileage)
