import React, { createContext, useContext, useEffect, useState } from 'react'
import { node } from 'prop-types'

import SectorContext from 'context/SectorContext'

const InventoryCaptureContext = createContext()
export default InventoryCaptureContext
/**
 * HOW TO USE:
 *
 * In a component where you want to use actions in the header, import addAction from this context
 * Call the method with an object containing the name of an action as well as it implementation
 *
 * addAction({
 *  doSomething: () => alert("Hello World!")
 * })
 *
 * In the HeaderContent.js file, go to the section you want to bind an action to.
 * headerActions is a method that gets passed the context actions. You can extract your action from there.
 *
 * orders: {
 *      headerActions: ({ doSomething }) => [
 *          <Button onClick={doSomething} />
 *      ]
 * }
 */
export const InventoryCaptureProvider = ({ children }) => {
  const { currentProductType: productType } = useContext(SectorContext)

  const [timeframe, setTimeframe] = useState('1')
  const [vapeCategory, setVapeCategory] = useState('all')

  useEffect(() => {
    if (productType === 'fmc') setVapeCategory('all')
  }, [productType])

  const value = {
    timeframe,
    setTimeframe,
    vapeCategory,
    setVapeCategory
  }

  return <InventoryCaptureContext.Provider value={value}>{children}</InventoryCaptureContext.Provider>
}

InventoryCaptureProvider.propTypes = {
  children: node.isRequired
}
