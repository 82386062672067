import React, { useEffect, useState } from 'react'
import { bool, func, node, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, grey, greyDark, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontBold } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};

  @media print {
    border-top: 0;
  }

  ${(props) =>
    props.isSubrow &&
    `
    margin-right: -${spacing.medium};

    &:last-child {
      margin-bottom: -${spacing.medium};
    }
  `};

  ${(props) =>
    !props.isSubrow &&
    media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:last-child {
    border-bottom: ${(props) => (props.isSubrow ? '0' : '1')}px solid ${borderColor};
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium};
  @media print {
    padding: ${spacing.small};
  }
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  font-weight: ${fontBold};
  color: ${greyDark};
`
const Title = styled.small`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  font-weight: ${fontBold};
  color: ${greyDark};

  @media print {
    font-size: 12px;
  }
`
const AddOn = styled.small`
  font-weight: ${fontBold};
  color: ${grey};
`

const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  padding: ${spacing.medium};
  padding-top: 0;
  margin-top: -${spacing.small};

  @media print {
    padding: ${spacing.small};
    padding-top: 0;
  }
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};

  @media print {
    display: none;
  }
`

const BasicAccordion = ({
  title,
  expandedTitle,
  children,
  isSubrow,
  details = null,
  preExpand = false,
  addOn,
  expandForPrint,
  onExpand
}) => {
  const [expanded, setExpanded] = useState(preExpand)

  useEffect(() => {
    if (onExpand) onExpand(expanded)
  }, [expanded])

  const handleExpanded = () => {
    setExpanded(children && !expanded)
  }

  const visuallyExpanded = expanded || expandForPrint
  return (
    <Container expanded={visuallyExpanded && children} isSubrow={isSubrow}>
      <Header onClick={handleExpanded}>
        <span style={{ flex: 1 }}>
          <TitleWrap>
            <Title>{visuallyExpanded && expandedTitle ? expandedTitle : title}</Title>
            {addOn && <AddOn>{addOn}</AddOn>}
          </TitleWrap>
          {details}
        </span>
        <Chevron icon="down-chevron" accent={Boolean(children)} expanded={visuallyExpanded} white={!children} />
      </Header>
      <Content expanded={visuallyExpanded}>{visuallyExpanded && children}</Content>
    </Container>
  )
}

BasicAccordion.propTypes = {
  children: node,
  title: string.isRequired,
  expandedTitle: string,
  isSubrow: bool,
  details: node,
  preExpand: bool,
  addOn: node,
  expandForPrint: bool,
  onExpand: func
}

export default BasicAccordion
