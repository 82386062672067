import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { bool } from 'prop-types'
import styled from 'styled-components'

import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import ErpSuggestAsync from 'components/ErpSuggestAsync'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Label from 'components/Label'
import SegmentControl from 'components/SegmentControl'

import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'utils/constants'

import * as spacing from 'styles/spacing'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const getStartHourOptions = (startDate, allowPastTime, mustBePast) => {
  const now = moment()

  if (mustBePast) {
    if (now.isSame(startDate, 'date')) {
      return HOUR_OPTIONS.filter((opt) => +opt.value <= now.hour())
    }
    return HOUR_OPTIONS
  }

  if (allowPastTime) return HOUR_OPTIONS

  if (now.isSame(startDate, 'date')) {
    return HOUR_OPTIONS.filter((opt) => +opt.value >= now.hour())
  }

  return HOUR_OPTIONS
}

const isSameDaySameHour = (time) => {
  const now = moment()
  return now.isSame(time, 'date') && now.isSame(time, 'hour')
}

const getMinuteOptions = (startDate, startHour, allowPastTime, mustBePast) => {
  const now = moment()
  const setStartTime = moment(startDate).set('hour', +startHour)

  if (mustBePast) {
    if (isSameDaySameHour(setStartTime)) {
      return MINUTE_OPTIONS.filter((opt) => +opt.value < now.minute())
    }
    return MINUTE_OPTIONS
  }

  if (allowPastTime) {
    return MINUTE_OPTIONS
  }

  if (isSameDaySameHour(setStartTime)) {
    MINUTE_OPTIONS.filter((opt) => opt.value >= now.minute())
  }

  return MINUTE_OPTIONS
}

function getEndHourOptions(startDate, startHour, startHourOptions, mustBePast) {
  const now = moment()
  const baseHour = startHour || startHourOptions[0].value

  if (mustBePast) {
    if (now.isSame(startDate, 'date')) {
      return HOUR_OPTIONS.filter((opt) => +opt.value >= +baseHour && +opt.value <= now.hour())
    }
    return HOUR_OPTIONS
  }

  return HOUR_OPTIONS.filter((opt) => +opt.value >= +baseHour)
}

function getEndMinuteOptions(
  startDate,
  startHour,
  startHourOptions,
  startMinute,
  startMinuteOptions,
  endHour,
  endHourOptions,
  allowPastTime,
  mustBePast
) {
  const now = moment()
  const setEndTime = moment(startDate).set('hour', +endHour)

  const baseStartHour = +startHour || startHourOptions[0]
  const baseEndHour = +endHour || endHourOptions[0]
  const allMinuteOptions = baseStartHour === baseEndHour ? startMinuteOptions : MINUTE_OPTIONS
  const baseStartMinute = startMinute || allMinuteOptions[0].value

  if (mustBePast) {
    if (isSameDaySameHour(setEndTime)) {
      return MINUTE_OPTIONS.filter((opt) => +opt.value <= now.minute())
    }
    return MINUTE_OPTIONS
  }

  if (allowPastTime) return MINUTE_OPTIONS

  if (baseStartHour === baseEndHour) {
    return allMinuteOptions.filter((opt) => +opt.value >= +baseStartMinute)
  }

  return allMinuteOptions
}

const CallForm = ({ canEdit, allowPastTime, mustBePast }) => {
  const [startHourOptions, setStartHourOptions] = useState(HOUR_OPTIONS)
  const [startMinuteOptions, setStartMinutesOptions] = useState(MINUTE_OPTIONS)
  const [endHourOptions, setEndHourOptions] = useState(HOUR_OPTIONS)
  const [endMinuteOptions, setEndMinuteOptions] = useState(MINUTE_OPTIONS)

  const { values, setFieldValue } = useFormikContext()

  const employee = useSelector((state) => state.auth.employee)

  // Adjust startHour
  useEffect(() => {
    const { startDate, startHour } = values || {}
    const hourOptions = getStartHourOptions(startDate, allowPastTime, mustBePast)
    setStartHourOptions(hourOptions)
    if (moment(startDate).isSame(moment(), 'date') && !allowPastTime && +hourOptions[0].value > +startHour) {
      setFieldValue('startHour', hourOptions[0].value)
    }
  }, [values?.startDate])

  // Adjust startMinute and endHour
  useEffect(() => {
    const { startDate, startHour, startMinute, endHour } = values || {}
    const newStartMinuteOptions = getMinuteOptions(startDate, startHour, allowPastTime, mustBePast)
    setStartMinutesOptions(newStartMinuteOptions)

    if (+newStartMinuteOptions[0].value > +startMinute) {
      setFieldValue('startMinute', newStartMinuteOptions[0].value)
    }
    const newEndHourOptions = getEndHourOptions(startDate, startHour, startHourOptions, mustBePast)
    setEndHourOptions(newEndHourOptions)
    if (+newEndHourOptions[0].value > +endHour) {
      setFieldValue('endHour', newEndHourOptions[0].value)
    }
  }, [values?.startDate, values?.startHour])

  // Adjust endMinute
  useEffect(() => {
    const { startDate, startHour, startMinute, endHour, endMinute } = values || {}
    const newEndMinuteOptions = getEndMinuteOptions(
      startDate,
      startHour,
      startHourOptions,
      startMinute,
      startMinuteOptions,
      endHour,
      endHourOptions,
      allowPastTime,
      mustBePast
    )
    setEndMinuteOptions(newEndMinuteOptions)
    if (+startHour === +endHour && +startMinute >= +endMinute) {
      // set 1 minute later, or the same if we are minute 59
      setFieldValue('endMinute', newEndMinuteOptions[1]?.value || newEndMinuteOptions[0].value)
    }
  }, [values?.startHour, values?.startMinute, values?.endHour])

  const adjustedCycleEndDate = useMemo(() => {
    if (employee?.currentCycle?.endTime)
      return employee?.currentCycle &&
        employee?.currentCycle?.endTime &&
        moment(employee.currentCycle.endTime).diff(moment(), 'days') < 7
        ? moment(employee.currentCycle.endTime).add(7, 'days')
        : moment(employee.currentCycle.endTime)
  }, [employee?.currentCycle])

  const dateIsOutsideOfRange = (day) => {
    const isOutOfCycle =
      employee?.currentCycle && employee?.currentCycle.endTime && moment(day).isAfter(adjustedCycleEndDate)
    const pastTimeAllowed = allowPastTime ? day.isAfter(moment(), 'day') : day.isBefore(moment(), 'day')
    return pastTimeAllowed || isOutOfCycle
  }

  return (
    <Fieldset>
      <FieldsetItem>
        <Field name="startDate">
          {({ field }) => (
            <DatePicker
              label="Select a date"
              disabled={!canEdit}
              value={field.value}
              onChange={(val) => setFieldValue('startDate', val)}
              isOutsideRange={dateIsOutsideOfRange}
            />
          )}
        </Field>
      </FieldsetItem>
      <FieldsetItem half>
        <Label>Start time</Label>
        <Row>
          <Field name="startHour">
            {({ field }) => <Dropdown noIcon options={startHourOptions} disabled={!canEdit} {...field} />}
          </Field>
          <Divider>:</Divider>
          <Field name="startMinute">
            {({ field }) => <Dropdown noIcon options={startMinuteOptions} disabled={!canEdit} {...field} />}
          </Field>
        </Row>
      </FieldsetItem>
      <FieldsetItem half>
        <Label>End time</Label>
        <Row>
          <Field name="endHour">
            {({ field }) => <Dropdown noIcon options={endHourOptions} disabled={!canEdit} {...field} />}
          </Field>
          <Divider>:</Divider>
          <Field name="endMinute">
            {({ field }) => <Dropdown noIcon options={endMinuteOptions} disabled={!canEdit} {...field} />}
          </Field>
        </Row>
      </FieldsetItem>
      <FieldsetItem>
        <Label>Store</Label>
        <Field name="customer" validate={(value) => (!value || isEmpty(value)) && 'Required'}>
          {({ field }) => (
            <ErpSuggestAsync
              input={{
                onChange: (e) => setFieldValue('customer', e),
                value: field.value
              }}
              isMulti={false}
              label="Store"
              isDisabled={!canEdit}
            />
          )}
        </Field>
      </FieldsetItem>
      <FieldsetItem>
        <Field name="type">
          {({ field }) => (
            <SegmentControl
              label="Type"
              options={[
                { label: 'Phone', value: 'telemarketing' },
                { label: 'In-person', value: 'in-person' }
              ]}
              disabled={!canEdit}
              {...field}
            />
          )}
        </Field>
      </FieldsetItem>
    </Fieldset>
  )
}

CallForm.propTypes = {
  allowPastTime: bool,
  canEdit: bool,
  mustBePast: bool
}

export default CallForm
