import React from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from 'components/Sidebar'

const MainLayout = () => {
  return (
    <div className="flex h-[100dvh] bg-slate-100 text-sm">
      <Sidebar />
      <Outlet />
    </div>
  )
}

export default MainLayout
