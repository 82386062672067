import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { func } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'

const StyledSelect = tw.select`cursor-pointer appearance-none rounded-l-md border-0 bg-transparent bg-none pl-3 text-sm text-slate-900 ring-0 transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`

const Select = ({ onChange }) => {
  const {
    user: { isDM, isSalesRep, isTM }
  } = useSelector((state) => state.auth)

  return (
    <div className="relative">
      <StyledSelect id="sector" name="sector" onChange={({ target }) => onChange(target.value)}>
        <option value="">All</option>
        <option value="customer">Customer</option>
        <option value="territory">Territory</option>
        {!isSalesRep && !isTM && <option value="district">District</option>}
        {!isDM && !isSalesRep && !isTM && <option value="region">Region</option>}
      </StyledSelect>
      <div className="pointer-events-none absolute bottom-0 right-2 top-0 flex items-center text-slate-600">
        <Icon icon="down-chevron" compact />
      </div>
    </div>
  )
}

Select.propTypes = {
  onChange: func.isRequired
}

const StyledInput = tw.input`w-full rounded-r-md pr-10 truncate border-0 bg-transparent text-sm text-slate-900 transition placeholder:text-slate-400 focus-visible:bg-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`

const Input = ({ setQueryString }) => {
  const { translate } = useContext(LangContext)

  return (
    <div className="peer relative grow">
      <StyledInput
        type="text"
        name="sector-query"
        id="sector-query"
        className="peer"
        placeholder={translate('app.placeholders.groupedSearch')}
        onChange={({ target }) => setQueryString(target.value)}
      />
      <div className="pointer-events-none absolute bottom-0 right-3 top-0 flex items-center text-slate-400 transition peer-focus:-translate-y-0.5 peer-focus:translate-x-2 peer-focus:rotate-[45deg] peer-focus:text-slate-500">
        <Icon icon="search" />
      </div>
    </div>
  )
}

Input.propTypes = {
  setQueryString: func.isRequired
}

export const GroupedSearch = ({ setSectorType, setQueryString }) => {
  return (
    <div className="flex rounded-md bg-slate-100 pt-px">
      <div className="flex items-center">
        <Select onChange={setSectorType} />
        <div className="z-10 h-4 w-px bg-slate-900/10" />
      </div>
      <Input setQueryString={setQueryString} />
    </div>
  )
}

GroupedSearch.propTypes = {
  setSectorType: func.isRequired,
  setQueryString: func.isRequired
}
