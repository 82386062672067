import { createAction } from 'redux-actions'

export const addSellinOrderCompletionToDistrict = createAction('Add Sell-in Order Completion to district')
export const addSellinGeographyToDistrict = createAction('Add Sell-in Geography to district')
export const addSellinRunRateToDistrict = createAction('Add Sell-in Run Rate to district')
export const addDistroTrendedDataToDistrict = createAction('Add Distro Trended Data to district')
export const addDistroBrandDataToDistrict = createAction('Add Distro Brand Data to district')
export const addDistroMaterialDataToDistrict = createAction('Add Distro Material Data to district')
export const addDistroGeographyDataToDistrict = createAction('Add Distro Geography Data to district')
export const addDistroSummaryDataToDistrict = createAction('Add Distro Summary Data to district')
export const addPlanningSellinValuesToDistrict = createAction('Add Planning Sell-in Values to district')
export const addPlanningSelloutValuesToDistrict = createAction('Add Planning Sell-out Values to district')
export const addPlanningHealthCheckValuesToDistrict = createAction('Add Planning Health Check Values to district')
export const addPlanningPriceCheckValuesToDistrict = createAction('Add Planning Price Check Values to district')
export const addPlanningEngagementValuesToDistrict = createAction('Add Planning Engagement Values to district')
export const addPlanningCycleCampaignValuesToDistrict = createAction('Add Cycle Campaign Values to district')

export const addAmplifySelloutValuesToDistrict = createAction('Add Amplify Sell-out Values to district')
export const addAmplifySelloutGeographyValuesToDistrict = createAction(
  'Add Amplify Sell-out Geography Values to district'
)
export const addAmplifyInventoryOosTrendingValuesToDistrict = createAction(
  'Add Amplify Inventory OOS Trending Values to district'
)
export const addAmplifyInventoryOosBrandsValuesToDistrict = createAction(
  'Add Amplify Inventory OOS Brand Values to district'
)
export const addAmplifyInventoryOosMaterialValuesToDistrict = createAction(
  'Add Amplify Inventory OOS Material Values to district'
)
export const addAmplifyInventoryOosGeographyValuesToDistrict = createAction(
  'Add Amplify Inventory OOS Geography Values to district'
)
export const addAmplifyInventoryOosFiltersValuesToDistrict = createAction(
  'Add Amplify Inventory OOS Filters Values to district'
)

export const addAmplifyExtraHubTrendingValuesToDistrict = createAction(
  'Add Amplify Extra Hub Trending Values to District'
)
export const addAmplifyExtraHubEngagementValuesToDistrict = createAction(
  'Add Amplify Extra Hub Engagement Values to District'
)
export const addAmplifyExtraHubGeographyValuesToDistrict = createAction(
  'Add Amplify Extra Hub Geography Values to District'
)
export const addAmplifyExtraHubFiltersValuesToDistrict = createAction(
  'Add Amplify Extra Hub Filters Values to District'
)
export const addAmplifyExtraHubActivityValuesToDistrict = createAction(
  'Add Amplify Extra Hub Activity Values to District'
)
export const addAmplifyPriceCaptureComplianceFootprintValuesToDistrict = createAction(
  'Add Amplify Price Capture Compliance Footprint Values to District'
)
export const addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict = createAction(
  'Add Amplify Price Capture Compliance Brand Completion Values to District'
)
export const addAmplifyPriceCaptureComplianceFiltersValuesToDistrict = createAction(
  'Add Amplify Price Capture Compliance Filters Values to District'
)
export const addAmplifyPriceCaptureComplianceGeographyValuesToDistrict = createAction(
  'Add Amplify Price Capture Compliance Geography Values to District'
)
export const addAmplifyPriceComplianceStrategyComplianceValuesToDistrict = createAction(
  'Add Amplify Price Compliance Strategy Compliance Values to District'
)
export const addAmplifyPriceComplianceEdlpComplianceValuesToDistrict = createAction(
  'Add Amplify Price Compliance Edlp Compliance Values to District'
)
export const addAmplifyPriceComplianceGeographyComplianceValuesToDistrict = createAction(
  'Add Amplify Price Compliance Geography Compliance Values to District'
)
export const addAmplifyPriceComplianceGeographyFiltersValuesToDistrict = createAction(
  'Add Amplify Price Compliance Geography Filters Values to District'
)

export const addCycleCampaignsToDistrict = createAction('Add Cycle Campaign to District')
