import React, { useContext } from 'react'
import { bool, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { CardContent, CardHeading, Data, DataVariation, HiddenIndicator, NotTargeted } from '.'

export const WeightedDistroData = ({ data, timeRange, hidden }) => {
  const { translate } = useContext(LangContext)
  return (
    <CardContent>
      <CardHeading>{translate('app.distro')}</CardHeading>
      {hidden && <HiddenIndicator />}

      <div
        className={`${
          hidden && 'pointer-events-none select-none blur'
        } flex h-full w-full flex-col items-center justify-center`}
      >
        <Data
          data={data.distro}
          title="Weighted distro"
          additionalData={{
            type: 'variation',
            data: {
              variation: {
                amount: data.distro.variation,
                time: timeRange
              }
            }
          }}
        />

        <div className="flex w-full grow items-center">
          <div className="flex w-full justify-between">
            <div className="flex grow flex-col items-center space-y-0.5">
              <span className="text-md font-medium text-slate-500">{translate('app.stores')}</span>
              {data.stores.isTracked ? (
                <div className="flex flex-col items-center gap-1">
                  <span className="text-2xl font-medium ">{data.stores.value.toFixed(2)}%</span>
                  <DataVariation variation={data.stores.variation} timeRange={timeRange} />
                </div>
              ) : (
                <NotTargeted />
              )}
            </div>
          </div>
          <div className="h-full w-px bg-brand-900/10" />
          <div className="w-full">
            <div className="flex grow flex-col items-center space-y-0.5">
              <span className="text-md font-medium text-slate-500">{translate('app.acronyms.SKUs')}</span>
              {data.skus.isTracked ? (
                <div className="flex flex-col items-center gap-1">
                  <span className="text-2xl font-medium ">{data.skus.value.toFixed(2)}%</span>
                  <DataVariation variation={data.skus.variation} timeRange={timeRange} />
                </div>
              ) : (
                <NotTargeted />
              )}
            </div>
          </div>
        </div>
      </div>
    </CardContent>
  )
}

WeightedDistroData.propTypes = {
  data: {
    distro: {
      value: number,
      variation: number,
      isTracked: bool
    },
    stores: {
      value: number,
      variation: number,
      isTracked: bool
    },
    skus: {
      value: number,
      variation: number,
      isTracked: bool
    }
  },
  hidden: bool,
  timeRange: string
}

WeightedDistroData.defaultProps = {
  data: {
    distro: {
      value: 0,
      variation: 0,
      isTracked: true
    },
    stores: {
      value: 0,
      isTracked: true
    },
    skus: {
      value: 0,
      isTracked: true
    }
  }
}
