import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTerritory } from 'store/territories/actions'
import { territorySurveyCustomers } from 'store/territories/selectors'

import LoadingCard from 'components/LoadingCard'
import SurveyCustomers from 'components/TerritorySurveyCustomers'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const TerritorySurveyCustomers = ({ connectedFetchTerritory }) => {
  const { sectorId: territoryId, surveyId, sectorType } = useParams()
  const { addAction } = useContext(ActionHeaderContext)

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY, { territoryId })
  useEffect(() => {
    setLoading(true)
    connectedFetchTerritory(territoryId, dataKey).then(() => {
      if (isMounted.current) setLoading(false)
    })
  }, [territoryId])
  const territoryLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const isLoading = loading || territoryLoading

  const employee = useSelector((state) => state.auth.user)
  const { survey, ...rest } = useSelector((state) => territorySurveyCustomers(state, { surveyId, territoryId }))

  if (!survey && !isLoading) navigate('..')

  useEffect(() => {
    addAction({
      getSurveyName: () => survey?.name || 'Survey fuck',
      getSectorLevel: () => sectorType
    })
  }, [survey, sectorType])

  if (isLoading) return <LoadingCard dataKey={dataKey} />

  return <SurveyCustomers {...rest} employee={employee} survey={survey} />
}

TerritorySurveyCustomers.propTypes = {
  connectedFetchTerritory: func
}

export default connect(null, {
  connectedFetchTerritory: fetchTerritory
})(TerritorySurveyCustomers)
