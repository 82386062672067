import React, { useContext, useEffect } from 'react'
import PriceCheckCard from 'views/plan/PriceCheckCard'

import SectorContext from 'context/SectorContext'

import EdlpCompliance from './EdlpCompliance'
import GeographyCompliance from './GeographyCompliance'
import PriceComplianceHeader from './PriceComplianceHeader'
import StrategyCompliance from './StrategyCompliance'

const AmplifyPriceComplianceLayout = () => {
  const { sectorType, sectorId, currentProductType } = useContext(SectorContext)

  useEffect(() => {
    document.title = 'Amplify - Price Compliance'
  }, [])

  const showEdlp = currentProductType === 'fmc'
  const xlCardWidth = showEdlp ? 4 : 6

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
      <PriceComplianceHeader parentHeader="Amplify" header="Price Compliance" />
      <PriceCheckCard
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: xlCardWidth }}
        amplifyAction={() => `/${sectorType}/${sectorId}/pace/amplify/price-capture`}
        complianceAmplify={() => `/${sectorType}/${sectorId}/pace/amplify/price-compliance`}
      />
      <StrategyCompliance span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: xlCardWidth }} />
      {showEdlp && <EdlpCompliance span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }} />}
      <GeographyCompliance span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
    </div>
  )
}
export default AmplifyPriceComplianceLayout
