import { addEntities, mergeEntities, setEntities } from 'store/actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => Object.assign({}, state, action.payload.surveys),
  [setEntities]: (state, action) => action.payload.surveys || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.surveys) return state
    return Object.entries(payload.surveys).reduce((acc, [id, survey]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...survey
        }
      })
    }, Object.assign({}, state))
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
