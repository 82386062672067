import React from 'react'

import FAQAccordion from 'components/accordion/FAQAccordion'
import Container from 'components/Container'

const FAQ = () => (
  <>
    <Container padded>
      <FAQAccordion question="Nullam id dolor id nibh ultricies vehicula ut id elit?">
        <p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis.</p>
        <p>
          Sed posuere consectetur est at lobortis. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
          auctor.
        </p>
      </FAQAccordion>
      <FAQAccordion question="Cras justo odio, dapibus ac facilisis in, egestas eget quam?">
        <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>
        <p>
          Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce dapibus, tellus ac
          cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
        </p>
      </FAQAccordion>
    </Container>
  </>
)

export default FAQ
