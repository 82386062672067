import React, { useContext } from 'react'
import { bool, func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Container from 'components/Container'
import GlobalAlert from 'components/GlobalAlert'
import Section from 'components/Section'

const EndOngoingCallWarning = ({ visible, close, ongoingCall }) => {
  const { translate } = useContext(LangContext)

  return (
    <ActionSheet
      title={translate('app.inCallWarning')}
      visible={visible}
      action={
        <button type="button" onClick={() => close()}>
          {translate('common.close')}
        </button>
      }
      onOverlayClick={() => close()}
    >
      <Container noPadding>
        <Section>
          <GlobalAlert>
            {translate('app.template.alreadyInCall', { customerName: ongoingCall.customer.name })}
          </GlobalAlert>
        </Section>
      </Container>
    </ActionSheet>
  )
}

EndOngoingCallWarning.propTypes = {
  close: func.isRequired,
  ongoingCall: object,
  visible: bool
}

export default EndOngoingCallWarning
