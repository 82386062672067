import React, { useContext, useEffect, useState } from 'react'
import { bool, func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import Container from 'components/Container'
import Dropdown from 'components/Dropdown'
import OrderApprovalBody from 'components/orderApproval/OrderApprovalBody'
import SegmentControl from 'components/SegmentControl'

const longLanguage = {
  fr: 'french',
  en: 'english'
}

const OrderApproval = ({ order, isRetailer, isActionable, submitOrderApproval, loading }) => {
  const { lang, translate } = useContext(LangContext)
  const language = longLanguage[lang]

  const [decision, setDecision] = useState(null)
  const [rejectReason, setRejectReason] = useState('')

  const header = {
    key: isRetailer ? `orderApproval.retailerHeader.${order.type}` : 'orderApproval.managerHeader',
    variables: {
      firstName: order.employee.firstName,
      lastName: order.employee.lastName,
      orderType: order.type,
      erp: order.customer.id,
      customerName: order.customer.name
    }
  }

  useEffect(() => {
    if (decision === 'APPROVED') setRejectReason('')
  }, [decision])

  const responseValid = Boolean(decision) && (decision === 'REJECTED') === Boolean(rejectReason)

  return (
    <Container>
      <OrderApprovalBody
        header={header}
        order={order}
        entries={order.entries}
        lang={language}
        isActionable={isActionable}
        isRetailer={isRetailer}
      >
        {isActionable && (
          <>
            <SegmentControl
              name="type"
              options={[
                {
                  label: translate('common.approve'),
                  value: 'APPROVED'
                },
                {
                  label: translate('common.reject'),
                  value: 'REJECTED'
                }
              ]}
              onChange={({ target }) => setDecision(target.value)}
              value={decision}
            />
            {decision === 'REJECTED' && (
              <Dropdown
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason}
                placeholder={translate('orderApproval.rejection.selectReason')}
                options={[
                  {
                    label: translate('orderApproval.rejection.noLongerNeeded'),
                    value: 'No Longer Needed'
                  },
                  {
                    label: translate('orderApproval.rejection.incorrectOrder'),
                    value: 'Incorrect Order'
                  },
                  { label: translate('orderApproval.rejection.didNotAgree'), value: 'Did not agree' }
                ]}
              />
            )}
            <Button
              primary
              full
              isLoading={loading}
              disabled={!responseValid}
              onClick={() => submitOrderApproval({ decision, rejectReason })}
            >
              {translate('common.submit')}
            </Button>
          </>
        )}
      </OrderApprovalBody>
    </Container>
  )
}

OrderApproval.propTypes = {
  order: object.isRequired,
  isRetailer: bool,
  isActionable: bool,
  submitOrderApproval: func,
  loading: bool
}

export default OrderApproval
