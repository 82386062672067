/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { connect, useSelector } from 'react-redux'
import { currentVersion } from 'appVersion'
import axios from 'axios'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import moment from 'moment'
import { func, node } from 'prop-types'

import { CallDurationContextProvider } from 'context/CallDurationContext'
import GoogleMapsContext from 'context/GoogleMapsContext'

import { setCoords, setEmployeeOngoingCall } from 'store/auth/actions'
import * as taskSelector from 'store/callTasks/selectors'
import { upsertCustomerCall } from 'store/customerCalls/actions'
import * as callSelector from 'store/customerCalls/selectors'

import ActiveCallDetails from 'components/schedule/ActiveCallDetails'
import CancelCallConfirmation from 'components/schedule/CancelCallConfirmation'
import EndOngoingCallWarning from 'components/schedule/EndOngoingCallWarning'
import ForcedCompleteSheet from 'components/schedule/ForcedCompleteSheet'
import MinifiedCall from 'components/schedule/MinifiedCall'
import StartCallSheet from 'components/schedule/StartCall'

import { CALL_CUSTOMER_ATTRS } from 'utils/constants'
import { isLaptopGeolockEnabled } from 'utils/featureFlags'
import { calculatedDistance, getAddressString, getDeviceType } from 'utils/helpers'

// 1 PERMISSION_DENIED The acquisition of the geolocation information failed because the page didn't have the permission to do it.
// 2 POSITION_UNAVAILABLE  The acquisition of the geolocation failed because at least one internal source of position returned an internal error.
// 3 TIMEOUT The time allowed to acquire the geolocation was reached before the information was obtained.

const locationPermissions = {
  1: 'denied',
  2: 'unavailable',
  3: 'timeout'
}

const territoryRadiusOverride = {
  '50203 - GUELPH - WATERLOO': 0.21,
  '50605 - MARKHAM': 0.21,
  '50405 - UPPER VAUGHAN': 0.21,
  '50504 - URBAN CORE EAST': 0.21
}

const ACCEPTABLE_ACCURACY = 150 // Refers to margin error for geolocation accuracy
const LOCAL_STORAGE_KEY_ONGOING_CALL = 'ongoingCall'

const saveToLocalStorage = (ongoingCall) => {
  localStorage.setItem(LOCAL_STORAGE_KEY_ONGOING_CALL, JSON.stringify(ongoingCall))
}

const ActiveCallContext = React.createContext()
export default ActiveCallContext

async function getCallLocation(setCoords) {
  if (navigator.permissions) {
    const permission = await navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
      return result.state
    })

    if (permission === 'denied') return Promise.resolve({ err: 'denied' })
    if (permission !== 'granted') return Promise.resolve({ err: permission })
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        const {
          coords: { latitude, longitude, accuracy },
          timestamp
        } = res
        setCoords({ coords: { latitude, longitude }, timestamp, accuracy })
        resolve({ coords: { latitude, longitude }, timestamp, accuracy })
      },
      (error) => {
        resolve({ error })
      },
      { enableHighAccuracy: true, maximumAge: 60000, timeout: 30000 }
    )
  })
}

const UnconnectedActiveCallProvider = ({
  children,
  upsertCustomerCall,
  toggleVisibleApptDetails,
  setCoords,
  updateEmployeeOngoingCall
}) => {
  const { getDrivingDistance } = useContext(GoogleMapsContext)

  const employee = useSelector((state) => state.auth.user)
  const allCalls = useSelector((state) => callSelector.allCustomerCalls(state))
  const tasksByOutletSubtype = useSelector((state) => taskSelector.tasksByOutletSubtype(state))
  const coords = useSelector((state) => state.auth.coords || {})

  const employeeTimezone = employee?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

  const debouncedUpsertCustomerCall = useMemo(
    () => debounce(upsertCustomerCall, 500, { leading: true, trailing: false }),
    [upsertCustomerCall]
  )

  const isCallCompleted = (call) => {
    const actualCall = allCalls.find(
      ({ id, customerId, callStart }) =>
        (customerId === call?.customerId && callStart === call?.callStart) || id === call.id
    )

    return actualCall && actualCall.callEnd
  }

  // To fix NOD-1034 and NOD-1012 and other ongoing call issues
  const getOrClearOngoingCall = () => {
    const ongoingCallFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY_ONGOING_CALL)
    const ongoingCallFromEmployee = isEmpty(employee.ongoingCall?.ongoingCall)
      ? null
      : employee.ongoingCall?.ongoingCall
    const serializedData =
      ongoingCallFromEmployee || (ongoingCallFromLocalStorage ? JSON.parse(ongoingCallFromLocalStorage) : null)

    if (isEmpty(serializedData)) return null

    // We want to remove the call if it was started yesterday or earlier
    if (
      serializedData?.ongoingCall?.callStart &&
      moment(serializedData.ongoingCall.callStart).isBefore(moment(), 'day')
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEY_ONGOING_CALL)
      // if (ongoingCallFromEmployee) updateEmployeeOngoingCall({ ongoingCall: {} })
      return null
    }

    // Checking if we have an actual call that's completed
    if (serializedData.ongoingCall && isCallCompleted(serializedData.ongoingCall)) {
      localStorage.removeItem(LOCAL_STORAGE_KEY_ONGOING_CALL)
      // if (ongoingCallFromEmployee) updateEmployeeOngoingCall({ ongoingCall: {} })
      return null
    }

    return serializedData
  }

  const [startCallScreenVisible, setStartCallScreenVisible] = useState(false)
  const [confirmCancelCallVisible, setConfirmCancelCallVisible] = useState(false)

  const [inPersonAllowed, setInPersonAllowed] = useState(employee.callRadiusExempt)
  const [loadingLocation, setLoadingLocation] = useState(false)
  const [inPersonCallLocationError, setInPersonCallLocationError] = useState()
  const [currentCallWarningCustomer, setCurrentCallWarningCustomer] = useState()

  const initialSerializedData = useMemo(() => getOrClearOngoingCall(), [])

  const [ongoingCall, setOngoingCall] = useState(initialSerializedData?.ongoingCall || null)
  const [ongoingCallMeta, setOngoingCallMeta] = useState({
    kms: initialSerializedData?.kms?.toString() || null,
    suggestedKms: initialSerializedData?.suggestedKms || null,
    note: initialSerializedData?.note || '',
    completedTasks: initialSerializedData?.completedTasks || [],
    isMinimized: initialSerializedData?.isMinimized || false
  })

  const [callLocation, setCallLocation] = useState(null)
  const [callToStart, setCallToStart] = useState(null)
  const [forcedComplete, setForcedComplete] = useState()

  const callType = ongoingCall?.callType || null
  const callStartTime = ongoingCall?.callStart ? new Date(ongoingCall.callStart).valueOf() : null

  const getDistanceToCustomer = (customer, coords, accuracy = 0) => {
    const distanceInKms = calculatedDistance([customer.latitude, customer.longitude], coords)
    const accuracyInKMetres = accuracy / 1000

    return Math.max(distanceInKms - accuracyInKMetres, 0)
  }

  // ADV-560 - Adding radius override for specific territories
  const checkCoordsWithinRadius = (customer, coords, accuracy = 0) => {
    const distance = getDistanceToCustomer(customer, coords, accuracy)
    const override = customer && customer.territory ? territoryRadiusOverride[customer.territory] : null
    if (customer && customer.territory && override) return distance <= override
    return distance <= 1.51 // kms
  }

  const isGeolockRequired = isMobile || isLaptopGeolockEnabled(employee)

  // ADV-563 - Adding new strike system before kinking out the user
  const [outsideRadiusStrike, setOutsideRadiusStrike] = useState([])

  const addStrikeOutsideRadius = (location) => {
    setOutsideRadiusStrike([location, ...outsideRadiusStrike])
    console.log('Outside radius strikes : ', outsideRadiusStrike.length + 1)
    setInPersonCallLocationError('outsideRadius')
    if (outsideRadiusStrike.length >= 2) {
      return true
    }
    return false
  }

  const clearStrikesInPersonAllowed = (coordsWithinRadius) => {
    setOutsideRadiusStrike([])
    setInPersonAllowed(coordsWithinRadius)
  }

  const verifyCallLocation = async () => {
    setInPersonAllowed(false)
    setInPersonCallLocationError()
    setLoadingLocation(false)

    if (employee.callRadiusExempt || !isGeolockRequired) {
      setInPersonAllowed(true)
      return
    }

    if (!employee.trackingAllowed) {
      setInPersonAllowed(false)
      setInPersonCallLocationError('denied')
      return
    }

    if (callToStart?.customer && (!callToStart?.customer?.latitude || !callToStart?.customer?.longitude)) {
      setInPersonAllowed(true)
      setInPersonCallLocationError('unavailable')
      return
    }

    if (callToStart?.customer) {
      setLoadingLocation(true)

      if (coords?.coords) {
        if (isGeolockRequired) {
          const coordsWithinRadius = checkCoordsWithinRadius(callToStart.customer, coords.coords, coords.accuracy)

          if (coords.accuracy > ACCEPTABLE_ACCURACY) {
            setInPersonCallLocationError('innaccurateLocation')
          } else if (!coordsWithinRadius) {
            setInPersonCallLocationError('outsideRadius')
          }
        }

        setCallLocation(coords)
        setLoadingLocation(false)
      } else {
        getCallLocation(setCoords)
          .then(async (location) => {
            if (location?.error) {
              const { error } = location
              const err = locationPermissions[error.code]

              setInPersonAllowed(err !== 'denied')
              setInPersonCallLocationError(JSON.stringify(error))
              setLoadingLocation(false)
            } else {
              if (isGeolockRequired) {
                const isWithinCallRadius = checkCoordsWithinRadius(
                  callToStart.customer,
                  location.coords,
                  location.accuracy
                )

                if (location.accuracy > ACCEPTABLE_ACCURACY) {
                  setInPersonCallLocationError('innaccurateLocation')
                } else if (!isWithinCallRadius) {
                  setInPersonCallLocationError('outsideRadius')
                }
              }

              setCallLocation(location)
              setLoadingLocation(false)
            }
          })
          .catch((err) => {
            console.log(err)
            setLoadingLocation(false)
          })
      }
    }
  }

  useEffect(() => {
    if (!callStartTime && startCallScreenVisible) {
      verifyCallLocation()
    }

    if (!startCallScreenVisible) {
      setCallToStart(null)
      setCallLocation(null)
      setLoadingLocation(false)
      setInPersonCallLocationError(null)
      setInPersonAllowed(false)
    }
  }, [startCallScreenVisible, callStartTime])

  useEffect(() => {
    if (callType === 'in-person' && ongoingCall && coords?.coords) {
      const isWithinCallRadius = checkCoordsWithinRadius(ongoingCall.customer, coords.coords, coords.accuracy)

      if (coords.accuracy > ACCEPTABLE_ACCURACY) {
        setInPersonCallLocationError('innaccurateLocation')
      } else if (!isWithinCallRadius) {
        const hasThreeStrikes = addStrikeOutsideRadius(coords)
        if (hasThreeStrikes) {
          completeCall({ forced: true, outsideRadiusStrike })
          setForcedComplete({
            kms: getDistanceToCustomer(ongoingCall.customer, coords.coords, coords.accuracy),
            customer: ongoingCall?.customer?.name
          })
          setOutsideRadiusStrike([])
        }
      } else {
        clearStrikesInPersonAllowed(coords)
      }
    }
  }, [
    coords.timestamp,
    ongoingCall?.customer?.id,
    ongoingCall?.customer?.name,
    callType,
    coords?.coords,
    coords?.accuracy,
    document.visibilityState
  ])

  const debounceSaveToLocalStorage = debounce(
    (ongoingCallInfo) => {
      saveToLocalStorage(ongoingCallInfo)
      // updateEmployeeOngoingCall({ ongoingCall: ongoingCallInfo })
    },
    500,
    { trailing: true }
  )

  const saveToLocalStorageCallback = useCallback(debounceSaveToLocalStorage, [
    saveToLocalStorage,
    debounceSaveToLocalStorage
  ])

  const serializeCall = () => {
    const { customer, ...call } = ongoingCall
    const ongoingCallInfo = {
      ...ongoingCallMeta,
      ongoingCall: {
        ...call,
        customer: pick(customer, CALL_CUSTOMER_ATTRS)
      }
    }
    saveToLocalStorageCallback(ongoingCallInfo)
  }

  const removeCallFromLocalStorage = async () => {
    debounceSaveToLocalStorage.cancel()
    localStorage.removeItem(LOCAL_STORAGE_KEY_ONGOING_CALL)
    // await updateEmployeeOngoingCall({ ongoingCall: {} }) // force sync operation
  }

  useEffect(() => {
    if (!isEmpty(ongoingCall) && callStartTime) serializeCall()
  }, [ongoingCallMeta, callStartTime])

  const showStartCallScreen = async ({ call, callId, customerId }) => {
    const callToStartToSet = callId ? allCalls.find(({ id }) => id === callId) : {}

    setCallToStart({
      customerId,
      ...(call || null),
      ...callToStartToSet
    })

    setStartCallScreenVisible(true)
  }

  const endCall = () => {
    setOngoingCall(null)
    setOngoingCallMeta({
      kms: null,
      suggestedKms: null,
      note: '',
      completedTasks: [],
      isMinimized: false
    })
  }

  // 'storage' event handling to end call if it's ended in another tab
  useEffect(() => {
    const handleStorageChange = async (event) => {
      if (event.key === LOCAL_STORAGE_KEY_ONGOING_CALL) {
        const serializedData = JSON.parse(event.newValue)

        if (ongoingCall && !serializedData) {
          endCall()
          await removeCallFromLocalStorage()
        } else if (!ongoingCall) {
          setOngoingCall(serializedData?.ongoingCall)
          setOngoingCallMeta(serializedData?.ongoingCallMeta)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      debounceSaveToLocalStorage.cancel()
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  const completeCall = async (params = { forced: false, overrideDuration: 0, outsideRadiusStrike }) => {
    await removeCallFromLocalStorage()

    const { forced, overrideDuration } = params

    const insertEmployeeKm =
      ongoingCallMeta.kms || ongoingCallMeta.suggestedKms
        ? {
            kms: ongoingCallMeta?.kms || 0,
            suggestedKms: ongoingCallMeta.suggestedKms,
            dateDriven: new Date(callStartTime),
            type: 'CALL'
          }
        : null

    const insertMessage = ongoingCallMeta.note?.trim()
      ? {
          text: ongoingCallMeta.note,
          customerId: ongoingCall?.customerId || ongoingCall?.customer?.id
        }
      : null

    // ADV-563 - Fetching location from IP address when users are forced out of the app
    const ipLocation = {}
    if (ongoingCall?.callType === 'in-person' && !employee.callRadiusExempt && coords && forced) {
      try {
        const {
          data: { ip }
        } = await axios.get('https://api.ipify.org/?format=json')
        if (ip) {
          const {
            data: { city, latitude, longitude, isp, zipcode }
          } = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=a09d9d1a297d48e9a8d70c9ebbc59906')
          ipLocation.city = city
          ipLocation.coord = `${latitude}, ${longitude}`
          ipLocation.latitude = latitude
          ipLocation.longitude = longitude
          ipLocation.isp = isp
          ipLocation.zipcode = zipcode
        }
      } catch (err) {
        console.log("Can't access IP address")
      }
    }

    const meta =
      ongoingCall?.callType === 'in-person' && !employee.callRadiusExempt && coords
        ? {
            callEnd: {
              ...coords.coords,
              submitTime: new Date().toISOString(),
              accuracy: coords.accuracy,
              timestamp: coords.timestamp,
              endedByGeolock: forced,
              deviceType: getDeviceType(),
              appVersion: currentVersion,
              browser: navigator.userAgent,
              outsideRadiusStrike,
              ipLocation
            }
          }
        : null

    const callEndTime = overrideDuration
      ? moment(callStartTime).add(overrideDuration, 'seconds').tz(employeeTimezone)
      : moment().tz(employeeTimezone)

    await debouncedUpsertCustomerCall({
      ...omit(ongoingCall, ['customer', 'employee', 'type']),
      customerId: ongoingCall.customerId || ongoingCall.customer?.id,
      callStart: moment.tz(callStartTime, employeeTimezone).toISOString(),
      callEnd: moment(callEndTime).toISOString(),
      completedTasks: ongoingCallMeta.completedTasks,
      online: window.navigator.onLine,
      orders: ongoingCall.orders || [],
      employeeKm: insertEmployeeKm,
      message: insertMessage,
      meta,
      employeeId: employee.id,
      callStartError: inPersonCallLocationError
    })

    endCall()
  }

  const confirmCancelCall = async () => {
    await removeCallFromLocalStorage()
    setConfirmCancelCallVisible(false)
    endCall()
  }

  const getPreviousCallDistance = async ({ start, address, coords }) => {
    const employeeValidAddressString = !isEmpty(employee.address) && getAddressString(employee.address)
    const validUserAddress = (employeeValidAddressString || (employee.latitude && employee.longitude)) && {
      ...employee.address,
      address: employeeValidAddressString,
      ...pick(employee, ['latitude', 'longitude'])
    }

    const prevCalls = allCalls.filter((c) => {
      const type = c.callType || c.scheduledType
      if (type !== 'in-person') return false

      const date = c.callEnd || c.scheduledEnd
      return moment(start).isSame(date, 'day') && moment(start).isAfter(date)
    })

    const prevCall =
      prevCalls && prevCalls.length > 0
        ? prevCalls.reduce((prev, curr) => (getEndDate(curr).isAfter(getEndDate(prev)) ? curr : prev), {})
        : {}

    const previousAddress = !isEmpty(prevCall?.customer)
      ? {
          ...prevCall.customer.address,
          address: getAddressString(prevCall.customer.address),
          ...pick(prevCall.customer, ['latitude', 'longitude'])
        }
      : validUserAddress

    const distance = previousAddress
      ? await getDrivingDistance({
          from: previousAddress,
          to: { ...address, ...coords, address: getAddressString(address) }
        })
      : 0

    return distance
  }

  const getEndDate = (call) => {
    if (isEmpty(call)) return moment(0)
    const endDate = call.callEnd || call.scheduledEnd

    return moment(endDate)
  }

  const startCall = async (callType) => {
    const newCallStartTime = new Date().toISOString()
    let drivingDistance = null

    if (callType === 'in-person') {
      const distance = await getPreviousCallDistance({
        start: newCallStartTime,
        address: callToStart.customer.address,
        coords: pick(callToStart.customer, ['latitude', 'longitude'])
      })
      drivingDistance = distance ? distance / 1000 : null

      setOngoingCallMeta({
        ...ongoingCallMeta,
        kms: null,
        suggestedKms: drivingDistance
      })
    }

    const callTasks =
      tasksByOutletSubtype &&
      callToStart?.customer?.outletSubtype &&
      tasksByOutletSubtype[callToStart.customer.outletSubtype]

    if (callTasks?.length) {
      setOngoingCallMeta({
        ...ongoingCallMeta,
        completedTasks: callTasks.map((task) => ({
          id: task.id,
          label: task.task,
          checked: false
        }))
      })
    }

    const locationVals =
      callType === 'in-person' && !employee.callRadiusExempt && callLocation?.coords
        ? { ...callLocation.coords, locationAccuracy: callLocation.accuracy }
        : null

    setConfirmCancelCallVisible(false)
    setStartCallScreenVisible(false)
    setOngoingCall({
      ...callToStart,
      ...locationVals,
      deviceType: getDeviceType(),
      callType,
      callStart: newCallStartTime
    })
  }

  const showEndCallWarning = (customer) => {
    setCurrentCallWarningCustomer(customer)
  }

  const cancelActiveCall = () => {
    setConfirmCancelCallVisible(true)
  }

  const debouncedCompleteCall = debounce(
    () => {
      completeCall()
    },
    500,
    { leading: true, trailing: false }
  )

  const value = {
    ongoingCall,
    setOngoingCall,
    showStartCallScreen,
    showEndCallWarning,
    getPreviousCallDistance
  }

  return (
    <ActiveCallContext.Provider value={value}>
      {children}
      <StartCallSheet
        employee={employee}
        call={callToStart}
        startCall={startCall}
        editCall={toggleVisibleApptDetails}
        startCallScreenVisible={startCallScreenVisible}
        setStartCallScreenVisible={setStartCallScreenVisible}
        inPersonCallLocationError={inPersonCallLocationError}
        inPersonAllowed={inPersonAllowed}
        inOngoingCall={Boolean(ongoingCall && callStartTime)}
        loadingLocation={loadingLocation}
      />
      {callStartTime && (
        <CallDurationContextProvider
          callStartTime={callStartTime}
          completeCall={debouncedCompleteCall}
          callType={callType}
        >
          {ongoingCallMeta?.isMinimized ? (
            <MinifiedCall
              call={ongoingCall}
              setIsMinimized={(shouldMinimize) =>
                setOngoingCallMeta({ ...ongoingCallMeta, isMinimized: shouldMinimize })
              }
            />
          ) : (
            <ActiveCallDetails
              call={ongoingCall}
              updateCall={() => debouncedCompleteCall()}
              cancelCall={cancelActiveCall}
              callType={callType}
              callStartTime={callStartTime}
              kms={ongoingCallMeta?.kms}
              note={ongoingCallMeta?.note}
              completedTasks={ongoingCallMeta?.completedTasks}
              setKms={(kms) => setOngoingCallMeta({ ...ongoingCallMeta, kms })}
              setNote={(note) => setOngoingCallMeta({ ...ongoingCallMeta, note })}
              setCompletedTasks={(completedTasks) => setOngoingCallMeta({ ...ongoingCallMeta, completedTasks })}
              setIsMinimized={(shouldMinimize) =>
                setOngoingCallMeta({ ...ongoingCallMeta, isMinimized: shouldMinimize })
              }
              timezone={employeeTimezone}
              setInPersonCallLocationError={setInPersonCallLocationError}
              canEdit
              isOngoingCall
            />
          )}
        </CallDurationContextProvider>
      )}
      <CancelCallConfirmation
        confirmCancelCallVisible={confirmCancelCallVisible}
        setConfirmCancelCallVisible={setConfirmCancelCallVisible}
        confirmCancelCall={confirmCancelCall}
      />
      {ongoingCall && callStartTime && (
        <EndOngoingCallWarning
          visible={Boolean(currentCallWarningCustomer)}
          close={() => showEndCallWarning()}
          ongoingCall={ongoingCall}
        />
      )}
      <ForcedCompleteSheet forcedCompleteDetails={forcedComplete} setForcedCompleteDetails={setForcedComplete} />
    </ActiveCallContext.Provider>
  )
}

UnconnectedActiveCallProvider.propTypes = {
  children: node.isRequired,
  upsertCustomerCall: func.isRequired,
  toggleVisibleApptDetails: func.isRequired,
  setCoords: func.isRequired,
  updateEmployeeOngoingCall: func.isRequired
}

const mapActionCreators = {
  upsertCustomerCall,
  updateEmployeeOngoingCall: setEmployeeOngoingCall,
  setCoords
}

export const ActiveCallProvider = connect(null, mapActionCreators)(UnconnectedActiveCallProvider)
