import React from 'react'
import { Link } from 'react-router-dom'
import { bool, string } from 'prop-types'

import { cn } from 'utils/styling'

const PricingCardNavArrow = ({ isPrev, href }) => {
  const arrow = isPrev ? '❮' : '❯'

  return (
    <Link
      onClick={(e) => e.stopPropagation()}
      className={cn(
        'hidden p-6 text-center text-[6rem] text-white/50 transition-colors duration-300 ease-curve hover:text-white/100 sm:block md:p-12 md:text-[9rem]'
      )}
      to={href}
    >
      {arrow}
    </Link>
  )
}

PricingCardNavArrow.propTypes = {
  isPrev: bool,
  href: string
}

export default PricingCardNavArrow
