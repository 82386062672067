import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import ViewHeader from 'components/ViewHeader'

import { DISTRO_VAPE_CHANNEL, OOS_VAPE_PRODUCT_CATEGORY } from 'utils/constants'

import DistroContext from './DistroContext'

const DistroHeader = ({ parentHeader, header }) => {
  const { vapeCategory, setVapeCategory, vapeChannel, setVapeChannel } = useContext(DistroContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const filters = [
    !isWideLayout && <ProductTypePicker key="amplify-distro-product-type-picker" />,
    currentProductType === 'vape' && (
      <Dropdown
        key="amplify-distro-vape-category-picker"
        name="vape-category"
        onChange={(e) => setVapeCategory(e.target.value)}
        value={vapeCategory}
        options={OOS_VAPE_PRODUCT_CATEGORY}
        shouldUpperCase
      />
    ),
    currentProductType === 'vape' && (
      <Dropdown
        key="amplify-distro-vape-channel-picker"
        name="vape-channel"
        onChange={(e) => setVapeChannel(e.target.value)}
        value={vapeChannel}
        options={DISTRO_VAPE_CHANNEL}
        shouldUpperCase
      />
    )
  ]
  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

DistroHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default DistroHeader
