import React, { useContext, useState } from 'react'
import { stripUnit } from 'polished'
import { bool, func, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Icon from 'components/Icon'

import { greyDark } from 'styles/colors'
import { animationCurve, animationTime } from 'styles/global'
import * as spacing from 'styles/spacing'

export const languages = [
  {
    name: 'english',
    label: 'English'
  },
  {
    name: 'french',
    label: 'Français'
  }
]

const OptionContainer = styled.button`
  display: block;
  text-align: left;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  transition: color ${animationTime} ${animationCurve};
  ${(props) => props.selected && `color: ${greyDark}`};

  &:last-child {
    margin-bottom: 0;
  }
`

const Checkmark = styled(Icon)`
  margin-left: ${spacing.tiny};
`

const Option = ({ currentSelection, label, name, setOption }) => {
  const isSelected = currentSelection === name
  return (
    <OptionContainer selected={isSelected} onClick={() => setOption(name)}>
      {label}
      {isSelected && <Checkmark icon="checkmark-small" accent />}
    </OptionContainer>
  )
}

Option.propTypes = {
  currentSelection: string,
  label: string.isRequired,
  name: string.isRequired,
  setOption: func.isRequired
}

const LanguagePicker = ({ visible, close, setLanguage, preferredLanguage }) => {
  const { translate } = useContext(LangContext)
  const [currentSelection, setOption] = useState(preferredLanguage)

  return (
    <ActionSheet
      title={translate('settings.preferredLanguage')}
      visible={visible}
      action={<button onClick={() => setLanguage(currentSelection)}>{translate('common.confirm')}</button>}
      onOverlayClick={close}
    >
      <div>
        {languages.map((option, idx) => (
          <Option key={idx} {...option} setOption={setOption} currentSelection={currentSelection} />
        ))}
      </div>
    </ActionSheet>
  )
}

LanguagePicker.propTypes = {
  visible: bool,
  close: func.isRequired,
  setLanguage: func.isRequired,
  preferredLanguage: string.isRequired
}
export default LanguagePicker
