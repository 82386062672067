import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

const periodFromProps = (state, props) => props.period
const rangeFromProps = (state, props) => props.range
const offsetFromProps = (state, props) => props.offset
const productTypeFromProps = (state, props) => props.activeProductType
const vapeCategoryFromProps = (state, props) => props.vapeCategory
const unitOfMeasureFromProps = (state, props) => props.unitOfMeasure
const geographyFromProps = (state, props) => props.geography
const columnSortedFromProps = (state, props) => props.columnSorted
const sortDirectionFromProps = (state, props) => props.sortDirection

export const planningSellinData = createSelector(
  productTypeFromProps,
  vapeCategoryFromProps,
  unitOfMeasureFromProps,
  periodFromProps,
  rangeFromProps,
  dataSourceFromSectorLevel,
  (productType, vapeCategory, unitOfMeasure, period, range, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-${productType}-${vapeCategory}-${unitOfMeasure}`] || {}
    const periodAmounts = amounts[period] || {}

    return {
      actuals: +periodAmounts.booked,
      gap: periodAmounts.gapTodate && range === 'to_date' ? +periodAmounts.gapTodate : +periodAmounts.gap,
      completionPercentage:
        periodAmounts.vsTargetTodate && range === 'to_date' ? +periodAmounts.vsTargetTodate : +periodAmounts.vsTarget
    }
  }
)

export const sellinRunRateData = createSelector(
  productTypeFromProps,
  vapeCategoryFromProps,
  unitOfMeasureFromProps,
  dataSourceFromSectorLevel,
  (productType, vapeCategory, unitOfMeasure, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-run-rate-${productType}-${vapeCategory}-${unitOfMeasure}`] || {}
    if (isEmpty(amounts)) return {}

    return amounts
  }
)

export const sellinOrderCompletionData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  (productType, sector) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`sellin-order-completion`] || {}
    if (isEmpty(amounts)) return {}

    return amounts
  }
)

export const sellinGeographicalData = createSelector(
  productTypeFromProps,
  geographyFromProps,
  periodFromProps,
  rangeFromProps,
  vapeCategoryFromProps,
  unitOfMeasureFromProps,
  offsetFromProps,
  columnSortedFromProps,
  sortDirectionFromProps,
  dataSourceFromSectorLevel,
  (productType, geography, period, range, vapeCategory, unitOfMeasure, offset, columnSorted, sortDirection, sector) => {
    if (isEmpty(sector)) return {}

    const amounts =
      sector[
        `sellin-geography-${productType}-${geography}-${period}-${vapeCategory}-${unitOfMeasure}-${columnSorted}-${sortDirection}-${range}-${offset}`
      ] || {}
    if (isEmpty(amounts)) return {}

    return amounts.map((sellIn) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${sellIn.id}/pace/amplify/sell-in`,
          ...sellIn
        }
      }
      return {
        linkTo: null,
        ...sellIn
      }
    })
  }
)
