import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { preferredLanguage } from 'store/auth/selectors'

const allAlerts = (state) => Object.values(omit(state.alerts || {}), '_persist')
const readAlerts = (state) => state.auth.user?.readAlerts || []

const readAlertIds = createSelector(readAlerts, (readAlerts) => readAlerts.map(({ id }) => id))
export const currentAlerts = createSelector(allAlerts, (alerts) =>
  alerts.filter((alert) => new Date(alert.endAt).valueOf() > Date.now())
)

export const sortedAlerts = createSelector(currentAlerts, readAlertIds, (alerts, readIds) => {
  return groupBy(
    sortBy(alerts, ({ endAt }) => new Date(endAt).valueOf()),
    ({ id }) => (readIds.includes(id) ? 'read' : 'unread')
  )
})

export const unreadAlertsCount = createSelector(sortedAlerts, ({ unread }) => unread?.length || 0)

const localizedAlertAnnouncements = {
  english: (count) => `You have ${count} unread alerts`,
  french: (count) => `Vous avez ${count} alertes non lues.`
}

export const unreadAlertsAnnouncement = createSelector(unreadAlertsCount, preferredLanguage, (count, language) =>
  count ? localizedAlertAnnouncements[language](count) : ''
)
