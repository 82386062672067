import styled, { css } from 'styled-components'

import { grey, greyDark, greyLight, secondaryColor } from './colors'
import * as spacing from './spacing'

// Font family
export const fontFamily = 'sans-serif'

// Font weights
export const fontRegular = 400
export const fontSemibold = 600
export const fontBold = 700

// Line height
export const baseLineHeight = 1.5
export const headingLineHeight = 1.2

// Font sizes
export const regularFontSize = '16px'
export const smallFontSize = '14px'
export const tinyFontSize = '12px'

export const H1 = styled.h1`
  font-size: 18px;
`

export const H2 = styled.h2`
  font-size: 17px;
`

export const H3 = styled.h3`
  font-size: 16px;
`

export const Accent = styled.span`
  color: ${secondaryColor};
`

export const Muted = styled.span`
  color: ${greyLight};
`

export const globalTypography = css`
  body {
    color: ${grey};
    font-size: ${regularFontSize};
    font-family: ${fontFamily};
    line-height: ${baseLineHeight};
  }

  small {
    font-size: ${smallFontSize};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
    color: ${greyDark};
  }

  p {
    margin-bottom: ${spacing.medium};

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: ${secondaryColor};
    }
  }
`
