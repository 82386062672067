import React, { useEffect, useRef, useState } from 'react'
import { func, node, number, string } from 'prop-types'

const CallDurationContext = React.createContext()
export default CallDurationContext

export const CallDurationContextProvider = ({ callStartTime, children, completeCall, callType }) => {
  const [ongoingCallSecondsElapsed, setOngoingCallSecondsElapsed] = useState(0)
  const [timeInterval, setTimeInterval] = useState(null)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!timeInterval && callStartTime) {
      clearInterval(timeInterval)
      const logInterval = setInterval(() => {
        if (mounted.current) setOngoingCallSecondsElapsed((Date.now() - callStartTime) / 1000)
      }, 1000)
      setTimeInterval(logInterval)
    }
    if (timeInterval && !callStartTime) {
      clearInterval(timeInterval)
      setTimeInterval(null)
    }
    return () => {
      if (timeInterval) clearInterval(timeInterval)
    }
  }, [callStartTime])

  useEffect(() => {
    const maxDuration = callType === 'in-person' ? 14400 : 3600
    if (ongoingCallSecondsElapsed > maxDuration) {
      completeCall({ overrideDuration: maxDuration })
    }
  }, [ongoingCallSecondsElapsed])

  const value = {
    ongoingCallSecondsElapsed
  }
  return <CallDurationContext.Provider value={value}>{children}</CallDurationContext.Provider>
}

CallDurationContextProvider.propTypes = {
  children: node,
  callStartTime: number,
  completeCall: func,
  callType: string
}
