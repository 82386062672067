import omit from 'lodash/omit'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { addEntities, mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import { addCustomerOrders, addOrderToCustomer, removeCustomerOrders } from 'store/customers/actions'
import outbox from 'store/outbox'
import { order as OrderSchema } from 'store/schema'

import * as api from './endpoints'

const queueOfflineSAQ = async (saq, tempId) => {
  const saqOutbox = (await outbox.getItem('saqs')) || {}
  const existingOutboxSaq = saqOutbox[tempId]
  await outbox.setItem('saqs', { ...saqOutbox, [tempId]: saq })
  await registerSync('submit-pending-saqs')
  return existingOutboxSaq?.id // order to be removed from customer
}

const createQueuedOrder = async (saq) => {
  const tempId = 'temp-id-' + (saq.type === 'saq' ? saq.customerId : saq.id)
  const cancelledTempId = await queueOfflineSAQ(saq, tempId)
  return {
    order: { tempId, status: 'new', createdAt: new Date(), ...saq },
    cancelledOrders: [cancelledTempId].filter(Boolean)
  }
}

const queueOfflineOrderDeletion = async (orderId) => {
  const orderOutbox = (await outbox.getItem('deleted-orders')) || []
  await outbox.setItem('deleted-orders', [...orderOutbox, orderId])
  return registerSync('submit-pending-deleted-orders')
}

export const addOrderEntities = createAction('Add order entity', (newOrder) => (dispatch) => {
  const { entities, result } = normalize(newOrder, OrderSchema)

  dispatch(addEntities(entities))
  dispatch(addOrderToCustomer({ customerId: newOrder.customerId, orderId: result, projectId: newOrder.projectId }))
  dispatch(addCustomerOrders({ customerId: newOrder.customerId, orders: [newOrder] }))
})

export const submitNewSAQ = createAction('Submit SAQ', (saq) => async (dispatch) => {
  let newOrder
  let cancelledOrders = []
  if (window.navigator.onLine) {
    try {
      const { data } = await api.submitNewSAQ(saq)
      newOrder = data.order
      cancelledOrders = data.cancelledOrders || []
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      const queued = await createQueuedOrder(saq)
      newOrder = queued.order
      cancelledOrders = queued.cancelledOrders
    }
  } else {
    const { order: queuedOrder, cancelledOrders: cancelledQueuedOrders } = await createQueuedOrder(saq)
    newOrder = queuedOrder
    cancelledOrders = cancelledQueuedOrders
  }
  if (cancelledOrders.length) {
    dispatch(removeCustomerOrders({ customerId: newOrder.customerId, cancelledOrders }))
  }
  dispatch(addOrderEntities(newOrder))
})

export const submitSAQEdit = createAction('Submit SAQ Edit', (saq) => async (dispatch) => {
  let newOrder
  let cancelledOrders = []

  if (window.navigator.onLine) {
    try {
      const { data } = await api.submitSAQEdit(saq)
      newOrder = data.order
      cancelledOrders = data.cancelledOrders || []
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      const queued = await createQueuedOrder(saq)
      newOrder = queued.order
      cancelledOrders = queued.cancelledOrders
    }
  } else {
    const { order: queuedOrder, cancelledOrders: cancelledQueuedOrders } = await createQueuedOrder(saq)
    newOrder = queuedOrder
    cancelledOrders = cancelledQueuedOrders
  }
  if (cancelledOrders.length) {
    dispatch(removeCustomerOrders({ customerId: newOrder.customerId, cancelledOrders }))
  }
  dispatch(addOrderEntities(newOrder))
})

export const clearQueuedSAQs = createAction('Clear submitted SAQs from store')

export const cancelSAQ = createAction(
  'Submit delete request for SAQ',
  ({ id, customerId, tempId, status }) =>
    async (dispatch) => {
      if (tempId) {
        const saqOutbox = (await outbox.getItem('saqs')) || {}
        await outbox.setItem('saqs', omit(saqOutbox, tempId))
        dispatch(mergeEntities({ orders: { [id]: { id, status: 'cancelled' } } }))
        return dispatch(removeCustomerOrders({ customerId, cancelledOrders: [id] }))
      }
      if (window.navigator.onLine) {
        try {
          await api.submitOrderDeletion(id)
          dispatch(mergeEntities({ orders: { [id]: { id, status: 'cancelled' } } }))
          dispatch(removeCustomerOrders({ customerId, cancelledOrders: [id] }))
        } catch (err) {
          if (err.message === 'Network Error') {
            dispatch(mergeEntities({ orders: { [id]: { id, status: 'cancelled' } } }))
            await queueOfflineOrderDeletion(id)
            dispatch(removeCustomerOrders({ customerId, cancelledOrders: [id] }))
          } else {
            if (err.details.order) {
              const { entities } = normalize(err.details.order, OrderSchema)
              dispatch(mergeEntities(entities))
            }
            throw err
          }
        }
      } else {
        dispatch(mergeEntities({ orders: { [id]: { id, status: 'cancelled' } } }))
        await queueOfflineOrderDeletion(id)
        dispatch(removeCustomerOrders({ customerId, cancelledOrders: [id] }))
      }
    }
)
