import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { bool, func } from 'prop-types'

const noAuth = (Component) => {
  const Base = () => {
    const isLoggedIn = useSelector(({ auth }) => auth.loggedIn)
    const isUserLoading = useSelector(({ auth }) => auth.loading)
    const lastUserFetch = useSelector(({ auth }) => auth.lastUserFetch)

    const props = { isLoggedIn, isUserLoading, lastUserFetch }

    if (localStorage.getItem('token')) return <Navigate to="/" />
    return <Component {...props} />
  }

  Base.propTypes = {
    isLoggedIn: bool,
    isUserLoading: bool,
    requestLogout: func
  }

  return Base
}

export default noAuth
