import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { bool, func, string } from 'prop-types'
import { Form, formValueSelector, reduxForm, SubmissionError, submit, unregisterField } from 'redux-form'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { addNewImages } from 'store/auth/actions'
import * as customerSelector from 'store/customers/selectors'
import { submitIntelUpdate } from 'store/intel/actions'
import { intelFromUrl, manufacturerBrandOptions } from 'store/intel/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

import IntelForm from './IntelForm'

const EditIntel = ({
  handleSubmit,
  change,
  submitting,
  invalid,
  submitFailed,
  error,
  backPath,
  submitIntelUpdate,
  addNewImages,
  dispatch
}) => {
  const { addAction } = useContext(ActionHeaderContext)
  const { intelId, sectorId: customerId, sectorType } = useParams()
  const navigate = useNavigate()
  const selector = formValueSelector('editIntel')

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const feedback = useSelector((state) => selector(state, 'feedback'))
  const startDate = useSelector((state) => selector(state, 'startDate'))
  const intelType = useSelector((state) => selector(state, 'type'))
  const productCategory = useSelector((state) => selector(state, 'productCategory'))
  const manufacturer = useSelector((state) => selector(state, 'manufacturer'))
  const existingImages = useSelector((state) => selector(state, 'images'))
  const newImages = useSelector((state) => state.auth.newImages)
  const brandOptions = useSelector((state) => manufacturerBrandOptions('editIntel')(state))
  const initialValues = useSelector((state) => intelFromUrl(state, { intelId }))
  const customer = useSelector((state) => customerSelector.customerFromUrl(state, { customerId }))

  useEffect(() => {
    addAction({
      onCreate: () => dispatch(submit('editIntel')),
      getLabel: () => 'Save'
    })
  }, [])

  useEffect(() => {
    if (initialValues.newImages) {
      addNewImages(initialValues.newImages)
      unregisterField('editIntel', 'newImages')
    }
  }, [addNewImages, initialValues.newImages])

  const formSubmitIntelUpdate = async (formValues) => {
    if (isMobileViewOnly) return navigate(backPath)
    try {
      const values = { ...formValues, customerId: customer.id, customerName: customer.name }
      return submitIntelUpdate({ values, newImages }, () => navigate(backPath))
    } catch (err) {
      throw new SubmissionError({ _error: err?.message || err })
    }
  }

  const formError = error || (invalid && submitFailed && 'Please correct the errors in your form')
  return (
    <Form onSubmit={handleSubmit(formSubmitIntelUpdate)}>
      <IntelForm
        change={change}
        startDate={startDate}
        intelType={intelType}
        existingImages={existingImages}
        brandOptions={brandOptions}
        manufacturer={manufacturer}
        feedback={feedback}
        productCategory={productCategory}
        error={formError}
      />
    </Form>
  )
}

const mapActionCreators = {
  submitIntelUpdate,
  addNewImages
}

const mapStateToProps = (state, props) => {
  return {
    employeeId: state.auth.user.id,
    initialValues: intelFromUrl(state, props),
    backPath: '..'
  }
}

EditIntel.propTypes = {
  handleSubmit: func.isRequired,
  change: func,
  submitting: bool,
  invalid: bool,
  submitFailed: bool,
  error: string,
  backPath: string,
  submitIntelUpdate: func,
  addNewImages: func,
  dispatch: func
}

const EditIntelForm = reduxForm({
  form: 'editIntel',
  onSubmitFail: (errors, dispatch, submitError, props) => {
    window.scroll(0, 0)
  }
})(EditIntel)
export default connect(mapStateToProps, mapActionCreators)(EditIntelForm)
