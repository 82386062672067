import React, { useContext, useEffect, useMemo } from 'react'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import AmplifyDistroBrandCard from './AmplifyDistroBrandCard'
import AmplifyDistroCard from './AmplifyDistroCard'
import AmplifyDistroMaterialCard from './AmplifyDistroMaterialCard'
import AmplifyDistroTableCard from './AmplifyDistroTableCard'
import AmplifyDistroTrendCard from './AmplifyDistroTrendCard'
import DistroContext, { DistroProvider } from './DistroContext'
import DistroHeader from './DistroHeader'

const AmplifyDistroCardLayout = () => {
  const { translate } = useContext(LangContext)
  const { vapeCategory } = useContext(DistroContext)
  const { currentProductType, selectedLevel } = useContext(SectorContext)

  useEffect(() => {
    document.title = 'Amplify - Distro'
  }, [])

  const actualVapeCategory = useMemo(() => {
    return currentProductType === 'fmc' ? 'all' : vapeCategory
  })

  const showCustomerView = useMemo(() => {
    return selectedLevel === 'customer'
  })

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
      <DistroHeader parentHeader={translate('app.pace.amplify')} header={translate('app.distro')} />

      <AmplifyDistroCard
        showCustomerView={showCustomerView}
        vapeCategory={actualVapeCategory}
        span={{ xs: 12, sm: 12, md: 12, lg: 2, xl: 2 }}
      />
      <AmplifyDistroTrendCard
        showCustomerView={showCustomerView}
        vapeCategory={actualVapeCategory}
        span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 4 }}
      />
      <AmplifyDistroBrandCard
        showCustomerView={showCustomerView}
        vapeCategory={actualVapeCategory}
        span={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}
      />
      <AmplifyDistroMaterialCard
        showCustomerView={showCustomerView}
        vapeCategory={actualVapeCategory}
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}
      />
      <AmplifyDistroTableCard
        showCustomerView={showCustomerView}
        vapeCategory={actualVapeCategory}
        span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
      />
    </div>
  )
}

const AmplifyDistroLayout = () => {
  return (
    <DistroProvider>
      <AmplifyDistroCardLayout />
    </DistroProvider>
  )
}

export default AmplifyDistroLayout
