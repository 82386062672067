import React from 'react'
import { string } from 'prop-types'

import Icon from 'components/Icon'

const FabButton = ({ icon, ...buttonProps }) => (
  <button
    className="fixed bottom-4 right-4 z-40 h-14 w-14 rounded-full bg-brand p-4 shadow-xl transition-colors duration-200 hover:bg-brand-700 focus:bg-brand-700 active:bg-brand-700 md:bottom-6 md:right-6 md:h-16 md:w-16 md:p-5"
    {...buttonProps}
  >
    <Icon icon={icon} white />
  </button>
)

FabButton.propTypes = {
  icon: string.isRequired
}

export default FabButton
