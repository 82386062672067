import React, { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { string } from 'prop-types'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Spinner from 'components/Spinner'

function LoadingCard({ dataKey }) {
  const { translate } = useContext(LangContext)

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  if (!isLoading) return null

  return (
    <div className="fixed bottom-[10%] left-1/2 z-[100] max-w-[80%] -translate-x-1/2 ">
      <div className="flex items-center gap-2 rounded-full bg-brand py-2 pl-2 pr-4 text-white shadow-md ring-1 ring-white/10">
        <div>
          <Spinner icon="spinner" />
        </div>
        <span className="text-nowrap">{translate('common.currentlyUpdating')}</span>
      </div>
    </div>
  )
}

LoadingCard.propTypes = {
  dataKey: string.isRequired
}

export default connect()(LoadingCard)
