import React, { useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

import { getValidTimeOptions } from 'utils/constants'
import { getHourDifferenceTimeString } from 'utils/helpers'

const defaultObject = { start: '09:00', end: '17:00' }
const validStartOptions = getValidTimeOptions({ start: '07:00', end: '10:00' })

const WorkHoursSheet = ({ visible, workHours, setWorkHours, closeWorkHourSheet, autoSchedulingEnabled }) => {
  const { translate } = useContext(LangContext)

  const [hours, setHours] = useState(workHours || defaultObject)
  const { start, end } = hours

  const startError = start < '07:00' || start > '10:00'
  const endError = getHourDifferenceTimeString(start, end) < 8
  const validEndOptions = getValidTimeOptions({ start: '15:00' })

  const workhoursChanged = isEmpty(workHours) || workHours.start !== start || workHours.end !== end

  return (
    <ActionSheet
      title={translate('workingHoursSheet.title')}
      visible={visible}
      action={<button onClick={closeWorkHourSheet}>{translate('common.cancel')}</button>}
      onOverlayClick={closeWorkHourSheet}
    >
      <Fieldset>
        <FieldsetItem half>
          <Dropdown
            half
            label={translate('common.start')}
            error={startError}
            hint={translate('workingHoursSheet.hints.start')}
            options={validStartOptions}
            value={hours.start}
            onChange={({ target }) => setHours({ ...hours, start: target.value })}
          />
        </FieldsetItem>
        <FieldsetItem half>
          <Dropdown
            half
            label={translate('common.end')}
            error={endError}
            hint={translate('workingHoursSheet.hints.end')}
            options={validEndOptions}
            value={hours.end}
            onChange={({ target }) => setHours({ ...hours, end: target.value })}
          />
        </FieldsetItem>

        {autoSchedulingEnabled && (
          <FieldsetItem>
            <p>{translate('workingHoursSheet.notifyScheduleUpdate')}</p>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Button
            disabled={startError || endError || !workhoursChanged}
            full
            primary
            onClick={() => setWorkHours(hours)}
          >
            {translate('common.save')}
          </Button>
        </FieldsetItem>
      </Fieldset>
    </ActionSheet>
  )
}

WorkHoursSheet.propTypes = {
  visible: bool,
  workHours: object,
  setWorkHours: func.isRequired,
  closeWorkHourSheet: func.isRequired,
  autoSchedulingEnabled: bool
}

export default WorkHoursSheet
