import { api } from 'store/api'

export const fetchGoFundExceptions = () => api.get('/go-fund-exceptions')

export const fetchGoFundExceptionForApproval = (exceptionId) =>
  api.get(`/go-fund-exceptions/manager-approval/${exceptionId}`)

export const submitGoFundProgramException = (exception) =>
  api.post(`/go-fund-programs/${exception.programId}/exception`, exception)

export const updateGoFundExceptions = (exception) => {
  return api.patch('/go-fund-exceptions', exception)
}
