// import { createSelector } from 'store/selectors'
import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { isVFUser } from 'store/auth/selectors'
import { customerFromUrl } from 'store/customers/selectors'
import { currentTerritoryName, territoryCustomers, territoryProvinces } from 'store/territories/selectors'

// import { DEFAULT_TTL } from 'utils/constants'

const allPricing = (state) => omit(state.pricings, '_persist')
const upcFromUrl = (state, props) => props.upc

const captureOrderedPricing = createSelector(allPricing, (pricing) =>
  sortBy(Object.values(pricing), 'dashboardPosition')
)
const getProvincePricing = (pricing, prov, visibilityFieldToCheck) =>
  pricing.reduce((acc, { provinces, upc, displayName }) => {
    const provinceDetails = provinces.find(({ province }) => province === prov)
    return provinceDetails && !provinceDetails[visibilityFieldToCheck] ? acc : [...acc, { upc, displayName }]
  }, [])

export const captureOrderedPricingByProvince = createSelector(
  isVFUser,
  territoryProvinces,
  captureOrderedPricing,
  (isVFUser, provinces, pricing) => {
    const vapeChannelPricing = provinces.vape.reduce(
      (acc, prov) => ({
        ...acc,
        [`vape_channel_${prov}`]: getProvincePricing(pricing, prov, 'visibleInVapeChannel')
      }),
      {}
    )
    const visibilityFieldToCheck = isVFUser ? 'visibleForVirtualForce' : 'visibleForField'
    return provinces.general.reduce(
      (acc, prov) => ({
        ...acc,
        [prov]: getProvincePricing(pricing, prov, visibilityFieldToCheck)
      }),
      vapeChannelPricing
    )
  }
)

// function checkCeilingCompliance({ price, priceCeiling, outOfDistribution }) {
//   if (!priceCeiling || outOfDistribution) return 'notEligible'
//   if (!price) return 'captureRequired'
//   return price <= +priceCeiling ? 'compliant' : 'nonCompliant'
// }
// function checkCompetitiveness({ price, competitivePrice, gap }) {
//   if (!price || !competitivePrice) return 'notCompeting'
//   return price - competitivePrice + (+gap || 0) <= 0 ? 'isCompetitive' : 'notCompetitive'
// }

// function getPricingStatus({
//   updatedAt,
//   competitivePrice,
//   priceCeiling,
//   price,
//   expiresAt,
//   gap,
//   ttl,
//   netCustomerPrice,
//   outOfDistribution
// }) {
//   if (!price && !outOfDistribution) return 'noStatus'
//   const isExpired =
//     (moment().isAfter(expiresAt) && moment(updatedAt).isBefore(expiresAt)) || // if last update was before expiry and today is after expiry
//     moment().isAfter(moment(updatedAt).add(ttl || DEFAULT_TTL, 'days')) // last update was more than TTL days ago (specified or default)
//   if (isExpired) return 'expired'
//   if (!priceCeiling && !competitivePrice) return 'notItCan' // Non-ITCAN products will have neither of these
//   const overCeiling = checkCeilingCompliance({ price, priceCeiling, outOfDistribution }) === 'nonCompliant'
//   const notCompetitive = checkCompetitiveness({ price, competitivePrice, gap }) === 'notCompetitive'
//   if (overCeiling || notCompetitive) return 'invalid'
//   return 'valid'
// }

const relevantPricingVisibilityField = createSelector(currentTerritoryName, isVFUser, (territoryName, isVFUser) => {
  if (territoryName?.toLowerCase().includes('vape')) return 'visibleInVapeChannel'
  return isVFUser ? 'visibleForVirtualForce' : 'visibleForField'
})

// const sortAndFilterPricingRequests = (visibilityField, customer, pricingRequests) => {
//   const filteredRequests = Object.values(pricingRequests)
//     .map(({ provinces, ...p }) => {
//       const customerProvince = provinces.find(({ province }) => province === customer.address?.state) || null
//       return !customerProvince || customerProvince[visibilityField] ? { ...p, provinces, customerProvince } : null
//     })
//     .filter(Boolean)
//   return sortBy(filteredRequests, 'dashboardPosition')
// }

// const createUserLatestPricings = (pricingRequests, latestPricings) => {
//   if (!pricingRequests?.length) return []
//   const customerPricings = keyBy(latestPricings, 'upc')
//   const mergedPricing = pricingRequests.map(({ customerProvince, ...p }) => {
//     const { gap, competitorUpc } = customerProvince || {}
//     const { price: competitivePrice } = customerPricings[competitorUpc] || {}
//     const customerPriceObj = customerPricings[p.upc] || {}
//     const margin =
//       customerPriceObj.price && customerPriceObj.netCustomerPrice
//         ? ((customerPriceObj.price - customerPriceObj.netCustomerPrice) / customerPriceObj.price) * 100
//         : ''
//     const profit =
//       customerPriceObj.price && customerPriceObj.netCustomerPrice
//         ? customerPriceObj.price - customerPriceObj.netCustomerPrice
//         : ''
//     const totalProfitForWeek = profit && customerPriceObj.storeAwr ? profit * customerPriceObj.storeAwr : ''
//     const merged = {
//       ...p,
//       ...customerPriceObj,
//       gap,
//       competitivePrice,
//       competitorUpc,
//       margin,
//       profit,
//       totalProfitForWeek
//     }
//     return {
//       ...merged,
//       status: getPricingStatus(merged),
//       ceilingCompliance: checkCeilingCompliance(merged),
//       competitiveness: checkCompetitiveness(merged)
//     }
//   })
//   return keyBy(mergedPricing, 'upc')
// }

const PRICING_STATUS = {
  NON_COMPLIANT: 'nonCompliant',
  NON_COMPETITIVE: 'notCompetitive',
  INVALID: 'invalid',
  VALID: 'valid',
  EXPIRED: 'expired',
  UPDATED: 'updated',
  OUTDATED: 'outdated',
  IN_PROGRESS: 'inProgress',
  NOT_REQUIRED: 'notRequired',
  NO_STATUS: 'noStatus'
}
function getPricingStatus(pricing, capture) {
  // console.log({ pricing, capture, isExpired: moment(capture.createdAt).isBefore(moment(pricing.startDate)) })
  if (!capture || isEmpty(capture) || (!capture.price && !capture.outOfDistribution)) return PRICING_STATUS.NO_STATUS
  if (moment(capture.updatedAt).isBefore(moment(pricing.startDate))) {
    return PRICING_STATUS.EXPIRED
  }
  return PRICING_STATUS.VALID
}

const mergeCustomerPricing = (customer) => {
  if (!customer) return []
  const { retailer, employee, ...others } = groupBy(customer.latestPricings, 'source')

  const retailerCaptures = merge([], ...Object.values(others), retailer)
  const employeeCaptures = merge([], ...Object.values(others), employee)

  const retailerPricingCaptures = keyBy(retailerCaptures, 'upc')
  const employeePricingCaptures = keyBy(employeeCaptures, 'upc')

  const mergedPricings =
    customer.pricingStrategies?.map((pricing) => {
      const employeeCapture = employeePricingCaptures[pricing.upc] || null
      const retailerCapture = retailerPricingCaptures[pricing.upc] || null
      const capture =
        employeeCapture?.updatedAt > retailerCapture?.updatedAt
          ? { ...retailerCapture, ...employeeCapture }
          : { ...employeeCapture, ...retailerCapture }
      const status = getPricingStatus(pricing, capture)
      return { ...pricing, ...capture, status }
    }) || []

  return uniqBy(mergedPricings, (pricing) => pricing.upc)
}

const mergeCustomerRetailerPricing = (customer) => {
  if (!customer) return []
  const { retailer, employee, ...others } = groupBy(customer.latestPricings, 'source')

  const retailerCaptures = merge([], ...Object.values(others), retailer)

  const retailerPricingCaptures = keyBy(retailerCaptures, 'upc')

  const mergedPricings =
    customer.pricingStrategies?.map((pricing) => {
      const retailerCapture = retailerPricingCaptures[pricing.upc] || null
      const status = getPricingStatus(pricing, retailerCapture)
      return { ...pricing, ...retailerCapture, status }
    }) || []

  return uniqBy(mergedPricings, (pricing) => pricing.upc)
}

export const customerPricing = createSelector(customerFromUrl, mergeCustomerPricing)
export const customeRetailerPricing = createSelector(customerFromUrl, mergeCustomerRetailerPricing)

export const totalStoreProfitForWeek = createSelector(customerPricing, (pricing) => {
  return sumBy(pricing, ({ storeAwr }) => storeAwr || 0)
})

export const customerPricingAnalytics = createSelector(customerPricing, (pricing) => {
  const brandGroupedPricing = groupBy(pricing, 'shortBrand')
  return Object.entries(brandGroupedPricing).map(([brand, brandPricings]) => ({
    name: brand,
    onePack20s: brandPricings.find(({ packCount, packSize }) => !packCount && packSize === '20'),
    onePack25s: brandPricings.find(({ packCount, packSize }) => !packCount && packSize === '25'),
    twoPack20s: brandPricings.find(({ packCount, packSize }) => packCount === '2pk' && packSize === '20'),
    twoPack25s: brandPricings.find(({ packCount, packSize }) => packCount === '2pk' && packSize === '25')
  }))
})

export const captureSortedCustomerPricing = createSelector(customerPricing, (pricings) =>
  sortBy(pricings, 'dashboardPosition')
)
const captureIndexOfPricingFromUrl = createSelector(captureSortedCustomerPricing, upcFromUrl, (pricing, upc) =>
  pricing.findIndex((cp) => cp.upc === upc)
)

export const pricingFromUrl = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, index) => pricings[index]
)
export const nextCaptureUpc = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, currentIndex) => {
    const nextPricing =
      pricings[currentIndex + 1] ||
      pricings.find(
        ({ status, outOfDistribution }) => status === 'expired' || (!outOfDistribution && status === 'noStatus')
      )
    return nextPricing ? nextPricing.upc : null
  }
)

export const previousCaptureUpc = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, currentIndex) => {
    if (!currentIndex || !pricings?.length) return ''
    return (pricings[currentIndex - 1] || {}).upc || ''
  }
)

export const capturesRemaining = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, index) => Math.min(pricings.length - index - 1, 3)
)

const getCustomerPricingStatus = ({ pricingNotification, latestPricings }) => {
  const priceNotRequired = pricingNotification?.match(/(non.?requis|not.?required)/i)
  if (priceNotRequired || !latestPricings.length) return PRICING_STATUS.NOT_REQUIRED
  const { true: updated = 0, false: notUpdated = 0 } = countBy(
    latestPricings,
    ({ status }) =>
      status !== PRICING_STATUS.EXPIRED && status !== PRICING_STATUS.NO_STATUS && status !== PRICING_STATUS.INVALID
  )
  if (!updated) return PRICING_STATUS.OUTDATED
  if (!notUpdated) return PRICING_STATUS.UPDATED
  return PRICING_STATUS.IN_PROGRESS
}

// Evaluate EDLP if One SKU from each of the following BRANDs is updated, then consider the store’s EDLP based on the logic in the file.
// const PALL_MALL = 'Pall Mall'
// const LD = 'LD'
// const PHIL_MORRIS_PMI = 'Philip Morris by PMI'
// const edlpQualifyingBrands = [PALL_MALL, LD, PHIL_MORRIS_PMI]

// const getEdlpBrands = (pricingGroup) => {
//   if (!pricingGroup?.length) return []
//   const { price } = minBy(pricingGroup, 'price')
//   return uniq(pricingGroup.filter((p) => p.price === price).map(({ brand }) => brand))
// }

export const territoryPricing = createSelector(
  territoryCustomers,
  allPricing,
  relevantPricingVisibilityField,
  (customers) => {
    const edlps = {}
    // const competitiveCounts = {}
    // const edlpQualifiedCount = 0
    const territoryCustomerPricings = customers
      ? Object.values(customers).map(
          ({ id, name, address, pricingNotification, latestPricings, pricingStrategies }) => {
            // if (!pricingStrategies) return null
            const mergedPricing = pricingStrategies
              ? mergeCustomerPricing({ address, latestPricings, pricingStrategies })
              : []
            const customerPricingStatus = getCustomerPricingStatus({
              pricingNotification,
              latestPricings: mergedPricing
            })
            // const brandGroupedPricing = groupBy(mergedPricing, 'brand')
            // const edlpQualifyingPricings = pick(brandGroupedPricing, edlpQualifyingBrands)
            // const customerIsEdlpQualified =
            //   ['updated', 'inProgress'].includes(customerPricingStatus) &&
            //   Object.values(edlpQualifyingPricings).length &&
            //   Object.values(edlpQualifyingPricings).every((brandPricing) =>
            //     brandPricing.some(({ status }) => status && !['noStatus', 'expired'].includes(status))
            //   )
            // const customerEdlps = {}
            // if (customerIsEdlpQualified) {
            //   edlpQualifiedCount++
            //   const packSizeCountGroupedPricing = groupBy(
            //     mergedPricing.filter((p) => p.price && p.status !== 'expired'),
            //     ({ packSize, packCount }) => [packSize, packCount].filter(Boolean).join('_')
            //   )
            //   customerEdlps.onePack20s = getEdlpBrands(packSizeCountGroupedPricing['20'])
            //   customerEdlps.onePack20s.forEach((brand) => {
            //     const brandCount = edlps[brand] || {}
            //     edlps[brand] = { ...brandCount, onePack20s: (brandCount.onePack20s || 0) + 1 }
            //   })
            //   customerEdlps.onePack25s = getEdlpBrands(packSizeCountGroupedPricing['25'])
            //   customerEdlps.onePack25s.forEach((brand) => {
            //     const brandCount = edlps[brand] || {}
            //     edlps[brand] = { ...brandCount, onePack25s: (brandCount.onePack25s || 0) + 1 }
            //   })
            //   customerEdlps.twoPack20s = getEdlpBrands(packSizeCountGroupedPricing['20_2pk'])
            //   customerEdlps.twoPack20s.forEach((brand) => {
            //     const brandCount = edlps[brand] || {}
            //     edlps[brand] = { ...brandCount, twoPack20s: (brandCount.twoPack20s || 0) + 1 }
            //   })
            //   customerEdlps.twoPack25s = getEdlpBrands(packSizeCountGroupedPricing['25_2pk'])
            //   customerEdlps.twoPack25s.forEach((brand) => {
            //     const brandCount = edlps[brand] || {}
            //     edlps[brand] = { ...brandCount, twoPack25s: (brandCount.twoPack25s || 0) + 1 }
            //   })
            // }

            // const { captureRequired, nonCompliant, compliant } = countBy(mergedPricing, 'ceilingCompliance')

            // if (customerPricingStatus === 'updated') {
            //   mergedPricing.forEach(
            //     ({ upc, price, brand, shortBrand, packSize, packCount, competitiveness, status, customerId }) => {
            //       if (['noStatus', 'expired'].includes(status)) return
            //       const competitivenessKey = [shortBrand, packSize, packCount].filter(Boolean).join('_')
            //       const currentCounts = competitiveCounts[competitivenessKey] || {
            //         isCompetitive: [],
            //         notCompetitive: [],
            //         notCompeting: []
            //       }
            //       competitiveCounts[competitivenessKey] = {
            //         ...currentCounts,
            //         [competitiveness]: uniq(currentCounts[competitiveness].concat(customerId))
            //       }
            //     }
            //   )
            // }

            return {
              id,
              name,
              address,
              // awr,
              pricingNotification,
              // edlpQualified: customerIsEdlpQualified,
              // edlpBrands: customerEdlps,
              // ceilingCompliance: captureRequired
              //   ? 'notEligible'
              //   : nonCompliant
              //   ? 'nonCompliant'
              //   : compliant
              //   ? 'compliant'
              //   : 'notEligible',
              pricingStatus: customerPricingStatus,
              pricing: mergedPricing
            }
          }
        )
      : []

    const customerStatusCounts = countBy(territoryCustomerPricings, 'pricingStatus')
    return {
      customerStatusCounts,
      // edlpQualifiedCount,
      customers: territoryCustomerPricings,
      edlps
      // competitiveCounts
    }
  }
)
