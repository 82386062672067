import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { submitNewPassword } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { match, minPasswordLength, required } from 'utils/validators'

function ResetPassword({ submitNewPassword }) {
  const navigate = useNavigate()
  const { resetToken } = useParams()
  const { translate } = useContext(LangContext)

  const [error, setError] = useState()

  const errorMessages = {
    invalidToken: (
      <GlobalAlert>
        {translate('auth.invalidToken.makeSureLinkCopied')}
        <Link to="/auth/forgot-password">{translate('auth.invalidToken.requestNewToken')}</Link>.
      </GlobalAlert>
    ),
    tokenExpired: (
      <GlobalAlert>
        {translate('auth.tokenExpired.canRequestOne')}
        <Link to="/auth/forgot-password">{translate('auth.tokenExpired.here')}</Link>.
      </GlobalAlert>
    ),
    offlineError: <GlobalAlert>{translate('auth.resetPassword.offlineError')}</GlobalAlert>,
    generic: <GlobalAlert>{translate('app.warn.errorOccuredTryLater')}</GlobalAlert>
  }

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setError()

    try {
      await submitNewPassword({ resetToken, password: values.password }, navigate)
    } catch ({ response }) {
      const resetError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[resetError] || errorMessages.generic)
    } finally {
      setSubmitting(false)
    }
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  const validateConfirmPassword = (values) => {
    const { password, confirmPassword } = values
    return required(confirmPassword) || match(confirmPassword, { password })
  }

  return (
    <Formik initialValues={{ password: '', confirmPassword: '' }} onSubmit={handleFormSubmit}>
      {({ isSubmitting, errors, values }) => {
        return (
          <Form>
            <h3>{translate('auth.chooseNewPassword')}</h3>
            <p>{translate('auth.newPasswordCondition')}</p>
            <Fieldset>
              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder={translate('auth.newPassword')}
                  name="password"
                  type="password"
                  validate={validatePassword}
                  disabled={isSubmitting}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder={translate('auth.confirmPassword')}
                  name="confirmPassword"
                  type="password"
                  validate={validateConfirmPassword(values)}
                  disabled={isSubmitting}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button
                  primary
                  full
                  type="submit"
                  disabled={Object.keys(errors).length > 0 || values.password !== values.confirmPassword}
                  isLoading={isSubmitting}
                >
                  {translate('common.send')}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Link to="/auth/login">
                  <small>{translate('auth.returnToLogin')}</small>
                </Link>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

ResetPassword.propTypes = {
  submitNewPassword: func.isRequired
}

const mapActionCreators = {
  submitNewPassword
}

export default connect(null, mapActionCreators)(ResetPassword)
