import React from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

import { greyLight } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Container = styled.div`
  display: flex;

  & + & {
    margin-top: ${spacing.medium};
  }
`

const Label = styled.label`
  flex-shrink: 0;
  width: 30%;
  color: ${greyLight};
  font-size: ${tinyFontSize};
  margin-top: ${spacing.tiny};
`

const Content = styled.main`
  width: 70%;
  padding-left: ${spacing.medium};
`

const SAQDetailRow = ({ label, content }) => (
  <Container>
    <Label>{label}</Label>
    <Content>{content}</Content>
  </Container>
)

SAQDetailRow.propTypes = {
  label: string.isRequired,
  content: node.isRequired
}

export default SAQDetailRow
