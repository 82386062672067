import React, { useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { array } from 'prop-types'

import Icon from 'components/Icon'

import { cn } from 'utils/styling'

import { popoverContentAnimationClasses } from 'styles/global'

const FAB = ({ items }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuClick = ({ handleClick, ...item }) => {
    if (handleClick) handleClick(item)
    setMenuOpen(false)
  }

  return (
    <div className="fixed bottom-4 right-4 z-50 md:bottom-8 md:right-8">
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="flex h-14 w-14 items-center justify-center rounded-full bg-brand shadow-xl transition-colors hover:bg-brand-700">
            <div className={cn('transition-transform duration-300', menuOpen && 'rotate-[135deg]')}>
              <Icon icon="plus" white />
            </div>
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="bottom"
            align="end"
            sideOffset={16}
            className={cn(
              'z-50 rounded-md bg-white p-3 shadow-xl ring-1 ring-slate-900/10',
              popoverContentAnimationClasses
            )}
          >
            <div className="flex flex-col gap-1">
              {items.map((item, i) => (
                <button
                  className="flex items-center gap-3 rounded px-3 py-2 text-slate-700 transition hover:bg-slate-100 hover:text-slate-900"
                  key={i}
                  onClick={() => handleMenuClick(item)}
                >
                  <Icon icon={item.icon} compact className="shrink-0" />
                  <span>{item.label}</span>
                </button>
              ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

FAB.propTypes = {
  items: array.isRequired
}

export default FAB
