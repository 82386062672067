import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchGeographyInventory, fetchTableFilters } from 'store/inventory/actions'
import { getCurrentFilters, getCurrentGeography } from 'store/inventory/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatCompactNumber, formatNumber } from 'utils/formatters'
import { createDataKey, createOptions, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import InventoryCaptureContext from './InventoryCaptureContext'

const GeographyTable = ({ span, fetchGeographyInventory, fetchTableFilters }) => {
  const { translate } = useContext(LangContext)

  const [error, setError] = useState(null)

  const [geography, setGeography] = useState('region')

  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'oos', order: SORTING_DIRECTION.DESC })

  const defaultFilters = {
    ownership: 'all',
    brand: 'all',
    material: 'all'
  }

  const [localFilters, setLocalFilters] = useState(defaultFilters)

  const COLS = [
    {
      field: 'oos',
      headerName: translate('app.OOS')
    },
    {
      field: 'missedPacks',
      headerName: translate('app.missedPacks')
    },
    {
      field: 'inventoryCapture',
      headerName: translate('app.InvCapture')
    }
  ]

  const { currentProductType: productType } = useContext(SectorContext)
  const { timeframe, vapeCategory } = useContext(InventoryCaptureContext)
  const { sectorId, sectorType } = useParams()

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const filtersDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.FILTERS, {
    sectorType,
    sectorId,
    productType,
    timeframe,
    vapeCategory,
    offset
  })

  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filtersDataKey }))
  const tableFilters = useSelector((state) => getCurrentFilters(state))

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.GEOGRAPHY, {
    sectorType,
    sectorId,
    geography,
    ownership: localFilters.ownership,
    brand: localFilters.brand,
    material: localFilters.material,
    productType,
    timeframe,
    vapeCategory,
    offset,
    sortBy: sortBy.column,
    direction: sortBy.order
  })

  const isRegionLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))

  const geoData = useSelector((state) =>
    getCurrentGeography(state, {
      geography,
      ownership: localFilters.ownership,
      brand: localFilters.brand,
      material: localFilters.material,
      productType,
      vapeCategory,
      timeframe,
      offset,
      sortBy: sortBy.column,
      direction: sortBy.order
    })
  )

  useEffect(() => {
    setPage(1)

    setLocalFilters({ ...localFilters, ownership: 'all', brand: 'all', material: 'all' })

    if (productType === 'fmc' && vapeCategory !== 'all') return

    if (sectorType && sectorId) {
      setError(null)

      fetchTableFilters({ dataKey: filtersDataKey, sectorType, sectorId, productType, timeframe, vapeCategory }).catch(
        (err) => setError(err)
      )
    }
  }, [sectorType, sectorId, timeframe, vapeCategory, productType])

  useEffect(() => {
    if (productType === 'fmc' && vapeCategory !== 'all') return

    if (sectorType && sectorId) {
      setError(null)

      fetchGeographyInventory({
        dataKey: geographyDataKey,
        sectorType,
        sectorId,
        geography,
        ownership: localFilters.ownership,
        brand: localFilters.brand,
        material: localFilters.material,
        productType,
        timeframe,
        vapeCategory,
        offset,
        sortBy: sortBy.column,
        direction: sortBy.order
      }).catch((err) => setError(err))
    }
  }, [sectorType, sectorId, geography, localFilters, productType, timeframe, vapeCategory, offset, sortBy])

  const columns = useMemo(() => {
    if (isRegionLoading || areFiltersLoading || !geoData) return []

    const updatedColumns = COLS.map((col) => ({ ...col, subHeader: timeframe === '1' ? 'vs PW' : 'vs P4' }))
    return [
      {
        field: 'name',
        headerName: translate(displayOptTableLabels(geography))
      },
      ...updatedColumns,
      {
        field: 'trend',
        headerName: translate('app.oosTrend'),
        isLarge: true
      }
    ]
  }, [geoData, geography, isRegionLoading, areFiltersLoading, timeframe])

  const rows = useMemo(() => {
    if (!geoData) return []

    return geoData?.map((row) => ({
      name:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={row.name}
            customerName={row.name}
            erp={row.id}
            address={row.address}
            ownershipType={row.ownership_type}
            linkTo={row.linkTo}
          />
        ) : (
          <GeoTableLineHeader name={row.name} linkTo={row.linkTo} />
        ),
      oos: (
        <DataCompare
          last={formatNumber(row.oos * 100)}
          variation={row.oosDiff * 100}
          reverseVariation
          variationBefore
          isPercent
        />
      ),
      missedPacks: (
        <DataCompare
          last={formatCompactNumber(row.missedPacks, { shouldStripIfInteger: true })}
          variation={row.missedPacksDiff}
          variationBefore
          reverseVariation
        />
      ),
      inventoryCapture: (
        <DataCompare
          last={formatNumber(row.inventoryCapture * 100)}
          variation={row.inventoryCaptureDiff * 100}
          isPercent
          variationBefore
        />
      ),
      trend: (
        <DataGraph
          color="#53CCF8"
          data={row.trendedOos.map((i) => ({ d: i.data * 100 }))}
          dataFormatter={formatNumber}
        />
      )
    }))
  }, [geoData, geography, isRegionLoading, areFiltersLoading, timeframe])

  const hasReachedLastPage = rows?.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const allBrandLabel = productType === 'fmc' ? translate('app.allBrands') : translate('app.allBrandVariants')

  const dataOptions = tableFilters[`${productType}-${vapeCategory}-${timeframe}`]
  const ownershipOptions = createOptions(dataOptions?.ownershipTypes || [])

  const brandsOptions = createOptions(dataOptions?.brands || [])
  const materialOptions = createOptions(dataOptions?.materials || [])

  const handleFilterChange = (updates) => {
    setLocalFilters({ ...localFilters, ...updates })
    setPage(1)
  }

  const geoFilters = useMemo(() => {
    return [
      {
        id: 'ownership',
        label: translate('app.ownership'),
        searchPlaceholder: translate('app.placeholders.searchOwnership'),
        options: [...ownershipOptions],
        value: localFilters.ownership,
        onChange: (value) => handleFilterChange({ ownership: value })
      },
      {
        id: 'brand',
        label: productType === 'fmc' ? translate('app.brands') : translate('app.brandVariant'),
        searchPlaceholder: translate('app.placeholders.searchBrand'),
        options: [...brandsOptions],
        value: localFilters.brand,
        onChange: (value) => handleFilterChange({ brand: value })
      },
      {
        id: 'material',
        label: translate('app.material'),
        searchPlaceholder: translate('app.placeholders.searchMaterial'),
        options: [...materialOptions],
        value: localFilters.material,
        onChange: (value) => handleFilterChange({ material: value })
      }
    ]
  }, [translate, ownershipOptions, brandsOptions, materialOptions, allBrandLabel])

  if (areFiltersLoading) {
    return (
      <Card span={span} title="Region performance">
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          filters={geoFilters}
          onFiltersChange={handleFilterChange}
        />
      ]}
      actions={
        !isRegionLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="invcap-oos-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isRegionLoading}
        error={error}
        rows={rows}
        columns={columns}
        activeColumn={sortBy}
        onColumnClick={handleSort}
        unClickableColumns={['trend']}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchGeographyInventory: func,
  fetchTableFilters: func
}

export default connect(null, {
  fetchGeographyInventory,
  fetchTableFilters
})(GeographyTable)
