import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { func, number, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroGeographyData } from 'store/distro/actions'
import * as api from 'store/distro/endpoints'
import { geographyDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatNumber, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import DistroContext from './DistroContext'

function toFixed(value) {
  return Math.round((value || 0) * 100) / 100
}

const AmplifyDistroTableCard = ({ span, fetchDistroGeographyData, vapeCategory = 'all' }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { vapeChannel } = useContext(DistroContext)

  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'weighteddistro', order: SORTING_DIRECTION.DESC })

  const defaultFilters = {
    ownership: 'all',
    brand: 'all',
    material: 'all'
  }

  const [filters, setFilters] = useState(defaultFilters)

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const { data: filterOptions } = useQuery({
    queryKey: ['distroFilterOptions'],
    queryFn: async () => {
      const { data } = await api.fetchDistroFilterOptions()
      return data
    },
    staleTime: 1000 * 60 * 15 // 15 minutes
  })

  const columns = [
    {
      field: 'geo',
      headerName: translate('app.geo.region')
    },
    selectedLevel !== SECTOR_LEVELS.CUSTOMER && {
      field: 'stores',
      headerName: translate('app.storesNotInDistro')
    },
    selectedLevel !== SECTOR_LEVELS.CUSTOMER && {
      field: 'storesTotal',
      headerName: translate('app.totalStores')
    },
    selectedLevel !== SECTOR_LEVELS.CUSTOMER && {
      field: 'storedistro',
      headerName: translate('app.storeDistro')
    },
    {
      field: 'skus',
      headerName: translate('app.SKUNotInDistro')
    },
    {
      field: 'skusTotal',
      headerName: translate('app.totalSKUs')
    },
    {
      field: 'skudistro',
      headerName: translate('app.SKUDistro')
    },
    ![SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY].includes(selectedLevel) && {
      field: 'weighteddistro',
      headerName: translate('app.wdistro')
    },
    ![SECTOR_LEVELS.CUSTOMER].includes(selectedLevel) && {
      field: 'salescontribution',
      headerName: translate('app.salesContribution')
    },
    geography !== SECTOR_LEVELS.CUSTOMER &&
      selectedLevel !== SECTOR_LEVELS.CUSTOMER && {
        field: 'weightedL13',
        headerName: translate('app.weightedDistroTrend'),
        isLarge: true
      },
    geography !== SECTOR_LEVELS.CUSTOMER &&
      selectedLevel === SECTOR_LEVELS.CUSTOMER && {
        field: 'skuL13',
        headerName: translate('app.SKUDistroTrend'),
        isLarge: true
      }
  ].filter(Boolean)

  columns[0].headerName = translate(displayOptTableLabels(geography))

  const geographyData = useSelector((state) => {
    return geographyDistroData(state, {
      activeProductType: currentProductType,
      geography,
      vapeCategory,
      vapeChannel,
      offset,
      filters,
      sortBy: sortBy.column,
      direction: sortBy.order
    })
  })

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.DISTRO.GEOGRAPHY, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    filters,
    geography,
    offset,
    vapeCategory,
    vapeChannel,
    sortBy: sortBy.column,
    direction: sortBy.order
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    if (currentSector[selectedLevel]?.id) {
      setError(null)
      setPage(1)

      const requestParams = {
        sectorId: currentSector[selectedLevel].id,
        sectorType: selectedLevel,
        geography,
        productType: currentProductType,
        vapeCategory,
        vapeChannel,
        offset,
        filters,
        limit: DEFAULT_TABLE_PAGE_SIZE,
        sortBy: sortBy.column,
        direction: sortBy.order
      }

      fetchDistroGeographyData(requestParams, dataKey).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, geography, vapeCategory, vapeChannel, filters, sortBy])

  useEffect(() => {
    if (currentSector[selectedLevel]?.id) {
      setError(null)

      fetchDistroGeographyData(
        {
          sectorId: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          geography,
          productType: currentProductType,
          vapeCategory,
          vapeChannel,
          offset,
          filters,
          limit: DEFAULT_TABLE_PAGE_SIZE,
          sortBy: sortBy.column,
          direction: sortBy.order
        },
        dataKey
      ).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [offset])

  const graphRows = useMemo(() => {
    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map(
        ({
          id,
          linkTo,
          name,
          customerName,
          erp,
          address,
          ownershipType,
          nbStoresNotInDistro,
          nbStoresTotal,
          storeDistro,
          storeDistroDiff,
          nbSkusTotal,
          nbSkusNotInDistro,
          skuDistro,
          skuDistroDiff,
          salesDistro,
          salesDistroDiff,
          salesContribution,
          salesContributionDiff,
          weightedL13,
          skuL13
        }) => {
          const displayName = geography === 'sku' ? `${name} - ${id}` : name

          return {
            geo:
              geography === SECTOR_LEVELS.CUSTOMER ? (
                <StoreInfoTooltip
                  displayName={displayName}
                  customerName={customerName}
                  erp={erp}
                  address={address}
                  ownershipType={ownershipType}
                  linkTo={linkTo}
                />
              ) : (
                <GeoTableLineHeader name={displayName} linkTo={linkTo} />
              ),
            stores: formatNumber(nbStoresNotInDistro),
            storesTotal: formatNumber(nbStoresTotal),
            storedistro: <DataCompare last={toFixed(storeDistro)} variation={storeDistroDiff} isPercent />,
            skus: formatNumber(nbSkusNotInDistro),
            skusTotal: formatNumber(nbSkusTotal),
            skudistro: <DataCompare last={toFixed(skuDistro)} variation={skuDistroDiff} isPercent />,
            weighteddistro: <DataCompare last={toFixed(salesDistro)} variation={salesDistroDiff} isPercent />,
            salescontribution: (
              <DataCompare last={toFixed(salesContribution)} variation={salesContributionDiff} isPercent />
            ),
            weightedL13: (
              <DataGraph
                color="#53CCF8"
                data={weightedL13}
                dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
              />
            ),
            skuL13: (
              <DataGraph
                color="#53CCF8"
                data={skuL13}
                dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
              />
            )
          }
        }
      )
    }

    return []
  }, [geographyData])

  const allDescriptionLabel =
    currentProductType === 'fmc' ? translate('app.allBVLS') : translate('app.allMaterialDescription')

  const allBrandLabel = currentProductType === 'fmc' ? translate('app.allBrands') : translate('app.allBrandVariants')

  const ownershipOptions = [
    { label: translate('app.DODOIndependent'), value: 'DODO & Independent' },
    { label: translate('app.acronyms.COCO'), value: 'COCO' },
    { label: translate('app.acronyms.CODO'), value: 'CODO' }
  ]

  const brandsOptions = [
    ...(filterOptions && currentProductType === 'fmc'
      ? filterOptions.brands
          .filter((b) => b.english_brand && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_brand, value: b.english_brand }))
      : []),
    ...(filterOptions && currentProductType === 'vape'
      ? filterOptions.brandVariants
          .filter((b) => b.english_variant && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_variant, value: b.english_variant }))
      : [])
  ]

  const materialOptions = [
    ...(filterOptions
      ? filterOptions.skus
          .filter((b) => b.english_name && b.product_type === currentProductType)
          .map((b) => ({ label: b.english_name, value: b.english_name }))
      : [])
  ]

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
    setPage(1)
  }

  const geoFilters = useMemo(() => {
    return [
      {
        id: 'ownership',
        label: translate('app.ownership'),
        searchPlaceholder: translate('app.placeholders.searchOwnership'),
        options: ownershipOptions,
        value: filters.ownership,
        onChange: (value) => handleFilterChange({ ownership: value })
      },
      {
        id: 'brand',
        label: currentProductType === 'fmc' ? translate('app.brands') : translate('app.brandVariant'),
        searchPlaceholder: translate('app.placeholders.searchBrand'),
        options: brandsOptions,
        value: filters.brand,
        onChange: (value) => handleFilterChange({ brand: value })
      },
      {
        id: 'material',
        label: translate('app.material'),
        searchPlaceholder: translate('app.placeholders.searchMaterial'),
        options: materialOptions,
        value: filters.material,
        onChange: (value) => handleFilterChange({ material: value })
      }
    ]
  }, [translate, allBrandLabel, allDescriptionLabel, filterOptions, filters])

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          filters={geoFilters}
          onFiltersChange={handleFilterChange}
        />
      ]}
      actions={
        !isGeographyLoading && (graphRows.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyLoading}
                disableNextButton={graphRows.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyLoading}
        error={error}
        rows={graphRows}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['weightedL13', 'skuL13']}
        columns={columns}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifyDistroTableCard.propTypes = {
  span: number,
  fetchDistroGeographyData: func,
  vapeCategory: string
}

export default connect(null, { fetchDistroGeographyData })(AmplifyDistroTableCard)
