import React, { useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { bool, func, node, string } from 'prop-types'
import tw from 'twin.macro'

import IconButton from 'components/button/IconButton'

const overlayVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const menuVariants = {
  initial: {
    opacity: 0,
    y: 30,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const Header = tw.h2`flex w-full justify-center items-center pb-6 text-lg font-semibold text-slate-900 border-b border-slate-900/10`

const Overlay = ({
  header,
  trigger,
  actions,
  onOpenChange,
  children,
  isOpen,
  hideBackButton,
  onHandleBack,
  onHandleClose,
  preventClosing
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = (params) => {
    if (onOpenChange) {
      onOpenChange(params)
    } else {
      setOpen(!open)
    }
  }

  const handlePointerDownOutside = (e) => {
    if (preventClosing) return e.preventDefault()
  }

  const handlEscapeKeyDown = (e) => {
    if (preventClosing) return e.preventDefault()
  }

  const dialogOpen = open || isOpen

  const preventDefault = (event) => {
    event.preventDefault()
  }

  return (
    <Dialog.Root open={dialogOpen} onOpenChange={handleOpenChange}>
      {/* Trigger */}
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      {/* Portal */}
      <AnimatePresence>
        {dialogOpen && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount asChild>
              <motion.div
                className="fixed left-0 top-0 z-[100] h-full w-full bg-black/60 md:hidden"
                key="overlay"
                variants={overlayVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              />
            </Dialog.Overlay>
            <Dialog.Content
              forceMount
              asChild
              onPointerDownOutside={handlePointerDownOutside}
              onEscapeKeyDown={handlEscapeKeyDown}
              onOpenAutoFocus={preventDefault}
            >
              <motion.div
                className="fixed bottom-0 z-[100] flex h-5/6 max-h-screen w-full flex-col gap-6 overflow-y-auto rounded-t-lg bg-white p-6 shadow-xl focus:outline-none md:hidden"
                key="content"
                variants={menuVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              >
                {!preventClosing && (
                  <Dialog.Close asChild>
                    <button className="absolute right-3 top-5 h-9 w-9">
                      <IconButton icon="close" ghost onClick={onHandleClose || null} aria-label="Close drawer" />
                    </button>
                  </Dialog.Close>
                )}
                {!hideBackButton && (
                  <button className="absolute left-3 top-5 h-9 w-9">
                    <IconButton
                      icon="left-chevron"
                      ghost
                      onClick={onHandleBack || null}
                      medium
                      aria-label="Close drawer"
                    />
                  </button>
                )}
                <Header>{header}</Header>
                {children}
                {actions && <div className="mt-4 space-y-4">{actions}</div>}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

Overlay.propTypes = {
  header: string.isRequired,
  children: node.isRequired,
  trigger: node.isRequired,
  onOpenChange: func,
  actions: node,
  isOpen: bool,
  onHandleClose: func,
  preventClosing: bool,
  hideBackButton: bool,
  onHandleBack: func
}

export default Overlay
