import React from 'react'
import { func, string } from 'prop-types'

const PricingCardOverlay = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="min-w-screen fixed left-0 top-0 z-50 flex h-screen min-h-screen w-screen items-center justify-center bg-black/65"
    >
      {children}
    </div>
  )
}

PricingCardOverlay.propTypes = {
  children: string,
  onClick: func
}

export default PricingCardOverlay
