import { addEntities, mergeEntities, setEntities } from 'store/actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.cycleCampaign
  }),
  [setEntities]: (state, action) => {
    if (!action.payload.cycleCampaign) return state
    return Object.entries(action.payload.cycleCampaign).reduce((mergedCampaigns, [id, campaign]) => {
      return {
        ...mergedCampaigns,
        [id]: {
          ...(state[id] || {}),
          ...campaign
        }
      }
    }, {})
  },
  [mergeEntities]: (state, action) => {
    if (!action.payload.cycleCampaigns) return state
    return Object.assign({}, state, {
      ...action.payload.cycleCampaigns
    })
  }
}

export const addToSectorReducer = (state, { payload: { cycleCampaigns, id } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      cycleCampaigns
    }
  })
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
