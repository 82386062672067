import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningSellin = (params) => api.get(`/sellin`, { params })

export const fetchOrderCompletion = ({ id, sectorLevel }) =>
  api.get(
    `/sellin/order-completion?${queryString.stringify(
      {
        id,
        sectorLevel
      },
      { skipNull: true }
    )}`
  )

export const fetchSellinRunRate = ({ id, sectorLevel, activeProductType, vapeCategory, unitOfMeasure }) =>
  api.get(
    `/sellin/run-rate?${queryString.stringify(
      {
        id,
        sectorLevel,
        type: activeProductType,
        vapeCategory,
        unitOfMeasure
      },
      { skipNull: true }
    )}`
  )

export const fetchSellinGeography = (params) => api.get(`/sellin/geography`, { params })
