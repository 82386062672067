import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import { array, bool, func, node, object, string } from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components'

import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import FormImageInput from 'components/FormImageInput'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Label from 'components/Label'
import MultiSelect from 'components/MultiSelect'

import { SOX_OPTIONS } from 'utils/constants'
import { maxValue, minValue } from 'utils/validators'

import { secondaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontRegular, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  width: 100%;
`

const ViewAttachment = styled.a`
  display: inline-block;
  margin-left: ${spacing.small};
  font-size: ${tinyFontSize};
  color: ${secondaryColor};
  font-weight: ${fontRegular};
  text-decoration: underline;
`

const VerificationStatus = ({ verificationStatus, comments, children }) => {
  if (!verificationStatus) return children
  if (verificationStatus?.toUpperCase() === 'PASS') {
    return (
      <GlobalAlert success>
        <Icon icon="checkmark-small" />
        <span>Your answer has been verified</span>
        {children}
      </GlobalAlert>
    )
  }
  if (verificationStatus?.toUpperCase() === 'RESUBMITTED') {
    return (
      <GlobalAlert warning>
        <Icon icon="warning" />
        <span>Your answer has been resubmitted</span>
        {children}
      </GlobalAlert>
    )
  }
  return (
    <GlobalAlert>
      <Icon icon="close" />
      <span>{comments}</span>
      {children}
    </GlobalAlert>
  )
}

VerificationStatus.propTypes = {
  verificationStatus: string,
  comments: string,
  children: node
}

const SoxField = ({ question, controlAnswer, answers, isLocked }) => {
  if (!answers?.length) return null
  return (
    <Fieldset>
      {sortBy(answers, 'serialNumber').map((answer) => {
        const disabled =
          (isLocked && answer.verificationStatus?.toUpperCase() !== 'FAIL') ||
          answer.verificationStatus?.toUpperCase() === 'PASS'
        return (
          <FieldsetItem key={answer.serialNumber}>
            <VerificationStatus verificationStatus={answer.verificationStatus} comments={answer.comments}>
              <Label>Serial #{answer.serialNumber}</Label>
              <Field
                component={Dropdown}
                name={`${question.id}.ans_${answer.id}.text`}
                options={SOX_OPTIONS}
                placeholder="Select a response..."
                disabled={disabled}
              />
              <div style={{ marginTop: spacing.small }}>
                <Field component={FormImageInput} name={`${question.id}.ans_${answer.id}.imgs`} disabled={disabled} />
              </div>
            </VerificationStatus>
          </FieldsetItem>
        )
      })}
    </Fieldset>
  )
}

SoxField.propTypes = {
  isLocked: bool,
  controlAnswer: string,
  question: object.isRequired,
  answers: array.isRequired
}

const TextInput = ({ question, controlAnswer, isLocked }) => {
  return <Field component={Input} name={question.id} disabled={isLocked} />
}

TextInput.propTypes = {
  isLocked: bool,
  question: object.isRequired,
  controlAnswer: string
}

const NumberInput = ({ question, controlAnswer, isLocked }) => {
  const minValidator = useMemo(() => minValue(question.meta?.min), [question?.meta])
  const maxValidator = useMemo(() => maxValue(question.meta?.max), [question?.meta])

  return (
    <Field
      component={Input}
      name={question.id}
      type="number"
      {...(question.meta || null)}
      validate={[minValidator, maxValidator]}
      disabled={isLocked}
    />
  )
}

NumberInput.propTypes = {
  isLocked: bool,
  question: object.isRequired,
  controlAnswer: string
}

const MultiSelectField = ({ question, controlAnswer, isLocked }) => {
  return (
    <Field
      disabled={isLocked}
      component={MultiSelect}
      name={question.id}
      options={question.options.map(({ id, label }) => ({ label, value: id }))}
    />
  )
}

MultiSelectField.propTypes = {
  isLocked: bool,
  question: object.isRequired,
  controlAnswer: string
}

const DropdownField = ({ question, controlAnswer, isLocked }) => {
  return (
    <Field
      disabled={isLocked}
      component={Dropdown}
      name={question.id}
      options={question.options.map(({ id, label }) => ({ label, value: id }))}
      placeholder="Select a response..."
    />
  )
}

DropdownField.propTypes = {
  isLocked: bool,
  question: object.isRequired,
  controlAnswer: string
}

const ImageInputField = ({ question, controlAnswer, isLocked }) => {
  return <Field component={FormImageInput} name={question.id} disabled={isLocked} />
}

ImageInputField.propTypes = {
  isLocked: bool,
  question: object.isRequired,
  controlAnswer: string
}

const getAnswerInput = (type) => {
  const defaultAnswerInputsByType = {
    text: TextInput,
    number: NumberInput,
    multi: MultiSelectField,
    dropdown: DropdownField,
    images: ImageInputField,
    sox: SoxField
  }
  return defaultAnswerInputsByType[type]
}

const RenderSurveyQuestions = ({ questions, answers = [], name, formValues, change, isLocked }) => {
  return (
    <Container>
      {questions.map((question) => {
        const SurveyInput = getAnswerInput(question.type)
        const controlAnswer = question.controlQuestionId && formValues && formValues[question.controlQuestionId]
        if (question.controlAnswerId && controlAnswer !== question.controlAnswerId) {
          return null
        }
        const questionAnswers = answers.filter(({ questionId }) => questionId === question.id)
        if (question.type === 'sox' && !questionAnswers?.length) return null

        return (
          <FieldsetItem key={question.id}>
            <Label>
              {question.question}
              {question.asset?.link && (
                <ViewAttachment target="_blank" href={question.asset.link}>
                  View attachment
                </ViewAttachment>
              )}
            </Label>
            <SurveyInput
              question={question}
              answers={questionAnswers}
              controlAnswer={controlAnswer}
              isLocked={isLocked}
            />
          </FieldsetItem>
        )
      })}
    </Container>
  )
}
RenderSurveyQuestions.propTypes = {
  questions: array.isRequired,
  answers: array,
  name: string,
  formValues: object,
  change: func,
  isLocked: bool
}

export default RenderSurveyQuestions
