import { api } from 'store/api'

export const login = (credentials) => api.post('/auth', credentials)
export const logout = () => api.delete('/auth')
export const signup = (credentials) => api.post('/employees', credentials)
export const requestResetToken = (email) => api.post('/auth/forgot-password', { email })
export const resetPassword = (resetDetails) => api.post('/auth/reset-password', resetDetails)

export const fetchUser = (lastFetch) => api.get(`/employees/me${lastFetch ? '?lastFetch=' + lastFetch : ''}`)
export const updateUserSettings = (settings) => api.put('/employees/me', settings)
export const verifySignup = (registrationToken) => api.put('/employees/verify', { registrationToken })

export const verifyRetailer = () => api.get(`/customers/me`)
export const saveDelegation = (startDate, endDate, delegates) =>
  api.put('/employees/me/delegations', {
    startDate,
    endDate,
    delegates
  })

export const getManagerSuggestions = (match) => {
  return api.get('/employees/suggest-manager', { params: match ? new URLSearchParams({ match }) : undefined })
}

export const fetchEmployeeCalendar = () => api.get(`/employees/me/calendar`)
