// Note: Style template code.

import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bool, func, node, number, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchDistroSummaryData } from 'store/distro/actions'
import { summaryDistroData } from 'store/distro/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage, parseNumberString } from 'utils/helpers'

import DistroContext from './DistroContext'

const Header = tw.span`text-sm font-semibold text-slate-500`

const VerticalLine = tw.div`w-px h-20 bg-slate-200`

const DataL = ({ header, children, variation }) => (
  <div className="flex w-full grow flex-col items-center space-y-3">
    <Header>{header}</Header>
    <div className="flex flex-col items-center space-y-2">
      <span className="text-2xl font-medium text-slate-900 @[12rem]/card:text-4xl @lg/card:text-5xl">{children}</span>
      {variation != null && (
        <div className="flex items-center space-x-2">
          <VariationIndicator variation={+variation} />
          <span className="text-2xs font-medium text-slate-500">{parseNumberString(Math.abs(variation))}% vs PW</span>
        </div>
      )}
    </div>
  </div>
)

DataL.propTypes = {
  header: string,
  children: node,
  variation: number
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-2">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const SpinnerContainer = tw.div`flex justify-center items-center w-full h-full`

const AmplifyDistroCard = ({ fetchDistroSummaryData, span, showCustomerView, vapeCategory = 'all' }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const { vapeChannel } = useContext(DistroContext)

  const [error, setError] = useState(null)
  const activeProductType = currentProductType

  const distroData = useSelector((state) =>
    summaryDistroData(state, {
      activeProductType,
      vapeCategory,
      vapeChannel
    })
  )

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.DISTRO.SUMMARY, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: activeProductType,
    vapeCategory,
    vapeChannel
  })

  useEffect(() => {
    if (currentSector[selectedLevel]?.id) {
      setError(null)

      fetchDistroSummaryData(
        {
          sectorId: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          geography: selectedLevel,
          productType: currentProductType,
          vapeCategory,
          vapeChannel
        },
        dataKey
      ).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, activeProductType, vapeCategory, vapeChannel])

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  if (error) {
    return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />
  }

  const cardProps = {
    title: translate('app.distro'),
    span,
    displayAmplify: true
  }

  if (isLoading) {
    return (
      <Card {...cardProps}>
        <SpinnerContainer>
          <Spinner icon="spinner" />
        </SpinnerContainer>
      </Card>
    )
  }

  if (isEmpty(distroData)) {
    return (
      <Card {...cardProps}>
        <EmptyState title={translate('app.warn.noData')} />
      </Card>
    )
  }

  const { salesDistroDiff, salesDistro, storeDistro, storeDistroDiff, skuDistro, skuDistroDiff } = distroData

  return (
    <Card {...cardProps}>
      <div className="flex h-full flex-col">
        {showCustomerView ? (
          <div className="mb-8 flex grow items-center">
            <DataL header={translate('app.acronyms.SKUs')}>{formatPercent(skuDistro, { nullDisplay: '0%' })}</DataL>
          </div>
        ) : (
          <>
            <div className="mb-8 flex grow items-center">
              <DataL header={translate('app.wdistro')} variation={salesDistroDiff || 0}>
                {formatPercent(salesDistro, { nullDisplay: '0%' })}
              </DataL>
            </div>
            <div className="flex items-center">
              <div className="w-full">
                <DataSm header={translate('app.stores')}>
                  <span className="text-2xl font-medium text-slate-900">
                    {formatPercent(storeDistro, { nullDisplay: '0%' })}
                  </span>
                  {storeDistroDiff != null && (
                    <div className="flex items-center space-x-2">
                      <VariationIndicator variation={storeDistroDiff} />
                      <span className="text-2xs font-medium text-slate-500">
                        {storeDistroDiff > 0 && '+'}
                        {parseNumberString(Math.abs(storeDistroDiff))}
                        {translate('app.percentPW')}
                      </span>
                    </div>
                  )}
                </DataSm>
              </div>

              <VerticalLine />
              <div className="w-full">
                <DataSm header={translate('app.acronyms.SKUs')}>
                  <span className="text-2xl font-medium text-slate-900">
                    {formatPercent(skuDistro, { nullDisplay: '0%' })}
                  </span>
                  {skuDistroDiff != null && (
                    <div className="flex items-center space-x-2">
                      <VariationIndicator variation={skuDistroDiff} />
                      <span className="text-2xs font-medium text-slate-500">
                        {skuDistroDiff > 0 && '+'}
                        {parseNumberString(skuDistroDiff)}
                        {translate('app.percentPW')}
                      </span>
                    </div>
                  )}
                </DataSm>
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  )
}

AmplifyDistroCard.propTypes = {
  fetchDistroSummaryData: func,
  span: number,
  vapeCategory: string,
  showCustomerView: bool
}

const mapActionCreators = {
  fetchDistroSummaryData
}

export default connect(null, mapActionCreators)(AmplifyDistroCard)
