import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { bool, func, object } from 'prop-types'
import CustomerCallsLogs from 'views/Customer/CallLogs'
import CustomerOpeningHours from 'views/Customer/CustomerOpeningHours'

import ActiveCallContext from 'context/ActiveCallContext'
import LangContext from 'context/LangContext'

import { canUserStartCall } from 'store/auth/selectors'

import Button from 'components/button/Button'
import { CustomerInfoSheet } from 'components/CustomerCard'
import { Modal } from 'components/Modal'

const StoreInfoModal = ({ open, onOpenChange, customer }) => {
  const { translate } = useContext(LangContext)
  const { showStartCallScreen, ongoingCall } = useContext(ActiveCallContext)

  const canStartCall = useSelector((state) => canUserStartCall(state, { customer }))

  const startCall = () => {
    onOpenChange(false)
    showStartCallScreen({ customerId: customer.id, call: { customer } })
  }

  return (
    <Modal
      title={translate('app.storeInfo')}
      open={open}
      onOpenChange={onOpenChange}
      onHandeClose={() => onOpenChange(false)}
    >
      <div className="space-y-6">
        <CustomerInfoSheet customer={customer} />
        <div className="space-y-2">
          <h1>{translate('app.callLog')}</h1>
          <CustomerCallsLogs closeModal={() => onOpenChange(false)} />
        </div>
        <div className="space-y-2">
          <h1>{translate('common.openingHours')}</h1>
          <CustomerOpeningHours customerId={+customer.id} customerHours={customer.openingHours} />
        </div>
      </div>
      {!ongoingCall && canStartCall && (
        <div className="mt-4">
          <Button full primary onClick={startCall}>
            {translate('common.call')} {customer.name}
          </Button>
        </div>
      )}
    </Modal>
  )
}

StoreInfoModal.propTypes = {
  open: bool,
  onOpenChange: func,
  customer: object
}

export default StoreInfoModal
