import React from 'react'
import { bool, string } from 'prop-types'
import styled from 'styled-components'
import InlineSVG from 'svg-inline-react'

import { greyLight, primaryColor, secondaryColor, white } from 'styles/colors'

const getFill = (props) => {
  if (props.color) return props.color
  if (props.white) return white
  if (props.primary) return primaryColor
  if (props.accent) return secondaryColor
  if (props.light) return greyLight
  return 'currentColor'
}

const getSpacing = ({ small, medium, compact, caret }) => {
  if (caret) return '10px'
  if (small) return '16px'
  if (compact) return '20px'
  if (medium) return '32px'
  return '24px'
}

export const Container = styled(({ white, accent, light, caret, small, medium, compact, color, left, ...rest }) => (
  <InlineSVG {...rest} />
))`
  display: inline-block;
  fill: ${getFill};
  width: ${getSpacing};
  height: ${getSpacing};
`

const Icon = ({ icon, ...rest }) => <Container {...rest} src={require(`static/icons/${icon}.svg`)} raw />

Icon.propTypes = {
  icon: string.isRequired,
  white: bool,
  light: bool,
  caret: bool,
  small: bool,
  medium: bool,
  compact: bool,
  color: string
}

export default Icon
