import React, { useState } from 'react'
import { bool, func, number } from 'prop-types'

import Icon from 'components/Icon'

import { cn } from 'utils/styling'

const NumberInput = ({
  value,
  onChange,
  allowNegativeValues = false,
  size = 'default',
  layout = 'default',
  fieldWidth = 'default',
  fadeZero = false,
  disabled = false
}) => {
  const [internalValue, setInternalValue] = useState(value || 0)

  // Sync internal state when prop value changes
  React.useEffect(() => {
    setInternalValue(value)
  }, [value])

  const increaseValue = () => {
    const newValue = internalValue + 1

    setInternalValue(newValue)
    onChange(newValue)
  }

  const decreaseValue = () => {
    const newValue = allowNegativeValues ? internalValue - 1 : Math.max(0, internalValue - 1)

    setInternalValue(newValue)
    onChange(newValue)
  }

  // Prevent decimal, exponential notation, and other invalid inputs from being entered even though the browser allows it temporarily
  const handleKeyDown = (event) => {
    const invalidKeys = ['.', 'e', '+', '-']

    if (invalidKeys.includes(event.key)) {
      event.preventDefault()
    }
  }

  const handleChange = (event) => {
    const newValue = event.target.value

    // Ensure the input value is numeric and handle leading zeros
    if (/^\d*$/.test(newValue)) {
      if (newValue === '') {
        setInternalValue('')
        onChange(0) // Optionally reset to 0 when the input is empty
      } else if (newValue === '0' && internalValue === 0) {
        // Allow a single zero
        setInternalValue(0) // Keep it numeric
        onChange(0)
      } else {
        // Remove leading zeros for numbers greater than 0
        const cleanValue = newValue.replace(/^0+/, '')
        const numericValue = cleanValue === '' ? 0 : Number(cleanValue)
        setInternalValue(numericValue)
        onChange(numericValue)
      }
    }
  }

  const handleClick = () => {
    if (value === 0) {
      setInternalValue('')
    }
  }

  const handleBlur = () => {
    if (internalValue === '') {
      setInternalValue(0)
      onChange(0) // Reset to 0 on blur if the input is empty
    }
  }

  const isModifersEndLayout = layout === 'modifiersEnd'

  // Disable arrows on number input
  const removeArrows =
    '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'

  return (
    <div
      className={cn(
        'flex items-center rounded-md ring-1 ring-slate-900/10 focus-within:ring-2 focus-within:ring-inherit',
        size === 'compact' ? 'h-6' : 'h-9',
        fieldWidth === 'full' && 'w-full',
        disabled && ' opacity-60'
      )}
    >
      <input
        type="text"
        className={cn(
          'z-[1] h-full border-none bg-transparent px-0.5 pb-0.5 pt-0 text-center text-sm text-slate-900 focus:ring-transparent',
          removeArrows,
          isModifersEndLayout ? 'order-1' : 'order-2',
          internalValue === 0 && fadeZero && 'text-slate-500',
          fieldWidth === 'small' && 'w-8',
          fieldWidth === 'default' && 'w-12',
          fieldWidth === 'large' && 'w-16',
          fieldWidth === 'full' && 'w-full'
        )}
        value={internalValue}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onClick={handleClick}
        onBlur={handleBlur}
        disabled={disabled}
      />
      <div
        className={cn(
          'z-0 flex cursor-pointer items-center justify-center border-slate-900/10 text-slate-500 transition-colors duration-150 hover:bg-slate-50 hover:text-slate-900 active:bg-slate-100',
          size === 'compact' ? 'size-6' : 'size-9 ',
          isModifersEndLayout ? 'order-2 border-l' : 'order-1 rounded-l-[7px] border-r',
          disabled && ' pointer-events-none'
        )}
        onClick={decreaseValue}
      >
        <Icon icon="minus-small" compact />
      </div>
      <div
        className={cn(
          'z-0 order-3 flex cursor-pointer items-center justify-center rounded-r-[7px] border-l border-slate-900/10 text-slate-500 transition-colors duration-150 hover:bg-slate-50 hover:text-slate-900 active:bg-slate-100',
          size === 'compact' ? 'size-6' : 'size-9 ',
          disabled && ' pointer-events-none'
        )}
        onClick={increaseValue}
      >
        <Icon icon="plus-small" compact />
      </div>
    </div>
  )
}

NumberInput.propTypes = {
  allowNegativeValues: bool,
  size: 'default' | 'compact',
  layout: 'default' | 'modifiersEnd',
  fieldWidth: 'small' | 'default' | 'large' | 'full',
  value: number,
  onChange: func,
  fadeZero: bool,
  disabled: bool
}

export default NumberInput
