import { denormalize } from 'normalizr'
import { createSelector } from 'reselect'

import { goFundProgram as GoFundProgramSchema } from 'store/schema'

import { getProgramStatusAndFund } from 'utils/helpers'

const fullState = (state) => state

export const programIdFromUrl = (state, props) => props.programId

const targetIdFromUrl = (state, props) => props.targetId

export const programFromUrl = createSelector(programIdFromUrl, fullState, (programId, state) => {
  const {
    goFundPrograms,
    auth: { user }
  } = state

  const goFundProgram =
    goFundPrograms[programId] || Object.values(goFundPrograms).find(({ tempId }) => programId === tempId)
  if (goFundProgram) {
    const denormalizedGoFundProgram = denormalize(goFundProgram, GoFundProgramSchema, state)

    return getProgramStatusAndFund(denormalizedGoFundProgram.goFund, denormalizedGoFundProgram, user.preferredLanguage)
  }
})

export const goProgramTargetFromUrl = createSelector(programFromUrl, targetIdFromUrl, (program, targetId) => {
  return program.targets.find((target) => target.id === +targetId)
})
