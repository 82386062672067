import React, { useEffect, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import config from 'config'
import { node } from 'prop-types'

const GoogleMapsContext = React.createContext()
export default GoogleMapsContext

export const GoogleMapsProvider = ({ children }) => {
  const [gMapsLoaded, setGMapsLoaded] = useState(false)
  const [googleMaps, setGoogleMaps] = useState()

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.googleMapsKey,
      libraries: ['places']
    })
    loader
      .load()
      .then((google) => {
        // console.log(google)
        const geocoder = new google.maps.Geocoder()
        const distanceMatrix = new google.maps.DistanceMatrixService()
        const AutoComplete = google.maps.places.Autocomplete
        const createLatLng = ({ latitude, longitude }) => new google.maps.LatLng(latitude, longitude)

        setGoogleMaps({ geocoder, distanceMatrix, createLatLng, AutoComplete, lib: google.maps })
        setGMapsLoaded(true)
      })
      .catch((e) => {
        console.log('ERROR LOADING GOOGLE MAPS')
        console.error(e)
        // do something
      })
  }, [])

  // Google is filtering out valid "street_address" type results that have a street number when route is excluded.
  // "route" type results included in "address" type do not include a street number.
  // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
  const initAutoComplete = (addressField, updateAddress) => {
    if (!gMapsLoaded || !googleMaps.AutoComplete) return
    const autoCompleteObj = new googleMaps.AutoComplete(addressField, {
      componentRestrictions: { country: ['ca'] },
      fields: ['address_components', 'geometry', 'formatted_address'],
      types: ['address'] // ['street_address', 'premise', 'subpremise']
    })
    return autoCompleteObj
  }

  async function geocodeAddress(address) {
    if (!address) return
    const addressString = address.address || Object.values(address).join(', ')
    let { results } = await googleMaps.geocoder.geocode({ address: addressString })
    const line1 = address.line_1 || address.line1
    const postCode = address.postCode || address.post_code

    if (!results.length && line1 && postCode) {
      const indexOfUnitNumber = line1.indexOf('-')
      const realLine1 = line1.substring(indexOfUnitNumber + 1).trim()
      const response = await googleMaps.geocoder.geocode({ address: [realLine1, address.postCode].join(', ') })
      results = response.results
    }
    if (!results.length) return null
    return results[0].geometry.location.toJSON()
  }

  async function getGoogleMapsDistance(from, to) {
    try {
      const { rows } = await googleMaps.distanceMatrix.getDistanceMatrix({
        origins: [from],
        destinations: [to],
        travelMode: googleMaps.lib.TravelMode.DRIVING,
        unitSystem: googleMaps.lib.UnitSystem.METRIC,
        drivingOptions: {
          departureTime: new Date()
        }
      })
      if (rows[0]?.elements[0]?.status === 'OK') return rows[0].elements[0].distance.value
    } catch (err) {
      console.error(err)
    }
    return null
  }

  const getDrivingDistance = async ({ from, to }) => {
    const fromGeocode = from.latitude && from.longitude ? googleMaps.createLatLng(from) : await geocodeAddress(from)
    const toGeocode = to.latitude && to.longitude ? googleMaps.createLatLng(to) : await geocodeAddress(to)
    if (!fromGeocode || !toGeocode) return null
    const distanceInMetres = await getGoogleMapsDistance(fromGeocode, toGeocode)
    return distanceInMetres
  }

  return (
    <GoogleMapsContext.Provider value={{ getDrivingDistance, initAutoComplete, gMapsLoaded }}>
      {children}
    </GoogleMapsContext.Provider>
  )
}

GoogleMapsProvider.propTypes = {
  children: node.isRequired
}
