import { addEntities, mergeEntities } from 'store/actions'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToNation,
  addAmplifyExtraHubEngagementValuesToNation,
  addAmplifyExtraHubFiltersValuesToNation,
  addAmplifyExtraHubGeographyValuesToNation,
  addAmplifyExtraHubTrendingValuesToNation,
  addAmplifyInventoryOosBrandsValuesToNation,
  addAmplifyInventoryOosFiltersValuesToNation,
  addAmplifyInventoryOosGeographyValuesToNation,
  addAmplifyInventoryOosMaterialValuesToNation,
  addAmplifyInventoryOosTrendingValuesToNation,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation,
  addAmplifyPriceCaptureComplianceFiltersValuesToNation,
  addAmplifyPriceCaptureComplianceFootprintValuesToNation,
  addAmplifyPriceCaptureComplianceGeographyValuesToNation,
  addAmplifyPriceComplianceEdlpComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyComplianceValuesToNation,
  addAmplifyPriceComplianceGeographyFiltersValuesToNation,
  addAmplifyPriceComplianceStrategyComplianceValuesToNation,
  addAmplifySelloutGeographyValuesToNation,
  addAmplifySelloutValuesToNation,
  addDistroBrandDataToNation,
  addDistroGeographyDataToNation,
  addDistroMaterialDataToNation,
  addDistroSummaryDataToNation,
  addDistroTrendedDataToNation,
  addPlanningCycleCampaignValuesToNation,
  addPlanningEngagementValuesToNation,
  addPlanningHealthCheckValuesToNation,
  addPlanningPriceCheckValuesToNation,
  addPlanningSellinValuesToNation,
  addPlanningSelloutValuesToNation,
  addSellinGeographyToNation,
  addSellinOrderCompletionToNation,
  addSellinRunRateToNation
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.nations) return state

    return Object.entries(payload.nations).reduce((acc, [id, nation]) => {
      return Object.assign(acc, {
        [id]: nation
      })
    }, Object.assign({}, state))
  },
  [mergeEntities]: (state, { payload }) => {
    if (!payload.nations) return state
    return Object.entries(payload.nations).reduce((acc, [id, nation]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...nation
        }
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToNation]: (state, { payload: { id, activeProductType, vapeCategory, unitOfMeasure, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addSellinGeographyToNation]: (
    state,
    {
      payload: {
        sectorId: id,
        currentProductType,
        geography,
        period,
        range,
        vapeCategory,
        unitOfMeasure,
        offset,
        result
      }
    }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${unitOfMeasure}-${range}-${offset}`]:
          result
      }
    })
  },
  [addSellinOrderCompletionToNation]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion`]: result
      }
    })
  },
  [addDistroTrendedDataToNation]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}-${vapeCategory}-${vapeChannel}`]: result
      }
    })
  },
  [addDistroBrandDataToNation]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`]: result
      }
    })
  },
  [addDistroGeographyDataToNation]: (
    state,
    { payload: { sectorId: id, productType, geography, vapeCategory, vapeChannel, offset, result, sortBy, direction } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${productType}-${geography}-${vapeCategory}-${vapeChannel}-offset-${offset}-${sortBy}-${direction}`]:
          result
      }
    })
  },
  [addDistroMaterialDataToNation]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`]: result
      }
    })
  },
  [addDistroSummaryDataToNation]: (
    state,
    { payload: { sectorId: id, productType, vapeCategory, vapeChannel, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-summary-${productType}-${vapeCategory}-${vapeChannel}`]: result
      }
    })
  },
  [addPlanningSellinValuesToNation]: (state, { payload: { id, result, type, vapeCategory, unitOfMeasure } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${type}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToNation]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToNation]: selloutReducer.mergeMain,
  [addAmplifySelloutGeographyValuesToNation]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToNation]: (
    state,
    { payload: { id, productType, result, timeframe, vapeCategory, vapeChannel } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${productType}-${vapeCategory}-timeframe-${timeframe}-${vapeChannel}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToNation]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToNation]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },
  [addPlanningCycleCampaignValuesToNation]: (
    state,
    { payload: { sectorId: id, campaignId, cycleCampaign: result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`planning-cycle-planning-campaign-${campaignId}`]: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToNation]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToNation]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToNation]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToNation]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToNation]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToNation]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToNation]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToNation]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToNation]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToNation]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToNation]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToNation]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToNation]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToNation]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToNation]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToNation]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToNation]: priceCaptureCompliance.priceComplianceGeographyFilters
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
