import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { secondaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'

const ShowMore = styled.button`
  color: ${secondaryColor};
  padding-left: ${spacing.small};
`

const CollapseText = ({ text, ellipsis = '...', maxLength = 50 }) => {
  const [isTruncated, setIsTruncated] = useState(true)

  const toggle = () => {
    setIsTruncated(!isTruncated)
  }

  const truncatedText = useMemo(() => {
    return text.slice(0, maxLength).split(' ').slice(0, -1).join(' ')
  }, [maxLength, text])

  if (text.length <= maxLength) {
    return <span>{text}</span>
  } else {
    return (
      <span>
        {isTruncated && truncatedText + ellipsis}
        {!isTruncated && text}
        <ShowMore onClick={toggle}>{isTruncated ? 'More' : 'Hide'}</ShowMore>
      </span>
    )
  }
}

CollapseText.propTypes = {
  text: PropTypes.string.isRequired,
  ellipsis: PropTypes.string,
  maxLength: PropTypes.number
}

export default CollapseText
