import React from 'react'
import { stripUnit } from 'polished'
import { bool, func, number, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { setForegroundColor } from 'utils/helpers'

import { greyDark, primaryColor, white } from 'styles/colors'
import { boxShadow, containerMaxWidth, headerHeight } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

import GlobalHeaderContent from './GlobalHeader'
import SearchHeaderContent from './SearchHeader'
import SubHeaderContent from './SubHeader'

const Container = styled.header`
  position: sticky;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  height: ${headerHeight};
  background-color: ${(props) => props.color || primaryColor};
  padding-left: ${spacing.medium};
  padding-right: ${spacing.medium};
  ${boxShadow};

  @media print {
    display: none;
  }
`

const SubHeaderContainer = styled(Container)`
  z-index: 401;
  top: ${(props) => stripUnit(headerHeight) * props.level + 'px'};
  background-color: ${(props) => props.color || primaryColor};
`
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;

  ${(props) =>
    !props.allowFullWidth &&
    media.breakpoint`
      max-width: ${containerMaxWidth};
    `};
`

export const BackButton = ({ goBack, color, ...props }) => {
  const iconColor = color ? setForegroundColor(color, { dark: greyDark, light: white }) : white
  return (
    <button onClick={goBack} type="button" {...props}>
      <Icon icon="left-arrow" color={iconColor} />
    </button>
  )
}
BackButton.propTypes = {
  goBack: func,
  color: string
}

export const SearchHeader = (props) => (
  <Container>
    <Inner>
      <SearchHeaderContent {...props} />
    </Inner>
  </Container>
)

const GlobalHeader = (props) => (
  <Container color={props.color}>
    <Inner allowFullWidth={props.allowFullWidth}>
      <GlobalHeaderContent {...props} />
    </Inner>
  </Container>
)

export const SubHeader = (props) => (
  <SubHeaderContainer color={props.color} level={props.level || 0}>
    <Inner>
      <SubHeaderContent {...props} />
    </Inner>
  </SubHeaderContainer>
)

SubHeader.propTypes = {
  color: string,
  level: number
}

GlobalHeader.propTypes = {
  color: string,
  allowFullWidth: bool
}

export default GlobalHeader
