import React from 'react'
import { arrayOf, func, object } from 'prop-types'

const CustomCell = ({ column, records, render }) => {
  return render?.(records, column) || <></>
}
CustomCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The record the cell will render
   */
  records: arrayOf(object).isRequired,

  /**
   * A function returning the React Element that will be rendered inside the cell
   *
   * @param {object} record The record provided to the cell
   * @param {object} column The column definition associated with the cell
   * @returns {ReactElement}
   */
  render: func.isRequired
}

export default CustomCell
