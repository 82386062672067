import { useEffect, useState } from 'react'

const DEFAULT_MOBILE_WIDTH = 768

export const useIsMobile = (params) => {
  const maxWidth = params?.maxWidthInPixels ?? DEFAULT_MOBILE_WIDTH
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia(`(max-width: ${maxWidth}px)`).matches)
    }

    checkIsMobile()

    window.addEventListener('resize', checkIsMobile)

    return () => window.removeEventListener('resize', checkIsMobile)
  }, [])

  return { isMobile }
}
