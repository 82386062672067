import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { array, number, object, string } from 'prop-types'
import styled, { css } from 'styled-components'

import SectorContext from 'context/SectorContext'

import { currentTerritoryName } from 'store/territories/selectors'

import Button from 'components/button/Button'
import Section from 'components/Section'
import Status from 'components/Status'

import { SECTOR_LEVELS } from 'utils/constants'

import { borderColor, greyDark, greyLight, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Store = styled.div`
  display: block;
  position: relative;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};
  cursor: pointer;
  & + & {
    border-top: 0;
  }

  ${(props) =>
    props.accessDisabled &&
    css`
      cursor: default;
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    `};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const StoreHeader = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing.tiny};
`

const StoreName = styled.strong`
  display: block;
  color: ${greyDark};
`

const StoreMeta = styled.span`
  color: ${greyLight};
  display: block;
  font-size: ${tinyFontSize};
`

const ShowMore = styled(Button)`
  padding-top: ${spacing.medium};
`

const PAGE_SIZE = 20
const PricingCustomers = ({ currentTerritoryName, customers, match, ...rest }) => {
  const [lastVisibleCustomer, setLastVisibleCustomer] = useState(PAGE_SIZE)
  const { fetchSector } = useContext(SectorContext)

  if (!customers?.length) return null
  return (
    <>
      <Section>
        {customers.slice(0, lastVisibleCustomer).map(({ name, address, status, id }) => {
          return (
            <Store
              key={id}
              onClick={() =>
                fetchSector({
                  id,
                  type: SECTOR_LEVELS.CUSTOMER
                })
              }
            >
              <StoreHeader>
                <Status status={status} />
                <StoreName>{name}</StoreName>
              </StoreHeader>
              <StoreMeta>ERP: {id}</StoreMeta>
              <StoreMeta>{address.line1}</StoreMeta>
            </Store>
          )
        })}
      </Section>
      {customers.length > lastVisibleCustomer && (
        <ShowMore full tertiary onClick={() => setLastVisibleCustomer(lastVisibleCustomer + PAGE_SIZE)}>
          Show more
        </ShowMore>
      )}
    </>
  )
}

PricingCustomers.propTypes = {
  total: number,
  complete: number,
  notStarted: number,
  inProgress: number,
  clarificationNeeded: number,
  customers: array,
  survey: object,
  match: object,
  currentTerritoryName: string
}

export default connect((state) => ({
  currentTerritoryName: currentTerritoryName(state)
}))(PricingCustomers)
