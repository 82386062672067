import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams, useSearchParams } from 'react-router-dom'
import { bool, func, string } from 'prop-types'
import { Form, formValueSelector, reduxForm, SubmissionError, submit } from 'redux-form'

import ActionHeaderContext from 'context/ActionHeaderContext'

import * as customerSelector from 'store/customers/selectors'
import { submitNewIntel } from 'store/intel/actions'
import * as intelSelector from 'store/intel/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

import IntelForm from './IntelForm'

const CreateIntel = ({ handleSubmit, change, submitting, invalid, submitFailed, error, submitNewIntel, dispatch }) => {
  const { addAction } = useContext(ActionHeaderContext)
  const navigate = useNavigate()
  const selector = formValueSelector('createIntel')
  const { sectorId: customerId, sectorType } = useParams()
  const [searchParams] = useSearchParams()

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const newImages = useSelector((state) => state.auth.newImages)
  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const currentTerritoryId = useSelector((state) => state.auth.currentTerritoryId)
  const startDate = useSelector((state) => selector(state, 'startDate'))
  const intelType = useSelector((state) => selector(state, 'type'))
  const manufacturer = useSelector((state) => selector(state, 'manufacturer'))
  const productCategory = useSelector((state) => selector(state, 'productCategory'))
  const brandOptions = useSelector((state) => intelSelector.manufacturerBrandOptions('createIntel')(state))
  const scopeFields = useSelector((state) =>
    intelSelector.scopeFields(state, { scopeId: searchParams?.get('scopeId') || null })
  )
  const customer = useSelector((state) => customerSelector.customerFromUrl(state, { customerId }))

  useEffect(() => {
    addAction({
      onCreate: () => dispatch(submit('createIntel')),
      getLabel: () => 'Save'
    })
  }, [])

  const submitIntel = async (formValues) => {
    if (isMobileViewOnly) return navigate('..')
    try {
      const values = { ...formValues, customerId: customer.id, customerName: customer.name }

      return submitNewIntel({ values, newImages, currentTerritoryId }, () => navigate('..'))
    } catch (err) {
      throw new SubmissionError({ _error: err?.message || err })
    }
  }

  const formError = error || (invalid && submitFailed && 'Please correct the errors in your form')
  return (
    <Form onSubmit={handleSubmit(submitIntel)}>
      <IntelForm
        change={change}
        startDate={startDate}
        intelType={intelType}
        brandOptions={brandOptions}
        manufacturer={manufacturer}
        scopeFields={scopeFields}
        productCategory={productCategory}
        error={formError}
        isCreate
      />
    </Form>
  )
}

CreateIntel.propTypes = {
  submitNewIntel: func.isRequired,
  handleSubmit: func.isRequired,
  change: func,
  submitting: bool,
  invalid: bool,
  submitFailed: bool,
  error: string,
  dispatch: func
}

const mapStateToProps = (state, props) => ({
  employeeId: state.auth.user.id,
  initialValues: intelSelector.createIntelInitialValues(state, props),
  backPath: '../intel'
})

const CreateIntelForm = reduxForm({
  form: 'createIntel',
  onSubmitFail: () => {
    window.scroll(0, 0)
  },
  enableReinitialize: true
})(CreateIntel)

const ConnectedForm = connect(mapStateToProps, { submitNewIntel })(CreateIntelForm)

const IntelWrapper = (props) => {
  const [searchParams] = useSearchParams()
  const scopeId = searchParams?.get('scopeId')

  return <ConnectedForm scopeId={scopeId} {...props} />
}

export default IntelWrapper
