import React, { Fragment } from 'react'
import { array } from 'prop-types'
import styled from 'styled-components'

import { grey } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { tinyFontSize } from 'styles/typography'

const Container = styled.div`
  display: flex;
  margin-top: ${spacing.medium};
`

const Item = styled.div`
  flex: 1;
  text-align: left;
`

const Datum = styled.strong`
  display: block;
  color: ${grey};
  margin-bottom: 0;
  font-size: ${tinyFontSize};
`

const Label = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const StatDetails = ({ data }) => (
  <Container>
    {data.map(({ label, stats }, i) => {
      const [first, ...lines] = [].concat(stats)
      return (
        <Item key={i}>
          <Label>{label}</Label>
          <Datum>
            {first}
            {lines.length > 0 &&
              lines.map((s, lineIdx) => (
                <Fragment key={`${i}-${lineIdx}`}>
                  <br />
                  {s}
                </Fragment>
              ))}
          </Datum>
        </Item>
      )
    })}
  </Container>
)

StatDetails.propTypes = {
  data: array.isRequired
}

export default StatDetails
