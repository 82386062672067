import React from 'react'
import { rgba } from 'polished'
import { array } from 'prop-types'
import styled, { css } from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { borderColor, extraSecondary } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontBold } from 'styles/typography'

const Container = styled.div`
  margin-top: ${spacing.medium};
`

const Title = styled(SmallCaps)`
  display: block;
  margin-bottom: ${spacing.small};
`

const Table = styled.table`
  width: 100%;

  td {
    padding: ${spacing.small};
    border: 1px solid ${borderColor};
    text-align: center;
  }
`

const Row = styled.tr`
  position: static;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${rgba(extraSecondary, 0.1)};
      color: ${extraSecondary};
      font-weight: ${fontBold};
    `};
`

const PayoutGrid = ({ data }) => {
  if (!data?.length) return null

  return (
    <Container>
      <Title>Payout grid</Title>
      <Table>
        <tbody>
          {data.map(({ title, value, isSelected }, idx) => (
            <Row key={`payouts-row-${idx}`} isActive={isSelected}>
              <td>{title}</td>
              <td>{value}</td>
            </Row>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

PayoutGrid.propTypes = {
  data: array
}

export default PayoutGrid
