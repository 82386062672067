import React, { useContext, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { bool, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { createMessage, updateMessage } from 'store/messages/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Toggle from 'components/Toggle'
import VFSuggest from 'components/VFSuggest'

const reserveDurationOptions = [
  { value: 'minutes', label: 'minutes' },
  { value: 'hours', label: 'hours' },
  { value: 'days', label: 'days' }
]

const CreateMessage = ({ createMessage, updateMessage, isVisible, messagesStatus, lastMessage, cancelAndReturn }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const myId = useSelector((state) => state.auth.user.id)
  const isMobileViewOnly = useSelector((state) => state.auth.user.isMobileViewOnly)
  const isTM = useSelector(
    (state) => !['telesalesRepresentative', 'customerService'].includes(state.auth.user.groupCode)
  )

  const [text, setMessageText] = useState('')
  const [actionRequired, setActionRequired] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [assignee, setAssignee] = useState(null)
  const [reserveDurationInterval, setReserveDurationInterval] = useState('minutes')
  const [reserveDuration, setReserveDuration] = useState(30)

  const isVFReserve = assignee?.id === myId

  const clearField = () => {
    setReserveDuration()
    setReserveDurationInterval()
    setAssignee()
    setSubmitting(false)
    setMessageText('')
    setActionRequired(false)
  }

  const submitNewMessage = () => {
    const nextActor = isTM ? 'VF' : 'TM'
    createMessage({
      customerId,
      text: text.trim(),
      assignedEmployeeId: assignee?.id || null,
      actionRequiredBy: actionRequired ? nextActor : null
    }).then(() => {
      clearField()
      cancelAndReturn()
    })
  }

  const submitMessageUpdate = () => {
    const assignmentExpiry = moment().add(reserveDuration, reserveDurationInterval).toISOString()
    updateMessage({
      id: lastMessage.id,
      customerId,
      assignedEmployeeId: myId,
      assignedAt: new Date().toISOString(),
      assignmentExpiry
    }).then(() => {
      clearField()
      cancelAndReturn()
    })
  }

  const handleClose = () => {
    clearField()
    cancelAndReturn()
  }

  const title = !isVFReserve ? 'app.createMessage' : 'app.reserveAction'

  return (
    <ActionSheet
      title={translate(title)}
      visible={isVisible}
      action={
        <button type="button" onClick={handleClose}>
          <Icon icon="close" />
        </button>
      }
      onOverlayClick={handleClose}
    >
      <Fieldset>
        {!isTM && messagesStatus === 'actionRequiredByVF' && (
          <FieldsetItem>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{translate('common.reserve')}</span>
              <Toggle checked={isVFReserve} onClick={() => setAssignee(isVFReserve ? null : { id: myId })} />
            </div>
          </FieldsetItem>
        )}
        {isVFReserve ? (
          <>
            <FieldsetItem half>
              <Input
                value={reserveDuration}
                onChange={({ target }) => setReserveDuration(target.value.replace(/\D/, ''))}
              />
            </FieldsetItem>
            <FieldsetItem half>
              <Dropdown
                options={reserveDurationOptions}
                value={reserveDurationInterval}
                onChange={(e) => setReserveDurationInterval(e.target.value)}
              />
            </FieldsetItem>
            <FieldsetItem>
              <Button
                primary
                full
                onClick={() => {
                  setSubmitting(true)
                  submitMessageUpdate()
                }}
                isLoading={submitting}
              >
                {translate('app.reserveAction')}
              </Button>
            </FieldsetItem>
          </>
        ) : (
          <>
            <FieldsetItem>
              <Input
                textarea
                enableCounter
                maxLength={700}
                placeholder={translate('app.placeholders.writeYourMessageHere')}
                onChange={({ target }) => setMessageText(target.value)}
                value={text}
              />
            </FieldsetItem>
            <FieldsetItem>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>
                  {translate('app.template.actionRequiredBy', {
                    entity: isTM ? translate('app.roles.VF') : translate('app.roles.TM')
                  })}{' '}
                  ?
                </span>
                <Toggle checked={actionRequired} onClick={() => setActionRequired(!actionRequired)} />
              </div>
            </FieldsetItem>
            {actionRequired && isTM && (
              <FieldsetItem>
                <VFSuggest onChange={setAssignee} value={assignee} />
              </FieldsetItem>
            )}
            <FieldsetItem>
              <Button
                primary
                onClick={() => {
                  setSubmitting(true)
                  submitNewMessage()
                }}
                full
                disabled={!text.trim() || isMobileViewOnly}
                isLoading={submitting}
              >
                {translate('common.submit')}
              </Button>
            </FieldsetItem>
          </>
        )}
      </Fieldset>
    </ActionSheet>
  )
}

CreateMessage.propTypes = {
  createMessage: func.isRequired,
  updateMessage: func.isRequired,
  cancelAndReturn: func.isRequired,
  isVisible: bool,
  messagesStatus: string,
  lastMessage: object
}

const mapActionCreators = {
  createMessage,
  updateMessage
}

export default connect(null, mapActionCreators)(CreateMessage)
