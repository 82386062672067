import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const defaultValues = { averageCallTime: null, completedCalls: null, completionPercentage: null, scheduledCalls: null }

export const planningEngagementData = createSelector(dataSourceFromSectorLevel, (sector) => {
  if (isEmpty(sector)) {
    return {
      cycle: defaultValues,
      week: defaultValues
    }
  }
  const engagementData = sector.engagement || { cycle: defaultValues, week: defaultValues }
  if (!engagementData.cycle) engagementData.cycle = defaultValues
  if (!engagementData.week) engagementData.week = defaultValues

  return engagementData
})
