import queryString from 'query-string'

import { api } from 'store/api'

export const upsertEmployeeOOT = (details) => api.post(`/oot`, details)
export const deleteEmployeeOOT = (details) => api.delete(`/oot/${details.id}`)
export const fetchEmployeeOots = ({ before, after, employeeId }) =>
  api.get(
    `/oot?${queryString.stringify(
      { after: after.format('yyyy-MM-DD'), before: before.format('yyyy-MM-DD'), employeeId },
      { skipNull: true }
    )}`
  )
