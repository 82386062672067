import { schema } from 'normalizr'

const product = new schema.Entity('products')
const customer = new schema.Entity('customers')

export const sellInProgram = new schema.Entity('sellInPrograms', {
  // exceptions: [{
  //   customer
  // }],
  targets: [
    {
      customer,
      skus: [
        {
          product
        }
      ]
    }
  ]
})
