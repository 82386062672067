import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, number, object, oneOfType, string } from 'prop-types'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchBrandCompletion } from 'store/priceCaptureCompliance/actions'
import { getBrandCompletion } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Brand = tw.span`w-36 text-base font-medium text-slate-900`

const CompletedAmount = tw.span`basis-16 text-right text-sm font-medium text-slate-900`

const TotalAmount = tw.span`basis-16 text-sm font-medium text-slate-500`

const BarContainer = tw.div`relative grow h-6 w-24 overflow-clip rounded bg-brand-50`

const Line = ({ brand, completed, total }) => {
  const CompletedPercent = ((completed / total) * 100).toFixed(2)
  const chartData = [{ name: brand, value: CompletedPercent }]

  return (
    <div className="flex items-center gap-4">
      <Brand>{brand}</Brand>
      <div className="flex w-full items-center space-x-4">
        <CompletedAmount>{CompletedPercent}%</CompletedAmount>
        <BarContainer>
          <div className="absolute h-full w-full">
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <BarChart
                barCategoryGap="0%"
                layout="vertical"
                data={chartData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <XAxis type="number" hide domain={['dataMin', 100]} />
                <YAxis type="category" hide domain={['dataMin', 'dataMax']} />
                <Bar dataKey="value" stackId="a" fill="#F5BE41" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </BarContainer>
        <TotalAmount>of {(+total).toLocaleString()}</TotalAmount>
      </div>
    </div>
  )
}

Line.propTypes = {
  brand: string.isRequired,
  completed: oneOfType([string, number]).isRequired,
  total: oneOfType([string, number]).isRequired
}

const LineTable = ({ brands }) => {
  return (
    <div className="flex h-full w-full flex-col justify-between pt-8">
      {Object.entries(brands).map(([key, value]) => {
        return <Line key={key} brand={key} completed={value.completed} total={value.count} />
      })}
    </div>
  )
}
LineTable.propTypes = {
  brands: object.isRequired
}

const BrandCompletion = ({ span, fetchBrandCompletion }) => {
  const { sectorType, sectorId } = useParams()
  const { currentProductType: productType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.BRAND_COMPLETION, {
    sectorType,
    sectorId,
    productType
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const data = useSelector((state) => getBrandCompletion(state, { productType }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (sectorType && sectorId) {
      fetchBrandCompletion({ sectorType, sectorId, productType, dataKey })
        .then(() => setError())
        .catch((e) => setError(e))
    }
  }, [sectorType, sectorId, productType])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    const rows = Object.values(data)
    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    return <LineTable brands={data} />
  }
  return (
    <Card title={translate('app.brandLevelCompletion')} span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

BrandCompletion.propTypes = {
  span: object.isRequired,
  fetchBrandCompletion: func
}

export default connect(null, {
  fetchBrandCompletion
})(BrandCompletion)
