import React from 'react'

import Button from 'components/button/Button'
import Spinner from 'components/Spinner'

import { SECTOR_LEVELS } from 'utils/constants'

/**
 * All Header Content parameters takes two arguments:
 * params: The current route parameters starting at actions/
 * actions: The actions registered in the HeaderActionsContext using addAction()
 *
 * The header text shown in the header
 * header: (params, actions) => string
 *
 * The breadcrumb appearing in the top left corner of the header
 * breadcrumbs: (params, actions) => [{
 *  text: string, // Text to display in the breadcrumb
 *  href: string, // Where the breadcrumb should link to when clicked
 * }]
 *
 * What to show in the header. Form inputs, filters, etc.
 * [headerActions]: (params, actions) => [node]
 */

const getRoot = ({ sectorType, sectorId }) => `/${sectorType}/${sectorId}/actions`

const pricingAction = {
  header: () => 'Pricing',
  breadcrumbs: (params) => [
    { text: 'Actions', href: `${getRoot(params)}` },
    { text: 'Pricing', href: `${getRoot(params)}/pricing` }
  ]
}

const HEADER_CONTENT = {
  pricing: pricingAction,
  'pricing/:upc': pricingAction,
  'pricing/thank-you': pricingAction,
  orders: {
    header: () => 'Orders',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Orders', href: `${getRoot(params)}/orders` }
    ],
    headerActions: (params, { getCreateButton = () => {} }) => [getCreateButton()]
  },
  'orders/create': {
    header: () => 'Create SAQ',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Orders', href: `${getRoot(params)}/orders` },
      { text: 'Create', href: `${getRoot(params)}/orders/create` }
    ],
    headerActions: (params, { getCreateButton = () => {} }) => [getCreateButton()]
  },
  surveys: {
    header: () => 'Surveys',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Surveys', href: `${getRoot(params)}/surveys` }
    ],
    headerActions: () => []
  },
  'surveys/:surveyId': {
    header: (params, { getSurveyName = () => 'Survey' }) => getSurveyName(),
    breadcrumbs: (params, { getSurveyName = () => 'Survey' }) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Surveys', href: `${getRoot(params)}/surveys` },
      { text: getSurveyName(), href: `${getRoot(params)}/surveys/${params.surveyId}` }
    ],
    headerActions: (
      params,
      { onSubmit = () => {}, isDisabled = () => false, isSubmitting = () => false, getSectorLevel = () => null }
    ) => {
      if (getSectorLevel() === SECTOR_LEVELS.TERRITORY) return []
      const disabled = isDisabled() ? { disabled: true } : {}
      return customerSurveyActions(isSubmitting(), onSubmit, disabled)
    }
  },
  'surveys/:surveyId/autofill': {
    header: (params, { getSurveyName = () => 'Survey' }) => getSurveyName(),
    breadcrumbs: (params, { getSurveyName = () => 'Survey' }) => [
      // relative to actions/surveys/:surveyId/customer/:customerId
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Surveys', href: `${getRoot(params)}/surveys` },
      { text: getSurveyName(), href: `${getRoot(params)}/surveys/${params.surveyId}` },
      { text: 'Autofill', href: `${getRoot(params)}/surveys/${params.surveyId}/autofill` }
    ],
    headerActions: (params, { onSubmit = () => {}, isDisabled = () => false, isSubmitting = () => false }) => {
      const disabled = isDisabled() ? { disabled: true } : {}
      return customerSurveyActions(isSubmitting(), onSubmit, disabled)
    }
  },
  'surveys/:surveyId/customer/:customerId': {
    header: (params, { getSurveyName = () => 'Survey' }) => getSurveyName(),
    breadcrumbs: (params, { getSurveyName = () => 'Survey', getCustomerName = () => 'Customer' }) => [
      // relative to actions/surveys/:surveyId/customer/:customerId
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Surveys', href: `${getRoot(params)}/surveys` },
      { text: getSurveyName(), href: `${getRoot(params)}/surveys/${params.surveyId}` },
      { text: getCustomerName(), href: `${getRoot(params)}/surveys/${params.surveyId}/customer/${params.customerId}` }
    ],
    headerActions: (
      params,
      { onSubmit = () => {}, isDisabled = () => false, isSubmitting = () => false, getSectorLevel = () => null }
    ) => {
      const disabled = isDisabled() ? { disabled: true } : {}
      return customerSurveyActions(isSubmitting(), onSubmit, disabled)
    }
  },
  funds: {
    header: () => 'Funds',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` }
    ],
    headerActions: () => []
  },
  'funds/:programId': {
    header: () => 'Funds program',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` },
      { text: 'Program', href: `${getRoot(params)}/funds/${params.programId}` }
    ],
    headerActions: () => []
  },
  'funds/:programId/payment': {
    header: () => 'Extra Funds Program Payment',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` },
      { text: 'Program', href: `${getRoot(params)}/funds/${params.programId}` },
      { text: 'Payment', href: `${getRoot(params)}/funds/${params.programId}/payment` }
    ],
    headerActions: (params, { renderActions = () => [] }) => [renderActions()]
  },
  'funds/:programId/target': {
    header: () => 'Extra Funds Program Target',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` },
      { text: 'Program', href: `${getRoot(params)}/funds/${params.programId}` },
      { text: 'Target', href: `${getRoot(params)}/funds/${params.programId}/target` }
    ],
    headerActions: (params, { onCreate = () => {}, getLabel = () => '', disabled = false }) => [
      <>
        <Button key={1} secondary to={`${getRoot(params)}/funds/${params.programId}`} disabled={disabled}>
          Cancel
        </Button>
        <Button key={2} primary onClick={onCreate}>
          {getLabel()}
        </Button>
      </>
    ]
  },
  'funds/:programId/exception': {
    header: () => 'Funds program exception',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` },
      { text: 'Program', href: `${getRoot(params)}/funds/${params.programId}` },
      { text: 'Exception', href: `${getRoot(params)}/funds/${params.programId}/exception` }
    ],
    headerActions: (params, { onCreate = () => {}, getLabel = () => '', disabled = false }) => [
      <>
        <Button key={1} secondary to={`${getRoot(params)}/funds/${params.programId}`} disabled={disabled}>
          Cancel
        </Button>
        <Button key={2} primary onClick={onCreate}>
          {getLabel()}
        </Button>
      </>
    ]
  },
  'funds/:programId/carton': {
    header: () => 'Funds program carton',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Funds', href: `${getRoot(params)}/funds` },
      { text: 'Program', href: `${getRoot(params)}/funds/${params.programId}` },
      { text: 'Carton', href: `${getRoot(params)}/funds/${params.programId}/carton` }
    ],
    headerActions: (params, { renderActions = () => [] }) => [renderActions()]
  },
  intel: {
    header: () => 'Intel',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Intel', href: `${getRoot(params)}/intel` }
    ],
    headerActions: (params, { onCreate = () => {}, getLabel = () => '' }) => {
      if (params.sectorType !== SECTOR_LEVELS.CUSTOMER) return
      return [
        <Button key={1} primary onClick={onCreate}>
          {getLabel()}
        </Button>
      ]
    }
  },
  'intel/create': {
    header: () => 'Create Intel',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Intel', href: `${getRoot(params)}/intel` },
      { text: 'Create', href: `${getRoot(params)}/intel/create` }
    ],
    headerActions: (params, { onCreate = () => {}, getLabel = () => '' }) => [
      <>
        <Button key={1} secondary to={`${getRoot(params)}/intel`}>
          Cancel
        </Button>
        <Button key={2} primary onClick={onCreate}>
          {getLabel()}
        </Button>
      </>
    ]
  },
  'intel/edit/:intelId': {
    header: () => 'Edit Intel',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Intel', href: `${getRoot(params)}/intel` },
      { text: 'Edit', href: `${getRoot(params)}/edit/:intelId` }
    ],
    headerActions: (params, { onCreate = () => {}, getLabel = () => '' }) => [
      <>
        <Button key={1} secondary to={`${getRoot(params)}/intel`}>
          Cancel
        </Button>
        <Button key={2} primary onClick={onCreate}>
          {getLabel()}
        </Button>
      </>
    ]
  },
  comments: {
    header: () => 'Comments',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Comments', href: `${getRoot(params)}/comments` }
    ],
    headerActions: (params, { getCreateButton = () => {} }) => [getCreateButton()]
  },
  'sell-in': {
    header: () => 'Sell In',
    breadcrumbs: (params) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Sell In', href: `${getRoot(params)}/sell-in` }
    ]
  },
  'sell-in/:programId': {
    header: (params, { getSellInProgramName = () => '' }) => getSellInProgramName(),
    breadcrumbs: (params, { getSellInProgramName = () => '' }) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Sell In', href: `${getRoot(params)}/sell-in` },
      { text: `${getSellInProgramName()}`, href: `${getRoot(params)}/sell-in/${params.programId}` }
    ]
  },
  'sell-in/:programId/customer/:customerId': {
    header: ({ customerId }, { getCustomerName = () => 'Customer' }) => `${getCustomerName()} (${customerId})`,
    breadcrumbs: (params, { getSellInProgramName = () => 'Sell In Program', getCustomerName = () => 'Customer' }) => [
      { text: 'Actions', href: `${getRoot(params)}` },
      { text: 'Sell In', href: `${getRoot(params)}/sell-in` },
      { text: `${getSellInProgramName()}`, href: `${getRoot(params)}/sell-in/${params.programId}` },
      {
        text: `${getCustomerName()} (${params.customerId})`,
        href: `${getRoot(params)}/sell-in/${params.programId}/customer/${params.customerId}`
      }
    ],
    headerActions: (params, { getPill = () => {} }) => [getPill()]
  }
}

const customerSurveyActions = (submitting, onClick, disabled) => [
  !submitting ? (
    <Button key={1} primary onClick={onClick} {...disabled}>
      Submit
    </Button>
  ) : (
    <Spinner icon="spinner" />
  )
]

export default HEADER_CONTENT
