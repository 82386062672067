import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import mapKeys from 'lodash/mapKeys'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { schema } from 'normalizr'

import { getEventType, getFourUpcomingOrderDates } from 'utils/helpers'
import { calculatedCustomerCardPerfData, calculatedPerfData } from 'utils/performance'

export const alert = new schema.Entity('alerts')
export const product = new schema.Entity('products')
export const scope = new schema.Entity('scopes')
export const survey = new schema.Entity('surveys')

export const call = new schema.Entity(
  'calls',
  {},
  {
    processStrategy: (entity) => ({
      ...entity,
      type: getEventType(entity),
      customer: entity.customer?.id || entity.customerId,
      employee: entity.employeeId
    })
  }
)

export const goFund = new schema.Entity('goFunds')

export const goFundAllocation = new schema.Entity(
  'goFundAllocations',
  { goFund },
  {
    processStrategy: (entity, parent) => {
      if (!parent?.customerId) return entity
      return {
        ...entity,
        goFund: entity.goFund || entity.goFundId,
        goFundPrograms: uniq((entity.goFundPrograms || []).concat(`${parent.id}_${parent.goFundId}`)).filter(Boolean)
      }
    },
    mergeStrategy: (oldAllocation, newAllocation) => {
      return {
        ...oldAllocation,
        ...newAllocation,
        goFund: oldAllocation.goFund || newAllocation.goFundId,
        goFundPrograms: uniq((oldAllocation.goFundPrograms || []).concat(newAllocation.goFundPrograms)).filter(Boolean)
      }
    }
  }
)

export const goFundProgram = new schema.Entity(
  'goFundPrograms',
  {
    goFund,
    allocation: goFundAllocation
  },
  {
    idAttribute: ({ id, goFundId }) => `${id}_${goFundId}`,
    processStrategy: (entity) => {
      return Object.assign({ customer: entity.customerId, goFund: entity.goFund || entity.goFundId }, entity, {
        ...(entity.startDate
          ? {
              startDate: entity.startDate.split('T')[0],
              endDate: entity.endDate.split('T')[0],
              finalCost: entity.adjustedPayment || entity.finalCost || null
            }
          : {})
      })
    }
  }
)

export const pricing = new schema.Entity(
  'pricings',
  {},
  {
    idAttribute: 'upc'
  }
)

export const program = new schema.Entity(
  'programs',
  {},
  {
    idAttribute: ({ tempId, id }) => tempId || id
  }
)

export const insight = new schema.Entity('insights')
export const intel = new schema.Entity('intel')
export const project = new schema.Entity('projects')
export const cycleCampaigns = new schema.Entity('cycleCampaigns')

export const order = new schema.Entity(
  'orders',
  {
    entries: [{ product }]
  },
  {
    processStrategy: (entity) =>
      Object.assign({}, entity, {
        totalQuantity: entity.entries.reduce((acc, { qty }) => acc + qty, 0),
        entries: entity.entries.map((e) => ({ ...e, product: { id: e.productId } }))
      })
  }
)

export const customer = new schema.Entity(
  'customers',
  {
    assortment: [product],
    goFundPrograms: [goFundProgram],
    insights: [insight],
    intel: [intel],
    orders: [order],
    programs: [program],
    projectLinks: [
      {
        project
      }
    ],
    scopeRequests: [
      {
        scope
      }
    ],
    surveys: [
      {
        survey
      }
    ],
    calls: [call]
  },
  {
    processStrategy: (
      { performance, customerCardPerformance, orderDates, awr, surveys, projectLinks, ...entity },
      parent
    ) => {
      const perfData = calculatedPerfData(performance)
      const customerCardPerfData = calculatedCustomerCardPerfData(customerCardPerformance)
      const customFields = {}
      if (!isEmpty(entity.address)) {
        customFields.address = mapKeys(entity.address, (val, key) => camelCase(key))
      }
      if (awr) {
        customFields.awr = awr.reduce((acc, { brand, ...awrRecord }) => Object.assign(acc, { [brand]: awrRecord }), {})
      }

      if (orderDates) {
        customFields.orderDates = getFourUpcomingOrderDates(orderDates)
      }

      if (surveys) {
        customFields.surveys = surveys.map(({ survey, surveyId, ...customerSurvey }) => ({
          ...customerSurvey,
          surveyId,
          survey: survey || { id: surveyId }
        }))
      }

      if (projectLinks) {
        customFields.projectLinks = projectLinks.map(({ projectId, ...projectCustomer }) => ({
          ...projectCustomer,
          projectId,
          project: { id: projectId },
          comments: sortBy(entity.comments || [], ({ createdAt }) => -new Date(createdAt))
        }))
      }
      if (parent?.name) {
        customFields.territory = parent.name
      }
      if (perfData) customFields.performance = perfData

      if (customerCardPerfData) customFields.customerCardPerformance = customerCardPerfData

      return Object.assign({}, entity, customFields)
    }
  }
)

export const sellInProgram = new schema.Entity('sellInPrograms', {
  targets: [
    {
      customer,
      skus: [
        {
          product
        }
      ]
    }
  ]
})

export const territory = new schema.Entity(
  'territories',
  {
    sellInPrograms: [sellInProgram],
    customers: [customer],
    goFundAllocations: [goFundAllocation],
    projectLinks: [
      {
        project
      }
    ]
  },
  {
    processStrategy: ({ performance, ...entity }) => {
      if (!performance) return entity
      return Object.assign({}, entity, {
        performance: calculatedPerfData(performance),
        lastFetch: Date.now()
      })
    },
    mergeStrategy: (oldTerr, newTerr) => {
      return {
        ...oldTerr,
        ...newTerr,
        sellInPrograms: newTerr.sellInPrograms?.length ? newTerr.sellInPrograms : oldTerr.sellInPrograms,
        customers: newTerr.customers?.length ? newTerr.customers : oldTerr.customers
      }
    }
  }
)

export const employee = new schema.Entity('employee')
export const oot = new schema.Entity('employeeOots')
export const employeeList = [employee]

export const user = new schema.Entity(
  'user',
  {
    territories: [territory],
    orders: [order],
    employeeOots: [oot]
  },
  {
    idAttribute: () => 'user',
    processStrategy: ({ groupCode, nationalMobilePermission, territories, ...entity }) => ({
      ...entity,
      groupCode,
      nationalMobilePermission,
      territories,
      districts: uniq(territories.map(({ district }) => district?.id).filter(Boolean)),
      isTM: !nationalMobilePermission && groupCode === 'accountRepresentative'
    })
  }
)

export const district = new schema.Entity('districts', {
  territories: [territory]
})

export const region = new schema.Entity('regions', { districts: [district] })

export const national = new schema.Entity('nations', { regions: [region] })

export const masterFetchSchema = {
  employee: user,
  products: [product],
  scopes: [scope],
  pricings: [pricing],
  alerts: [alert],
  surveys: [survey],
  projects: [project],
  customerCalls: [call]
}

export const territoryFetchSchema = {
  territory,
  products: [product],
  scopes: [scope],
  pricings: [pricing],
  surveys: [survey],
  projects: [project]
}
export const customerFetchSchema = {
  customer,
  pricings: [pricing],
  surveys: [survey],
  projects: [project],
  goFunds: [goFund],
  goFundPrograms: [goFundProgram],
  sellInPrograms: [sellInProgram]
}

export const sectorFetchSchema = {
  district,
  national,
  region,
  territory,
  customer
}

export const callTask = new schema.Entity('callTasks')
