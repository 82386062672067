import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'

import { verifySignup } from 'store/auth/actions'

import { Spinner } from 'components/button/Button'
import GlobalAlert from 'components/GlobalAlert'

const errorMessages = {
  offlineError: <GlobalAlert>You cannot signup without an internet connection.</GlobalAlert>,
  invalidRegistrationToken: (
    <GlobalAlert>
      We cannot validate your registration. Please make sure you have copied the link correctly.
    </GlobalAlert>
  ),
  generic: <GlobalAlert>An error has occured. Please try again later.</GlobalAlert>
}

const VerifySignup = ({ verifySignup }) => {
  const { registrationToken } = useParams()
  const isLoading = useSelector(({ auth }) => auth.loading)

  const [error, setError] = useState(null)

  useEffect(() => {
    verifySignup(registrationToken).catch((res) => {
      const signupError =
        !res.response || res.response.status === 500 || !res.response.data.message
          ? 'generic'
          : res.response.data.message
      setError(errorMessages[signupError] || errorMessages.generic)
    })
  }, [registrationToken, verifySignup])

  return (
    <>
      {isLoading && (
        <>
          <p>Please wait while we verify your registration.</p>
          <Spinner icon="spinner" />
        </>
      )}
      {error}
    </>
  )
}

VerifySignup.propTypes = {
  verifySignup: func.isRequired
}

const mapActionCreators = {
  verifySignup
}

export default connect(null, mapActionCreators)(VerifySignup)
