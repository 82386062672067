import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import { createAction } from 'redux-actions'

import { registerSync, uploadImagesGetKeys } from 'store/api'
import { updateAutofilledCustomerSurveys, updateCustomerSurvey } from 'store/customers/actions'
import outbox from 'store/outbox'

import * as api from './endpoints'

const queueOfflineSurvey = async (survey) => {
  const surveyOutbox = (await outbox.getItem('survey')) || {}
  await outbox.setItem('survey', { ...surveyOutbox, [`${survey.surveyId}-${survey.customerId}`]: survey })
  return registerSync('submit-pending-survey')
}

const createQueuedCustomerSurvey = async (customerSurvey) => {
  await queueOfflineSurvey(customerSurvey)
  return customerSurvey
}

const queueOfflineSurveyAutoFill = async (targets) => {
  const surveyAutoFillOutbox = (await outbox.getItem('survey-autofill')) || {}
  await outbox.setItem(
    'survey-autofill',
    targets.reduce(
      (acc, target) => ({ ...acc, [`${target.surveyId}-${target.customerId}`]: target }),
      surveyAutoFillOutbox
    )
  )
  return registerSync('submit-pending-survey-autofill')
}

const createQueuedSurveyAutofill = async (targets) => {
  const targetUpdates = targets.map(({ isAutofilled, completion, ...rest }) => ({
    completion: 'complete',
    ...rest
  }))
  await queueOfflineSurveyAutoFill(targetUpdates)
  return targetUpdates
}

export const submitSurveyAnswers = createAction(
  'Submit survey answers',
  ({ answers, ...surveyDetails }) =>
    async (dispatch) => {
      let surveyUpdate
      const answeredQuestionsCount = countBy(answers, ({ answer }) => Boolean(answer))
      try {
        if (window.navigator.onLine) {
          const answersReadyToSubmit = []

          for await (const a of answers) {
            if (!a.answer?.imgs) {
              answersReadyToSubmit.push(a)
              continue
            }
            const { oldImgs = [], newImgs } = groupBy(a.answer.imgs, (img) => (img.url ? 'oldImgs' : 'newImgs'))
            const newUploadedImgs = await uploadImagesGetKeys(newImgs)
            answersReadyToSubmit.push({
              ...a,
              answer: {
                ...a.answer,
                imgs: uniqBy(oldImgs.concat(newUploadedImgs), 'key')
              }
            })
          }
          const {
            data: { customerSurvey }
          } = await api.submitSurveyAnswers({ ...surveyDetails, answers: answersReadyToSubmit })
          surveyUpdate = customerSurvey
        } else {
          surveyUpdate = await createQueuedCustomerSurvey({ ...surveyDetails, answers, answeredQuestionsCount })
        }
        dispatch(updateCustomerSurvey(surveyUpdate))
      } catch (err) {
        console.dir(err)
        if (err.message !== 'Network Error') {
          throw err
        }

        surveyUpdate = await createQueuedCustomerSurvey({ ...surveyDetails, answers, answeredQuestionsCount })
        dispatch(updateCustomerSurvey(surveyUpdate))
      }
    }
)

export const submitSurveyAutoComplete = createAction(
  'Submit survey autocomplete',
  ({ surveyId, customerIds, startedAt, finishedAt, answers }) =>
    async (dispatch) => {
      // let submitSuccess = false
      let targetUpdates
      try {
        if (window.navigator.onLine) {
          const answersReadyToSubmit = []
          for await (const a of answers) {
            if (!a.answer?.imgs) {
              answersReadyToSubmit.push(a)
              continue
            }
            const newUploadedImgs = await uploadImagesGetKeys(a.answer.imgs)
            answersReadyToSubmit.push({
              ...a,
              answer: {
                ...a.answer,
                imgs: uniqBy(newUploadedImgs, 'key')
              }
            })
          }
          const {
            data: { customerSurveys }
          } = await api.submitSurveyAutoFill({
            surveyId,
            customerIds,
            startedAt,
            finishedAt,
            answers: answersReadyToSubmit
          })
          // submitSuccess = true
          targetUpdates = customerSurveys
        } else {
          targetUpdates = await createQueuedSurveyAutofill({ surveyId, customerIds, startedAt, finishedAt, answers })
        }
        dispatch(updateAutofilledCustomerSurveys(targetUpdates))
      } catch (err) {
        console.dir(err)
        if (err.message !== 'Network Error') {
          throw err
        }

        targetUpdates = await createQueuedSurveyAutofill({ surveyId, customerIds, startedAt, finishedAt, answers })
        dispatch(updateAutofilledCustomerSurveys(targetUpdates))
      }
    }
)
