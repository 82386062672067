const formErrorMessages = {
  payLimitExceeded: (details) => ({ finalCost: `Maximum $${details.limit}` }),
  payBudgetFrozen: (details) => ({ finalCost: `Budget frozen. Payment cannot exceed $${details.limit}` }),
  reserveLimitExceeded: (details) => ({ reservedBudget: `Maximum $${details.limit}` }),
  reserveBudgetFrozen: (details) => ({ reservedBudget: `Budget frozen. Additional funds cannot be reserved.` }),
  createLimitExceeded: (details) =>
    details.limit
      ? { reservedBudget: `Maximum $${details.limit}` }
      : { _error: `Budget has no available funds to create this program.` },
  createBudgetFrozen: (details) => ({ _error: `Budget frozen. New funds cannot be reserved.` }),
  customerNotEligible: (details) => ({ _error: 'This customer is not eligible for budgeted programs.' }),
  alreadyPaid: (details) => ({
    _error: 'This program has already been paid out.'
  }),
  generic: () => ({ _error: 'Something went wrong.' })
}

export const getFormErrorMessage = ({ status, data }) => {
  try {
    const { reason, details } = JSON.parse(data.message)
    return formErrorMessages[reason || 'generic'](details)
  } catch (err) {
    console.log('GO PROGRAM RESPONSE ERROR: ', JSON.stringify({ data }))
    return formErrorMessages.generic()
  }
}

const inboxErrorMessages = {
  payLimitExceeded: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to payment',
    link: `/customers/${customerId}/programs/close/${id}`,
    message: `Payment must be less than $${details.limit}`
  }),
  payBudgetFrozen: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to payment',
    link: `/customers/${customerId}/programs/close/${id}`,
    message: `Budget frozen. Payment cannot exceed reserved amount: $${details.limit}`
  }),
  reserveLimitExceeded: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to edit',
    link: `/customers/${customerId}/programs/edit/${id}`,
    message: `Reserved budget cannot exceed $${details.limit}`
  }),
  reserveBudgetFrozen: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to edit',
    link: `/customers/${customerId}/programs/edit/${id}`,
    message: `Budget frozen. Additional funds cannot be reserved.`
  }),
  createLimitExceeded: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to customer programs',
    link: `/customers/${customerId}/programs`,
    message: details.limit
      ? `Reserved budget cannot exceed $${details.limit}`
      : `Budget has no available funds to create this program.`
  }),
  createBudgetFrozen: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to customer programs',
    link: `/customers/${customerId}/programs`,
    message: `Budget frozen. New funds cannot be reserved to create this program.`
  }),
  customerNotEligible: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to customer programs',
    link: `/customers/${customerId}/programs`,
    message: 'This customer is not eligible for budgeted programs'
  }),
  alreadyPaid: ({ details, customerId, id }) => ({
    linkTag: 'Dismiss & go to customer programs',
    link: `/customers/${customerId}/programs`,
    message: `This program has already been paid out: $${details.finalCost}`
  }),
  generic: ({ customerId }) => ({
    linkTag: 'Dismiss & go to customer programs',
    link: `/customers/${customerId}/programs`,
    message: 'Something went wrong. Please try again.'
  })
}

export const getInboxErrorMessage = ({ error, ...syncData }) => {
  try {
    const { reason, details } = JSON.parse(error)
    return inboxErrorMessages[reason]({ details, ...syncData })
  } catch (err) {
    return inboxErrorMessages.generic(syncData)
  }
}
