import React, { useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import moment from 'moment'
import { func, node } from 'prop-types'

import { ActiveCallProvider } from 'context/ActiveCallContext'

import { fetchCallTasks } from 'store/callTasks/actions'
import { fetchCustomerCalls, upsertCustomerCall } from 'store/customerCalls/actions'
import * as callSelector from 'store/customerCalls/selectors'
import { fetchEmployeeOots } from 'store/employeeOots/actions'
import * as ootSelector from 'store/employeeOots/selectors'

import CallDetails from 'components/schedule/CallDetails'
import CreateCallSheet from 'components/schedule/CreateCallSheet'
import CreateOOTSheet from 'components/schedule/CreateOOTSheet'
import CreatePastCallSheet from 'components/schedule/CreatePastCallSheet'

import { checkDateOverlap, isWeekend } from 'utils/helpers'

const ScheduleContext = React.createContext()
export default ScheduleContext

const UnconnectedScheduleProvider = ({
  children,
  fetchCustomerCalls,
  fetchEmployeeOots,
  fetchCallTasks,
  upsertCustomerCall
}) => {
  const employee = useSelector((state) => state.auth.user)
  const allOots = useSelector((state) => ootSelector.allOots(state))
  const allCalls = useSelector((state) => callSelector.allCustomerCalls(state))

  // OOT creation / editing
  const [ootToEdit, setOotToEdit] = useState()
  const [createOOTDetails, setCreateOOTDetails] = useState()
  const [employeeKm, setEmployeeKm] = useState()

  // Call creation / editing
  const [callType, setCallType] = useState()
  const [createCallScreenVisible, setCreateCallScreenVisible] = useState(false)
  const [callToEdit, setCallToEdit] = useState(null)
  const [createdFromMessageId, setCreatedFromMessageId] = useState(null)

  // Completed call display
  const [callToDisplay, setCallToDisplay] = useState(null)
  const [kmsCallDisplay, setKmsCallDisplay] = useState(null)
  const [noteCallDisplay, setNoteCallDisplay] = useState(null)

  // Past calls
  const [createPastCallVisible, setCreatePastCallVisible] = useState(false)
  const [pastCallToEdit, setPastCallToEdit] = useState()

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString())
  const [beforeAfterDate, setBeforeAfterDate] = useState()

  useEffect(() => {
    if (!callToDisplay) {
      setKmsCallDisplay(null)
      setNoteCallDisplay(null)
    }
  }, [callToDisplay])

  const clearOOTDetails = () => {
    setCreateOOTDetails()
    setOotToEdit()
  }

  const fetchSchedule = (calendarDate) => {
    if (
      !beforeAfterDate ||
      moment(calendarDate).add(1, 'week').isAfter(moment(beforeAfterDate.before)) ||
      moment(calendarDate).subtract(1, 'week').isBefore(moment(beforeAfterDate.after))
    ) {
      const before = moment(calendarDate).add(1, 'month')
      const after = moment(calendarDate).subtract(1, 'month')
      const shouldUpdateFetchAt =
        !beforeAfterDate?.fetchAt || moment().diff(moment(beforeAfterDate?.fetchAt), 'hours') > 8
      fetchCustomerCalls({ before, after })
      fetchEmployeeOots({ before, after })
      setBeforeAfterDate({
        before: moment.max(before, beforeAfterDate?.before || before),
        after: moment.min(after, beforeAfterDate?.after || after),
        fetchAt: shouldUpdateFetchAt ? moment() : beforeAfterDate?.fetchAt
      })
    }
  }

  useEffect(() => {
    fetchCallTasks()
  }, [fetchCallTasks])

  const showEditOotScreen = ({ ootId, ootDetails }) => {
    const ootToEdit = (ootId && allOots.find(({ id }) => id === ootId)) || ootDetails
    setOotToEdit(ootToEdit)
    setEmployeeKm(ootToEdit?.employeeKm || {})
    setCreateOOTDetails(true)
  }

  const updateCall = async (call) => {
    const { id, callStart, callEnd, customerId, customer, employeeId, employeeKm, message, completedTasks } = call

    let insertEmployeeKm = null
    if (employeeKm) {
      insertEmployeeKm = {
        ...employeeKm,
        kms: parseFloat(kmsCallDisplay)
      }
    } else if (kmsCallDisplay) {
      insertEmployeeKm = {
        kms: parseFloat(kmsCallDisplay),
        customerCallId: id,
        dateDriven: new Date(callStart),
        type: 'CALL'
      }
    }

    const insertMessage = noteCallDisplay?.trim()
      ? {
          ...(message ? { id: message.id } : null),
          text: noteCallDisplay,
          customerId: customerId || customer?.id,
          employeeId
        }
      : null

    await upsertCustomerCall({
      id,
      callEnd,
      customerId: customerId || customer?.id,
      employeeKm: insertEmployeeKm,
      message: insertMessage,
      completedTasks
    })
    setCallToDisplay()
  }

  const togglePastCallVisible = () => {
    setPastCallToEdit()
    setCreatePastCallVisible(true)
  }

  const toggleVisibleApptDetails = (call) => {
    if (call) {
      if (call.id || call.callId) {
        const callId = call.id || call.callId
        const callToEdit = allCalls.find(({ id }) => id === callId) || {}
        setCallToEdit({
          ...callToEdit,
          ...call
        })
      } else {
        setCallToEdit(call)
      }

      if (call.createdFromMessageId) {
        setCreatedFromMessageId(call.createdFromMessageId)
      } else {
        setCreatedFromMessageId()
      }
    }

    setCreateCallScreenVisible(true)
  }

  const clearVisibleApptDetails = () => {
    setCallToEdit()
    setCreateCallScreenVisible(false)
  }

  const showCallDetailsScreen = useCallback(
    ({ displayCall, callId }) => {
      const call = displayCall || allCalls.find((c) => c.id === callId)
      setCallType(call.callType || call.scheduledType)
      setKmsCallDisplay(call.employeeKm?.kms?.toString() || null)
      setNoteCallDisplay(call.message?.text || '')
      // setCompletedTasksCallDisplay(isEmpty(call.completedTasks) ? null : call.completedTasks)
      setCallToDisplay(call)
    },
    [allCalls]
  )

  const showPastCallScreenScreen = ({ pastCall }) => {
    setCallType(pastCall.callType)
    setKmsCallDisplay(pastCall.employeeKm?.kms?.toString() || null)
    setNoteCallDisplay(pastCall.message?.text || '')
    setPastCallToEdit(pastCall)
    setCreatePastCallVisible(true)
  }

  const isDuringOffTime = (start, end) => {
    if (!start && !end) return null
    const startsOnWeekend = start && isWeekend(start)
    const endsOnWeekend = end && isWeekend(end)
    return (
      startsOnWeekend ||
      endsOnWeekend ||
      Object.values(allOots).some((oot) => checkDateOverlap(oot.startAt, oot.endAt, start, end))
    )
  }

  const isDuringCall = (start, end, call) => {
    if (!start && !end) return null
    const callToCompare = call ? allCalls.filter((c) => c.id !== call.id) : allCalls
    return Object.values(callToCompare).some((call) => {
      const callStart = call.callEnd ? call.callStart : call.scheduledStart
      const callEnd = call.callEnd || call.scheduledEnd
      return checkDateOverlap(start, end, callStart, callEnd)
    })
  }

  const value = {
    toggleVisibleApptDetails,
    togglePastCallVisible,
    showCallDetailsScreen,
    fetchSchedule,
    showEditOotScreen,
    isDuringOffTime,
    isDuringCall,
    showPastCallScreenScreen,
    selectedDate,
    setSelectedDate
  }

  const isMoveableGlobalOot =
    ootToEdit?.isGlobal && ootToEdit?.rule && moment().isSameOrBefore(ootToEdit.startAt, 'day')
  const ootIsEditable = !ootToEdit?.isGlobal || isMoveableGlobalOot

  return (
    <ScheduleContext.Provider value={value}>
      <ActiveCallProvider toggleVisibleApptDetails={toggleVisibleApptDetails} isDuringOffTime={isDuringOffTime}>
        {children}
      </ActiveCallProvider>
      <CreateCallSheet
        allowPastTime={false}
        canEdit={!callToEdit?.employeeId || callToEdit?.employeeId === employee.id}
        setSheetVisible={clearVisibleApptDetails}
        sheetVisible={createCallScreenVisible}
        callToEdit={callToEdit}
        createdFromMessageId={createdFromMessageId}
      />
      <CreatePastCallSheet
        sheetVisible={createPastCallVisible}
        setSheetVisible={setCreatePastCallVisible}
        pastCallToEdit={pastCallToEdit}
        canEdit={!pastCallToEdit?.employeeId || pastCallToEdit?.employeeId === employee.id}
      />
      {callToDisplay && (
        <CallDetails
          call={callToDisplay}
          setCall={setCallToDisplay}
          updateCall={updateCall}
          callType={callType}
          kms={kmsCallDisplay}
          note={noteCallDisplay}
          setKms={setKmsCallDisplay}
          setNote={setNoteCallDisplay}
          canEdit={callToDisplay.employeeId === employee.id}
          employee={employee}
        />
      )}
      <CreateOOTSheet
        visibleScheduleScreen={Boolean(createOOTDetails)}
        setVisibleScheduleScreen={clearOOTDetails}
        employeeWorkHours={employee.workHours}
        ootDetails={ootToEdit}
        employeeKm={employeeKm}
        canEdit={Boolean(!ootToEdit?.employeeId || ootToEdit?.employeeId === employee.id) && ootIsEditable}
        isGlobal={Boolean(ootToEdit?.isGlobal)}
      />
    </ScheduleContext.Provider>
  )
}

UnconnectedScheduleProvider.propTypes = {
  children: node.isRequired,
  fetchCustomerCalls: func.isRequired,
  fetchEmployeeOots: func.isRequired,
  fetchCallTasks: func.isRequired,
  upsertCustomerCall: func.isRequired
}

const mapActionCreators = {
  fetchCustomerCalls,
  fetchEmployeeOots,
  fetchCallTasks,
  upsertCustomerCall
}

export const ScheduleProvider = connect(null, mapActionCreators)(UnconnectedScheduleProvider)
