import omit from 'lodash/omit'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync, uploadImagesGetKeys } from 'store/api'
import { addIntelToCustomer, removeIntelFromCustomer } from 'store/customers/actions'
import outbox from 'store/outbox'
import { intel as IntelSchema } from 'store/schema'
import { addIntelToTerritory, fetchTerritory, removeIntelFromTerritory } from 'store/territories/actions'

import * as api from './endpoints'

const queueOfflineIntel = async (intel) => {
  const intelOutbox = (await outbox.getItem('intel')) || {}
  await outbox.setItem('intel', { ...intelOutbox, [intel.id]: intel })
  return registerSync('submit-pending-intel')
}

const queueOfflineIntelDeletion = async (intelId) => {
  const intelOutbox = (await outbox.getItem('deleted-intel')) || []
  await outbox.setItem('deleted-intel', [...intelOutbox, intelId])
  return registerSync('submit-pending-deleted-intel')
}

export const addIntelEntities = createAction('Add new/updated intel', (intel) => (dispatch) => {
  const { entities } = normalize(intel, IntelSchema)
  dispatch(mergeEntities(entities))
  if (intel.customerId) dispatch(addIntelToCustomer(intel))
  if (intel.territoryId) dispatch(addIntelToTerritory(intel))
})

export const removeIntelEntity = createAction('Remove intel entity')
export const removeIntelEntities = createAction('Remove deleted intel', (intel) => (dispatch) => {
  if (intel.customerId) dispatch(removeIntelFromCustomer(intel))
  if (intel.territoryId) dispatch(removeIntelFromTerritory(intel))
  dispatch(removeIntelEntity(intel))
})

const createQueuedIntel = async (intel) => {
  const tempIntel = { ...intel, id: intel.id || `temp-id-${Date.now()}` }
  await queueOfflineIntel(tempIntel)
  return tempIntel
}

export const submitNewIntel = createAction(
  'Submit new intel',
  ({ values, newImages, currentTerritoryId }, redirect) =>
    async (dispatch) => {
      let newIntel
      const { customerName, territoryId, ...intelValues } = values
      let uploadedImages
      try {
        if (window.navigator.onLine) {
          const imageKeys = await uploadImagesGetKeys(newImages)
          uploadedImages = imageKeys.length ? imageKeys.map(({ key }) => ({ key })) : null
          const {
            data: { intel }
          } = await api.submitNewIntel({ ...intelValues, images: uploadedImages })
          newIntel = intel
        } else {
          newIntel = await createQueuedIntel({ ...intelValues, newImages, uploadedImages })
        }

        dispatch(addIntelEntities({ customerName, ...newIntel }))
        if (intelValues.scopeErps?.length && currentTerritoryId) {
          dispatch(fetchTerritory(currentTerritoryId))
        }
        redirect()
      } catch (err) {
        if (err.message !== 'Network Error') {
          throw err
        }
        newIntel = await createQueuedIntel({ ...intelValues, newImages, uploadedImages })
        dispatch(addIntelEntities({ customerName, ...newIntel }))
        redirect()
      }
    }
)

export const submitIntelUpdate = createAction(
  'Submit intel update',
  ({ values, newImages }, redirect) =>
    async (dispatch) => {
      let intelUpdate
      const { customerName, territoryId, ...intelValues } = values
      let uploadedImages
      try {
        if (window.navigator.onLine) {
          const imageKeys = await uploadImagesGetKeys(newImages)
          uploadedImages = imageKeys.length ? imageKeys.map(({ key }) => ({ key })) : null
          const {
            data: { intel }
          } = await api.submitUpdatedIntel({
            ...intelValues,
            images: [...values.images, ...(uploadedImages || [])]
          })
          intelUpdate = intel
        } else {
          intelUpdate = await createQueuedIntel({ ...intelValues, newImages, uploadedImages })
        }

        dispatch(addIntelEntities({ customerName, ...intelUpdate }))
        redirect()
      } catch (err) {
        if (err.message !== 'Network Error') {
          throw err
        }

        intelUpdate = await createQueuedIntel({ ...intelValues, newImages, uploadedImages })
        dispatch(addIntelEntities({ customerName, ...intelUpdate }))
        redirect()
      }
    }
)

export const deleteIntel = createAction('Submit intel to delete', (values) => async (dispatch) => {
  if (`${values.id}`.includes('temp')) {
    const intelOutbox = (await outbox.getItem('intel')) || {}
    await outbox.setItem('intel', omit(intelOutbox, values.id))
    return dispatch(removeIntelEntities(values))
  }

  let deletedIntel

  try {
    if (window.navigator.onLine) {
      const {
        data: { intel }
      } = await api.submitIntelDeletion(values.id)
      deletedIntel = intel
    } else {
      await queueOfflineIntelDeletion(values.id)
      deletedIntel = values
    }

    dispatch(removeIntelEntities(deletedIntel))
  } catch (err) {
    if (err.message !== 'Network Error') {
      throw err
    }
    await queueOfflineIntelDeletion(values.id)
    dispatch(removeIntelEntities(values))
  }
})
