import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import countBy from 'lodash/countBy'
import { mix, rgba, stripUnit } from 'polished'
import { array, bool, func, number, object, string } from 'prop-types'
import styled, { css } from 'styled-components'

import Spinner from 'components/Spinner'

import { allFieldLabels } from 'utils/constants'

import { borderColor, grey, greyDark, greyLight, offWhite, secondaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius, headerHeight, tabNavHeight } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontRegular, smallFontSize, tinyFontSize } from 'styles/typography'

const combinedNavHeight = stripUnit(headerHeight) + stripUnit(tabNavHeight)
const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  & + & {
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};

  ${(props) =>
    props.isInboxScope &&
    css`
      background-color: ${mix(0.5, offWhite, white)};
    `};
`

const Feedback = styled.button`
  color: ${secondaryColor};
  display: inline-block;
  font-weight: ${fontRegular};
  font-size: ${smallFontSize};
  border-bottom: 1px solid ${rgba(secondaryColor, 0.25)};
`
const FeedbackLink = Feedback.withComponent(Link)

const Name = styled.strong`
  display: block;
  color: ${greyDark};
  margin-bottom: ${spacing.tiny};
  padding-right: ${spacing.xxxLarge};
`

const Details = styled.div`
  margin-left: -${spacing.medium};
  margin-right: -${spacing.medium};
  padding: ${spacing.medium};
  padding-top: ${spacing.small};
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};

  ${(props) =>
    props.collapsed &&
    css`
      border-radius: 0 0 ${borderRadius} ${borderRadius};
      margin-bottom: -${spacing.medium};
      border-bottom: 0;
    `};

  ${(props) =>
    !props.inCustomerView &&
    css`
      position: sticky;
      top: ${combinedNavHeight}px;
    `};
`

const Question = styled.div`
  border-radius: ${borderRadius} ${borderRadius} 0 0;
  margin-left: -${spacing.medium};
  margin-right: -${spacing.medium};
  margin-top: -${spacing.medium};
  padding: ${spacing.medium};
  padding-bottom: ${spacing.small};
  background-color: ${white};
  font-size: ${smallFontSize};
  font-weight: bold;
`

const Confirmation = styled.div`
  position: static;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${smallFontSize};
  margin-top: ${spacing.medium};
`

const Answer = styled.label`
  cursor: pointer;
  margin-right: ${spacing.small};
`

const Input = styled.input.attrs({ type: 'radio' })`
  display: none;
`

const AnswerLabel = styled.span`
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve}, background-color ${animationTime} ${animationCurve};
  border-radius: ${borderRadius};
  background-color: ${rgba(greyLight, 0.25)};
  padding: ${stripUnit(spacing.small) * 0.5 + 'px'} ${spacing.small};

  ${Answer}:hover & {
    color: ${grey};
    background-color: ${rgba(greyLight, 0.3)};
  }

  ${Input}:checked + & {
    background-color: ${secondaryColor};
    color: ${white};
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.small};
`

const Column = styled.div`
  width: 50%;
  padding: ${spacing.tiny} ${spacing.small};

  &:only-child {
    padding: ${spacing.small};
  }

  ${media.breakpoint`
    padding: ${spacing.small};
  `};
`

const Label = styled.span`
  display: block;
  color: ${greyLight};
  font-size: ${smallFontSize};
`

const ConfirmationActions = styled.span`
  display: flex;
  flex-wrap: nowrap;
`

const ScopeCustomer = styled.div`
  padding-right: ${spacing.small};
`

const Address = styled.small`
  display: block;
  color: ${grey};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
  font-size: ${tinyFontSize};

  ${media.breakpoint`
    max-width: 340px;
  `};
`

const Action = styled.button`
  color: ${secondaryColor};
  display: inline-block;
  font-weight: ${fontRegular};
  font-size: ${smallFontSize};
  border-bottom: 1px solid ${rgba(secondaryColor, 0.25)};
`

const IntelFeedbackCard = ({
  id,
  detailRequests = [],
  feedback = [],
  customerName,
  customerId,
  brand,
  type,
  inCustomerView
}) => {
  const requestedFields = detailRequests.map((field) => allFieldLabels[field]).join(', ')
  const { true: unansweredQuestions } = countBy(feedback, ({ answer }) => !answer && answer !== 0 && answer !== false)
  return (
    <Container>
      {inCustomerView ? (
        <Name>
          <small>
            {brand} - {type}
          </small>
        </Name>
      ) : (
        <Name>
          {customerName} <small>({customerId})</small>
        </Name>
      )}
      <p>
        <small>
          {requestedFields} • {unansweredQuestions || 'No'} question{unansweredQuestions === 1 ? '' : 's'}
        </small>
      </p>
      <FeedbackLink to={`/customers/${customerId}/intel/feedback/${id}`}>Provide requested feedback</FeedbackLink>
    </Container>
  )
}

const CustomerScopeFeedback = ({
  inCustomerView,
  customerName,
  customerAddress,
  customerId,
  updateCustomerScope,
  scopeId,
  id
}) => {
  const navigate = useNavigate()

  const PROGRAM_EXISTANCE = {
    exists: { value: 'yes', label: 'Yes' },
    doesNotExist: { value: 'no', label: 'No' }
  }

  const [programExists, setProgramExists] = useState(PROGRAM_EXISTANCE.exists.value)
  const [submitting, setSubmitting] = useState(false)

  const submitScopeUpdate = () => {
    if (programExists === 'no') {
      updateCustomerScope({ id, programExists: false, customerId })
      setSubmitting(true)
    } else {
      navigate(`/customers/${customerId}/intel/create?scopeId=${scopeId}`)
    }
  }

  return (
    <Confirmation>
      {!inCustomerView && (
        <ScopeCustomer>
          <Name>
            {customerName} <small>({customerId})</small>
          </Name>
          <Address>{customerAddress}</Address>
        </ScopeCustomer>
      )}
      <ConfirmationActions>
        {Object.values(PROGRAM_EXISTANCE).map((option) => (
          <Answer key={option.value}>
            <Input
              name={`${id}-programExists`}
              checked={programExists === option.value}
              value={option.value}
              onChange={() => setProgramExists(option.value)}
            />
            <AnswerLabel>{option.label}</AnswerLabel>
          </Answer>
        ))}
        &nbsp;
        {submitting ? <Spinner icon="spinner" /> : <Feedback onClick={submitScopeUpdate}>Submit</Feedback>}
      </ConfirmationActions>
    </Confirmation>
  )
}

CustomerScopeFeedback.propTypes = {
  customerName: string,
  id: number,
  scopeId: number,
  updateCustomerScope: func,
  customerId: number,
  inCustomerView: bool,
  customerAddress: string
}

const ScopeFeedbackCard = ({ fields, scopeRequests, id, inCustomerView, ...props }) => {
  const [collapsed, setCollapsed] = useState(false)
  const containerRef = useRef()

  const handleCollapse = () => {
    // when collapsing store list, page jumps to much lower scroll position because of removed elements. Set scroll to top of sticky header if it is at the top.
    containerRef.current &&
      window.scrollTo(
        0,
        Math.min(
          document.scrollingElement.scrollTop,
          containerRef.current.offsetTop - combinedNavHeight + containerRef.current.firstElementChild.clientHeight
        )
      )
    setCollapsed(!collapsed)
  }
  return (
    <Container ref={containerRef} isInboxScope={!inCustomerView}>
      {!inCustomerView && <Question>Are these intel details applicable to the following stores?</Question>}
      <Details collapsed={collapsed} inCustomerView={inCustomerView}>
        <Row>
          {Object.entries(fields).map(([key, value], idx) => (
            <Column key={idx}>
              <Label>{allFieldLabels[key]}</Label>
              <span>{['startDate', 'endDate'].includes(key) ? new Date(value).toDateString() : String(value)}</span>
            </Column>
          ))}
        </Row>
        {!inCustomerView && (
          <Row>
            <Column>
              <Action onClick={handleCollapse}>
                <span>{collapsed ? 'Show' : 'Hide'} stores</span>
              </Action>
            </Column>
          </Row>
        )}
      </Details>
      {!collapsed && (
        <>
          {scopeRequests.map((scopeRequest, i) => (
            <CustomerScopeFeedback
              key={scopeRequest.id}
              {...props}
              {...scopeRequest}
              scopeId={id}
              inCustomerView={inCustomerView}
            />
          ))}
        </>
      )}
    </Container>
  )
}

ScopeFeedbackCard.propTypes = {
  fields: object,
  scopeRequests: array,
  id: number,
  inCustomerView: bool
}

const FeedbackCard = ({ inboxItem, ...props }) => {
  const ItemCard = inboxItem.isIntelFeedback ? IntelFeedbackCard : ScopeFeedbackCard
  return <ItemCard {...inboxItem} {...props} />
}

FeedbackCard.propTypes = {
  inboxItem: object
}

IntelFeedbackCard.propTypes = {
  id: number,
  detailRequests: array,
  feedback: array,
  customerName: string,
  customerId: number,
  inCustomerView: bool,
  brand: string,
  type: string
}

export default FeedbackCard
