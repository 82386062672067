import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import styled from 'styled-components'
import { CurrentIntel, PastIntel } from 'views/TerritoryIntel'
import IntelRequests from 'views/TerritoryIntel/IntelRequests'

import { fetchTerritoryIntel } from 'store/territories/actions'
import { currentTerritory } from 'store/territories/selectors'

import Container from 'components/Container'
import EmptyState from 'components/EmptyState'

import { getErrorMessage } from 'utils/helpers'

import * as spacing from 'styles/spacing'
import { H1 } from 'styles/typography'

const Title = styled(H1)`
  margin-bottom: ${spacing.small};
`

const TerritoryIntel = ({ fetchTerritoryIntel }) => {
  const { sectorId: territoryId } = useParams()
  const mounted = useRef(false)

  const territory = useSelector((state) => currentTerritory(state, { territoryId }))
  const [error, setError] = useState()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (territoryId) {
      setError()
      fetchTerritoryIntel(territoryId).catch((error) => {
        if (mounted.current) {
          setError(getErrorMessage(error))
        }
      })
    }
  }, [territoryId, fetchTerritoryIntel])

  if (!territory) {
    return (
      <>
        <EmptyState title="Nothing yet" subtitle="You are not currently assigned any territories." />
      </>
    )
  }

  return (
    <>
      {error ? (
        <EmptyState title="Error loading territory intel" subtitle={error} />
      ) : (
        <>
          <Container>
            <Title>Current Intel</Title>
            <CurrentIntel />
          </Container>
          <Container>
            <Title>Past Intel</Title>
            <PastIntel />
          </Container>
          <Container>
            <Title>Resquested Intel</Title>
            <IntelRequests />
          </Container>
        </>
      )}
    </>
  )
}

TerritoryIntel.propTypes = {
  fetchTerritoryIntel: func
}

const mapActionCreators = {
  fetchTerritoryIntel
}

export default connect(null, mapActionCreators)(TerritoryIntel)
