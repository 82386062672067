import { createAction } from 'redux-actions'

import { addExceptionToGoFundProgram } from 'store/goFundPrograms/actions'

import * as api from './endpoints'

export const submitGoFundProgramException = createAction(
  'Submit goFundProgram exception',
  (values) => async (dispatch) => {
    try {
      if (window.navigator.onLine) {
        const {
          data: { exception }
        } = await api.submitGoFundProgramException(values)
        dispatch(
          addExceptionToGoFundProgram({
            exception,
            programId: values.programId.concat('_', values.goFundId)
          })
        )
      }
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
    }
  }
)
