import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useSelector } from 'react-redux'
import omit from 'lodash/omit'
import moment from 'moment'
import { node } from 'prop-types'

const STORAGE_KEY = 'usageTracking'

const GAnalyticsContext = React.createContext()
export default GAnalyticsContext

export const GAnalyticsProvider = ({ children }) => {
  const employee = useSelector((state) => state.auth.user)
  const [tabTracking, setTabTracking] = useState({})

  useEffect(() => {
    ReactGA.default.set({ userId: employee.id })
    ReactGA.default.gtag('set', 'user_properties', {
      name: `${employee.firstName} ${employee.lastName}`,
      id: employee.id
    })

    return () => {}
  }, [employee?.id])

  // TODO: Handle multiple tabs
  // document.addEventListener('visibilitychange', (event) => {
  //   const visibility = event.target.visibilityState
  //   if (!['hidden', 'visible'].includes(visibility)) return
  //   const activeTrackings = localStorage.getItem(STORAGE_KEY)
  //   if (activeTrackings) {
  //     const trackings = JSON.parse(activeTrackings)
  //     if (visibility === 'hidden') {
  //       console.log('hidden')
  //     }
  //     if (visibility === 'visible') {
  //       console.log(tabTracking)
  //     }
  //   }
  // })

  const addUsageEvent = (title) => {
    if (title.length > 40) {
      console.warn(`GAnalytics: ${title} has too much characters. Please provide a title with less than 40 characters.`)
    }
    ReactGA.default.event(title)
  }

  const startTracking = (title) => {
    if (title.length > 40) {
      console.warn(`GAnalytics: ${title} has too much characters. Please provide a title with less than 40 characters.`)
    }

    const activeTrackings = localStorage.getItem(STORAGE_KEY)
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    if (activeTrackings) {
      const trackings = JSON.parse(activeTrackings)
      if (trackings[title]) {
        stopTracking(title)
      }

      trackings[title] = currentTime
      localStorage.setItem(STORAGE_KEY, JSON.stringify(trackings))
    } else {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ [title]: currentTime }))
    }
    setTabTracking({ ...tabTracking, [title]: currentTime })
  }

  const stopTracking = (title) => {
    const activeTrackings = localStorage.getItem(STORAGE_KEY)
    if (activeTrackings) {
      const trackings = JSON.parse(activeTrackings)
      const startingTime = trackings[title]
      if (startingTime) {
        const activeSeconds = getActiveTime(startingTime)

        ReactGA.default.event(title, { value: activeSeconds })
        localStorage.setItem(STORAGE_KEY, JSON.stringify(omit(trackings, title)))
      }
    }
  }

  function getActiveTime(since) {
    return moment().diff(moment(since), 'seconds')
  }

  const value = {
    addUsageEvent,
    startTracking,
    stopTracking
  }

  return <GAnalyticsContext.Provider value={value}>{children}</GAnalyticsContext.Provider>
}

GAnalyticsProvider.propTypes = {
  children: node.isRequired
}
