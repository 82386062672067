import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { func, object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchFilters, fetchGeography } from 'store/priceCaptureCompliance/actions'
import { getFilters, getGeography } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, DEFAULT_TABLE_PAGE_SIZE, PLACEHOLDERS, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatCurrency, formatPercent } from 'utils/formatters'
import { createDataKey, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

const createOptions = (filters) => {
  const options =
    typeof filters[0] === 'string'
      ? filters.map((filter) => ({ label: filter, value: filter }))
      : filters.map((filter) => ({ label: filter.name, value: filter.id }))

  return sortBy(options, 'label')
}

const GeographyTable = ({ span, fetchFilters, fetchGeography }) => {
  const { translate } = useContext(LangContext)
  const { currentProductType: productType } = useContext(SectorContext)
  const { sectorType, sectorId } = useParams()

  const [error, setError] = useState(null)
  const [geography, setGeography] = useState(SECTOR_LEVELS.TERRITORY)

  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'storesUpdated', order: SORTING_DIRECTION.DESC })

  const defaultFilters = {
    channel: 'all',
    ownershipType: 'all',
    headoffice: 'all',
    banner: 'all',
    tier: 'all',
    material: 'all'
  }

  const [filters, setFilters] = useState(defaultFilters)

  const COLS = [
    {
      field: 'name',
      headerName: translate('app.geo.region')
    },
    {
      field: 'storesUpdated',
      headerName: translate('app.storesUpdated'),
      filter: 'footprint'
    },
    {
      field: 'storesRemaining',
      headerName: translate('app.storesRemaining'),
      filter: 'footprint'
    },
    {
      field: 'storesCount',
      headerName: translate('app.totalStores'),
      filter: 'footprint'
    },
    {
      field: 'skusUpdated',
      headerName: translate('app.skusUpdated'),
      filter: 'sku_completion'
    },
    {
      field: 'skusRemaining',
      headerName: translate('app.skusRemaining'),
      filter: 'sku_completion'
    },
    {
      field: 'skusTotal',
      headerName: translate('app.skusTotal'),
      filter: 'sku_completion'
    },
    {
      field: 'captureStatus',
      headerName: translate('common.status'),
      filter: 'sku_geography'
    },
    {
      field: 'price',
      headerName: translate('app.price'),
      filter: 'customer_sku_geography'
    }
  ]

  const filterDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.FILTERS, {
    sectorType,
    sectorId,
    productType
  })

  const areFiltersLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: filterDataKey }))
  const geographyOptions = useSelector((state) => getFilters(state, { productType }))

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const geographyDataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.GEOGRAPHY, {
    sectorType,
    sectorId,
    productType,
    geography,
    filters,
    offset,
    sortBy: sortBy.column,
    direction: sortBy.order
  })

  const isGeographyLoading = useSelector((state) => isDataKeyLoading(state, { dataKey: geographyDataKey }))
  const rawGeographies = useSelector((state) => getGeography(state) || [])

  const makeRow = useCallback(
    ({
      id,
      name,
      storesUpdated,
      storesPercent,
      storesRemaining,
      storesCount,
      skusUpdated,
      skusPercent,
      skusRemaining,
      skusTotal,
      isCaptured,
      ownershipType,
      address,
      upc,
      price
    }) => {
      const shouldLinkTo =
        ['region', 'district', 'territory', 'customer'].includes(geography) ||
        (geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER)

      const link =
        geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER
          ? `/customer/${sectorId}/actions/pricing/${upc}`
          : `/${geography}/${id}/pace/amplify/price-capture`

      const compoundName = geography === 'sku' ? `${name} - ${id}` : name

      const row = {
        name:
          geography === SECTOR_LEVELS.CUSTOMER ? (
            <StoreInfoTooltip
              displayName={name}
              customerName={name}
              erp={id}
              address={address}
              ownershipType={ownershipType}
              linkTo={link}
            />
          ) : (
            <GeoTableLineHeader name={compoundName} linkTo={shouldLinkTo ? link : null} />
          ),
        skusUpdated: (
          <DataCombined
            primary={skusUpdated ? (+skusUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              skusPercent ? formatPercent(skusPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        ),
        skusRemaining: skusRemaining ? (+skusRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE,
        skusTotal: skusTotal ? (+skusTotal).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }

      if (sectorType === SECTOR_LEVELS.CUSTOMER) {
        row.captureStatus = isCaptured ? translate('app.updated') : translate('app.notUpdated')
        if (geography === 'sku') {
          row.price = formatCurrency(price)
        }
      }

      if (!(sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku')) {
        row.storesUpdated = (
          <DataCombined
            primary={storesUpdated ? (+storesUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              storesPercent ? formatPercent(storesPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        )

        row.storesRemaining = storesRemaining ? (+storesRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE
        row.storesCount = storesCount ? (+storesCount).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }

      return row
    },
    [geography, sectorType, sectorId]
  )

  const channelOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.channels || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const ownershipOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.ownershipTypes || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const headofficeOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.headoffices || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const bannerOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.banners || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const tierOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.tiers || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  const materialOptions = useMemo(() => {
    const options = createOptions(geographyOptions?.materials || [])
    return options
  }, [sectorType, sectorId, productType, areFiltersLoading])

  useEffect(() => {
    if (sectorType && sectorId) {
      setError(null)
      fetchFilters({ productType, sectorType, sectorId, dataKey: filterDataKey }).catch((err) => setError(err))
    }
  }, [sectorType, sectorId, productType])

  useEffect(() => {
    if (sectorType && sectorId) {
      setPage(1)
      setError(null)

      fetchGeography({
        dataKey: geographyDataKey,
        sectorType,
        sectorId,
        productType,
        geography,
        filters,
        offset,
        limit: DEFAULT_TABLE_PAGE_SIZE,
        sortBy: sortBy.column,
        sortDirection: sortBy.order
      }).catch((e) => setError(null))
    }
  }, [sectorType, sectorId, productType, geography, filters, sortBy])

  useEffect(() => {
    if (sectorType && sectorId) {
      setError(null)

      fetchGeography({
        dataKey: geographyDataKey,
        sectorType,
        sectorId,
        productType,
        geography,
        filters,
        offset,
        limit: DEFAULT_TABLE_PAGE_SIZE,
        sortBy: sortBy.column,
        sortDirection: sortBy.order
      }).catch((err) => setError(err))
    }
  }, [offset])

  const columns = useMemo(() => {
    let filteredCols = [...COLS]
    filteredCols[0].headerName = translate(displayOptTableLabels(geography))

    if (sectorType !== SECTOR_LEVELS.CUSTOMER || geography !== 'sku') {
      filteredCols = filteredCols.filter((col) => col.filter !== 'customer_sku_geography')
    }

    if (sectorType !== SECTOR_LEVELS.CUSTOMER) {
      filteredCols = filteredCols.filter((col) => col.filter !== 'sku_geography')
    } else {
      filteredCols = filteredCols.filter((col) => col.filter !== 'footprint')
    }

    return filteredCols
  }, [geography, sectorType])

  const rows = useMemo(() => {
    const { channel, ownershipType, banner, headoffice, tier, material } = filters

    const geographies =
      rawGeographies[
        `geography-${geography}-${productType}-${channel}-${ownershipType}-${banner}-${headoffice}-${tier}-${material}-offset-${offset}-${sortBy.column}-${sortBy.order}`
      ] || []

    return geographies.map(makeRow)
  }, [rawGeographies, sectorType, sectorId, geography, productType, filters, isGeographyLoading, offset, sortBy])

  const hasReachedLastPage = rows?.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(rows) || rows.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const handleSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const updateGeography = (newGeography) => {
    setGeography(newGeography)
    setPage(1)
  }

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geoFilters = useMemo(
    () => [
      {
        id: 'tier',
        label: translate('app.tier'),
        searchPlaceholder: translate('app.placeholders.searchTier'),
        options: tierOptions,
        value: filters.tier,
        onChange: (value) => handleFilterChange({ tier: value })
      },
      {
        id: 'channel',
        label: translate('app.channel'),
        searchPlaceholder: translate('app.placeholders.searchChannel'),
        options: channelOptions,
        value: filters.channel,
        onChange: (value) => handleFilterChange({ channel: value })
      },
      {
        id: 'ownershipType',
        label: translate('app.ownership'),
        searchPlaceholder: translate('app.placeholders.searchOwnership'),
        options: ownershipOptions,
        value: filters.ownershipType,
        onChange: (value) => handleFilterChange({ ownershipType: value })
      },
      {
        id: 'banner',
        label: translate('app.banner'),
        searchPlaceholder: translate('app.placeholders.searchBanner'),
        options: bannerOptions,
        value: filters.banner,
        onChange: (value) => handleFilterChange({ banner: value })
      },
      {
        id: 'headoffice',
        label: translate('app.headOffice'),
        searchPlaceholder: translate('app.placeholders.searchHeadOffice'),
        options: headofficeOptions,
        value: filters.headoffice,
        onChange: (value) => handleFilterChange({ headoffice: value })
      },
      {
        id: 'material',
        label: translate('app.material'),
        searchPlaceholder: translate('app.placeholders.searchMaterial'),
        options: materialOptions,
        value: filters.material,
        onChange: (value) => handleFilterChange({ material: value })
      }
    ],
    [translate, tierOptions, channelOptions, ownershipOptions, bannerOptions, headofficeOptions, materialOptions]
  )

  if (areFiltersLoading) {
    return (
      <Card
        title={translate('app.performance')}
        disclaimer={translate('amplify.priceCapture.warn.dataFromPreviousDays')}
        span={span}
      >
        <WrappedSpinner icon="spinner" />
      </Card>
    )
  }

  const appliedFilters = Object.values(filters).filter((value) => value !== 'all').length

  const resetFilters = () => {
    setFilters(defaultFilters)
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="price-capture-geo-toggle"
          includeChannel
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          geography={geography}
          setGeography={updateGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          filters={geoFilters}
          appliedFilters={appliedFilters}
          resetFilters={resetFilters}
          onFiltersChange={(newFilters) => {
            // Merge with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="price-capture-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyLoading}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyLoading}
        error={error}
        rows={rows}
        columns={columns}
        activeColumn={sortBy}
        onColumnClick={handleSorted}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object,
  fetchFilters: func,
  fetchGeography: func
}

export default connect(null, {
  fetchFilters,
  fetchGeography
})(GeographyTable)
