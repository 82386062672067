import queryString from 'query-string'

import { api } from 'store/api'

export const submitNewProgram = (program) => api.post('/programs', program)

export const submitUpdatedCustomerSellInProgram = (program) =>
  api.put(`/customers-sell-in-programs/${program.sellInProgramId}/${program.customerId}`, program)

export const submitProgramDeletion = (programId) => api.delete(`/programs/${programId}`)

export const fetchSellInProgramsForTerritory = (territoryId) => {
  return api.get(`/sell-in-programs?${queryString.stringify({ territoryId }, { skipNull: true })}`)
}

export const fetchSellInProgramForTerritory = ({ sellInProgramId, territoryId }) => {
  return api.get(`/sell-in-programs/${sellInProgramId}?${queryString.stringify({ territoryId }, { skipNull: true })}`)
}

export const downloadSellInProgramSkuDataCsv = ({ id, territoryId }) => {
  return api.get(`/sell-in-programs/${id}/sku-data-csv?${queryString.stringify({ territoryId }, { skipNull: true })}`)
}

export const submitCustomerSellInProgramException = (exception) =>
  api.post(`/customers-sell-in-programs/${exception.sellInProgramId}/${exception.customerId}/exception`, exception)

export const cancelCustomerSellInProgramException = (exception) =>
  api.delete(
    `/customers-sell-in-programs/${exception.sellInProgramId}/${exception.customerId}/exception/${exception.id}`,
    exception
  )

export const sendSellInRetailerEmail = (program) =>
  api.post(`/customers-sell-in-programs/${program.sellInProgramId}/${program.customerId}/retailer-email`)

export const sendSellInTerritoryRetailerEmail = ({ territoryId, sellInProgramId }) =>
  api.post(`/customers-sell-in-programs/${sellInProgramId}/retailer-email/${territoryId}`)
