import React from 'react'
import { Field, useFormikContext } from 'formik'
import { array, bool, object, string } from 'prop-types'

import Dropdown from 'components/Dropdown'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'
import Label from 'components/Label'

import { AGREEMENT_TERMS } from 'utils/constants'

const DisabledInputField = ({ question, controlAnswer, answer }) => {
  return <Input name={question.id} disabled value={answer} />
}

DisabledInputField.propTypes = {
  question: object.isRequired,
  controlAnswer: string,
  answer: string
}

const getValidOptions = (preset, options) => {
  const allOptionsAreNumbers = options.every(({ value }) => !isNaN(+value))
  return allOptionsAreNumbers ? options.filter(({ value }) => +value <= +preset) : options
}

const DropdownField = ({ question, controlAnswer, preset, isLocked }) => {
  const options = getValidOptions(preset, question.options)
  return (
    <Field
      disabled={isLocked}
      component={Dropdown}
      placeholder="Select..."
      name={`answers.${question.id}`}
      options={options}
    />
  )
}

DropdownField.propTypes = {
  question: object.isRequired,
  controlAnswer: string,
  preset: string,
  isLocked: bool
}

const getAnswerInput = (type) => {
  const defaultAnswerInputsByType = {
    dropdown: DropdownField,
    readOnly: DisabledInputField
  }
  return defaultAnswerInputsByType[type]
}

const RenderSellInQuestionExceptionsForm = ({ questions, customerQuestions }) => {
  if (!customerQuestions) return null
  return (
    <>
      {questions.map((question) => {
        const customerQuestion = customerQuestions[question.id]
        if (!customerQuestion) return null
        const controlQuestion =
          question.controlQuestionId && questions.find(({ id }) => id === question.controlQuestionId)
        const questionLabel = controlQuestion ? (
          <span>
            If {controlQuestion.question} = {question.controlAnswer}
            <br />
            {question.question}
          </span>
        ) : (
          question.question
        )

        return (
          <FieldsetItem key={question.id} half>
            <Label>{questionLabel}</Label>
            <Field component={Input} name={`exception.questions.${question.id}`} disabled />{' '}
            {/* disabled -> NOD-1255 Users must now ask for changes through the comment box. */}
          </FieldsetItem>
        )
      })}
    </>
  )
}

RenderSellInQuestionExceptionsForm.propTypes = {
  questions: array.isRequired,
  customerQuestions: object
}

const RenderSellInQuestionsForm = ({ questions, customerQuestions }) => {
  const { values, setFieldValue } = useFormikContext()

  if (!customerQuestions || !questions) return null

  return (
    <>
      {questions.map((question) => {
        const customerQuestion = customerQuestions[question.id]
        if (!customerQuestion) return null
        const QuestionInput = getAnswerInput(question.type)
        const controlAnswer =
          question.controlQuestionId && values?.answers && values.answers[question.controlQuestionId]
        const controlAnswerIsValid = controlAnswer && controlAnswer === question.controlAnswer

        if (question.controlAnswer && !controlAnswerIsValid) {
          if (values.answers[question.id]) {
            setFieldValue(`answers.${question.id}`, '')
          }
          return null
        }
        if (
          question.controlAnswer &&
          controlAnswerIsValid &&
          question.type === 'readOnly' &&
          !values.answers[question.id]
        ) {
          setFieldValue(`answers.${question.id}`, customerQuestion.preset)
        }
        const answer = values.answers[question.id]
        return (
          <FieldsetItem key={question.id} half>
            <Label>{question.question}</Label>
            <QuestionInput
              question={question}
              controlAnswer={controlAnswer}
              preset={customerQuestion.preset}
              answer={answer}
            />
          </FieldsetItem>
        )
      })}
    </>
  )
}

RenderSellInQuestionsForm.propTypes = {
  questions: array.isRequired,
  customerQuestions: object
}

const RenderLockedSellInQuestions = ({ questions, customerQuestions, answers }) => {
  if (!customerQuestions) return null
  return (
    <>
      {questions.map((question) => {
        const customerQuestion = customerQuestions[question.id]
        if (!customerQuestion) return null
        const controlAnswer = question.controlQuestionId && answers[question.controlQuestionId]
        if (question.controlAnswer && controlAnswer !== question.controlAnswer) {
          return null
        }
        const answer = answers[question.id]
        return (
          <FieldsetItem key={question.id} half>
            <Label>{question.question}</Label>
            <DisabledInputField question={question} answer={answer} />
          </FieldsetItem>
        )
      })}
    </>
  )
}

RenderLockedSellInQuestions.propTypes = {
  questions: array.isRequired,
  customerQuestions: object,
  answers: object
}

const RenderSellInQuestions = ({ isLocked, exceptionType, ...props }) => {
  const isOptionChangeException = exceptionType === AGREEMENT_TERMS
  if (isOptionChangeException) return <RenderSellInQuestionExceptionsForm {...props} />
  if (exceptionType) return null
  if (isLocked) return <RenderLockedSellInQuestions {...props} />
  return <RenderSellInQuestionsForm {...props} />
}

RenderSellInQuestions.propTypes = {
  isLocked: bool,
  exceptionType: string
}

export default RenderSellInQuestions
