import React, { useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { array, func, object, string } from 'prop-types'
import styled from 'styled-components'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Button from 'components/button/Button'
import Label from 'components/Label'
import Pill from 'components/Pill'
import SAQDetailRow from 'components/SAQDetailRow'
import Table from 'components/table'

import { sellInStatusColors } from 'styles/colors'

const SellInExceptionInner = styled.div`
  max-height: 90vh;
  overflow-y: auto;
`

const ExceptionDetails = ({ exception, onCancelException, sellInQuestions, sellInTargetSkus, preferredLanguage }) => {
  const [isCancellationConfirmationVisible, setCancellationConfirmationVisible] = useState(false)
  const exceptionIsPending = exception?.exceptionStatus === 'pending'

  const exceptionQuestions = useMemo(() => {
    if (isEmpty(exception?.questions)) return
    return Object.entries(exception.questions)
      .reduce((acc, [qId, requestedAnswer]) => {
        const { question } = sellInQuestions.find(({ id }) => qId === id) || {}
        if (!question) return acc
        return [...acc, { question, requestedAnswer }]
      }, [])
      .filter(Boolean)
  }, [exception?.questions, sellInQuestions])

  const exceptionProducts = useMemo(() => {
    if (isEmpty(exception?.skus)) return

    return Object.entries(exception.skus)
      .reduce((acc, [pId, minMax]) => {
        const product = sellInTargetSkus.find(({ productId }) => {
          return pId.replace('p', '') === productId
        })
        if (!product) return acc
        const name = product.product[`${preferredLanguage}Name`]
        return [
          ...acc,
          {
            name,
            origMin: product.minQty,
            origMax: product.maxQty,
            requestedMin: minMax.minQty,
            requestedMax: minMax.maxQty
          }
        ]
      }, [])
      .filter(Boolean)
  }, [exception?.skus, preferredLanguage, sellInTargetSkus])

  return (
    <SellInExceptionInner>
      <div>
        <SAQDetailRow
          label="Request"
          content={
            <p>
              {exception.requestComment}
              <br />
              <small>{new Date(exception.createdAt).toDateString()}</small>
            </p>
          }
        />
        {exception?.responseComment && (
          <SAQDetailRow
            label="Response"
            content={
              <p>
                {exception.responseComment}
                <br />
                <small>{new Date(exception.updatedAt).toDateString()}</small>
              </p>
            }
          />
        )}
        {exception.requestedStatus && (
          <SAQDetailRow
            label="Requested program status"
            content={<Pill color={sellInStatusColors[exception.requestedStatus]}>{exception.requestedStatus}</Pill>}
          />
        )}
      </div>
      <div>
        {!isEmpty(exceptionQuestions) && (
          <BasicAccordion title="Question Change requests">
            <Table
              data={exceptionQuestions}
              columns={[
                {
                  name: 'question',
                  header: 'Question',
                  body: {
                    labelGenFn: (record) => {
                      return <Label>{record.question}</Label>
                    }
                  }
                },
                {
                  name: 'requestedAnswer',
                  header: 'Requested Answer'
                }
              ]}
            />
          </BasicAccordion>
        )}
        {!isEmpty(exceptionProducts) && (
          <BasicAccordion title="SKU Change requests">
            <Table
              data={exceptionProducts}
              columns={[
                {
                  name: 'name',
                  header: 'Product'
                },
                exceptionIsPending && {
                  name: 'origMin',
                  header: 'Orig. Min'
                },
                {
                  name: 'requestedMin',
                  header: 'Req. Min',
                  body: {
                    labelGenFn: (record) =>
                      record.origMin === record.requestedMin ? record.requestedMin : <b>{record.requestedMin}</b>
                  }
                },
                exceptionIsPending && {
                  name: 'origMax',
                  header: 'Orig. Max'
                },
                {
                  name: 'requestedMax',
                  header: 'Req. Max',
                  body: {
                    labelGenFn: (record) =>
                      record.origMax === record.requestedMax ? record.requestedMax : <b>{record.requestedMax}</b>
                  }
                }
              ].filter(Boolean)}
            />
          </BasicAccordion>
        )}
      </div>
      {exceptionIsPending && (
        <div className="mt-2 p-1">
          {isCancellationConfirmationVisible ? (
            <div className="flex flex-row gap-2">
              <Button primary onClick={onCancelException} full>
                Yes, delete exception
              </Button>
              <Button secondary onClick={() => setCancellationConfirmationVisible(false)} full>
                No, keep exception
              </Button>
            </div>
          ) : (
            <Button secondary onClick={() => setCancellationConfirmationVisible(true)} full>
              Cancel this exception request
            </Button>
          )}
        </div>
      )}
    </SellInExceptionInner>
  )
}

ExceptionDetails.propTypes = {
  exception: object.isRequired,
  onCancelException: func.isRequired,
  sellInQuestions: array,
  sellInTargetSkus: array,
  preferredLanguage: string
}

export default ExceptionDetails
