import React, { useContext, useState } from 'react'
import { arrayOf, bool, func, node, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import FabButton from 'components/button/FabButton'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

import Actions from './Actions'
import SectionCard from './SectionCard'

const Section = ({ title, icon, children }) => {
  return (
    <div className="flex max-w-full flex-col gap-3">
      <div className="flex items-center justify-center gap-1.5 rounded-md bg-brand-50 p-2 text-slate-500">
        <Icon icon={icon} compact />
        <h3 className="text-sm font-medium uppercase ">{title}</h3>
      </div>
      <div className="flex flex-col gap-3 text-sm">{children}</div>
    </div>
  )
}

Section.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  children: node.isRequired
}

const List = ({ items, title, maxItems = 5 }) => {
  const { translate } = useContext(LangContext)

  const [isViewAll, setIsViewAll] = useState(false)

  const listItems = isViewAll ? items : items.slice(0, maxItems)

  return (
    <div className="flex flex-col gap-2">
      {title && <h4 className="text-base font-medium text-slate-900">{title}</h4>}
      <div className="flex flex-col gap-2">
        <ul className="flex list-disc flex-col gap-1.5 pl-4 text-slate-900 *:leading-snug marker:text-slate-700">
          {listItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        {items.length > maxItems && (
          <div className="pl-4">
            <Button tertiary onClick={() => setIsViewAll(!isViewAll)}>
              {isViewAll ? translate('common.viewLess') : translate('common.viewAll')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

List.propTypes = {
  items: arrayOf(string).isRequired,
  maxItems: number,
  title: string
}

const OpportunitiesModal = ({ open, onOpenChange }) => {
  const { translate } = useContext(LangContext)

  // //////////////////////////////////////////////////////////////
  // PLACEHOLDER DATA
  // //////////////////////////////////////////////////////////////
  const PLACEHOLDER_DATA = {
    performance: {
      sellIn: ['This is a placeholder', 'This is a placeholder', 'This is a placeholder', 'This is a placeholder'],
      sellOut: [
        'This is a placeholder',
        'This is a placeholder',
        'This is a placeholder',
        'This is a placeholder',
        'This is a placeholder',
        'This is a placeholder'
      ],
      healthCheck: ['This is a placeholder', 'This is a placeholder', 'This is a placeholder'],
      priceCheck: ['This is a placeholder', 'This is a placeholder', 'This is a placeholder']
    },
    actions: [
      'Call the customer to discuss the opportunity',
      'Send a follow-up email',
      'Schedule a meeting with the customer',
      'Call the customer to discuss the opportunity',
      'Send a follow-up email',
      'Schedule a meeting with the customer',
      'Call the customer to discuss the opportunity',
      'Send a follow-up email',
      'Schedule a meeting with the customer'
    ],
    reminders: [
      'Call the customer to discuss the opportunity',
      'Send a follow-up email',
      'Schedule a meeting with the customer'
    ]
  }
  // //////////////////////////////////////////////////////////////

  return (
    <Modal
      size="xLarge"
      trigger={<FabButton icon="list" />}
      footer={
        <div className="flex w-full justify-end">
          <Button primary icon="checkmark" onClick={() => onOpenChange(false)}>
            {translate('common.okay')}
          </Button>
        </div>
      }
      title={translate('close.objectives.title')}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className="grid max-w-full gap-x-5 gap-y-8 md:grid-cols-2">
        <div className="min-w-0 max-w-full md:col-span-2">
          <Section title="Performance Soundbites" icon="chart">
            <div className="grid max-w-full gap-5 md:grid-cols-2">
              <SectionCard
                title="Race to Cycle Target"
                tip="Next order days by end of cycle: 9/10/2024, 9/17/2024, 9/24/2024"
                data={{
                  fmc: { summary: '-600 ctns to reach cycle goal' },
                  vape: { summary: '-350 units to reach cycle goal' }
                }}
                amplifyLink="/"
              />
              <SectionCard
                title="Spotting Hidden Opportunities"
                tip="Seize the distribution gap! Close the missing products in FMC and Vape to maximize performance"
                data={{
                  fmc: { summary: '4 SKUs are not available on the shelves' },
                  vape: { summary: '5 SKUs are not available on the shelves' }
                }}
                amplifyLink="/"
              />
              <SectionCard
                title="Unlock the Sell-Out Surge"
                data={{
                  fmc: {
                    summary:
                      'The share of Volume Dropped by -1.06 pp this week, not at 44.95%. We need a targeted push to regain momentum in key segments!'
                  },
                  vape: {
                    summary:
                      'The share of Volume Dropped by -1.06 pp this week, not at 44.95%. We need a targeted push to regain momentum in key segments!'
                  }
                }}
                amplifyLink="/"
              />
              <SectionCard
                title="Fine Tune Pricing Opportunities"
                tip="Fine-tune your pricing for big sell-out gains! Stay competitive and aligned with BAT strategies! Check the top 5 products with the largest pricing gaps vs. their lowest peer prices below!"
                data={{
                  fmc: { summary: undefined },
                  vape: { summary: undefined }
                }}
                amplifyLink="/"
              />
            </div>
          </Section>
        </div>
        <Section title={translate('common.actions')} icon="actions">
          <Actions />
        </Section>
        <Section title={translate('common.reminders')} icon="notification">
          <List items={PLACEHOLDER_DATA.reminders} />
        </Section>
      </div>
    </Modal>
  )
}

OpportunitiesModal.propTypes = {
  open: bool,
  onOpenChange: func
}

export default OpportunitiesModal
