import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import config from 'config'

import GAnalyticsContext from 'context/GAnalyticsContext'
import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import CycleFocusPlaceholder from 'components/Placeholders/CycleFocusPlaceholder'
import EngagementCardPlaceholder from 'components/Placeholders/EngagementCardPlaceholder'
import PriceCheckCardPlaceholder from 'components/Placeholders/PriceCheckCardPlaceholder'

import CycleFocusCard from './CycleFocusCard'
import EngagementCard from './EngagementCard'
import HealthCheckCard from './HealthCheckCard'
import PlanHeader from './PlanHeader'
import PriceCheckCard from './PriceCheckCard'
import SellinCard from './SellinCard'
import SelloutCard from './SelloutCard'

const PlanLayout = () => {
  const { translate } = useContext(LangContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const { addUsageEvent } = useContext(GAnalyticsContext)

  const { sectorType, sectorId } = useParams()
  const navigate = useNavigate()

  document.title = 'Plan'

  return (
    <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
      {!isWideLayout && <PlanHeader header={translate('app.pace.plan')} />}

      <SellinCard
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }}
        amplifyAction={() => {
          addUsageEvent('plan_sell_in_amplify_button_click')
          navigate(`/${sectorType}/${sectorId}/pace/amplify/sell-in`)
        }}
      />

      <SelloutCard
        title={currentProductType === 'fmc' ? translate('app.acronyms.SOM') : translate('app.acronyms.SOV')}
        activeProductType={currentProductType}
        span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 3 }}
        amplifyAction={() => {
          addUsageEvent('plan_sell_out_amplify_button_click')
          navigate(`/${sectorType}/${sectorId}/pace/amplify/sell-out`)
        }}
      />

      <HealthCheckCard
        span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
        amplifyAction={() => {
          addUsageEvent('plan_health_check_amplify_button_click')
          navigate(`/${sectorType}/${sectorId}/pace/amplify/distro`)
        }}
        oosInvAmplify={() => {
          addUsageEvent('plan_healthcheck_oos_amp_button_click')
          navigate(`/${sectorType}/${sectorId}/pace/amplify/inventory`)
        }}
      />

      {config.featureFlags.pricingPace ? (
        <PriceCheckCard
          span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
          amplifyAction={() => {
            addUsageEvent('plan_price_check_amplify_button_click')
            navigate(`/${sectorType}/${sectorId}/pace/amplify/price-capture`)
          }}
          complianceAmplify={() => {
            addUsageEvent('plan_pricecheckcomp_amplify_button_click')
            navigate(`/${sectorType}/${sectorId}/pace/amplify/price-compliance`)
          }}
        />
      ) : (
        <PriceCheckCardPlaceholder span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }} />
      )}

      {config.featureFlags.egagement ? (
        <EngagementCard
          span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }}
          amplifyAction={() => {
            addUsageEvent('plan_engagement_amplify_button_click')
            navigate(`/${sectorType}/${sectorId}/pace/amplify/extra-hub`)
          }}
        />
      ) : (
        <EngagementCardPlaceholder span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 2 }} />
      )}

      {config.featureFlags.cycleFocusPace ? (
        <CycleFocusCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
      ) : (
        <CycleFocusPlaceholder span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
      )}
    </div>
  )
}

export default PlanLayout
