import React from 'react'
import { number, string } from 'prop-types'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'

export const SAQItemsWrap = styled.div`
  max-height: 30vh;
  overflow: auto;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: ${spacing.small};
  }
`

const SAQItem = ({ item, quantity }) => (
  <Container>
    <span className="notranslate" translate="no">
      {item}
    </span>
    <span>× {quantity}</span>
  </Container>
)

SAQItem.propTypes = {
  item: string.isRequired,
  quantity: number.isRequired
}

export default SAQItem
