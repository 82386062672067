import React from 'react'

import AdvanceLogo from 'static/images/advance/adv_trade_icon.png'

export const AdvanceLogotype = () => (
  <div className="-ml-4 mt-2 w-[calc(100%+2rem)]">
    <div className="flex aspect-[10/4] w-full rounded-md bg-gradient-to-b from-[#25386F] to-[#3D4865] pl-3 text-white">
      <div className="flex items-center gap-2">
        <img className="h-[48px] w-[48px]" src={AdvanceLogo} alt="Advance" />
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-medium uppercase leading-none">Trade Hub</h4>
          <div className="flex flex-col gap-1">
            <span className="text-3xs leading-none opacity-80">Action & Analytics</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const AdvanceLogotypeForMobile = () => (
  <div className="-ml-4 -mt-4 w-[calc(100%+2rem)]">
    <div className="flex w-full rounded-md bg-gradient-to-b from-[#25386F] to-[#3D4865] py-5 pl-3 text-white">
      <div className="flex items-center gap-2">
        <img className="h-[48px] w-[48px]" src={AdvanceLogo} alt="Advance" />
        <div className="flex flex-col gap-2">
          <h4 className="text-base font-medium uppercase leading-none">Trade Hub</h4>
          <div className="flex flex-col gap-1">
            <span className="text-2xs leading-none opacity-80">Action & Analytics</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
