import queryString from 'query-string'

import { api } from 'store/api'

export const fetchPlanningEngagement = ({ id, sectorLevel }) =>
  api.get(
    `/engagement?${queryString.stringify(
      {
        id,
        sectorLevel
      },
      { skipNull: true }
    )}`
  )
