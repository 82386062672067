import { readableColor } from 'polished'
import styled from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { greyLight, white } from 'styles/colors'

export default styled(SmallCaps)`
  background-color: ${(props) => props.color || greyLight};
  color: ${(props) => (props.textColor ? props.textColor : props.color ? readableColor(props.color) : white)};
  border-radius: 10px;
  padding: 2px 6px 3px 7px;
  font-size: 11px;
  text-align: center;
`
