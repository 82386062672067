import isEmpty from 'lodash/isEmpty'
import snakeCase from 'lodash/snakeCase'
import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

const productTypeFromProps = (state, props) => props.activeProductType
const vapeCategoryFromProps = (state, props) => props.vapeCategory
const vapeChannelFromProps = (state, props) => props.vapeChannel
const geographyFromProps = (state, props) => props.geography
const offsetFromProps = (state, props) => props.offset
const filtersFromProps = (state, props) => props.filters
const sortByFromProps = (state, props) => props.sortBy
const directionFromProps = (state, props) => props.direction

export const trendedDistroData = createSelector(
  dataSourceFromSectorLevel,
  productTypeFromProps,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  (sector, productType, vapeCategory, vapeChannel) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-trended-${productType}-${vapeCategory}-${vapeChannel}`] || {}

    return {
      ...amounts
    }
  }
)

export const brandDistroData = createSelector(
  dataSourceFromSectorLevel,
  productTypeFromProps,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  sortByFromProps,
  directionFromProps,
  (sector, productType, vapeCategory, vapeChannel, sortBy, direction) => {
    if (isEmpty(sector)) return {}
    const data = sector[`distro-brand-${productType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`] || []

    return [...data]
  }
)

export const materialDistroData = createSelector(
  dataSourceFromSectorLevel,
  productTypeFromProps,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  sortByFromProps,
  directionFromProps,
  (sector, productType, vapeCategory, vapeChannel, sortBy, direction) => {
    if (isEmpty(sector)) return {}
    const data = sector[`distro-material-${productType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`] || []

    return [...data]
  }
)

export const geographyDistroData = createSelector(
  dataSourceFromSectorLevel,
  geographyFromProps,
  productTypeFromProps,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  offsetFromProps,
  filtersFromProps,
  sortByFromProps,
  directionFromProps,
  (sector, geography, productType, vapeCategory, vapeChannel, offset, filters, sortBy, direction) => {
    if (isEmpty(sector)) return {}
    let amounts =
      sector[
        `distro-geography-${productType}-${geography}-${vapeCategory}-${vapeChannel}-offset-${offset}-${
          filters && !isEmpty(filters)
            ? Object.entries(filters)
                .map(([key, value]) => `${key}-${snakeCase(value)}`)
                .join('-')
            : 'no-filters'
        }-${sortBy}-${direction}`
      ] || []

    amounts = amounts.map((distro) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${distro.id}/pace/amplify/distro`,
          ...distro
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${distro.id}/pace/amplify/distro`,
          ...distro
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${distro.id}/pace/amplify/distro`,
          ...distro
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${distro.id}/pace/amplify/distro`,
          ...distro
        }
      }
      return {
        linkTo: null,
        ...distro
      }
    })

    return {
      ...amounts
    }
  }
)

export const summaryDistroData = createSelector(
  productTypeFromProps,
  dataSourceFromSectorLevel,
  vapeCategoryFromProps,
  vapeChannelFromProps,
  (productType, sector, vapeCategory, vapeChannel) => {
    if (isEmpty(sector)) return {}
    const amounts = sector[`distro-summary-${productType}-${vapeCategory}-${vapeChannel}`] || {}

    return {
      ...amounts[0]
    }
  }
)
