import React from 'react'
import { Link } from 'react-router-dom'
import { stripUnit } from 'polished'
import { object } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, grey, greyDark, greyLight, secondaryColor, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
`

const Header = styled.header`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const NameWrap = styled.div`
  flex: 1;
`

const Name = styled.strong`
  display: block;
  color: ${greyDark};
`

const Rank = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const Action = styled(Link)`
  color: ${greyLight};
  transition: color ${animationTime} ${animationCurve};
  margin-left: ${spacing.small};

  &:hover,
  &:focus {
    color: ${grey};
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled.div`
  display: block;
  flex: 1;
`

const Label = styled.span`
  display: block;
  font-size: ${tinyFontSize};
`

const Stat = styled.strong`
  display: block;
  font-size: ${smallFontSize};
  color: ${secondaryColor};
  margin-bottom: 0;
`

const KeyCard = ({ card }) => {
  const { id, programName, customerId, perfToDate, target, perfVsTarget, storeRank } = card

  return (
    <Container>
      <Header>
        <NameWrap>
          <Name>{programName}</Name>
          {storeRank && <Rank>Store rank: {storeRank}</Rank>}
        </NameWrap>
        <Action to={`/customers/${customerId}/investments/key-accounts/${id}`}>
          <Icon icon="more" />
        </Action>
      </Header>
      <Inner>
        <Item>
          <Label>Target</Label>
          <Stat>{target}</Stat>
        </Item>
        <Item>
          <Label>Perf to date</Label>
          <Stat>{perfToDate}</Stat>
        </Item>
        <Item>
          <Label>Perf vs Target</Label>
          <Stat>{perfVsTarget}</Stat>
        </Item>
      </Inner>
    </Container>
  )
}

KeyCard.propTypes = {
  card: object
}

export default KeyCard
