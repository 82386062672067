import React, { useEffect, useMemo, useState } from 'react'
import capitalize from 'lodash/capitalize'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { node, object } from 'prop-types'

import { api } from 'store/api'
import { fetchCustomerCalls } from 'store/customerCalls/endpoints'
import { calendarEventFromCall } from 'store/customerCalls/selectors'
import { fetchEmployeeOots } from 'store/employeeOots/endpoints'
import { calendarEventFromOOT } from 'store/employeeOots/selectors'

const DistrictManagerContext = React.createContext()
export default DistrictManagerContext

export const DistrictManagerProvider = ({ user, children }) => {
  const [mountTime, setMountTime] = useState()
  const [employeePayoutOptions, setUserPayoutOptions] = useState(null)
  const [employeeScheduleOptions, setUserScheduleOptions] = useState(null)
  const [loading, setLoading] = useState(false)

  const [employeeSchedules, setEmployeeSchedules] = useState({})
  const [beforeAfterDates, setBeforeAfterDates] = useState({})
  // const [selectedEmployeeSchedule, setSelectedEmployeeSchedule] = useState()

  // const [selectedDate, setSelectedDate] = useState(new Date().toISOString())

  const fetchEmployeeSchedule = async (calendarDate, employeeId) => {
    const beforeAfterDate = beforeAfterDates[employeeId]
    if (
      !beforeAfterDate ||
      !beforeAfterDate.fetchAt ||
      (beforeAfterDate.fetchAt && moment().diff(moment(beforeAfterDate.fetchAt), 'hours') > 8) ||
      moment(calendarDate).add(1, 'week').isAfter(moment(beforeAfterDate.before)) ||
      moment(calendarDate).subtract(1, 'week').isBefore(moment(beforeAfterDate.after))
    ) {
      const before = moment(calendarDate).add(1, 'month')
      const after = moment(calendarDate).subtract(1, 'month')
      const {
        data: { customerCalls }
      } = await fetchCustomerCalls({ before, after, employeeId })
      const {
        data: { employeeOots }
      } = await fetchEmployeeOots({ before, after, employeeId })
      const newSched = flatten(
        customerCalls.map(calendarEventFromCall).concat(employeeOots.map(calendarEventFromOOT))
      ).filter(Boolean)
      const existingSched = (employeeSchedules[employeeId] || []).filter(
        ({ start, end }) =>
          !moment(start).isBetween(after.startOf('day'), before.endOf('day')) &&
          !moment(end).isBetween(after.startOf('day'), before.endOf('day'))
      )
      const employeeSched = uniqBy(newSched.concat(existingSched), 'id')
      const shouldUpdateFetchAt =
        !beforeAfterDate?.fetchAt || moment().diff(moment(beforeAfterDate?.fetchAt), 'hours') > 8
      setBeforeAfterDates({
        ...beforeAfterDates,
        [employeeId]: {
          before: moment.max(before, beforeAfterDate?.before || before),
          after: moment.min(after, beforeAfterDate?.after || after),
          fetchAt: shouldUpdateFetchAt ? moment() : beforeAfterDate?.fetchAt
        }
      })
      setEmployeeSchedules({ ...employeeSchedules, [employeeId]: employeeSched })
    }
  }

  const districtEmployeeOptions = useMemo(() => {
    return user?.districtTeam?.length
      ? user.districtTeam.map(({ id, firstName, lastName, primaryTerritory }) => ({
          label: `${primaryTerritory ? `${primaryTerritory.name} - ` : ''}${capitalize(firstName)} ${capitalize(
            lastName
          )}`,
          value: id
        }))
      : []
  }, [user])

  useEffect(() => {
    let mounted = true
    let allEmployeePayoutsOptions = []
    let allEmployeeScheduleOptions = []

    async function fetchAllUserPayouts() {
      const { data } = await api.get('/employee-payouts')

      allEmployeePayoutsOptions = data.employeePayouts.map(({ id, firstName, lastName, payouts }) => ({
        label: `${capitalize(firstName)} ${capitalize(lastName)}`,
        value: id,
        payouts
      }))
    }

    async function fetchAllUserSchedules() {
      const { data } = await api.get('/customer-calls/users')

      allEmployeeScheduleOptions = data.employees.map(({ id, firstName, lastName, primaryTerritory }) => ({
        label: `${primaryTerritory ? `${primaryTerritory.name} - ` : ''}${capitalize(firstName)} ${capitalize(
          lastName
        )}`,
        value: id
      }))
    }

    if (mountTime) {
      const dmOptions = user?.districtTeam?.length
        ? user.districtTeam.map(({ id, firstName, lastName, payouts, primaryTerritory }) => ({
            label: `${primaryTerritory ? `${primaryTerritory.name} - ` : ''}${capitalize(firstName)} ${capitalize(
              lastName
            )}`,
            value: id,
            payouts
          }))
        : []
      if (user.isPayoutsAdmin) {
        setLoading(true)
        fetchAllUserPayouts()
          .then(() => {
            if (mounted) {
              const sortedEmployees = allEmployeePayoutsOptions.sort((a, b) => a.label.localeCompare(b.label))
              const allOptions = [{ label: 'My payouts', value: user.id, payouts: user.payouts }].concat(
                sortedEmployees
              )
              setUserPayoutOptions(allOptions)
            }
          })
          .catch((err) => {
            console.log(err)
          })
        setLoading(false)
      } else {
        setUserPayoutOptions(
          [{ label: 'My payouts', value: user.id, payouts: user.payouts }].concat(
            dmOptions.sort((a, b) => a.label.localeCompare(b.label))
          )
        )
      }
      if (user.isScheduleAdmin) {
        setLoading(true)
        fetchAllUserSchedules()
          .then(() => {
            if (mounted) {
              setUserScheduleOptions(allEmployeeScheduleOptions.sort((a, b) => a.label.localeCompare(b.label)))
            }
          })
          .catch((err) => {
            console.log(err)
          })
        setLoading(false)
      } else {
        setUserScheduleOptions(dmOptions.sort((a, b) => a.label.localeCompare(b.label)))
      }
    }
    return () => {
      mounted = false
    }
  }, [mountTime])

  return (
    <DistrictManagerContext.Provider
      value={{
        districtEmployeeOptions,
        employeePayoutOptions,
        employeeScheduleOptions,
        setMountTime,
        loading,
        fetchEmployeeSchedule,
        employeeSchedules
      }}
    >
      {children}
    </DistrictManagerContext.Provider>
  )
}

DistrictManagerProvider.propTypes = {
  children: node.isRequired,
  user: object.isRequired
}
