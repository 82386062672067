import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { bool, func, string } from 'prop-types'

import Dropdown from 'components/Dropdown'

// import SegmentControl from 'components/SegmentControl'
import { GEOGRAPHIES, SECTOR_LEVELS } from 'utils/constants'

export const displayOptTableLabels = (geography) => {
  const selectedOption = GEOGRAPHIES.find((geo) => geo.value === geography)
  if (selectedOption && selectedOption.label) return selectedOption.label
  return geography
}

const GeographyToggle = ({
  geography,
  setGeography,
  includeActivity = false,
  includeCampaign = false,
  includeBrand = false,
  includeChannel = false,
  includeSku = false,
  includeOwnership = false,
  includeBrandVariant = false,
  includeUser = false
}) => {
  const { sectorType } = useParams()

  const geographyOptions = useMemo(() => {
    let options = GEOGRAPHIES

    if (sectorType === SECTOR_LEVELS.DISTRICT) {
      options = options.filter((opt) => !['province', 'region'].includes(opt.value))
    } else if (sectorType === SECTOR_LEVELS.TERRITORY) {
      options = options.filter((opt) => !['province', 'region', 'district'].includes(opt.value))
    } else if (sectorType === SECTOR_LEVELS.CUSTOMER) {
      options = options.filter(
        (opt) =>
          !['headoffice', 'channel', 'region', 'district', 'territory', 'province', 'ownership'].includes(opt.value)
      )
    }

    if (!includeActivity) {
      options = options.filter((opt) => opt.value !== 'activity')
    }

    if (!includeCampaign) {
      options = options.filter((opt) => opt.value !== 'campaign')
    }

    if (!includeBrand) {
      options = options.filter((opt) => opt.value !== 'brand')
    }

    if (!includeChannel) {
      options = options.filter((opt) => opt.value !== 'channel')
    }

    if (!includeOwnership) {
      options = options.filter((opt) => opt.value !== 'ownership')
    }

    if (!includeSku) {
      options = options.filter((opt) => opt.value !== 'sku')
    }

    if (!includeSku) {
      options = options.filter((opt) => !['sku'].includes(opt.value))
    }

    if (!includeBrandVariant) {
      options = options.filter((opt) => !['brand_variant'].includes(opt.value))
    }

    if (!includeUser) {
      options = options.filter((opt) => !['user'].includes(opt.value))
    }

    return options
  }, [includeActivity, includeBrand, includeBrandVariant, sectorType])

  useEffect(() => {
    if (!geographyOptions.some((opt) => opt.value === geography)) setGeography(geographyOptions[0].value)
  }, [geographyOptions])

  return (
    <Dropdown name="type" onChange={(e) => setGeography(e.target.value)} value={geography} options={geographyOptions} />
  )
}

GeographyToggle.propTypes = {
  geography: string,
  setGeography: func,
  includeActivity: bool,
  includeCampaign: bool,
  includeBrand: bool,
  includeChannel: bool,
  includeSku: bool,
  includeOwnership: bool,
  includeBrandVariant: bool,
  includeUser: bool
}

export default GeographyToggle
