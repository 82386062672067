import React from 'react'
import { object } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { calculatedDistance, getAddressString } from 'utils/helpers'

import { grey, secondaryColor } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const AddressWrap = styled.div`
  display: flex;
  align-items: center;
`

const Address = styled.small`
  display: block;
  color: ${grey};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;

  ${media.breakpoint`
    max-width: 340px;
  `};
`

const AddressIcon = styled(Icon)`
  fill: ${secondaryColor};
  flex-shrink: 0;
  margin-right: ${spacing.tiny};
`

export const CustomerAddress = ({ customer, userLocation }) => {
  const customerAddress = getAddressString(customer?.address, ['line1', 'city', 'postCode'])
  return (
    <AddressWrap>
      <AddressIcon icon="location" small />
      <Address className="notranslate" translate="no">
        {customerAddress}{' '}
        {userLocation ? `(${calculatedDistance([customer.latitude, customer.longitude], userLocation.coords)}km)` : ''}
      </Address>
    </AddressWrap>
  )
}
CustomerAddress.propTypes = {
  customer: object,
  userLocation: object
}

export default CustomerAddress
