import React from 'react'
import { stripUnit } from 'polished'
import { node, string } from 'prop-types'
import styled from 'styled-components'

import { greyLight } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

const Container = styled.section`
  position: relative;

  & + & {
    padding-top: ${spacing.large};

    ${media.breakpoint`
      padding-top: ${spacing.xLarge};
    `};
  }
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
  `};
`

const Header = styled.header`
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  padding-left: ${spacing.medium};

  ${media.breakpoint`
    padding-left: 0;
    margin-bottom: ${spacing.medium};
  `};
`

const Title = styled(H3)`
  margin-bottom: ${stripUnit(spacing.tiny) * 0.5 + 'px'};
  white-space: pre-line;
`

const Subtitle = styled.small`
  display: block;
  color: ${greyLight};
`

const Section = ({ title, subtitle, children, ...props }) => (
  <Container {...props}>
    {title && (
      <Header>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Header>
    )}
    <main>{children}</main>
  </Container>
)

Section.propTypes = {
  title: string,
  subtitle: string,
  children: node.isRequired
}

export default Section
