import React from 'react'
import { Link } from 'react-router-dom'
import { mix, readableColor } from 'polished'
import { bool, func, node, string } from 'prop-types'
import { css } from 'styled-components'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'
import RawSpinner from 'components/Spinner'

const Container = styled.button((props) => [
  tw`relative flex h-9 gap-2 px-4 items-center rounded-md transition text-sm font-normal`,
  props.color &&
    css`
      background-color: ${props.color};
      color: ${readableColor(props.color)};
      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.93, props.color, '#0F172A')};
      }
    `,
  props.primary &&
    tw`shadow-md bg-brand text-white hover:bg-brand-700 active:bg-brand-600 ring-1 ring-slate-900/10 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.secondary &&
    tw`bg-white text-slate-800 shadow-md ring-1 ring-slate-900/10 hover:bg-slate-50 active:bg-slate-500/5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.ghost &&
    tw`text-slate-800 hover:bg-slate-50 active:bg-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.dark && tw`text-white`,
  props.tertiary &&
    tw`h-auto px-0 text-brand hover:text-brand-600 underline underline-offset-4 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500`,
  props.destructive &&
    tw`shadow-md bg-red-600 text-white hover:bg-red-700 active:bg-red-600 ring-1 ring-slate-900/10 active:shadow focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500`,
  props.header && tw`h-auto text-white`,
  props.full && tw`w-full justify-center`,
  props.isLoading && tw`pointer-events-none`,
  props.small && tw`h-6 px-2 text-xs`,
  props.disabled && tw`cursor-default pointer-events-none opacity-40 select-none shadow-none`
])

const Label = styled.span((props) => [tw`flex items-center whitespace-nowrap`, props.isLoading && tw`opacity-0`])

export const Spinner = tw(RawSpinner)`absolute -ml-3 -mt-3 top-1/2 left-1/2`

const ButtonIcon = styled(Icon)(({ left, right }) => [tw`flex`, left && tw`-ml-1`, right && tw`-mr-2`])

const getContainerAttrs = (props) => {
  if (props.to) return { as: Link }
  if (props.href) return { as: 'a' }
  return { as: 'button', type: props.type || 'button' }
}

const Button = React.forwardRef(({ children, isLoading, disabled, offlineDisabled, icon, endIcon, ...rest }, ref) => {
  const containerAttrs = getContainerAttrs(rest)
  if (offlineDisabled && !window.navigator.onLine) disabled = true

  return (
    <Container isLoading={isLoading} disabled={disabled} ref={ref} {...rest} {...containerAttrs}>
      {icon && !isLoading && <ButtonIcon left="true" icon={icon} compact />}
      <Label isLoading={isLoading}>{children}</Label>
      {endIcon && !isLoading && <ButtonIcon right="true" icon={endIcon} compact />}
      {isLoading && <Spinner icon="spinner" />}
    </Container>
  )
})

Button.propTypes = {
  children: node.isRequired,
  icon: string,
  endIcon: string,
  primary: bool,
  secondary: bool,
  ghost: bool,
  destructive: bool,
  dark: bool,
  header: bool,
  isLoading: bool,
  tertiary: bool,
  full: bool,
  small: bool,
  Container: func,
  noShadow: bool,
  disabled: bool,
  offlineDisabled: bool,
  type: string
}

Button.displayName = 'Button'
export default Button
