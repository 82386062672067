// redux-form 7.2.0 field validations CANNOT handle thunks.
import moment from 'moment'

import { getMinEmergencyOrderCtns, PROGRAM_MAX_RESERVABLE } from 'utils/constants'

export const isValid = (value) =>
  value === 0 || value === false || (typeof value === 'string' ? value.trim().length > 0 : value)

export const required = (value) => {
  if (!isValid(value)) return 'Required'
}

export const minLength = (min) => (value) => {
  if (!value || value.length >= min) return
  return `Minimum ${min} characters.`
}

export const minPasswordLength = minLength(8)

export const match = (value, { password }) => {
  if (!value || password === value) return
  return 'Passwords must match.'
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmail = (value) => {
  if (!value || emailRegex.test(value)) return
  return 'Invalid email.'
}

export const isBatEmail = (value) => {
  if (!value || /@bat\.com$/.test(value)) return
  return 'Invalid email'
}

export const underMaxReservable = (value) => {
  if (!value || value <= PROGRAM_MAX_RESERVABLE) return
  return `Maximum $${PROGRAM_MAX_RESERVABLE}`
}

export const endDateBeforeMax = (value, values, { maxEnd }) => {
  if (!value || moment(value).isSameOrBefore(maxEnd, 'day')) return
  return `End date must be before ${new Date(maxEnd).toDateString()}`
}

export const startDateAfterMin = (value, values, { minStart }) => {
  if (!value || moment(value).isSameOrAfter(minStart, 'day')) return
  return `Start date must be after ${new Date(minStart).toDateString()}`
}

export const endDateAfterStart = (value, { startDate }, ...props) => {
  if (!value || moment(value).isSameOrAfter(startDate, 'day')) return
  return 'End date must be after start'
}

export const requiredBy =
  (field, requisiteValue, opts = {}) =>
  (value, fields) => {
    if (value || isValid(value)) return
    if (opts.negate && fields[field] === requisiteValue) return
    if (!opts.negate && fields[field] !== requisiteValue) return
    return `Required by ${opts.requisiteField || 'a previous field'}`
  }

export const maxValue = (max) => (value) => {
  if (max !== 0 && !max) return
  if (!value || +value <= +max) return
  return `Maximum ${max}`
}

export const minValue = (min) => (value) => {
  if (min !== 0 && !min) return
  if (!value || +value >= +min) return
  return `Minimum ${min}`
}

export const minCtnsForEmergencyOrder = (orderType, order, { saqTotalQuantity, customer, qtyToRemove }) => {
  if (orderType !== 'emergency') return
  const minCtnsRequired = getMinEmergencyOrderCtns(customer)

  // Drop the Pod bags does not count towards the emergency order total NOD-874
  // DTP bags are included in qtyToRemove
  const total = saqTotalQuantity - qtyToRemove

  if (total < minCtnsRequired) return `Minimum ${minCtnsRequired} required for emergency orders`
}

export const isInAnAtlanticProvince = (address) => {
  return ['NB', 'NS', 'PE', 'NL'].includes(address.state)
}
