import React, { useEffect, useState } from 'react'
import { currentVersion } from 'appVersion'
import { node } from 'prop-types'

const AppVersionContext = React.createContext()
export default AppVersionContext

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

export const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...')
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      console.log(names)
      for (const name of names) caches.delete(name)
    })
  }
  // delete browser cache and hard reload
  window.location.reload(true)
}

export const AppVersionProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [isStaleVersion, setIsStaleVersion] = useState(false)
  const [latestVersion, setLatestVersion] = useState()

  useEffect(() => {
    console.log(`Running app version ${currentVersion}`)
    let mounted = true
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        if (mounted) {
          setLatestVersion(meta.version)
          const shouldForceRefresh = semverGreaterThan(meta.version, currentVersion)
          setLoading(false)
          if (shouldForceRefresh) {
            console.log(`We have a new version - ${meta.version}. Should force refresh`)
            setIsStaleVersion(true)
          } else {
            console.log(`The latest version is - ${meta.version}. No cache refresh needed.`)
            setIsStaleVersion(false)
          }
        }
      })
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (!localStorage.getItem('token') && isStaleVersion && latestVersion) refreshCacheAndReload()
  }, [isStaleVersion, latestVersion])

  const value = {
    refreshCacheAndReload,
    isStaleVersion,
    loading,
    latestVersion
  }
  return <AppVersionContext.Provider value={value}>{children}</AppVersionContext.Provider>
}

AppVersionProvider.propTypes = {
  children: node.isRequired
}
