import { addEntities, mergeEntities, setEntities } from 'store/actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.products) return state
    return Object.entries(payload.products).reduce((acc, [id, product]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...product
        }
      })
    }, Object.assign({}, state))
  },
  [setEntities]: (state, action) => action.payload.products || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.products) return state
    return Object.entries(payload.products).reduce((acc, [id, product]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...product
        }
      })
    }, Object.assign({}, state))
  }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
