import React, { createContext, useState } from 'react'
import { node } from 'prop-types'

const ActionHeaderContext = createContext()
export default ActionHeaderContext
/**
 * HOW TO USE:
 *
 * In a component where you want to use actions in the header, import addAction from this context
 * Call the method with an object containing the name of an action as well as it implementation
 *
 * addAction({
 *  doSomething: () => alert("Hello World!")
 * })
 *
 * In the HeaderContent.js file, go to the section you want to bind an action to.
 * headerActions is a method that gets passed the context actions. You can extract your action from there.
 *
 * orders: {
 *      headerActions: ({ doSomething }) => [
 *          <Button onClick={doSomething} />
 *      ]
 * }
 */
export const ActionHeaderProvider = ({ children }) => {
  const [actions, setActions] = useState({})

  const addAction = (newAction) => {
    setActions({
      ...actions,
      ...newAction
    })
  }

  const value = {
    actions,
    addAction
  }

  return <ActionHeaderContext.Provider value={value}>{children}</ActionHeaderContext.Provider>
}

ActionHeaderProvider.propTypes = {
  children: node.isRequired
}
