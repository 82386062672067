import React, { useContext } from 'react'
import { arrayOf, object } from 'prop-types'

import LangContext from 'context/LangContext'

import Table from 'components/table'

const TerritoryTargetsTable = ({ targets = [] }) => {
  const { translate } = useContext(LangContext)

  return (
    <Table
      data={targets}
      columns={[
        {
          name: 'targetedFormat',
          header: translate('components.sellInPrograms.territoryTargetsTable.targetedFormat')
        },
        {
          name: 'targetedAmount',
          header: translate('components.sellInPrograms.territoryTargetsTable.targetedAmount')
        },
        {
          name: 'actualAmount',
          header: translate('components.sellInPrograms.territoryTargetsTable.actualAmount')
        },
        {
          name: 'performance',
          header: translate('components.sellInPrograms.territoryTargetsTable.performance')
        }
      ]}
    />
  )
}

TerritoryTargetsTable.propTypes = {
  targets: arrayOf(object)
}

export default TerritoryTargetsTable
