import React, { Fragment, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { rgba, stripUnit } from 'polished'
import { array, number, object } from 'prop-types'
import styled from 'styled-components'

import SectorContext from 'context/SectorContext'

import Icon from 'components/Icon'
import Pill from 'components/Pill'

import { MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN, SECTOR_LEVELS, transactionTypes } from 'utils/constants'
import { isCartonBasedActivity, pricify } from 'utils/helpers'

import { borderColor, goBlue, green, greyDark, offWhite, red, white, yellow } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  overflow: hidden;

  & + & {
    margin-top: ${spacing.small};

    ${media.breakpoint`
      margin-top: ${spacing.medium};
    `};
  }

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const Header = styled.header`
  cursor: ${(props) => (props.isExpandable ? 'pointer' : 'auto')};
  padding: ${spacing.medium};
`

const Content = styled.main`
  background-color: ${rgba(offWhite, 0.5)};
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  border-top: 1px solid ${borderColor};
`

const Section = styled.div`
  padding: ${spacing.medium};

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Title = styled(H3)`
  flex: 1;
  padding-right: ${spacing.small};
  margin-bottom: 0;
`

const SectionTitle = styled.strong`
  display: block;
  font-size: ${smallFontSize};
  color: ${goBlue};
  margin-bottom: ${spacing.small};
`

const Row = styled.div`
  display: flex;
`

const Cell = styled.div`
  position: static;
  font-size: ${tinyFontSize};

  & + & {
    margin-left: ${spacing.large};
  }
`

const Number = styled.strong`
  display: block;
  color: ${greyDark};
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};
`

const PaymentDueWrap = styled.div`
  display: flex;
  align-items: center;
  margin: ${spacing.small} 0;
  font-size: ${tinyFontSize};
`

const Warning = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

function CompletionStatus({ ctnsBooked, targetVolume }) {
  return ctnsBooked >= targetVolume ? (
    <div>
      <Pill color={green}>COMPLETE</Pill>
    </div>
  ) : (
    <div>
      <Pill color={yellow}>INCOMPLETE</Pill>
    </div>
  )
}

CompletionStatus.propTypes = {
  ctnsBooked: number,
  targetVolume: number
}

const Accordion = ({ goFund, programs }) => {
  const [expanded, setExpanded] = useState(false)
  const { fetchSector } = useContext(SectorContext)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const activityIsCartonBased = isCartonBasedActivity(goFund)

  const shouldDisplayStatus = goFund.activity === MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN

  return (
    <Container expanded={expanded}>
      <Header isExpandable={programs && Boolean(programs.length)} onClick={toggleExpanded}>
        <TitleWrap>
          <Title>
            <span>{goFund.fundName}</span>
          </Title>
          {programs && Boolean(programs.length) && <Chevron icon="down-chevron" accent expanded={expanded} />}
        </TitleWrap>
        <Row>
          <Cell>
            <Number>{moment.utc(goFund.endDate).format('MM/DD/YYYY')}</Number>
            <span>end date</span>
          </Cell>
          <Cell>
            <Number>{pricify(goFund.allocatedBudget)}</Number>
            <span>allocated</span>
          </Cell>
          <Cell>
            <Number>{pricify(goFund.spent)}</Number>
            <span>spent</span>
          </Cell>
        </Row>
        {goFund.reserved > 0 && goFund.isExpired && (
          <PaymentDueWrap style={{ color: red }}>
            <Warning icon="warning" small />
            <span>Budget expired missing payments</span>
          </PaymentDueWrap>
        )}
      </Header>
      {programs && Boolean(programs.length) && (
        <Content expanded={expanded}>
          {programs.map((p, idx) => {
            return (
              <Section key={idx}>
                <Link
                  onClick={() => {
                    fetchSector({ id: p.customerId, type: SECTOR_LEVELS.CUSTOMER })
                  }}
                >
                  <SectionTitle>
                    {p.customer}&nbsp;<small>({p.customerId})</small>
                  </SectionTitle>
                </Link>
                <Row>
                  <Cell>
                    <Number>{p.brandFocus}</Number>
                    <span>brand focus</span>
                  </Cell>
                  <Cell>
                    <Number>{pricify(p.maxPayout)}</Number>
                    <span>max payout</span>
                  </Cell>
                  {activityIsCartonBased && (
                    <Cell>
                      <Number>{pricify(p.cartonPayout)}</Number>
                      <span>carton payout</span>
                    </Cell>
                  )}
                  {p.finalCost !== null || p.officialPaymentAmount !== null ? (
                    <Fragment>
                      <Cell>
                        <Number>{pricify(p.officialPaymentAmount || p.finalCost)}</Number>
                        <span>spent</span>
                      </Cell>
                      <Cell>
                        <Number>
                          {p.officialPaymentDate
                            ? moment(p.officialPaymentDate.split('T')[0]).format('MM/DD/YYYY')
                            : p.paymentExtractedAt
                            ? moment(p.paymentExtractedAt).format('MM/DD/YYYY')
                            : '-'}
                        </Number>
                        <span>pay date</span>
                      </Cell>
                    </Fragment>
                  ) : (
                    <Cell>
                      <Number>{pricify(p.totalPayments)}</Number>
                      <span>spent</span>
                    </Cell>
                  )}
                  {p.transactionType && (
                    <Cell>
                      <Number>{transactionTypes[p.transactionType]}</Number>
                      <span>pay type</span>
                    </Cell>
                  )}
                  {shouldDisplayStatus && (
                    <Cell>
                      <CompletionStatus ctnsBooked={p.ctnsBooked} targetVolume={p.targetVolume} />
                      <span>status</span>
                    </Cell>
                  )}
                </Row>
                {!p.finalCost && p.finalCost !== 0 && goFund.isExpired && (
                  <PaymentDueWrap style={{ color: red }}>
                    <Warning icon="warning" small />
                    <span>Payment not available due to budget expiry</span>
                  </PaymentDueWrap>
                )}
              </Section>
            )
          })}
        </Content>
      )}
    </Container>
  )
}

Accordion.propTypes = {
  goFund: object.isRequired,
  programs: array
}

export default Accordion
