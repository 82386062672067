import * as React from 'react'
import { cva } from 'class-variance-authority'
import { oneOf, string } from 'prop-types'

import { cn } from 'utils/styling'

const badgeVariants = cva(
  'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        sellIn: 'bg-sellIn text-white',
        priceCheck: 'bg-priceCheck text-white',
        survey: 'bg-survey text-white',
        extraFunds: 'bg-extraFunds text-white'
      }
    }
  }
)

export default function ObjectiveBadge({ className, variant, ...props }) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />
}

ObjectiveBadge.propTypes = {
  className: string,
  variant: oneOf(['sellIn', 'priceCheck', 'survey', 'extraFunds'])
}
