import React, { useContext } from 'react'
import { bool, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { CardContent, CardHeading, Data, HiddenIndicator } from '.'

export const SOMData = ({ data, timeRange, hidden, currentProductType }) => {
  const { translate } = useContext(LangContext)
  return (
    <CardContent>
      <CardHeading>{translate('app.sellOut')}</CardHeading>
      {hidden && <HiddenIndicator />}

      <Data
        data={data.sellout}
        title={currentProductType === 'fmc' ? translate('app.acronyms.SOM') : translate('app.acronyms.SOV')}
        additionalData={{
          type: 'variation',
          data: {
            variation: {
              amount: data.sellout.variation,
              time: timeRange
            }
          }
        }}
      />

      <Data
        data={data.rateOfSales}
        title="Rate of Sales"
        additionalData={{
          type: 'variation',
          data: {
            variation: {
              amount: data.rateOfSales.variation,
              time: timeRange
            }
          }
        }}
      />
    </CardContent>
  )
}

SOMData.propTypes = {
  data: {
    sellout: {
      value: number,
      variation: number,
      isTracked: bool
    },
    rateOfSales: {
      value: number,
      variation: number,
      isTracked: bool
    }
  },
  hidden: bool,
  timeRange: string,
  currentProductType: string
}

SOMData.defaultProps = {
  data: {
    sellout: {
      value: 0,
      variation: null,
      isTracked: true
    },
    rateOfSales: {
      value: 0,
      variation: null,
      isTracked: true
    }
  }
}
