import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { transparentize } from 'polished'
import { func, number, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchSellinRunRate } from 'store/Sellin/actions'
import { sellinRunRateData } from 'store/Sellin/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import { DATAKEY_TYPES, PLACEHOLDERS } from 'utils/constants'
import { formatCompactNumber, formatGap, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const DataLabel = tw.span`block text-2xs font-medium text-slate-500 text-center`

const ChartWrapper = tw.div`relative flex grow flex-col items-center justify-center h-[200px]`

const DataXL = tw.span`block text-xl font-medium text-slate-900 text-center`

const Data2XL = tw.span`block text-3xl font-medium text-slate-900 text-center`

const Spacer = tw.div`shrink-0 h-9 w-full max-lg:hidden`

const AmplifySellinRunRate = ({ span, fetchSellinRunRate, vapeCategory, unitOfMeasure }) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [error, setError] = useState()
  const mounted = useRef(false)

  const dataKey = createDataKey(DATAKEY_TYPES.SELL_IN_RUN_RATE, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: currentProductType,
    vapeCategory,
    unitOfMeasure
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const sellInRunRateData = useSelector((state) =>
    sellinRunRateData(state, {
      activeProductType: currentProductType,
      vapeCategory,
      unitOfMeasure
    })
  )

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchSellinRunRate(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          vapeCategory,
          unitOfMeasure
        },
        dataKey
      ).catch((error) => {
        if (mounted.current) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, vapeCategory, unitOfMeasure])

  function getCircularData(completionPercentage) {
    const percentage = (completionPercentage || 0) * 100
    return [
      {
        name: 'completionPercentage',
        value: Math.min(percentage, 100),
        fill: '#E08330'
      },
      {
        name: 'gapPercentage',
        value: Math.max(100 - percentage, 0),
        fill: transparentize(0.8, '#E08330')
      }
    ]
  }

  const renderContent = () => {
    const { actuals, completionPercentage, gap } = sellInRunRateData
    const circularData = getCircularData(completionPercentage)

    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />

    return (
      <div className="flex h-full grow flex-col justify-center">
        <div className="flex grow items-center justify-around pt-10">
          <div className="my-auto hidden w-16 min-w-fit space-y-5 @lg/card:block">
            <div className="space-y-2">
              <DataLabel>{translate('sellIn.actuals')}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={actuals}>
                  {actuals ? formatCompactNumber(actuals) : PLACEHOLDERS.NO_VALUE}
                </Tooltip>
              </DataXL>
            </div>
          </div>
          <ChartWrapper>
            <div className="absolute">
              <PieChart width={200} height={200} margin={0}>
                <Pie
                  data={circularData}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius="90%"
                  outerRadius="100%"
                  isAnimationActive={false}
                />
              </PieChart>
            </div>
            <DataLabel>{translate('sellIn.today')}</DataLabel>
            <Data2XL>{formatPercent(completionPercentage, { convertDecimal: true })}</Data2XL>
            <DataLabel>{translate('sellIn.ofTarget')}</DataLabel>
          </ChartWrapper>
          <div className="my-auto hidden w-16 min-w-fit space-y-5 @lg/card:block">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.gap')}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={gap}>
                  {formatGap(gap, formatCompactNumber)}
                </Tooltip>
              </DataXL>
            </div>
          </div>
        </div>

        <div className="flex pt-8 @lg/card:hidden">
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.actuals')}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={actuals}>
                  {actuals ? formatCompactNumber(actuals) : PLACEHOLDERS.NO_VALUE}
                </Tooltip>
              </DataXL>
            </div>
          </div>
          <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
            <div className="flex flex-col items-center gap-2">
              <DataLabel>{translate('sellIn.gap')}</DataLabel>
              <DataXL>
                <Tooltip isNumber hint={gap}>
                  {formatGap(gap, formatCompactNumber)}
                </Tooltip>
              </DataXL>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card title={translate('app.runRate')} span={span} displayAmplify>
      {' '}
      <div className="flex h-full flex-col">
        <Spacer />
        {renderContent()}
      </div>
    </Card>
  )
}

AmplifySellinRunRate.propTypes = {
  span: number,
  fetchSellinRunRate: func,
  vapeCategory: string,
  unitOfMeasure: string
}

export default connect(null, { fetchSellinRunRate })(AmplifySellinRunRate)
