import React, { useContext, useEffect, useRef } from 'react'
import { stripUnit } from 'polished'
import { bool, func, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import Input from 'components/Input'

import { white } from 'styles/colors'
import * as spacing from 'styles/spacing'

const Cancel = styled.button`
  color: ${white};
  margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const SearchHeader = ({ goBack, updateResults, currentQuery, isCustomerService }) => {
  const { translate } = useContext(LangContext)

  const searchInputRef = useRef()
  useEffect(() => {
    if (searchInputRef.current) searchInputRef.current.focus()
  }, [])
  return (
    <>
      <Input
        ref={searchInputRef}
        placeholder={`${translate('common.search')}...`}
        onChange={({ target }) => updateResults(target.value)}
        value={currentQuery}
        small
      />
      {!isCustomerService && <Cancel onClick={goBack}>Cancel</Cancel>}
    </>
  )
}
SearchHeader.propTypes = {
  goBack: func,
  updateResults: func,
  currentQuery: string,
  isCustomerService: bool
}

export default SearchHeader
