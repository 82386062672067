import { useContext, useState } from 'react'

import LangContext from 'context/LangContext'

const useErrors = () => {
  const [globalError, setGlobalError] = useState(false)
  const { translate } = useContext(LangContext)

  const generateErrorMessage = (key, error) => `
  ${translate(key)}
  ${translate('common.errorDetails')} : ${JSON.stringify(error?.message || error)}
  `

  return {
    globalError,
    setGlobalError,
    generateErrorMessage
  }
}

export default useErrors
