import React from 'react'
import snakeCase from 'lodash/snakeCase'
import { array, bool, func, object, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'

import { cn } from 'utils/styling'

const TableRow = ({ rowData, alignCenter, columns, stickyFirstColumn }) => {
  const rowHeaderCol = rowData[columns[0].field]

  return (
    <tr className="group">
      {columns.map(({ field }, idx) => {
        const value = rowData[field]
        const key = `${field}_${snakeCase(rowHeaderCol)}_${idx}`
        if (idx === 0 && stickyFirstColumn)
          return (
            <th
              className="sticky left-0 z-20 w-0 border-r border-slate-300 bg-white px-2.5 py-2.5 text-sm font-medium text-slate-900 first:pl-0 last:pr-0 group-last:pb-0 group-hover:bg-brand-50 max-md:max-w-[13.25rem]"
              key={key}
            >
              <div className={cn('flex grow items-center whitespace-nowrap', alignCenter && 'justify-center')}>
                {value}
              </div>
            </th>
          )
        else
          return (
            <td
              className="px-2.5 py-2.5 text-sm font-medium text-slate-900 first:pl-0 last:pr-0 group-last:pb-0 group-hover:bg-brand-50"
              key={key}
            >
              <div className={cn('flex items-center whitespace-nowrap', alignCenter && 'justify-center')}>{value}</div>
            </td>
          )
      })}
    </tr>
  )
}

TableRow.propTypes = {
  rowData: object,
  alignCenter: bool,
  columns: array.isRequired,
  stickyFirstColumn: bool
}

const TableHeader = styled.th(({ alignCenter, isClickable, isLarge, stickyFirstColumn, stickyHeaders }) => [
  tw`sticky z-10 top-0 pb-3 px-2.5 first:pl-0 last:pr-0 text-sm font-medium text-slate-500 border-b border-slate-300 align-top sticky top-0 bg-white z-10`,
  alignCenter ? tw`text-center` : tw`text-left`,
  isClickable ? tw`cursor-pointer` : tw`cursor-default`,
  isLarge && tw` min-w-52`,
  stickyFirstColumn && tw`first:bg-white first:left-0 first:z-30 first:border-r`,
  stickyHeaders && tw`max-w-0 min-w-20`
])

const DataTable = ({
  columns,
  rows,
  alignCenter,
  fillContainer,
  fullWidth,
  stickyFirstColumn,
  stickyHeaders,
  onColumnClick,
  activeColumn,
  unClickableColumns,
  wideTable
}) => {
  return (
    <div className={cn(wideTable && '-mr-6', 'h-full')}>
      <div className="h-full max-w-full overflow-x-auto">
        <div
          className={cn(
            'flow-root h-full',
            stickyFirstColumn && 'max-h-[calc(100dvh-9rem)] md:max-h-[calc(100dvh-10rem)]'
          )}
        >
          <table
            className={cn(
              'relative table-auto border-separate border-spacing-0',
              wideTable && 'pr-6',
              fillContainer && 'h-full w-full',
              fullWidth && !fillContainer && 'w-full'
            )}
          >
            <thead>
              <tr>
                {columns.map((column) => {
                  const isClickable = onColumnClick && !unClickableColumns.includes(column.field)
                  const handleClick = isClickable ? onColumnClick : () => null

                  return (
                    <TableHeader
                      key={column.field}
                      scope="col"
                      alignCenter={alignCenter}
                      stickyFirstColumn={stickyFirstColumn}
                      stickyHeaders={stickyHeaders}
                      isClickable={isClickable}
                      onClick={() => handleClick(column.field)}
                    >
                      <div className="relative flex w-full items-center gap-0.5">
                        <div className="flex w-full min-w-0 flex-col">
                          <div className="flex w-full min-w-0 items-center">
                            <Tooltip contentAsChild hint={column.headerName}>
                              <span className="min-w-0 truncate">{column.headerName}</span>
                            </Tooltip>
                            {column.additionnal && <span>{column.additionnal}</span>}
                          </div>

                          {column.subHeader && <p className="text-sm font-normal">{column.subHeader}</p>}
                        </div>
                        {activeColumn?.column === column.field ? (
                          <Icon
                            icon="down-chevron"
                            compact
                            className={cn('w-full shrink-0', activeColumn?.order === 'asc' && 'rotate-180')}
                          />
                        ) : null}
                      </div>
                    </TableHeader>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  rowData={row}
                  alignCenter={alignCenter}
                  columns={columns}
                  stickyFirstColumn={stickyFirstColumn}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

DataTable.propTypes = {
  columns: array,
  rows: array,
  alignCenter: bool,
  fillContainer: bool,
  fullWidth: bool,
  onColumnClick: func,
  activeColumn: string,
  unClickableColumns: array,
  stickyFirstColumn: bool,
  stickyHeaders: bool,
  wideTable: bool
}

DataTable.defaultProps = {
  unClickableColumns: []
}

export default DataTable
