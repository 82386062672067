import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import * as authSelector from 'store/auth/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchTerritoryProjectLinks } from 'store/territories/actions'
import { getCurrentTerritory, territoryProjects } from 'store/territories/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import Container from 'components/Container'
import TerritoryCustomerProject from 'components/CustomerProject/TerritoryCustomerProject'
import TerritoryProjectStatusHeader from 'components/CustomerProject/TerritoryProjectStatusHeader'
import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import * as spacing from 'styles/spacing'
import { smallFontSize } from 'styles/typography'

const TerritoryProjects = ({ fetchTerritoryProjectLinks }) => {
  const navigate = useNavigate()
  const { sectorId, projectId } = useParams()

  const [error, setError] = useState()

  const territory = useSelector((state) => getCurrentTerritory(state))
  const projectLinks = useSelector((state) => territoryProjects(state))
  const preferredLanguage = useSelector((state) => authSelector.preferredLanguage(state).substring(0, 2))

  const { addAction } = useContext(ActionHeaderContext)

  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY_PROJECT_LINKS, {
    territoryId: sectorId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true

    setError()
    if (sectorId) {
      fetchTerritoryProjectLinks(sectorId, dataKey).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }
    return () => {
      mounted.current = false
    }
  }, [dataKey])

  useEffect(() => {
    if (projectId) {
      if (projectLinks === undefined || !Object.keys(projectLinks).length) return
      const activeProject = projectLinks[projectId]

      if (activeProject) {
        addAction({
          getProjectName: () => activeProject?.project?.title?.[preferredLanguage] || 'Project'
        })
      }
    } else {
      navigate(`/territory/${sectorId}/pace/close/insight-to-action`)
    }
  }, [projectId, projectLinks])

  const renderContent = () => {
    if (isLoading) {
      return <Spinner icon="spinner" />
    }

    if (error) {
      return <EmptyState title="An error occured" subtitle={error} />
    }

    if (!projectLinks || !Object.values(projectLinks).length)
      return <EmptyState title="No data yet" subtitle="No projects available" />

    return (
      <div className="w-full">
        {territory.insightsMessage ? (
          <div
            style={{ marginBottom: spacing.large, fontSize: smallFontSize }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(territory.insightsMessage) }}
          />
        ) : null}
        {Object.values(projectLinks).map(({ project, ...p }) => {
          const expanded = Number(projectId) === project.id

          return (
            <BasicAccordion
              title={project.title[preferredLanguage]}
              preExpand={expanded}
              details={
                <TerritoryProjectStatusHeader
                  actioned={p.actioned}
                  resolved={p.resolved}
                  creation={project.creation}
                  expiry={project.expiry}
                  total={p.total}
                />
              }
              locationState={location.state}
              key={project.id}
              onExpand={(isExpanded) =>
                navigate(`/territory/${sectorId}/pace/close/insight-to-action${isExpanded ? `/${project.id}` : ''}`)
              }
            >
              {/* <ActionStats actionTotals={p.actionTotals} /> */}
              <p>
                <small>{p.text[preferredLanguage]}</small>
              </p>
              <p>
                <small>
                  <b>{project.territorySectionTitle[preferredLanguage]}</b>
                </small>
              </p>
              {p.customers.toAction?.length > 0 && (
                <BasicAccordion title="To Action" isSubrow>
                  <TerritoryCustomerProject
                    customers={p.customers.toAction}
                    preferredLanguage={preferredLanguage}
                    labels={p[`${preferredLanguage}Labels`]}
                  />
                </BasicAccordion>
              )}
              {p.customers.actioned?.length > 0 && (
                <BasicAccordion title="Actioned" isSubrow>
                  <TerritoryCustomerProject
                    customers={p.customers.actioned}
                    preferredLanguage={preferredLanguage}
                    labels={p[`${preferredLanguage}Labels`]}
                  />
                </BasicAccordion>
              )}
            </BasicAccordion>
          )
        })}
      </div>
    )
  }

  return <Container fullWidth>{renderContent()}</Container>
}

TerritoryProjects.propTypes = {
  fetchTerritoryProjectLinks: func
}

const mapActionCreators = {
  fetchTerritoryProjectLinks
}

export default connect(null, mapActionCreators)(TerritoryProjects)
