import React from 'react'
import { Link } from 'react-router-dom'
import { rgba, stripUnit } from 'polished'
import { string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { secondaryColor } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Container = styled(Link)`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  background-color: ${(props) => rgba(props.color, 0.15)};
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'};
  padding-left: ${spacing.medium};
  margin-bottom: ${spacing.medium};
  margin-top: -${spacing.medium};
  font-weight: ${fontSemibold};
  font-size: ${smallFontSize};
  transition: background-color ${animationTime} ${animationCurve};

  &:hover {
    background-color: ${(props) => rgba(props.color, 0.25)};
  }

  ${media.breakpoint`
    border-radius: ${borderRadius};
  `};
`

const Text = styled.span`
  flex: 1;
  text-align: left;
`

const Chevron = styled.div`
  margin-left: ${spacing.small};
`

const IconWrap = styled.div`
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

const Announcement = ({ icon, text, link, color = secondaryColor }) => (
  <Container to={link} color={color}>
    {icon && (
      <IconWrap>
        <Icon icon={icon} />
      </IconWrap>
    )}
    <Text>{text}</Text>
    <Chevron>
      <Icon icon="right-chevron" />
    </Chevron>
  </Container>
)

Announcement.propTypes = {
  icon: string,
  text: string.isRequired,
  link: string.isRequired,
  color: string
}

export default Announcement
