import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { capitalize } from 'lodash'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { customerFromUrl } from 'store/customers/selectors'
import { submitGoFundProgramException } from 'store/goFundExceptions/actions'
import { programFromUrl } from 'store/goFundPrograms/selectors'
import { goFundExceptionInitialValues } from 'store/goFunds/selectors'

import Container from 'components/Container'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import GoFundProgramCard from 'components/ProgramCard'

import { SECTOR_LEVELS } from 'utils/constants'
import { required } from 'utils/validators'

import Card from './Card'

const GoProgramException = ({ submitGoFundProgramException }) => {
  const { programId, sectorId: customerId, sectorType } = useParams()
  const formRef = useRef()
  const { addAction } = useContext(ActionHeaderContext)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  const employeeId = useSelector((state) => state.auth.user.id)
  const program = useSelector((state) => programFromUrl(state, { programId }))
  const customer = useSelector((state) => customerFromUrl(state, { customerId }))
  const initialValues = useSelector((state) => goFundExceptionInitialValues(state, { customerId, programId }))

  useEffect(() => {
    addAction({
      onCreate: () => formRef.current.handleSubmit(),
      getLabel: () => 'Save',
      disabled: isLoading
    })
  }, [formRef])

  const submitNewException = async (values) => {
    setError()
    setIsLoading(true)
    const toSubmit = { ...values, requestedBy: employeeId, goFundProgramId: +program.id }
    submitGoFundProgramException(toSubmit)
      .then(() => navigate(-1))
      .catch((e) => {
        console.log(e)
        setError(e.message)
      })
      .finally(() => setIsLoading(false))
  }

  const storeContacts = useMemo(
    () =>
      customer?.storeContacts
        ? customer.storeContacts.map((contact) => ({
            label: `${capitalize(contact.firstname)} ${capitalize(contact.lastname)} - ${capitalize(contact.role)} - ${
              contact.email
            }`,
            value: contact.id
          }))
        : [],
    [customer]
  )

  return (
    <Formik innerRef={formRef} initialValues={initialValues} onSubmit={submitNewException} validateOnBlur>
      <Form>
        <Container>
          <GoFundProgramCard program={program} />
          <Card>
            <Fieldset padded>
              {/* Request Exception */}
              <FieldsetItem>
                <Field
                  component={Dropdown}
                  options={storeContacts}
                  name="payeeId"
                  label="Payee"
                  menuPlacement="top"
                  placeholder={storeContacts.length ? 'Select one...' : 'No payee available.'}
                  renderWithoutOptions
                  validate={required}
                  disabled={isLoading}
                />
              </FieldsetItem>
              <FieldsetItem>
                <Field
                  component={Input}
                  name="requestedAmount"
                  label="Requested amount"
                  placeholder="$0"
                  validate={required}
                  disabled={isLoading}
                />
              </FieldsetItem>
              <FieldsetItem>
                <Field
                  component={Input}
                  label="Details"
                  name="details"
                  textarea
                  placeholder={
                    'Please indicate the reason for the exception request \nex. adjusted carons, adjusted date, etc'
                  }
                  disabled={isLoading}
                />
              </FieldsetItem>
              {error && (
                <FieldsetItem>
                  <GlobalAlert>{error}</GlobalAlert>
                </FieldsetItem>
              )}
            </Fieldset>
          </Card>
        </Container>
      </Form>
    </Formik>
  )
}

GoProgramException.propTypes = {
  submitGoFundProgramException: func.isRequired
}

const mapActionCreators = {
  submitGoFundProgramException
}

export default connect(null, mapActionCreators)(GoProgramException)
