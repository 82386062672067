import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySellout } from 'store/Sellout/actions'

import PaceSelloutL13TrendCard from 'components/PaceSelloutL13TrendCard'

import { CHANNEL_FILTERS, DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const PlanningSelloutCard = ({ fetchAmplifySellout, ...props }) => {
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])
  const [error, setError] = useState()
  const { currentProductType, currentSector, selectedLevel } = useContext(SectorContext)
  const [currentChannel, setChannel] = useState(CHANNEL_FILTERS[0].value)
  const dataType = useMemo(() => {
    return currentProductType === 'fmc' ? 'qty' : 'cost'
  }, [currentProductType])

  const dataKey = useMemo(
    () =>
      createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.MAIN, {
        sectorType: selectedLevel,
        sectorId: currentSector[selectedLevel]?.id,
        productType: currentProductType,
        channel: currentChannel,
        dataType
      }),
    [currentSector, selectedLevel, currentProductType, currentChannel, dataType]
  )

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    setError()
    const invalidProductTypeChannel = currentProductType !== 'vape' && currentChannel === 'specialty'
    if (currentSector[selectedLevel]?.id && !invalidProductTypeChannel && fetchAmplifySellout) {
      fetchAmplifySellout(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          activeProductType: currentProductType,
          channel: currentChannel,
          dataType
        },
        dataKey
      ).catch((error) => {
        if (mounted.curent) setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, currentProductType, currentChannel, dataType, dataKey])
  return (
    <PaceSelloutL13TrendCard
      {...props}
      error={error}
      currentChannel={currentChannel}
      setChannel={setChannel}
      currentProportion="share"
      dataKey={dataKey}
      dataType={dataType}
      selloutDataLoading={isLoading}
      currentVapeCategory="allConsumables"
    />
  )
}

const mapActionCreators = {
  fetchAmplifySellout
}

PlanningSelloutCard.propTypes = {
  fetchAmplifySellout: func
}

export default connect(null, mapActionCreators)(PlanningSelloutCard)
