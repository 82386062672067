// Note: Style template code.

import React, { useContext } from 'react'
import { arrayOf, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import Card from 'components/card'

import CardPlaceholder from './CardPlaceholder'

const HubData = ({ children, description }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <span className="text-xl font-medium text-slate-900">{children}</span>
      <span className="text-2xs font-medium text-slate-500">{description}</span>
    </div>
  )
}

HubData.propTypes = {
  children: arrayOf(string),
  description: string
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const Header = tw.span`text-lg font-semibold text-slate-500 w-full flex justify-center`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`space-y-5`

const THTop = tw.th`text-lg font-medium text-slate-500 text-center`

const THLeft = tw.th`text-lg font-medium text-slate-500 text-left`

const TData1 = tw.td`font-medium text-xl text-slate-900 text-center`

const TData2 = tw.td`font-medium text-xl text-slate-500 text-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const EngagementCardPlaceholder = ({ span }) => {
  const { translate } = useContext(LangContext)

  return (
    <Card title={translate('app.engagement')} span={span}>
      <TopSection>
        <Eyebrow />
        <CardPlaceholder>
          <div className="items-center space-y-3">
            <Header>{translate('app.callsPerDay')}</Header>
            <table className="w-full table-auto border-separate border-spacing-y-2">
              <tbody>
                <tr>
                  <th></th>
                  <THTop>{translate('common.visit')}</THTop>
                  <THTop>{translate('common.time')}</THTop>
                </tr>
                <tr>
                  <THLeft>{translate('common.week')}</THLeft>
                  <TData1>64.1%</TData1>
                  <TData1>38 min</TData1>
                </tr>
                <tr>
                  <THLeft>{translate('common.cycle')}</THLeft>
                  <TData2>23.4%</TData2>
                  <TData2>43 min</TData2>
                </tr>
              </tbody>
            </table>
          </div>
        </CardPlaceholder>
      </TopSection>
      <BottomSection>
        <Eyebrow />
        <div className="items-center space-y-3">
          <div className="flex items-center justify-between">
            <Title>{translate('app.extraHubActivity')}</Title>
          </div>
          <CardPlaceholder>
            <div className="flex flex-col space-y-3">
              <div className="flex">
                <HubData description="of 15 stores">87%</HubData>
                <HubData description="of 56 users">86%</HubData>
              </div>
              <HubData description="available to be redeemed">$431</HubData>
            </div>
          </CardPlaceholder>
        </div>
      </BottomSection>
    </Card>
  )
}

EngagementCardPlaceholder.propTypes = {
  span: object
}

export default EngagementCardPlaceholder
