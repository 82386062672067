import React from 'react'
import { Link } from 'react-router-dom'
import { any, func, object } from 'prop-types'
import styled from 'styled-components'

import { isNullish } from 'utils/helpers'

import { cover } from 'styles/global'

const Container = styled.div`
  position: relative;
`

const LinkOverlay = styled(Link)`
  display: block;
  z-index: 1;
  ${cover('absolute')};
`

const LinkCell = ({ column, record, linkGenFn, label, labelGenFn }) => {
  const href = linkGenFn?.(record)
  const value = !isNullish(record[column.name]) ? record[column.name] : column.name
  const lLabel = label || labelGenFn?.(record) || value
  return (
    <td>
      <Container>
        {lLabel}
        {href && <LinkOverlay to={{ pathname: href }} />}
      </Container>
    </td>
  )
}
LinkCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The record the cell will render
   */
  record: object.isRequired,

  label: any,

  /**
   * An optional function that should provide the label that will be displayed inside the cell.
   * If not provided, the label is going to be the value of the record value matching the column name.
   * If no such value exist, the label will be the column name
   * @param {object} record The record provided to the cell
   * @returns {string} The label that will be displayed in the cell
   */
  labelGenFn: func,

  /**
   * An optional func that should return the pathname of the Route component
   * If not provided, The Route will have no pathname which turns it useless
   * @param {object} record
   * @returns {string} The pathname that will be provided to the route
   */
  linkGenFn: func
}

export default LinkCell
