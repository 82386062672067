import React, { useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import { object, string } from 'prop-types'
import styled from 'styled-components'

import Checkbox from 'components/Checkbox'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { required } from 'utils/validators'

import { greyDark } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontBold } from 'styles/typography'
const questionInputs = {
  multipleChoice: {
    InputComponent: Dropdown,
    answerField: 'answerId',
    placeholder: 'Select...'
  },
  manualEntry: {
    InputComponent: (props) => <Input textarea {...props} />,
    answerField: 'textAnswer',
    placeholder: 'Enter an answer...'
  }
}

const Title = styled.small`
  display: block;
  font-weight: ${fontBold};
  color: ${greyDark};
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.medium};
`

// const ScoreLabel = styled.small`
//   display: block;
//   margin-top: ${spacing.tiny};
//   font-size: ${tinyFontSize};
//   font-style: italic;
// `
// const actionScores = [
//   { label: '1', value: '1' },
//   { label: '2', value: '2' },
//   { label: '3', value: '3' },
//   { label: '4', value: '4' },
//   { label: '5', value: '5' }
// ]

const validateMultipleChoice = (/* value, allValues, props, name */) => {
  // console.log({ value, allValues, props, name })
  // if (name.includes('answerId') && props.customerProject.status.startsWith('followUp'))
  //   return required(value, allValues, props, name)
}
const ActionsQuestionsFeedback = ({ project, customerProject, preferredLanguage, error }) => {
  const { setFieldValue, values } = useFormikContext()

  const inFollowUp = useMemo(() => customerProject?.status.startsWith('followUp') || false, [customerProject?.status])
  const feedbackOptions = project?.feedbacks
    .filter(({ id }) => Boolean(id))
    .map(({ id, text }) => ({ label: text[preferredLanguage], value: String(id) }))

  const questionsByStatus = project?.questions?.filter(({ option }) => (option === 'followUp') === inFollowUp)
  return (
    <>
      <Fieldset>
        {project?.actions.map(({ text, id }, idx) => (
          <FieldsetItem key={idx}>
            <Field
              name={`actions.a_${id}.complete`}
              component={Checkbox}
              label={text[preferredLanguage]}
              disabled={inFollowUp}
              onChange={() => {
                const before = values.actions[`a_${id}`].complete
                setFieldValue(`actions.a_${id}.complete`, !before)
              }}
            />
            {/* formValues?.actions[`a_${id}`] &&
              formValues?.actions[`a_${id}`].complete && (
                <FieldsetItem key={`score-${idx}`}>
                  <Field
                    name={`actions.a_${id}.score`}
                    component={SegmentControl}
                    options={actionScores}
                    disabled={projectResolved || inFollowUp}
                  />
                  <ScoreLabel>Confidence score</ScoreLabel>
                </FieldsetItem>
              ) */}
          </FieldsetItem>
        ))}
      </Fieldset>
      {questionsByStatus?.length > 0 && <Title>Questions</Title>}
      {questionsByStatus?.length > 0 && (
        <Fieldset>
          {questionsByStatus.map(({ id, text, type, answers }, idx) => {
            const options = answers?.length
              ? answers.map(({ text, id }) => ({ label: text[preferredLanguage], value: id }))
              : null
            const { InputComponent, answerField, placeholder } = questionInputs[type]
            return (
              <FieldsetItem key={idx}>
                <Field
                  component={InputComponent}
                  name={`answers.q_${id}.${answerField}`}
                  label={text[preferredLanguage]}
                  placeholder={placeholder}
                  options={options}
                  validate={validateMultipleChoice}
                />
              </FieldsetItem>
            )
          })}
        </Fieldset>
      )}
      <Title>Feedback</Title>
      <Fieldset>
        <FieldsetItem>
          <Field name="feedback.id" component={Dropdown} options={feedbackOptions} vertical validate={required} />
        </FieldsetItem>
        <FieldsetItem>
          <Field component={Input} placeholder="Add a comment..." name="comment" />
        </FieldsetItem>
        {error && (
          <FieldsetItem>
            <GlobalAlert>{error}</GlobalAlert>
          </FieldsetItem>
        )}
      </Fieldset>
    </>
  )
}

ActionsQuestionsFeedback.propTypes = {
  project: object.isRequired,
  customerProject: object.isRequired,
  preferredLanguage: string.isRequired,
  error: string
}

export default ActionsQuestionsFeedback
