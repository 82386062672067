import React, { useContext, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { requestLogin } from 'store/auth/actions'

import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'

import { isEmail, minPasswordLength, required } from 'utils/validators'

function Login({ requestLogin }) {
  const { translate } = useContext(LangContext)
  const [error, setError] = useState()

  const errorMessages = {
    registrationRequired: (
      <GlobalAlert>
        {translate('auth.error.registrationRequired.1')}
        <Link to="/auth/signup">{translate('auth.error.registrationRequired.2')}</Link>
        {translate('auth.error.registrationRequired.3')}
      </GlobalAlert>
    ),
    invalidEmployeeDetails: <GlobalAlert>{translate('auth.invalidEmployeeDetails')}</GlobalAlert>,
    permissionDenied: <GlobalAlert>{translate('auth.permissionDenied')}</GlobalAlert>,
    offlineError: <GlobalAlert>{translate('auth.logIn.offlineError')}</GlobalAlert>,
    sessionExpired: <GlobalAlert>{translate('auth.logIn.sessionExpired')}</GlobalAlert>,
    authRequired: <GlobalAlert>{translate('auth.logIn.authRequired')}</GlobalAlert>,
    generic: <GlobalAlert>{translate('auth.errors.generic')}</GlobalAlert>
  }

  const validateEmail = (value) => {
    return required(value) || isEmail(value)
  }

  const validatePassword = (value) => {
    return required(value) || minPasswordLength(value)
  }

  const handleEmailChange = (value, setFieldValue) => {
    // Somehow the .trim() is not working. Need to figure out why...
    const email = value.trim().toLowerCase()
    setFieldValue('email', email)
  }

  const handleFormSubmit = async (values) => {
    try {
      setError()
      await requestLogin(values)
    } catch ({ response }) {
      const loginError =
        !response || response.status === 500 || !response.data.message ? 'generic' : response.data.message
      setError(errorMessages[loginError] || errorMessages.generic)
    }
  }

  const navigate = useNavigate()

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={handleFormSubmit}>
      {({ isSubmitting, errors, setFieldValue }) => {
        return (
          <Form>
            <Fieldset>
              <FieldsetItem>
                <h3 className="mb-2 text-xl font-medium text-slate-700">{translate('auth.logIn')}</h3>
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  type="email"
                  component={Input}
                  icon="mail"
                  placeholder={translate('common.emailAddress')}
                  name="email"
                  onChange={(event) => handleEmailChange(event.target.value, setFieldValue)}
                  validate={validateEmail}
                />
              </FieldsetItem>

              <FieldsetItem>
                <Field
                  component={Input}
                  icon="lock"
                  placeholder={translate('common.password')}
                  type="password"
                  name="password"
                  validate={validatePassword}
                />
              </FieldsetItem>

              {error && <FieldsetItem>{error}</FieldsetItem>}

              <FieldsetItem>
                <Button type="submit" disabled={Object.keys(errors).length > 0} primary full isLoading={isSubmitting}>
                  {translate('auth.logIn')}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Link to="/auth/forgot-password">
                  <p className="text-sm font-medium text-brand underline hover:text-brand-600">
                    {translate('auth.forgotPassword')}
                  </p>
                </Link>
              </FieldsetItem>

              <FieldsetItem>
                <hr className="my-4 border-t border-slate-300" />
              </FieldsetItem>

              <FieldsetItem>
                <div className="space-y-4">
                  <p className="text-base font-medium text-slate-600">{translate('auth.noAccount')}</p>
                  <Button secondary full onClick={() => navigate('/auth/signup')}>
                    {translate('auth.signUp')}
                  </Button>
                </div>
              </FieldsetItem>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

const mapActionCreators = {
  requestLogin
}

Login.propTypes = {
  requestLogin: func
}

export default connect(null, mapActionCreators)(Login)
