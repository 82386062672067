import React, { useEffect, useState } from 'react'
import { any, func, object, oneOf } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

const Arrow = styled(({ direction, ...rest }) => Icon(rest))`
  transform: rotate(${(props) => (props.direction === 'asc' ? 180 : 0)}deg);
  margin: -6px;
`

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
`

const SortIcon = ({ direction = 'neutral', onSort }) => {
  const [selectedDirection, setSelectedDirection] = useState(direction)

  useEffect(() => {
    setSelectedDirection(direction)
  }, [direction])

  const changeDirection = () => {
    const order = ['desc', 'asc', 'neutral']
    const current = order.indexOf(selectedDirection)
    const next = order[current === order.length - 1 ? 0 : current + 1]
    setSelectedDirection(next)
    onSort && onSort(next)
  }

  return (
    <ArrowContainer
      onClick={() => {
        changeDirection()
      }}
    >
      {['asc', 'neutral'].includes(selectedDirection) && <Arrow direction="asc" icon="dropdown" />}
      {['desc', 'neutral'].includes(selectedDirection) && <Arrow direction="desc" icon="dropdown" />}
    </ArrowContainer>
  )
}
SortIcon.propTypes = {
  direction: oneOf(['asc', 'desc', 'neutral']),
  onSort: func
}

const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  gap: 5px;
  margin: auto;
`

const SortCell = ({ column, label, labelGenFn, onSort, direction }) => {
  const lLabel = label || labelGenFn?.(column) || column.name
  return (
    <th>
      <ThWrapper>
        <h3 style={{ margin: 0 }}>{lLabel}</h3>
        <SortIcon direction={direction} onSort={onSort} />
      </ThWrapper>
    </th>
  )
}
SortCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  label: any,

  /**
   * An optional function that should provide the label that will be displayed inside the cell.
   * If not provided, the label will be the column name
   * @param {object} record The record provided to the cell
   * @returns {string} The label that will be displayed in the cell
   */
  labelGenFn: func,

  /**
   * The handler to call when the sort button has been clicked.
   * This function should sort the data of the table, triggering a render of the table
   *
   * @param {'asc' | 'desc' | 'neutral'} direction The current sort direction
   */
  onSort: func,

  /**
   * The direction to render the sort icons as
   */
  direction: oneOf(['asc', 'desc', 'neutral'])
}

export default SortCell

// TODO refactor. Make the sortcell trigger the sort, not the sortIcon
