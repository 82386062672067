import React from 'react'
import { stripUnit } from 'polished'
import { bool, func, string } from 'prop-types'
import styled, { css } from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { borderColor, greyLight, secondaryColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-bottom: 1px solid ${borderColor};
  padding: ${spacing.medium};

  ${(props) =>
    props.past &&
    css`
      opacity: 0.6;
    `} ${media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:first-child {
    border-top: 1px solid ${borderColor};
  }
`

const Content = styled.div`
  font-size: ${smallFontSize};
  padding-right: ${spacing.large};
`

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Action = styled.button`
  color: ${secondaryColor};
`

const Date = styled.span`
  color: ${greyLight};
  font-size: ${tinyFontSize};
  padding-right: ${spacing.small};
  flex: 1;
`

const AlertCard = ({ children, date, action, cta, past }) => (
  <Container past={past}>
    <Content>{children}</Content>
    <Footer>
      <Date>{date}</Date>
      {cta && (
        <Action onClick={action}>
          <SmallCaps>{cta}</SmallCaps>
        </Action>
      )}
    </Footer>
  </Container>
)

AlertCard.propTypes = {
  children: string.isRequired,
  date: string.isRequired,
  action: func,
  cta: string,
  past: bool
}

export default AlertCard
