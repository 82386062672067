import React from 'react'
import { arrayOf, object, string } from 'prop-types'
import tw from 'twin.macro'

import Card from 'components/card'

import CardPlaceholder from './CardPlaceholder'

const Eyebrow = tw.hr`w-full h-px bg-slate-100`
const Header = tw.span`block text-lg font-semibold text-slate-500`

const Data2Up = ({ data, description }) => (
  <div className="flex w-full flex-col space-y-4">
    <Eyebrow />
    <Header>{description}</Header>
    {data &&
      data.slice(0, 2).map((data) => (
        <div key={data.description} className="flex flex-col space-y-2">
          <span className="text-3xl font-medium text-slate-900">
            {data?.data?.toFixed(2)}
            {data?.isPercentage && '%'}
          </span>
          <span className="text-sm font-medium text-slate-500">{data.description}</span>
        </div>
      ))}
  </div>
)

Data2Up.propTypes = {
  data: arrayOf(object),
  description: string
}

function CycleFocusPlaceholder({ span }) {
  return (
    <Card title="Cycle focus" span={span} displayAmplify hideOnSmallScreens={true}>
      <CardPlaceholder>
        <div className="flex space-x-4">
          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />
          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />
          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />
          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />
          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />

          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />

          <Data2Up
            data={[
              { description: 'Accepted stores', data: 2.6, isPercentage: true },
              { description: 'Accepted SKUs', data: 12.15, isPercentage: true }
            ]}
          />
        </div>
      </CardPlaceholder>
    </Card>
  )
}

CycleFocusPlaceholder.propTypes = {
  span: object
}

export default CycleFocusPlaceholder
