import React, { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

import * as orderSelector from 'store/orders/selectors'

import Button from 'components/button/Button'
import Container from 'components/Container'
import MobileHeader from 'components/MobileHeader'
import TableRow from 'components/tableRow/TableRow'
import TableRowGroup from 'components/tableRow/TableRowGroup'

import 'moment/locale/fr'

const AwaitingInformation = () => {
  const { translate } = useContext(LangContext)

  return (
    <TableRow>
      <small>{translate('app.awaitingUpdateInfo')}</small>
    </TableRow>
  )
}

const LastSyncs = ({ lastSyncs }) => {
  const { translate, lang } = useContext(LangContext)

  if (!lastSyncs) return <AwaitingInformation />

  return (
    <Fragment>
      {Object.entries(lastSyncs)
        .sort()
        .map(([type, date]) => (
          <TableRow key={type}>
            <span>{translate(`perfTypeDescriptors.${type}`)}</span>
            <span className="text-nowrap text-right text-sm text-brand">{moment(date).locale(lang).fromNow()}</span>
          </TableRow>
        ))}
    </Fragment>
  )
}

LastSyncs.propTypes = {
  lastSyncs: object
}

const LastSync = () => {
  const { translate, lang } = useContext(LangContext)

  const lastUserFetch = useSelector(({ auth }) => auth.lastUserFetch)
  const lastSyncs = useSelector(({ auth }) => auth.user.lastSyncs || {})
  const lastOrderSync = useSelector((state) => orderSelector.lastOrderSync(state))

  return (
    <>
      <MobileHeader isNavOnly />
      <div className="w-full max-md:mt-16">
        <Container>
          <div className="mb-6 mt-4 block p-px max-md:mx-5">
            <Button tertiary to="../" icon="left-arrow">
              {translate('common.back')}
            </Button>
          </div>
          <TableRowGroup>
            <TableRow>
              <span>{translate('app.userFetched')}</span>
              <span className="text-nowrap text-right text-sm text-brand">
                {moment(lastUserFetch).locale(lang).fromNow()}
              </span>
            </TableRow>
            <TableRow>
              <span>{translate('app.SAQUpdated')}</span>
              <span className="text-nowrap text-right text-sm text-brand">
                {lastOrderSync ? lastOrderSync.fromNow() : '-'}
              </span>
            </TableRow>
          </TableRowGroup>

          <TableRowGroup heading={translate('app.territoryPerformance')}>
            <LastSyncs lastSyncs={lastSyncs.territory} />
          </TableRowGroup>
          <TableRowGroup heading={translate('app.customerPerformance')}>
            <LastSyncs lastSyncs={lastSyncs.customer} />
          </TableRowGroup>
        </Container>
      </div>
    </>
  )
}

export default LastSync
