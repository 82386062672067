import omit from 'lodash/omit'

import { composeGraphData } from 'utils/formatters'

const currentPeriodRebates = ({ currentPeriodRebates }, locale, labels) => {
  const formattedRebates = [
    {
      sectionTitle: labels.rebatesTitle(currentPeriodRebates.goRebates),
      columns: labels.rebateColumns,
      rows: currentPeriodRebates.goRebates.brands.map(({ name, ...data }) => ({
        title: name[locale],
        values: Object.values(omit(data, ['atTarget', 'growth']))
      }))
    }
  ]
  if (currentPeriodRebates.finalCosts) {
    formattedRebates.push({
      sectionTitle: 'Final cost for pricing',
      columns: ['Unit cost', 'Off invoice', 'At target'],
      rows: currentPeriodRebates.finalCosts.brands.map(({ name, ...data }) => ({
        title: name[locale],
        values: Object.values(omit(data, ['finalCost']))
      }))
    })
  }
  return { currentPeriodRebates: formattedRebates }
}
const historicalPerformance = ({ historicalPerformance }, locale, labels) => ({
  historicalPerformance: {
    details: labels.historicalPerformance(historicalPerformance),
    sectionColumns: labels.historicalPeriodColumns,
    periods: historicalPerformance.periods.map(({ actuals, benchmark, comparison, ...periodData }) => {
      const additionalRows = labels.historicalAdditionalRows(periodData)
      const { period, ...actualsData } = actuals
      return {
        title: period,
        columns: Object.values(actualsData),
        rows: [benchmark, comparison]
          .map(({ period, ...data }) => ({ name: period, values: Object.values(data) }))
          .filter(({ name }) => Boolean(name))
          .concat(additionalRows)
      }
    })
  }
})

const currentPeriodTracking = ({ currentPeriodTracking }, locale, labels) => ({
  currentPeriodTracking: {
    performanceTiers: currentPeriodTracking.performanceTiers.map((tier) => ({
      sectionTitle: `${tier.name[locale]}: ${tier.targetTotal || tier.avpTotal}`,
      details: {
        [labels.targetRemainingPerWeek(tier, locale)]: tier.targetRemainingPerWeek,
        [labels.targetRemaining(tier, locale)]: tier.targetRemaining,
        'Potential payout': `$${tier.potentialPayout.toFixed(2)}`,
        'Perf to date': `${Math.round(tier.performanceToDate * 100)}%`
      }
    })),
    performanceToDate: composeGraphData(currentPeriodTracking.performanceToDate, locale)
  }
})

const investmentViewDetails = {
  rebates: currentPeriodRebates,
  historical: historicalPerformance,
  investments: currentPeriodTracking
}

const qcInvestmentLabels = {
  trendingPerformanceTier: 'Trending vs AVP option',
  targetRemainingPerWeek: (tier, locale) => `Cartons per week to complete ${tier.name[locale]}`,
  targetRemaining: (tier, locale) => `Total cartons remaining to complete ${tier.name[locale]}`,
  rebatesTitle: ({ option }) => `Option: ${option || '-'}`,
  rebateColumns: ['AVP'],
  historicalPerformance: ({ totalInvestment, potentialInvestment }) => ({
    Total: `$${totalInvestment ? totalInvestment.toFixed(2) : '-'}`,
    'Potential Inv.': `$${potentialInvestment ? potentialInvestment.toFixed(2) : '-'}`
  }),
  historicalPeriodColumns: ['Period', 'Option', 'AVP', 'Actuals', 'Result'],
  historicalAdditionalRows: ({ pricingDiscount, totalInvestment }) => [
    { name: 'Pricing discounts', values: [pricingDiscount] },
    { name: 'Total Investment', values: [totalInvestment] }
  ]
}

const defaultLabels = {
  trendingPerformanceTier: 'Tier',
  targetRemainingPerWeek: (tier, locale) => `Cartons per week to reach ${tier.name[locale]}`,
  targetRemaining: (tier, locale) => `Total cartons remaining to reach ${tier.name[locale]}`,
  rebatesTitle: () => 'Go rebates',
  rebateColumns: ['P4P Payout'],
  historicalPerformance: ({ totalEarned, growthTotalAdditional }) => ({
    'Total Earned': `$${totalEarned.toFixed(2)}`,
    'Total Additional Growth': `$${growthTotalAdditional.toFixed(2)}`
  }),
  historicalPeriodColumns: ['Period', 'Res.', 'At Tar.', 'Grw.', 'Act.'],
  historicalAdditionalRows: ({ salesDeals, payment }) => [
    { name: 'Sales deals', values: [salesDeals] },
    { name: 'Total Investment', values: [payment] }
  ]
}

export default ({ investments, address: { state } }, { user: { preferredLanguage = 'english' } }, currentView) => {
  if (!investments) return null
  const locale = preferredLanguage.substring(0, 2)
  const { currentPeriodTracking } = investments
  const sectionTitle = currentPeriodTracking.periodName[locale]
  const useQcFormat = state === 'QC' && currentPeriodTracking.performanceTiers[0].avpTotal !== undefined
  const labels = useQcFormat ? qcInvestmentLabels : defaultLabels

  return {
    overview: {
      sectionTitle,
      details: {
        ...(currentPeriodTracking.servicePayment ? { 'Service Payments': currentPeriodTracking.servicePayment } : null),
        [labels.trendingPerformanceTier]: currentPeriodTracking.trendingPerformanceTier,
        'Total Actuals': currentPeriodTracking.totalActuals
      }
    },
    ...investmentViewDetails[currentView](investments, locale, labels)
  }
}
