import React from 'react'
import startCase from 'lodash/startCase'
import { func, number, object } from 'prop-types'
import styled from 'styled-components'

import Checkbox from 'components/Checkbox'
import Status from 'components/Status'

import { greyDark } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;

  ${media.breakpoint`
    padding: 0;
  `};
`

const Inner = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
  grid-template-columns: repeat(2, 1fr);
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
`

const Main = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: -3px; /* Align number with checkbox label */
`

const Count = styled.span`
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  display: block;
  margin-left: 16px; /* Align number with checkbox label */
`

const Heading = styled.span`
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  display: block;
  color: ${greyDark};
  margin-bottom: ${spacing.medium};
`

const SurveyCompletionFilters = ({
  statusFilters,
  complete = 0,
  actionRequiredByTM = 0,
  actionRequiredByVF = 0,
  reserved = 0,
  updateStatusFilters,
  statusColors
}) => {
  const counts = {
    complete,
    actionRequiredByTM,
    actionRequiredByVF,
    reserved
  }

  const toggleStatus = (status) => {
    updateStatusFilters({
      ...statusFilters,
      [status]: !statusFilters[status]
    })
  }

  return (
    <Container>
      <Heading>Filter by:</Heading>
      <Inner>
        {Object.keys(counts).map((status, idx) => (
          <Item key={idx}>
            <Checkbox checked={statusFilters[status]} onClick={() => toggleStatus(status)} />
            <Main>
              <Status status={status} color={statusColors[status]} label={startCase(status)} />
              <Count>{counts[status]}</Count>
            </Main>
          </Item>
        ))}
      </Inner>
    </Container>
  )
}

SurveyCompletionFilters.propTypes = {
  reserved: number,
  complete: number,
  actionRequiredByTM: number,
  actionRequiredByVF: number,
  updateStatusFilters: func,
  statusColors: object,
  statusFilters: object
}

export default SurveyCompletionFilters
