import flatten from 'lodash/flatten'
import omit from 'lodash/omit'
import range from 'lodash/range'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { OOT_CATEGORIES } from 'utils/constants'

import { calendarEventColors } from 'styles/colors'

export const allOots = (state) => {
  return Object.values(omit(state.employeeOots, '_persist'))
}

export const calendarEventFromOOT = (oot) => {
  const color = calendarEventColors['time-off'] || 'blue'
  const category = OOT_CATEGORIES.find((cat) => cat.value === oot.category)?.label || oot.category
  const ootStart = moment(oot.start || oot.startAt)
  const ootEnd = moment(oot.end || oot.endAt)
  const ootType = oot.isGlobal ? 'GLO' : 'EMP'

  const baseOOT = {
    backgroundColor: color,
    borderColor: color,
    eventType: 'oot',
    isGlobal: oot.isGlobal,
    ootDetails: oot
  }

  const isMultiDayOOT = oot.isAllDay && moment(ootEnd).isAfter(ootStart, 'day')
  if (isMultiDayOOT) {
    const numberOfDays = moment(ootEnd).diff(moment(ootStart), 'days') + 1
    return range(numberOfDays).map((x, idx) => {
      return {
        id: `${ootType}-${oot.id}-${idx}`,
        start: moment(ootStart)
          .set({ hour: moment(ootStart).hours(), minute: moment(ootStart).minutes() })
          .add(x, 'day')
          .format('yyyy-MM-DDTHH:mm:ss.000Z'),
        end: moment(ootStart)
          .set({ hour: moment(ootEnd).hours(), minute: moment(ootEnd).minutes() })
          .add(x, 'day')
          .format('yyyy-MM-DDTHH:mm:ss.000Z'),
        title: `${oot.name} - ${category} (${idx + 1}/${numberOfDays})`,
        ...baseOOT
      }
    })
  }

  const calendarEvent = {
    id: `${ootType}-${oot.id}`,
    start: oot.startAt,
    end: oot.endAt,
    title: `${oot.name} - ${category}`,
    ...baseOOT
  }
  return calendarEvent
}

export const allCalendarOots = createSelector(allOots, (oots) => {
  if (!oots?.length) return []
  const calendarOots = oots.map((oot) => calendarEventFromOOT(oot))
  return uniqBy(flatten(calendarOots), 'id')
})
