import React from 'react'
import omit from 'lodash/omit'
import moment from 'moment'
import { array, bool, func, string } from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components'

import DatePicker from 'components/DatePicker/index'
import Dropdown from 'components/Dropdown'
import ErpSuggest from 'components/ErpSuggest'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'
import Label from 'components/Label'
import SegmentControl from 'components/SegmentControl'
import { ToggleField } from 'components/Toggle'

import {
  allFieldLabels,
  NONCTMC,
  OTHER,
  productCategories,
  sources,
  types,
  VAPE_SPECIALTY_STORE
} from 'utils/constants'
import { endDateAfterStart, required, requiredBy } from 'utils/validators'

import { smallFontSize } from 'styles/typography'

const ToggleLabel = styled(Label)`
  font-size: ${smallFontSize};
`
const requiredByVapeSpecialtyType = requiredBy('type', VAPE_SPECIALTY_STORE)

export const ProductCategory = ({ scopeFieldNames = [], change, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Dropdown}
      disabled={scopeFieldNames.includes('productCategory')}
      name="productCategory"
      label={allFieldLabels.productCategory}
      options={productCategories}
      placeholder="Select an option"
      validate={required}
      onChange={() => {
        change('manufacturer', '')
      }}
    />
  </FieldsetItem>
)
ProductCategory.propTypes = {
  scopeFieldNames: array,
  change: func
}
export const Manufacturer = ({ scopeFieldNames = [], change, manufacturers, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Dropdown}
      disabled={!manufacturers.length || scopeFieldNames.includes('manufacturer')}
      name="manufacturer"
      label={allFieldLabels.manufacturer}
      options={manufacturers.map((m) => ({ label: m, value: m }))}
      placeholder={manufacturers.length ? 'Select an option' : 'Select a product category'}
      validate={required}
      onChange={(e, newVal) => {
        const brandSet = [NONCTMC, OTHER].includes(newVal) ? newVal : ''
        change('brand', brandSet)
      }}
      renderWithoutOptions
    />
  </FieldsetItem>
)

Manufacturer.propTypes = {
  change: func,
  scopeFieldNames: array,
  manufacturers: array
}

export const CustomBrand = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      disabled={scopeFieldNames.includes('customBrand')}
      name="customBrand"
      label={allFieldLabels.customBrand}
      placeholder="Enter a brand..."
      normalize={(val) => (val ? val[0].toUpperCase() + val.substring(1) : val)}
      validate={required}
    />
  </FieldsetItem>
)

CustomBrand.propTypes = {
  scopeFieldNames: array
}

export const Brand = ({ scopeFieldNames = [], brandOptions, change, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Dropdown}
      disabled={!brandOptions.length || scopeFieldNames.includes('brand')}
      name="brand"
      label={allFieldLabels.brand}
      options={brandOptions.map((brand) => ({ label: brand, value: brand }))}
      placeholder={brandOptions.length ? 'Select an option' : 'Select a manufacturer'}
      validate={required}
      renderWithoutOptions
    />
  </FieldsetItem>
)

Brand.propTypes = {
  brandOptions: array.isRequired,
  change: func.isRequired,
  scopeFieldNames: array
}

export const Type = ({ scopeFieldNames = [], change, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Dropdown}
      disabled={scopeFieldNames.includes('type')}
      name="type"
      label={allFieldLabels.type}
      options={types.map((m) => ({ label: m, value: m }))}
      placeholder="Select an option"
      validate={required}
      onChange={() => {
        change('mfctRebate', null)
        change('rspDiscount20', null)
        change('rspDiscount20', null)
        change('backMargin', null)
        change('offInvoice', null)
        change('isAppliedToRsp', null)
        change('storeName', null)
        change('comments', null)
      }}
    />
  </FieldsetItem>
)

Type.propTypes = {
  change: func,
  scopeFieldNames: array
}

export const Covid = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={ToggleField}
      disabled={scopeFieldNames.includes('covid')}
      name="covid"
      label={allFieldLabels.covid}
    />
  </FieldsetItem>
)

Covid.propTypes = {
  scopeFieldNames: array
}

export const Regulations = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={ToggleField}
      disabled={scopeFieldNames.includes('regulations')}
      name="regulations"
      label={allFieldLabels.regulations}
    />
  </FieldsetItem>
)

Regulations.propTypes = {
  scopeFieldNames: array
}

export const PlainPack = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={ToggleField}
      disabled={scopeFieldNames.includes('plainPack')}
      name="plainPack"
      label={allFieldLabels.plainPack}
    />
  </FieldsetItem>
)

PlainPack.propTypes = {
  scopeFieldNames: array
}

export const MfctRebate = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.mfctRebate}
      placeholder="200"
      disabled={scopeFieldNames.includes('mfctRebate')}
      name="mfctRebate"
      dollar
      normalize={(val) => val.replace(/[^.\d]/g, '')}
    />
  </FieldsetItem>
)

MfctRebate.propTypes = {
  scopeFieldNames: array
}

export const RspDiscount20 = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem half>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.rspDiscount20}
      placeholder="200"
      disabled={scopeFieldNames.includes('rspDiscount20')}
      name="rspDiscount20"
      dollar
      normalize={(val) => val.replace(/[^.\d]/g, '')}
    />
  </FieldsetItem>
)
RspDiscount20.propTypes = {
  scopeFieldNames: array
}
export const RspDiscount25 = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem half>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.rspDiscount25}
      placeholder="200"
      disabled={scopeFieldNames.includes('rspDiscount25')}
      name="rspDiscount25"
      dollar
      normalize={(val) => val.replace(/[^.\d]/g, '')}
    />
  </FieldsetItem>
)
RspDiscount25.propTypes = {
  scopeFieldNames: array
}
export const OffInvoice = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem half>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.offInvoice}
      placeholder="200"
      disabled={scopeFieldNames.includes('offInvoice')}
      name="offInvoice"
      dollar
      normalize={(val) => val.replace(/[^.\d]/g, '')}
    />
  </FieldsetItem>
)
OffInvoice.propTypes = {
  scopeFieldNames: array
}
export const IsAppliedToRsp = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem half>
    <Field
      {...props}
      component={Dropdown}
      options={[
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]}
      label={allFieldLabels.isAppliedToRsp}
      disabled={scopeFieldNames.includes('isAppliedToRsp')}
      name="isAppliedToRsp"
      placeholder="Select an option"
    />
  </FieldsetItem>
)
IsAppliedToRsp.propTypes = {
  scopeFieldNames: array
}
export const BackMargin = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.backMargin}
      placeholder="200"
      disabled={scopeFieldNames.includes('backMargin')}
      name="backMargin"
      dollar
      normalize={(val) => val.replace(/[^.\d]/g, '')}
    />
  </FieldsetItem>
)
BackMargin.propTypes = {
  scopeFieldNames: array
}

export const StoreName = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.storeName}
      placeholder="Vape specialty store name here..."
      disabled={scopeFieldNames.includes('storeName')}
      name="storeName"
      validate={requiredByVapeSpecialtyType}
    />
  </FieldsetItem>
)
StoreName.propTypes = {
  scopeFieldNames: array
}
export const Comments = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label={allFieldLabels.comments}
      placeholder="Write additional details here..."
      disabled={scopeFieldNames.includes('coments')}
      name="comments"
      textarea
    />
  </FieldsetItem>
)
Comments.propTypes = {
  scopeFieldNames: array
}
export const Source = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Dropdown}
      disabled={scopeFieldNames.includes('source')}
      name="source"
      label={allFieldLabels.source}
      options={sources.map((m) => ({ label: m, value: m }))}
      placeholder="Select an option"
      validate={required}
    />
  </FieldsetItem>
)
Source.propTypes = {
  scopeFieldNames: array
}
export const Details = ({ scopeFieldNames = [], ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label="Details"
      placeholder="Write additional details here..."
      name="details"
      textarea
      maxLength={255}
      validate={required}
    />
  </FieldsetItem>
)
Details.propTypes = {
  scopeFieldNames: array
}

export const StartDate = ({ scopeFieldNames = [], isRequired, ...props }) => (
  <FieldsetItem half>
    <Field
      {...omit(props, 'change', 'brandOptions')}
      component={DatePicker}
      name="startDate"
      label={'Start date' + (!isRequired && !scopeFieldNames.includes('startDate') ? ' (optional)' : '')}
      isOutsideRange={(date) => moment().subtract(90, 'days').isAfter(date)}
    />
  </FieldsetItem>
)
StartDate.propTypes = {
  scopeFieldNames: array,
  isRequired: bool
}

export const EndDate = ({ scopeFieldNames = [], isRequired, startDate, validate = [], ...props }) => (
  <FieldsetItem half>
    <Field
      {...omit(props, 'change', 'brandOptions')}
      component={DatePicker}
      name="endDate"
      label={'End date' + (!isRequired && !scopeFieldNames.includes('endDate') ? ' (optional)' : '')}
      isOutsideRange={(date) => moment().subtract(90, 'days').isAfter(date)}
      isDayBlocked={(date) => new Date(date.startOf('date')) <= new Date(startDate)}
      validate={[...validate, endDateAfterStart]}
    />
  </FieldsetItem>
)

EndDate.propTypes = {
  startDate: string,
  isRequired: bool,
  validate: array,
  scopeFieldNames: array
}

export const ImpactRating = ({ scopeFieldNames = [], isRequired, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={SegmentControl}
      disabled={scopeFieldNames.includes('impactRating')}
      name="impactRating"
      label={'Impact rating' + (!isRequired ? ' (optional)' : '')}
      normalize={(val) => +val || null}
      options={[
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 }
      ]}
      hint="Please rate the impact of the competitor intel activity on ITCAN Share and Volume within your territory.  5 = high (immediate attention req'd), 1 = low."
    />
  </FieldsetItem>
)

ImpactRating.propTypes = {
  isRequired: bool,
  scopeFieldNames: array
}

export const ImpactSummary = ({ scopeFieldNames = [], isRequired, ...props }) => (
  <FieldsetItem>
    <Field
      {...props}
      component={Input}
      label={'Impact summmary' + (!isRequired ? ' (optional)' : '')}
      placeholder="Write additional details here..."
      disabled={scopeFieldNames.includes('impactSummary')}
      name="impactSummary"
      textarea
    />
  </FieldsetItem>
)

ImpactSummary.propTypes = {
  isRequired: bool,
  scopeFieldNames: array
}

export const ApplyToMultiErps = ({ setIsMultiErp, isMultiErp }) => {
  return (
    <FieldsetItem>
      <ToggleField
        input={{
          onChange: setIsMultiErp,
          value: isMultiErp
        }}
        label={<ToggleLabel>Is the intel relevant to multiple stores in your territory?</ToggleLabel>}
      />
    </FieldsetItem>
  )
}
ApplyToMultiErps.propTypes = {
  isMultiErp: bool,
  setIsMultiErp: func
}

export const MultiErps = (props) => {
  return (
    <FieldsetItem>
      <Field name="scopeErps" {...props} notTerritoryRestricted component={ErpSuggest} />
    </FieldsetItem>
  )
}
MultiErps.propTypes = {
  isMultiErp: bool,
  setIsMultiErp: func
}

export default {
  productCategory: ProductCategory,
  manufacturer: Manufacturer,
  customBrand: CustomBrand,
  brand: Brand,
  type: Type,
  mfctRebate: MfctRebate,
  rspDiscount20: RspDiscount20,
  rspDiscount25: RspDiscount25,
  offInvoice: OffInvoice,
  isAppliedToRsp: IsAppliedToRsp,
  backMargin: BackMargin,
  storeName: StoreName,
  comments: Comments,
  source: Source,
  details: Details,
  startDate: StartDate,
  endDate: EndDate,
  impactRating: ImpactRating,
  impactSummary: ImpactSummary
}
