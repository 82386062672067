import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import useErrors from 'hooks/useErrors'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { bool, func, number, object } from 'prop-types'

import LangContext from 'context/LangContext'

import { deleteCustomerCall, upsertCustomerCall } from 'store/customerCalls/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import CallForm from 'components/schedule/CallForm'

import ConfirmeDeleteCallSheet from './ConfirmeDeleteCallSheet'

const getDefaultValues = () => {
  const now = moment()
  const starTime = moment(now).add(1, 'hour').set('minute', 0)
  const endTime = moment(now).add(2, 'hour').set('minute', 0)

  return {
    startDate: now.toISOString(),
    startHour: starTime.hour(),
    startMinute: starTime.minute(),
    endHour: endTime.hour(),
    endMinute: endTime.minute(),
    type: 'in-person'
  }
}

const CreateCallForm = ({
  callToEdit,
  allowPastTime,
  canEdit,
  setSheetVisible,
  sheetVisible,
  deleteCustomerCall,
  upsertCustomerCall,
  createdFromMessageId
}) => {
  const { translate } = useContext(LangContext)

  const [initialValues, setInitialValues] = useState(getDefaultValues())
  const [confirmDeleteCall, setConfirmDeleteCall] = useState(false)
  const [isLoading, setIsLoading] = useState()
  const { globalError, setGlobalError, generateErrorMessage } = useErrors()

  useEffect(() => {
    if (!callToEdit || isEmpty(callToEdit)) return

    const { customer, scheduledStart, scheduledEnd, type } = callToEdit
    const startTime = moment(scheduledStart)
    const endTime = moment(scheduledEnd)

    setInitialValues({
      customer,
      startDate: scheduledStart,
      startHour: startTime.hour(),
      startMinute: startTime.minute(),
      endHour: endTime.hour(),
      endMinute: endTime.minute(),
      type
    })
  }, [callToEdit])

  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const hasOrders = useMemo(() => {
    return callToEdit?.orders?.length || false
  }, [callToEdit])

  const onSubmit = async (values, actions) => {
    const { customer, startDate, startHour, startMinute, endHour, endMinute, type } = values
    try {
      setIsLoading(true)

      const startDateTime = moment(startDate).set({
        hour: startHour,
        minute: startMinute || 0,
        second: 0,
        millisecond: 0
      })
      const endDateTime = moment(startDate).set({ hour: endHour, minute: endMinute || 0, second: 0, millisecond: 0 })

      await upsertCustomerCall({
        ...(callToEdit ? { id: callToEdit.id } : null),
        customerId: customer.id,
        scheduledStart: moment.tz(startDateTime, employeeTimezone),
        scheduledEnd: moment.tz(endDateTime, employeeTimezone),
        scheduledType: type,
        loggedBy: 'APP',
        updatedAt: moment().toISOString(),
        ...(createdFromMessageId ? { createdFromMessageId } : {})
      })

      actions.resetForm()

      setSheetVisible(false)
    } catch (err) {
      console.error(err)
      setGlobalError(generateErrorMessage('createCallSheet.errors.scheduleCall', err))
    } finally {
      setIsLoading(false)
    }
  }

  const deleteAppointment = async () => {
    setIsLoading(true)
    deleteCustomerCall(callToEdit)
      .then(() => {
        setIsLoading(false)
        setInitialValues(getDefaultValues())
        setSheetVisible()
        setConfirmDeleteCall(false)
      })
      .catch((err) => {
        setIsLoading(false)
        setGlobalError(generateErrorMessage('createCallSheet.errors.deleteCall', err))
        setConfirmDeleteCall(false)
      })
  }

  const handleCancel = () => {
    setInitialValues(getDefaultValues())
    setSheetVisible(false)
  }

  const title = initialValues ? 'app.callDetails' : 'app.scheduleCall'

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ submitForm, isSubmitting, errors }) => (
        <>
          <ActionSheet
            title={translate(title)}
            visible={sheetVisible}
            action={
              <button type="button" onClick={handleCancel}>
                {translate('common.cancel')}
              </button>
            }
            onOverlayClick={handleCancel}
          >
            <Form>
              <CallForm allowPastTime={allowPastTime} canEdit={canEdit} />
              {canEdit && (
                <Fieldset>
                  <FieldsetItem>
                    <Button
                      full
                      primary
                      onClick={submitForm}
                      disabled={isLoading || isSubmitting || !isEmpty(errors)}
                      isLoading={isLoading || isSubmitting}
                    >
                      {!isEmpty(callToEdit) ? translate('common.save') : translate('common.create')}
                    </Button>
                  </FieldsetItem>
                  {(callToEdit?.id || callToEdit?.callId) && !hasOrders && !callToEdit?.generatedCallId && (
                    <FieldsetItem>
                      <Button
                        full
                        secondary
                        onClick={() => setConfirmDeleteCall(true)}
                        disabled={isLoading || isSubmitting}
                        isLoading={isLoading || isSubmitting}
                        type="button"
                      >
                        {translate('app.deleteCall')}
                      </Button>
                    </FieldsetItem>
                  )}
                </Fieldset>
              )}
            </Form>
          </ActionSheet>

          {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
          <ConfirmeDeleteCallSheet
            confirmDeleteCall={confirmDeleteCall}
            deleteAppointment={deleteAppointment}
            setConfirmDeleteCall={setConfirmDeleteCall}
            isLoading={isLoading || isSubmitting}
          />
        </>
      )}
    </Formik>
  )
}

CreateCallForm.propTypes = {
  callToEdit: object,
  allowPastTime: bool,
  canEdit: bool,
  setSheetVisible: func,
  sheetVisible: bool,
  deleteCustomerCall: func,
  upsertCustomerCall: func,
  createdFromMessageId: number
}

export default connect(null, {
  deleteCustomerCall,
  upsertCustomerCall
})(CreateCallForm)
