import React, { useContext, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import flatten from 'lodash/flatten'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { getCustomer, updateCustomerScope } from 'store/customers/actions'
import { customerIntel, customerIntelClarifications, customerScopeConfirmations } from 'store/customers/selectors'
import { deleteIntel } from 'store/intel/actions'

import IntelAccordion from 'components/accordion/IntelAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import FeedbackCard from 'components/FeedbackCard'
import IntelCard from 'components/IntelCard'
import Section from 'components/Section'

import { formatIntelGroupTitle } from 'utils/formatters'
import { getAverageRating } from 'utils/helpers'

const mapActionCreators = {
  deleteIntel,
  updateCustomerScope,
  getCustomer
}

const IntelList = ({ deleteIntel, updateCustomerScope, getCustomer }) => {
  const { sectorId: customerId } = useParams()
  const navigate = useNavigate()
  const { addAction } = useContext(ActionHeaderContext)

  const myId = useSelector((state) => state.auth.user.id)
  const { current, past } = useSelector((state) => customerIntel(state, { customerId }))
  const scopes = useSelector((state) => customerScopeConfirmations(state, { customerId }))
  const clarificationRequests = useSelector((state) => customerIntelClarifications(state, { customerId }))

  const noIntel = !flatten([...Object.values(current), ...Object.values(past), ...scopes, ...clarificationRequests])
    .length

  useEffect(() => {
    addAction({
      onCreate: () => navigate('create'),
      getLabel: () => 'Create'
    })
  }, [customerId])

  useEffect(() => {
    if (customerId) getCustomer(customerId)
  }, [customerId, getCustomer])

  return (
    <>
      <Container>
        {noIntel ? (
          <EmptyState title="Nothing yet" subtitle="No intel have been added yet." />
        ) : (
          <>
            {scopes.length > 0 && (
              <Section title="Are the following intel details applicable to this store?">
                {scopes.map((item, idx) => (
                  <FeedbackCard
                    key={`${item.id}-${idx}`}
                    inboxItem={item}
                    push={navigate}
                    updateCustomerScope={updateCustomerScope}
                    inCustomerView
                  />
                ))}
              </Section>
            )}
            {clarificationRequests.length > 0 && (
              <Section title="Provide requested details">
                {clarificationRequests.map((item, idx) => (
                  <FeedbackCard key={`${item.id}-${idx}`} inboxItem={item} push={navigate} inCustomerView />
                ))}
              </Section>
            )}
            {Object.keys(current).length > 0 &&
              Object.entries(current).map(([grouping, intel], idx) => (
                <Section title={formatIntelGroupTitle(grouping)} key={idx}>
                  {intel.map((iActivity, i) => (
                    <IntelCard
                      key={i}
                      deleteIntel={deleteIntel}
                      intel={iActivity}
                      isCurrent
                      isMyIntel={iActivity.employeeId === myId}
                      isCustomerView
                    />
                  ))}
                </Section>
              ))}
            {Object.keys(past).length > 0 && (
              <Section>
                <IntelAccordion
                  title="Past Intel"
                  avgStars={getAverageRating(Object.values(past))}
                  number={Object.keys(past).length}
                >
                  {Object.entries(past).map(([grouping, intel], idx) => (
                    <Section title={formatIntelGroupTitle(grouping)} key={idx}>
                      {intel.map((iActivity, i) => (
                        <IntelCard
                          key={i}
                          deleteIntel={deleteIntel}
                          intel={iActivity}
                          isPast
                          isMyIntel={iActivity.employeeId === myId}
                          isCustomerView
                        />
                      ))}
                    </Section>
                  ))}
                </IntelAccordion>
              </Section>
            )}
          </>
        )}
      </Container>
    </>
  )
}

IntelList.propTypes = {
  deleteIntel: func,
  updateCustomerScope: func,
  getCustomer: func
}

export default connect(null, mapActionCreators)(IntelList)
