import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import maxBy from 'lodash/maxBy'
import moment from 'moment'
import { func, object } from 'prop-types'
import styled from 'styled-components'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { customerFromUrl } from 'store/customers/selectors'
import { fetchMessages } from 'store/messages/actions'

import Button from 'components/button/Button'
import Container from 'components/Container'
import CustomerMessage from 'components/CustomerMessage'
import EmptyState from 'components/EmptyState'
import Status from 'components/Status'

import { isTCPEnabled } from 'utils/featureFlags'

import { borderColor, grey, red, yellow } from 'styles/colors'
import * as spacing from 'styles/spacing'

import CreateMessage from './CreateMessage'

const Alert = styled.div`
  padding-bottom: ${spacing.medium};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  padding-left: ${spacing.small};
`

const CustomerMessages = ({ fetchMessages }) => {
  const { sectorId: customerId } = useParams()
  const { addAction } = useContext(ActionHeaderContext)
  const [createVisible, setCreateVisisble] = useState(false)

  const employee = useSelector((state) => state.auth.user)
  const customer = useSelector((state) => customerFromUrl(state, { customerId }))

  const tcpEnabled = isTCPEnabled(employee)
  const isTM = !['telesalesRepresentative', 'customerService'].includes(employee.groupCode)
  const myId = employee.id

  useEffect(() => {
    fetchMessages({ customerId })
  }, [customerId])

  useEffect(() => {
    addAction({
      getCreateButton: () => (
        <Button primary onClick={() => setCreateVisisble(true)}>
          Create
        </Button>
      )
    })
  }, [customer])

  if (!customer) return null

  const lastMessage = maxBy(customer.messages, 'createdAt')
  return (
    <Container>
      {!customer?.messages?.length ? (
        <EmptyState title="Nothing yet" subtitle="No comments to view." />
      ) : (
        <>
          <ConversationStatus lastMessage={lastMessage} employee={employee} />
          <div>
            {customer.messages.map((comment, idx) => (
              <CustomerMessage
                key={idx}
                isMyComment={comment.employeeId && comment.employeeId === myId}
                {...comment}
                tcpEnabled={tcpEnabled}
              />
            ))}
          </div>
        </>
      )}
      {(isTM ||
        ['actionRequiredByVF', 'complete'].includes(customer.messagesStatus || 'complete') ||
        (customer.messagesStatus === 'reserved' && customer.messages[0].assignedEmployeeId === myId)) && (
        <>
          <Outlet />
        </>
      )}
      <CreateMessage
        lastMessage={customer.messages[0]}
        messagesStatus={customer.messagesStatus}
        cancelAndReturn={() => setCreateVisisble(!createVisible)}
        isVisible={Boolean(createVisible)}
      />
    </Container>
  )
}

CustomerMessages.propTypes = {
  fetchMessages: func.isRequired
}

const mapActionCreators = {
  fetchMessages
}

const ConversationStatus = ({ lastMessage, employee }) => {
  if (!lastMessage?.actionRequiredBy) return null
  const assignmentNotExpired = lastMessage.assignmentExpiry && moment().isBefore(lastMessage.assignmentExpiry)
  if (lastMessage.assignee && assignmentNotExpired) {
    const assignedToMe = lastMessage.assignedEmployeeId === employee.id
    const assigneeName = assignedToMe ? 'you' : `${lastMessage.assignee.firstName} ${lastMessage.assignee.lastName}`
    return (
      <Alert>
        <Status
          color={assignedToMe ? red : yellow}
          label={`Assigned to ${assigneeName} until ${moment(lastMessage.assignmentExpiry).format('MMM D HH:mm')}`}
        />
      </Alert>
    )
  }
  const assignedToVF = lastMessage.actionRequiredBy === 'VF'
  const isVFUser = ['telesalesRepresentative', 'customerService'].includes(employee.groupCode)
  const indicatorColor = assignedToVF === isVFUser ? red : grey
  return (
    <Alert>
      <Status color={indicatorColor} label={`Action required by ${lastMessage.actionRequiredBy}`} />
    </Alert>
  )
}

ConversationStatus.propTypes = {
  lastMessage: object,
  employee: object.isRequired
}

const UnconnectedNonCallCustomerMessages = () => {
  return <CustomerMessages />
}

export const NonCallCustomerMessages = connect(null, mapActionCreators)(UnconnectedNonCallCustomerMessages)

export default connect(null, mapActionCreators)(CustomerMessages)
