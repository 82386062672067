import LZ from 'lz-string'
import Worker from 'workers/refetch.worker'

import { requestLogout, storeUser } from 'store/auth/actions'

import { store } from '../main'

const myWorker = new Worker()

myWorker.onmessage = function ({ data }) {
  if (data.error) {
    store.dispatch(requestLogout())
  }
  if (data.entities) {
    store.dispatch(storeUser(data.entities))
  }
  if (data.compressedEntities) {
    const entities = JSON.parse(LZ.decompressFromUTF16(data.compressedEntities))
    store.dispatch(storeUser(entities))
  }
}

export default myWorker
