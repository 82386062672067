import React, { useContext } from 'react'
import config from 'config'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import ClickableTile from 'components/ClickableTile'

import DistributionOpportunitiesIcon from 'static/images/advance/amplify/distribution_opportunities.png'
import ExtraHubActivitiesIcon from 'static/images/advance/amplify/extra_hub_activities.png'
import InventoryOOSIcon from 'static/images/advance/amplify/inventory_oos.png'
import PriceCaptureIcon from 'static/images/advance/amplify/price_capture.png'
import PriceComplianceIcon from 'static/images/advance/amplify/price_compliance.png'
import SellInIcon from 'static/images/advance/amplify/sell_in_vs_target.png'
import SellOutIcon from 'static/images/advance/amplify/sell_out.png'

const Container = tw.div`grid lg:grid-cols-2 xl:grid-cols-3 md:p-5 max-md:pt-16 gap-4 justify-center`

const AmplifyIndex = () => {
  const { translate } = useContext(LangContext)

  const TILES = [
    {
      title: translate('app.sellIn'),
      description: translate('amplify.sellIn.description'),
      to: 'sell-in',
      icon: {
        source: SellInIcon,
        alt: translate('app.sellIn')
      }
    },
    {
      title: translate('app.sellOut'),
      description: translate('amplify.sellOut.description'),
      to: 'sell-out',
      icon: {
        source: SellOutIcon,
        alt: translate('app.sellOut')
      }
    },
    {
      title: translate('app.OOSInv'),
      description: translate('amplify.OOSInv.description'),
      to: 'inventory',
      icon: {
        source: InventoryOOSIcon,
        alt: translate('app.OOSInv')
      },
      comingSoon: !config.featureFlags.inventoryPace
    },
    {
      title: translate('app.distro'),
      description: translate('amplify.distro.description'),
      to: 'distro',
      icon: {
        source: DistributionOpportunitiesIcon,
        alt: translate('app.distro')
      },
      comingSoon: !config.featureFlags.distroPace
    },
    {
      title: translate('app.priceCapture'),
      description: translate('amplify.priceCapture.description'),
      to: 'price-capture',
      icon: {
        source: PriceCaptureIcon,
        alt: translate('app.priceCapture')
      },
      comingSoon: !config.featureFlags.pricingPace
    },
    {
      title: translate('app.priceCompliance'),
      description: translate('amplify.priceCompliance.description'),
      to: 'price-compliance',
      icon: {
        source: PriceComplianceIcon,
        alt: translate('app.priceCompliance')
      },
      comingSoon: !config.featureFlags.pricingPace
    },
    {
      title: translate('app.extraHubActivity'),
      description: translate('amplify.extraHubActivity.description'),
      to: 'extra-hub',
      icon: {
        source: ExtraHubActivitiesIcon,
        alt: translate('app.extraHubActivity')
      },
      comingSoon: !config.featureFlags.extrahubPace
    }
  ]

  return (
    <Container>
      {TILES.map((tile) => (
        <ClickableTile key={tile.title} content={tile} comingSoon={tile.comingSoon} />
      ))}
    </Container>
  )
}

export default AmplifyIndex
