import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Dialog from '@radix-ui/react-dialog'
import config from 'config'
import { AnimatePresence, motion } from 'framer-motion'
import { bool } from 'prop-types'
import InlineSVG from 'svg-inline-react'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import { AdvanceLogotypeForMobile } from 'components/AdvanceLogotype'
import IconButton from 'components/button/IconButton'
import Icon from 'components/Icon'
import Profile from 'components/Sidebar/Profile'

import AdvanceLogo from 'static/images/advance/adv_trade_icon.png'
import BatLogo from 'static/logos/bat-logo.svg'

import MobileLink from './MobileLink'
import MobileLinkMenu from './MobileLinkMenu'

const overlayVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const menuVariants = {
  initial: {
    opacity: 0,
    x: 30,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      ease: [0.44, 0, 0.38, 1],
      duration: 0.3
    }
  }
}

const MenuButton = tw.button`relative z-10 flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-brand active:bg-brand-600 transition-all text-white shadow-lg`

const MenuButtonWide = tw.button`relative z-10 flex h-10 min-w-0 grow items-center rounded-full bg-brand active:bg-brand-600 transition-all text-left text-white shadow-lg ring-1 ring-brand/20`

const MobileNav = ({ isNavOnly }) => {
  const { translate } = useContext(LangContext)
  const [isOpen, setIsOpen] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const isCustomerService = user && user.groupCode === 'customerService'

  const handleClose = () => setIsOpen(false)

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      {/* Trigger */}
      <Dialog.Trigger asChild>
        {isNavOnly ? (
          <MenuButtonWide>
            <div className=" -ml-1.5 flex w-full min-w-0 items-center gap-1.5 p-2">
              <img className="h-9 w-9" src={AdvanceLogo} alt="Advance" />
              <h4 className="text-sm font-medium uppercase leading-none">Trade Hub</h4>
            </div>
            <div className="flex h-10 min-w-[40px] shrink-0 items-center pl-2 pr-3">
              <Icon icon="menu" />
            </div>
          </MenuButtonWide>
        ) : (
          <MenuButton>
            <Icon icon="menu" />
          </MenuButton>
        )}
      </Dialog.Trigger>

      {/* Portal */}
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount asChild>
              <motion.div
                className="fixed left-0 top-0 z-50 h-full w-full bg-black/60 md:hidden"
                key="overlay"
                variants={overlayVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              />
            </Dialog.Overlay>
            <Dialog.Content forceMount asChild>
              <motion.div
                className="fixed bottom-0 right-0 top-0 z-50 flex w-11/12 max-w-sm flex-col overflow-y-auto bg-brand p-6 shadow-xl md:hidden"
                key="content"
                variants={menuVariants}
                initial="initial"
                animate="enter"
                exit="initial"
              >
                <div>
                  <AdvanceLogotypeForMobile />

                  <div className="absolute right-4 top-4 flex">
                    <Dialog.Close asChild>
                      <IconButton icon="close" dark />
                    </Dialog.Close>
                  </div>
                </div>
                <div className="-mx-12 mt-6 flex h-full flex-col justify-between overflow-y-scroll px-12">
                  <div className="h-full min-h-0 space-y-4">
                    {!isCustomerService && (
                      <>
                        <div className="space-y-3">
                          <span className="ml-1 text-xs font-medium uppercase text-white/60">Pace</span>
                          <div className="space-y-1">
                            <MobileLink
                              name={translate('app.pace.plan')}
                              url="/pace/plan"
                              icon="dashboard"
                              onClick={handleClose}
                            />
                            <MobileLinkMenu
                              name={translate('app.pace.amplify')}
                              icon="graph-window"
                              links={[
                                {
                                  name: 'Sell-In',
                                  url: '/pace/amplify/sell-in',
                                  onClick: handleClose
                                },
                                {
                                  name: 'Sell-Out',
                                  url: '/pace/amplify/sell-out',
                                  onClick: handleClose
                                },
                                {
                                  name: 'OOS & Inv',
                                  url: '/pace/amplify/inventory',
                                  onClick: handleClose,
                                  disabled: !config.featureFlags.inventoryPace
                                },
                                {
                                  name: 'Distro',
                                  url: '/pace/amplify/distro',
                                  onClick: handleClose,
                                  disabled: !config.featureFlags.distroPace
                                },
                                {
                                  name: 'Price capture',
                                  url: '/pace/amplify/price-capture',
                                  onClick: handleClose,
                                  disabled: !config.featureFlags.pricingPace
                                },
                                {
                                  name: 'Price compliance',
                                  url: '/pace/amplify/price-compliance',
                                  onClick: handleClose,
                                  disabled: !config.featureFlags.pricingPace
                                },
                                {
                                  name: 'Extra Hub Activity',
                                  url: '/pace/amplify/extra-hub',
                                  onClick: handleClose,
                                  disabled: !config.featureFlags.extrahubPace
                                }
                              ]}
                            />
                            <MobileLink
                              name={translate('app.pace.close')}
                              url="/pace/close"
                              icon="checklist"
                              onClick={handleClose}
                            />
                            <MobileLink
                              name={translate('app.pace.evaluate')}
                              url="/pace/evaluate"
                              icon="clipboard"
                              disabled={!config.featureFlags.evaluate}
                              onClick={handleClose}
                            />
                          </div>
                        </div>
                        <hr className="w-full bg-white opacity-10" />
                      </>
                    )}
                    <div className="space-y-1">
                      <MobileLink name={translate('app.actions')} url="/actions" icon="actions" onClick={handleClose} />
                      <MobileLink
                        name={translate('app.alerts')}
                        url="/alerts"
                        icon="notification"
                        onClick={handleClose}
                      />
                      <MobileLink
                        name={translate('app.schedule')}
                        url="/schedule"
                        icon="calendar"
                        onClick={handleClose}
                      />
                      <MobileLink
                        name={translate('app.payouts')}
                        url="/payouts"
                        icon="stacked-coins"
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center justify-between rounded-lg bg-white/5 ring-1 ring-white/15">
                    <Profile />
                  </div>
                  <div className="-mt-2 flex shrink-0 items-center rounded py-4 pl-4">
                    <div className="-ml-3 -mt-1">
                      <InlineSVG src={BatLogo} className="h-[20px] w-[57px]" raw />
                    </div>
                  </div>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  )
}

MobileNav.propTypes = {
  isNavOnly: bool
}

export default MobileNav
