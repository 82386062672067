import { createAction } from 'redux-actions'

import { PLANNING_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningHealthCheck = createAction(
  'Get Planning Health Check Values',

  (props, dataKey) => async (dispatch, getState) =>
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.fetchPlanningHealthCheck(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].healthCheck
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
)
