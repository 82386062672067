import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useErrors from 'hooks/useErrors'
import moment from 'moment'
import { rgba } from 'polished'
import { array, bool, func, number, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { deleteCustomerCall } from 'store/customerCalls/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Input from 'components/Input'
import Label from 'components/Label'
import ConfirmeDeleteCallSheet from 'components/schedule/ConfirmeDeleteCallSheet'
import Section from 'components/Section'

import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'utils/constants'
import { getAddressString } from 'utils/helpers'

import { greyDark, secondaryColor } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, H1, smallFontSize } from 'styles/typography'

import Checklist from './Checklist'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const Title = styled(H1)`
  margin-bottom: ${spacing.tiny};

  small {
    display: inline-block;
    margin-left: ${spacing.small};
    vertical-align: text-bottom;
  }
`

const Address = styled.small`
  display: flex;
  align-items: center;
  gap: ${spacing.tiny};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.medium};

  > div {
    flex: 1;
  }
`

const MetaLabel = styled.span`
  display: block;
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  color: ${greyDark};
`

const CallButton = styled.button`
  display: flex;
  padding: ${spacing.small};
  background-color: ${rgba(secondaryColor, 0.15)};
  border-radius: 50%;
  margin-left: auto;
  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    user-select: none;
  `};
`

const CallDetails = ({
  call,
  deleteCustomerCall,
  updateCall,
  setCall,
  callStartTime,
  setIsMinimized,
  cancelCall,
  completedTasks,
  callType,
  kms,
  note,
  setKms,
  setNote,
  setCompletedTasks,
  canEdit,
  employee
}) => {
  const { translate } = useContext(LangContext)

  const [endHour, setEndHour] = useState(call?.callEnd ? moment(call?.callEnd).hours() : null)
  const [endMinute, setEndMinute] = useState(call?.callEnd ? moment(call?.callEnd).minutes() : null)
  const [saveLoading, setSaveLoading] = useState(false)
  const [confirmDeleteCall, setConfirmDeleteCall] = useState(false)

  const { globalError, setGlobalError, generateErrorMessage } = useErrors()

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const callStart = useMemo(() => call?.callStart || call?.scheduledStart || callStartTime, [call, callStartTime])
  const callEnd = useMemo(() => call?.callEnd || call?.scheduledEnd, [call])

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezone = useSelector(({ auth }) => auth.user?.timezone || browserTimezone)

  const completeOrSaveCall = async () => {
    setSaveLoading(true)

    try {
      await updateCall({
        ...call,
        callEnd:
          callType !== 'telemarketing' && canEdit
            ? call.callEnd
            : endHour && endMinute
            ? moment(call.callEnd).set({ hour: endHour, minute: endMinute || 0, second: 0, millisecond: 0 })
            : moment().tz(timezone).format()
      })
      setCall()
      setGlobalError(null)
    } catch (error) {
      setGlobalError(generateErrorMessage('callDetails.errors.saveCall', error))
    } finally {
      if (mounted.current) {
        setSaveLoading(false)
      }
    }
  }

  const deleteCall = async () => {
    setSaveLoading(true)

    try {
      await deleteCustomerCall(call)
      setCall()
      setGlobalError(null)
    } catch (error) {
      setGlobalError(generateErrorMessage('callDetails.errors.deleteCall', error))
    } finally {
      if (mounted.current) {
        setSaveLoading(false)
        setConfirmDeleteCall(false)
      }
    }
  }

  const callCustomerAssignedToEmployee = useMemo(
    () =>
      call?.customer?.primaryTerritory &&
      employee.territories &&
      employee.territories.includes(call?.customer?.primaryTerritory?.id),
    [call?.customer?.primaryTerritory, employee.territories]
  )

  const isInPerson = useMemo(() => callType && callType === 'in-person', [callType])

  const validMileage = useMemo(() => (isInPerson ? kms : true), [kms, isInPerson])
  const customerAddress = getAddressString(call?.customer.address, ['line1', 'city'])

  return (
    <>
      <ActionSheet
        title={translate('app.callDetails')}
        visible
        action={
          <button type="button" onClick={() => setCall(null)}>
            {translate('common.close')}
          </button>
        }
        onOverlayClick={() => setCall(null)}
      >
        <Container>
          <Section
            as={Link}
            to={{
              pathname: `/customers/${call?.customer?.id}/calls`,
              state: { prevRoute: { pathname: '/schedule' } }
            }}
            onClick={() => setCall(null)}
            disabled={!canEdit || !callCustomerAssignedToEmployee}
          >
            <Title>
              <span>{call?.customer?.name}</span>
              <small>{call?.customer?.id}</small>
            </Title>
            <Address>
              <Icon icon="location" small accent />
              <span>{customerAddress}</span>
            </Address>
          </Section>
          <Section>
            <Meta>
              <div>
                <MetaLabel>{translate('app.startTime')}</MetaLabel>
                <div>{moment(callStart).format('h:mm a')}</div>
              </div>
              {callEnd &&
                (callType === 'telemarketing' && canEdit ? (
                  <FieldsetItem half>
                    <Label>{translate('app.endTime')}</Label>
                    <Row>
                      <Dropdown
                        noIcon
                        name={translate('common.endTime')}
                        options={HOUR_OPTIONS.filter((opt) => opt.value >= moment(call.callStart).hours())}
                        value={endHour}
                        onChange={({ target }) => {
                          setEndHour(target.value)
                        }}
                        disabled={!canEdit}
                      />
                      <Divider>:</Divider>
                      <Dropdown
                        noIcon
                        options={MINUTE_OPTIONS.filter((opt) =>
                          endHour === moment(call.callStart).hours()
                            ? opt.value > moment(call.callStart).minutes()
                            : true
                        )}
                        value={endMinute}
                        onChange={({ target }) => {
                          setEndMinute(target.value)
                        }}
                        disabled={!canEdit}
                      />
                    </Row>
                  </FieldsetItem>
                ) : (
                  <div>
                    <MetaLabel>{translate('common.endTime')}</MetaLabel>
                    <div>{moment(callEnd).format('h:mm a')}</div>
                  </div>
                ))}
              {callType === 'telemarketing' && (
                <CallButton disabled={!canEdit}>
                  <Icon icon="phone" accent />
                </CallButton>
              )}
            </Meta>
          </Section>
          {Boolean(call.completedTasks?.length || completedTasks?.length) && (
            <Section>
              <Checklist
                items={call.completedTasks ? call.completedTasks : completedTasks}
                onChange={setCompletedTasks}
                disabled
              />
            </Section>
          )}
          <Section>
            <Fieldset>
              {callType === 'in-person' && (
                <FieldsetItem>
                  <Input
                    label={translate('common.mileage')}
                    name="mileage"
                    type="number"
                    placeholder="0"
                    value={kms || ''}
                    onChange={({ target }) =>
                      target.value || target.value >= 0 ? setKms(+String(target.value).replace('e', '')) : setKms(null)
                    }
                    disabled={!canEdit}
                  />
                </FieldsetItem>
              )}
              <FieldsetItem>
                <Input
                  label={translate('app.callNotes')}
                  name="notes"
                  placeholder={translate('app.placeholders.typeNotesHere')}
                  textarea
                  value={note}
                  maxLength={700}
                  enableCounter
                  onChange={({ target }) => setNote(target.value)}
                  disabled={!canEdit}
                />
              </FieldsetItem>

              {!validMileage && canEdit && (
                <FieldsetItem>
                  <GlobalAlert>{translate('callDetails.mileageRequiredToSaveCall')}</GlobalAlert>
                </FieldsetItem>
              )}
              {globalError && (
                <FieldsetItem>
                  <GlobalAlert>{globalError}</GlobalAlert>
                </FieldsetItem>
              )}
            </Fieldset>
          </Section>
          {canEdit && (
            <Section>
              <ButtonGroup full>
                <Button
                  full
                  primary
                  isLoading={saveLoading}
                  disabled={saveLoading || !validMileage}
                  onClick={completeOrSaveCall}
                >
                  {translate('common.save')}
                </Button>
                {/* {isCallCompleted && !call.scheduledStart && ( */}
                {!call.generatedCallId && (
                  <Button full secondary isLoading={saveLoading} onClick={() => setConfirmDeleteCall(true)}>
                    {translate('common.delete')}
                  </Button>
                )}
              </ButtonGroup>
            </Section>
          )}
        </Container>
      </ActionSheet>

      <ConfirmeDeleteCallSheet
        confirmDeleteCall={confirmDeleteCall}
        deleteAppointment={deleteCall}
        setConfirmDeleteCall={setConfirmDeleteCall}
        isLoading={saveLoading}
      />
    </>
  )
}

CallDetails.propTypes = {
  call: object,
  setIsMinimized: func,
  cancelCall: func,
  callStartTime: number,
  setCall: func,
  updateCall: func,
  completedTasks: array,
  callType: string,
  kms: string,
  note: string,
  setKms: func,
  setNote: func,
  setCompletedTasks: func,
  deleteCustomerCall: func.isRequired,
  canEdit: bool,
  employee: object
}

export default connect(null, { deleteCustomerCall })(CallDetails)
