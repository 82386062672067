import React, { useState } from 'react'
import { func, shape, string } from 'prop-types'

import Button from '../button/Button'

import { Modal } from '.'

const WarningModal = ({ title, message, trigger, secondaryAction, primaryAction }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const primaryActionType = primaryAction.type || 'default'
  const isDestructive = primaryActionType === 'destructive'

  const handleSecondaryAction = () => {
    secondaryAction.action()
    setModalOpen(false)
  }

  const handlePrimaryAction = () => {
    primaryAction.action()
    setModalOpen(false)
  }

  return (
    <Modal
      title={title}
      subtitle={message}
      footer={
        <div className="flex w-full justify-end gap-3">
          {secondaryAction && (
            <Button secondary onClick={handleSecondaryAction}>
              {secondaryAction.label}
            </Button>
          )}
          <Button primary={!isDestructive} destructive={isDestructive} onClick={handlePrimaryAction}>
            {primaryAction.label}
          </Button>
        </div>
      }
      trigger={trigger}
      open={modalOpen}
      onOpenChange={setModalOpen}
      onHandeClose={() => setModalOpen(false)}
    />
  )
}

WarningModal.propTypes = {
  title: string,
  message: string,
  trigger: React.node,
  secondaryAction: shape({
    label: string,
    action: func
  }),
  primaryAction: shape({
    label: string,
    type: 'default' | 'destructive',
    action: func
  })
}

export default WarningModal
