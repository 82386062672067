import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

import { SECTOR_LEVELS } from 'utils/constants'

const getCurrentInventory = createSelector(dataSourceFromSectorLevel, (sectorData) => sectorData?.inventory || {})

const activeProductTypeFromProps = (state, props) => props.activeProductType
const vapeCategoryFromProps = (state, props) => props.vapeCategory
const offsetFromProps = (state, props) => props.offset
const productTypeFromProps = (state, props) => props.productType
const geographyFromProps = (state, props) => props.geography
const ownershipFromProps = (state, props) => props.ownership
const brandFromProps = (state, props) => props.brand
const materialFromProps = (state, props) => props.material
const timeframeFromProps = (state, props) => props.timeframe
const sortByFromProps = (state, props) => props.sortBy
const directionFromProps = (state, props) => props.direction

export const getCurrentTrending = createSelector(
  getCurrentInventory,
  activeProductTypeFromProps,
  vapeCategoryFromProps,
  (inventory, productType, vapeCategory) => {
    const trendingValues = inventory?.trending

    if (!trendingValues) return { oos: [], inventory: [] }

    const realValues = trendingValues[`${productType}-${vapeCategory}`]

    return realValues || { oos: [], inventory: [] }
  }
)

export const getCurrentBrands = createSelector(getCurrentInventory, (inventory) => inventory?.brands || {})

export const getCurrentMaterials = createSelector(getCurrentInventory, (inventory) => inventory?.materials || {})

export const getCurrentGeography = createSelector(
  getCurrentInventory,
  geographyFromProps,
  ownershipFromProps,
  brandFromProps,
  materialFromProps,
  productTypeFromProps,
  vapeCategoryFromProps,
  timeframeFromProps,
  offsetFromProps,
  sortByFromProps,
  directionFromProps,
  (
    inventory,
    geography,
    ownership,
    brand,
    material,
    productType,
    vapeCategory,
    timeframe,
    offset,
    sortBy,
    direction
  ) => {
    const geographies = inventory?.geographies
    if (!geographies) return []

    const geoData =
      geographies[
        `${geography}-${ownership}-${brand}-${material}-${productType}-${vapeCategory}-${timeframe}-${offset}-${sortBy}-${direction}`
      ]

    if (!geoData) return []

    return geoData.map((geo) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${geo.id}/pace/amplify/inventory`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${geo.id}/pace/amplify/inventory`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${geo.id}/pace/amplify/inventory`,
          ...geo
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${geo.id}/pace/amplify/inventory`,
          ...geo
        }
      }
      return {
        linkTo: null,
        ...geo
      }
    })
  }
)

export const getCurrentFilters = createSelector(getCurrentInventory, (inventory) => inventory?.filters || {})
