import React from 'react'
import { array, object, string } from 'prop-types'
import { Field } from 'redux-form'
import styled from 'styled-components'

import Dropdown from 'components/Dropdown'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import Input from 'components/Input'

import { required } from 'utils/validators'

import { H3 } from 'styles/typography'

const Container = styled.div`
  width: 100%;
`

const TrueFalseDropdown = (props) => (
  <Dropdown
    {...props}
    options={[
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]}
    placeholder="Select an option"
  />
)
const RenderFieldArray = ({ fields, title, meta: { error, submitFailed } }) => (
  <Container>
    {title && (
      <FieldsetItem>
        <H3>{title}</H3>
      </FieldsetItem>
    )}
    {fields.map((feedback, idx) => (
      <FieldsetItem key={idx}>
        <Field
          name={`${feedback}.answer`}
          type={fields.get(idx).type}
          label={fields.get(idx).question}
          placeholder="Enter the answer"
          component={fields.get(idx).type === 'boolean' ? TrueFalseDropdown : Input}
          validate={required}
        />
      </FieldsetItem>
    ))}
  </Container>
)

RenderFieldArray.propTypes = {
  fields: array.isRequired,
  meta: object,
  title: string
}

export default RenderFieldArray
