import isEmpty from 'lodash/isEmpty'
import snakeCase from 'lodash/snakeCase'
import uniq from 'lodash/uniq'

import { addEntities, mergeEntities, setEntities } from 'store/actions'
import { addToSectorReducer } from 'store/cycleCampaigns/reducer'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToTerritory,
  addAmplifyExtraHubEngagementValuesToTerritory,
  addAmplifyExtraHubFiltersValuesToTerritory,
  addAmplifyExtraHubGeographyValuesToTerritory,
  addAmplifyExtraHubTrendingValuesToTerritory,
  addAmplifyInventoryOosBrandsValuesToTerritory,
  addAmplifyInventoryOosFiltersValuesToTerritory,
  addAmplifyInventoryOosGeographyValuesToTerritory,
  addAmplifyInventoryOosMaterialValuesToTerritory,
  addAmplifyInventoryOosTrendingValuesToTerritory,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory,
  addAmplifyPriceCaptureComplianceFiltersValuesToTerritory,
  addAmplifyPriceCaptureComplianceFootprintValuesToTerritory,
  addAmplifyPriceCaptureComplianceGeographyValuesToTerritory,
  addAmplifyPriceComplianceEdlpComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyComplianceValuesToTerritory,
  addAmplifyPriceComplianceGeographyFiltersValuesToTerritory,
  addAmplifyPriceComplianceStrategyComplianceValuesToTerritory,
  addAmplifySelloutGeographyValuesToTerritory,
  addAmplifySelloutValuesToTerritory,
  addCycleCampaignsToTerritory,
  addDistroBrandDataToTerritory,
  addDistroGeographyDataToTerritory,
  addDistroMaterialDataToTerritory,
  addDistroSummaryDataToTerritory,
  addDistroTrendedDataToTerritory,
  addIntelToTerritory,
  addPlanningCycleCampaignValuesToTerritory,
  addPlanningEngagementValuesToTerritory,
  addPlanningHealthCheckValuesToTerritory,
  addPlanningPriceCheckValuesToTerritory,
  addPlanningSellinValuesToTerritory,
  addPlanningSelloutValuesToTerritory,
  addSellinGeographyToTerritory,
  addSellinOrderCompletionToTerritory,
  addSellinRunRateToTerritory,
  addTerritoryProjectLinks,
  removeIntelFromTerritory,
  updatePerfSequence
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.territories) return state
    return Object.entries(payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = acc[id] || {}
      if (!territory.sellInPrograms && oldTerr.sellInPrograms) {
        territory.sellInPrograms = oldTerr.sellInPrograms
      }
      return Object.assign(acc, {
        [id]: territory
      })
    }, Object.assign({}, state))
  },
  [setEntities]: (state, action) => {
    if (!action.payload.territories) return state
    return Object.entries(action.payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = state[id]
      if (!territory.sellInPrograms && oldTerr?.sellInPrograms) {
        territory.sellInPrograms = oldTerr.sellInPrograms
      }
      acc[id] = territory
      return acc
    }, {})
  },
  [mergeEntities]: (state, { payload }) => {
    if (!payload.territories) return state
    return Object.entries(payload.territories).reduce((acc, [id, territory]) => {
      const oldTerr = acc[id] || {}
      return Object.assign(acc, {
        [id]: {
          ...oldTerr,
          ...territory,
          sellInPrograms: uniq([].concat(oldTerr.sellInPrograms || []).concat(territory.sellInPrograms || []))
        }
      })
    }, Object.assign({}, state))
  },
  [updatePerfSequence]: (state, { payload: { territoryId, sequence } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      sequence
    }
  }),
  [addIntelToTerritory]: (state, { payload: { territoryId, id } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      intel: uniq([...state[territoryId].intel, id])
    }
  }),
  [removeIntelFromTerritory]: (state, { payload: { territoryId, id, tempId = 0 } }) => ({
    ...state,
    [territoryId]: {
      ...state[territoryId],
      intel: state[territoryId].intel.filter((intelId) => ![id, tempId].includes(intelId))
    }
  }),
  [addSellinRunRateToTerritory]: (
    state,
    { payload: { id, activeProductType, vapeCategory, unitOfMeasure, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addSellinGeographyToTerritory]: (
    state,
    {
      payload: {
        sectorId: id,
        currentProductType,
        geography,
        period,
        range,
        vapeCategory,
        unitOfMeasure,
        offset,
        result,
        sortBy,
        sortDirection
      }
    }
  ) => {
    const key = `sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${unitOfMeasure}-${sortBy}-${sortDirection}-${range}-${offset}`
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [key]: result
      }
    })
  },
  [addSellinOrderCompletionToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion`]: result
      }
    })
  },
  [addDistroTrendedDataToTerritory]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}-${vapeCategory}-${vapeChannel}`]: result
      }
    })
  },
  [addDistroBrandDataToTerritory]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`]: result
      }
    })
  },
  [addDistroGeographyDataToTerritory]: (
    state,
    {
      payload: {
        sectorId: id,
        productType,
        geography,
        vapeCategory,
        vapeChannel,
        offset,
        filters,
        result,
        sortBy,
        direction
      }
    }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${productType}-${geography}-${vapeCategory}-${vapeChannel}-offset-${offset}-${
          filters && !isEmpty(filters)
            ? Object.entries(filters)
                .map(([key, value]) => `${key}-${snakeCase(value)}`)
                .join('-')
            : 'no-filters'
        }-${sortBy}-${direction}`]: result
      }
    })
  },
  [addDistroMaterialDataToTerritory]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}`]: result
      }
    })
  },
  [addDistroSummaryDataToTerritory]: (
    state,
    { payload: { sectorId: id, productType, vapeCategory, vapeChannel, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-summary-${productType}-${vapeCategory}-${vapeChannel}`]: result
      }
    })
  },
  [addPlanningSellinValuesToTerritory]: (state, { payload: { id, result, type, vapeCategory, unitOfMeasure } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${type}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToTerritory]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToTerritory]: selloutReducer.mergeMain,
  [addAmplifySelloutGeographyValuesToTerritory]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToTerritory]: (
    state,
    { payload: { id, productType, result, timeframe, vapeCategory, vapeChannel } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${productType}-${vapeCategory}-timeframe-${timeframe}-${vapeChannel}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToTerritory]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToTerritory]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },

  [addPlanningCycleCampaignValuesToTerritory]: (
    state,
    { payload: { sectorId: id, campaignId, cycleCampaign: result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`planning-cycle-planning-campaign-${campaignId}`]: result
      }
    })
  },
  [addTerritoryProjectLinks]: (state, { payload: { territoryId, projectLinks, customers } }) =>
    Object.assign({}, state, {
      [territoryId]: {
        ...state[territoryId],
        projectLinks,
        customers
      }
    }),
  [addAmplifyInventoryOosTrendingValuesToTerritory]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToTerritory]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToTerritory]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToTerritory]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToTerritory]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToTerritory]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToTerritory]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToTerritory]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToTerritory]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToTerritory]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToTerritory]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToTerritory]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToTerritory]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToTerritory]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToTerritory]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToTerritory]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToTerritory]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToTerritory]: priceCaptureCompliance.priceComplianceGeographyFilters,
  [addCycleCampaignsToTerritory]: addToSectorReducer
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
