import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Scroll from 'react-scroll'
import { bool, node, object, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, greyDark, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontBold, smallFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};

  ${(props) =>
    !props.isSubrow &&
    media.breakpoint`
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};

    &:first-child {
      border-top-left-radius: ${borderRadius};
      border-top-right-radius: ${borderRadius};
    }

    &:last-child {
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    }
  `};

  &:last-child {
    border-bottom: ${(props) => (props.isSubrow ? '0' : '1')}px solid ${borderColor};
  }
`
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium};
`

const TitleWrap = styled.div`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
`

const Title = styled.small`
  font-weight: ${fontBold};
  color: ${greyDark};
  font-size: 14px;
`

const Content = styled.main`
  padding: ${spacing.medium};
  padding-top: ${(props) => (props.hasSubrows ? spacing.medium : spacing.tiny)};
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  font-size: ${smallFontSize};
  margin-top: -${spacing.small};
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};
`

const ProjectAccordion = ({ project, expanded, title, details, children }) => {
  const { sectorId } = useParams()
  const projectRef = useRef()
  const projectHeaderRef = useRef()
  const navigate = useNavigate()

  const toggleExpanded = () => {
    navigate(`/customer/${sectorId}/pace/close/insight-to-action${expanded ? '' : '/' + project.id}`)
  }

  return (
    <Scroll.Element name={`project-${project.projectId}`}>
      <Container ref={projectRef} expanded={expanded}>
        <Header ref={projectHeaderRef} onClick={toggleExpanded}>
          <TitleWrap>
            <Title>{title}</Title>
            {details}
          </TitleWrap>
          <Chevron icon="down-chevron" accent expanded={expanded} />
        </Header>
        <Content hasSubrows={Boolean(children)} expanded={expanded}>
          {expanded && children}
        </Content>
      </Container>
    </Scroll.Element>
  )
}

ProjectAccordion.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  details: node,
  expanded: bool,
  project: object.isRequired
}

export default ProjectAccordion
