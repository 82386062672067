import React, { useContext } from 'react'
import { bool, func } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const CancelCallConfirmation = ({ confirmCancelCallVisible, setConfirmCancelCallVisible, confirmCancelCall }) => {
  const { translate } = useContext(LangContext)

  return (
    <ActionSheet
      title={translate('app.cancelCall')}
      visible={confirmCancelCallVisible}
      onOverlayClick={() => setConfirmCancelCallVisible(false)}
    >
      <div>
        {translate('app.confirmCancelCall')}
        <Fieldset>
          <FieldsetItem>
            <Button full primary onClick={confirmCancelCall}>
              {translate('common.yes')}
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full onClick={() => setConfirmCancelCallVisible(false)}>
              {translate('common.no')}
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

CancelCallConfirmation.propTypes = {
  confirmCancelCallVisible: bool,
  setConfirmCancelCallVisible: func.isRequired,
  confirmCancelCall: func.isRequired
}

export default CancelCallConfirmation
