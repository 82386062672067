import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TerritoryIntel from 'layouts/TerritoryIntel.js'
import IntelList from 'views/actions/intel/IntelList'

import { SECTOR_LEVELS } from 'utils/constants'

const PricingIndex = () => {
  const { sectorType } = useParams()

  useEffect(() => {
    document.title = 'Actions - Intel'
  }, [])

  return (
    <>
      {sectorType === SECTOR_LEVELS.TERRITORY && <TerritoryIntel />}
      {sectorType === SECTOR_LEVELS.CUSTOMER && <IntelList />}
    </>
  )
}

export default PricingIndex
