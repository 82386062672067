import React, { useEffect, useRef, useState } from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { bool, node, string } from 'prop-types'

import { cn } from 'utils/styling'

const Tooltip = ({ hint, isNumber, contentAsChild, children, className }) => {
  const [open, setOpen] = useState(false)
  const triggerRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef.current && !triggerRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (!hint) return children

  return (
    <RadixTooltip.Root open={open} onOpenChange={setOpen}>
      <RadixTooltip.Trigger asChild={contentAsChild} ref={triggerRef} onClick={() => setOpen((prevOpen) => !prevOpen)}>
        {children}
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          sideOffset={5}
          className={cn(
            'z-[200] max-w-64 rounded bg-slate-800 px-3 py-2 text-center text-sm text-white shadow ring-1 ring-white/60 data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade',
            className
          )}
        >
          {isNumber && hint ? hint.toLocaleString('default') : hint}
          <RadixTooltip.Arrow className="drop-shadow-[0_1px_0_rgba(255,255,255,0.6)]" />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  )
}

Tooltip.propTypes = {
  children: node.isRequired,
  contentAsChild: bool,
  isNumber: bool,
  className: string,
  hint: string
}

export default Tooltip
