import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import config from 'config'

import App from './containers/App'
import createStore from './store/createStore'

// ========================================================
// Google analytics - Google Tag Manager
// ========================================================
if (config.googleTagManager) {
  TagManager.initialize(config.googleTagManager)
}

// ========================================================
// Store
// ========================================================
const initialState = window.___INITIAL_STATE__
export const { persistor, store } = createStore(initialState)

// ========================================================
// Service Workers
// ========================================================
import('./swHandler')

// ========================================================
// Render
// ========================================================
const container = document.getElementById('root')
const root = createRoot(container)

const render = () => {
  root.render(<App store={store} persistor={persistor} />)
}

// ========================================================
// Go!
// ========================================================
render()
