import { api } from 'store/api'

export const submitNewSAQ = (order) => api.post('/orders', order)
export const submitSAQEdit = (order) => api.put('/orders', order)

export const submitOrderDeletion = (orderId) => api.delete(`/orders/${orderId}`)

export const getOrderForManagerApproval = (orderId) => api.get(`/orders/manager-approval/${orderId}`)
export const getOrderForRetailerApproval = (retailerToken) => api.get(`/orders/retailer-approval/${retailerToken}`)

export const submitManagerOrderApproval = ({ orderId, ...data }) => api.put(`/orders/manager-approval/${orderId}`, data)
export const submitRetailerOrderApproval = ({ retailerOrderToken, ...data }) =>
  api.put(`/orders/retailer-approval/${retailerOrderToken}`, data)
