// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { planningHealthCheckData } from 'store/HealthCheck/selectors'
import { fetchOosInvCaptureKpis } from 'store/inventory/actions'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import VariationIndicator from 'components/VariationIndicator'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import InventoryCaptureContext from './InventoryCaptureContext'

const Header = tw.span`block text-2xs font-semibold text-slate-500`

const Data = ({ description, data, isPercentage, variation, additional, reverseVariation }) => {
  const ppIndicator = variation > 0 ? '+' : null
  return (
    <div className="flex w-full flex-col items-center space-y-4">
      <Header>{description}</Header>
      <div className="text-center">
        <span className="text-4xl font-medium">
          {data}
          {isPercentage && '%'}
        </span>
        <div className="flex items-center space-x-2">
          <VariationIndicator reverseVariation={reverseVariation} variation={Number(variation)} />
          <span className="text-2xs font-medium text-slate-500">
            {ppIndicator}
            {variation}pp {additional}
          </span>
        </div>
      </div>
    </div>
  )
}

Data.propTypes = {
  description: string,
  data: oneOfType([string, number]),
  isPercentage: bool,
  reverseVariation: bool,
  variation: oneOfType([string, number]),
  additional: string
}

const Cap = ({ span, fetchOosInvCaptureKpis }) => {
  const { currentSector, selectedLevel, currentProductType: activeProductType } = useContext(SectorContext)
  const { translate } = useContext(LangContext)

  const { timeframe, vapeCategory } = useContext(InventoryCaptureContext)
  const isMounted = useRef()

  const [error, setError] = useState()

  const healthCheckData = useSelector((state) => planningHealthCheckData(state, { timeframe, vapeCategory }))

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.INVENTORY_OOS.OOS_INV_CAPTURE, {
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: activeProductType,
    timeframe,
    vapeCategory
  })

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  useEffect(() => {
    isMounted.current = true
    if (activeProductType !== 'vape' && vapeCategory !== 'all') return
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchOosInvCaptureKpis(
        {
          id: currentSector[selectedLevel].id,
          sectorType: selectedLevel,
          productType: activeProductType,
          timeframe,
          vapeCategory
        },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }

    return () => {
      isMounted.current = false
    }
  }, [currentSector, selectedLevel, activeProductType, timeframe, vapeCategory])

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  return (
    <Card title={translate('app.OOSInventory')} span={span} displayAmplify>
      {isLoading ? (
        <WrappedSpinner icon="spinner" />
      ) : (
        <div className="mt-2 flex flex-col">
          <div className="space-y-8">
            <Data
              description={translate('app.OOS')}
              data={healthCheckData.oos.percent}
              isPercentage
              variation={healthCheckData.oos.variation}
              reverseVariation
              additional={`vs ${timeframe === '1' ? 'PW' : 'P4'}`}
            />
            <Data
              description={translate('app.InvCapture')}
              data={healthCheckData.inventoryCaptures.percent}
              isPercentage
              variation={healthCheckData.inventoryCaptures.variation}
              additional={`vs ${timeframe === '1' ? 'PW' : 'P4'}`}
            />
          </div>
        </div>
      )}
    </Card>
  )
}

Cap.propTypes = {
  span: object,
  fetchOosInvCaptureKpis: func
}

export default connect(null, { fetchOosInvCaptureKpis })(Cap)
