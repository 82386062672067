import React from 'react'
import { rgba } from 'polished'
import { node, string } from 'prop-types'
import styled from 'styled-components'

import { setForegroundColor } from 'utils/helpers'

import { greyDark, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontBold, H2, tinyFontSize } from 'styles/typography'

const TitleWrap = styled.div`
  flex: 1;
  text-align: center;
`

const Actions = styled.div`
  min-width: 72px; /* Width of 2 icons plus spacing; sometimes there are 3 though */
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: ${white};
`

const Title = styled(H2)`
  display: block;
  color: ${(props) => (props.color ? setForegroundColor(props.color, { dark: greyDark, light: white }) : white)};
  font-weight: ${fontBold};
  margin-bottom: 0;
`

const SubTitle = styled.span`
  display: block;
  color: ${(props) =>
    props.color ? setForegroundColor(props.color, { dark: greyDark, light: rgba(white, 0.75) }) : rgba(white, 0.75)};
  font-size: ${tinyFontSize};
`

const HeaderCenter = styled.div`
  margin: 0 -${spacing.xLarge};
  display: flex;
  justify-content: space-around;
`

const SubHeader = ({
  title,
  subtitle,
  headerCenter,
  headerFarLeft,
  headerLeft,
  headerRight,
  headerFarRight,
  color,
  ...rest
}) => (
  <>
    <Actions>
      {[]
        .concat(headerFarLeft)
        .concat(headerLeft)
        .filter(Boolean)
        .map(
          (hr, idx) =>
            hr && (
              <span key={idx} style={{ marginLeft: !idx ? 0 : '12px' }}>
                {hr}
              </span>
            )
        )}
    </Actions>
    <TitleWrap>
      {headerCenter && <HeaderCenter>{headerCenter}</HeaderCenter>}
      {title && <Title color={color}>{title}</Title>}
      {subtitle && <SubTitle color={color}>{subtitle}</SubTitle>}
    </TitleWrap>
    <Actions style={{ justifyContent: 'flex-end' }}>
      {[]
        .concat(headerRight)
        .concat(headerFarRight)
        .filter(Boolean)
        .map(
          (hr, idx) =>
            hr && (
              <span key={idx} style={{ marginLeft: !idx ? 0 : '12px' }}>
                {hr}
              </span>
            )
        )}
    </Actions>
  </>
)

SubHeader.propTypes = {
  title: string,
  subtitle: string,
  headerCenter: node,
  headerFarLeft: node,
  headerLeft: node,
  headerRight: node,
  headerFarRight: node,
  color: string
}

export default SubHeader
