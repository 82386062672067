import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'

import { runStressTest, validateStoreDataKey } from 'utils/helpers'

import { sectorFetchSchema } from '../schema'

import * as api from './endpoints'

export const setCurrentSector = createAction('Set Current Sector')
export const setSelectedLevel = createAction('Set Selected Level')
export const setProductType = createAction('Set Product Type')
export const mergeSearchResult = createAction('Set Sector Search Result')

export const fetchAllSectors = createAction('Fetch all sectors', (params) => async (dispatch) => {
  try {
    const { data } = await api.fetchAllSectors(params)

    // STRESS TEST - TO REMOVE
    runStressTest(() => api.fetchAllSectors(params))
    return data.sectors
  } catch (err) {
    console.log({ err })
  }
})

// We cache only the search when the query string is empty. Otherwise
// We store lots of duplicated data for no good reason.
export const cachedFetchAllSectors = createAction(
  'Fetch all sectors',
  ({ limit, sectorType, dataKey }) =>
    (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data } = await api.fetchAllSectors({ limit, sectorType })
        dispatch(mergeSearchResult({ data, sectorType }))
      })
)

export const fetchCurrentSector = createAction(
  'Fetch current sector',
  ({ sectorType, sectorId }) =>
    async (dispatch) => {
      try {
        const { data } = await api.fetchCurrentSector({ sectorType, sectorId })
        const { entities } = normalize(data, sectorFetchSchema)
        dispatch(mergeEntities(entities))
        dispatch(setCurrentSector(data))
        dispatch(setSelectedLevel(sectorType))

        // STRESS TEST - TO REMOVE
        runStressTest(() => api.fetchCurrentSector({ sectorType, sectorId }))
      } catch (err) {
        console.log({ err })
      }
    }
)
