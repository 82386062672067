import React, { useState } from 'react'
import moment from 'moment'
import { bool, func } from 'prop-types'
import styled from 'styled-components'

import { upsertEmployeeOOT } from 'store/employeeOots/endpoints'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Dropdown from 'components/Dropdown'
import Fieldset from 'components/fieldset'
// import Input from 'components/Input'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Label from 'components/Label'

import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'utils/constants'

import * as spacing from 'styles/spacing'

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Divider = styled.span`
  padding: 0 ${spacing.tiny};
`

const LUNCH_BREAK_IN_MS = 2700000 // 45 mins in MS
const LogLunch = ({ visibleScheduleScreen, setVisibleScheduleScreen }) => {
  const [startHour, setStartHour] = useState(String(new Date().getHours()).padStart(2, 0))
  const [startMinute, setStartMinute] = useState(String(new Date().getMinutes()).padStart(2, 0))
  const [endHour, setEndHour] = useState(String(new Date(Date.now() + LUNCH_BREAK_IN_MS).getHours()).padStart(2, 0))
  const [endMinute, setEndMinute] = useState(
    String(new Date(Date.now() + LUNCH_BREAK_IN_MS).getMinutes()).padStart(2, 0)
  )
  const [date, setDate] = useState(new Date().toISOString())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const submitOOT = async () => {
    try {
      setLoading(true)

      await upsertEmployeeOOT({
        // ...(ootDetails || null),
        start: moment(date).hour(startHour).minute(startMinute).toString(),
        end: moment(date).hour(endHour).minute(endMinute).toString(),
        category: 'OOT_LUNCH'
      })

      setVisibleScheduleScreen(null)
    } catch (e) {
      console.log(e)
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ActionSheet
      title="Log Lunch"
      action={
        <button type="button" onClick={() => setVisibleScheduleScreen()}>
          Cancel
        </button>
      }
      visible={visibleScheduleScreen}
    >
      <div>
        <Fieldset>
          <FieldsetItem>
            <DatePicker
              label="Date"
              name="Date"
              placeholder="Select a date"
              openDirection="up"
              value={date}
              onChange={(value) => setDate(value)}
            />
          </FieldsetItem>

          <FieldsetItem half>
            <Label>Start time</Label>
            <Row>
              <Dropdown
                noIcon
                options={HOUR_OPTIONS}
                value={startHour}
                onChange={({ target }) => {
                  setStartHour(target.value)
                }}
              />
              <Divider>:</Divider>
              <Dropdown
                noIcon
                options={MINUTE_OPTIONS}
                value={startMinute}
                onChange={({ target }) => {
                  setStartMinute(target.value)
                }}
              />
            </Row>
          </FieldsetItem>
          <FieldsetItem half>
            <Label>End time</Label>
            <Row>
              <Dropdown
                noIcon
                name="End time"
                options={HOUR_OPTIONS}
                value={endHour}
                onChange={({ target }) => {
                  setEndHour(target.value)
                }}
              />
              <Divider>:</Divider>
              <Dropdown
                noIcon
                options={MINUTE_OPTIONS}
                value={endMinute}
                onChange={({ target }) => {
                  setEndMinute(target.value)
                }}
              />
            </Row>
          </FieldsetItem>
          {error && <GlobalAlert>{error}</GlobalAlert>}

          <FieldsetItem>
            <Button full primary onClick={submitOOT} disabled={loading} isLoading={loading}>
              Save
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

LogLunch.propTypes = {
  visibleScheduleScreen: bool,
  setVisibleScheduleScreen: func
}

export default LogLunch
