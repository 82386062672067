import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'

import * as goFundSelector from 'store/goFunds/selectors'

import Accordion from 'components/accordion/GoFundsAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GoTotals from 'components/GoTotals'
import LoadingCard from 'components/LoadingCard'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const TerritoryExtraFund = ({ fetchTerritoryGoFunds }) => {
  const { sectorId: territoryId } = useParams()
  const goFunds = useSelector((state) => goFundSelector.territoryGoFundDetails(state, { territoryId }))
  const totals = useSelector((state) => goFundSelector.territoryGoFundTotals(state, { territoryId }))

  const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY_GO_FUND, { territoryId })

  useEffect(() => {
    if (territoryId) fetchTerritoryGoFunds(territoryId, dataKey)
  }, [fetchTerritoryGoFunds, territoryId])

  if (!goFunds) return <EmptyState title="No data yet" subtitle={`No Extra Funds data available`} />

  return (
    <Fragment>
      <Container>
        <LoadingCard dataKey={dataKey} />
        <GoTotals totals={totals} />
        {goFunds.map(({ programs, ...goFund }, i) => {
          if (!programs?.length) return null
          return <Accordion key={i} goFund={goFund} programs={programs} />
        })}
      </Container>
    </Fragment>
  )
}

TerritoryExtraFund.propTypes = {
  fetchTerritoryGoFunds: func
}

export default TerritoryExtraFund
