import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { arrayOf, shape, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'

import Button from 'components/button/Button'

import MobileOverlay from './MobileOverlay'

const Breadcrumb = styled(Link)(({ isActive }) => [
  tw`flex space-x-2`,
  isActive ? tw`text-slate-900 pointer-events-none cursor-auto` : tw`text-slate-500 hover:text-slate-800`
])

const ViewHeader = ({ breadcrumbs, header, filters }) => {
  const { translate } = useContext(LangContext)
  const { isWideLayout } = useContext(MediaContext)

  return (
    <div className="col-span-12 flex items-center max-md:pt-20 md:justify-between">
      <div className="w-full space-y-4">
        <div className="flex w-full items-center justify-between">
          <div className="shrink-0 space-y-2">
            {breadcrumbs && (
              <div className="flex justify-between">
                <div className="flex space-x-2">
                  {breadcrumbs.map((breadcrumb, i) => {
                    const isLast = i + 1 === breadcrumbs.length

                    return (
                      <Breadcrumb to={breadcrumb.href} key={i} isActive={isLast}>
                        <span>{breadcrumb.text}</span>
                        {!isLast && <span>/</span>}
                      </Breadcrumb>
                    )
                  })}
                </div>
              </div>
            )}
            <h1 className="shrink-0 text-2xl font-semibold md:text-4xl">{header}</h1>
          </div>
          {filters && (
            <>
              {isWideLayout ? (
                <div className="flex flex-wrap justify-end gap-3 max-md:hidden">
                  {filters.map((filter, i) => (
                    <div key={i}>{filter}</div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-wrap justify-end md:hidden">
                  <MobileOverlay
                    header={translate('common.filters')}
                    trigger={
                      <Button icon="page-info" secondary>
                        {translate('common.filters')}
                      </Button>
                    }
                  >
                    {filters.map((filter, i) => (
                      <div key={`mobile-filter-${i}`}>{filter}</div>
                    ))}
                  </MobileOverlay>
                </div>
              )}
            </>
          )}
        </div>

        <hr className="w-full" />
      </div>
    </div>
  )
}

ViewHeader.propTypes = {
  breadcrumbs: arrayOf(shape({})),
  header: string.isRequired,
  filters: arrayOf(shape({}))
}

export default ViewHeader
