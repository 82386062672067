import React, { useContext } from 'react'
import { connect, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import useErrors from 'hooks/useErrors'
import moment from 'moment-timezone'
import { array, bool, func } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { submitSwapScheduleDays } from 'store/customerCalls/actions'
import { allOots } from 'store/employeeOots/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import DatePicker from 'components/DatePicker'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'

const Spacer = styled.div`
  height: 150px;
`

const DEFAULT_VALUES = {
  date1: '',
  date2: ''
}

const SwapScheduleDays = ({
  visibleScheduleScreen,
  setVisibleScheduleScreen,
  allOots,
  submitSwapScheduleDays,
  canEdit
}) => {
  const { translate } = useContext(LangContext)
  const { globalError, setGlobalError, generateErrorMessage } = useErrors()

  const employee = useSelector((state) => state.auth.user)

  const submitSwap = async (values, actions) => {
    const { date1, date2 } = values
    setGlobalError()
    try {
      await submitSwapScheduleDays({ date1, date2 })

      setVisibleScheduleScreen(null)
      actions.resetForm()
    } catch (err) {
      console.error(err)
      setGlobalError(generateErrorMessage('swapScheduleDays.errors.swapSelectedDays', err))
    }
  }

  const checkIfDayBlocked = (d) => {
    const isOutOfCycle =
      employee.currentCycle && employee.currentCycle.endTime && moment(d).isAfter(employee.currentCycle.endTime)
    const isAllDayOOT = allOots.some(
      ({ startAt, endAt, isAllDay }) => isAllDay && moment(d).isBetween(startAt, endAt, 'day', '[]')
    )
    return isAllDayOOT || isOutOfCycle
  }

  return (
    <Formik onSubmit={submitSwap} initialValues={DEFAULT_VALUES}>
      {({ submitForm, resetForm, isSubmitting, setFieldValue, values }) => (
        <Form>
          <ActionSheet
            title={translate('app.swapDaySchedules')}
            visible={visibleScheduleScreen}
            action={
              <button
                type="button"
                onClick={() => {
                  resetForm()
                  setVisibleScheduleScreen(false)
                }}
              >
                {translate('common.cancel')}
              </button>
            }
            onOverlayClick={() => {
              resetForm()
              setVisibleScheduleScreen(false)
            }}
          >
            <div>
              <Fieldset>
                <FieldsetItem>
                  <GlobalAlert warning>{translate('swapScheduleDays.warn.allVisitsWillBeSwapped')}</GlobalAlert>
                </FieldsetItem>
                {employee.updateSchedule && (
                  <FieldsetItem>
                    <GlobalAlert warning>{translate('app.warn.scheduleWillBeRegenerated')}</GlobalAlert>
                  </FieldsetItem>
                )}
                <FieldsetItem>
                  <Field name="date1">
                    {({ field }) => (
                      <DatePicker
                        label={translate('common.from')}
                        value={field.value}
                        onChange={(val) => setFieldValue('date1', val)}
                        isOutsideRange={(d) => moment().isAfter(d, 'day')}
                        isDayBlocked={checkIfDayBlocked}
                        openDirection="down"
                      />
                    )}
                  </Field>
                </FieldsetItem>
                <Spacer />
                <FieldsetItem>
                  <Field name="date2">
                    {({ field }) => (
                      <DatePicker
                        label={translate('common.to')}
                        value={field.value}
                        onChange={(val) => setFieldValue('date2', val)}
                        isOutsideRange={(d) => moment().isAfter(d, 'day')}
                        isDayBlocked={(d) => checkIfDayBlocked(d) || (values?.date1 && d.isSame(values?.date1, 'day'))}
                        openDirection="up"
                      />
                    )}
                  </Field>
                </FieldsetItem>
                <Spacer />
                {globalError && <GlobalAlert>{globalError}</GlobalAlert>}
                <Spacer />
                <FieldsetItem>
                  <Button
                    full
                    primary
                    disabled={isSubmitting || !(values.date1 && values.date2)}
                    onClick={submitForm}
                    isLoading={isSubmitting}
                  >
                    {translate('common.submit')}
                  </Button>
                </FieldsetItem>
              </Fieldset>
            </div>
          </ActionSheet>
        </Form>
      )}
    </Formik>
  )
}

SwapScheduleDays.propTypes = {
  visibleScheduleScreen: bool.isRequired,
  setVisibleScheduleScreen: func.isRequired,
  allOots: array.isRequired,
  submitSwapScheduleDays: func.isRequired,
  canEdit: bool
}

const mapStateToProps = (state, props) => ({
  allOots: allOots(state, props)
})

export default connect(mapStateToProps, {
  submitSwapScheduleDays
})(SwapScheduleDays)
