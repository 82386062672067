import React from 'react'
import { bool, node } from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -${spacing.small};
  ${(props) =>
    props.padded &&
    css`
      padding: ${spacing.medium};
    `};
  ${media.breakpoint`
    ${(props) =>
      props.padded &&
      css`
        padding-left: 0;
        padding-right: 0;
      `}
  `}
`

const Fieldset = ({ children, padded }) => <Container padded={padded}>{children}</Container>

Fieldset.propTypes = {
  children: node.isRequired,
  padded: bool
}

export default Fieldset
