import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import noAuth from 'layouts/authWrappers/noAuth'
import styled from 'styled-components'

import * as spacing from 'styles/spacing'

import AdvanceLogo from 'static/images/advance/adv_trade_full.png'

const Content = styled.div`
  padding: ${spacing.xxxLarge} ${spacing.xLarge};
  margin: 0 auto;
  max-width: 440px;
  text-align: center;
`

const PublicLayout = () => {
  useEffect(() => {
    document.title = 'Advance'
  }, [])

  return (
    <Content>
      <div className="flex justify-center">
        <img src={AdvanceLogo} alt="Advance" className="mb-16 max-w-32" />
      </div>
      <Outlet />
    </Content>
  )
}

export default noAuth(PublicLayout)
