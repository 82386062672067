import { createAction } from 'redux-actions'

import { registerSync } from 'store/api'
import { addMileageToEmployee, removeMileageToEmployee } from 'store/auth/actions'
import outbox from 'store/outbox'

import { OUTBOX_KEYS, SYNC_ACTION_KEYS } from 'utils/constants'

import * as api from './endpoints'

const createQueuedMileage = async (mileage) => {
  const tempMileage = { ...mileage, id: mileage.id || `temp-id-${Date.now()}` }
  const mileageOutbox = (await outbox.getItem(OUTBOX_KEYS.UPSERTED_MILEAGE)) || {}
  await outbox.setItem(OUTBOX_KEYS.UPSERTED_MILEAGE, { ...mileageOutbox, [tempMileage.id]: tempMileage })
  return registerSync(SYNC_ACTION_KEYS.SUBMIT_PENDING_MILEAGE)
}

const queueOfflineMileageDeletion = async (mileage) => {
  const mileageOutbox = (await outbox.getItem(OUTBOX_KEYS.DELETED_MILEAGE)) || {}
  await outbox.setItem(OUTBOX_KEYS.DELETED_MILEAGE, { ...mileageOutbox, [mileage.id]: mileage })
  return registerSync(SYNC_ACTION_KEYS.DELETE_PENDING_MILEAGE)
}

export const saveUserMileage = createAction('Save User Mileage', (mileage) => async (dispatch) => {
  try {
    if (window.navigator.onLine) {
      await api.saveUserMileage(mileage)
    } else {
      await createQueuedMileage(mileage)
    }
    dispatch(addMileageToEmployee(mileage))
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const deleteUserMileage = createAction('Delete User Mileage', (mileage) => async (dispatch) => {
  try {
    if (window.navigator.onLine) {
      await api.deleteUserMileage(mileage)
    } else {
      await queueOfflineMileageDeletion(mileage)
    }
    await dispatch(removeMileageToEmployee(mileage))
  } catch (err) {
    console.dir(err)
    throw err
  }
})
