import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { api, uploadImagesGetKeys } from 'store/api'
import { customerFromUrl } from 'store/customers/selectors'

import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Container from 'components/Container'
import Disclaimer from 'components/Disclaimer'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import FileInput from 'components/FileInput'
import { SubHeader } from 'components/Header'
import Logo from 'components/Logo'
import TabNav from 'components/TabNav'

import { getAddressString } from 'utils/helpers'

import { extraHubBlue, white } from 'styles/colors'
import { fontBold, regularFontSize } from 'styles/typography'

import ExtraHubLogo from 'static/images/extrahub_logo.png'

const Label = styled.span`
  font-weight: ${fontBold};
  font-size: ${regularFontSize};
`

const PrimaryButton = styled(Button)`
  background-color: ${extraHubBlue};
  color: ${white};
`

const ImageUpload = () => {
  const { customerId } = useParams()

  const newImages = useSelector((state) => state.auth.newImages)
  const customer = useSelector((state) => customerFromUrl(state, { customerId }))

  const { lang, setLang, translate } = useContext(LangContext)
  const [loading, setLoading] = useState(false)

  const save = async () => {
    if (!newImages?.length) return
    setLoading(true)
    try {
      const imageKeys = await uploadImagesGetKeys(newImages)
      await api.post('/retailer-image-upload', {
        customerId: customer.id,
        imageUrls: imageKeys
      })
      setLoading(false)
      location.reload()
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const links = [{ label: translate('app.imageUpload'), to: `/retailer/${customerId}/imageupload` }]
  const altLang = lang === 'en' ? 'fr' : 'en'

  return (
    <>
      <SubHeader
        color={extraHubBlue}
        title={customer.name}
        subtitle={customer.address.line1}
        headerFarRight={<button onClick={() => setLang(altLang)}>{altLang.toUpperCase()}</button>}
      />
      <TabNav links={links} />
      <Container padded>
        <div>
          <Logo src={ExtraHubLogo} />
        </div>
        <Fieldset>
          <FieldsetItem>
            <Label>ERP : </Label>
            {customer.id}
          </FieldsetItem>
          <FieldsetItem>
            <Label>{translate('common.name')} : </Label>
            {customer.name}
          </FieldsetItem>
          <FieldsetItem>
            <Label>{translate('common.address')} : </Label>
            {getAddressString(customer.address, ['line1', 'city', 'state'])}
          </FieldsetItem>
          {customer.latestRetailerImage && (
            <>
              <div style={{ display: 'flex' }}>
                {customer.latestRetailerImage.imageUrls.map((imageUrl, index) => (
                  <a
                    key={index}
                    href={imageUrl}
                    target="_blank"
                    style={{ margin: '20px 10px', maxWidth: '30%' }}
                    rel="noreferrer"
                  >
                    <img src={imageUrl} style={{}} />
                  </a>
                ))}
              </div>
              <br />
            </>
          )}
          <FieldsetItem>
            <FileInput loading={loading} change={save} existingImages={[]} />
            <ButtonGroup>
              <PrimaryButton onClick={save} isLoading={loading} disabled={!newImages?.length}>
                {translate('save')}
              </PrimaryButton>
              <Button secondary onClick={() => location.reload()} disabled={loading}>
                {translate('common.cancel')}
              </Button>
            </ButtonGroup>
          </FieldsetItem>
          <Disclaimer>
            <p>{translate('app.imageUploadDisclaimer')}</p>
          </Disclaimer>
        </Fieldset>
      </Container>
    </>
  )
}

export default ImageUpload
