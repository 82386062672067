import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { func, object, string } from 'prop-types'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchEdlpCompliance } from 'store/priceCaptureCompliance/actions'
import { getEdlpCompliance } from 'store/priceCaptureCompliance/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const PackSize = ({ size }) => {
  return (
    <div className="flex flex-col items-center font-medium text-slate-900">
      <span className="text-base">{size}</span>
    </div>
  )
}

PackSize.propTypes = {
  size: string.isRequired
}

const CompletedAmount = tw.span`text-sm font-medium text-slate-900`

const BarContainer = tw.div`relative grow h-5 w-full overflow-clip rounded bg-brand-50`

const TotalAmount = tw.span`basis-16 text-sm font-medium text-slate-500`

const Line = ({ completed, total }) => {
  const completedValue = Number(completed)
  const totalValue = Number(total)
  const completedPercent = totalValue === 0 ? 0 : (completedValue / totalValue) * 100
  const percent = formatPercent(completedPercent, { convertDecimal: false })
  const chartData = [{ name: 'data', value: completedPercent.toFixed(2), key: 'data' + completedPercent.toFixed(2) }]

  const notEdlp = totalValue - completedValue
  return (
    <div className="w-full space-y-1">
      <div className="flex items-center gap-4">
        <CompletedAmount>{percent}</CompletedAmount>
        <BarContainer>
          <div className="absolute h-full w-full">
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <BarChart
                barCategoryGap="0%"
                layout="vertical"
                data={chartData}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <XAxis type="number" hide domain={['dataMin', 100]} />
                <YAxis type="category" hide domain={['dataMin', 'dataMax']} />
                <Bar dataKey="value" stackId="a" fill="#4FAEE6" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </BarContainer>
        <TotalAmount>of {(+totalValue).toLocaleString()}</TotalAmount>
      </div>
      {Boolean(notEdlp) && <span className="block text-2xs text-red-500">Not EDLP {(+notEdlp).toLocaleString()}</span>}
    </div>
  )
}

Line.propTypes = {
  completed: string.isRequired,
  total: string.isRequired
}

const EdlpCompliance = ({ span, fetchEdlpCompliance }) => {
  const { sectorType, sectorId } = useParams()
  const [error, setError] = useState()
  const { translate } = useContext(LangContext)

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.PRICE_CAPTURE_COMPLIANCE.EDLP_COMPLIANCE, {
    sectorType,
    sectorId
  })
  const COLS = [
    {
      field: 'type',
      headerName: ''
    },
    {
      field: 'single',
      headerName: `${translate('app.packSize')} 20`
    },
    {
      field: 'twoPack',
      headerName: `${translate('app.packSize')} 25`
    }
  ]
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const edlpCompliance = useSelector((state) => getEdlpCompliance(state))
  const rows = useMemo(() => {
    const defaultEdlp = { totalCount: 0, compliantCount: 0 }
    const edlp120 = edlpCompliance['1']?.['20'] || defaultEdlp
    const edlp220 = edlpCompliance['2']?.['20'] || defaultEdlp
    const edlp125 = edlpCompliance['1']?.['25'] || defaultEdlp
    const edlp225 = edlpCompliance['2']?.['25'] || defaultEdlp
    return [
      {
        type: <PackSize size={translate('app.single')} />,
        single: <Line completed={edlp120.compliantCount} total={edlp120.totalCount} />,
        twoPack: <Line completed={edlp125.compliantCount} total={edlp125.totalCount} />
      },
      {
        type: <PackSize size={translate('app.twoPack')} />,
        single: <Line completed={edlp220.compliantCount} total={edlp220.totalCount} />,
        twoPack: <Line completed={edlp225.compliantCount} total={edlp225.totalCount} />
      }
    ]
  }, [edlpCompliance, isLoading])

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (sectorType && sectorId) {
      fetchEdlpCompliance({ dataKey, sectorType, sectorId })
        .then(() => isMounted.current && setError())
        .catch((e) => isMounted.current && setError(e))
    }
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (isEmpty(edlpCompliance)) return <EmptyState title="Nothing to display" />

    return <DataTable columns={COLS} rows={rows} fillContainer />
  }
  return (
    <Card title={translate('app.edlpPerformance')} span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

EdlpCompliance.propTypes = {
  span: object,
  fetchEdlpCompliance: func
}

export default connect(null, {
  fetchEdlpCompliance
})(EdlpCompliance)
