import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { func, node, object, string } from 'prop-types'

import ActiveCallContext from 'context/ActiveCallContext'

import { fetchCurrentSector, setProductType, setSelectedLevel } from 'store/sector/actions'
import { getCurrentProductType, getCurrentSector, getSelectedSectorLevel } from 'store/sector/selectors'
import { fetchTerritoryCustomerList } from 'store/territories/actions'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const SectorContext = React.createContext()
export default SectorContext

const UnconnectedSectorProvider = ({
  currentSector,
  selectedLevel,
  children,
  fetchCurrentSector,
  setSelectedLevel,
  currentProductType,
  setProductType,
  fetchTerritoryCustomerList
}) => {
  const { sectorType, sectorId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)

  const { ongoingCall } = useContext(ActiveCallContext)

  const user = useSelector((state) => state.auth.user)
  const mounted = useRef()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const fetchSector = async ({ type, id }) => {
    if (mounted.current && id) {
      await fetchCurrentSector({ sectorType: type, sectorId: id })
    }
  }

  useEffect(() => {
    if (ongoingCall) {
      fetchSector({ type: SECTOR_LEVELS.CUSTOMER, id: ongoingCall.customerId })
    }
  }, [ongoingCall])

  const value = {
    currentSector,
    selectedLevel,
    setSelectedLevel,
    fetchSector,
    currentProductType,
    setProductType,
    modalOpen,
    setModalOpen
  }

  // Select a sector if we dont already have one
  useEffect(() => {
    if (isEmpty(currentSector)) {
      if (sectorType && sectorId) {
        fetchSector({ type: sectorType, id: sectorId })
      } else if (user.primaryTerritory) {
        fetchSector({ type: SECTOR_LEVELS.TERRITORY, id: user.primaryTerritory.id })
      } else if (user.regions?.length) {
        fetchSector({ type: SECTOR_LEVELS.REGION, id: user.regions[0].id })
      } else if (user.groupCode === 'districtManager' && !isEmpty(user.districts)) {
        fetchSector({ type: SECTOR_LEVELS.DISTRICT, id: user.districts[0] })
      } else if (user.territories?.length) {
        const territory = user.territories[0]
        fetchSector({ type: SECTOR_LEVELS.TERRITORY, id: territory })
      } else {
        setModalOpen(true)
      }
    }
  }, [])

  useEffect(() => {
    if (sectorType === SECTOR_LEVELS.TERRITORY) {
      const dataKey = createDataKey(DATAKEY_TYPES.TERRITORY_CUSTOMERS, { territoryId: sectorId })
      fetchTerritoryCustomerList(sectorId, dataKey)
    }
  }, [sectorType, sectorId])

  useEffect(() => {
    const sectorLevelMismatch = sectorType !== selectedLevel
    const sectorIdNotInCurrentSector = currentSector && String(currentSector[sectorType]?.id) !== sectorId
    if (sectorType && sectorId && sectorLevelMismatch && sectorIdNotInCurrentSector) {
      fetchSector({ type: sectorType, id: sectorId })
    } else if (sectorLevelMismatch) {
      setSelectedLevel(sectorType)
    }
  }, [sectorType, sectorId])

  return <SectorContext.Provider value={value}>{children}</SectorContext.Provider>
}

UnconnectedSectorProvider.propTypes = {
  children: node.isRequired,
  currentSector: object,
  selectedLevel: string,
  fetchCurrentSector: func,
  setSelectedLevel: func,
  currentProductType: string,
  setProductType: func,
  fetchTerritoryCustomerList: func
}

export const SectorProvider = connect(
  (state, props) => ({
    currentSector: getCurrentSector(state, props),
    selectedLevel: getSelectedSectorLevel(state, props),
    currentProductType: getCurrentProductType(state, props)
  }),
  {
    fetchCurrentSector,
    setSelectedLevel,
    setProductType,
    fetchTerritoryCustomerList
  }
)(UnconnectedSectorProvider)
