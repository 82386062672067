import localForage from 'localforage'
import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { persistReducer } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createCompressor from 'redux-persist-transform-compress'

import alerts from './alerts/reducer'
import auth from './auth/reducer'
import callTasks from './callTasks/reducer'
import calls from './customerCalls/reducer'
import customers from './customers/reducer'
import cycleCampaigns from './cycleCampaigns/reducer'
import dataFetches from './dataFetches/reducer'
import districts from './districts/reducer'
import employeeOots from './employeeOots/reducer'
import goFundAllocations from './goFundAllocations/reducer'
import goFundPrograms from './goFundPrograms/reducer'
import goFunds from './goFunds/reducer'
import insights from './insights/reducer'
import intel from './intel/reducer'
import nations from './nations/reducer'
import orders from './orders/reducer'
import pricings from './pricings/reducer'
import products from './products/reducer'
import projects from './projects/reducer'
import regions from './regions/reducer'
import scopes from './scopes/reducer'
import sector from './sector/reducer'
import sellInPrograms from './sellInPrograms/reducer'
import surveys from './surveys/reducer'
import territories from './territories/reducer'

const compressor = createCompressor()

const createPersistConfig = (key) => ({
  key,
  storage: localForage,
  transforms: [compressor]
})

const authPersistConfig = {
  key: 'auth',
  storage: localForage,
  blacklist: ['lastUserFetch', 'loading', 'newImages'],
  stateReconciler: autoMergeLevel2,
  debug: true
}

const alertsPersistConfig = createPersistConfig('alerts')
const customersPersistConfig = createPersistConfig('customers')
const cycleCampaignsPersistConfig = createPersistConfig('cycleCampaigns')
const goFundAllocationsPersistConfig = createPersistConfig('goFundAllocations')
const goFundProgramsPersistConfig = createPersistConfig('goFundPrograms')
const goFundsPersistConfig = createPersistConfig('goFunds')
const insightsPersistConfig = createPersistConfig('insights')
const intelPersistConfig = createPersistConfig('intel')
const ordersPersistConfig = createPersistConfig('orders')
const pricingsPersistConfig = createPersistConfig('pricings')
const productsPersistConfig = createPersistConfig('products')
const projectsPersistConfig = createPersistConfig('projects')
const sectorPersistConfig = createPersistConfig('sector')
const scopesPersistConfig = createPersistConfig('scopes')
const sellInProgramsPersistConfig = createPersistConfig('sellInPrograms')
const surveysPersistConfig = createPersistConfig('surveys')
const territoriesPersistConfig = createPersistConfig('territories')
const callsPersistConfig = createPersistConfig('calls')
const callTasksPersistConfig = createPersistConfig('callTasks')
const employeeOotsPersistConfig = createPersistConfig('employeeOots')
const dataFetchesPersistConfig = createPersistConfig('dataFetches')
const districtsPersistConfig = createPersistConfig('districts')
const regionsPersistConfig = createPersistConfig('regions')
const nationsPersistConfig = createPersistConfig('nations')

export default combineReducers({
  form,
  alerts: persistReducer(alertsPersistConfig, alerts),
  auth: persistReducer(authPersistConfig, auth),
  calls: persistReducer(callsPersistConfig, calls),
  callTasks: persistReducer(callTasksPersistConfig, callTasks),
  customers: persistReducer(customersPersistConfig, customers),
  cycleCampaigns: persistReducer(cycleCampaignsPersistConfig, cycleCampaigns),
  dataFetches: persistReducer(dataFetchesPersistConfig, dataFetches),
  districts: persistReducer(districtsPersistConfig, districts),
  employeeOots: persistReducer(employeeOotsPersistConfig, employeeOots),
  goFundAllocations: persistReducer(goFundAllocationsPersistConfig, goFundAllocations),
  goFundPrograms: persistReducer(goFundProgramsPersistConfig, goFundPrograms),
  goFunds: persistReducer(goFundsPersistConfig, goFunds),
  insights: persistReducer(insightsPersistConfig, insights),
  intel: persistReducer(intelPersistConfig, intel),
  nations: persistReducer(nationsPersistConfig, nations),
  orders: persistReducer(ordersPersistConfig, orders),
  pricings: persistReducer(pricingsPersistConfig, pricings),
  products: persistReducer(productsPersistConfig, products),
  projects: persistReducer(projectsPersistConfig, projects),
  regions: persistReducer(regionsPersistConfig, regions),
  sector: persistReducer(sectorPersistConfig, sector),
  scopes: persistReducer(scopesPersistConfig, scopes),
  sellInPrograms: persistReducer(sellInProgramsPersistConfig, sellInPrograms),
  surveys: persistReducer(surveysPersistConfig, surveys),
  territories: persistReducer(territoriesPersistConfig, territories)
})
