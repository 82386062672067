import React from 'react'
import { bool, func, number, oneOfType, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

const BreadcrumbButton = styled.button(({ isActive }) => [
  tw`relative transition ease-in-out flex-nowrap whitespace-nowrap flex items-center space-x-2 pl-5 pr-2 focus:outline-none first:pl-3 last:pr-3 py-2 before:absolute before:inset-y-[-1px] before:left-full before:z-[1] before:ml-px before:w-[1em] before:bg-slate-900/10 before:transition-[background] before:duration-[0.2s] before:ease-linear before:content-[''] before:[clip-path:polygon(50%_50%,-50%_-50%,0_100%)] after:absolute after:inset-y-[-1px] after:left-full after:z-[1] after:w-[1em] after:transition after:ease-in-out after:content-[''] after:[clip-path:polygon(50%_50%,-50%_-50%,0_100%)]`,
  isActive
    ? tw`bg-brand-50 text-brand after:bg-brand-50`
    : tw`bg-white after:bg-white text-slate-500 hover:bg-slate-100 hover:text-slate-900 after:hover:bg-slate-100 focus:bg-slate-100 focus:text-slate-900 after:focus:bg-slate-100`
])

const BreadcrumbItem = ({ label, sublabel, isActive, onClick, ...rest }) => {
  return (
    <BreadcrumbButton isActive={isActive} onClick={onClick} {...rest}>
      <span>{label}</span>
      {sublabel && <span className="text-xs opacity-70">{sublabel}</span>}
    </BreadcrumbButton>
  )
}

BreadcrumbItem.propTypes = {
  label: string.isRequired,
  sublabel: oneOfType([string, number]),
  isActive: bool,
  onClick: func
}

export default BreadcrumbItem
