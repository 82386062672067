import React from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bool, func } from 'prop-types'
import styled from 'styled-components'

import { deleteIntel } from 'store/intel/actions'
import * as territorySelector from 'store/territories/selectors'

import IntelAccordion from 'components/accordion/IntelAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import IntelCard from 'components/IntelCard'
import Section from 'components/Section'
import SmallCaps from 'components/SmallCaps'
import Spinner from 'components/Spinner'

import { getAverageRating } from 'utils/helpers'

import { borderColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Totals = styled(SmallCaps)`
  display: flex;
  justify-content: space-around;
  align-content: center;
  padding-bottom: ${spacing.medium};
`

const Card = styled.div`
  padding: ${spacing.medium};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  margin-bottom: ${spacing.medium};
  background-color: ${white};

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const NoIntel = ({ isPast }) => (
  <Container>
    <Card>
      <EmptyState
        title="Nothing yet"
        subtitle={`No competitive activity has been ${isPast ? 'completed' : 'reported'} yet.`}
      />
    </Card>
  </Container>
)

NoIntel.propTypes = {
  isPast: bool
}

const renderIntel = (intelType) => {
  const TerritoryIntel = ({ deleteIntel }) => {
    const { sectorId: territoryId } = useParams()
    const myId = useSelector((state) => state.auth.user.id)

    const { customers, totalCustomers, ...intel } =
      useSelector((state) => territorySelector.territoryIntel(state, { territoryId })) || {}

    const loading = useSelector((state) => state.auth.territoryLoading)

    const { customerCount, groupedIntel } = intel[intelType]
    const intelToRender = groupedIntel ? Object.entries(groupedIntel) : []
    const noIntel = !intelToRender.length
    const isPast = intelType === 'past'

    if (loading && noIntel) {
      return (
        <>
          <Card>
            <EmptyState title="Fetching..." subtitle="Just a moment while we fetch the customers for this territory">
              <Spinner icon="spinner" />
            </EmptyState>
          </Card>
        </>
      )
    }

    if (noIntel) return <NoIntel isPast={isPast} />

    return (
      <Container>
        <Card>
          <Totals>
            <span>
              Stores: {customerCount}/{totalCustomers}
            </span>
          </Totals>
          {intelToRender.map(([grouping, iActivities], idx) => (
            <Section key={idx}>
              <IntelAccordion title={grouping} avgStars={getAverageRating(iActivities)} number={iActivities.length}>
                {iActivities.map((activity, i) => (
                  <IntelCard
                    key={i}
                    deleteIntel={deleteIntel}
                    intel={activity}
                    customer={customers[activity.customerId]}
                    isPast={isPast}
                    // intelPath={match.url.replace('/past', '')}   // KEEP : not sure what that wass used for? Doesn't seem to be used
                    isMyIntel={activity.employeeId === myId}
                    isTerritoryView
                  />
                ))}
              </IntelAccordion>
            </Section>
          ))}
        </Card>
      </Container>
    )
  }

  TerritoryIntel.propTypes = {
    deleteIntel: func
  }

  return connect(null, { deleteIntel })(TerritoryIntel)
}

export const CurrentIntel = renderIntel('current')
export const PastIntel = renderIntel('past')
