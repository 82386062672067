import { createSelector } from 'reselect'

import { dataSourceFromSectorLevel } from 'store/sector/selectors'

const getProps = (state, props) => props
export const getCurrentPriceCheck = createSelector(
  getProps,
  dataSourceFromSectorLevel,
  (props, sectorData) => sectorData?.[`pricecheck-${props.activeProductType}`] || { graphs: {}, averages: {} }
)
