import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { bool, func, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import Icon from 'components/Icon'

const StyledNavLink = styled(NavLink)(({ $active: active, disabled }) => {
  const styles = [
    tw`text-white -mx-1 transition font-medium focus-visible:outline-none hover:bg-white/5 focus-visible:ring-2 focus-visible:ring-brand-500 px-3 flex rounded-md space-x-2 h-[36px] items-center relative`
  ]
  if (active)
    styles.push(
      tw`bg-brand-700 text-brand-accent after:absolute after:-left-5 after:top-0 after:h-full after:w-[3px] after:rounded-tr-md after:rounded-br-md after:bg-brand-accent after:content-[""] hover:bg-brand-700 hover:text-brand-accent`
    )
  if (disabled) styles.push(tw`text-white/40 pointer-events-none`)
  return styles
})

StyledNavLink.propTypes = {
  $active: bool,
  disabled: bool
}

const SidebarLink = ({ name, icon, url, disabled, onClick = () => null }) => {
  const { pathname } = useLocation()
  const isActive = pathname.includes(url)

  return (
    <StyledNavLink to={url} $active={isActive} disabled={disabled} onClick={onClick}>
      {icon && (
        <span className="-ml-[3px] flex">
          <Icon small icon={icon} />
        </span>
      )}
      <span className="max-w-full select-none text-ellipsis">{name}</span>
    </StyledNavLink>
  )
}

SidebarLink.propTypes = {
  name: string.isRequired,
  url: string,
  icon: string.isRequired,
  disabled: bool,
  onClick: func
}

export default SidebarLink
