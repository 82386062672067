import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { stripUnit } from 'polished'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'
import ScheduleContext from 'context/ScheduleContext'

import { canUserStartCall } from 'store/auth/selectors'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import ButtonGroup from 'components/button/ButtonGroup'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Section from 'components/Section'

import { IN_PERSON_LOCATION_ERRORS, IN_PERSON_LOCATION_WARNINGS } from 'utils/constants'
import { getAddressString } from 'utils/helpers'

import { borderColor, greyDark } from 'styles/colors'
import { borderRadius } from 'styles/global'
import * as spacing from 'styles/spacing'
import { fontSemibold, H1, H3, smallFontSize } from 'styles/typography'

import GMapsLogo from 'static/images/directions/gmaps.png'
import WazeLogo from 'static/images/directions/waze.png'

const Directions = styled.div`
  text-align: left;
`

const Actions = styled.div`
  display: flex;
  width: 100%;
`

const Action = styled(Button)`
  flex: 1;

  & + & {
    margin-left: ${stripUnit(spacing.small) * 1.5 + 'px'};
  }
`

const Brand = styled.img`
  width: 24px;
  margin-right: ${spacing.small};
  border: 1px solid ${borderColor};
  border-radius: ${borderRadius};
`

const Title = styled(H1)`
  margin-bottom: ${spacing.tiny};

  small {
    display: inline-block;
    margin-left: ${spacing.small};
    vertical-align: text-bottom;
  }
`

const Address = styled.small`
  display: flex;
  align-items: center;
  gap: ${spacing.tiny};
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.medium};

  > div {
    flex: 1;
  }
`

const MetaLabel = styled.span`
  display: block;
  font-size: ${smallFontSize};
  font-weight: ${fontSemibold};
  color: ${greyDark};
`

const StartCall = ({
  startCall,
  startCallScreenVisible,
  setStartCallScreenVisible,
  call,
  inPersonCallLocationError,
  editCall,
  inOngoingCall,
  loadingLocation
}) => {
  const { translate } = useContext(LangContext)
  const { isDuringOffTime } = useContext(ScheduleContext)

  const [showOotWarning, setShowOotWarning] = useState(false)
  const [intendedType, setIntendedType] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(false)
  }, [call])

  useEffect(() => {
    setShowOotWarning(false)
  }, [startCallScreenVisible])

  const callCustomerAssignedToEmployee = useSelector((state) => canUserStartCall(state, { customer: call?.customer }))

  const canStartCall = callCustomerAssignedToEmployee && !inOngoingCall

  const customerAddress = getAddressString(call?.customer.address, ['line1', 'city'])
  const handleWazeClick = () => {
    window.open(`https://waze.com/ul?q=${encodeURIComponent(customerAddress)}&navigate=yes`)
  }

  const handleGoogleMapsClick = () => {
    if (!customerAddress) return
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(customerAddress)}`)
  }

  const handleStartClick = (type) => {
    if (!canStartCall) return

    if (isDuringOffTime(moment())) {
      setShowOotWarning(true)
      setIntendedType(type)
    } else {
      startCall(type)
      setLoading(true)
    }
  }

  const handleEditCall = () => {
    setStartCallScreenVisible()
    editCall(call)
  }

  const locationError = inPersonCallLocationError && IN_PERSON_LOCATION_ERRORS[inPersonCallLocationError]
  const locationWarning = inPersonCallLocationError && IN_PERSON_LOCATION_WARNINGS[inPersonCallLocationError]
  // const unknownLocationWarning =
  //   !locationError &&
  //   !locationWarning &&
  //   inPersonCallLocationError &&
  //   `UNKNOWN ERROR: ${inPersonCallLocationError} - Please report this to support.`

  return (
    <ActionSheet
      title={translate('app.startCall')}
      visible={startCallScreenVisible}
      action={
        <ButtonGroup>
          {callCustomerAssignedToEmployee && (
            <button type="button" onClick={handleEditCall}>
              {translate('common.edit')}
            </button>
          )}
          <button type="button" onClick={() => setStartCallScreenVisible()}>
            {translate('common.close')}
          </button>
        </ButtonGroup>
      }
      onOverlayClick={() => setStartCallScreenVisible()}
    >
      <Section
        as={Link}
        to={{
          pathname: `/customer/${call?.customer?.id}/pace/plan`
        }}
        onClick={() => setStartCallScreenVisible()}
        disabled={!callCustomerAssignedToEmployee}
      >
        <Title>
          <span>{call?.customer?.name}</span>
          <small>{call?.customer?.id}</small>
        </Title>
        <Address>
          <Icon icon="location" small accent />
          <span>{customerAddress}</span>
        </Address>
      </Section>

      {call?.scheduledStart && (
        <Section>
          <Meta>
            <div>
              <MetaLabel>{translate('common.startTime')}</MetaLabel>
              <div>{moment(call?.scheduledStart).format('h:mm a')}</div>
            </div>
            {call?.scheduledEnd && (
              <div>
                <MetaLabel>{translate('common.endTime')}</MetaLabel>
                <div>{moment(call?.scheduledEnd).format('h:mm a')}</div>
              </div>
            )}
          </Meta>
        </Section>
      )}

      <Section>
        <Fieldset>
          {inOngoingCall && (
            <FieldsetItem>
              <GlobalAlert>{translate('app.warn.alreadyInCall"')}</GlobalAlert>
            </FieldsetItem>
          )}
          {!callCustomerAssignedToEmployee && (
            <FieldsetItem>
              <GlobalAlert warning>{translate('app.warn.customerNoLongerAssigned"')}</GlobalAlert>
            </FieldsetItem>
          )}
          {canStartCall && (
            <>
              <FieldsetItem>
                <Button
                  full
                  primary
                  onClick={() => handleStartClick('in-person')}
                  disabled={loadingLocation || locationError}
                  isLoading={loading}
                >
                  {translate('app.startVisit')}
                </Button>
              </FieldsetItem>

              <FieldsetItem>
                <Button full secondary onClick={() => handleStartClick('telemarketing')} isLoading={loading}>
                  {translate('app.startTelemarketingCall')}
                </Button>
              </FieldsetItem>
              {locationWarning && (
                <FieldsetItem>
                  <GlobalAlert>{locationWarning}</GlobalAlert>
                </FieldsetItem>
              )}
              {(loadingLocation || locationError) && (
                <FieldsetItem>
                  {loadingLocation ? (
                    <GlobalAlert warning>{translate('app.warn.verifyingLocation')}</GlobalAlert>
                  ) : (
                    locationError && <GlobalAlert>{locationError}</GlobalAlert>
                  )}
                </FieldsetItem>
              )}
            </>
          )}

          {showOotWarning && (
            <FieldsetItem>
              <GlobalAlert warning>
                <p>{translate('startCall.warn.confirm.startCallDuringOOT')}</p>
                <ButtonGroup>
                  <Button secondary small onClick={() => startCall(intendedType)}>
                    {translate('common.yes')}
                  </Button>
                  <Button secondary small onClick={() => setShowOotWarning(false)}>
                    {translate('common.no')}
                  </Button>
                </ButtonGroup>
              </GlobalAlert>
            </FieldsetItem>
          )}

          {callCustomerAssignedToEmployee && customerAddress && (
            <FieldsetItem>
              <Directions>
                <H3>{translate('common.getDirections')}</H3>
                <Actions>
                  <Action secondary onClick={handleWazeClick}>
                    <Brand src={WazeLogo} />
                    Waze
                  </Action>
                  <Action secondary onClick={handleGoogleMapsClick}>
                    <Brand src={GMapsLogo} />
                    Google Maps
                  </Action>
                </Actions>
              </Directions>
            </FieldsetItem>
          )}
        </Fieldset>
      </Section>
    </ActionSheet>
  )
}

StartCall.propTypes = {
  startCallScreenVisible: bool,
  setStartCallScreenVisible: func.isRequired,
  call: object,
  inPersonCallLocationError: string,
  startCall: func,
  editCall: func,
  inOngoingCall: bool,
  loadingLocation: bool
}

export default StartCall
