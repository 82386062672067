// Note: Style template code.

import React from 'react'
import { func, node, number, object, string } from 'prop-types'
import tw from 'twin.macro'

import Card from 'components/card'
import VariationIndicator from 'components/VariationIndicator'

import CardPlaceholder from './CardPlaceholder'

const Header = tw.span`text-lg font-semibold text-slate-500`

const DataL = ({ header, children, variation, additional }) => (
  <div className="flex w-full flex-col items-center space-y-3">
    <Header>{header}</Header>
    <div className="flex flex-col items-center space-y-2">
      <span className="text-3xl font-medium text-slate-900">{children}</span>
      <div className="flex items-center space-x-2">
        <VariationIndicator variation={variation} />
        <span className="text-2xs font-medium text-slate-500">
          {variation > 0 ? '+' : variation < 0 ? '-' : ''}
          {Math.abs(variation)} {additional}
        </span>
      </div>
    </div>
  </div>
)

DataL.propTypes = {
  header: string,
  additional: string,
  children: node,
  variation: number
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-2">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const VerticalLine = tw.div`w-px h-20 bg-slate-200`

const TopSection = tw.div`space-y-5`

const BottomSection = tw.div`flex w-full space-x-4  items-center`

const Title = tw.span`inline-flex items-center h-9 text-xl font-semibold text-slate-900`

const HealthCheckCardPlaceholder = ({ span, amplifyAction, oosInvAmplify }) => {
  return (
    <Card title="Health Check" span={span} amplifyAction={amplifyAction} amplifySize={2}>
      <TopSection>
        <Eyebrow />
        <DistroCardPlaceholder />
        <Eyebrow />
      </TopSection>
      <div className="flex items-center">
        <Title>Inv. Capture & OOS</Title>
      </div>
      <InventoryCardPlaceholder />
    </Card>
  )
}

export const DistroCardPlaceholder = () => (
  <CardPlaceholder>
    <div className="space-y-4">
      <DataL header="Distro" variation={2.6} additional="vs PW">
        84%
      </DataL>
      <div className="flex items-center">
        <div className="w-full">
          <DataSm header="Stores">
            <div className="flex space-x-1 font-medium text-slate-900">
              <span className="font-medium text-slate-900">53%</span>
            </div>
          </DataSm>
        </div>
        <VerticalLine />
        <div className="w-full">
          <DataSm header="SKUs">
            <span className="flex font-medium text-slate-900">24%</span>
          </DataSm>
        </div>
      </div>
    </div>
  </CardPlaceholder>
)

export const InventoryCardPlaceholder = () => (
  <CardPlaceholder>
    <BottomSection>
      <div className="w-full">
        <DataL header="OOS" variation={2.4} additional="vs PW">
          12%
        </DataL>
      </div>
      <VerticalLine />
      <div className="w-full">
        <DataL header="Inv capture" variation={1.2} additional="vs PW">
          12%
        </DataL>
      </div>
    </BottomSection>
  </CardPlaceholder>
)

HealthCheckCardPlaceholder.propTypes = {
  span: object,
  amplifyAction: func,
  oosInvAmplify: func
}

export default HealthCheckCardPlaceholder
