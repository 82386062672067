// import { parseSearchQuery } from 'utils/helpers'
import orderBy from 'lodash/orderBy'

import { groupedScopeConfirmations } from 'store/customers/selectors'
import { createSelector } from 'store/selectors'

import { brandsByManufacturerAndCategory, fieldOrder, filterAllowedRequiredValues } from 'utils/constants'

const fullState = (state) => state

export const intelIdFromUrl = (state, props) => props.intelId
export const customerId = (state, props) => props.customerId || null
export const scopeId = (state, props) => {
  return props.scopeId
}

export const intelFromUrl = createSelector(intelIdFromUrl, fullState, (intelId, { intel, customers }) => {
  const intelObject = intel[intelId] || Object.values(intel).find(({ tempId }) => intelId === tempId)
  const customer = customers[intelObject.customerId]
  return {
    ...intelObject,
    customerName: customer?.name,
    detailRequests: orderBy(intelObject.detailRequests, (field) => fieldOrder.lastIndexOf(field))
  }
})

export const intelFeedbackInitialValues = createSelector(intelFromUrl, (intel) => {
  return {
    id: intel.id,
    images: intel.images,
    feedback: intel.feedback,
    customerId: intel.customerId,
    customerName: intel.customerName,
    ...intel.detailRequests.reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName]: intel[fieldName]
      }),
      {}
    )
  }
})

export const manufacturerBrandOptions = (formName) =>
  createSelector(fullState, ({ form }) => {
    const { values } = form[formName] || {}
    if (!values || !values.productCategory || !values.manufacturer) return []
    return brandsByManufacturerAndCategory[values.productCategory][values.manufacturer] || []
  })

const myIntel = createSelector(fullState, ({ intel, auth }) =>
  Object.values(intel).filter(({ employeeId }) => employeeId === auth.user.id)
)

export const intelRequiringActions = createSelector(myIntel, fullState, (intel, { scopes, customers }) => {
  const actionableIntel = intel.filter((i) => {
    const allowedRequiredValues = filterAllowedRequiredValues(i.type)
    const hasUnansweredQuestions = i.feedback && i.feedback.some(({ answer }) => !answer)
    const hasDetailRequests =
      i.detailRequests && i.detailRequests.some((field) => i[field] === null && allowedRequiredValues.includes(field))
    return hasUnansweredQuestions || hasDetailRequests
  })
  return actionableIntel.map((intel) => ({
    isIntelFeedback: true,
    detailRequests: orderBy(intel.detailRequests, (field) => fieldOrder.lastIndexOf(field)),
    customerName: customers[intel.customerId].name,
    ...intel
  }))
})

export const getInboxIntelCount = createSelector(intelRequiringActions, groupedScopeConfirmations, (intels, scopes) => {
  return scopes.reduce((sum, { scopeRequests }) => sum + scopeRequests.length, intels.length)
})

export const scopeFields = createSelector(fullState, scopeId, ({ scopes }, scopeId) => {
  const fields = scopeId ? scopes[scopeId].fields : {}
  return { ...fields, scopeId }
})

export const createIntelInitialValues = createSelector(scopeFields, (scopeFields) => {
  const { brand, manufacturer, productCategory } = scopeFields
  if (brand || manufacturer) {
    const [inferredCategory] = Object.entries(brandsByManufacturerAndCategory).find(([cat, catObj]) =>
      Object.entries(catObj).find(([man, brands]) => man === manufacturer || brands.includes(brand))
    )
    scopeFields.productCategory = productCategory || inferredCategory
    if (brand && !manufacturer) {
      const [inferredManufacturer] = Object.entries(brandsByManufacturerAndCategory[scopeFields.productCategory]).find(
        ([man, brands]) => brands.includes(brand)
      )
      scopeFields.manufacturer = inferredManufacturer
    }
  }
  return {
    plainPack: false,
    covid: false,
    regulations: false,
    ...scopeFields
  }
})
