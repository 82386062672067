import { createAction } from 'redux-actions'

import { updateUserReadAlerts } from 'store/auth/actions'

import * as api from './endpoints'

export const updateReadAlert = createAction('Mark alert as read')
export const markAlertRead = createAction('Submit alert as read', (id) => async (dispatch) => {
  const { data } = await api.submitAlertRead(id)
  dispatch(updateUserReadAlerts(data.alert))
})
