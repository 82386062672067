import React, { useContext } from 'react'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import LangContext from 'context/LangContext'

import Hint from 'components/Hint'
import Label from 'components/Label'

import { getInputFieldProps, getMeta } from 'utils/helpers'

const Inner = styled.div(({ disabled }) => [
  tw`flex items-center h-9 rounded-md bg-slate-500/5 p-1`,
  disabled && tw`bg-slate-50`
])

const Option = styled.label(({ disabled }) => [
  tw`relative h-full flex flex-1 justify-center`,
  disabled && tw`opacity-50 pointer-events-none`
])

const Input = tw.input``
Input.defaultProps = { type: 'radio' }

const OptionLabel = tw.span`font-medium border-0 whitespace-nowrap flex h-full w-full select-none items-center justify-center rounded px-4 text-sm text-slate-500 cursor-pointer transition hover:text-slate-900 peer-checked:bg-white peer-checked:text-slate-800 peer-checked:shadow-md peer-checked:ring-1 peer-checked:ring-slate-900/10 peer-focus-visible:ring-2 peer-focus-visible:ring-brand-500 `

const Badge = tw.span`text-sm inline-block ml-1 bg-slate-500 py-0 px-2 rounded-full`

const ErrorMessage = tw.small`text-red-500 block mt-2 text-2xs`

function SegmentControl({ vertical, options, label, hint, small, onChange, disabled, name, ...props }) {
  const { translate } = useContext(LangContext)

  const meta = getMeta(props) || {}
  const inputFieldProps = getInputFieldProps(props)

  const handleClick = (event) => {
    if (onChange) return
    if (props.field) {
      // Formik
      props.form.setFieldValue(inputFieldProps.name, event.target.value)
    } else {
      // Redux-Forms
      inputFieldProps.onChange(event.target.value)
    }
  }

  return (
    <div>
      {label && (
        <Label>
          {label}
          {hint && <Hint hint={hint} />}
        </Label>
      )}

      <Inner vertical={vertical} disabled={disabled}>
        {options.map((option, i) => (
          <Option key={option.value} small={small} defaultValue={meta.initialValue} disabled={disabled}>
            <Input
              aria-describedby={`${option.value}-description`}
              name={name}
              type="radio"
              {...inputFieldProps}
              {...props}
              value={option.value}
              onChange={onChange}
              className="peer sr-only"
              checked={(inputFieldProps.value || props.value) === option.value}
              onClick={handleClick}
            />
            <OptionLabel alt={props.alt} vertical={vertical}>
              {translate(option.label) ?? option.label}
              {Boolean(option.badgeCount) && <Badge>{option.badgeCount}</Badge>}
            </OptionLabel>
          </Option>
        ))}
      </Inner>

      {meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  )
}

SegmentControl.propTypes = {
  input: object,
  label: string,
  name: string,
  options: array.isRequired,
  hint: string,
  vertical: bool,
  value: oneOfType([string, number]),
  alt: bool,
  small: bool,
  field: object,
  form: object,
  onChange: func,
  disabled: bool
}

export default SegmentControl
