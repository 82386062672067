import camelCase from 'lodash/camelCase'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import { customerAssortment } from 'store/customers/selectors'
import { createSelector } from 'store/selectors'

import { DOUBLE_NESTED_BRANDS } from 'utils/constants'

export const productsByBrand = createSelector(customerAssortment, (assortment) => {
  if (isEmpty(assortment)) return {}
  const orderedAssortment = orderBy(assortment, [
    ({ brand }) => (brand || '').toLowerCase(),
    ({ name }) => (name || '').toLowerCase()
  ])
  const brandGroupedProducts = groupBy(orderedAssortment, 'brand')
  return Object.entries(brandGroupedProducts).reduce(
    (acc, [brand, products]) => ({
      ...acc,
      [camelCase(brand)]: {
        label: brand,
        products: DOUBLE_NESTED_BRANDS.includes(brand)
          ? Object.entries(
              groupBy(
                orderBy(products, [({ variant }) => (variant || '').toLowerCase()]),
                ({ brand, variant }) => variant || brand
              )
            ).reduce(
              (acc, [variant, products]) => ({
                ...acc,
                [camelCase(variant)]: {
                  label: variant,
                  products
                }
              }),
              {}
            )
          : products
      }
    }),
    {}
  )
})

// export const zfg100000products = createSelector(customerAssortment, assortment => {
//   if (isEmpty(assortment)) return []
//   return assortment.filter(item => item.materialGroupCode === 'ZFG100000').map(({ id }) => `p${id}`)
// })
