import React from 'react'
import { stripUnit } from 'polished'
import { array, bool, object, string } from 'prop-types'
import styled, { css } from 'styled-components'

import Checkbox from 'components/Checkbox'
import FieldError from 'components/ErrorMessage'
import Label from 'components/Label'

import { getInputFieldProps, getMeta } from 'utils/helpers'

import { borderColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  background-color: ${white};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `};
`

const Wrap = styled.div`
  position: relative;
  padding: ${stripUnit(spacing.small) * 1.5 + 'px'};
  border-radius: ${borderRadius};
  box-shadow: inset 0 0 0 1px ${borderColor};
`

const Options = styled.div`
  display: grid;
  grid-gap: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${(props) =>
    !props.singleColumn &&
    css`
      ${media.breakpoint`
    grid-template-columns: repeat(2, 1fr);
  `};
    `};
`

const MultiSelect = ({ singleColumn, options, label, disabled, ...props }) => {
  const input = getInputFieldProps(props)
  const meta = getMeta(props)
  const updateSelected = (option) => {
    const { value, onChange } = input
    if (!value && value !== false) return onChange([option])
    const optionIndex = value.findIndex((val) => val === option)
    if (optionIndex === -1) return onChange([...value, option])
    return onChange(value.filter((val) => val !== option))
  }

  return (
    <Container disabled={disabled}>
      {label && <Label>{label}</Label>}
      <Wrap>
        <Options singleColumn={singleColumn}>
          {options.map((option, i) => {
            const optionLabel = typeof option === 'object' ? option.label : option
            const optionValue = typeof option === 'object' ? option.value : option
            return (
              <Checkbox
                key={i}
                label={optionLabel}
                checked={Boolean(input.value && input.value.includes(optionValue))}
                onClick={() => updateSelected(optionValue)}
              />
            )
          })}
        </Options>
      </Wrap>
      {meta.error && meta.touched && !meta.active && <FieldError>{meta.error}</FieldError>}
    </Container>
  )
}

MultiSelect.propTypes = {
  options: array.isRequired,
  input: object,
  singleColumn: bool,
  label: string,
  disabled: bool,
  meta: object
}

export default MultiSelect
