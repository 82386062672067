import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Field, Form, Formik, useFormikContext } from 'formik'
import useErrors from 'hooks/useErrors'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import moment from 'moment-timezone'
import { bool, func, object } from 'prop-types'

import LangContext from 'context/LangContext'

import * as callTaskSelector from 'store/callTasks/selectors'
import { deleteCustomerCall, upsertCustomerCall } from 'store/customerCalls/actions'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Input from 'components/Input'
import CallForm from 'components/schedule/CallForm'

import Checklist from './Checklist'
import ConfirmeDeleteCallSheet from './ConfirmeDeleteCallSheet'

const PastCallForm = ({ canEdit, pastCallToEdit, setShowDeleteConfirmation }) => {
  const { translate } = useContext(LangContext)
  const { values, submitForm, isSubmitting, errors } = useFormikContext()

  const fulfilledGeneratedCall = Boolean(pastCallToEdit?.generatedCallId)

  const tasksByOutletSubtype = useSelector(callTaskSelector.tasksByOutletSubtype)

  const completedTasks = useMemo(() => {
    const { customer } = values
    const tasks = tasksByOutletSubtype && customer?.outletSubtype && tasksByOutletSubtype[customer.outletSubtype]
    const existingTasks = pastCallToEdit ? pastCallToEdit.completedTasks : null

    if (tasks?.length) {
      return (
        tasks?.map((task) => ({
          id: task.id,
          label: task.task,
          checked: existingTasks ? existingTasks.find(({ id }) => task.id === id)?.checked : false
        })) || []
      )
    }
    return []
  }, [values?.customer, pastCallToEdit, tasksByOutletSubtype])

  const isInPerson = useMemo(() => {
    const { type } = values
    return type === 'in-person'
  }, [values?.type])

  const milleageError = useMemo(() => {
    const { mileage } = values
    return (
      isInPerson &&
      (typeof mileage !== 'number' || mileage < 0) &&
      translate('createPastCallSheet.warn.mileageRequired')
    )
  }, [isInPerson, values?.mileage])

  return (
    <Form>
      <CallForm allowPastTime={true} canEdit={canEdit} mustBePast />
      {completedTasks?.length > 0 && (
        <Fieldset>
          <FieldsetItem>
            <Field name="completedTasks">
              {({ field }) => <Checklist items={completedTasks} disabled={!canEdit} {...field} />}
            </Field>
          </FieldsetItem>
        </Fieldset>
      )}
      <Fieldset>
        {isInPerson && (
          <FieldsetItem>
            <Field name="mileage">
              {({ field }) => (
                <Input
                  label={translate('common.mileage')}
                  type="number"
                  placeholder="0"
                  disabled={!canEdit}
                  {...field}
                />
              )}
            </Field>
          </FieldsetItem>
        )}
        <FieldsetItem>
          <Field name="notes">
            {({ field }) => (
              <Input
                label={translate('app.callNotes')}
                placeholder={translate('app.placeholders.typeNotesHere')}
                textarea
                maxLength={700}
                enableCounter
                disabled={!canEdit}
                {...field}
              />
            )}
          </Field>
        </FieldsetItem>
      </Fieldset>
      {canEdit && (
        <Fieldset>
          {milleageError && (
            <FieldsetItem>
              <GlobalAlert>{milleageError}</GlobalAlert>
            </FieldsetItem>
          )}
          <FieldsetItem>
            <Button
              full
              primary
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors) || milleageError}
            >
              Save
            </Button>
          </FieldsetItem>
          {pastCallToEdit && (
            <FieldsetItem>
              <Button
                type="button"
                full
                onClick={() => setShowDeleteConfirmation(true)}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                {fulfilledGeneratedCall ? translate('app.unlogCall') : translate('app.deleteCall')}
              </Button>
            </FieldsetItem>
          )}
        </Fieldset>
      )}
    </Form>
  )
}

PastCallForm.propTypes = {
  canEdit: bool,
  pastCallToEdit: object,
  setShowDeleteConfirmation: func.isRequired
}

const getDefaultValues = () => {
  const now = moment()

  return {
    startDate: now.toISOString(),
    startHour: '00',
    startMinute: '00',
    endHour: '00',
    endMinute: '01',
    type: 'in-person',
    notes: '',
    mileage: '',
    completedTasks: []
  }
}

const CreatePastCallSheet = ({
  deleteCustomerCall,
  upsertCustomerCall,
  pastCallToEdit,
  canEdit,
  sheetVisible,
  setSheetVisible
}) => {
  const { translate } = useContext(LangContext)

  const [showDeleteCallConfirmation, setShowDeleteConfirmation] = useState(false)
  const [initialValues, setInitialValues] = useState(getDefaultValues())
  const { globalError, setGlobalError, generateErrorMessage } = useErrors()

  const employeeTimezone = useSelector(
    (state) => state.auth.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  const fulfilledGeneratedCall = Boolean(pastCallToEdit?.generatedCallId)

  useEffect(() => {
    if (pastCallToEdit) {
      const { callStart, callEnd, type, customer, employeeKm, message, completedTasks } = pastCallToEdit

      const startTime = moment(callStart)
      const endTime = moment(callEnd)
      setInitialValues({
        startDate: callStart,
        startHour: startTime.hour(),
        startMinute: startTime.minute(),
        endHour: endTime.hour(),
        endMinute: endTime.minute(),
        type,
        notes: message?.text || null,
        mileage: employeeKm?.kms || null,
        completedTasks,
        customer
      })
    } else {
      setInitialValues(getDefaultValues())
    }
  }, [pastCallToEdit])

  const saveCall = async (values, actions) => {
    const { customer, startDate, startHour, startMinute, endHour, endMinute, type, notes, mileage, completedTasks } =
      values
    const startDateTime = moment(startDate).set({
      hour: startHour,
      minute: startMinute || 0,
      second: 0,
      millisecond: 0
    })

    const endDateTime = moment(startDate).set({ hour: endHour, minute: endMinute || 0, second: 0, millisecond: 0 })

    const insertMessage = notes?.trim()
      ? {
          text: notes,
          customerId: customer.id
          // employeeId: call.employeeId
        }
      : null

    const insertEmployeeKm = mileage
      ? {
          kms: parseFloat(mileage),
          suggestedKms: mileage,
          dateDriven: new Date(moment.tz(startDateTime, employeeTimezone)),
          type: 'CALL'
        }
      : null

    try {
      await upsertCustomerCall({
        ...pick(pastCallToEdit, ['id']),
        customerId: customer.id,
        callStart: moment.tz(startDateTime, employeeTimezone),
        callEnd: moment.tz(endDateTime, employeeTimezone),
        employeeKm: insertEmployeeKm,
        message: insertMessage,
        callType: type,
        loggedBy: 'USER',
        completedTasks
      })

      actions.resetForm()
      setSheetVisible(false)
    } catch (err) {
      console.error(err)
      setGlobalError(generateErrorMessage('app.errors.scheduleCall', err))
    }
  }

  const deleteCall = async () => {
    await deleteCustomerCall(pastCallToEdit)

    setInitialValues(getDefaultValues())
    setSheetVisible(false)
    setShowDeleteConfirmation(false)
  }

  const handleCancel = () => {
    setSheetVisible(false)
  }

  const title = pastCallToEdit ? 'createPastCallSheet.pastCallDetails' : 'createPastCallSheet.logPastCall'

  return (
    <Formik initialValues={initialValues} onSubmit={saveCall} enableReinitialize>
      {({ isSubmitting }) => (
        <>
          <ActionSheet
            title={translate(title)}
            visible={sheetVisible}
            action={
              <button type="button" onClick={handleCancel}>
                {translate('common.cancel')}
              </button>
            }
            onOverlayClick={handleCancel}
          >
            {globalError && <GlobalAlert warning>{globalError}</GlobalAlert>}
            <PastCallForm
              canEdit={canEdit}
              pastCallToEdit={pastCallToEdit}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
          </ActionSheet>

          <ConfirmeDeleteCallSheet
            confirmDeleteCall={showDeleteCallConfirmation}
            deleteAppointment={deleteCall}
            setConfirmDeleteCall={setShowDeleteConfirmation}
            isLoading={isSubmitting}
            wasGenerated={fulfilledGeneratedCall}
          />
        </>
      )}
    </Formik>
  )
}

CreatePastCallSheet.propTypes = {
  canEdit: bool,
  pastCallToEdit: object,
  sheetVisible: bool,
  setSheetVisible: func.isRequired,
  upsertCustomerCall: func.isRequired,
  deleteCustomerCall: func.isRequired
}

export default connect(null, {
  upsertCustomerCall,
  deleteCustomerCall
})(CreatePastCallSheet)
