import React, { useContext } from 'react'
import { bool, func } from 'prop-types'

import LangContext from 'context/LangContext'

import ActionSheet from 'components/ActionSheet'
import Button from 'components/button/Button'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'

const ConfirmeDeleteCallSheet = ({
  confirmDeleteCall,
  deleteAppointment,
  setConfirmDeleteCall,
  isLoading,
  wasGenerated
}) => {
  const { translate } = useContext(LangContext)

  const title = wasGenerated ? 'app.unlogCall' : 'app.deleteCall'
  const confirmText = wasGenerated ? 'app.confirmUnlogCall' : 'app.confirmDeleteCall'

  return (
    <ActionSheet
      title={translate(title)}
      visible={confirmDeleteCall}
      onOverlayClick={() => setConfirmDeleteCall(false)}
    >
      <div>
        {translate(confirmText)}
        <Fieldset>
          <FieldsetItem>
            <Button full primary isLoading={isLoading} disabled={isLoading} onClick={deleteAppointment}>
              {translate('common.yes')}
            </Button>
          </FieldsetItem>
          <FieldsetItem>
            <Button full isLoading={isLoading} disabled={isLoading} onClick={() => setConfirmDeleteCall(false)}>
              {translate('common.no')}
            </Button>
          </FieldsetItem>
        </Fieldset>
      </div>
    </ActionSheet>
  )
}

ConfirmeDeleteCallSheet.propTypes = {
  confirmDeleteCall: bool,
  deleteAppointment: func,
  setConfirmDeleteCall: func,
  isLoading: bool,
  wasGenerated: bool
}

export default ConfirmeDeleteCallSheet
