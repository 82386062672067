import React from 'react'
import { func, string } from 'prop-types'
import InlineSVG from 'svg-inline-react'

import { cn } from 'utils/styling'

import thumbDownIcon from 'static/icons/thumb-down.svg'
import thumbUpIcon from 'static/icons/thumb-up.svg'

const ItemWithRating = ({ item, rating, handleRating }) => {
  return (
    <div className="flex w-full items-center justify-between">
      <span className="text-base leading-tight text-slate-700">{item}</span>
      <div className="flex items-center gap-2">
        <button
          className={cn(
            'flex size-8 items-center justify-center rounded-full',
            rating === 'up' ? 'bg-brand' : 'bg-slate-50'
          )}
          onClick={() => handleRating('up')}
        >
          <div className={cn('size-4', rating === 'up' ? 'fill-slate-50' : 'fill-slate-400')}>
            <InlineSVG src={thumbUpIcon} />
          </div>
        </button>
        <button
          className={cn(
            'flex size-8 items-center justify-center rounded-full',
            rating === 'down' ? 'bg-brand' : 'bg-slate-50'
          )}
          onClick={() => handleRating('down')}
        >
          <div className={cn('size-4', rating === 'down' ? 'fill-slate-50' : 'fill-slate-400')}>
            <InlineSVG src={thumbDownIcon} />
          </div>
        </button>
      </div>
    </div>
  )
}

ItemWithRating.propTypes = {
  item: string.isRequired,
  rating: string,
  handleRating: func.isRequired
}

export default ItemWithRating
