import omit from 'lodash/omit'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync } from 'store/api'
import outbox from 'store/outbox'
import { oot as ootSchema } from 'store/schema'

import { OUTBOX_KEYS, SYNC_ACTION_KEYS } from 'utils/constants'

import * as api from './endpoints'

export const removeEmployeOot = createAction('Remove employee Oots')
export const addMileageToEmployeeOot = createAction('Add Mileage To Employee Oot')
export const removeMileageToEmployeeOot = createAction('Remove Mileage To Employee Oot')
export const overrideEmployeeOot = createAction('Override employee oot')

const queueOfflineOotDeletion = async (oot) => {
  const ootCreatedOffline = await outbox.getItem(OUTBOX_KEYS.UPSERTED_OOTS || {})
  const ootWasNotSaved = Object.keys(ootCreatedOffline).find((created) => created === oot.id)
  if (ootWasNotSaved) {
    await outbox.setItem(OUTBOX_KEYS.UPSERTED_OOTS, omit(ootCreatedOffline, oot.id))
  } else {
    const ootOutbox = (await outbox.getItem(OUTBOX_KEYS.DELETED_OOTS)) || {}
    await outbox.setItem(OUTBOX_KEYS.DELETED_OOTS, { ...ootOutbox, [oot.id]: oot })
    console.log(await outbox.getItem(OUTBOX_KEYS.DELETED_OOTS))
  }
  return registerSync(SYNC_ACTION_KEYS.DELETE_PENDING_OOTS)
}

const queueOfflineOotUpsert = async (oot) => {
  const tempOot = { ...oot, id: oot.id || `temp-id-${Date.now()}` }
  const ootOutbox = (await outbox.getItem(OUTBOX_KEYS.UPSERTED_OOTS)) || {}
  await outbox.setItem(OUTBOX_KEYS.UPSERTED_OOTS, { ...ootOutbox, [tempOot.id]: tempOot })
  await registerSync(SYNC_ACTION_KEYS.SUBMIT_PENDING_OOTS)
  return tempOot
}

export const fetchEmployeeOots = createAction('Fetch employee oots', ({ before, after }) => async (dispatch) => {
  try {
    const { data } = await api.fetchEmployeeOots({ before, after })
    const { entities } = normalize(data.employeeOots, [ootSchema])
    dispatch(mergeEntities(entities))
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const deleteEmployeeOOT = createAction('Delete Employee OOT', (oot) => async (dispatch) => {
  try {
    if (window.navigator.onLine) {
      await api.deleteEmployeeOOT(oot)
    } else {
      console.log('this is offline, trashing this => ', oot)
      await queueOfflineOotDeletion(oot)
    }

    dispatch(removeEmployeOot(oot))
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const upsertEmployeeOOT = createAction('Upsert Employee OOT', (oot) => async (dispatch) => {
  try {
    let upsertedOot
    if (window.navigator.onLine) {
      const { data } = await api.upsertEmployeeOOT(oot)
      const { entities } = normalize(data.employeeOots, ootSchema)
      dispatch(mergeEntities(entities))
      upsertedOot = data.employeeOots
    } else {
      const tempOot = await queueOfflineOotUpsert(oot)
      const { entities } = normalize(tempOot, ootSchema)
      dispatch(mergeEntities(entities))
      upsertedOot = tempOot
    }
    return upsertedOot
  } catch (err) {
    console.dir(err)
    throw err
  }
})

export const replaceEmployeeOot = createAction('Replace Employee OOT', ({ idToReplace, oot }) => async (dispatch) => {
  try {
    dispatch(overrideEmployeeOot({ idToReplace, oot }))
    return oot
  } catch (err) {
    console.dir(err)
    throw err
  }
})
