import React from 'react'
import { useLocation } from 'react-router-dom'

import Button from 'components/button/Button'

const PasswordResetRequested = () => {
  const { state } = useLocation()
  return (
    <>
      <p>
        {state?.requestQueued ? 'When you regain an internet connection, we will send' : 'We’ve just sent'} an email
        with password reset instructions to <u>{state?.email}</u>.
      </p>
      <Button to="/auth/login" primary full>
        Back to Login
      </Button>
    </>
  )
}

export default PasswordResetRequested
