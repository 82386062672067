import React from 'react'
import { connect } from 'react-redux'
import { string } from 'prop-types'

import Disclaimer from 'components/Disclaimer'

const content = {
  en: (
    <p>
      For further information, you are invited to consult the document “Practical Recommendations on Receiving
      Competitor Intelligence Information” found on the Competition Law Training Program located at{' '}
      <a href="http://www.snwebcastcenter.com/custom_events/itcan-2017-cltp/site/">
        http://www.snwebcastcenter.com/custom_events/itcan-2017-cltp/site/
      </a>
      .
    </p>
  ),
  fr: (
    <p>
      Pour plus d’information, vous êtes invités à consulter le document « Recommandations Pratiques Concernant la
      Réception de Données sur la Concurrence » que vous pourrez trouver sur le site du Programme de formation en droit
      de la concurrence au{' '}
      <a href="http://www.snwebcastcenter.com/custom_events/itcan-2017-cltp-fr/site/">
        http://www.snwebcastcenter.com/custom_events/itcan-2017-cltp-fr/site/
      </a>
    </p>
  )
}
const IntelDisclaimer = ({ language = 'en' }) => <Disclaimer>{content[language]}</Disclaimer>

IntelDisclaimer.propTypes = {
  language: string
}

export default connect((state) => ({ language: state.auth.user.preferredLanguage.substring(0, 2) }))(IntelDisclaimer)
