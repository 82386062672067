// Note: Style template code.

import React from 'react'
import { transparentize } from 'polished'
import { bool, func, node, number, object, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'
import tw from 'twin.macro'

import Card from 'components/card'

import CardPlaceholder from './CardPlaceholder'

const Header = tw.span`text-lg font-semibold text-slate-500`

const ChartWrapper = tw.div`relative flex h-12 w-12 flex-col`

const MiniPieChart = ({ value, description, amplify }) => {
  function getChartData(completionPercentage) {
    if (!completionPercentage && completionPercentage !== 0) return [0, 100]
    const completed = completionPercentage
    const toDo = 1 - completed

    return [
      {
        name: 'completionPercentage',
        value: completed,
        fill: '#53CCF8'
      },
      {
        name: 'gapPercentage',
        value: toDo,
        fill: transparentize(0.8, '#53CCF8')
      }
    ]
  }

  MiniPieChart.propTypes = {
    value: number,
    description: string,
    amplify: func
  }

  const chartData = getChartData(value)

  return (
    <div className="flex w-12 flex-col items-center space-y-3" onClick={amplify}>
      <ChartWrapper>
        <PieChart width={48} height={48} margin={0}>
          <Pie
            data={chartData}
            startAngle={90}
            endAngle={-270}
            dataKey="value"
            innerRadius="80%"
            outerRadius="100%"
            isAnimationActive={false}
          />
        </PieChart>
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-medium text-slate-900">
          {value || value === 0 ? Math.floor(value * 100) : '-'}%
        </div>
      </ChartWrapper>
      <span className="line-clamp-2 text-ellipsis text-center text-xs font-medium leading-4 text-slate-500">
        {description}
      </span>
    </div>
  )
}

const DataSm = ({ header, children }) => (
  <div className="flex flex-col items-center space-y-5">
    <Header>{header}</Header>
    {children}
  </div>
)

DataSm.propTypes = {
  header: string,
  children: node
}

const Eyebrow = tw.hr`w-full h-px bg-slate-100`

const PriceCheckCardPlaceholder = ({ onlyTop, span }) => {
  return (
    <Card title="Price check" span={span}>
      <>
        <CardPlaceholder>
          <div className="space-y-4">
            <Eyebrow />
            <div className="flex justify-between pb-2">
              <MiniPieChart value={+(32 / 100).toFixed(2)} description="Price capture" />
              <MiniPieChart value={+(85 / 100).toFixed(2)} description="Total strategy" />
              <MiniPieChart value={+(73 / 100).toFixed(2)} description="EDLP compliance" />
            </div>
          </div>

          {!onlyTop && (
            <div className="space-y-5">
              <Eyebrow />
              <div>
                <DataSm header="Average price">
                  <div className="flex flex-col space-y-4 text-lg font-semibold text-slate-500">64.02$</div>
                </DataSm>
              </div>
            </div>
          )}
        </CardPlaceholder>
      </>
    </Card>
  )
}

PriceCheckCardPlaceholder.propTypes = {
  onlyTop: bool,
  span: object
}

export default PriceCheckCardPlaceholder
