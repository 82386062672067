import React from 'react'
import { bool, number, oneOfType, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import DataVariation from './DataVariation'

const Container = styled.div(({ tight, stacked }) => [tw`flex gap-2`, stacked ? tw`flex-col gap-1` : tw`items-center`])

const Last = tw.span`text-sm font-medium text-slate-900`

const DataCompare = ({
  last,
  variation,
  stacked,
  hideIfZero,
  variationBefore,
  isPercent,
  reverseVariation,
  ...rest
}) => {
  const shouldDisplayVariation = !(!variation && hideIfZero) || variation !== 0
  return (
    <Container stacked={stacked}>
      <Last>
        {last} {isPercent && '%'}
      </Last>
      {shouldDisplayVariation && (
        <DataVariation
          stacked={!variationBefore}
          peer
          variation={Number(variation)}
          isPercent={isPercent}
          reverseVariation={reverseVariation}
          {...rest}
        />
      )}
    </Container>
  )
}

DataCompare.propTypes = {
  last: oneOfType([string, number]),
  variation: oneOfType([string, number]),
  stacked: bool,
  isPercent: bool,
  hideIfZero: bool,
  reverseVariation: bool,
  variationBefore: bool
}

export default DataCompare
