import React from 'react'
import moment from 'moment'
import { arrayOf, func, object } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'
import Pill from 'components/Pill'
import Table from 'components/table'

import { getExceptionType } from 'utils/formatters'

import { sellInStatusColors } from 'styles/colors'
import * as spacing from 'styles/spacing'

const CustomerName = styled.div`
  margin-bottom: ${spacing.small};
`

const ExceptionTable = ({ data = [], exceptionActionClick }) => {
  return (
    <Table
      data={data}
      columns={[
        {
          name: 'id',
          header: 'Exception details',
          body: {
            labelGenFn: (record) => {
              const exceptionType = getExceptionType(record)
              return (
                <div>
                  {record.customer && (
                    <CustomerName>
                      <b>{record.customer.name}</b> ({record.customer.id})
                    </CustomerName>
                  )}
                  <b>Type: </b>
                  Change {exceptionType}
                  <br />
                  <b>Request: </b>
                  {record.requestComment}
                  {record.responseComment && (
                    <>
                      <br />
                      <b>Response: </b>
                      {record.responseComment}
                    </>
                  )}
                  <br />
                  Last updated: {moment(record.updatedAt).format('MMM D HH:mm')}
                </div>
              )
            }
          }
        },
        {
          name: 'exceptionStatus',
          header: 'Status',
          body: {
            labelGenFn: (record) => {
              return <Pill color={sellInStatusColors[record.exceptionStatus]}>{record.exceptionStatus}</Pill>
            }
          }
        },
        {
          name: 'exceptionAction',
          header: '',
          body: {
            labelGenFn: (record) => {
              return (
                <button type="button" onClick={() => exceptionActionClick(record)}>
                  <Icon icon="right-arrow" />
                </button>
              )
            }
          }
        }
      ]}
    />
  )
}
ExceptionTable.propTypes = {
  data: arrayOf(object),
  exceptionActionClick: func.isRequired
}

export default ExceptionTable
