import React from 'react'
import { bool, node, string } from 'prop-types'
import styled, { css } from 'styled-components'

import { containerMaxWidth } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

export const ContainerWrap = styled.div`
  padding: ${(props) => (!props.noPadding ? `${spacing.medium} 0` : '0')};
  width: 100%;
  margin: 0 auto;
  height: 100%;
  overflow-y: ${(props) => (props.showOverflow ? 'visible' : 'auto')};

  ${(props) =>
    props.padded &&
    css`
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    `};

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.background};
    `};

  ${media.breakpoint`
    padding: ${(props) => (!props.noPadding ? `${spacing.large} 0` : '0')};

    ${(props) =>
      !props.fullWidth &&
      css`
        max-width: ${containerMaxWidth};
      `}

    ${(props) =>
      props.padded &&
      css`
        padding-left: 0;
        padding-right: 0;
      `}
  `};
  @media print {
    padding-bottom: ${spacing.small};
  }
`

const Container = ({ children, padded, noPadding, fullWidth, background, showOverflow, ...rest }) => (
  <ContainerWrap
    showOverflow={showOverflow}
    padded={padded}
    noPadding={noPadding}
    fullWidth={fullWidth}
    background={background}
  >
    {children}
  </ContainerWrap>
)

Container.propTypes = {
  children: node.isRequired,
  padded: bool,
  noPadding: bool,
  fullWidth: bool,
  background: string,
  showOverflow: bool
}

export default Container
