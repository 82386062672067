import { addEntities, mergeEntities, setEntities } from 'store/actions'

import { addProgramToAllocation, removeProgramFromAllocation } from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => ({
    ...state,
    ...action.payload.goFundAllocations
  }),
  [setEntities]: (state, action) => action.payload.goFundAllocations || state,
  [mergeEntities]: (state, action) => {
    if (!action.payload.goFundAllocations) return state
    return Object.entries(action.payload.goFundAllocations).reduce(
      (mergedGoFundAllocations, [id, goFundAllocation]) => {
        return Object.assign(mergedGoFundAllocations, {
          [id]: {
            ...(mergedGoFundAllocations[id] || {}),
            ...goFundAllocation
          }
        })
      },
      Object.assign({}, state)
    )
  },
  [addProgramToAllocation]: (state, { payload: { goFundId, id, territoryId } }) => ({
    ...state,
    [`${territoryId}_${goFundId}`]: {
      ...state[`${territoryId}_${goFundId}`],
      goFundPrograms: [...state[`${territoryId}_${goFundId}`].goFundPrograms, `${id}_${goFundId}`]
    }
  }),
  [removeProgramFromAllocation]: (state, { payload: { goFundId, id, territoryId } }) => ({
    ...state,
    [`${territoryId}_${goFundId}`]: {
      ...state[`${territoryId}_${goFundId}`],
      goFundPrograms: state[`${territoryId}_${goFundId}`].goFundPrograms.filter(
        (compositeKey) => compositeKey !== `${id}_${goFundId}`
      )
    }
  })
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
