import React from 'react'
import { func, object } from 'prop-types'

const CustomCell = ({ column, record, render }) => <td>{render?.(record, column)}</td>
CustomCell.propTypes = {
  /**
   * The definition of the column the cell is going to be rendered into
   */
  column: object.isRequired,

  /**
   * The record the cell will render
   */
  record: object.isRequired,

  /**
   * A function returning the React Element that will be rendered inside the cell
   *
   * @param {object} record The record provided to the cell
   * @param {object} column The column definition associated with the cell
   * @returns {ReactElement}
   */
  render: func.isRequired
}

export default CustomCell
