import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { bool, string } from 'prop-types'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'

import { cn } from 'utils/styling'

import { calendarEventColors } from 'styles/colors'
import { popoverContentAnimationClasses } from 'styles/global'

const LegendItem = ({ name, color, line = false }) => (
  <div className="flex items-center gap-2.5 text-sm text-slate-700">
    {line ? (
      <div className="h-1 w-3" style={{ backgroundColor: color }} />
    ) : (
      <div className="size-3 rounded-full" style={{ backgroundColor: color }} />
    )}
    <span>{name}</span>
  </div>
)

LegendItem.propTypes = {
  name: string.isRequired,
  color: string.isRequired,
  line: bool
}

const LegendDropdown = () => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div>
          <div className="max-md:hidden">
            <Button icon="legend" secondary>
              Legend
            </Button>
          </div>
          <div className="md:hidden">
            <IconButton icon="legend" secondary />
          </div>
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          side="bottom"
          align="end"
          sideOffset={8}
          className={cn(
            'z-50 rounded-md bg-white px-4 py-3 shadow-lg ring-1 ring-slate-900/10',
            popoverContentAnimationClasses
          )}
        >
          <div className="flex select-none flex-col gap-3">
            <LegendItem name="App Scheduled call" color={calendarEventColors.generatedCalls} />
            <LegendItem name="TM Scheduled call" color={calendarEventColors.future} />
            <LegendItem name="Missed call" color={calendarEventColors.missed} />
            <LegendItem name="App-logged in-person" color={calendarEventColors['in-personFulfilled']} />
            <LegendItem name="App-logged telemarketing" color={calendarEventColors.telemarketingFulfilled} />
            <LegendItem name="TM-logged in-person" color={calendarEventColors['in-personPast']} />
            <LegendItem name="TM-logged telemarketing" color={calendarEventColors.telemarketingPast} />
            <LegendItem name="OOT" color={calendarEventColors['time-off']} />
            <LegendItem name="TM rescheduled" color={calendarEventColors.future} line />
            <LegendItem name="Out of territory" color={calendarEventColors.adhoc} line />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default LegendDropdown
