import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { secondaryColor } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

export const Container = styled.div`
  position: relative;
  display: block;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};
  padding: 0 ${spacing.medium};

  ${media.breakpoint`
    padding: 0;
  `};
`

export const Title = styled(H3)`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
`

export const Action = styled.button`
  color: ${secondaryColor};
`

class ProgramAccordion extends PureComponent {
  static propTypes = {
    children: node.isRequired,
    title: string.isRequired
  }

  state = {
    expanded: false
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { children, title } = this.props
    const { expanded } = this.state
    return (
      <Container expanded={expanded}>
        <Header>
          <Title>{title}</Title>
          <Action onClick={this.toggleExpanded}>
            <div className="flex items-center gap-0.5">
              {expanded ? 'Hide' : 'Show'}
              <div className={expanded && 'rotate-180'}>
                <Icon icon="down-chevron" />
              </div>
            </div>
          </Action>
        </Header>
        <Content expanded={expanded}>{expanded && children}</Content>
      </Container>
    )
  }
}

export default ProgramAccordion
