import { saveAs } from 'file-saver'
import snakeCase from 'lodash/snakeCase'
// import JSONtoCSV from 'json2csv'
import moment from 'moment'

import { downloadSellInProgramSkuDataCsv } from 'store/sellInPrograms/endpoints'

const createDownloader = (fetchData, fileName, formatParamsForFilename) => (params) => {
  const filenameSlug = formatParamsForFilename ? '_' + formatParamsForFilename(params) : ''
  return fetchData(params).then(({ data }) => {
    saveAs(new Blob([data], { type: 'text/csv;charset=utf-8' }), `${fileName}${filenameSlug}-${moment().format()}.csv`)
  })
}

export const downloadSellInProgramSkuData = createDownloader(
  downloadSellInProgramSkuDataCsv,
  'sell-in-sku-data',
  ({ id, name, territoryId, territoryName }) => id + '_' + snakeCase(name) + '_' + snakeCase(territoryName)
)
