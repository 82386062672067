import React, { useEffect, useState } from 'react'
import { array, bool, string } from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { borderColor, greyDark, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontBold } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};

  @media print {
    border-top: 0;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${spacing.medium};
  @media print {
    padding: ${spacing.small};
  }
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  font-weight: ${fontBold};
  color: ${greyDark};
`
const Title = styled.small`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  font-weight: ${fontBold};
  color: ${greyDark};

  @media print {
    font-size: 12px;
  }
`

const Content = styled.main`
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  padding: ${spacing.medium};
  padding-top: 0;
  margin-top: -${spacing.small};

  @media print {
    padding: ${spacing.small};
    padding-top: 0;
  }
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};

  @media print {
    display: none;
  }
`

const ScrollingContainer = styled.div`
  overflow: visible;
  white-space: nowrap;
  overflow-x: auto;
`

const ScrollingImages = styled.div`
  display: flex;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 300px;
`

const ImageAccordion = ({ title, preExpand, images }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (preExpand) setExpanded(true)
  }, [])

  return (
    <Container expanded={expanded}>
      <Header onClick={() => setExpanded(!expanded)}>
        <span style={{ flex: 1 }}>
          <TitleWrap>
            <Title>{title || 'Images'}</Title>
          </TitleWrap>
        </span>
        <Chevron icon="down-chevron" accent={Boolean(images.length)} expanded={expanded} white={!images} />
      </Header>
      <Content expanded={expanded}>
        <ScrollingContainer>
          <ScrollingImages>
            {images.map((image, index) => (
              <ImageContainer key={index}>
                <a
                  href={image.preview}
                  target="_blank"
                  style={{
                    margin: '20px 10px',
                    height: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems: 'center'
                  }}
                  rel="noreferrer"
                >
                  <img src={image.preview} />
                </a>
                <p>{image.text}</p>
              </ImageContainer>
            ))}
          </ScrollingImages>
        </ScrollingContainer>
      </Content>
    </Container>
  )
}

ImageAccordion.propTypes = {
  title: string.isRequired,
  preExpand: bool,
  images: array
}

export default ImageAccordion
