// Note: Style template code.

import React, { useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchActivity } from 'store/extraHub/actions'
import { getCurrentActivity } from 'store/extraHub/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const Data = ({ data, additional }) => (
  <div className="w-full space-y-4">
    <div className="flex flex-col items-center space-y-2">
      <span className="text-3xl font-medium text-slate-900">{data}</span>
      <span>{additional}</span>
    </div>
  </div>
)

Data.propTypes = {
  data: string,
  additional: string
}

const Activity = ({ span, fetchActivity }) => {
  const { translate } = useContext(LangContext)
  const [error, setError] = useState()
  const { sectorType, sectorId } = useParams()
  const currentActivity = useSelector((state) => getCurrentActivity(state))

  const dataKey = createDataKey(DATAKEY_TYPES.AMPLIFY.EXTRA_HUB.ACTIVITY, { sectorType, sectorId })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const isMounted = useRef()
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (sectorType && sectorId) {
      fetchActivity({ dataKey, sectorType, sectorId })
        .then(() => isMounted.current && setError())
        .catch((e) => isMounted.current && setError(e))
    }
  }, [sectorType, sectorId])

  const getContent = () => {
    if (isLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    return (
      <div className="grid h-full w-full grid-cols-6 justify-between gap-8 md:py-8">
        <div className="col-span-3 @lg/card:col-span-2">
          <Data
            data={formatPercent(currentActivity.erp)}
            additional={translate('app.ofXStores', { amount: currentActivity.totalErp.toLocaleString() })}
          />
        </div>
        <div className="col-span-3 @lg/card:col-span-2">
          <Data
            data={formatPercent(currentActivity.user)}
            additional={translate('app.ofXUsers', { amount: currentActivity.totalUser.toLocaleString() })}
          />
        </div>

        <div className="col-span-6 @lg/card:col-span-2">
          <Data data={`$${currentActivity.coins.toLocaleString()}`} additional={translate('app.availableRedeemed')} />
        </div>
      </div>
    )
  }

  return (
    <Card title={translate('app.extraHubActivity')} span={span} displayAmplify>
      {getContent()}
    </Card>
  )
}

Activity.propTypes = {
  span: object,
  fetchActivity: func
}

export default connect(null, {
  fetchActivity
})(Activity)
