import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { arrayOf, string } from 'prop-types'

import ObjectiveBadge from 'components/close/ObjectiveBadge'
import Icon from 'components/Icon'

const ActionAccordion = ({ category, categoryColor, summary, items }) => {
  return (
    <Accordion.Item value={category}>
      <Accordion.Trigger className="group flex w-full items-center justify-between gap-2">
        <div className="flex gap-1 max-md:flex-col md:items-center">
          <div className="flex shrink-0 justify-start md:w-36">
            <ObjectiveBadge variant={categoryColor}>{category}</ObjectiveBadge>
          </div>
          <span className="text-left font-medium text-slate-900">{summary}</span>
        </div>
        <div className="relative size-5 shrink-0">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform group-data-[state=open]:rotate-180">
            <Icon icon="down-chevron" compact />
          </div>
        </div>
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
        <ul className="max-h-48 list-disc overflow-y-auto pl-5 text-xs text-slate-900 marker:text-slate-700">
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  )
}

ActionAccordion.propTypes = {
  category: string.isRequired,
  categoryColor: string.isRequired,
  summary: string.isRequired,
  items: arrayOf(string).isRequired
}

const ACTIONS = [
  {
    category: 'Sell In Program',
    categoryColor: 'sellIn',
    summary: '<% amount %> Targeted Programs to take action now!',
    items: ['H2 2024 Buy & Save (VAPE)', 'H2 2024 Buy & Save (FMC)', 'H2 2024 Double Up']
  },
  {
    category: 'Price Capture',
    categoryColor: 'priceCheck',
    summary: 'Just <% amount %> products left to acheive 100% price capture!',
    items: [
      'Price check needed for Dunhill Red',
      'Update price for Vuse Go Classic Tobacco',
      'Verify pricing on Kent Blue',
      'Price adjustment required for Vuse ePod',
      'Review pricing for Pall Mall Red',
      'Update Rothmans Blue price tag',
      'Check pricing for Vuse Alto pods',
      'Price verification for Lucky Strike',
      'Update Dunhill International pricing',
      'Review Kent Nanotek price point'
    ]
  },
  {
    category: 'Price Compliance',
    categoryColor: 'priceCheck',
    summary: 'Only <% amount %> products remain to fully align with our price strategy!',
    items: [
      'Adjust Dunhill Red price to match recommended retail',
      'Update Vuse Go pricing to align with strategy',
      'Review Kent Blue price compliance',
      'Align Pall Mall pricing with market guidelines',
      'Adjust Lucky Strike price point to strategy'
    ]
  },
  {
    category: 'Survey',
    categoryColor: 'survey',
    summary: '<% amount %> targeted surveys to take action now!',
    items: [
      'Complete monthly product survey for Q1',
      'Submit customer feedback questionnaire',
      'Fill out store display compliance survey'
    ]
  },
  {
    category: 'Extra Funds',
    categoryColor: 'extraFunds',
    summary: '<% amount %> targeted program to take action now!',
    items: ['H2 2024 Buy & Save (VAPE)']
  }
]

const Actions = () => {
  return (
    <Accordion.Root type="single" collapsible className="flex flex-col gap-4 md:gap-2">
      {ACTIONS.map((action) => {
        const formattedSummary = action.summary.replace('<% amount %>', action.items.length)

        if (action.items.length === 0) return null

        return <ActionAccordion key={action.category} {...action} summary={formattedSummary} />
      })}
    </Accordion.Root>
  )
}

export default Actions
