import React, { useContext } from 'react'
import { array, node, string } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { SECTOR_LEVELS } from 'utils/constants'

import IncompatibleScope from './IncompatibleScope'

const defaultValidScopes = [SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY]

const CheckForValidScope = ({ children, validScopes, pageName }) => {
  const { selectedLevel } = useContext(SectorContext)

  const acceptedScopes = validScopes || defaultValidScopes

  if (acceptedScopes.includes(selectedLevel)) return children
  return <IncompatibleScope page={pageName} acceptedScopes={acceptedScopes} />
}

CheckForValidScope.propTypes = {
  children: node.isRequired,
  pageName: string.isRequired,
  validScopes: array
}

export default CheckForValidScope
