import uniqBy from 'lodash/uniqBy'

import { addEntities, mergeEntities, setEntities } from 'store/actions'

const addMergeEntities = (state, { payload }) => {
  if (!payload.sellInPrograms) return state
  return Object.entries(payload.sellInPrograms).reduce((acc, [id, sellInProgram]) => {
    const oldSIP = acc[id] || {}
    const targetsByTerritory = oldSIP.targetsByTerritory || {}
    return Object.assign(acc, {
      [id]: {
        ...oldSIP,
        ...sellInProgram,
        targetsByTerritory: Object.assign({}, targetsByTerritory, sellInProgram.targetsByTerritory || {}),
        targets: uniqBy((sellInProgram.targets || []).concat(oldSIP.targets || []), 'customerId')
      }
    })
  }, Object.assign({}, state))
}

const ACTION_HANDLERS = {
  [addEntities]: addMergeEntities,
  [setEntities]: (state, action) => action.payload.sellInPrograms || state,
  [mergeEntities]: addMergeEntities
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
