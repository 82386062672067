import React, { useContext } from 'react'

import LangContext from 'context/LangContext'

import CheckForValidScope from 'components/guards/CheckForValidScope'

import { SECTOR_LEVELS } from 'utils/constants'

import CartCard from './CartCard'

const CloseCartView = () => {
  const { translate } = useContext(LangContext)

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  return (
    <CheckForValidScope pageName={translate('close.closeOpportunities')} validScopes={validScopes}>
      <div className="relative grid h-full w-full grid-cols-12 gap-5 max-md:pt-20">
        <CartCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} />
      </div>
    </CheckForValidScope>
  )
}

export default CloseCartView
