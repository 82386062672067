import React, { useLayoutEffect, useState } from 'react'
import { stripUnit } from 'polished'
import { array, bool, func } from 'prop-types'
import styled, { css } from 'styled-components'

import Checkbox from 'components/Checkbox'

import { greyLight } from 'styles/colors'
import { animationCurve, animationTime } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: grid;
  grid-gap: ${spacing.medium};
`

const Label = styled.span`
  text-align: left;
  margin-top: -3px; // Visually align text with checkbox
  transition: color ${animationTime} ${animationCurve};

  ${(props) =>
    props.strikethrough &&
    css`
      color: ${greyLight};
      text-decoration: line-through;
    `};
`

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Option = styled.div`
  flex-basis: 100%;
  padding-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  padding-right: ${stripUnit(spacing.small) * 1.5 + 'px'};
  ${media.breakpoint`
    flex-basis: ${(props) => (props.singleColumn ? '100%' : '50%')};
  `};
`

const Checklist = ({ items, onChange, disabled }) => {
  const [stateItems, setStateItems] = useState([])
  useLayoutEffect(() => {
    if (items?.length) {
      setStateItems(
        items.map((item) => ({
          ...item,
          checked: item.checked || false
        }))
      )
    }
  }, [items])

  const handleClick = (itemId) => {
    if (!onChange || disabled) return
    const updatedList = stateItems.map((item) => {
      if (item.id !== itemId) return item
      return { ...item, checked: !item.checked }
    })
    onChange(updatedList)
    setStateItems(updatedList)
  }

  return (
    <Container>
      <Options>
        {stateItems.map((item, i) => {
          return (
            <Option key={item.id} singleColumn>
              <Checkbox
                small
                id={`item-${item.id}`}
                name={`item-${item.id}`}
                checked={item.checked}
                onChange={() => handleClick(item.id)}
                disabled={disabled}
              />
              <Label strikethrough={item.checked}>{item.label}</Label>
            </Option>
          )
        })}
      </Options>
    </Container>
  )
}

Checklist.propTypes = {
  items: array.isRequired,
  onChange: func,
  disabled: bool
}

export default Checklist
