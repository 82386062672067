import React from 'react'
import { bool, node } from 'prop-types'

import MobileNav from 'components/MobileNav'

const MobileHeader = ({ children, isNavOnly }) => {
  return (
    <div className="absolute left-0 right-0 top-0 z-50 flex gap-3 from-white to-white/0 p-5 after:pointer-events-none after:absolute after:-bottom-4 after:left-0 after:right-0 after:top-0 after:block after:w-full after:bg-gradient-to-b after:content-[''] md:hidden">
      {!isNavOnly && children}
      <MobileNav isNavOnly={isNavOnly} />
    </div>
  )
}

MobileHeader.propTypes = {
  isNavOnly: bool,
  children: node
}

export default MobileHeader
