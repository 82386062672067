import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'
import KeyAccounts from 'views/Customer/KeyAccounts'

import LangContext from 'context/LangContext'

import { fetchCustomerConsumerOffers } from 'store/customers/actions'
import { customerConsumerOffers, customerKeyCards } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import CheckForValidScope from 'components/guards/CheckForValidScope'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const ConsumerOfferContent = ({ fetchCustomerConsumerOffers }) => {
  useEffect(() => {
    document.title = 'Close - Consumer Offer'
  }, [])

  const { sectorId } = useParams()
  const [error, setError] = useState()

  const consumerOffers = useSelector((state) => customerConsumerOffers(state, { customerId: sectorId }))
  const keyCards = useSelector((state) => customerKeyCards(state, { customerId: sectorId }))

  const hasKeyAcctData = consumerOffers.length + keyCards.length > 0

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_CONSUMER_OFFER, {
    customerId: sectorId
  })
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  useEffect(() => {
    let mounted = true

    setError()

    if (sectorId) {
      fetchCustomerConsumerOffers(sectorId, dataKey).catch((error) => {
        if (mounted) setError(getErrorMessage(error))
      })
    }

    return () => {
      mounted = false
    }
  }, [sectorId])

  const renderContent = () => {
    if (isLoading) {
      return <Spinner icon="spinner" />
    }

    if (error) {
      return <EmptyState title="An error occured" subtitle={error} />
    }

    if (!hasKeyAcctData) {
      return <EmptyState title="No data available" subtitle="This store has no consumer offers." />
    }

    return <KeyAccounts consumerOffers={consumerOffers} keyCards={keyCards} />
  }

  return renderContent()
}

ConsumerOfferContent.propTypes = {
  fetchCustomerConsumerOffers: func
}

const mapActionCreators = {
  fetchCustomerConsumerOffers
}
const ConnectedContent = connect(null, mapActionCreators)(ConsumerOfferContent)

const ConsumerOfferView = () => {
  const { translate } = useContext(LangContext)

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  return (
    <CheckForValidScope pageName={translate('close.consumerOffer')} validScopes={validScopes}>
      <Card removeHeader span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <ConnectedContent />
      </Card>
    </CheckForValidScope>
  )
}

export default ConsumerOfferView
