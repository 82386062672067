import React from 'react'
import { stripUnit } from 'polished'
import { bool, node, string } from 'prop-types'
import styled from 'styled-components'

import SmallCaps from 'components/SmallCaps'

import { greyLight } from 'styles/colors'
import * as spacing from 'styles/spacing'

export const Container = styled.div`
  margin-bottom: ${spacing.large};

  &:first-child {
    margin-top: ${spacing.small};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = styled(SmallCaps)`
  color: ${greyLight};
  display: block;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  padding-left: ${spacing.medium};
`

const TableRowGroup = ({ heading, children, ...rest }) => (
  <Container>
    {heading && <Heading>{heading}</Heading>}
    <div>{children}</div>
  </Container>
)

TableRowGroup.propTypes = {
  children: node.isRequired,
  heading: string,
  full: bool
}

export default TableRowGroup
