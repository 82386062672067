import { addEntities, mergeEntities, setEntities } from 'store/actions'
// import { markInsightSaqComplete } from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, action) => Object.assign({}, state, action.payload.projects),
  [setEntities]: (state, action) => action.payload.projects || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.projects) return state
    return Object.entries(payload.projects).reduce((acc, [id, project]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...project
        }
      })
    }, Object.assign({}, state))
  }
  // [markInsightSaqComplete]: (state, { payload: { projectId, orderId } }) => {
  //   const project = state[projectId]
  //   if (!project) return state
  //   return Object.assign({}, state, { projectId: { ...project, order: { id: orderId } } })
  // }
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
