import React, { useContext } from 'react'

import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'

import CheckForValidScope from 'components/guards/CheckForValidScope'
import ViewHeader from 'components/ViewHeader'

import { SECTOR_LEVELS } from 'utils/constants'

import AddedValueCard from './AddedValueCard'
import ObjectivesCard from './ObjectivesCard'
import OrderRecapCard from './OrderRecapCard'

const EvaluateLayout = () => {
  const { translate } = useContext(LangContext)
  const { isWideLayout } = useContext(MediaContext)

  document.title = 'Evaluate'

  return (
    <CheckForValidScope pageName={translate('app.pace.evaluate')} validScopes={[SECTOR_LEVELS.CUSTOMER]}>
      <div className="grid w-full grid-cols-12 gap-5 p-5 max-md:pt-0">
        {!isWideLayout && <ViewHeader header={translate('app.pace.evaluate')} />}
        <ObjectivesCard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} />
        <OrderRecapCard span={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 4 }} />
        <AddedValueCard span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 4 }} />
      </div>
    </CheckForValidScope>
  )
}

export default EvaluateLayout
