import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { capitalize, debounce, isEmpty } from 'lodash'
import { arrayOf, bool, func, number, object, string } from 'prop-types'
import tw, { styled } from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { cachedFetchAllSectors, fetchAllSectors } from 'store/sector/actions'
import { getSearchResult } from 'store/sector/selectors'

import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'
import { cn } from 'utils/styling'

import { GroupedSearch } from './GroupedSearch'

const SEARCH_LIMIT = 50

const ItemWrapper = styled.button(({ selected }) => [
  tw`flex w-full items-center justify-between rounded-md px-4 py-3 transition-all text-left`,
  selected
    ? tw`bg-brand-100 border-2 border-brand-700/60 text-brand-900`
    : tw`bg-white border-2 border-slate-900/10 hover:border-brand-600/60 hover:shadow-md hover:bg-slate-50`
])

const Name = styled.span(({ selected }) => [tw`text-sm font-medium text-slate-900`, selected && tw`text-brand-800`])

const Additional = tw.div`flex items-center gap-1.5 text-2xs text-slate-900/60`

const ItemContainer = styled.div(({ amount }) => [
  tw`relative h-full md:h-[410px] space-y-3 p-px overflow-y-scroll pr-4`
])

const Item = ({ selected, result, handleItemClick }) => {
  const { translate } = useContext(LangContext)

  return (
    <ItemWrapper selected={selected} disabled={selected} className="group" onClick={handleItemClick}>
      {result && (
        <>
          <div className="space-y-0.5">
            <div className="space-x-2">
              <Name selected={selected}>
                {result.type === 'customer' && `${result.id} - `}
                {result.name}
              </Name>
            </div>

            <Additional>
              {capitalize(result?.type)} {result.additional}{' '}
              {result.type === 'customer' &&
                ` - ${result.address.line_1}, ${result.address.city}, ${result.address.state}`}
            </Additional>
          </div>

          {!selected && (
            <div className="relative -left-1.5 flex rounded-lg p-0.5 text-slate-300 transition group-hover:translate-x-2 group-hover:bg-slate-50 group-hover:text-slate-500">
              <Icon icon="right-chevron" />
            </div>
          )}

          {selected && (
            <div className="mr-2 flex items-center gap-0.5 uppercase text-brand-600 drop-shadow">
              <Icon icon="checkmark-small" />
              <span className="text-xs font-medium text-brand-600/80">{translate('common.selected')}</span>
            </div>
          )}
        </>
      )}
    </ItemWrapper>
  )
}

Item.propTypes = {
  selected: bool,
  name: string,
  // id: string,
  additional: string,
  handleItemClick: func,
  result: object
}

const EndMessageWrapper = styled.div(({ amount }) => [
  tw`flex w-full items-center justify-center text-sm text-slate-500`,
  amount > 5 ? tw`h-16` : tw`h-12`
])

const EndMessage = ({ amount }) => {
  const { translate } = useContext(LangContext)

  function message(amount) {
    if (amount > SEARCH_LIMIT)
      return (
        <div className="space-y-0.5 pl-4 text-center">
          <p className="block">
            {translate('search.template.showingResults', {
              searchLimit: SEARCH_LIMIT,
              resultsAmount: amount
            })}
          </p>
          <span className="block text-2xs text-slate-400">{translate('app.refineYourSearch')}</span>
        </div>
      )

    return translate('app.endOfResults')
  }

  return <EndMessageWrapper amount={amount}>{message(amount)}</EndMessageWrapper>
}

EndMessage.propTypes = {
  amount: number
}

const Content = styled.div(({ isMobile }) => [
  tw`flex flex-col space-y-4`,
  isMobile && tw`h-full min-h-0 overflow-y-clip p-px`
])

const Results = styled.div(({ isMobile }) => [
  isMobile ? tw`h-full min-h-0 md:pb-24` : tw`flex flex-col justify-center h-full min-h-[410px]`
])

const ResultsLabel = styled.span(({ isScrolled }) => [
  tw`mb-2 ml-2 inline-block w-full select-none text-xs font-medium uppercase tracking-widest text-slate-300`,
  isScrolled && tw`text-pink-500`
])

const SectorBrowser = ({
  onHandleClose,
  fetchAllSectors,
  sectorType,
  setSectorType,
  queryString,
  setQueryString,
  searchResults,
  setSearchResults,
  clearFields,
  isMobile,
  cachedFetchAllSectors
}) => {
  const { translate } = useContext(LangContext)
  const { fetchSector, selectedLevel, currentSector } = useContext(SectorContext)

  const [isLoading, setIsLoading] = useState(true)

  const mounted = useRef()

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const cachedResult = useSelector((state) => getSearchResult(state, { sectorType }))

  useEffect(() => {
    if (queryString) return
    setSearchResults(cachedResult)
  }, [sectorType, queryString, cachedResult])

  const handleItemClick = async (result) => {
    try {
      await fetchSector(result)
      if (mounted.current) {
        clearFields()
        onHandleClose()
      }
    } catch (err) {
      console.log(`Unable to fetch ${result.type} with ID ${result.id}`)
    }
  }

  const fetchSectors = async (sectorType, queryString) => {
    try {
      setIsLoading(true)
      if (queryString) {
        const sectors = await fetchAllSectors({ limit: SEARCH_LIMIT, sectorType, queryString })
        if (!mounted.current) return
        setSearchResults(sectors)
      } else {
        // cache request if we have an empty query string
        const dataKey = createDataKey(DATAKEY_TYPES.SECTOR_PICKER, { sectorType: sectorType || 'all' })
        await cachedFetchAllSectors({ sectorType, limit: SEARCH_LIMIT, dataKey })
      }
      setIsLoading(false)
    } catch (err) {
      if (!mounted.current) return
      setSearchResults([])
      setIsLoading(false)
    }
  }

  const debouncedSectorFetch = useCallback(debounce(fetchSectors, 300), [])

  useEffect(() => {
    debouncedSectorFetch(sectorType, queryString)
  }, [queryString])

  // not debounced version to fix an issue
  // where "No sectors available to you" flash
  // on screen for a 1/3 a second
  useEffect(() => {
    fetchSectors(sectorType, queryString)
  }, [sectorType])

  return (
    <Content className={'overflow-x-clip'} isMobile={isMobile}>
      {!isEmpty(currentSector) && <Item selected result={currentSector[selectedLevel]} />}

      <div className="mx-auto w-[calc(100%-4px)]">
        <GroupedSearch setSectorType={setSectorType} setQueryString={setQueryString} />
      </div>

      <ResultsLabel>{translate('app.searchResults')}</ResultsLabel>
      <Results isMobile={isMobile}>
        {isLoading ? (
          <WrappedSpinner icon="spinner" />
        ) : (
          <>
            {searchResults && Boolean(searchResults.length) && (
              <ItemContainer amount={searchResults.length}>
                {searchResults.slice(0, SEARCH_LIMIT).map((result, i) => (
                  <Item key={i} result={result} handleItemClick={() => handleItemClick(result)} />
                ))}
                {searchResults.length > 5 && <EndMessage amount={searchResults.length} />}
              </ItemContainer>
            )}

            {(!searchResults || !searchResults.length) && (
              <EmptyState
                title={translate('app.warn.noSectorAvailableToYou')}
                subtitle={translate('app.warn.mightNotHaveTerritoryAssigned')}
                className={cn(!isMobile && 'mb-12')}
              />
            )}
          </>
        )}
      </Results>
    </Content>
  )
}

SectorBrowser.propTypes = {
  fetchAllSectors: func.isRequired,
  cachedFetchAllSectors: func.isRequired,
  onHandleClose: func.isRequired,
  sectorType: string,
  setSectorType: func.isRequired,
  queryString: string,
  setQueryString: func.isRequired,
  clearFields: func.isRequired,
  searchResults: arrayOf(object),
  setSearchResults: func.isRequired,
  isMobile: bool
}

export default connect(null, {
  fetchAllSectors,
  cachedFetchAllSectors
})(SectorBrowser)
