import sortBy from 'lodash/sortBy'

export const SELLOUT_STATE_KEYS = {
  MAIN: ({ activeProductType, channel, dataType, unitOfMeasure }) =>
    `amplify-sellout-${activeProductType}-${channel}-${dataType}-${unitOfMeasure}`,
  GEOGRAPHY: ({
    productType,
    channel,
    dataType,
    geography,
    proportion,
    timeDisplay,
    unitOfMeasure,
    filters,
    sortColumn,
    sortDirection,
    offset,
    limit
  }) => {
    const filterString = sortBy(Object.keys(filters))
      .map((k) => filters[k])
      .filter(Boolean)
      .join('-')
    const stateKey = `amplify-sellout-${productType}-${channel}-${dataType}-${geography}-${proportion}-${timeDisplay}-${unitOfMeasure}-${filterString}-${sortColumn}-${sortDirection}-${offset}-${limit}`
    return stateKey
  }
}
