import React, { useContext } from 'react'
import { bool, number } from 'prop-types'

import LangContext from 'context/LangContext'

import { CardContent, CardHeading, Data, HiddenIndicator } from '.'

export const SellInData = ({ data, hidden }) => {
  const { translate } = useContext(LangContext)
  return (
    <CardContent>
      <CardHeading>{translate('app.sellInCompletion')}</CardHeading>
      {hidden && <HiddenIndicator />}

      <Data
        data={data.acceptedStores}
        title="Accepted Stores"
        additionalData={{
          type: 'actuals',
          data: {
            actuals: {
              amount: data.acceptedStores.actuals.amount,
              total: data.acceptedStores.actuals.total
            }
          }
        }}
      />

      <Data
        data={data.acceptedSKUs}
        title="Accepted SKUs"
        additionalData={{
          type: 'actuals',
          data: {
            actuals: {
              amount: data.acceptedSKUs.actuals.amount,
              total: data.acceptedSKUs.actuals.total
            }
          }
        }}
      />
    </CardContent>
  )
}

SellInData.propTypes = {
  data: {
    acceptedStores: {
      value: number,
      actuals: {
        amount: number,
        total: number
      },
      isTracked: bool
    },
    acceptedSKUs: {
      value: number,
      actuals: {
        amount: number,
        total: number
      },
      isTracked: bool
    }
  },
  hidden: bool
}

SellInData.defaultProps = {
  data: {
    acceptedStores: {
      value: 0,
      isTracked: true
    },
    acceptedSKUs: {
      value: 0,
      isTracked: true
    }
  }
}
